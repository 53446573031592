import { Button, notification, Tooltip } from 'antd';
import { ReactMediaRecorder } from 'components/recording/MediaRecorder';
import {
  CameraIcon,
  Close,
  DisableCameraIcon,
  DraggableIcon,
  MicrophoneIcon,
  ScreenIcon,
} from 'components/svg';
import firebase from 'firebase/app';
// @ts-ignore
import getUserMedia from 'getusermedia';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable'; // The default
import { connect } from 'react-redux';
import { connectServer, startSendingChunks } from 'utils/realtimeStream';
import { v4 as uuid } from 'uuid';
import { mobileCheck } from '../../utils';
import { secondsToHms } from '../../utils/index';
import AudioRecorderAddProject from '../recording/AudioRecorderAddProject';
import { RecordingTimer } from '../shared/RecordingTimer';
import './style.css';

const isMobile = mobileCheck();
let localStream: any = null;
const VideoDialogContent = (props: any) => {
  const streamToBeClearedRef = useRef<any>(null)
  const counterText: any = useRef(null);
  const [isTimer, shouldStartTimer] = React.useState(false);
  const [isReleaseCamera, setIsReleaseCamera] = React.useState(false);
  const { createVideoTask, ShowNewVideoCanvas } = props;
  const [hideDialogContent, setHideDialogContent] = useState(false);
  const [cameraState, setCameraState] = useState(false);
  const [screenState, setScreenState] = useState(true);
  const [microphoneState, setMicrophoneState] = useState(true);
  const [videoStreamSize, setVideoStreamSize] = useState('small');
  const [countDownTimer, setCountDownTimer] = useState(3);
  const [timerActive, setTimerActive] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [fileName, setFilename] = useState('');
  const [triggerRecordingPermission, setTriggerRecordingPermission] = useState(false);
  const [triggerStartRecording, setTriggerStartRecording] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [triggerStopRecording, setTriggerStopRecording] = useState(false);
  const [recordingInProgress, setRecordingInProgress] = useState(false);
  // const [videoStreamVisiblity, setVideoStreamVisiblity] = useState(false);
  const [fullScreenSize, setFullScreenSize] = useState('large');
  const [shouldStartRecording, setShouldStartRecording] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const { profile, auth } = props;
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = props.organization.selectOrganizationDetails.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  const maxRecordTime = userQuota && userQuota.videoCommentTime;
  const isChromeExtensionInstalled = false;
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  useEffect(() => {
    makeConnection();
    const recordingFileName = `recording-${uuid()}`;
    setFilename(recordingFileName);
    setVideoURL(`https://zooc-media-files.s3.eu-central-1.amazonaws.com/${recordingFileName}.m3u8`);
    return () => {
      streamToBeClearedRef.current &&
        streamToBeClearedRef.current.getTracks().forEach((track: any) => {
          track.stop();
        });
    };
  }, [props.auth]);

  // @ts-ignore
  let videoTag = null;
  const makeConnection = async () => {
    if (props.firebaseAuthToken) {
      firebaseToken = await firebaseInstance.auth().currentUser.getIdToken(true);
      connectServer(firebaseToken);
    } else {
      getFirebaseToken();
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (cameraState) {
      getUserMedia({ video: true, audio: false }, (err: any, stream: any) => {
        if (!err) {
          localStream = stream;
          // stream && setVideoSrc(window.URL.createObjectURL(stream));
          // @ts-ignore
          videoTag.srcObject = stream;
          // @ts-ignore
          videoTag.play();
        }
      });
    } else {
      localStream &&
        localStream.getTracks().forEach((track: any) => {
          track.stop();
        });
    }
    return () => {
      localStream &&
        localStream.getTracks().forEach((track: any) => {
          track.stop();
        });
    };
  }, [cameraState]);

  useEffect(() => {
    let interval: any = null;
    if (timerActive) {
      interval = setInterval(() => {
        const currentCounterString =
          (counterText && counterText.current && counterText.current.innerText) || '4';
        const currentCounterArray = currentCounterString.split(' ');
        let currentCounterValue = parseInt(currentCounterArray[currentCounterArray.length - 1], 10);
        if (currentCounterValue === 0) {
          clearInterval(interval);
          counterText.current.style.display = 'none';
        } else if (counterText.current) {
          counterText.current.innerHTML = `<div>${--currentCounterValue}</div>`;
        }
        setCountDownTimer(countDownTimer - 1);
      }, 600);
    }

    // setTimeout(() => {
    //   setVideoStreamVisiblity(true);
    // }, 2000);

    if (countDownTimer <= 0) {
      setTriggerStartRecording(true);
      setShowCountDown(false);
      clearInterval(interval);
      setTimerActive(false);
      printState('4-Triggered-Start-Recording');
    }
    return () => {
      clearInterval(interval);
    };
  }, [timerActive, countDownTimer]);

  const getVideoStreamContainerClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return '';
      case 'medium':
        return 'videoStreamContainerMedium';
      case 'large':
        return 'videoStreamContainerLarge';
    }
  };

  const getVideoStreamTagClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return 'videoStreamTag';
      case 'medium':
        return 'videStreamTagMedium';
      case 'large':
        return 'videStreamTagLarge';
    }
  };

  const getVideoStreamControlsClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return '';
      case 'medium':
        return 'videoStreamControlsMedium';
      case 'large':
        return 'videoStreamControlsLarge';
    }
  };

  const getStopRecordingButtonClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return '';
      case 'medium':
        return 'stopRecordingButtonMedium';
      case 'large':
        return 'stopRecordingButtonLarge';
    }
  };

  const printState = (step: string) => {
    const stateSnap = [
      'countDownTimer: ' + countDownTimer,
      'timerActive: ' + timerActive,
      'triggerRecordingPermission: ' + triggerRecordingPermission,
      'triggerStartRecording: ' + triggerStartRecording,
      'showCountDown: ' + showCountDown,
      'triggerStopRecording: ' + triggerStopRecording,
      'recordingInProgress: ' + recordingInProgress,
    ];

    const stepData = JSON.parse(localStorage.getItem(step) || '[]');

    stepData.push(stateSnap);
    localStorage.setItem(step, JSON.stringify(stepData));
  };

  const firebaseInstance: any = firebase;
  let firebaseToken = '';

  const getFirebaseToken = async () => {
    if (firebaseInstance && firebaseInstance.auth() && firebaseInstance.auth().currentUser) {
      firebaseToken = await firebaseInstance.auth().currentUser.getIdToken(true);
      connectServer(firebaseToken);
    }
  };

  const videoStreamControls =
    hideDialogContent &&
    (cameraState || screenState) &&
    (cameraState ? (
      <Draggable>
        <div className={`videoStreamControls ${getVideoStreamControlsClass()}`}>
          <div className='disableCameraFeed' onClick={() => setCameraState(!cameraState)}>
            <DisableCameraIcon />
          </div>
          <div className={`videoStreamContainer ${getVideoStreamContainerClass()}`}>
            <video
              className={getVideoStreamTagClass()}
              ref={(input) => {
                videoTag = input;
              }}
              autoPlay
            />
          </div>

          <div className='videoStreamSizes'>
            <div
              className={`videoStreamSmall ${(videoStreamSize === 'small' &&
                'videoStreamSelectedOption') ||
                ''}`}
              onClick={() => setVideoStreamSize('small')}
            ></div>
            <div
              className={`videoStreamMedium  ${(videoStreamSize === 'medium' &&
                'videoStreamSelectedOption') ||
                ''}`}
              onClick={() => setVideoStreamSize('medium')}
            ></div>
            <div
              className={`videoStreamLarge  ${(videoStreamSize === 'large' &&
                'videoStreamSelectedOption') ||
                ''}`}
              onClick={() => setVideoStreamSize('large')}
            ></div>
          </div>
          {hideDialogContent && shouldStartRecording ? (
            <Tooltip placement='right' title='Stop Recording'>
              <div
                className={`stopRecordingButton ${getStopRecordingButtonClass()}`}
                onClick={() => {
                  setTriggerStopRecording(true);
                }}
              ></div>
            </Tooltip>
          ) : null}
          <div className='videoDuration'>
            {isTimer ? (
              <RecordingTimer
                hideText
                startTimer={shouldStartTimer}
                maxRecordTime={maxRecordTime}
              />
            ) : (
              <span>{secondsToHms(maxRecordTime * 60)}</span>
            )}
          </div>
        </div>
      </Draggable>
    ) : (
      <Draggable>
        <div className='screenRecordingControls'>
          <Tooltip placement='top' title='Add Camera'>
            <div className='addVideoStream' onClick={() => setCameraState(!cameraState)}>
              <CameraIcon color='#4F4F4F' />
            </div>
          </Tooltip>
          <div className='videoDuration chrome-extension-screen-recording'>
            {isTimer ? (
              <RecordingTimer
                hideText
                startTimer={shouldStartTimer}
                maxRecordTime={maxRecordTime}
              />
            ) : (
              <span>{secondsToHms(maxRecordTime * 60)}</span>
            )}
          </div>
          <Tooltip placement='top' title='Stop Recording'>
            <div
              className='stopScreenRecordingButton'
              onClick={() => {
                setTriggerStopRecording(true);
              }}
            ></div>
          </Tooltip>
          <div className='draggableIcon'>
            <DraggableIcon color='#4F4F4F' />
          </div>
        </div>
      </Draggable>
    ));

  const getVideoSize = () => {
    switch (fullScreenSize) {
      case 'large':
        return '';
      case 'medium':
        return 'mediumFullScreenVideo';
      case 'small':
        return 'smallFullScreenVideo';
    }
  };

  const fullScreenVideoStream = hideDialogContent && (
    <div className='fullScreenVideoContainer'>
      {cameraState ? (
        <video
          className={`fullScreenVideo ${getVideoSize()}`}
          ref={(input) => {
            videoTag = input;
          }}
          autoPlay
        />
      ) : null}
      {screenState && (
        <div className='recording-screen-icon'>
          <ScreenIcon />
        </div>
      )}
      <div
        className='recording-cancel-icon cursor-pointer'
        onClick={() => {
          ShowNewVideoCanvas && ShowNewVideoCanvas(false);
        }}
      >
        <Close />
      </div>
      {/* {showCountDown && <div className="recording-start-animation">
        <div className="recording-start-animation-count">
          {countDownTimer}
        </div>
        <div className="recording-start-animation-text">
          Click anywhere to cancel recording
        </div>
      </div>
      } */}
      {showCountDown && (
        <div style={{ position: 'relative' }}>
          <div ref={counterText} className='recordingCountDown'></div>
        </div>
      )}
      <>
        {cameraState && (
          <div className='fullScreenSizes'>
            <div
              className={`fullScreenButtons smallFullScreen ${fullScreenSize === 'small' &&
                'selectedFullScreen'}`}
              onClick={() => setFullScreenSize('small')}
            ></div>
            <div
              className={`fullScreenButtons mediumFullScreen  ${fullScreenSize === 'medium' &&
                'selectedFullScreen'}`}
              onClick={() => setFullScreenSize('medium')}
            ></div>
            <div
              className={`fullScreenButtons largeFullScreen  ${fullScreenSize === 'large' &&
                'selectedFullScreen'}`}
              onClick={() => setFullScreenSize('large')}
            ></div>
          </div>
        )}
        {!isRecordingStarted ? (
          <div className='fullScreenVideoControls'>
            <div
              className='videoStreamButtons'
              onClick={() => {
                cameraState && setHideDialogContent(false);
                setCameraState(!cameraState);
              }}
              style={{ backgroundColor: cameraState ? '#ECF5FF' : '#fff' }}
            >
              <CameraIcon color={(cameraState && '#001DAD') || '#828282'} />
            </div>
            <div
              className='videoStreamButtons'
              onClick={() => {
                setScreenState(!screenState);
                const windowObj: any = window;
                if (windowObj && !windowObj.isSocketConnected) {
                  notification.error({
                    message:
                      'Something went wrong with socket connection, Please try again or contact with admin.',
                  });
                  return false;
                } else {
                  // setShouldStartRecording(true);
                  setScreenState(!screenState);
                }
              }}
              style={{ backgroundColor: screenState ? '#ECF5FF' : '#fff' }}
            >
              <ScreenIcon color={(screenState && '#001DAD') || '#828282'} />
            </div>
            <div
              className='videoStreamButtons'
              onClick={() => setMicrophoneState(!microphoneState)}
              style={{ backgroundColor: microphoneState ? '#ECF5FF' : '#fff' }}
            >
              <MicrophoneIcon color={(microphoneState && '#001DAD') || '#828282'} />
            </div>
            <div style={{ height: 52 }}>
              <Button
                className='startRecordingButton startButtonBig'
                onClick={() => {
                  const windowObj: any = window;
                  if (windowObj && !windowObj.isSocketConnected) {
                    notification.error({
                      message:
                        'Something went wrong with socket connection, Please try again or contact with admin.',
                    });
                    return false;
                  } else {
                    if (auth && auth.uid && userQuota && userQuota.storageLimit <= 0) {
                      notification.error({
                        message: 'Your current plan doesn’t include using this feature.',
                      });
                    } else {
                      if (
                        cameraState &&
                        screenState &&
                        userQuota &&
                        (userQuota.totalVideoComments <= 0 || userQuota.totalScreenRecordings <= 0)
                      ) {
                        notification.error({
                          message: 'Your current plan doesn’t include using this feature.',
                        });
                      } else if (cameraState && userQuota && userQuota.totalVideoComments <= 0) {
                        notification.error({
                          message: 'Your current plan doesn’t include using this feature.',
                        });
                      } else if (screenState && userQuota && userQuota.totalScreenRecordings <= 0) {
                        notification.error({
                          message: 'Your current plan doesn’t include using this feature.',
                        });
                      } else {
                        setShouldStartRecording(true);
                      }
                    }
                  }
                }}
                disabled={timerActive}
                loading={timerActive}
              >
                Start Recording
              </Button>
            </div>
          </div>
        ) : (
          <div className='fullScreenVideoControls start-recording-bottom-control'>
            <div className='play-recording-container'>
              <div className='play-recording-circle'></div>
              <div className='play-recording-timer'>
                {isTimer ? (
                  <RecordingTimer
                    hideText
                    startTimer={shouldStartTimer}
                    maxRecordTime={maxRecordTime}
                  />
                ) : (
                  <span>{secondsToHms(maxRecordTime * 60)}</span>
                )}
              </div>
            </div>
            <div style={{ height: 52 }}>
              <Button
                className='startRecordingButton startButtonBig'
                onClick={() => {
                  setTriggerStopRecording(true);
                }}
                disabled={triggerStopRecording}
                loading={triggerStopRecording}
              >
                Stop Recording
              </Button>
            </div>
          </div>
        )}
      </>
    </div>
  );
  return (
    <>
      <div className='dialogBodyContainer'>
        {!isAudioRecording ? (
          <>
            {/* {isChromeExtensionInstalled && videoStreamVisiblity */}
            {isChromeExtensionInstalled ? videoStreamControls : fullScreenVideoStream}
            <div
              className={(cameraState && 'videoDialogButtonEnabled') || 'videoDialogButtonDisabled'}
              onClick={() => {
                if (
                  auth &&
                  auth.uid &&
                  userQuota &&
                  userQuota.storageLimit > 0 &&
                  userQuota.totalVideoComments &&
                  userQuota.totalVideoComments > 0
                ) {
                  setCameraState(!cameraState);
                  !cameraState && setHideDialogContent(true);
                } else {
                  notification.error({
                    message: 'Your current plan doesn’t include using this feature.',
                  });
                }
              }}
            >
              <div>
                <CameraIcon color={(cameraState && '#001DAD') || '#828282'} />
              </div>
              <div>Camera</div>
            </div>
            {!isMobile && (
              <div
                className={
                  (screenState && 'videoDialogButtonEnabled') || 'videoDialogButtonDisabled'
                }
                onClick={() => setScreenState(!screenState)}
              >
                <div>
                  <ScreenIcon color={(screenState && '#001DAD') || '#828282'} />
                </div>
                <div>Screen</div>
              </div>
            )}
            <div
              className={
                (microphoneState && 'videoDialogButtonEnabled') || 'videoDialogButtonDisabled'
              }
              onClick={() => setMicrophoneState(!microphoneState)}
            >
              <div>
                <MicrophoneIcon color={(microphoneState && '#001DAD') || '#828282'} />
              </div>
              <div>Microphone</div>
            </div>
          </>
        ) : (
          <AudioRecorderAddProject
            maxRecordTime={(userQuota && userQuota.recordVoiceClipTime) || 10}
            projectID={'dasdasd'}
            onClose={() => {
              setIsAudioRecording(false);
            }}
            showPercentage
            onSubmit={(audioUrl: string) => createVideoTask(audioUrl, 'audio', '')}
            background={'rgb(236, 245, 255)'}
            isSmartComment
            profile={profile}
            auth={auth}
            selectedOrganizationData={props.organization.selectOrganizationDetails}
          />
        )}
      </div>
      {!isAudioRecording ? (
        <div className='dialogFooter'>
          <div className='dialogCTAsContainer'>
            <div className='dialogSettingsIcon'>
              {/* {!props.chromeExtension && <SettingsIcon />} */}
            </div>
            <div className='dialogCTAButtons'>
              <div>
                <Button
                  className='cancelButton'
                  onClick={() => {
                    props.chromeExtension
                      ? window.parent.postMessage({ url: '' }, '*')
                      : ShowNewVideoCanvas(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  // disabled={
                  //  profile.plan === "freelancer" || !(cameraState || microphoneState || screenState) || isRecordingStarted || isTimer
                  // }
                  className={
                    cameraState || microphoneState || screenState
                      ? 'startRecordingButton'
                      : 'startRecordingButtonDisabled'
                  }
                  onClick={async () => {
                    const windowObj: any = window;
                    if (windowObj && !windowObj.isSocketConnected) {
                      notification.error({
                        message:
                          'Something went wrong with socket connection, Please try again or contact with admin.',
                      });
                      return false;
                    } else if (!screenState && !cameraState) {
                      if (auth && auth.uid && userQuota && userQuota.storageLimit <= 0) {
                        notification.error({
                          message: 'Your current plan doesn’t include using this feature.',
                        });
                      } else if (userQuota && userQuota.totalVoiceClips <= 0) {
                        notification.error({
                          message: 'Your current plan doesn’t include using this feature.',
                        });
                      } else {
                        setIsAudioRecording(true);
                      }
                    } else {
                      if (auth && auth.uid && userQuota && userQuota.storageLimit <= 0) {
                        notification.error({
                          message: 'Your current plan doesn’t include using this feature.',
                        });
                      } else {
                        if (
                          cameraState &&
                          screenState &&
                          userQuota &&
                          (userQuota.totalVideoComments <= 0 ||
                            userQuota.totalScreenRecordings <= 0)
                        ) {
                          notification.error({
                            message: 'Your current plan doesn’t include using this feature.',
                          });
                        } else if (cameraState && userQuota && userQuota.totalVideoComments <= 0) {
                          notification.error({
                            message: 'Your current plan doesn’t include using this feature.',
                          });
                        } else if (
                          screenState &&
                          userQuota &&
                          userQuota.totalScreenRecordings <= 0
                        ) {
                          notification.error({
                            message: 'Your current plan doesn’t include using this feature.',
                          });
                        } else {
                          screenState && setTriggerRecordingPermission(true);
                          setHideDialogContent(true);
                          printState('1-Start-Recording-Clicked');
                          setShouldStartRecording(true);
                        }
                      }
                    }
                  }}
                >
                  Start Recording
                </Button>

                {/* {showCountDown && (
                <div className='recordingCountDown'>
                  <div>{countDownTimer}</div>
                </div>
              )} */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {(triggerRecordingPermission || shouldStartRecording) && !isReleaseCamera && (
        <ReactMediaRecorder
          fileName={fileName}
          video={cameraState}
          screen={screenState}
          audio={microphoneState}
          onStop={(BlobUrl) => {
            setIsReleaseCamera(true);
            let isUpdate = false;
            if (cameraState && screenState) {
              if (
                auth &&
                auth.uid &&
                userQuota &&
                userQuota.storageLimit > 0 &&
                userQuota.totalVideoComments &&
                userQuota.totalVideoComments > 0 &&
                userQuota.totalScreenRecordings > 0
              ) {
                userQuota.totalScreenRecordings = userQuota.totalScreenRecordings - 1;
                userQuota.totalVideoComments = userQuota.totalVideoComments - 1;
                isUpdate = true;
              }
            } else if (cameraState) {
              if (
                auth &&
                auth.uid &&
                userQuota &&
                userQuota.storageLimit > 0 &&
                userQuota.totalVideoComments &&
                userQuota.totalVideoComments > 0
              ) {
                userQuota.totalVideoComments = userQuota.totalVideoComments - 1;
                isUpdate = true;
              }
            } else if (screenState) {
              if (
                auth &&
                auth.uid &&
                userQuota &&
                userQuota.storageLimit > 0 &&
                userQuota.totalScreenRecordings &&
                userQuota.totalScreenRecordings > 0
              ) {
                userQuota.totalScreenRecordings = userQuota.totalScreenRecordings - 1;
                isUpdate = true;
              }
            }
            if (isUpdate) {
              const firestore = firebase.firestore();
              firestore
                .collection('users')
                .doc(auth.uid)
                .update({
                  userQuota,
                });
              createVideoTask(videoURL, 'videoRecord', BlobUrl);
            }
          }}
          // render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream }) => {
          render={({ status, startRecording, stopRecording, previewStream }) => {
            streamToBeClearedRef.current = previewStream;

            if (triggerStopRecording && status === 'recording') {
              stopRecording();
              setTriggerStopRecording(true);
              setRecordingInProgress(false);
              setTriggerStartRecording(false);
            }

            if (
              !isRecordingStarted &&
              previewStream &&
              previewStream.active &&
              !triggerStopRecording &&
              status === 'idle'
            ) {
              setShouldStartRecording(true);
              setIsRecordingStarted(true);
              setShowCountDown(true);
              setTimerActive(true);
              startSendingChunks(fileName);
              setTimeout(() => {
                shouldStartTimer(true);
                startRecording();
                setShowCountDown(false);
                setTimerActive(false);
              }, 2500);
            }
            return <></>;
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firebaseAuthToken: state.auth.firebaseAuthToken,
    canvas: state.canvas,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    organization: state.organization,
  };
};

export default connect(mapStateToProps)(VideoDialogContent) as any;

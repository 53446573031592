import React from 'react';

export default () => (
  <svg width='26' height='34' viewBox='0 0 26 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M25.6528 8.52277L17.7051 0.356696C17.4835 0.129018 17.1844 0 16.8705 0H1.18182C0.528125 0 0 0.542634 0 1.21429V32.7857C0 33.4574 0.528125 34 1.18182 34H24.8182C25.4719 34 26 33.4574 26 32.7857V9.38415C26 9.06161 25.8744 8.75045 25.6528 8.52277ZM23.2744 9.94197H16.3239V2.80045L23.2744 9.94197ZM23.3409 31.2679H2.65909V2.73214H13.8125V10.9286C13.8125 11.3513 13.9759 11.7566 14.2668 12.0555C14.5577 12.3544 14.9522 12.5223 15.3636 12.5223H23.3409V31.2679ZM12.7045 21.0223H5.90909C5.74659 21.0223 5.61364 21.1589 5.61364 21.3259V23.1473C5.61364 23.3143 5.74659 23.4509 5.90909 23.4509H12.7045C12.867 23.4509 13 23.3143 13 23.1473V21.3259C13 21.1589 12.867 21.0223 12.7045 21.0223ZM5.61364 16.1652V17.9866C5.61364 18.1536 5.74659 18.2902 5.90909 18.2902H20.0909C20.2534 18.2902 20.3864 18.1536 20.3864 17.9866V16.1652C20.3864 15.9982 20.2534 15.8616 20.0909 15.8616H5.90909C5.74659 15.8616 5.61364 15.9982 5.61364 16.1652Z'
      fill='#9B9B9B'
    />
  </svg>
);

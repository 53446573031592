const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;
// http://localhost:5000/feedback-hero/us-central1

export const jiraConnectAction = (firebaseToken: string) => {
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/jiraConnect`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return { error: false, message: `Your jira account has been connected successfully.` };
      } else {
        return { error: true, message: data.message };
      }
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

export const getMasterData = (firebaseToken: string, endPoint: string) => {
  const postData = { endPoint };
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/getJiraMaterData`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(postData),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return data.data;
      } else {
        return [];
      }
    });
};

export const postJiraData = (firebaseToken: string, endPoint: string, postBodyData: any) => {
  const postData = { endPoint, postBodyData };
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/postJiraData`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(postData),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return {
          error: false,
          message: data.message ? data.message : 'Jira issue created successfully.',
          data: data.data,
        };
      } else {
        return { error: true, message: data.message ? data.message : 'Something went wrong.' };
      }
    });
};

import { Modal } from 'antd';
import React from 'react';
import { Zbutton } from '../../primitives';

export const AlertModal = ({
  title,
  message,
  visible,
  handleOk,
}: {
  title: string;
  message: string;
  visible: boolean;
  handleOk: any;
}) => {
  return (
    <Modal visible={visible} title={title} onOk={handleOk} onCancel={handleOk} footer={null}>
      <div className='container'>
        <p>{message}</p>
        <Zbutton key='submit' primary onClick={handleOk}>
          Ok
        </Zbutton>
      </div>
    </Modal>
  );
};

import { Drawer, Icon, Menu, Modal } from 'antd';
import Dialog from 'components/newCanvas/Dialog';
// import VideoStreamControls from 'components/newCanvas/VideoStreamControls';
import firebase from 'firebase/app';
import { Zmenu } from 'primitives';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import {
  checkChromeExtensionAndSendToken,
  createThumbnailImg,
  defaultTag,
  isDataURL,
  mobileCheck,
  sendImageToStore,
  trackAnalytics,
  showFullFeature,
  getLastSelectedWorkflowAndMasterProjectId
} from 'utils';
import { v4 as uuid } from 'uuid';
import ColorContext from '../../context/ColorContext';
import { Zbutton } from '../../primitives';
import { updateSelectOrganizationDetails } from '../../store/actions/organizationAction';
import { deleteProject } from '../../store/actions/projectActions';
import { createProject as createProjectAction } from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import {
  IMasterProject,
  IOrgProject,
  IProject,
  IProjectCreate,
  Iworkflow,
  IworkflowCanvas,
} from '../../types/projectTypes';
import DeletedUser from '../auth/DeletedUser';
import Notifications from '../notifications';
import ChatWindowNew from '../projects/Chat/ChatWindowNew';
import ProjectList from '../projects/ProjectList';
import { ArchivedIcon } from '../svg';
import UserDrawer from '../users';
import DashboardButtons from './DashboardButtons';
import DashboardKanban from './DashboardKanban';
import Filter from './filter';
import MasterProjects from './MasterProjects';
import MemberListModel from './MemberListModel';
import Members from './Members';
import MobileDashboard from './MobileDashboard';
import NewSelectDropdown from './NewSelectDropdown';
import ProfileMenu from './ProfileMenu';
import ProjectForm from './ProjectForm';
import ProjectSelectDropdown from './ProjectSelectDropdown';
import './style.css';
import TagsDrawer from './tags/TagsDrawer';
import Workflow from './Workflow';
import WorkflowDrawer from './WorkflowDrawer';
import CopyVoiceClipModal from '../shared/CopyVoiceClipModal';

const isMobile = mobileCheck();

interface IOwnProps {
  profile: any;
  projects: IProject[];
  auth?: any;
  isWriteAccess: boolean;
  signUpDays: number;
  masterProjects: IMasterProject[];
  selectedMasterProject: IMasterProject;
  history: any;
  match: any;
  updateSelectOrganizationDetails: any;
  createProject: any;
  firebaseAuthToken: any;
  deleteProject(projectId: string): void;
  dispatch(): void;
}

// interface IState {
//   selfProjects: any[];
//   loader: boolean;
//   projectFormVisible: boolean;
//   projectFormIsEdit: boolean;
//   selfMasterProjects: IMasterProject[];
//   selectedMasterProject: IMasterProject;
//   memberListModelVisible: boolean;
//   loginUserRole: string;
// }
let unsubscribeMasterProjectListner: any = '';
class Dashboard extends Component<IOwnProps, any> {
  state = {
    isCopyVoiceClipModal: false,
    organizationDetail: [],
    selectedOrgProject: {
      id: '',
      projectColors: {},
      logoUrl: '',
      projectName: '',
    },
    masterProjectId: '',
    selfProjects: [],
    loader: true,
    projectFormVisible: false,
    projectFormIsEdit: false,
    selfMasterProjects: [],
    selectedMasterProject: {
      id: '',
      name: '',
      created_by: '',
      workflow: [],
      members: [],
      member_emails: [],
      setTagDrawerVisible: [],
      member_ids: [],
      tags: [],
    },
    chatData: {
      messages: [],
      count: 0,
      chatId: '',
      prevChatId: '',
    },
    workflowCanvas: [
      {
        canvases: [
          {
            createdAt: '',
          },
        ],
        loadingMoreCanvas: true,
        isMoreCanvas: true,
        id: '',
        color: '',
        step: '',
        total: 0,
      },
    ],
    oldWorkflowCanvas: [
      {
        canvases: [
          {
            createdAt: '',
          },
        ],
        loadingMoreCanvas: true,
        isMoreCanvas: true,
        id: '',
        color: '',
        step: '',
      },
    ],
    memberListModelVisible: false,
    loginUserRole: '',
    paginationLimit: 200,
    selectedWorkflowId: '',
    WorkflowDrawerVisible: false,
    tagsDrawerVisible: false,
    isArchived: 'no',
    archivedCanvases: [],
    selectedOrganizationIndex: 0,
    chatWindowMobile: false,
    projectControls: false,
    userDrawerVisible: false,
    onboardingVideoVisibility: true,
  };

  changeOrganization = (index: number) => {
    this.setState({
      selectedOrganizationIndex: index,
    });
  };
  openInviteMemberModal = () => {
    this.setState({
      userDrawerVisible: true,
    });
  };

  checkMasterProjectIdInSelectedMasterProject = (selfMasterProjectsArray: IMasterProject[]) => {
    const selectedMasterProjectId: string | null = window.localStorage.getItem(
      'selectedMasterProjectId',
    );
    if (selectedMasterProjectId) {
      const selectedMasterProjectIndex = selfMasterProjectsArray.findIndex(
        (mp: any) => mp.id === selectedMasterProjectId,
      );
      if (selectedMasterProjectIndex !== -1) {
        return selectedMasterProjectIndex;
      } else {
        return -1;
      }
    } else {
      return -1;
    }
  };

  checkWorkflowIdInSelectedProject = (workflowCanvas: IworkflowCanvas[]) => {
    const workflowId: string | null = window.localStorage.getItem('selectedWorkflowId');
    if (workflowId) {
      const workflowIndex = workflowCanvas.findIndex((wf: any) => wf.id === workflowId);
      if (workflowIndex !== -1) {
        return workflowId;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  checkOrgProjectIdInSelectedOrgProject = (orgProject: IOrgProject[]) => {
    const orgProjectId: string | null = window.localStorage.getItem('selectedProjectId');
    if (orgProjectId) {
      const orgProjectIndex = orgProject.findIndex((op: any) => op.id === orgProjectId);
      if (orgProjectIndex !== -1) {
        return orgProjectIndex;
      } else {
        return -1;
      }
    }
  };

  workSpaceData = (selfMasterProjectsSnap: any) => {
    if (
      selfMasterProjectsSnap &&
      selfMasterProjectsSnap.docs &&
      selfMasterProjectsSnap.docs.length
    ) {
      let selfMasterProjects = selfMasterProjectsSnap.docs.map((doc: any) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });
      if (this.props.match.params.id) {
        const matchedProject = selfMasterProjects.find(
          (ele: IMasterProject) => ele.id === this.props.match.params.id,
        );
        const updatedTimeStamp = (matchedProject && matchedProject.updatedTimeStamp) || 'no';
        const lastUpdatedTimeStamp = window.localStorage.getItem('updatedTimeStamp');
        if (updatedTimeStamp.toString() === lastUpdatedTimeStamp) {
          return true;
        }
      }
      selfMasterProjects = selfMasterProjects.sort((a: IMasterProject,b: IMasterProject) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
      this.setState({
        selfMasterProjects,
      });
      if (!this.props.match.params.id && this.props.match.path !== '/old') {
        // chat data populate
        // selfMasterProjects[0].chatId &&
        //   firestore
        //     .collection('chat')
        //     .doc(selfMasterProjects[0].chatId)
        //     .onSnapshot((chatDataProject: any) => {
        //       this.setState({
        //         chatData: { prevChatId: '', chatId: selfMasterProjects[0].chatId, ...chatDataProject.data() },
        //       });
        //     });

        /*
        Match selfMasterProjects with selectedMasterProjectId
        if matched then use matched selfMasterProject otherwise selfMasterProjects[0]
        and get matched selfMasterProject workflows with selectedWorkflowId
        if matched then use matched selectedWorkflowId otherwise first
        */
        let localstorageMasterProjectIndex: number = this.checkMasterProjectIdInSelectedMasterProject(
          selfMasterProjects || [],
        );
        localstorageMasterProjectIndex =
          localstorageMasterProjectIndex !== -1 ? localstorageMasterProjectIndex : 0;

        let localstorageWorkflowId: string = this.checkWorkflowIdInSelectedProject(
          selfMasterProjects[localstorageMasterProjectIndex].workflow || [],
        );
        localstorageWorkflowId = localstorageWorkflowId
          ? localstorageWorkflowId
          : selfMasterProjects[localstorageMasterProjectIndex].workflow[0].id;

        this.setState({
          selectedMasterProject: selfMasterProjects[localstorageMasterProjectIndex],
          workflowCanvas: JSON.parse(
            JSON.stringify(selfMasterProjects[localstorageMasterProjectIndex].workflow || []),
          ),
        });
        this.loginUserProjectRole(selfMasterProjects[localstorageMasterProjectIndex]);
        if (selfMasterProjects[localstorageMasterProjectIndex].workflow && localstorageWorkflowId) {
          const projectWorkflow = JSON.parse(
            JSON.stringify(selfMasterProjects[localstorageMasterProjectIndex].workflow || []),
          );
          this.getCanvases(localstorageWorkflowId, projectWorkflow);
          this.setState({
            selectedWorkflowId: localstorageWorkflowId,
          });
        }

        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type') || '';
        this.props.history.push(
          `/pt/${selfMasterProjects[localstorageMasterProjectIndex].id}${type ? '?type=' + type : ''
          }`,
        );
      } else {
        let matchedProject = selfMasterProjects.find(
          (ele: IMasterProject) => ele.id === this.props.match.params.id,
        );
        matchedProject =
          matchedProject && matchedProject.id ? matchedProject : selfMasterProjects[0];
        let localstorageWorkflowId: string = this.checkWorkflowIdInSelectedProject(
          matchedProject.workflow || [],
        );
        // chat data populate when project change
        // matchedProject.chatId &&
        //   firestore
        //     .collection('chat')
        //     .doc(matchedProject.chatId)
        //     .onSnapshot((chatDataProject: any) => {
        //       this.setState({
        //         chatData: { prevChatId: '', chatId: matchedProject.chatId, ...chatDataProject.data() },
        //       });
        //     });

        this.setState({
          selectedMasterProject: matchedProject,
          workflowCanvas: JSON.parse(JSON.stringify(matchedProject.workflow || [])),
        });
        this.loginUserProjectRole(matchedProject);
        if (this.props.match.path === '/pk/:id') {
          matchedProject &&
            matchedProject.workflow &&
            matchedProject.workflow.map((workflowStep: Iworkflow) => {
              this.getCanvases(workflowStep.id, matchedProject.workflow || []);
            });
          if(window.localStorage.getItem('selectedWorkflowId')){
            this.setState({
              selectedWorkflowId: window.localStorage.getItem('selectedWorkflowId')
            });
          }
        } else if (
          matchedProject.workflow &&
          matchedProject.workflow[0] &&
          matchedProject.workflow[0].id
        ) {
          localstorageWorkflowId = localstorageWorkflowId
            ? localstorageWorkflowId
            : matchedProject.workflow[0].id;
          const projectWorkflow = JSON.parse(JSON.stringify(matchedProject.workflow || []));
          this.getCanvases(localstorageWorkflowId, projectWorkflow);
          this.setState({
            selectedWorkflowId: localstorageWorkflowId,
          });
          window.localStorage.setItem('selectedMasterProjectId', matchedProject.id);
          window.localStorage.setItem('selectedWorkflowId', localstorageWorkflowId);
          getLastSelectedWorkflowAndMasterProjectId();
        }
      }
    } else {
      this.setState({
        selfMasterProjects: [],
        selectedMasterProject: {},
        workflowCanvas: [],
      });
    }
  };

  getOrgProjects = () => {
    const firestore = firebase.firestore();
    firestore
      .collection('organization')
      .where('member_ids', 'array-contains', this.props.auth.uid)
      .onSnapshot((organizationData: any) => {
        // .get()
        // .then((organizationData: any) => {
        if (organizationData && organizationData.docs && organizationData.docs.length) {
          let organizationDetail = organizationData.docs.map((doc: any) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          });

          organizationDetail = organizationDetail.map((organizationDetailData: any) => {
            organizationDetailData.projects = organizationDetailData.projects.filter(
              (organizationDataProjects: any) => {
                return organizationDataProjects && organizationDataProjects.isDeleted !== true;
              },
            );
            return organizationDetailData;
          });
          this.setState({
            organizationDetail,
          });
          const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');

          let selectedOrganizationMatchedIndex = organizationDetail.findIndex(
            (og: any) => og.id === selectedOrganizationId,
          );
          if (selectedOrganizationMatchedIndex === -1) {
            selectedOrganizationMatchedIndex = 0;
            window.localStorage.setItem('selectedOrganizationId', organizationDetail[0].id);
          }
          this.props.updateSelectOrganizationDetails(
            organizationDetail[selectedOrganizationMatchedIndex],
          );
          this.setState({
            selectedOrganizationIndex: selectedOrganizationMatchedIndex,
          });
          let localstorageOrgProjectIndex: any = this.checkOrgProjectIdInSelectedOrgProject(
            organizationDetail[selectedOrganizationMatchedIndex].projects || [],
          );
          localstorageOrgProjectIndex =
            localstorageOrgProjectIndex !== -1 ? localstorageOrgProjectIndex : 0;

          if (localstorageOrgProjectIndex) {
            const projectId =
              organizationDetail[selectedOrganizationMatchedIndex].projects[
                localstorageOrgProjectIndex
              ].id;
            window.localStorage.setItem('selectedProjectId', projectId);
            this.setState({
              selectedOrgProject:
                organizationDetail[selectedOrganizationMatchedIndex].projects[
                localstorageOrgProjectIndex
                ],
            });
            unsubscribeMasterProjectListner && unsubscribeMasterProjectListner();
            unsubscribeMasterProjectListner = firestore
              .collection('masterProjects')
              // .where('member_emails', 'array-contains', this.props.auth.email)
              .where('projectId', '==', projectId)
              .where('member_ids', 'array-contains-any', [this.props.auth.uid, this.props.auth.email])
              //.where('member_ids', 'array-contains', this.props.auth.uid)
              .onSnapshot((selfMasterProjectsSnap: any) => {
                // .get()
                // .then((selfMasterProjectsSnap: any) => {
                this.workSpaceData(selfMasterProjectsSnap);
              });
          } else {
            if (
              organizationDetail &&
              organizationDetail[selectedOrganizationMatchedIndex] &&
              organizationDetail[selectedOrganizationMatchedIndex].projects &&
              organizationDetail[selectedOrganizationMatchedIndex].projects.length
            ) {
              const projectId = organizationDetail[selectedOrganizationMatchedIndex].projects[0].id;
              window.localStorage.setItem('selectedProjectId', projectId);
              this.setState({
                selectedOrgProject:
                  organizationDetail[selectedOrganizationMatchedIndex].projects[0],
              });
              unsubscribeMasterProjectListner && unsubscribeMasterProjectListner();
              unsubscribeMasterProjectListner = firestore
                .collection('masterProjects')
                // .where('member_emails', 'array-contains', this.props.auth.email)
                .where('projectId', '==', projectId)
                .where('member_ids', 'array-contains-any', [this.props.auth.uid, this.props.auth.email])
                // .where('member_ids', 'array-contains', this.props.auth.uid)
                .onSnapshot((selfMasterProjectsSnap: any) => {
                  // .get()
                  // .then((selfMasterProjectsSnap: any) => {
                  this.workSpaceData(selfMasterProjectsSnap);
                });
            }
          }
        } 
          // else {
          // firestore
          //   .collection('masterProjects')
          //   // .where('member_emails', 'array-contains', this.props.auth.email)
          //   .where('member_ids', 'array-contains', this.props.auth.uid)
          //   .onSnapshot((selfMasterProjectsSnap: any) => {
          //     this.workSpaceData(selfMasterProjectsSnap)
          //   });
          // }
      });
  };

  getArchivedCanvases = () => {
    if (this.props.match.params.id) {
      const firestore = firebase.firestore();
      firestore
        .collection('projects')
        .where('masterProjectId', '==', this.props.match.params.id)
        //  .where('member_ids', 'array-contains', this.props.auth.uid)
        //  .where('project_created_by', '==', this.props.auth.uid)
        .where('archived', '==', 'yes')
        .orderBy(
          this.props.match.path === '/pt/:id' ? 'createdAt' : 'sequenceNumber',
          this.props.match.path === '/pt/:id' ? 'desc' : 'asc',
        )
        .get()
        .then((canvasesSnap: any) => {
          if (canvasesSnap && canvasesSnap.docs && canvasesSnap.docs.length) {
            const archivedCanvases = canvasesSnap.docs.map((doc: any) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            });
            this.setState({
              archivedCanvases,
            });
          } else {
            this.setState({
              archivedCanvases: [],
            });
          }
        });
    }
  };

  filterCanvases = (tags: any, members: any) => {
    const { oldWorkflowCanvas } = this.state;
    let filterWorkflowCanvases: any = JSON.parse(JSON.stringify(oldWorkflowCanvas));
    const selectedMembers = members.filter((member: any) => member.isChecked);
    const membersIds = selectedMembers.map((selectedMember: any) => selectedMember.id);
    const selectedTags = tags.filter((tag: any) => tag.isChecked);
    const tagIds = selectedTags.map((selectedTag: any) => selectedTag.id);
    if (membersIds.length) {
      filterWorkflowCanvases = filterWorkflowCanvases.map((filterCanvas: any) => {
        const filterCanvases = filterCanvas.canvases
          ? filterCanvas.canvases.filter((canvas: any) => {
            const selectedMembersIds = canvas.selectedMembersIds || [];
            if (selectedMembersIds.some((arr1: any) => membersIds.includes(arr1))) {
              return canvas;
            }
          })
          : [];
        filterCanvas.canvases = filterCanvases;
        return filterCanvas;
      });
    }

    if (tagIds.length) {
      filterWorkflowCanvases = filterWorkflowCanvases.map((filterCanvas: any) => {
        const filterTagCanvases = filterCanvas.canvases
          ? filterCanvas.canvases.filter((canvas: any) => {
            const selectedTagsIds = canvas.selectedtagsIds || [];
            if (selectedTagsIds.some((arr1: any) => tagIds.includes(arr1))) {
              return canvas;
            }
          })
          : [];
        filterCanvas.canvases = filterTagCanvases;
        return filterCanvas;
      });
    }
    this.setState({
      workflowCanvas: filterWorkflowCanvases,
    });
  };

  getCanvases = (workflowId: string, workflowArray: IworkflowCanvas[] = []) => {
    const { paginationLimit, workflowCanvas = [], isArchived } = this.state;
    if (isArchived === 'yes') {
      this.setState({
        isArchived: 'no',
      });
    }
    let workflowCanvasArray = JSON.parse(JSON.stringify(workflowArray));
    if (workflowCanvas && workflowCanvas[0] && workflowCanvas[0].id) {
      workflowCanvasArray = workflowCanvas;
    }
    const workflowIndex = workflowCanvasArray.findIndex((wf: any) => wf.id === workflowId);
    if (workflowIndex !== -1) {
      // workflowCanvasArray[workflowIndex].loadingMoreCanvas = false;
      // this.setState({
      //   workflowCanvas: workflowCanvasArray,
      // });
      const firestore = firebase.firestore();
      firestore
        .collection('projects')
        //  .where('selectedMembersIds', 'array-contains-any', ["KhmCtoADQffAemzffDU2KCXMnH42"])
        //  .where('selectedtagsIds', 'array-contains-any', ["d7051429-17a0-496b-992a-90511755b19b", "846b5cee-9215-4979-a9e1-b4369dec0c77"])
        //  .where('member_ids', 'array-contains', this.props.auth.uid)
        //  .where('project_created_by', '==', this.props.auth.uid)
        .where('workflowId', '==', workflowId)
        .where('archived', '==', 'no')
        // .orderBy('sequenceNumber', 'asc')
        //  .orderBy('createdAt', 'desc')
        .orderBy(
          this.props.match.path === '/pt/:id' ? 'createdAt' : 'sequenceNumber',
          this.props.match.path === '/pt/:id' ? 'desc' : 'asc',
        )
        .limit(paginationLimit)
        // .onSnapshot((canvasesSnap: any) => {
        .get()
        .then((canvasesSnap: any) => {
          if (this.state.selectedWorkflowId !== workflowId && this.props.match.path === '/pt/:id') {
            this.setState({
              selectedWorkflowId: workflowId,
            });
          }
          if (canvasesSnap && canvasesSnap.docs && canvasesSnap.docs.length) {
            const canvases = canvasesSnap.docs.map((doc: any) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            });
            workflowCanvasArray[workflowIndex].canvases = canvases;
            workflowCanvasArray[workflowIndex].loadingMoreCanvas = false;
            workflowCanvasArray[workflowIndex].isMoreCanvas =
              canvases.length < paginationLimit ? false : true;
            this.setState({
              workflowCanvas: workflowCanvasArray,
              oldWorkflowCanvas: workflowCanvasArray,
              loader: false,
            });
          } else {
            workflowCanvasArray[workflowIndex].canvases = [];
            workflowCanvasArray[workflowIndex].loadingMoreCanvas = false;
            workflowCanvasArray[workflowIndex].isMoreCanvas = false;
            this.setState({
              workflowCanvas: workflowCanvasArray,
              oldWorkflowCanvas: workflowCanvasArray,
              loader: false,
            });
          }
        });
    }
  };

  loadMoreCanvas = (workflowId: string) => {
    const { workflowCanvas = [], paginationLimit } = this.state;
    const workflowIndex = workflowCanvas.findIndex((wf: any) => wf.id === workflowId);
    if (workflowIndex !== -1) {
      workflowCanvas[workflowIndex].loadingMoreCanvas = true;
      this.setState({
        workflowCanvas,
      });
      const firestore = firebase.firestore();
      const currentWorkflowCanvas = workflowCanvas[workflowIndex].canvases || [];
      const currentWorkflowCanvasCount = currentWorkflowCanvas.length;
      if (currentWorkflowCanvasCount) {
        firestore
          .collection('projects')
          //  .where('member_ids', 'array-contains', this.props.auth.uid)
          // .where('project_created_by', '==', this.props.auth.uid)
          //  .where('project_created_by', '==', '4I2h75BJZqWRObL8jqn344KMM0Q2')
          //  .where('workflowId', '==', '76109195-5ba1-4f71-9c2b-c649784d17f7')
          .where('workflowId', '==', workflowId)
          .where('archived', '==', 'no')
          // .orderBy('sequenceNumber', 'asc')
          .orderBy(
            this.props.match.path === '/pt/:id' ? 'createdAt' : 'sequenceNumber',
            this.props.match.path === '/pt/:id' ? 'desc' : 'asc',
          )
          .startAfter(currentWorkflowCanvas[currentWorkflowCanvasCount - 1].createdAt)
          .limit(paginationLimit)
          .get()
          .then((selfProjectsSnap: any) => {
            if (selfProjectsSnap && selfProjectsSnap.docs && selfProjectsSnap.docs.length) {
              const canvases = selfProjectsSnap.docs.map((doc: any) => {
                return {
                  ...doc.data(),
                  id: doc.id,
                };
              });
              workflowCanvas[workflowIndex].canvases = currentWorkflowCanvas.concat(canvases);
              workflowCanvas[workflowIndex].loadingMoreCanvas = false;
              workflowCanvas[workflowIndex].isMoreCanvas =
                canvases.length < paginationLimit ? false : true;
              this.setState({
                workflowCanvas,
              });
            } else {
              workflowCanvas[workflowIndex].loadingMoreCanvas = false;
              workflowCanvas[workflowIndex].isMoreCanvas = false;
              this.setState({
                workflowCanvas,
              });
            }
          });
      }
    }
  };

  toggleChatWindow = () => {
    this.setState({
      chatWindowMobile: !this.state.chatWindowMobile,
    });
  };
  toggleProjectControls = () => {
    this.setState({
      projectControls: !this.state.projectControls,
    });
  };

  componentDidMount() {
    const loginUserDetail = localStorage.getItem('userId');
    if (loginUserDetail) {
      const loginUser = JSON.parse(loginUserDetail);
      loginUser && loginUser.uid && checkChromeExtensionAndSendToken(loginUser.uid);
    }
    this.setState({
      paginationLimit: this.props.match.path === '/pt/:id' ? 200 : 200,
    });
    this.getOrgProjects();
    window.localStorage.removeItem('updatedTimeStamp');
  }

  componentWillReceiveProps(nextProps: any) {
    const { match } = nextProps;
    if (this.props.match !== match && this.props.match.url !== match.url) {
      window.localStorage.setItem('dashboardViewPath', match.path);
      // this.getOrgProjects();
      // this.setState({
      //   masterProjectId: this.props.match.params.id,
      //   paginationLimit: nextProps.match.path === '/pt/:id' ? 200 : 200,
      // });
      this.getWorkspaceProjects();
    }
  }

  getWorkspaceProjects = () => {
    const firestore = firebase.firestore();
    let organizationDetail: any = this.state.organizationDetail || [];
    const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
    let selectedOrganizationMatchedIndex = organizationDetail.findIndex(
      (og: any) => og.id === selectedOrganizationId,
    );
    if (selectedOrganizationMatchedIndex === -1) {
      selectedOrganizationMatchedIndex = 0;
      window.localStorage.setItem('selectedOrganizationId', organizationDetail[0].id);
    }
    this.props.updateSelectOrganizationDetails(
      organizationDetail[selectedOrganizationMatchedIndex],
    );
    this.setState({
      selectedOrganizationIndex: selectedOrganizationMatchedIndex,
    });
    let localstorageOrgProjectIndex: any = this.checkOrgProjectIdInSelectedOrgProject(
      organizationDetail[selectedOrganizationMatchedIndex].projects || [],
    );
    localstorageOrgProjectIndex =
      localstorageOrgProjectIndex !== -1 ? localstorageOrgProjectIndex : 0;

    if (localstorageOrgProjectIndex) {
      const projectId =
        organizationDetail[selectedOrganizationMatchedIndex].projects[
          localstorageOrgProjectIndex
        ].id;
      window.localStorage.setItem('selectedProjectId', projectId);
      this.setState({
        selectedOrgProject:
          organizationDetail[selectedOrganizationMatchedIndex].projects[
          localstorageOrgProjectIndex
          ],
      });
      unsubscribeMasterProjectListner && unsubscribeMasterProjectListner();
      unsubscribeMasterProjectListner = firestore
        .collection('masterProjects')
        // .where('member_emails', 'array-contains', this.props.auth.email)
        .where('projectId', '==', projectId)
        .where('member_ids', 'array-contains-any', [this.props.auth.uid, this.props.auth.email])
        //.where('member_ids', 'array-contains', this.props.auth.uid)
        .onSnapshot((selfMasterProjectsSnap: any) => {
          // .get()
          // .then((selfMasterProjectsSnap: any) => {
          this.workSpaceData(selfMasterProjectsSnap);
        });
    } else {
      if (
        organizationDetail &&
        organizationDetail[selectedOrganizationMatchedIndex] &&
        organizationDetail[selectedOrganizationMatchedIndex].projects &&
        organizationDetail[selectedOrganizationMatchedIndex].projects.length
      ) {
        const projectId = organizationDetail[selectedOrganizationMatchedIndex].projects[0].id;
        window.localStorage.setItem('selectedProjectId', projectId);
        this.setState({
          selectedOrgProject:
            organizationDetail[selectedOrganizationMatchedIndex].projects[0],
        });
        unsubscribeMasterProjectListner && unsubscribeMasterProjectListner();
        unsubscribeMasterProjectListner = firestore
          .collection('masterProjects')
          // .where('member_emails', 'array-contains', this.props.auth.email)
          .where('projectId', '==', projectId)
          .where('member_ids', 'array-contains-any', [this.props.auth.uid, this.props.auth.email])
          // .where('member_ids', 'array-contains', this.props.auth.uid)
          .onSnapshot((selfMasterProjectsSnap: any) => {
            // .get()
            // .then((selfMasterProjectsSnap: any) => {
            this.workSpaceData(selfMasterProjectsSnap);
          });
      }
    }
  }

  loginUserProjectRole = (selfMasterProjects: IMasterProject) => {
    const { auth } = this.props;
    // const { members = [] } = selfMasterProjects;
    // const selfMember: any = members.find((member: IMemberUser) => member.id === auth.uid);
    // if (selfMember && selfMember.role) {
    //   this.setState({
    //     loginUserRole: selfMember.role,
    //   });
    // }
    if (selfMasterProjects && auth && selfMasterProjects.created_by === auth.uid) {
      this.setState({
        loginUserRole: 'Admin',
      });
    }
  };

  onDelete = (projectId: string) => {
    this.props.deleteProject(projectId);
  };

  sortProject = (projects: any) => {
    if (projects.length > 1) {
      return projects.slice().sort((a: IProject, b: IProject) => {
        return b.createdAt && b.createdAt.seconds ? b.createdAt.seconds - a.createdAt.seconds : 0;
      });
    }
    return projects;
  };

  setWorkflowDrawerVisible = () => {
    this.setState({
      WorkflowDrawerVisible: false,
    });
  };

  setTagDrawerVisible = () => {
    this.setState({
      tagsDrawerVisible: false,
    });
  };

  setProjectFormVisible = () => {
    this.setState({
      projectFormVisible: false,
      projectFormIsEdit: false,
    });
  };

  setMemberListModelVisible = () => {
    this.setState({
      memberListModelVisible: false,
    });
  };

  handleSelectedProject = (project: IOrgProject) => {
    this.setState({
      selectedOrgProject: project,
    });
  };

  changeMasterProject = (project: IMasterProject) => {
    window.localStorage.removeItem('updatedTimeStamp');
    this.setState({
      selectedMasterProject: project,
    });
    this.loginUserProjectRole(project);
  };

  addMasterProject = () => {
    this.setState({
      projectFormVisible: true,
      projectFormIsEdit: false,
    });
  };

  deleteMasterProject = () => {
    const { selectedMasterProject } = this.state;
    const { id, name } = selectedMasterProject;
    if (id) {
      Modal.confirm({
        content: '',
        title: `Are you sure, you want to delete "${name}" project?`,
        onOk() {
          trackAnalytics('Workspace', 'Delete workspace Clicked');
          const firestore = firebase.firestore();
          firestore
            .collection('masterProjects')
            .doc(id)
            .delete();
        },
      });
    }
  };

  leaveProject = () => {
    const { selectedMasterProject } = this.state;
    const { auth } = this.props;
    const { id, name, members, member_emails = [], member_ids = [] } = selectedMasterProject;
    if (id) {
      const matchedEmailIndex = member_emails.findIndex((email: string) => email === auth.email);
      if (matchedEmailIndex > -1) {
        member_emails.splice(matchedEmailIndex, 1);
      }

      const matchedMemberEmailIndex = members.findIndex(
        ({ email }: { email: string }) => email === auth.email,
      );
      if (matchedMemberEmailIndex > -1) {
        members.splice(matchedMemberEmailIndex, 1);
      }

      const matchedMemberIdIndex = member_ids.findIndex(
        (member_id: string) => member_id === auth.uid,
      );
      if (matchedMemberIdIndex > -1) {
        member_ids.splice(matchedMemberIdIndex, 1);
      }

      Modal.confirm({
        content: '',
        title: `Are you sure, you want to leave from "${name}" project?`,
        onOk() {
          const firestore = firebase.firestore();
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .doc(id)
            .set(
              {
                member_emails,
                members,
                member_ids,
                updatedTimeStamp,
              },
              { merge: true },
            );
        },
      });
    }
  };

  editMasterProject = () => {
    trackAnalytics('Workspace', 'Workspace settings Clicked');
    this.setState({
      projectFormVisible: true,
      projectFormIsEdit: true,
    });
  };

  projectMenu = () => {
    const isShowFullFeature = showFullFeature();
    const { loginUserRole } = this.state;
    const menu = (
      <ColorContext.Consumer>
        {({ theme }) => (
          <Zmenu menuColors={theme}>
            {loginUserRole === 'Admin' && (
              <Menu.Item className='project-dropdown-item' key='1' onClick={this.editMasterProject}>
                Workspace settings
              </Menu.Item>
            )}
            {loginUserRole === 'Admin' && (
              <Menu.Item
                className='project-dropdown-item'
                key='2'
                onClick={() => {
                  trackAnalytics('Workspace', 'Workspace members Clicked');
                  this.setState({
                    memberListModelVisible: true,
                  });
                }}
              >
                Workspace members
              </Menu.Item>
            )}
            <Menu.Item
              className='project-dropdown-item'
              key='3'
              onClick={() => {
                trackAnalytics('Workspace', 'Leave workspace Clicked');
                this.leaveProject();
              }}
            >
              Leave workspace
            </Menu.Item>
            {loginUserRole === 'Admin' && (
              <Menu.Item
                className='project-dropdown-item'
                key='4'
                onClick={this.deleteMasterProject}
              >
                Delete workspace
              </Menu.Item>
            )}
            {loginUserRole === 'Admin' && (
              <Menu.Item
                className='project-dropdown-item'
                key='5'
                onClick={() => {
                  let workflowCanvasData = JSON.parse(JSON.stringify(this.state.workflowCanvas));
                  const selectedMasterProjectData = this.state.selectedMasterProject;
                  workflowCanvasData = workflowCanvasData.filter((order: any) => {
                    delete order.canvases;
                    delete order.isMoreCanvas;
                    delete order.loadingMoreCanvas;
                    return order;
                  });
                  selectedMasterProjectData.workflow = workflowCanvasData;
                  trackAnalytics('Workspace', 'Modify workspace Clicked');
                  this.setState({
                    WorkflowDrawerVisible: true,
                    selectedMasterProject: selectedMasterProjectData,
                  });
                }}
              >
                Modify workflow
              </Menu.Item>
            )}
            <Menu.Item
              className='project-dropdown-item'
              key='6'
              onClick={() => {
                trackAnalytics('Workspace', 'Modify Tags Clicked');
                this.setState({
                  tagsDrawerVisible: true,
                });
              }}
            >
              Modify Tags
            </Menu.Item>
            { isShowFullFeature && <Menu.Item
              className='project-dropdown-item'
              key='7'
              onClick={() => {
                trackAnalytics('Workspace', 'Copy voice clip URL Clicked');
                this.setState({
                  isCopyVoiceClipModal : true,
                });
              }}
            >
              Copy voice clip URL
            </Menu.Item>
            }
          </Zmenu>
        )}
      </ColorContext.Consumer>
    );
    return menu;
  };

  updateStateOnDragEnd = (kanbanWorkfowList: any) => {
    this.setState({
      workflowCanvas: kanbanWorkfowList,
    });
  };
  closeChatWindow = () => {
    this.setState({ chatWindowMobile: false });
  };
  onPaste = (e: any) => {
    const items = e && (e.clipboardData || e.originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
      }
    }
    if (blob !== null) {
      const reader = new FileReader();
      reader.onload = async (eData: any) => {
        if (isDataURL(eData.target.result)) {
          this.setState({
            loader: true,
          });
          const thumbnailBase64Img = await createThumbnailImg(eData.target.result);
          const originalImg: any = await sendImageToStore(eData.target.result);
          this.createBase64Project(originalImg, thumbnailBase64Img);
        }
      };
      reader.readAsDataURL(blob);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  createBase64Project = async (originalImg: string, thumbnailBase64Img: string) => {
    const projectType = 'image';
    const { createProject, auth, firebaseAuthToken, profile } = this.props;

    const currentSelectedWorkflowId = this.state.selectedWorkflowId;

    const loginUserEmail = auth && auth.email;
    const selectedMasterProjectId =
      (this.state.selectedMasterProject && this.state.selectedMasterProject.id) || '';

    trackAnalytics('Project', 'Created a canvas', 'Image');
    const projectData: any = {
      title: 'Image',
      project_url: originalImg,
      member_emails: [loginUserEmail],
      project_tags: [],
      type: projectType,
      imageType: 'base64',
      jiraIssueUrl: '',
      trelloIssueUrl: '',
      project_base64_image: thumbnailBase64Img,
      workflowId: currentSelectedWorkflowId,
      masterProjectId: selectedMasterProjectId,
      archived: 'no',
    };

    const fiebaseInstance: any = firebase;
    let firebaseUserAuthToken = firebaseAuthToken;
    if (
      !firebaseAuthToken &&
      fiebaseInstance &&
      fiebaseInstance.auth() &&
      fiebaseInstance.auth().currentUser
    ) {
      firebaseUserAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    createProject(projectData, firebaseUserAuthToken, this.state.selectedMasterProject).then(
      (insertedId: any) => {
        this.setState({
          loader: false,
        });
        const projectTypeLabel = defaultTag(projectType);
        if (insertedId) {
          const slackMessage = `${profile.firstName}${' '}${profile.lastName
            } created ${projectTypeLabel} type project`;
          const notificationId = uuid();
          const projectURL = `${window.location.href}project/${insertedId}?notificationId=${notificationId}`;
          firebaseAuthToken &&
            sendMessageToSlack(
              firebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );
          this.getCanvases(this.state.selectedWorkflowId, []);
        }
      },
    );
  };

  updateSelectedWorkflowId = (updatedWorkflowId: string) => {
    this.setState({
      selectedWorkflowId: updatedWorkflowId,
    });
  }

  render() {
    const isShowFullFeature = showFullFeature();
    const { projects = [], auth, profile, isWriteAccess, signUpDays, history, match } = this.props;
    const {
      organizationDetail,
      selectedOrgProject,
      projectFormVisible,
      selfMasterProjects,
      selectedMasterProject,
      projectFormIsEdit,
      // chatData,
      memberListModelVisible,
      selectedWorkflowId,
      workflowCanvas = [],
      WorkflowDrawerVisible,
      paginationLimit,
      tagsDrawerVisible,
      isArchived,
      archivedCanvases,
      selectedOrganizationIndex,
      isCopyVoiceClipModal
    } = this.state;
    const workflowIndex = workflowCanvas.findIndex((wf: any) => wf.id === selectedWorkflowId);
    const selfProjects: any = this.state.selfProjects;
    let sortedProjects = projects || [];
    const allProjects: any = selfProjects.concat(sortedProjects);
    if (!auth.uid) {
      return <Redirect to='/signin' />;
    }
    if (allProjects) {
      sortedProjects = this.sortProject(allProjects);
    }

    const { members = [], tags = [] } = selectedMasterProject;

    const currentWorkflowCanvasArray =
      (workflowIndex !== -1 &&
        workflowCanvas[workflowIndex] &&
        workflowCanvas[workflowIndex].canvases) ||
      [];

    const firstTimeUser =
      this.props.match.path === '/pt/:id' &&
      !currentWorkflowCanvasArray.length &&
      profile.isInitialUser;
    return (
      <ColorContext.Consumer>
        {({ theme }) => (
          <div className='TEST' onPaste={this.onPaste}>
            <Drawer
              title='Quick Introduction of Zooc'
              placement='right'
              closable={true}
              width='75vw'
              onClose={() => this.setState({ onboardingVideoVisibility: false })}
              visible={firstTimeUser && this.state.onboardingVideoVisibility}
            >
              <div className='embed-container'>
                {this.state.onboardingVideoVisibility && (
                  <iframe
                   // src='https://www.youtube.com/embed/EXaUC-uhWRI?rel=0'
                    src="https://www.youtube.com/embed/oouLDEwZAEA?rel=0"
                    frameBorder='0'
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </Drawer>
            <CopyVoiceClipModal
              isOpen={isCopyVoiceClipModal}
              setIsOpen={() => {
                this.setState({
                  isCopyVoiceClipModal: false
                })
              }}
            />
            <Dialog
              selectedOrganizationData={
                (organizationDetail &&
                  organizationDetail[selectedOrganizationIndex]) || { userQuota: {} }
              }
              selectedMasterProject={selectedMasterProject}
              history={history}
              selectedWorkflowId={selectedWorkflowId}
              selectedMasterProjectId={
                (selectedMasterProject && selectedMasterProject.id) || ''
              }
            />
            {/* <VideoStreamControls /> */}
            <ProjectForm
              title=''
              visible={projectFormVisible}
              setVisible={this.setProjectFormVisible}
              project={selectedMasterProject}
              isEdit={projectFormIsEdit}
            />
            <MemberListModel
              title='Project Members'
              visible={memberListModelVisible}
              setVisible={this.setMemberListModelVisible}
              selectedMasterProject={selectedMasterProject}
            />
            <TagsDrawer
              tagsDrawerVisible={tagsDrawerVisible}
              setTagDrawerVisible={this.setTagDrawerVisible}
              project={selectedMasterProject}
            />
            <div
              className='dashboard-container'
              style={
                this.props.match.path === '/pk/:id'
                  ? { width: '100%', overflow: 'inherit' }
                  : { width: '100vw', overflow: 'hidden' }
              }
            >
              {isMobile ? null : (
                <div className='dashboard-container-left-bar'>
                  <MasterProjects
                    selfMasterProjects={selfMasterProjects}
                    changeMasterProject={this.changeMasterProject}
                    selectedMasterProject={selectedMasterProject}
                    addMasterProject={this.addMasterProject}
                    history={history}
                    match={match}
                    profile={profile}
                    selectedOrganizationData={
                      (organizationDetail && organizationDetail[selectedOrganizationIndex]) || {
                        userQuota: {},
                      }
                    }
                  />
                  <ProfileMenu
                    selfMasterProjects={selfMasterProjects}
                    getOrgProjects={this.getOrgProjects}
                    organizationDetail={organizationDetail}
                    selectedOrgProject={selectedOrgProject}
                    handleSelectedProject={this.handleSelectedProject}
                    changeOrganization={this.changeOrganization}
                    selectedOrganizationIndex={selectedOrganizationIndex}
                    openInviteMemberModal={this.openInviteMemberModal}
                    selectedOrganizationData={
                      (organizationDetail && organizationDetail[selectedOrganizationIndex]) || {
                        userQuota: {},
                      }
                    }
                  />
                </div>
              )}
              <WorkflowDrawer
                WorkflowDrawerVisible={WorkflowDrawerVisible}
                setWorkflowDrawerVisible={this.setWorkflowDrawerVisible}
                selectedMasterProject={selectedMasterProject}
                getCanvases={this.getCanvases}
                selectedWorkflowIndex={workflowIndex}
              />
              <TagsDrawer
                tagsDrawerVisible={tagsDrawerVisible}
                setTagDrawerVisible={this.setTagDrawerVisible}
                project={selectedMasterProject}
              />

              {this.props.match.path === '/pt/:id' && selfMasterProjects.length ? (
                <>
                  {isMobile ? (
                    <Drawer
                      placement='left'
                      closable={false}
                      width='90vw'
                      onClose={() => this.setState({ projectControls: false })}
                      visible={this.state.projectControls}
                    >
                      <div style={{ display: 'flex', height: '100%' }}>
                        <div className='dashboard-container-left-bar'>
                          <MasterProjects
                            selfMasterProjects={selfMasterProjects}
                            changeMasterProject={this.changeMasterProject}
                            selectedMasterProject={selectedMasterProject}
                            addMasterProject={this.addMasterProject}
                            history={history}
                            match={match}
                            profile={profile}
                            selectedOrganizationData={
                              (organizationDetail &&
                                organizationDetail[selectedOrganizationIndex]) || { userQuota: {} }
                            }
                          />
                          <ProfileMenu
                            selfMasterProjects={selfMasterProjects}
                            getOrgProjects={this.getOrgProjects}
                            organizationDetail={organizationDetail}
                            selectedOrgProject={selectedOrgProject}
                            handleSelectedProject={this.handleSelectedProject}
                            changeOrganization={this.changeOrganization}
                            selectedOrganizationIndex={selectedOrganizationIndex}
                            openInviteMemberModal={this.openInviteMemberModal}
                            selectedOrganizationData={
                              (organizationDetail &&
                                organizationDetail[selectedOrganizationIndex]) || { userQuota: {} }
                            }
                          />
                        </div>
                        <div className='project-workflow'>
                          <ProjectSelectDropdown
                            selectedMasterProject={selectedMasterProject}
                            projectMenu={this.projectMenu}
                            onClose={() => this.setState({ projectControls: false })}
                          />
                          <div className='workflow-container'>
                            <Members
                              selfMasterProjects={selfMasterProjects}
                              selectedMasterProject={selectedMasterProject}
                              organizationDetail={organizationDetail}
                              auth={auth}
                            />
                            <Workflow
                              isEdit={false}
                              selectedMasterProject={selectedMasterProject}
                              getCanvases={this.getCanvases}
                              selectedWorkflowIndex={isArchived === 'yes' ? -1 : workflowIndex}
                              onCloseMobile={() => this.setState({ projectControls: false })}
                            />
                            <div
                              onClick={() => {
                                trackAnalytics('Workflow', 'Archived Clicked');
                                const updateArchived = isArchived === 'no' ? 'yes' : 'no';
                                isArchived === 'no' && this.getArchivedCanvases();
                                this.setState({
                                  isArchived: updateArchived,
                                });
                              }}
                              className={`archived-unarchived-container ${isArchived === 'no' ? '' : 'archived-unarchived-container-selected'
                                }`}
                            >
                              <div className='archived-unarchived-div cursor-pointer'>
                                <ArchivedIcon />
                                <span
                                  className='archived-unarchived-content'
                                  style={{ color: theme.text.color2 }}
                                >
                                  Archived
                                </span>
                              </div>
                              {isArchived === 'yes' && (
                                <span className='archived-count'>{archivedCanvases.length}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Drawer>
                  ) : (
                      <div className='project-workflow'>
                        <ProjectSelectDropdown
                          selectedMasterProject={selectedMasterProject}
                          projectMenu={this.projectMenu}
                          isStyle={true}
                        />
                        <div className='workflow-container'>
                          <Members
                            selfMasterProjects={selfMasterProjects}
                            selectedMasterProject={selectedMasterProject}
                            organizationDetail={organizationDetail || { userQuota: {} }}
                            auth={auth}
                          />
                          <Workflow
                            isEdit={false}
                            selectedMasterProject={selectedMasterProject}
                            getCanvases={this.getCanvases}
                            selectedWorkflowIndex={isArchived === 'yes' ? -1 : workflowIndex}
                          />

                          {/* <div
                        onClick={() => {
                          const updateArchived = isArchived === 'no' ? 'yes' : 'no';
                          isArchived === 'no' && this.getArchivedCanvases();
                          this.setState({
                            isArchived: updateArchived,
                          });
                        }}
                        className={`archived-unarchived-container ${
                          isArchived === 'no' ? '' : 'archived-unarchived-container-selected'
                          }`}
                      >
                        <div className='archived-unarchived-div cursor-pointer'>
                          <ArchivedIcon />
                          <span className='archived-unarchived-content' style={{ color: color.text.color2 }}>Archived</span>
                        </div> */}
                          <div
                            onClick={() => {
                              const updateArchived = isArchived === 'no' ? 'yes' : 'no';
                              isArchived === 'no' && this.getArchivedCanvases();
                              this.setState({
                                isArchived: updateArchived,
                              });
                            }}
                            className={`archived-unarchived-container ${isArchived === 'no' ? '' : 'archived-unarchived-container-selected'
                              }`}
                          >
                            <div className='archived-unarchived-div cursor-pointer'>
                              <ArchivedIcon />
                              <span
                                className='archived-unarchived-content'
                                style={{ color: theme.text.color2 }}
                              >
                                Archived
                            </span>
                            </div>
                            {isArchived === 'yes' && (
                              <span className='archived-count'>{archivedCanvases.length}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  <div
                    className='content-container default-dashboard'
                    style={{
                      background: theme.backgrounds.color1,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {isMobile ? (
                      <div
                        className='Msearch-container'
                        style={{ background: theme.backgrounds.color2 }}
                      >
                        <div
                          onClick={this.toggleProjectControls}
                          className={'project-workflow-mobile'}
                        >
                          {workflowCanvas[workflowIndex] && workflowCanvas[workflowIndex].step}(
                          {workflowCanvas[workflowIndex] && workflowCanvas[workflowIndex].total})
                        </div>
                        { isShowFullFeature && <div className='filter-and-notification-container'>
                          <Filter
                            filterCanvases={this.filterCanvases}
                            tags={tags}
                            members={members}
                          />
                          <Notifications loginUserId={auth.uid} />
                        </div>
                        }
                      </div>
                    ) : (
                        <div
                          className='search-container'
                          style={{ background: theme.backgrounds.color1 }}
                        >
                          {!(
                            this.props.match.path === '/pt/:id' &&
                            !currentWorkflowCanvasArray.length &&
                            profile.isInitialUser
                          ) &&
                            auth.email && (
                              <NewSelectDropdown
                                selectedOrganizationData={
                                  (organizationDetail &&
                                    organizationDetail[selectedOrganizationIndex]) || {
                                    userQuota: {},
                                  }
                                }
                                selectedMasterProject={selectedMasterProject}
                                selectedWorkflowId={selectedWorkflowId}
                                selectedMasterProjectId={
                                  (selectedMasterProject && selectedMasterProject.id) || ''
                                }
                              />
                            )}
                          { isShowFullFeature && <div className='filter-and-notification-container'>
                            <Filter
                              filterCanvases={this.filterCanvases}
                              tags={tags}
                              members={members}
                            />
                            <Notifications loginUserId={auth.uid} />
                          </div>
                          }
                          { !isShowFullFeature && 
                          <div className='filter-and-notification-container'>
                            <DashboardButtons />
                          </div>
                          }
                        </div>
                      )}
                    {isMobile ? null : (
                      <div
                        className='folder-container'
                        style={{ background: theme.backgrounds.color1 }}
                      >
                        {/* Folder Area <br /> */}
                        { isShowFullFeature && <DashboardButtons /> }
                      </div>
                    )}
                    {profile.isUserDeleted && <DeletedUser />}
                    {/* <div className='dashboard container'> */}
                    <div style={{ width: '100%', height: '100%' }} className='row'>
                      {this.props.match.path === '/pt/:id' &&
                        !currentWorkflowCanvasArray.length &&
                        profile.isInitialUser && (
                          <div className='user-intial-step'>
                            <div className='user-intial-step-msg'>
                              {!(profile && profile.adminIds && profile.adminIds[0])
                                ? 'Your next steps can be to create a new Canvas'
                                : 'Your next steps can be either to create a new Canvas or Invite Users'}
                            </div>
                            <NewSelectDropdown
                              selectedOrganizationData={
                                (organizationDetail &&
                                  organizationDetail[selectedOrganizationIndex]) || {
                                  userQuota: {},
                                }
                              }
                              selectedMasterProject={selectedMasterProject}
                              selectedWorkflowId={selectedWorkflowId}
                              selectedMasterProjectId={
                                (selectedMasterProject && selectedMasterProject.id) || ''
                              }
                            />
                            {!(profile && profile.adminIds && profile.adminIds[0]) && (
                              <>
                                <div>OR</div>
                                <Zbutton
                                  onClick={() => {
                                    trackAnalytics('Profile', 'Invite Users Clicked');
                                    this.setState({
                                      userDrawerVisible: true,
                                    });
                                  }}
                                  primary
                                  btnColors={theme}
                                  style={{ 'font-size': '14px' }}
                                >
                                  Invite Users
                                </Zbutton>
                              </>
                            )}
                            <UserDrawer
                              selectedOrganizationData={
                                (organizationDetail &&
                                  organizationDetail[selectedOrganizationIndex]) || {
                                  userQuota: {},
                                }
                              }
                              onCloseDrawer={() => {
                                this.setState({
                                  userDrawerVisible: false,
                                });
                                const firestore = firebase.firestore();
                                profile.isInitialUser &&
                                  firestore
                                    .collection('users')
                                    .doc(auth.uid)
                                    .update({
                                      isInitialUser: false,
                                    });
                              }}
                              visible={this.state.userDrawerVisible}
                              profile={profile}
                              organizationDetail={organizationDetail || { userQuota: {} }}
                              auth={auth}
                            />
                          </div>
                        )}

                      <div style={{ width: '100%', height: '100%' }} className='row'>
                        <div className='col s12 m6 default-dashboard-project-list'>
                          {workflowIndex !== -1 && (
                            <ProjectList
                              isShowInitialStep={
                                this.props.match.path === '/pt/:id' &&
                                !currentWorkflowCanvasArray.length &&
                                profile.isInitialUser
                              }
                              selectedMasterProject={selectedMasterProject}
                              isWriteAccess={isWriteAccess}
                              signUpDays={signUpDays}
                              profile={profile}
                              authId={auth.uid}
                              loader={this.state.loader}
                              loginUserId={auth.uid}
                              loginUserEmail={auth.email}
                              projects={JSON.parse(JSON.stringify(sortedProjects))}
                              canvases={
                                isArchived === 'yes'
                                  ? archivedCanvases
                                  : (workflowIndex !== -1 &&
                                    workflowCanvas[workflowIndex] &&
                                    workflowCanvas[workflowIndex].canvases) ||
                                  [] ||
                                  []
                              }
                              onDelete={this.onDelete}
                              loadingMoreCanvas={
                                workflowCanvas[workflowIndex] &&
                                workflowCanvas[workflowIndex].loadingMoreCanvas
                              }
                              isMoreCanvas={
                                isArchived === 'yes'
                                  ? false
                                  : workflowCanvas[workflowIndex] &&
                                  workflowCanvas[workflowIndex].isMoreCanvas
                              }
                              loadMoreCanvas={this.loadMoreCanvas}
                              selectedWorkflowId={selectedWorkflowId}
                              paginationLimit={paginationLimit}
                              selectedMasterProjectId={
                                (selectedMasterProject && selectedMasterProject.id) || ''
                              }
                              selectedOrganizationData={
                                (organizationDetail &&
                                  organizationDetail[selectedOrganizationIndex]) || {
                                  userQuota: {},
                                }
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMobile ? (
                    <Drawer
                      placement='right'
                      closable={false}
                      width={'320px'}
                      onClose={this.closeChatWindow}
                      visible={this.state.chatWindowMobile}
                    >
                      <div
                        style={{
                          height: '100vh',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '5px 10px 20px 10px',
                        }}
                      >
                        <span
                          onClick={this.closeChatWindow}
                          style={{
                            background: '#FFF',
                            borderRadius: '2px',
                            zIndex: 1,
                          }}
                        >
                          <Icon type='close' />
                        </span>
                        <ChatWindowNew
                          selectedMasterProject={selectedMasterProject}
                          projectId={selectedMasterProject.id}
                          // chatData={chatData}
                          isWriteAccess={isWriteAccess || true}
                        />
                      </div>
                    </Drawer>
                  ) : (
                      <div className='chat-container'>
                        <ChatWindowNew
                          selectedMasterProject={selectedMasterProject}
                          projectId={selectedMasterProject.id}
                          // chatData={chatData}
                          isWriteAccess={isWriteAccess || true}
                        />
                      </div>
                    )}
                </>
              ) : null}
              {isMobile ? (
                <MobileDashboard
                  toggleChatWindow={this.toggleChatWindow}
                  chatWindowMobile={this.state.chatWindowMobile}
                  projectControls={this.state.projectControls}
                  selectedMasterProject={selectedMasterProject}
                  selectedWorkflowId={selectedWorkflowId}
                  toggleProjectControls={this.toggleProjectControls}
                />
              ) : null}
              {this.props.match.path === '/pk/:id' && selfMasterProjects.length ? (
                <DashboardKanban
                  updateSelectedWorkflowId={this.updateSelectedWorkflowId}
                  selectedWorkflowId={selectedWorkflowId}
                  history={history}
                  isWriteAccess={isWriteAccess}
                  signUpDays={signUpDays}
                  profile={profile}
                  auth={auth}
                  loader={this.state.loader}
                  projects={JSON.parse(JSON.stringify(sortedProjects))}
                  onDelete={this.onDelete}
                  selectedMasterProject={selectedMasterProject}
                  workflowCanvas={workflowCanvas}
                  projectMenu={this.projectMenu}
                  loadMoreCanvas={this.loadMoreCanvas}
                  paginationLimit={paginationLimit}
                  updateStateOnDragEnd={this.updateStateOnDragEnd}
                  selectedOrganizationData={
                    (organizationDetail && organizationDetail[selectedOrganizationIndex]) || {
                      userQuota: {},
                    }
                  }
                />
              ) : null}
            </div>
          </div>
        )}
      </ColorContext.Consumer>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    notifications: state.firestore.ordered.notifications,
    projects: state.firestore.ordered.projects,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
  };
};
// tslint:disable-next-line
const mapDispatchToProps = (dispatch: any) => {
  return {
    createProject: (
      project: IProjectCreate,
      firebaseAuthToken: string,
      selectedMasterProject: any,
    ) => dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
    deleteProject: (projectId: string) => dispatch(deleteProject(projectId)),
    updateSelectOrganizationDetails: (selectedOrganizationDetails: any) =>
      dispatch(updateSelectOrganizationDetails(selectedOrganizationDetails)),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  // firestoreConnect((props: any) => [
  //   {
  //     collection: 'projects',
  //     where: [['member_emails', 'array-contains', props.auth.email]],
  //   },
  // ]),
)(Dashboard);

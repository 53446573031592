import authReducer from './authReducer';
import projectReducer from './projectReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import newCanvasReducer from './newCanvas';
import organizationReducer from './organizationReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  canvas: newCanvasReducer,
  organization: organizationReducer,
});

export default rootReducer;

// the key name will be the data property on the state object

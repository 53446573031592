import React from 'react';

export default () => (
  <svg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.17345 2.2042L9.83266 12.4357C9.96214 12.5865 10.0341 12.7794 10.0355 12.9795V19.1942C10.0346 19.2813 10.0609 19.3664 10.1106 19.4374C10.1603 19.5084 10.2309 19.5616 10.3122 19.5895L14.4275 20.9786C14.489 20.9995 14.5545 21.0052 14.6186 20.9952C14.6827 20.9853 14.7436 20.9599 14.7961 20.9213C14.8486 20.8827 14.8913 20.8319 14.9206 20.7732C14.9499 20.7146 14.9649 20.6496 14.9645 20.5838V12.9795C14.9666 12.7791 15.0395 12.5862 15.1699 12.4357L23.8266 2.2042C23.9161 2.09843 23.9738 1.96884 23.993 1.83079C24.0121 1.69274 23.9919 1.55201 23.9346 1.42529C23.8773 1.29856 23.7854 1.19115 23.6698 1.11577C23.5542 1.0404 23.4197 1.00022 23.2823 1H1.72026C1.58262 0.999721 1.44778 1.03954 1.33179 1.11472C1.2158 1.1899 1.12353 1.29728 1.06594 1.4241C1.00835 1.55093 0.987866 1.69186 1.00692 1.83014C1.02598 1.96843 1.08378 2.09826 1.17345 2.2042Z'
      stroke='#4F4F4F'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

import React from 'react';

export default () => (
  <svg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.27148 21H6.18164V13.7754L3.99609 14.5781V13.5938L7.10156 12.4277H7.27148V21ZM15.7969 17.3613C15.7969 18.6309 15.5801 19.5742 15.1465 20.1914C14.7129 20.8086 14.0352 21.1172 13.1133 21.1172C12.2031 21.1172 11.5293 20.8164 11.0918 20.2148C10.6543 19.6094 10.4277 18.707 10.4121 17.5078V16.0605C10.4121 14.8066 10.6289 13.875 11.0625 13.2656C11.4961 12.6562 12.1758 12.3516 13.1016 12.3516C14.0195 12.3516 14.6953 12.6465 15.1289 13.2363C15.5625 13.8223 15.7852 14.7285 15.7969 15.9551V17.3613ZM14.7129 15.8789C14.7129 14.9609 14.584 14.293 14.3262 13.875C14.0684 13.4531 13.6602 13.2422 13.1016 13.2422C12.5469 13.2422 12.1426 13.4512 11.8887 13.8691C11.6348 14.2871 11.5039 14.9297 11.4961 15.7969V17.5312C11.4961 18.4531 11.6289 19.1348 11.8945 19.5762C12.1641 20.0137 12.5703 20.2324 13.1133 20.2324C13.6484 20.2324 14.0449 20.0254 14.3027 19.6113C14.5645 19.1973 14.7012 18.5449 14.7129 17.6543V15.8789Z'
      fill='#333333'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M2 5.36577C2.49917 4.71193 3.15071 3.87402 3.57082 3.41293C5.53403 1.25818 7.76148 0 10.5 0C16.8897 0 20 4.41222 20 10H18C18 5.39719 15.6052 2 10.5 2C8.40884 2 6.66775 2.98346 5.04921 4.75991C4.6264 5.22397 3.82048 6.28244 3.26844 7H9V9H0V0H2V5.36577Z'
      fill='#333333'
    />
  </svg>
);

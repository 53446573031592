import { Modal, notification } from 'antd';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { convertPdfToPng, uploadPdfByURLAction } from 'store/actions/newCanvasActions';
import { v4 as uuid } from 'uuid';
import {
  createProject as createProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { trackAnalytics } from '../../utils';
import ByClipboard from './ByClipboard';
import ByURLDialogContent from './ByURLDialogContent';
import './style.css';
import UploadDialogContent from './UploadDialogContent';

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
}

const Dialog = (props: {
  canvas: any;
  selectedWorkflowId?: string;
  selectedMasterProjectId?: string;
  history?: any;
  createProject: any;
  resetLoader: any;
  firebaseAuthToken: string;
  profile: any;
  auth: any;
  isPdfModal: boolean;
  setIsPdfModal: any;
  propsLoading: boolean;
  selectedMasterProject: any;
  selectedOrganizationData: any;
}) => {
  const {
    selectedWorkflowId,
    selectedMasterProjectId,
    history,
    createProject,
    firebaseAuthToken,
    profile,
    auth,
    isPdfModal,
    setIsPdfModal,
    selectedMasterProject,
    selectedOrganizationData,
  } = props;
  const [selectedTab, setSelectedTab] = useState('Upload');
  const [loading, setLoading] = useState(false);

  const createPdfToImage = async (url: string) => {
    setLoading(true);
    let firebaseLoginUserToken = firebaseAuthToken;
    if (!firebaseLoginUserToken) {
      const fiebaseInstance: any = firebase;
      firebaseLoginUserToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    convertPdfToPng(firebaseLoginUserToken, url).then((response: any) => {
      if (response.status) {
        const { project_url, thumbnail_url } = response;
        createPdfTask(project_url, thumbnail_url);
      } else {
        setLoading(false);
        notification.error({
          message: 'Something went wrong, please try again.',
        });
      }
    });
  };

  const uploadPdfByURL = async (url: string) => {
    setLoading(true);
    let firebaseLoginUserToken = firebaseAuthToken;
    if (!firebaseLoginUserToken) {
      const fiebaseInstance: any = firebase;
      firebaseLoginUserToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    uploadPdfByURLAction(firebaseLoginUserToken, url).then((response: any) => {
      if (response.status) {
        const { project_url } = response;
        createPdfTask(project_url, '');
      } else {
        setLoading(false);
        notification.error({
          message: 'Something went wrong, please try again.',
        });
      }
    });
  };

  const createPdfTask = async (url: string, thumbnailURL: string = '') => {
    setLoading(true);
    trackAnalytics('Canvas', 'Created a canvas', 'pdf');
    const { email } = profile;
    const projectData = {
      title: 'Untitled task',
      project_url: url,
      member_emails: [email],
      project_tags: [],
      type: 'pdf',
      workflowId: selectedWorkflowId,
      masterProjectId: selectedMasterProjectId,
      archived: 'no',
      thumbnail_url: thumbnailURL,
    };
    let userFirebaseAuthToken = firebaseAuthToken;
    const fiebaseInstance: any = firebase;
    if (
      !firebaseAuthToken &&
      fiebaseInstance &&
      fiebaseInstance.auth() &&
      fiebaseInstance.auth().currentUser
    ) {
      userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    createProject(projectData, userFirebaseAuthToken, selectedMasterProject).then(
      async (insertedId: any) => {
        if (insertedId) {
          const slackMessage = `${profile.fullName} created pdf type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/pdf/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );
          history.push(`/pdf/${insertedId}`);
          setIsPdfModal(false);
          setLoading(false);
        }
      },
    );
  };
  return (
    <div>
      <Modal
        visible={isPdfModal}
        footer={null}
        closable={false}
        maskClosable={true}
        onOk={() => setIsPdfModal(false)}
        onCancel={() => setIsPdfModal(false)}
        bodyStyle={{ height: '300px', width: '500px' }}
      >
        <div className='dialogContainer'>
          <div className='dialogTabsContainer'>
            <div
              className={`pdfDialog dialogTab dialogTab1 ${selectedTab === 'Upload' &&
                'dialogTabSelected'}`}
              onClick={() => {
                trackAnalytics('Canvas', 'PDF Canvas Upload Tab Clicked');
                setSelectedTab('Upload');
              }}
            >
              Upload
            </div>
            <div
              className={`pdfDialog dialogTab dialogTab3 ${selectedTab === 'ByURL' &&
                'dialogTabSelected'}`}
              onClick={() => {
                trackAnalytics('Canvas', 'PDF Canvas By URL Clicked');
                setSelectedTab('ByURL');
              }}
            >
              By URL
            </div>
          </div>
          {selectedTab === 'Record' && isPdfModal ? (
            <ByClipboard
              selectedOrganizationData={selectedOrganizationData}
              ShowNewVideoCanvas={setIsPdfModal}
              createPdfTask={createPdfTask}
            />
          ) : selectedTab === 'Upload' && isPdfModal ? (
            <UploadDialogContent
              projectType='pdf'
              isLoading={loading}
              firebaseAuthToken={firebaseAuthToken}
              createPdfTask={createPdfTask}
              ShowNewVideoCanvas={setIsPdfModal}
              profile={profile}
              auth={auth}
              selectedOrganizationData={selectedOrganizationData}
            />
          ) : (
            isPdfModal && (
              <ByURLDialogContent
                loading={loading}
                type={'pdf'}
                createPdfToImage={createPdfToImage}
                uploadPdfByURL={uploadPdfByURL}
                ShowNewVideoCanvas={setIsPdfModal}
              />
            )
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    canvas: state.canvas,
    propsLoading: state.project.loading,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetLoader: () => dispatch(resetAction()),
    createProject: (project: Project, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dialog as any) as any;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { changeReadBy } from '../../store/actions/pageActions';
import { addFeedback } from '../../store/actions/projectActions';
import { getQueryStringValue } from '../../utils';
import { mobileCheck } from '../../utils';
import FeedbackModel from '../projects/FeedbackModel';

const isMobile = mobileCheck();

const VideoProjectChat = (props: any) => {
  const [pageID, setPageID] = useState('');
  const [loading, setLoading] = useState(false);
  const { closeChatWindow, clearShowingMessageid, pages, isWriteAccess } = props;
  const addPoint = async (data: any) => {
    const { addFeedbackProps } = props;
    const pagePath = getQueryStringValue('page');
    data.pagePath = pagePath;
    if (addFeedbackProps) {
      const value = await addFeedbackProps(data, props.match.params.id);

      if (value) {
        const store = { pageID: value.id };
        return store;
      }
      return { pageID: '' };
    }
  };

  useEffect(() => {
    if (props.isOpenChatWindow) {
      if (typeof props.pages === 'object' && props.pages.length > 0 && !loading) {
        const { pages: pageTemp, auth } = props;
        if (pageTemp && pageTemp[0]) {
          const { feedback } = pageTemp[0];
          feedback.forEach((feeds: any) => {
            if (feeds.circle_pointer.id === 'chat-window') {
              if (!feeds.readBy || !feeds.readBy.includes(auth.uid)) {
                props.changeReadByUsers(feeds, pageTemp);
              }
            }
          });
        }
      }
    }
  }, [props.isOpenChatWindow]);

  useEffect(() => {
    if (typeof props.pages === 'object' && props.pages.length === 0 && !loading) {
      if (!(props.pages && props.pages.length > 0 && props.pages[0].feedback)) {
        setLoading(true);
        addPoint({
          id: 'chat-window',
          x: 10,
          y: 10,
          path: 'SECTION#hero',
          url: '/',
          oldWindowHeight: 0,
          oldWindowWidth: 0,
          pagePath: '/',
        }).then((store: any) => {
          setPageID(store.pageID);
          setLoading(false);
        });
      }
    }
  }, [props.pages]);

  let tempPage = pages;
  if (pages && pages.length > 0) {
    tempPage = pages[0].id;
  } else {
    tempPage = pageID;
  }
  return props.isOpenChatWindow ? (
    <div id='viewport'>
      <div className={'feedbackbox ' + `${isMobile ? 'chat-mobile' : ''}`} style={{ zIndex: 10 }}>
        <FeedbackModel
          isWriteAccess={isWriteAccess}
          projectID={props.match.params.id}
          pageID={tempPage}
          circlePointerID={'chat-window'}
          onClose={closeChatWindow}
          direction={'right'}
          resolved={false}
          isChatWindow
          clearShowingMessageid={clearShowingMessageid}
        />
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    pages: state.firestore.ordered.pages,
    isOpenChatWindow: state.project.isOpenChatWindow,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addFeedbackProps: (feedback: any, projectId: string) =>
      dispatch(addFeedback(feedback, projectId)),
    changeReadByUsers: (data: any, pages: any) => dispatch(changeReadBy(data, pages)),
  };
};

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  firestoreConnect((props: any) => {
    return [
      {
        collection: 'pages',
        where: [['project_id', '==', props.match.params.id]],
      },
    ];
  }),
)(VideoProjectChat);

export const eventConstant = {
  LOADED_IFRAME: 'LOADED_IFRAME',
  ADD_POINT: 'ADD_POINT',
  POINT_CLICK: 'POINT_CLICK',
  SET_IFRAME_HEIGHT: 'SET_IFRAME_HEIGHT',
  IFRAME_SCROLLED: 'IFRAME_SCROLLED',
  SYNC_PAGE_ID: 'SYNC_PAGE_ID',
  SYNC_INIT: 'SYNC_INIT',
  BROWSER_MODE: 'BROWSER_MODE',
  HIDE_COMMENTS: 'HIDE_COMMENTS',
  SHOW_COMMENTS: 'SHOW_COMMENTS',
  ADD_FEEDBACK: 'ADD_FEEDBACK',
  SCROLL_WINDOW_TO_FEEDBACK_POINT: 'SCROLL_WINDOW_TO_FEEDBACK_POINT',
  INIT: 'INIT',
  RE_INIT: 'RE_INIT',
  HIDE_CHAT_WINDOW: 'HIDE_CHAT_WINDOW',
  SHOW_CHAT_WINDOW: 'SHOW_CHAT_WINDOW',
  COMMENTRY_MODE: 'COMMENTRY_MODE',
  LINK_DISABLED_IN_COMMENTRY_MODE: 'LINK_DISABLED_IN_COMMENTRY_MODE',
  SHOW_RESOLVED_FEEDBACK: 'SHOW_RESOLVED_FEEDBACK',
  SHOW_ACTIVE_FEEDBACK: 'SHOW_ACTIVE_FEEDBACK',
  SHOW_ALL_FEEDBACK: 'SHOW_ALL_FEEDBACK',
  HIDE_ALL_FEEDBACK: 'HIDE_ALL_FEEDBACK',
  LOADED_PAGE: 'LOADED_PAGE',
  CHANGE_COMMENT_FILTER_TO_ALL: 'CHANGE_COMMENT_FILTER_TO_ALL',
  CHANGE_COMMENT_FILTER_TO_ACTIVE: 'CHANGE_COMMENT_FILTER_TO_ACTIVE',
  TOGGLE_DRAWING: 'TOGGLE_DRAWING',
  ADD_KONVA_OBJECT: 'ADD_KONVA_OBJECT',
  SHOW_UNREAD_FEEDBACK: 'SHOW_UNREAD_FEEDBACK',
  HIDE_DRAWINGS: 'HIDE_DRAWINGS',
  MOUSE_EVENT_TRIGGER: 'MOUSE_EVENT_TRIGGER',
  UNDO_OR_REDO: 'UNDO_OR_REDO',
  SHOULD_DISABLE_UNDO_REDO: 'SHOULD_DISABLE_UNDO_REDO',
  SYNC_STROKE_COLOR: 'SYNC_STROKE_COLOR',
  SHOW_ACTIVE_CHAT_POINT: 'SHOW_ACTIVE_CHAT_POINT',
  HIDE_ACTIVE_CHAT_POINT: 'HIDE_ACTIVE_CHAT_POINT',
  SYNC_THREAD_ID: 'SYNC_THREAD_ID',
};

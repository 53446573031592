import { Form, Modal } from 'antd';
import firebase from 'firebase';
import React, { useState } from 'react';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { trackAnalytics } from '../../utils';

interface IProps {
  signOut: () => void;
  userId: string;
  form: any;
}

const DeleteUserModal: React.FC<IProps> = ({
  form: { getFieldDecorator, validateFieldsAndScroll },
  userId,
  signOut,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const VisibleModalClicked = () => {
    setVisibleModal(true);
    trackAnalytics('Profile', 'Delete Account Button Clicked');
  };

  const handleCancelModal = () => {
    setVisibleModal(false);
    trackAnalytics('Profile', 'Delete Account Modal Closed');
  };

  const handleDeleteUser = () => {
    validateFieldsAndScroll((err: any, values: any) => {
      const confirmMassege = 'YES, I WANT TO DELETE MY ACCOUNT';
      const { text } = values;
      if (!err) {
        if (text === confirmMassege) {
          const firestore = firebase.firestore();
          return firestore
            .collection('users')
            .doc(userId)
            .set(
              {
                isUserDeleted: true,
              },
              { merge: true },
            )
            .then(() => {
              trackAnalytics('Profile', 'Deleted Account');
              statsAction('USER_DELETED', firestore);
              signOut();
            })
            .catch(() => {
              return false;
            });
        }
      }
    });
  };

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div style={{ color: theme.text.color2 }}>
          <Zbutton danger btnColors={theme} onClick={VisibleModalClicked}>
            Delete Account
          </Zbutton>
          <Modal
            visible={visibleModal}
            onOk={handleCancelModal}
            onCancel={handleCancelModal}
            footer={null}
          >
            <h3 style={{ color: 'orange' }}>WARNING!</h3>
            <p>
              If you delete your account all your data will be deleted within 24 hours. In order to
              confirm please type the following text in the input box provided and click on Confirm
              button:
            </p>
            <p>
              <strong>YES, I WANT TO DELETE MY ACCOUNT</strong>
            </p>
            <Form>
              <Form.Item>
                {getFieldDecorator('text', {
                  rules: [
                    {
                      required: true,
                      message: `Please type the above text!`,
                      whitespace: true,
                    },
                  ],
                })(<Zinput placeholder='Type the text here.' />)}
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Zbutton
                  secondary
                  btnColors={theme}
                  style={{ marginRight: 10 }}
                  onClick={handleCancelModal}
                >
                  Cancel
                </Zbutton>
                <Zbutton danger btnColors={theme} onClick={handleDeleteUser}>
                  Confirm
                </Zbutton>
              </div>
            </Form>
          </Modal>
        </div>
      )}
    </ColorContext.Consumer>
  );
};

export default Form.create<IProps>()(DeleteUserModal);

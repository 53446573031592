import React from 'react';

export default () => (
  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.74886 17.8473C9.52886 17.1473 9.13886 15.2173 8.23886 13.9973C7.34886 12.7473 6.11886 11.8873 4.87886 11.0573C3.99411 10.4997 3.20415 9.80427 2.53886 8.99735C2.25886 8.66735 1.68886 8.05735 2.26886 7.93735C2.85886 7.81735 3.87886 8.39735 4.39886 8.61735C5.30886 8.99735 6.20886 9.43735 7.04886 9.95735L8.05886 8.25735C6.49886 7.22735 4.49886 6.31735 2.63886 6.04735C1.57886 5.88735 0.458856 6.10735 0.0988564 7.25735C-0.221144 8.24735 0.288856 9.24735 0.868856 10.0273C2.23886 11.8573 4.36886 12.7373 5.95886 14.3173C6.29886 14.6473 6.70886 15.0373 6.90886 15.4973C7.11886 15.9373 7.06886 15.9673 6.59886 15.9673C5.35886 15.9673 3.80886 14.9973 2.79886 14.3573L1.78886 16.0573C3.31886 16.9973 5.87886 18.4673 7.74886 17.8473ZM18.8389 2.24735C19.0589 2.02735 19.0589 1.66735 18.8389 1.45735L17.5389 0.157347C17.4341 0.0564022 17.2943 0 17.1489 0C17.0034 0 16.8636 0.0564022 16.7589 0.157347L15.7389 1.17735L17.8189 3.25735L18.8389 2.24735ZM8.99886 7.91735V9.99735H11.0789L17.2289 3.84735L15.1489 1.76735L8.99886 7.91735Z'
      fill='#4F4F4F'
    />
  </svg>
);

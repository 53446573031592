import { Button, notification } from 'antd';
import firebase from 'firebase/app';
import React, { useState } from 'react';

import { createThumbnailImg, isDataURL, sendImageToStore } from '../../utils';
import './style.css';

const UploadDialogContent = (props: any) => {
  const { ShowNewVideoCanvas, createImageTask, profile, auth, selectedOrganizationData } = props;
  const [imageUrl, setImageUrl] = useState('');
  const [sendingImage, setSendingImage] = useState(false);
  const [thumbnailBlob, setThumbnailBlob] = useState('');
  // @ts-ignore
  const [isBase64ImageValid, setIsBase64ImageValid] = useState(true);
  const [blobSize, setBlobSize] = useState(0);
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  return (
    <>
      <div
        className='dialogBodyContainer'
        onPaste={(e: any) => {
          const items = e && (e.clipboardData || e.originalEvent.clipboardData).items;
          let blob = null;
          for (const item of items) {
            if (item.type.indexOf('image') === 0) {
              blob = item.getAsFile();
            }
          }
          if (blob !== null) {
            if (blob.size && userQuota) {
              if (auth && auth.uid) {
                userQuota.storageLimit = userQuota.storageLimit - blob.size / 1024;
                if (userQuota.storageLimit <= 0) {
                  notification.error({
                    message: 'Your current plan doesn’t include using this feature.',
                  });
                  return true;
                }
                const firestore = firebase.firestore();
                if (profile.isManagedUserQuotaInOrganization) {
                  firestore
                    .collection('organization')
                    .doc(selectedOrganizationData.id)
                    .update({
                      userQuota,
                    });
                } else {
                  firestore
                    .collection('users')
                    .doc(auth.uid)
                    .update({
                      userQuota,
                    });
                }
              }
            }

            setSendingImage(true);
            const reader = new FileReader();
            reader.onload = async (eData: any) => {
              if (isDataURL(eData.target.result)) {
                // Create Thubnail Here
                const thumbnailBase64Img = await createThumbnailImg(eData.target.result);
                setThumbnailBlob(thumbnailBase64Img);
                const originalImg: any = await sendImageToStore(eData.target.result);
                originalImg && setImageUrl(originalImg);
                setSendingImage(false);
              } else {
                setIsBase64ImageValid(true);
                setSendingImage(false);
              }
            };
            blob.size && setBlobSize(blob.size);
            reader.readAsDataURL(blob);
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        <input
          type='text'
          autoFocus
          style={{ visibility: 'visible', width: '0px', height: '0px', border: 'none' }}
        />
        <div className={'pb-10'}>
          {!imageUrl && !sendingImage && (
            <>
              <p>Paste base64 image </p>
              <div>Steps:</div>
              <div>
                1. Press{' '}
                {`${
                  /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
                    ? 'Ctrl+Shift+CMD+4 then press on the Spacebar in order to select window'
                    : 'PrtScrn / Ctrl+PrtScrn / Windows+PrtScrn / Alt+PrtScrn / Fn+PrtScrn'
                }`}{' '}
                to take screenshot.
              </div>
              <div>
                2. Press{' '}
                {`${/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? 'CMD+V' : 'Ctrl+V'}`} to
                paste it on this dialog.
              </div>
            </>
          )}
          {sendingImage ? (
            <div style={{ height: '165px', width: '450px' }} className='gif-loader' />
          ) : (
            ''
          )}
          {imageUrl && (
            <div className={'drop-image-preview'}>
              <div
                style={{
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  background: `url(${imageUrl})`,
                  height: '165px',
                  width: '450px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className='dialogFooter'>
        <div className='dialogCTAsContainer' style={{ justifyContent: 'flex-end' }}>
          <div>
            <Button
              className='cancelButton'
              onClick={() => {
                ShowNewVideoCanvas(false);
              }}
            >
              Cancel
            </Button>
          </div>
          <div
            onClick={() => createImageTask(imageUrl, '', thumbnailBlob, blobSize)}
            style={{ marginLeft: '10px' }}
          >
            <Button disabled={imageUrl ? false : true} className='uploadButton'>
              Upload
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDialogContent;

import { Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import * as EmailValidator from 'email-validator';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CustomAlert } from './CustomAlert';
import './emailMultipleSelect.css';

const { Option } = Select;

interface EmailSelectOptionProps {
  email: string;
  name: string;
}

interface EmailMultipleSelectProps {
  allEmails: string[];
  loginUserEmail: string;
  memberEMailEditable: boolean;
  emails: string[];
  invalidEmailsError: string;
  type: string;
  options: EmailSelectOptionProps[];
  auth: any;
  profile: any;
  invitedEmails?: any;
  organization: any;
  updateAllEmails(emails: string[]): void;
  updateValidEmails(emails: string[]): void;
  updateInvalidEmailsError(error: string): void;
}

const EmailMultipleSelect = (props: EmailMultipleSelectProps & FormComponentProps) => {
  const FormItem = Form.Item;
  const {
    form,
    memberEMailEditable,
    auth,
    updateAllEmails,
    allEmails,
    profile,
    invitedEmails,
    organization,
  } = props;
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = organization.selectOrganizationDetails.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  const { getFieldDecorator } = form;
  useEffect(() => {
    form.setFieldsValue({
      ['invitees']: allEmails ? allEmails : [],
    });
  }, [allEmails]);
  const { invalidEmailsError, type, options } = props;
  const emailValidator = (rule: any, values: any) => {
    const { updateValidEmails, updateInvalidEmailsError } = props;
    updateAllEmails(values || []);
    const validEmails: any = values.filter(
      (value: string) => EmailValidator.validate(value.trim()) && auth.email !== value.trim(),
    );
    updateValidEmails(validEmails);
    const invalidInputs = values.filter(
      (value: string) => !EmailValidator.validate(value.trim()) || auth.email === value.trim(),
    );
    if (type === 'tags' && invitedEmails.length + values.length > userQuota.totalUser) {
      updateInvalidEmailsError('You can not add more than 2 member in freelancer plan.');
    } else if (rule && invalidInputs.length === 0) {
      updateInvalidEmailsError('');
    } else if (invalidInputs.length === 1) {
      updateInvalidEmailsError(invalidInputs.join('') + ' is not a valid email');
    } else {
      updateInvalidEmailsError(
        invalidInputs.slice(0, -1).join(', ') +
          ' and ' +
          invalidInputs.slice(-1) +
          ' are not valid emails',
      );
    }
  };
  const children: any = [];
  if (type === 'multiple') {
    options.forEach((obj) => {
      children.push(<Option key={obj.email}>{obj.name ? obj.name : obj.email}</Option>);
    });
  }

  return (
    <div className={'email-multiple-select'}>
      <Form>
        <FormItem>
          {getFieldDecorator('invitees', {
            rules: [
              {
                validator: emailValidator,
              },
            ],
          })(
            <Select
              disabled={!memberEMailEditable}
              dropdownStyle={{ display: type === 'multiple' ? '' : 'none' }}
              mode={type}
              tokenSeparators={[',', ';', ' ']}
            >
              {children}
            </Select>,
          )}
        </FormItem>
        {type !== 'multiple' && (
          <span className={'copy-and-maste-msg'}>Copy and paste multiple emails</span>
        )}
        {invalidEmailsError && (
          <div className='invalid-members-email'>
            <CustomAlert message={invalidEmailsError} type='error' />
          </div>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    organization: state.organization,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create()(EmailMultipleSelect));

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Icon } from 'antd';
import ColorContext from 'context/ColorContext';
import { Zbutton } from 'primitives';
import React, { useState } from 'react';
import { mobileCheck } from '../../utils';
import DropDownCustom from '../dropDown';

const isMobile = mobileCheck();

const ProjectSelectDropdown = (props: any) => {
  const { selectedMasterProject, projectMenu, onClose } = props;
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div className={`${props.isStyle ? 'project-selection-1' : 'project-selection-2'}`}>
          <div className='workflow-container'>
            <span className='project-dropdown-label'>Workspace</span>
            {isMobile && (
              <span onClick={onClose} style={{ position: 'absolute', right: 10, top: 5 }}>
                <Icon type='close' />
              </span>
            )}
            {selectedMasterProject && selectedMasterProject.id ? (
              isMobile ? (
                <DropDownCustom
                  fixedPosition
                  buttonComponent={() => (
                    <Button
                      className='project-dropdown'
                      style={{
                        color: theme.text.color2,
                        ...(hover && { color: theme.primaryBtn.color2 }),
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {selectedMasterProject.name ? selectedMasterProject.name : 'No Workspace'}{' '}
                      <DownOutlined />
                    </Button>
                  )}
                  position={'bottom'}
                  items={projectMenu()}
                  classes={'mobileSelectDropDown'}
                />
              ) : (
                <Dropdown overlay={projectMenu()}>
                  <Button
                    className='project-dropdown'
                    style={{
                      color: theme.text.color2,
                      ...(hover && { color: theme.primaryBtn.color2 }),
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {selectedMasterProject.name ? selectedMasterProject.name : 'No Workspace'}{' '}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              )
            ) : (
              <Zbutton btnColors={theme}>
                {selectedMasterProject && selectedMasterProject.name
                  ? selectedMasterProject.name
                  : 'No Workspace'}{' '}
                <DownOutlined />
              </Zbutton>
            )}
          </div>
        </div>
      )}
    </ColorContext.Consumer>
  );
};

export default ProjectSelectDropdown;

import { Modal } from 'antd';
import { Col, Row } from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import React from 'react';
import { trackAnalytics } from '../../utils';

const UploadModal = (props: any) => {
  const { isUploadModal, setIsUploadModal, sendFeedback, pageID } = props;
  const [images, setImages] = React.useState([]);
  const [loading, setIsLoading] = React.useState(true);
  const imageClick = (image: any) => {
    sendFeedback(image.thumbnail_url, image.image_url, true, pageID, false, '', '');
    setIsUploadModal(false);
  };

  React.useEffect(() => {
    if (props.userID && isUploadModal) {
      firebase
        .firestore()
        .collection('users')
        .doc(props.userID)
        .get()
        .then((doc: any) => {
          const userScreenshots = doc.data().images;
          if (userScreenshots) {
            setImages(userScreenshots);
          }
          setIsLoading(false);
        });
    }
  }, [props.userID, isUploadModal]);

  return (
    <Modal
      title='Screenshots from chrome extension'
      visible={isUploadModal}
      footer={false}
      width={800}
      bodyStyle={{ height: 400 }}
      onCancel={() => setIsUploadModal(false)}
    >
      <div className={images.length ? 'image-view-container' : 'image-view-container align-center'}>
        {loading ? (
          <div className='loader-extension'>Loading...</div>
        ) : (
          <Row>
            {images.length ? (
              images.map((image: any, index: number) => {
                return (
                  <Col onClick={() => imageClick(image)} key={index} span={6}>
                    <div className='container-extenstion'>
                      <div
                        style={{ backgroundImage: `url(${image.thumbnail_url})` }}
                        className='image'
                      />

                      <div className='overlay-extenstion'>
                        <div className='text'>Send</div>
                      </div>
                      <div
                        style={{
                          fontWeight: 600,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'center',
                          height: '20px',
                        }}
                      >
                        {image.description}
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div>
                There are no screenshots taken by the chrome extension yet.{' '}
                <a
                  onClick={() => trackAnalytics('Chat window', 'Install chrome extenstion clicked')}
                  href='https://chrome.google.com/webstore/detail/feedbackhero-screenshot-t/fnfdbalaemoeodickdflahhnoibdgief'
                  target='_blank'
                >
                  Click here
                </a>{' '}
                to install chrome extension and take screenshots to see them here.
              </div>
            )}
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default UploadModal;

// import { FundProjectionScreenOutlined } from '@ant-design/icons';
import {
  Button,
  // Icon,
  notification,
  Tooltip,
} from 'antd';
import 'antd/dist/antd.css';
import firebase from 'firebase/app';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { trackAnalytics } from '../../../utils';
import { secondsToHms } from '../../../utils/index';
import { connectServer, startSendingChunks } from '../../../utils/realtimeStream';
// import RenderVideosBlob from '../../projects/RenderVideosBlob';
import { ReactMediaRecorder } from '../../recording/MediaRecorder';
import { RecordingTimer } from '../../shared/RecordingTimer';
import { RecordIcon } from './ChatSvgs';
import ChatVideoPlayer from './ChatVideoPlayer';

const { REACT_APP_IS_AWS_REAL_TIME_SAVING = 'false' } = process.env;
const isAWSRealTimeSaving = REACT_APP_IS_AWS_REAL_TIME_SAVING.trim() === 'true' ? true : false;
const VideoRecord = (props: any) => {
  const streamToBeClearedRef = useRef<any>(null);
  const [fileName, setFileName] = React.useState('');
  const [videoS3URL, setVideoS3URL] = React.useState('');
  const [isTimer, shouldStartTimer] = React.useState(false);
  const [startRecordingTitle, setStartRecordingTitle] = React.useState('Start Recording');
  const [isStopped, setIsStopped] = React.useState(false);
  const [isStopping, setIsStopping] = React.useState(false);
  const [remountComponent, setRemountComponent] = React.useState(false);

  // const [isStartClicked, setIsStartClicked] = React.useState(false);
  useEffect(() => {
    const recordingFileName = `recording-${uuid()}`;
    setFileName(recordingFileName);
    setVideoS3URL(
      `https://zooc-media-files.s3.eu-central-1.amazonaws.com/${recordingFileName}.m3u8`,
    );

    if (isAWSRealTimeSaving) {
      if (props.firebaseAuthToken) {
        isAWSRealTimeSaving && connectServer(props.firebaseAuthToken);
        firebaseToken = props.firebaseAuthToken;
      } else {
        getFirebaseToken();
      }
    }
  }, []);

  useEffect(() => {
    if (remountComponent) {
      setRemountComponent(false);
    }
  }, [remountComponent]);

  const counterText: any = useRef(null);
  const videoDivRef: any = useRef();
  const fiebaseInstance: any = firebase;
  let recordTimeInSeconds = 0;
  let startTime = moment().utc();
  let endTime = moment().utc();
  let firebaseToken = '';
  const {
    isRecordModal,
    loading,
    sendToFirebase,
    showSendButton,
    maxRecordTime,
    setIsRecording,
    setVideoUrl,
  } = props;

  useEffect(() => {
    return () => {
       // @ts-ignore
      if(window.stream){
       // @ts-ignore
         window.stream.getTracks().forEach((track: any) => {
          track.stop();
        });

          // @ts-ignore
         window.stream.getAudioTracks().forEach(function (track: any) {
          track.stop();
        });

         // @ts-ignore
         window.stream.getVideoTracks().forEach((track: any) => {
          track.stop();
        });
         // @ts-ignore
        window.stream = null;
      }
    };
  }, []);

  const getFirebaseToken = async () => {
    firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    connectServer(firebaseToken);
  };

  const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      navigator.getUserMedia(
        { audio: true, video: true },
        () => {
          setStartRecordingTitle('Start Recording');
        },
        () => {
          setStartRecordingTitle('Please allow to access microphone and camera.');
        },
      );
    }, []);

    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);

    if (!stream) {
      return null;
    }
    return (
      <div className='chat-window-video-container'>
        <video className='chat-window-video-component-recorder' ref={videoRef} autoPlay />
      </div>
    );
  };

  const stopVideoRecording = (message: string) => {
    streamToBeClearedRef.current &&
      streamToBeClearedRef.current.getTracks().forEach((track: any) => {
        track.stop();
      });
      streamToBeClearedRef.current &&
      streamToBeClearedRef.current.getVideoTracks().forEach((track: any) => {
        track.stop();
      });
    setIsStopped(true);
    shouldStartTimer(false);
    setIsRecording(false);
    trackAnalytics('Chat window', message);
  };
  return (
    <div style={{ height: '214px', width: '268px', zIndex: 1 }} className='d-flex'>
      {isRecordModal !== '' && !remountComponent && (
        <ReactMediaRecorder
          fileName={fileName}
          video={isRecordModal === 'video'}
          screen={isRecordModal === 'screen'}
          onStop={(BlobUrl) => {
            !showSendButton ? sendToFirebase(BlobUrl, videoS3URL) : setVideoUrl(BlobUrl);
          }}
          render={({
            status,
            startRecording,
            stopRecording,
            mediaBlobUrl,
            previewStream,
            error,
          }: any) => {
            if (!isTimer && status === 'recording') {
              setIsStopped(true);
              stopRecording();
              trackAnalytics('Chat window', 'Stop recording');
              // streamToBeClearedRef.current &&
              //   streamToBeClearedRef.current.getTracks().forEach((track: any) => {
              //     track.stop();
              //   });
            }
            if (error !== '' && isTimer) {
              stopRecording();
              setIsStopped(true);
              shouldStartTimer(false);
            }
            if (error !== '') {
              return (
                <div>
                  <div>Please allow permissions!</div>
                  <div className='d-flex'>
                    <Button
                      className={'audio-record-send-btn'}
                      type='primary'
                      onClick={() => setRemountComponent(true)}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            }
            if (isTimer && (status === 'idle' || status === 'stopped')) {
              startRecording();
              setIsStopped(false);
              // setIsStartClicked(true);
            }
            streamToBeClearedRef.current = previewStream;
            if (isRecordModal === 'video') {
              return (
                <div>
                  <div style={{ position: 'relative' }}>
                    <div ref={counterText} className='recordingCountDown-video'></div>
                    {status === 'recording' && <VideoPreview stream={previewStream} />}
                  </div>

                  {status === 'stopping' && (
                    <div className='d-flex' style={{ height: '175px' }}>
                      <img
                        style={{ height: '43px', width: '80px' }}
                        src='/images/small-loader.gif'
                        alt='Loading...'
                      />
                    </div>
                  )}
                  {(status === 'idle' || status === 'stopped' || status === 'acquiring_media') && (
                    <div
                      ref={videoDivRef}
                      style={{
                        height: '175px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          height: '175px',
                          width: '100%',
                        }}
                      >
                        {/* <RenderVideosBlob
                          className={'chat-window-video-component'}
                          videos={[mediaBlobUrl]}
                          id={'video'}
                          showPlayIcon
                          unMute
                        /> */}
                        {/* <div
                          className={` chat-window-message chat-window-hovered-background`}
                        > */}
                        <ChatVideoPlayer
                          isMediaCreatedByLoginUser={true}
                          createdTime={moment()
                            .utc()
                            .valueOf()}
                          blobURL={mediaBlobUrl}
                          videos={[mediaBlobUrl]}
                          parentComponent='feedbackModal-Parent'
                          containerClass=''
                          setShowCinemaModal={() => null}
                        />
                        {/* </div> */}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      justifyContent: 'space-between',
                      height: '39px',
                      padding: '0 10px',
                    }}
                    className='media-control-recorder d-flex'
                  >
                    <div className='d-flex'>
                      <Tooltip placement='top' title={startRecordingTitle}>
                        {/* status === 'recording' */}
                        {/* htmlType='submit' */}
                        {/* disabled={status === 'recording' || isStartClicked} */}
                        <div
                          className='d-flex c-p'
                          style={{ display: status === 'recording' ? 'none' : 'flex' }}
                          onClick={async () => {
                            if (startRecordingTitle === 'Start Recording') {
                              const windowObj: any = window;
                              if (windowObj && !windowObj.isSocketConnected) {
                                notification.error({
                                  message:
                                    'Something went wrong with socket connection, Please try again or contact with admin.',
                                });
                                return false;
                              }
                              startSendingChunks(fileName);
                              startTime = moment().utc();
                              setIsStopped(false);
                              // setIsStartClicked(true);
                              if (
                                videoDivRef &&
                                videoDivRef.current &&
                                videoDivRef.current.clientHeight
                              ) {
                                // counterText.current.style.height = `${videoDivRef.current.clientHeight}px`;
                              }
                              counterText.current.style.display = 'flex';
                              const interval = setInterval(() => {
                                const currentCounterString =
                                  (counterText &&
                                    counterText.current &&
                                    counterText.current.innerText) ||
                                  '4';
                                const currentCounterArray = currentCounterString.split(' ');
                                let currentCounterValue = parseInt(
                                  currentCounterArray[currentCounterArray.length - 1],
                                  10,
                                );
                                if (currentCounterValue <= 1) {
                                  clearInterval(interval);
                                  shouldStartTimer(true);
                                  setIsRecording(true);
                                  trackAnalytics('Chat window', 'Recording video');
                                  counterText.current.style.display = 'none';
                                } else if (counterText.current) {
                                  counterText.current.innerHTML = `<div>${--currentCounterValue}</div>`;
                                }
                              }, 600);
                            }
                          }}
                        >
                          <RecordIcon />
                        </div>
                      </Tooltip>
                      <Tooltip placement='top' title={'Stop Recording'}>
                        {/* isStopping */}
                        {/* htmlType='submit' */}
                        {/*  */} {/* disabled */}
                        {/* {!isStopping && <Icon type='stop' />} */}
                        <div
                          style={{
                            display: !(status === 'recording' && !isStopping) ? 'none' : 'flex',
                          }}
                          className='d-flex c-p'
                          onClick={() => {
                            setIsStopping(true);
                            endTime = moment().utc();
                            recordTimeInSeconds = moment(endTime).diff(
                              moment(startTime),
                              'seconds',
                            );
                            if (recordTimeInSeconds >= 4) {
                              setIsStopping(false);
                              stopRecording();
                              stopVideoRecording('Video recording stop');
                            } else {
                              setTimeout(() => {
                                setIsStopping(false);
                                stopRecording();
                                stopVideoRecording('Video recording stop');
                              }, (4 - recordTimeInSeconds) * 1000);
                            }
                          }}
                        >
                          {/* <PauseIcon /> */}
                          <span
                            style={{
                              height: '24px',
                              width: '24px',
                              backgroundColor: 'red',
                              borderRadius: '5px',
                            }}
                          ></span>
                        </div>
                      </Tooltip>
                      {!isStopping && (
                        <div style={{ padding: '0 10px' }} className='d-flex'>
                          {isTimer ? (
                            <RecordingTimer
                              hideText
                              startTimer={shouldStartTimer}
                              maxRecordTime={maxRecordTime}
                            />
                          ) : (
                            <span>{secondsToHms(maxRecordTime * 60)}</span>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      {showSendButton && isStopped && (
                        <Button
                          className='audio-record-cln-btn'
                          style={{ marginRight: '5px' }}
                          htmlType='submit'
                          loading={loading}
                          onClick={() => {
                            setIsStopped(false);
                            setVideoUrl('');
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                      {showSendButton && isStopped && (
                        <Button
                          className={'audio-record-send-btn'}
                          type='primary'
                          htmlType='submit'
                          loading={loading}
                          onClick={() => {
                            setIsStopped(false);
                            setVideoUrl('');
                            sendToFirebase(mediaBlobUrl, videoS3URL);
                          }}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div>
                  {/* {isTimer && (
                  <RecordingTimer startTimer={shouldStartTimer} maxRecordTime={maxRecordTime} />
                )} */}
                  <div style={{ position: 'relative' }}>
                    <div ref={counterText} className='recordingCountDown-video'></div>
                    {status === 'recording' && (
                      <div>
                        <VideoPreview stream={previewStream} />
                      </div>
                    )}
                  </div>
                  {status === 'stopping' && (
                    <div className='d-flex' style={{ height: '175px' }}>
                      {' '}
                      <img
                        style={{ height: '43px', width: '80px' }}
                        src='/images/small-loader.gif'
                        alt='Loading...'
                      />
                    </div>
                  )}
                  {(status === 'idle' || status === 'stopped' || status === 'acquiring_media') && (
                    <div
                      ref={videoDivRef}
                      style={{
                        height: '175px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {/* <div style={{ height: '175px', width: '100%' }}>
                        <RenderVideosBlob
                          className={'chat-window-video-component'}
                          videos={[mediaBlobUrl]}
                          id={'screen'}
                          showPlayIcon
                          unMute
                        />
                      </div> */}

                      <ChatVideoPlayer
                        isMediaCreatedByLoginUser={true}
                        createdTime={moment()
                          .utc()
                          .valueOf()}
                        blobURL={mediaBlobUrl}
                        videos={[mediaBlobUrl]}
                        parentComponent='feedbackModal-Parent'
                        containerClass=''
                        setShowCinemaModal={() => null}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      justifyContent: 'space-between',
                      height: '39px',
                      padding: '0 10px',
                    }}
                    className='media-control-recorder d-flex'
                  >
                    <div className='d-flex c-p'>
                      <Tooltip placement='top' title={startRecordingTitle}>
                        <div
                          // disabled={status === 'recording' || isStartClicked}
                          style={{ display: status === 'recording' ? 'none' : 'flex' }}
                          onClick={async () => {
                            if (startRecordingTitle === 'Start Recording') {
                              const windowObj: any = window;
                              if (windowObj && !windowObj.isSocketConnected) {
                                notification.error({
                                  message:
                                    'Something went wrong with socket connection, Please try again or contact with admin.',
                                });
                                return false;
                              }
                              startSendingChunks(fileName);
                              startTime = moment().utc();
                              setIsStopped(false);
                              // setIsStartClicked(true);
                              if (
                                videoDivRef &&
                                videoDivRef.current &&
                                videoDivRef.current.clientHeight
                              ) {
                                // counterText.current.style.height = `${videoDivRef.current.clientHeight}px`;
                              }
                              counterText.current.style.display = 'flex';
                              counterText.current.innerHTML = '<div>3</div>';
                              const interval = setInterval(() => {
                                const currentCounterString =
                                  (counterText &&
                                    counterText.current &&
                                    counterText.current.innerText) ||
                                  '4';
                                const currentCounterArray = currentCounterString.split(' ');
                                let currentCounterValue = parseInt(
                                  currentCounterArray[currentCounterArray.length - 1],
                                  10,
                                );
                                if (currentCounterValue <= 1) {
                                  clearInterval(interval);
                                  shouldStartTimer(true);
                                  setIsRecording(true);
                                  trackAnalytics('Chat window', 'Screen Recording');
                                  counterText.current.style.display = 'none';
                                } else if (counterText.current) {
                                  counterText.current.innerHTML = `<div>${--currentCounterValue}</div>`;
                                }
                              }, 600);
                            }
                          }}
                        >
                          <RecordIcon />
                        </div>
                      </Tooltip>
                      <Tooltip placement='top' title={'Stop Recording'}>
                        <div
                          style={{
                            display: !(status === 'recording' && !isStopping) ? 'none' : 'flex',
                          }}
                          // loading={isStopping}
                          // type='primary'
                          // shape={'circle'}
                          // htmlType='submit'
                          className='d-flex c-p'
                          // disabled={!(status === 'recording' && !isStopping)}
                          onClick={() => {
                            setIsStopping(true);
                            endTime = moment().utc();
                            recordTimeInSeconds = moment(endTime).diff(
                              moment(startTime),
                              'seconds',
                            );
                            if (recordTimeInSeconds >= 4) {
                              setIsStopping(false);
                              stopRecording();
                              stopVideoRecording('Screen recording stop');
                            } else {
                              setTimeout(() => {
                                setIsStopping(false);
                                stopRecording();
                                stopVideoRecording('Screen recording stop');
                              }, (4 - recordTimeInSeconds) * 1000);
                            }
                          }}
                        >
                          {!isStopping && (
                            <span
                              style={{
                                height: '24px',
                                width: '24px',
                                backgroundColor: 'red',
                                borderRadius: '5px',
                              }}
                            ></span>
                          )}
                        </div>
                      </Tooltip>
                      {!isStopping && (
                        <div style={{ padding: '0 5px 0 5px' }} className='d-flex'>
                          {isTimer ? (
                            <RecordingTimer
                              hideText
                              startTimer={shouldStartTimer}
                              maxRecordTime={maxRecordTime}
                            />
                          ) : (
                            <span>{secondsToHms(maxRecordTime * 60)}</span>
                          )}
                        </div>
                      )}
                    </div>

                    <div>
                      {showSendButton && isStopped && (
                        <Button
                          className='audio-record-cln-btn'
                          style={{ marginRight: '5px' }}
                          htmlType='submit'
                          loading={loading}
                          onClick={() => {
                            setIsStopped(false);
                            setVideoUrl('');
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                      {showSendButton && isStopped && (
                        <Button
                          type='primary'
                          className={'audio-record-send-btn'}
                          htmlType='submit'
                          loading={loading}
                          onClick={() => {
                            setIsStopped(false);
                            setVideoUrl('');
                            sendToFirebase(mediaBlobUrl, videoS3URL);
                          }}
                        >
                          {/* {!loading && <Icon type='upload' />} */}
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
          }}
        />
      )}
    </div>
  );
};

export default VideoRecord;

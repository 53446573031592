import React from 'react';

export default () => (
  <svg width='33' height='30' viewBox='0 0 33 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.61 3.41L7.19 9H2C1.46957 9 0.960859 9.21071 0.585786 9.58579C0.210714 9.96086 0 10.4696 0 11V19C0 19.5304 0.210714 20.0391 0.585786 20.4142C0.960859 20.7893 1.46957 21 2 21H7.14L14.55 28.47C14.7445 28.65 14.9729 28.7896 15.2218 28.8805C15.4707 28.9715 15.7352 29.0121 16 29C16.2607 29 16.5189 28.9491 16.76 28.85C17.1268 28.6993 17.4405 28.443 17.6613 28.1136C17.8821 27.7842 18 27.3966 18 27V19.77L23.89 25.66C23.64 25.81 23.4 25.95 23.14 26.08C22.9026 26.1993 22.7224 26.4081 22.6389 26.6603C22.5555 26.9126 22.5757 27.1876 22.695 27.425C22.8143 27.6624 23.0231 27.8426 23.2753 27.9261C23.5276 28.0095 23.8026 27.9893 24.04 27.87C24.4895 27.6431 24.9267 27.3927 25.35 27.12L27.63 29.4L29 28L3 2L1.61 3.41ZM16 27L8.27 19.23C8.07188 19.0646 7.81748 18.9822 7.56 19H2V11H7.64C7.77231 10.9995 7.90319 10.9727 8.02507 10.9212C8.14694 10.8697 8.25739 10.7945 8.35 10.7L8.61 10.44L16 17.81V27Z'
      fill='black'
    />
    <path
      d='M22.89 3.69006C24.6329 4.50414 26.163 5.71196 27.3594 7.21829C28.5559 8.72463 29.3861 10.4883 29.7846 12.3703C30.1831 14.2522 30.139 16.2011 29.6558 18.0631C29.1726 19.9251 28.2634 21.6494 27 23.1001L28.42 24.5201C29.9115 22.8426 30.9907 20.8401 31.5719 18.672C32.153 16.5039 32.22 14.23 31.7675 12.0315C31.3149 9.83291 30.3554 7.77036 28.9652 6.008C27.5751 4.24565 25.7927 2.83216 23.76 1.88006C23.6413 1.81856 23.5115 1.78149 23.3783 1.77102C23.245 1.76055 23.111 1.7769 22.9842 1.8191C22.8573 1.86131 22.7403 1.92851 22.6399 2.01674C22.5394 2.10497 22.4577 2.21243 22.3996 2.33278C22.3414 2.45312 22.308 2.58391 22.3012 2.7174C22.2944 2.85089 22.3145 2.98439 22.3602 3.10999C22.406 3.23559 22.4764 3.35076 22.5674 3.44867C22.6584 3.54659 22.7681 3.62526 22.89 3.68006V3.69006Z'
      fill='black'
    />
    <path
      d='M20.69 9.61997C21.6312 10.1692 22.413 10.9542 22.9584 11.8976C23.5037 12.841 23.7938 13.9103 23.8 15C23.8 16.3384 23.3648 17.6405 22.56 18.71L24 20.13C25.1695 18.6764 25.8048 16.8656 25.8 15C25.7935 13.5607 25.4119 12.148 24.6929 10.9012C23.974 9.65441 22.9424 8.61654 21.7 7.88997C21.5862 7.81762 21.4589 7.76903 21.3258 7.74715C21.1927 7.72526 21.0566 7.73051 20.9256 7.7626C20.7946 7.79469 20.6715 7.85294 20.5636 7.93386C20.4557 8.01477 20.3652 8.11668 20.2977 8.23344C20.2302 8.35021 20.1871 8.47942 20.1708 8.6133C20.1545 8.74719 20.1655 8.88298 20.2031 9.01251C20.2407 9.14204 20.3041 9.26262 20.3895 9.36702C20.4749 9.47141 20.5805 9.55746 20.7 9.61997H20.69Z'
      fill='black'
    />
    <path
      d='M16 3.00001V12.15L18 14.15V3.00001C18.0003 2.60361 17.8828 2.21606 17.6625 1.88656C17.4421 1.55707 17.1288 1.30048 16.7623 1.14939C16.3958 0.998304 15.9927 0.959523 15.6042 1.03797C15.2156 1.11642 14.8591 1.30857 14.58 1.59001L10 6.17001L11.41 7.58001L16 3.00001Z'
      fill='black'
    />
  </svg>
);

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M1.63636 0H11.4545C12.3583 0 13.0909 0.732625 13.0909 1.63636V2.76704L18 0.312511V11.142L13.0909 8.6875V9.81818C13.0909 10.7219 12.3583 11.4545 11.4545 11.4545H1.63636C0.732625 11.4545 0 10.7219 0 9.81818V1.63636C0 0.732625 0.732625 0 1.63636 0ZM13.0909 6.85799L16.3636 8.49433V2.9602L13.0909 4.59655V6.85799ZM1.63636 1.63636V9.81818H11.4545V1.63636H1.63636Z'
      fill={color || '#001DAD'}
    />
  </svg>
);

export default Icon;

import { Button, Modal, notification } from 'antd';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { IMemberUser } from 'types/projectTypes';
import { trackAnalytics, validateEmail } from 'utils';
import { statsAction } from '../../../store/actions/authActions';
import '../tags/tags.css';

interface IProps {
  closeModal: () => void;
  projectId?: string;
  visible: boolean;
  memberList: IMemberUser[];
  addedMembers: IMemberUser[];
  workspace?: boolean;
  masterProjectId?: string;
  organizationDetail?: any;
  isAllowNewMember?: any;
  selectedOrganizationMatchedIndex?: any;
  auth?: any;
}
const CanvasTagDialog: React.FC<IProps> = ({
  closeModal,
  visible,
  memberList,
  projectId,
  addedMembers,
  workspace,
  masterProjectId,
  organizationDetail,
  isAllowNewMember = false,
  selectedOrganizationMatchedIndex,
  auth
}) => {
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const defaultSelctedValue =
      addedMembers.map((member: IMemberUser) => {
        return { value: member.id, label: member.name, image: member.image };
      }) || [];
    setSelectedOption(JSON.parse(JSON.stringify(defaultSelctedValue)));
  }, [addedMembers, visible]);

  const handleChange = (option: any) => {
    setSelectedOption(option);
  };

  const handleSubmitMember = async () => {
    setIsLoading(true);
    const invalidEmails: any = [];
    if (isAllowNewMember) {
      trackAnalytics('Profile', 'Invited a User');
      let addedEmails = [];
      addedEmails = selectedOption.filter((option) => option.__isNew__)
      addedEmails = addedEmails.map((addedEmail) => addedEmail.value);
      addedEmails.forEach((value) => {
        if (!validateEmail(value)) {
          invalidEmails.push(value);
        }
      });
      if (invalidEmails.length) {
        const invalidEmailsError = `${invalidEmails.join(',')} ${invalidEmails.length === 1 ? 'is' : 'are'
          } not a valid email address.`;
        notification.error({
          message: invalidEmailsError,
        });
        setIsLoading(false);
        return true;
      } else {
        const oldMembers =
          organizationDetail &&
            organizationDetail[selectedOrganizationMatchedIndex] &&
            organizationDetail[selectedOrganizationMatchedIndex].members
            ? organizationDetail[selectedOrganizationMatchedIndex].members
            : [];
        const duplicateMembers = addedEmails.filter((email: string) =>
          oldMembers.some((member: { email: string }) => email === member.email),
        );
        if (!duplicateMembers.length) {
          const firestore = firebase.firestore();
          const addedEmailsWithStatus: any = [];
          // for (let i = 0; i < addedEmails.length; i++) {
          for (const email of addedEmails) {
            //  const email = addedEmails[i];
            let name = '';
            let image = '';
            const memberUserDetail: any = await firestore
              .collection('users')
              .where('email', '==', email)
              .get();
            if (memberUserDetail && memberUserDetail.docs && memberUserDetail.docs.length) {
              const memberUsersDetailDocs = memberUserDetail.docs[0];
              const memberUserData = memberUsersDetailDocs.data();
              name = memberUserData.fullName || '';
              image = memberUserData.photoURL || '';
            }
            addedEmailsWithStatus.push({
              name,
              image,
              status: 'PENDING',
              email,
              role: 'Admin',
              invite_by: auth.uid,
            });
          }

          const members = oldMembers.concat(addedEmailsWithStatus);
          // setIsLoading(true);
          if (organizationDetail && organizationDetail[selectedOrganizationMatchedIndex]) {
            firestore
              .collection('organization')
              .doc(organizationDetail[selectedOrganizationMatchedIndex].id)
              .set(
                {
                  members,
                },
                { merge: true },
              )
              .then(() => {
                statsAction('INVITE_MEMBER', firestore);
              //  setIsLoading(false);
              })
              .catch(() => {
              // setIsLoading(false);
              //  return false;
              });
          }
        } else {
          const alreadyMemberError = `Already send an invitation on ${duplicateMembers.join(
            ',',
          )}`;
          notification.error({
            message: alreadyMemberError,
          });
          setIsLoading(false);
          // return true;
        }
      }
    }

    // return false;
    const members = selectedOption
      ? selectedOption.map((opt: any) => {
        return { image: opt.image || '' , name: opt.label || '', id: opt.value || '' };
      })
      : [];
    if(!invalidEmails.length){
    if (!workspace) {
      const firestore: any = firebase.firestore();
      return firestore
        .collection('projects')
        .doc(projectId)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            trackAnalytics('Members', 'Done Clicked');
            const data = doc.data();
            if (data.members && members) {
              const selectedMembers = members;
              const selectedMembersIds = selectedMembers.map(
                (selectedMember: any) => selectedMember.id,
              );
              firestore
                .collection('projects')
                .doc(doc.id)
                .set({ ...data.members, members, selectedMembersIds }, { merge: true })
                .then(() => {
                  setSelectedOption([]);
                  setIsLoading(false);
                  closeModal();
                })
                .catch(() => {
                  setIsLoading(false);
                  return false;
                });
            } else if (members) {
              const selectedMembersIds = members.map((selectedMember: any) => selectedMember.id);
              firestore
                .collection('projects')
                .doc(doc.id)
                .set({ members, selectedMembersIds }, { merge: true })
                .then(() => {
                  setSelectedOption([]);
                  setIsLoading(false);
                  closeModal();
                })
                .catch(() => {
                  setIsLoading(false);
                  return false;
                });
            }
          }
        });
    } else {
      if (members && members.length) {
        const firestore: any = firebase.firestore();
        return firestore
          .collection('masterProjects')
          .doc(masterProjectId)
          .get()
          .then((doc: any) => {
            if (doc.exists) {
              const data = doc.data();
              if (data.members && members) {
                const selectedMembers = members;
                const selectedMembersIds = selectedMembers.map(
                  (selectedMember: any) => selectedMember.id,
                );
                const updatedTimeStamp = new Date().getTime();
                firestore
                  .collection('masterProjects')
                  .doc(doc.id)
                  .set(
                    { ...data.members, members, member_ids: selectedMembersIds, updatedTimeStamp },
                    { merge: true },
                  )
                  .then(() => {
                    setSelectedOption([]);
                    setIsLoading(false);
                    closeModal();
                  })
                  .catch(() => {
                    setIsLoading(false);
                    return false;
                  });
              } else if (members) {
                const selectedMembersIds = members.map((selectedMember: any) => selectedMember.id);
                const updatedTimeStamp = new Date().getTime();
                firestore
                  .collection('masterProjects')
                  .doc(doc.id)
                  .set(
                    { members, member_ids: selectedMembersIds, updatedTimeStamp },
                    { merge: true },
                  )
                  .then(() => {
                    setSelectedOption([]);
                    setIsLoading(false);
                    closeModal();
                  })
                  .catch(() => {
                    setIsLoading(false);
                    return false;
                  });
              }
            }
          });
      } else {
        setSelectedOption([]);
        setIsLoading(false);
        closeModal();
      }
    }
  }
  };

  let options = memberList.map((member: IMemberUser) => {
    return { value: member.id, label: member.name, image: member.image, status: member.status };
  });

  options = options.filter((opt: any) => (opt.status ? opt.status === 'ACTIVE' : true));
  return (
    <div>
      <Modal visible={visible} onOk={closeModal} onCancel={() => closeModal()} footer={null}>
        <div className='tag-dialog-container'>
          <h2>Members</h2>
          <p>Members can be used to easily categorize and filter canvases. </p>
          <CreatableSelect
            onChange={handleChange}
            value={selectedOption}
            options={options}
            isMulti
            closeMenuOnSelect={false}
            placeholder='Select a member from here.'
          />
          <div className='add-tag-btn-container' style={{ marginTop: 50 }}>
            <Button
              className='cancel tag-btn'
              onClick={() => {
                trackAnalytics('Members', 'Close Member Modal Clicked');
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              className='add tag-btn'
              key='submit'
              type='primary'
              onClick={handleSubmitMember}
              loading={isLoading}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CanvasTagDialog;

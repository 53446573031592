import { Form, Icon, Modal, notification, Typography } from 'antd';
import firebase from 'firebase/app';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import ColorContext from '../../context/ColorContext';
import { trelloConnectAction } from '../../store/actions/trelloIntegrationAction';
import { trackAnalytics } from '../../utils';
import { CircleRightClick } from '../svg';
import { Zbutton, Zinput } from './../../primitives';
import './index.css';

interface IProps {
  profile: any;
  auth: any;
  selectedOrganizationData: any;
}

const TrelloConnect: React.FC<IProps> = ({ profile, auth, selectedOrganizationData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trelloKey, setTrelloKey] = useState(profile.trelloKey || '');
  const [trelloAPIToken, setTrelloAPIToken] = useState(profile.trelloAPIToken || '');
  const [apiError, setApiError] = useState<any>('success');
  const [apiTokenError, setApiTokenError] = useState<any>('success');
  const [trelloModalVisible, setTrelloModalVisible] = useState(false);

  const handleIntegrateCancel = () => {
    setTrelloModalVisible(false);
  };

  const trelloDisconnected = (e: any) => {
    e.stopPropagation();
    Modal.confirm({
      content: '',
      title: 'Are you sure, you want to be disconnected from trello account?',
      onOk() {
        setTrelloKey('');
        setTrelloAPIToken('');
        const firestore = firebase.firestore();
        const profileData = profile;
        const loginUserId = auth.uid;
        profileData.trelloKey = '';
        profileData.trelloAPIToken = '';
        profileData.trelloConnect = false;
        firestore
          .collection('users')
          .doc(loginUserId)
          .set(profileData, { merge: true });
      },
    });
  };

  const connectTrelloAccount = async () => {
    trackAnalytics('Integration', 'Connect to trello account clicked');
    const fiebaseInstance: any = firebase;
    !trelloKey && setApiError('error');
    !trelloAPIToken && setApiTokenError('error');
    const firestore = firebase.firestore();
    if (trelloKey && trelloAPIToken) {
      const loginUserId = auth.uid;
      const profileData = profile;
      profileData.trelloKey = trelloKey;
      profileData.trelloAPIToken = trelloAPIToken;
      const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
      setIsLoading(true);
      firestore
        .collection('users')
        .doc(loginUserId)
        .set(profileData, { merge: true })
        .then(() => {
          trelloConnectAction(firebaseToken).then((resMessage: any) => {
            if (resMessage.error) {
              profileData.trelloKey = '';
              profileData.trelloAPIToken = '';
              firestore
                .collection('users')
                .doc(loginUserId)
                .set(profileData, { merge: true });
              notification.error({
                message: resMessage.message,
              });
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          });
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  const FormItem = Form.Item;
  const trelloConnect = (profile && profile.trelloConnect) || false;
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <Fragment>
          <div className={`trello-connect-btn ${trelloConnect ? 'connected-trello' : ''}`}>
            {trelloConnect ? (
              <Zbutton secondary btnColors={theme} onClick={trelloDisconnected}>
                Disconnect
              </Zbutton>
            ) : (
              <Zbutton
                primary
                btnColors={theme}
                disabled={trelloConnect || (userQuota && !userQuota.trelloIntegration)}
                onClick={() => {
                  if (userQuota && userQuota.trelloIntegration) {
                    setTrelloModalVisible(true);
                  }
                }}
              >
                Connect
              </Zbutton>
            )}
          </div>
          <Modal
            title={trelloConnect ? '' : 'Connect Trello'}
            visible={trelloModalVisible}
            onOk={handleIntegrateCancel}
            onCancel={handleIntegrateCancel}
            footer={null}
          >
            {trelloConnect ? (
              <div className='trello-success-container'>
                <span className='trello-success-message'>
                  <CircleRightClick />
                  <div style={{ marginLeft: 10 }}>Trello is connected</div>
                </span>
              </div>
            ) : (
              <div className='trello-integration-container'>
                <Form style={{ color: theme.text.color2 }}>
                  <div className='form-field step-trello-api'>
                    <Typography.Text strong={true}>Trello API Key*</Typography.Text>
                    <FormItem
                      validateStatus={apiError}
                      help={apiError === 'error' && 'Trello API key is required.'}
                    >
                      <Zinput
                        placeholder='Trello API key'
                        value={trelloKey}
                        onChange={(e: any) => {
                          setTrelloKey(e.target.value);
                          setApiError(e.target.value ? 'success' : 'error');
                        }}
                      />
                    </FormItem>
                  </div>
                  {trelloConnect ? null : (
                    <div className='form-field step-trello-api-token'>
                      <Typography.Text strong={true}>Trello API Token*</Typography.Text>
                      <FormItem
                        validateStatus={apiTokenError}
                        help={apiTokenError === 'error' && 'Trello API Token is required.'}
                      >
                        <Zinput
                          placeholder='Trello API Token'
                          value={trelloAPIToken}
                          onChange={(e: any) => {
                            setTrelloAPIToken(e.target.value);
                            setApiTokenError(e.target.value ? 'success' : 'error');
                          }}
                        />
                      </FormItem>
                      <a
                        onClick={() => trackAnalytics('Integration', 'Get api token clicked')}
                        target='_blank'
                        href={'https://trello.com/app-key'}
                      >
                        <span
                          className='integration-api'
                          style={{ color: theme.primaryBtn.color1 }}
                        >
                          Get your API token from here
                          <Icon type='key' style={{ marginLeft: 5 }} />
                        </span>
                      </a>
                    </div>
                  )}
                </Form>
                <div style={{ width: '100%', textAlign: 'end' }}>
                  <Zbutton
                    primary
                    btnColors={theme}
                    style={{ width: 180 }}
                    loading={isLoading}
                    onClick={connectTrelloAccount}
                  >
                    Connect my Trello account
                  </Zbutton>
                </div>
              </div>
            )}
          </Modal>
        </Fragment>
      )}
    </ColorContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(TrelloConnect);

import { Alert, Button, Card, Form, Icon, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form'; // tslint:disable-line
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import {
  clearAuthState as clearAuthAction,
  forgotPassword as forgotPasswordAction,
  resetPassword as resetPasswordAction,
  verifyToken as verifyTokenAction,
} from '../../store/actions/authActions';
import { IAuthAction, Type } from '../../types/authTypes';
import { ButtonEvent } from '../../types/syntheticEventsTypes';
import Spinner from '../shared/spinner';
import './auth.css';

const FormItem = Form.Item;
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      offset: 4,
      span: 16,
    },
    xs: {
      offset: 0,
      span: 24,
    },
  },
};

// interface ICreds {
//   email: string;
//   password: string;
// }

interface IProps extends FormComponentProps {
  children?: JSX.Element | JSX.Element[];
  forgotPassword: (email: string) => void;
  verifyToken: (token: string) => void;
  resetPassword: (token: string, newPass: string) => void;
  clearAuthState: () => void;
  authError: string;
  status: string;
  auth: {
    isLoaded: boolean;
    uid: string;
  };
}
interface ITParams {
  mode: string;
  oobCode: string;
}
const AuthAction: React.SFC<IProps & RouteComponentProps<ITParams>> = ({
  form,
  status,
  // forgotPassword,
  authError,
  auth,
  verifyToken,
  resetPassword,
  location,
  clearAuthState,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  // will uncomment on adding functionality
  useEffect(() => {
    status === Type.StatusRunning ? setLoading(true) : setLoading(false);
    if (status === Type.verifyTokenError) {
      return () => clearAuthState();
    }
    const params = new URLSearchParams(location.search);
    const paramToken: any = params.get('oobCode');
    const mode: any = params.get('mode');
    if (mode === 'resetPassword' && paramToken) {
      verifyToken(paramToken);
      setToken(paramToken);
    }
    return () => clearAuthState();
  }, [setLoading, clearAuthState, location.search, status, verifyToken]);
  const handleSubmit = (e: ButtonEvent) => {
    e.preventDefault();
    return form.validateFields(
      (err: any, values: { password: string; confirmPassword: string }) => {
        err = values.password !== values.confirmPassword;
        if (err) {
          return notification.error({
            description: 'Password & Confirm Password should be same',
            message: 'Invalid Password',
          });
        }
        if (!err) {
          return resetPassword(token, values.password);
        }
      },
    );
  };
  if (auth.isLoaded && auth.uid) {
    return <Redirect to='/' />;
  }
  if (auth.isLoaded && !auth.uid) {
    return (
      <div className='outer-container'>
        <Card className='form-container'>
          {status === Type.resetPasswordSuccess ? (
            <AlertInfo
              message='Password has been changed!'
              description={
                <div>
                  You can login to your account with new password by clicking here{' '}
                  <Link to='/signIn'>Login</Link>
                </div>
              }
              type='success'
            />
          ) : status === Type.verifyTokenError ? (
            <AlertInfo
              message='Verify Token Failed'
              description={
                <div>
                  {authError}
                  <div>
                    <Link to='/signIn'>Login</Link>
                  </div>
                </div>
              }
              type='error'
            />
          ) : status === Type.verifyTokenSuccess ? (
            <NewPasswordForm form={form} loading={loading} handleSubmit={handleSubmit} />
          ) : (
            <Spinner />
          )}
        </Card>
      </div>
    );
  }
  return <Spinner />;
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any };
}) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    status: state.auth.status,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    clearAuthState: () => dispatch(clearAuthAction()),
    forgotPassword: (email: string) => dispatch(forgotPasswordAction(email)),
    resetPassword: (token: string, newPass: string) =>
      dispatch(resetPasswordAction(token, newPass)),
    verifyToken: (token: string) => dispatch(verifyTokenAction(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create<IProps>()(AuthAction));

interface INewPasswordForm extends FormComponentProps {
  handleSubmit: (e: ButtonEvent) => void;
  loading: boolean;
}
const NewPasswordForm: (props: INewPasswordForm) => JSX.Element = ({
  handleSubmit,
  form,
  loading,
}) => (
  <Form className='form'>
    <FormItem {...tailFormItemLayout}>
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        Reset Account password
      </h1>
    </FormItem>
    <FormItem {...tailFormItemLayout}>
      {form.getFieldDecorator('password', {
        rules: [
          {
            message: 'New Password Please!',
            required: true,
          },
        ],
      })(
        <Input
          prefix={
            <Icon
              type='lock'
              style={{
                color: 'rgba(0,0,0,.25)',
              }}
            />
          }
          placeholder='Password'
          type='password'
        />,
      )}
    </FormItem>
    <FormItem {...tailFormItemLayout}>
      {form.getFieldDecorator('confirmPassword', {
        rules: [
          {
            message: 'Confirm New Password Please!',
            required: true,
          },
        ],
      })(
        <Input
          prefix={
            <Icon
              type='lock'
              style={{
                color: 'rgba(0,0,0,.25)',
              }}
            />
          }
          placeholder='Confirm Password'
          type='password'
        />,
      )}
    </FormItem>
    <FormItem {...tailFormItemLayout}>
      <Link className='form-forgot' to='/signIn'>
        SignIn to an existing account?
      </Link>
    </FormItem>
    <FormItem {...tailFormItemLayout}>
      <Button
        type='primary'
        loading={loading}
        htmlType='submit'
        className='form-button'
        block={true}
        onSubmit={handleSubmit}
      >
        Reset Password
      </Button>
    </FormItem>
  </Form>
);

interface IAlertInfo {
  message: string;
  description: string | JSX.Element;
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
}
const AlertInfo: (props: IAlertInfo) => JSX.Element = ({ message, description, type }) => (
  <Alert className='form' message={message} description={description} type={type} showIcon={true} />
);

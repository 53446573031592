import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { trackAnalytics } from '../../utils';
import ProjectForm from '../projects/ProjectForm';
import './style.css';

interface IProps {
  className?: string;
  profile: any;
  auth: any;
}

const Onboarding: (props: IProps) => JSX.Element = (props: any) => {
  const { profile } = props;
  const [isOpenCreateProjectModal, setIsOpenCreateProjectModal] = useState(false);
  const [createProjectStepIndex, setCreateProjectStepIndex] = useState(0);
  const [createProjectRun, setCreateProjectRun] = useState(false);

  const updateUserOnboarding = (updateData: any) => {
    const { uid } = props.auth;
    if (uid) {
      const firestore = firebase.firestore();
      firestore
        .collection('users')
        .doc(uid)
        .set(updateData, { merge: true });
    }
  };

  useEffect(() => {
    const loginUserId = props.auth.uid;
    const firestore = firebase.firestore();
    if (loginUserId) {
      firestore
        .collection('users')
        .where(firebase.firestore.FieldPath.documentId(), '==', loginUserId)
        .onSnapshot((usersDetails) => {
          if (usersDetails && usersDetails.docs && usersDetails.docs.length) {
            const usersDetailDocs = usersDetails.docs[0];
            const usersDetail = usersDetailDocs.data();
            if (usersDetail.isCreateOnboardingVisited) {
              setCreateProjectRun(false);
            } else {
              setCreateProjectRun(true);
            }
          }
        });
    }
  }, [props.profile]);

  const createProjectSteps = [
    {
      target: '.step-add-new-project',
      content: 'Click here to create a new canvas',
      disableBeacon: true,
    },
    {
      target: '.select-project-type',
      content: 'Use this options to select a canvas type',
    },
    {
      target: '.step-create-project-submit',
      content: 'Click here to finish creating your first canvas',
    },
  ];

  const handleCreateProjectJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      const newStepsIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      trackAnalytics(
        'User onboarding',
        `project => clicked ${action}, completed step: ${newStepsIndex}`,
      );

      if (newStepsIndex === 1) {
        setIsOpenCreateProjectModal(true);
        setTimeout(() => {
          setCreateProjectStepIndex(newStepsIndex);
        }, 300);
      } else {
        setCreateProjectStepIndex(newStepsIndex);
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setCreateProjectRun(false);
      if (!profile.isCreateOnboardingVisited) {
        updateUserOnboarding({
          isCreateOnboardingVisited: true,
        });
      }
    }
  };

  const closeCreateProject = () => {
    setCreateProjectRun(false);
  };

  return (
    <>
      <ReactJoyride
        callback={handleCreateProjectJoyrideCallback}
        run={createProjectRun}
        stepIndex={createProjectStepIndex}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={createProjectSteps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <ProjectForm
        closeCreateProject={closeCreateProject}
        isOpenSteps={true}
        memberEMailEditable={true}
        visible={isOpenCreateProjectModal}
        setVisible={setIsOpenCreateProjectModal}
        title='Create New Canvas'
      />
    </>
  );
};

const mapStateToProps = (state: { firebase: { auth: {}; profile: {} } }) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Onboarding);

import React from 'react';

export default () => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.85398 0.646039C3.90054 0.692485 3.93748 0.74766 3.96269 0.808405C3.9879 0.869151 4.00087 0.934272 4.00087 1.00004C4.00087 1.06581 3.9879 1.13093 3.96269 1.19167C3.93748 1.25242 3.90054 1.30759 3.85398 1.35404L1.20698 4.00004L3.85398 6.64604C3.90047 6.69253 3.93734 6.74772 3.9625 6.80846C3.98766 6.86919 4.00061 6.9343 4.00061 7.00004C4.00061 7.06578 3.98766 7.13088 3.9625 7.19162C3.93734 7.25236 3.90047 7.30755 3.85398 7.35404C3.80749 7.40053 3.7523 7.4374 3.69156 7.46256C3.63082 7.48772 3.56572 7.50067 3.49998 7.50067C3.43423 7.50067 3.36913 7.48772 3.30839 7.46256C3.24766 7.4374 3.19247 7.40053 3.14598 7.35404L0.145978 4.35404C0.099415 4.30759 0.062472 4.25242 0.0372655 4.19167C0.0120591 4.13093 -0.000915527 4.06581 -0.000915527 4.00004C-0.000915527 3.93427 0.0120591 3.86915 0.0372655 3.80841C0.062472 3.74766 0.099415 3.69248 0.145978 3.64604L3.14598 0.646039C3.19242 0.599476 3.2476 0.562533 3.30834 0.537326C3.36909 0.51212 3.43421 0.499146 3.49998 0.499146C3.56575 0.499146 3.63087 0.51212 3.69161 0.537326C3.75236 0.562533 3.80753 0.599476 3.85398 0.646039Z'
      fill='black'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0.5 4C0.5 3.86739 0.552679 3.74021 0.646447 3.64645C0.740215 3.55268 0.867392 3.5 1 3.5H7.5C7.63261 3.5 7.75979 3.55268 7.85355 3.64645C7.94732 3.74021 8 3.86739 8 4C8 4.13261 7.94732 4.25979 7.85355 4.35355C7.75979 4.44732 7.63261 4.5 7.5 4.5H1C0.867392 4.5 0.740215 4.44732 0.646447 4.35355C0.552679 4.25979 0.5 4.13261 0.5 4Z'
      fill='black'
    />
  </svg>
);

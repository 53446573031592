import { notification } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';
import { ThunkDispatch } from 'redux-thunk';
import { checkChromeExtensionAndSendToken, getLastSelectedWorkflowAndMasterProjectId } from 'utils';
import { projectColors } from 'utils/dummyData';
import { v4 as uuid } from 'uuid';
import { IAuthAction, ICredentials, IUser, Type } from '../../types/authTypes';
const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;

export const generateCustomToken = (firebaseToken: string, uid: string) => {
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/generateCustomToken`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ uid }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

export const clearAuthState = (): IAuthAction => {
  return {
    type: Type.ClearAuthState,
  };
};

export const signIn = (credentials: ICredentials) => (
  dispatch: ThunkDispatch<{}, void, IAuthAction>,
  _getState: () => {},
  { getFirebase, getFirestore }: any,
): Promise<IAuthAction> => {
  dispatch({ type: Type.SignInRequest });
  const firebase = getFirebase();
  const firestore = getFirestore();
  const firebaseToken = window.localStorage.getItem('firebaseToken');

  return firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password)
    .then((resp: any) => {
      checkChromeExtensionAndSendToken(resp.user.uid);
      firestore
        .collection('users')
        .where(firebase.firestore.FieldPath.documentId(), '==', resp.user.uid)
        .get()
        .then((userDetails: any) => {
          if (userDetails && userDetails.docs && userDetails.docs.length) {
            window.localStorage.setItem(
              'userId',
              JSON.stringify({ uid: resp.user.uid, email: resp.user.email }),
            );
            stats('SIGNIN', firestore);
            const userDetailData = userDetails.docs[0].data();
            const userTokens = userDetailData && userDetailData.tokens ? userDetailData.tokens : [];
            const isExistToken = userTokens.includes(firebaseToken);
            if (!isExistToken) {
              userTokens.push(firebaseToken);
              firestore
                .collection('users')
                .doc(resp.user.uid)
                .update({ tokens: userTokens });
            }
          }
        });
      dispatch({ type: Type.SignInSuccess });
    })
    .catch((err: Error) => {
      dispatch({ type: Type.SignInError, err });
    });
};

export const signOut = () => {
  return (
    dispatch: ThunkDispatch<{}, void, IAuthAction>,
    _getState: () => {},
    { getFirebase }: any,
  ): Promise<IAuthAction> => {
    const firebase = getFirebase();

    return firebase
      .auth()
      .signOut()
      .then(() => {
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('selectedMasterProjectId');
        window.localStorage.removeItem('selectedWorkflowId');
        window.localStorage.removeItem('selectedProjectId');
        window.localStorage.removeItem('firebaseToken');
        window.localStorage.removeItem('selectedOrganizationId');
        window.postMessage({ message: 'logout', logout: true }, '*');
        dispatch({ type: Type.SignOutSuccess });
        console.log("call logout")
      });
  };
};

export const signUp = (newUser: IUser) => {
  return (
    dispatch: ThunkDispatch<{}, void, IAuthAction>,
    _getState: () => {},
    { getFirebase, getFirestore }: any,
  ): Promise<IAuthAction> => {
    dispatch({ type: Type.SignUpRequest });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const firebaseToken = window.localStorage.getItem('firebaseToken');

    // const urlParams = new URLSearchParams(window.location.search);
    // const plan = urlParams.get('plan');
    const plan = '';
    return firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(async (resp: any) => {
        window.localStorage.setItem(
          'userId',
          JSON.stringify({ uid: resp.user.uid, email: resp.user.email }),
        );
        let organizationId: any = '';
        const liveChatUid = Cookies.get('liveChatUserUid');
        let userPlan =
          plan && (plan === 'freelancer' || plan === 'productTeam' || plan === 'digitalAgency')
            ? plan
            : newUser.appSumoId
            ? 'productTeam'
            : 'freelancer';
        if (newUser && newUser.adminIds && newUser.adminIds[0]) {
          await firestore
            .collection('users')
            .doc(newUser.adminIds[0])
            .get()
            .then((doc: any) => {
              if (doc.exists) {
                const userData = doc.data();
                if (userData.plan) {
                  userPlan = userData.plan;
                }
              }
            });
          await firestore
          .collection('organization')
          .doc(newUser.organizationId)
          .get()
          .then(async (projectDetails: any) => {
            let projectDetail = projectDetails.data();
            if (projectDetail) {
              let members = projectDetail.members;
              let member_emails = projectDetail.member_emails || [];
              member_emails.push(newUser.email);
              projectDetail.member_emails = member_emails;
              let member_ids = projectDetail.member_ids || [];
              member_ids.push(resp.user.uid);
              members = members.map((data: any) => {
                if (data.email === newUser.email) {
                  data.status = "ACTIVE";
                  data.name = newUser.fullName;
                  data.image = resp.user.image ? resp.user.image : '';
                  data.invite_by = newUser.adminIds[0];
                  data.id = resp.user.uid;
                  return data
                }
                return data;
              })
              projectDetail.members = members;
              projectDetail.member_ids = member_ids;
            //  updateProjectMemberIds(user.organizationId, member_ids);
            }
            await firestore.collection('organization').doc(newUser.organizationId).set(projectDetail);
          })
        }

        //  if (!(newUser && newUser.adminIds && newUser.adminIds[0])) {
        const chatId = await firestore.collection('chat').add({
          messages: [],
          count: 0,
        });
        const uniqueId = uuid();
        const projectId: string = uuid();
        const updatedTimeStamp = new Date().getTime();
        const masterProject = await firestore.collection('masterProjects').add({
          updatedTimeStamp,
          name: 'Sample Workspace',
          imageUrl: '',
          color: '#BDBDBD',
          imageThumbnailUrl: '',
          created_by: resp.user.uid,
          chatId: chatId.id,
          workflow: [
            {
              step: 'Open',
              total: 0,
              color: '#828282',
              id: `open-${uniqueId}`,
            },
            {
              step: 'In Progress',
              total: 0,
              color: ' #2D9CDB',
              id: `inprogress-${uniqueId}`,
            },
            {
              step: 'Review',
              total: 0,
              color: '#F2C94C',
              id: `review-${uniqueId}`,
            },
            {
              step: 'Blocked',
              total: 0,
              color: '#EB5757',
              id: `blocked-${uniqueId}`,
            },
            {
              step: 'Completed',
              total: 0,
              color: '#27AE60',
              id: `completed-${uniqueId}`,
            },
          ],
          member_emails: [resp.user.email],
          member_ids: [resp.user.uid],
          members: [
            {
              id: resp.user.uid,
              name: newUser.fullName,
              image: '',
              role: 'Admin',
              invite_by: resp.user.uid,
              status: 'ACTIVE',
              email: resp.user.email,
            },
          ],
          projectId,
        });
        window.localStorage.setItem('selectedMasterProjectId', masterProject.id);
        window.localStorage.setItem('selectedWorkflowId', `open-${uniqueId}`);
        getLastSelectedWorkflowAndMasterProjectId();
        //  }
        return firestore
          .collection('pricingQuota')
          .get()
          .then(async (snapshot: any) => {
            const pricingData = snapshot.docs.map((doc: any) => doc.data());
            if (pricingData && pricingData[0] && pricingData[0][userPlan]) {
              const userQuota = pricingData[0][userPlan];
              userQuota.maxCanvasesCount = userQuota.canvasesCount;
              userQuota.maxChromeExtensionScreenshots = userQuota.chromeExtensionScreenshots;
              userQuota.maxStorageLimit = userQuota.storageLimit;
              userQuota.maxTotalVoiceClips = userQuota.totalVoiceClips;
              userQuota.maxTotalVideoComments = userQuota.totalVideoComments;
              userQuota.maxTotalScreenRecordings = userQuota.totalScreenRecordings;
              userQuota.maxTotalUser = userQuota.totalUser;
              if (newUser.appSumoId) {
                userQuota.whitelabel = true;
              }
              userQuota.created_at = moment.utc().format('DD-MM-YYYY');
              if (newUser.appSumoId) {
                stats('APPSUMO_REGISTRATION', firestore);
                stats('APPSUMO_CODE_USED', firestore);
              } else if (newUser && newUser.adminIds && newUser.adminIds[0]) {
                stats('USER_REGISTRATION_BY_APPSUMO_INVITEE', firestore);
              } else {
                stats('REGISTRATION', firestore);
              }
              organizationId = await firestore.collection('organization').add({
                member_ids: [resp.user.uid],
                name: newUser.fullName ? `${newUser.fullName}'s Organization` : '',
                members: [
                  {
                    id: resp.user.uid,
                    name: newUser.fullName || '',
                    image: '',
                    role: 'Admin',
                    invite_by: resp.user.uid,
                    status: 'ACTIVE',
                    email: newUser.email || '',
                  },
                ],
                projects: [
                  {
                    projectColors,
                    projectName: 'Untitled project',
                    id: projectId,
                  },
                ],
                userQuota,
                created_by: resp.user.uid,
                plan: userPlan,
                appSumoId: newUser.appSumoId ? newUser.appSumoId : '',
                usedAppSumoCode: newUser.appSumoId ? 1 : 0,
              });
              return firestore
                .collection('users')
                .doc(resp.user.uid)
                .set({
                  isManagedUserQuotaInOrganization: true,
                  liveChatUid: liveChatUid ? liveChatUid : '',
                  userQuota,
                  created_at: moment.utc().format('DD-MM-YYYY'),
                  adminIds: newUser.adminIds,
                  email: newUser.email,
                  fullName: newUser.fullName,
                  initials: `${newUser.fullName[0]}`,
                  token: firebaseToken ? [firebaseToken] : [],
                  isPublicUser: newUser.isPublicUser ? newUser.isPublicUser : false,
                  isCreateOnboardingVisited: true,
                  isFreePlan: true,
                  isSubscription: false,
                  plan: userPlan,
                  organizationId: newUser.organizationId,
                  appSumoId: newUser.appSumoId ? newUser.appSumoId : '',
                  isInitialUser: newUser && newUser.adminIds && newUser.adminIds[0] ? false : true,
                })
                .then(() => {
                  checkChromeExtensionAndSendToken(resp.user.uid);
                  dispatch({ type: Type.SignUpSuccess });
                  return {
                    uid: resp.user.uid,
                    usedByOrganisationID: organizationId.id || '',
                  };
                });
            }
          });
      })
      .catch((err: Error) => {
        dispatch({ type: Type.SignUpError, err });
        return err;
      });
  };
};

export const checkExistUser = (email: string) => {
  return (
    dispatch: ThunkDispatch<{}, void, IAuthAction>,
    _getState: () => {},
    { getFirestore }: any,
  ): any => {
    const firestore = getFirestore();
    return firestore
      .collection('users')
      .where('email', '==', email)
      .get()
      .then((userDetails: any) => {
        if (userDetails && userDetails.docs && userDetails.docs.length) {
          const userDetailDocs = userDetails.docs[0];
          return userDetailDocs.id;
        } else {
          return '';
        }
      })
      .catch(() => {
        dispatch({ type: Type.SignUpError, err: {} });
      });
  };
};

export const inviteAction = (
  adminId: string,
  userId: string,
  organizationId: string,
  actionType: string,
) => {
  const postData = { adminId, userId, actionType, organizationId };
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/invitation`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(postData),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return `You have ${actionType} Invitation successfully.`;
      } else {
        return 'Something went wrong';
      }
    });
};

export const forgotPassword = (email: string) => (
  dispatch: ThunkDispatch<{}, void, IAuthAction>,
  _getState: () => {},
  { getFirebase }: any,
): Promise<IAuthAction> => {
  dispatch({ type: Type.forgotPasswordRequest });
  const firebase = getFirebase();
  const actionCodeSettings = {
    url: `${window.location.origin}/signIn`,
    handleCodeInApp: false,
  };
  return firebase
    .auth()
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then(() => {
      dispatch({ type: Type.forgotPasswordSuccess });
    })
    .catch((err: Error) => {
      dispatch({ type: Type.forgotPasswordError, err });
    });
};

export const verifyToken = (token: string) => (
  dispatch: ThunkDispatch<{}, void, IAuthAction>,
  _getState: () => {},
  { getFirebase }: any,
): Promise<IAuthAction> => {
  dispatch({ type: Type.verifyTokenRequest });
  const firebase = getFirebase();
  return firebase
    .auth()
    .verifyPasswordResetCode(token)
    .then(() => {
      dispatch({ type: Type.verifyTokenSuccess });
    })
    .catch((err: Error) => {
      dispatch({ type: Type.verifyTokenError, err });
    });
};

export const resetPassword = (token: string, newPass: string) => (
  dispatch: ThunkDispatch<{}, void, IAuthAction>,
  _getState: () => {},
  { getFirebase }: any,
): Promise<IAuthAction> => {
  dispatch({ type: Type.resetPasswordRequest });
  const firebase = getFirebase();
  return firebase
    .auth()
    .confirmPasswordReset(token, newPass)
    .then(() => {
      dispatch({ type: Type.resetPasswordSuccess });
    })
    .catch((err: Error) => {
      dispatch({ type: Type.resetPasswordError, err });
    });
};

const stats = async (event: string, firestore: any) => {
  const { NODE_ENV } = process.env;
  let userData: any = await window.localStorage.getItem('userId');
  userData = JSON.parse(userData);
  const userEmail = (userData && userData.email) || '';
  if (
    NODE_ENV === 'production' &&
    !userEmail.includes('@reactgeeks.com') &&
    !userEmail.includes('@zooc.io') &&
    !userEmail.includes('fh.public.user')
  ) {
    // Events
    // 1. Registration
    // 2. login
    // 3. Public user signup
    // 4. Project create
    // 5. Add pages
    // 6. Added feedback
    // 7. Add text Comment
    // 8. Add image send

    // Record data
    // date (DD-MM-YYYY)
    // total_users_signed_up
    // total_users_logged_in
    // total_projects_created
    // total_pages_created
    // total_feedback_buttons_create
    // total_comments_sent
    // total_screenshots_sent

    const currentDate = moment.utc().format('DD-MM-YYYY');
    firestore
      .collection('stats')
      .where('date', '==', currentDate)
      .get()
      .then((statsDetails: any) => {
        if (statsDetails && statsDetails.docs && statsDetails.docs.length) {
          const statsDetailDocs = statsDetails.docs[0];
          const statsDetail = statsDetailDocs.data();
          const updateData: any = {};
          switch (event) {
            case 'INVITE_MEMBER':
              updateData.total_invite_users =
                1 + (statsDetail.total_invite_users ? statsDetail.total_invite_users : 0);
              break;
            case 'USER_REGISTRATION_BY_APPSUMO_INVITEE':
              updateData.total_users_signed_up_by_appsumo_invitee =
                1 +
                (statsDetail.total_users_signed_up_by_appsumo_invitee
                  ? statsDetail.total_users_signed_up_by_appsumo_invitee
                  : 0);
              break;
            case 'APPSUMO_REGISTRATION':
              updateData.total_users_signed_up_by_appsumo_code =
                1 +
                (statsDetail.total_users_signed_up_by_appsumo_code
                  ? statsDetail.total_users_signed_up_by_appsumo_code
                  : 0);
              break;
            case 'APPSUMO_CODE_USED':
              updateData.total_appsumo_code_used =
                1 + (statsDetail.total_appsumo_code_used ? statsDetail.total_appsumo_code_used : 0);
              break;
            case 'REGISTRATION':
              updateData.total_users_signed_up =
                1 + (statsDetail.total_users_signed_up ? statsDetail.total_users_signed_up : 0);
              break;
            case 'SIGNIN':
              updateData.total_users_logged_in =
                1 + (statsDetail.total_users_logged_in ? statsDetail.total_users_logged_in : 0);
              break;
            case 'PUBLIC_USER_SIGININ':
              updateData.total_public_users_logged_in =
                1 +
                (statsDetail.total_public_users_logged_in
                  ? statsDetail.total_public_users_logged_in
                  : 0);
              break;
            case 'PROJECT_CREATED':
              updateData.total_projects_created =
                1 + (statsDetail.total_projects_created ? statsDetail.total_projects_created : 0);
              break;
            case 'PAGE_CREATED':
              updateData.total_pages_created =
                1 + (statsDetail.total_pages_created ? statsDetail.total_pages_created : 0);
              break;
            case 'FEEDBACK_CREATED':
              updateData.total_feedback_buttons_create =
                1 +
                (statsDetail.total_feedback_buttons_create
                  ? statsDetail.total_feedback_buttons_create
                  : 0);
              break;
            case 'TEXT_COMMENT_SENT':
              updateData.total_comments_sent =
                1 + (statsDetail.total_comments_sent ? statsDetail.total_comments_sent : 0);
              break;
            case 'IMAGE_COMMENT_SENT':
              updateData.total_screenshots_sent =
                1 + (statsDetail.total_screenshots_sent ? statsDetail.total_screenshots_sent : 0);
              break;
            default:
              updateData.default_event = 'Default event';
          }
          firestore
            .collection('stats')
            .doc(statsDetailDocs.id)
            .set(updateData, { merge: true });
        } else {
          const addData: any = { date: currentDate };
          switch (event) {
            case 'REGISTRATION':
              addData.total_users_signed_up = 1;
              break;
            case 'SIGNIN':
              addData.total_users_logged_in = 1;
              break;
            case 'PUBLIC_USER_SIGININ':
              addData.total_public_users_logged_in = 1;
              break;
            case 'PROJECT_CREATED':
              addData.total_projects_created = 1;
              break;
            case 'PAGE_CREATED':
              addData.total_pages_created = 1;
              break;
            case 'FEEDBACK_CREATED':
              addData.total_feedback_buttons_create = 1;
              break;
            case 'TEXT_COMMENT_SENT':
              addData.total_comments_sent = 1;
              break;
            case 'IMAGE_COMMENT_SENT':
              addData.total_screenshots_sent = 1;
              break;
            default:
              addData.default_event = 'Default event';
          }
          firestore.collection('stats').add(addData);
        }
      });
  }
};

export const updateFirebaseAuthToken = (data: any) => {
  return {
    type: Type.firebaseAuthToken,
    payload: data,
  };
};

export const statsAction = stats;

export const loginWithGoogle = () => {
  return (_: any, _getState: () => {}, { getFirebase }: any) => {
    const firebase = getFirebase();
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase.auth().signInWithRedirect(provider);
  };
};

export const getGoogleLogingResult = (adminId: string, emailId: string, appSumoCode?: string) => (
  dispatch: ThunkDispatch<{}, void, IAuthAction>,
  _getState: () => {},
  { getFirebase, getFirestore }: any,
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const firebaseToken = window.localStorage.getItem('firebaseToken');

  return firebase
    .auth()
    .getRedirectResult()
    .then((res: any) => {
      window.localStorage.setItem(
        'userId',
        JSON.stringify({ uid: res.user.uid, email: res.user.email }),
      );
      checkChromeExtensionAndSendToken(res.user.uid);
      firestore
        .collection('users')
        .where(firebase.firestore.FieldPath.documentId(), '==', res.user.uid)
        .get()
        .then(async (userDetails: any) => {
          window.localStorage.setItem(
            'userId',
            JSON.stringify({ uid: res.user.uid, email: res.user.email }),
          );
          if (userDetails && userDetails.docs && userDetails.docs.length) {
            /* User is already register, update latest details in users */
            return firestore
              .collection('users')
              .doc(res.user.uid)
              .update({
                fullName: res.user.displayName,
                photoURL: res.user.photoURL,
                email: res.user.email || '',
                initials: `${res.user.displayName && res.user.displayName[0]}`,
                created_at: moment.utc().format('DD-MM-YYYY'),
                token: firebaseToken ? [firebaseToken] : [],
              });
            stats('SIGNIN', firestore);
          } else {
            if (appSumoCode) {
              stats('APPSUMO_REGISTRATION', firestore);
              stats('APPSUMO_CODE_USED', firestore);
            } else if (adminId && emailId && emailId === res.user.email) {
              stats('USER_REGISTRATION_BY_APPSUMO_INVITEE', firestore);
            } else {
              stats('REGISTRATION', firestore);
            }
            let organizationId: any = {};
            const urlParams = new URLSearchParams(window.location.search);
            const plan = urlParams.get('plan');
            let userPlan =
              plan && (plan === 'freelancer' || plan === 'productTeam' || plan === 'digitalAgency')
                ? plan
                : appSumoCode
                ? 'productTeam'
                : 'freelancer';
            if (adminId && emailId && emailId === res.user.email) {
              await firestore
                .collection('users')
                .doc(adminId)
                .get()
                .then((doc: any) => {
                  if (doc.exists) {
                    const userData = doc.data();
                    if (userData.plan) {
                      userPlan = userData.plan;
                    }
                  }
                });
            }
            //  if (!(adminId && emailId && (emailId === res.user.email))) {
            const chatId = await firestore.collection('chat').add({
              messages: [],
              count: 0,
            });
            const uniqueId = uuid();
            const projectId: string = uuid();
            const updatedTimeStamp = new Date().getTime();
            const masterProject = await firestore.collection('masterProjects').add({
              updatedTimeStamp,
              name: 'Sample Workspace',
              imageUrl: '',
              color: '#BDBDBD',
              imageThumbnailUrl: '',
              created_by: res.user.uid,
              chatId: chatId.id,
              workflow: [
                {
                  step: 'Open',
                  total: 0,
                  color: '#828282',
                  id: `open-${uniqueId}`,
                },
                {
                  step: 'In Progress',
                  total: 0,
                  color: ' #2D9CDB',
                  id: `inprogress-${uniqueId}`,
                },
                {
                  step: 'Review',
                  total: 0,
                  color: '#F2C94C',
                  id: `review-${uniqueId}`,
                },
                {
                  step: 'Blocked',
                  total: 0,
                  color: '#EB5757',
                  id: `blocked-${uniqueId}`,
                },
                {
                  step: 'Completed',
                  total: 0,
                  color: '#27AE60',
                  id: `completed-${uniqueId}`,
                },
              ],
              member_emails: [res.user.email],
              member_ids: [res.user.uid],
              members: [
                {
                  id: res.user.uid,
                  name: res.user.displayName || '',
                  image: '',
                  role: 'Admin',
                  invite_by: res.user.uid,
                  status: 'ACTIVE',
                  email: res.user.email || '',
                },
              ],
              projectId,
            });
            window.localStorage.setItem('selectedMasterProjectId', masterProject.id);
            window.localStorage.setItem('selectedWorkflowId', `open-${uniqueId}`);
            getLastSelectedWorkflowAndMasterProjectId();
            //  }
            return firestore
              .collection('pricingQuota')
              .get()
              .then(async (snapshot: any) => {
                const pricingData = snapshot.docs.map((doc: any) => doc.data());
                if (pricingData && pricingData[0] && pricingData[0][userPlan]) {
                  const userQuota = pricingData[0][userPlan];
                  userQuota.maxCanvasesCount = userQuota.canvasesCount;
                  userQuota.maxChromeExtensionScreenshots = userQuota.chromeExtensionScreenshots;
                  userQuota.maxStorageLimit = userQuota.storageLimit;
                  userQuota.maxTotalVoiceClips = userQuota.totalVoiceClips;
                  userQuota.maxTotalVideoComments = userQuota.totalVideoComments;
                  userQuota.maxTotalScreenRecordings = userQuota.totalScreenRecordings;
                  userQuota.maxTotalUser = userQuota.totalUser;

                  if (appSumoCode) {
                    userQuota.whitelabel = true;
                  }

                  userQuota.created_at = moment.utc().format('DD-MM-YYYY');

                  organizationId = await firestore.collection('organization').add({
                    member_ids: [res.user.uid],
                    members: [
                      {
                        id: res.user.uid,
                        name: res.user.displayName || '',
                        image: '',
                        role: 'Admin',
                        invite_by: res.user.uid,
                        status: 'ACTIVE',
                        email: res.user.email || '',
                      },
                    ],
                    projects: [
                      {
                        projectColors,
                        projectName: 'Untitled project',
                        id: projectId,
                      },
                    ],
                    created_by: res.user.uid,
                    userQuota,
                    plan: userPlan,
                    appSumoId: appSumoCode ? appSumoCode : '',
                    usedAppSumoCode: appSumoCode ? 1 : 0,
                  });

                  return firestore
                    .collection('users')
                    .doc(res.user.uid)
                    .set({
                      isManagedUserQuotaInOrganization: true,
                      liveChatUid: '',
                      userQuota,
                      created_at: moment.utc().format('DD-MM-YYYY'),
                      adminIds: adminId && emailId && emailId === res.user.email ? [adminId] : [''],
                      email: res.user.email || '',
                      fullName: res.user.displayName,
                      initials: `${res.user.displayName && res.user.displayName[0]}`,
                      token: firebaseToken ? [firebaseToken] : [],
                      photoURL: res.user.photoURL || '',
                      isPublicUser: false,
                      isCreateOnboardingVisited: true,
                      isFreePlan: true,
                      isSubscription: false,
                      plan: userPlan,
                      organizationId: '',
                      appSumoId: appSumoCode ? appSumoCode : '',
                      isInitialUser:
                        adminId && emailId && emailId === res.user.email ? false : true,
                    })
                    .then(() => {
                      dispatch({ type: Type.SignUpSuccess });
                      // this will update appsumo code
                      if (appSumoCode) {
                        notification.success({
                          message: 'Congratulations!',
                          description: 'You have successfully redeemed your AppSumo Code.',
                        });
                        firestore
                          .collection('redemption')
                          .doc(appSumoCode)
                          .set(
                            {
                              isUsed: true,
                              usedByOrganisationID: organizationId.id || '',
                              userId: res.user.uid,
                            },
                            {
                              merge: true,
                            },
                          );
                      }
                      return {
                        uid: res.user.uid,
                        usedByOrganisationID: organizationId.id || '',
                      };
                    });
                }
              });
          }
        });
    })
    .then(() => {
      dispatch({ type: Type.GoogleLogedIn });
    })
    .catch((err: Error) => {
      dispatch({ type: Type.GoogleLogInError, err });
    });
};
export const loginWithGitHub = () => {
  return (_: any, _getState: () => {}, { getFirebase }: any) => {
    const firebase = getFirebase();
    const provider = new firebase.auth.GithubAuthProvider();

    firebase.auth().signInWithRedirect(provider);
  };
};

export const getGitHubLogingResult = (adminId: string, emailId: string, appSumoCode?: string) => (
  dispatch: ThunkDispatch<{}, void, IAuthAction>,
  _getState: () => {},
  { getFirebase, getFirestore }: any,
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const firebaseToken = window.localStorage.getItem('firebaseToken');
  return firebase
    .auth()
    .getRedirectResult()
    .then((res: any) => {
      firestore
        .collection('users')
        .where(firebase.firestore.FieldPath.documentId(), '==', res.user.uid)
        .get()
        .then(async (userDetails: any) => {
          checkChromeExtensionAndSendToken(res.user.uid);
          window.localStorage.setItem(
            'userId',
            JSON.stringify({ uid: res.user.uid, email: res.user.email }),
          );
          if (userDetails && userDetails.docs && userDetails.docs.length) {
            /* User is already register, update latest details in users */
            return firestore
              .collection('users')
              .doc(res.user.uid)
              .update({
                fullName: res.user.displayName,
                photoURL: res.user.photoURL,
                email: res.user.email || '',
                initials: `${res.user.displayName && res.user.displayName[0]}`,
                created_at: moment.utc().format('DD-MM-YYYY'),
                token: firebaseToken ? [firebaseToken] : [],
              });
            stats('SIGNIN', firestore);
          } else {
            if (appSumoCode) {
              stats('APPSUMO_REGISTRATION', firestore);
              stats('APPSUMO_CODE_USED', firestore);
            } else if (adminId && emailId && emailId === res.user.email) {
              stats('USER_REGISTRATION_BY_APPSUMO_INVITEE', firestore);
            } else {
              stats('REGISTRATION', firestore);
            }
            let organizationId: any = {};
            const urlParams = new URLSearchParams(window.location.search);
            const plan = urlParams.get('plan');
            let userPlan =
              plan && (plan === 'freelancer' || plan === 'productTeam' || plan === 'digitalAgency')
                ? plan
                : appSumoCode
                ? 'productTeam'
                : 'freelancer';
            if (adminId && emailId && emailId === res.user.email) {
              await firestore
                .collection('users')
                .doc(adminId)
                .get()
                .then((doc: any) => {
                  if (doc.exists) {
                    const userData = doc.data();
                    if (userData.plan) {
                      userPlan = userData.plan;
                    }
                  }
                });
            }
            //  if (!(newUser && newUser.adminIds && newUser.adminIds[0])) {
            const chatId = await firestore.collection('chat').add({
              messages: [],
              count: 0,
            });
            const uniqueId = uuid();
            const projectId: string = uuid();
            const updatedTimeStamp = new Date().getTime();
            const masterProject = await firestore.collection('masterProjects').add({
              updatedTimeStamp,
              name: 'Sample Workspace',
              imageUrl: '',
              color: '#BDBDBD',
              imageThumbnailUrl: '',
              created_by: res.user.uid,
              chatId: chatId.id,
              workflow: [
                {
                  step: 'Open',
                  total: 0,
                  color: '#828282',
                  id: `open-${uniqueId}`,
                },
                {
                  step: 'In Progress',
                  total: 0,
                  color: ' #2D9CDB',
                  id: `inprogress-${uniqueId}`,
                },
                {
                  step: 'Review',
                  total: 0,
                  color: '#F2C94C',
                  id: `review-${uniqueId}`,
                },
                {
                  step: 'Blocked',
                  total: 0,
                  color: '#EB5757',
                  id: `blocked-${uniqueId}`,
                },
                {
                  step: 'Completed',
                  total: 0,
                  color: '#27AE60',
                  id: `completed-${uniqueId}`,
                },
              ],
              member_emails: [res.user.email],
              member_ids: [res.user.uid],
              members: [
                {
                  id: res.user.uid,
                  name: res.user.displayName || '',
                  image: '',
                  role: 'Admin',
                  invite_by: res.user.uid,
                  status: 'ACTIVE',
                  email: res.user.email || '',
                },
              ],
              projectId,
            });
            window.localStorage.setItem('selectedMasterProjectId', masterProject.id);
            window.localStorage.setItem('selectedWorkflowId', `open-${uniqueId}`);
            getLastSelectedWorkflowAndMasterProjectId();
            //  }
            return firestore
              .collection('pricingQuota')
              .get()
              .then(async (snapshot: any) => {
                const pricingData = snapshot.docs.map((doc: any) => doc.data());
                if (pricingData && pricingData[0] && pricingData[0][userPlan]) {
                  const userQuota = pricingData[0][userPlan];
                  userQuota.maxCanvasesCount = userQuota.canvasesCount;
                  userQuota.maxChromeExtensionScreenshots = userQuota.chromeExtensionScreenshots;
                  userQuota.maxStorageLimit = userQuota.storageLimit;
                  userQuota.maxTotalVoiceClips = userQuota.totalVoiceClips;
                  userQuota.maxTotalVideoComments = userQuota.totalVideoComments;
                  userQuota.maxTotalScreenRecordings = userQuota.totalScreenRecordings;
                  userQuota.maxTotalUser = userQuota.totalUser;

                  if (appSumoCode) {
                    userQuota.whitelabel = true;
                  }

                  userQuota.created_at = moment.utc().format('DD-MM-YYYY');

                  organizationId = await firestore.collection('organization').add({
                    member_ids: [res.user.uid],
                    members: [
                      {
                        id: res.user.uid,
                        name: res.user.displayName || '',
                        image: '',
                        role: 'Admin',
                        invite_by: res.user.uid,
                        status: 'ACTIVE',
                        email: res.user.email || '',
                      },
                    ],
                    projects: [
                      {
                        projectColors,
                        projectName: 'Untitled project',
                        id: projectId,
                      },
                    ],
                    created_by: res.user.uid,
                    userQuota,
                    plan: userPlan,
                    appSumoId: appSumoCode ? appSumoCode : '',
                    usedAppSumoCode: appSumoCode ? 1 : 0,
                  });

                  return firestore
                    .collection('users')
                    .doc(res.user.uid)
                    .set({
                      isManagedUserQuotaInOrganization: true,
                      liveChatUid: '',
                      userQuota,
                      created_at: moment.utc().format('DD-MM-YYYY'),
                      adminIds: adminId && emailId && emailId === res.user.email ? [adminId] : [''],
                      email: res.user.email || '',
                      fullName: res.user.displayName,
                      initials: `${res.user.displayName && res.user.displayName[0]}`,
                      photoURL: res.user.photoURL || '',
                      token: firebaseToken ? [firebaseToken] : [],
                      isPublicUser: false,
                      isCreateOnboardingVisited: true,
                      isFreePlan: true,
                      isSubscription: false,
                      plan: userPlan,
                      organizationId: '',
                      appSumoId: appSumoCode ? appSumoCode : '',
                      isInitialUser:
                        adminId && emailId && emailId === res.user.email ? false : true,
                    })
                    .then(() => {
                      dispatch({ type: Type.SignUpSuccess });
                      // this will update appsumo code usage
                      if (appSumoCode) {
                        notification.success({
                          message: 'Congratulations!',
                          description: 'You have successfully redeemed your AppSumo Code.',
                        });
                      }
                      firestore
                        .collection('redemption')
                        .doc(appSumoCode)
                        .set(
                          {
                            isUsed: true,
                            usedByOrganisationID: organizationId.id || '',
                            userId: res.user.uid,
                          },
                          {
                            merge: true,
                          },
                        );
                      return {
                        uid: res.user.uid,
                        usedByOrganisationID: organizationId.id || '',
                      };
                    });
                }
              });
          }
        });
    })
    .then(() => {
      dispatch({ type: Type.githubLogedIn });
    })
    .catch((err: Error) => {
      dispatch({ type: Type.githubLogInError, err });
    });
};

export const updateUserProfile = (
  fullName: string,
  image: string,
  imgUrl: string,
  organizationDetail: any = '',
) => (dispatch: ThunkDispatch<{}, void, IAuthAction>, _getState: any, { getFirestore }: any) => {
  dispatch({ type: Type.updateUserProfileRequest });
  const userId = _getState().firebase.auth.uid;
  const firestore = getFirestore();
  return firestore
    .collection('users')
    .doc(userId)
    .set(
      {
        fullName,
        initials: fullName[0],
        photoURL: image,
        imageUrl: imgUrl,
      },
      { merge: true },
    )
    .then(() => {
      dispatch({ type: Type.updateUserProfileSuccess });
      if (organizationDetail && organizationDetail.length) {
        organizationDetail.map((organization: any) => {
          const projects = organization.projects || [];
          projects.map((project: any) => {
            if (project && project.id) {
              firestore
                .collection('masterProjects')
                // .where('member_emails', 'array-contains', this.props.auth.email)
                .where('projectId', '==', project.id)
                .where('member_ids', 'array-contains', userId)
                .get()
                .then((selfMasterProjectsSnap: any) => {
                  // .onSnapshot((selfMasterProjectsSnap: any) => {
                  if (
                    selfMasterProjectsSnap &&
                    selfMasterProjectsSnap.docs &&
                    selfMasterProjectsSnap.docs.length
                  ) {
                    const workspaceMasterProjects = selfMasterProjectsSnap.docs.map((doc: any) => {
                      return {
                        ...doc.data(),
                        id: doc.id,
                      };
                    });
                    workspaceMasterProjects.map((selfMasterProject: any) => {
                      const masterProjectMembers: any = selfMasterProject.members
                        ? selfMasterProject.members.map((member: any) => {
                            if (member.id === userId) {
                              member.image = image;
                            }
                            return member;
                          })
                        : [];
                      const updatedTimeStamp = new Date().getTime();
                      firestore
                        .collection('masterProjects')
                        .doc(selfMasterProject.id)
                        .update({
                          members: masterProjectMembers,
                          updatedTimeStamp,
                        });
                    });
                  }
                });
            }
          });
        });
      }
    })
    .catch((err: Error) => {
      dispatch({ type: Type.updateUserProfileErrore, err });
    });
};

export const updateUserEmail = (email: string) => (
  dispatch: ThunkDispatch<{}, void, IAuthAction>,
  _getState: any,
  { getFirestore }: any,
) => {
  dispatch({ type: Type.updateEmailRequest });
  const userId = _getState().firebase.auth.uid;
  const firestore = getFirestore();
  return firestore
    .collection('users')
    .doc(userId)
    .set(
      {
        newEmail: email,
        uuid: uuid(),
      },
      { merge: true },
    )
    .then(() => {
      dispatch({ type: Type.updateEmailSuccess });
    })
    .catch((err: Error) => {
      dispatch({ type: Type.updateEmailErrore, err });
    });
};

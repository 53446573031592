import { Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import NavItems from './NavItems';
import './style.css';

const { Header } = Layout;

const Navbar = (props: {
  iframe: any;
  profile: {};
  auth: {};
  path: string;
  setDrawerVisible: any;
  toggleDrawing: boolean;
  setDrawing: any;
  activeTab: any;
  setActiveTab: any;
  keyboardEvent: string;
  projectData?: any;
  isWriteAccess: boolean;
}) => {
  const {
    profile,
    path,
    iframe,
    setDrawerVisible,
    setDrawing,
    toggleDrawing,
    activeTab,
    setActiveTab,
    keyboardEvent,
    projectData,
    isWriteAccess,
  } = props;
  return (
    <nav className='nav-wrapper grey darken-3'>
      <Layout className='layout'>
        <Header className='header'>
          {/* // Fix Me
  // @ts-ignore */}
          <NavItems
            isWriteAccess={isWriteAccess}
            setDrawerVisible={setDrawerVisible}
            profile={profile}
            path={path}
            iframe={iframe}
            setDrawing={setDrawing}
            toggleDrawing={toggleDrawing}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            keyboardEvent={keyboardEvent}
            projectData={projectData}
          />
        </Header>
      </Layout>
    </nav>
  );
};

const mapStateToProps = (state: { firebase: { auth: {}; profile: {} } }) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Navbar);

import firebase from 'firebase';
import firebaseApp from 'firebase/app';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { eventConstant } from '../../constant';
import {
  checkTrialPeriod,
  openChatWindow as openChatWindowAction,
} from '../../store/actions/projectActions';
import { IProject } from '../../types/projectTypes';
import { mobileCheck } from '../../utils';
import Iframe from '../iframe';
// import { SettingsContent } from '../layout/SettingsContent'
// import RenderComments from '../iframe/RenderComments';
import Navbar from '../layout/Navbar';
import VideoProjectChat from '../layout/VideoProjectChat';
import AudioPlayer from '../player/AudioPlayer';
import RenderVideos from './RenderVideos';

const isMobile = mobileCheck();

interface IOwnProps {
  project: IProject;
  auth: any;
  profile: any;
  match: any;
  iframe: any;
  openChatWindow: any;
  isOpenChatWindow: boolean;
  firebaseAuthToken: any;
  persistCommentWindow: any;
}

const ProjectDetails = (props: IOwnProps) => {
  const token = localStorage.getItem('firebaseToken');
  const [project, setProject] = useState({
    project_url: '',
    userPushNotificationTokens: [],
    type: '',
    appendVideo: '',
    videoStatus: '',
    project_created_by: '',
    blobURL: '',
    createdAtUTC: moment().utc(),
  });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [toggleDrawing, setDrawing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState('commenting');
  const [keyboardEvent, setKeyboardEvent] = useState('');
  const [isWriteAccess, setIsWriteAccess] = useState(true);

  const { persistCommentWindow } = props;
  const eventHandler = (e: any) => {
    const eventData = e.data;
    const event = eventData.event;
    switch (event) {
      case eventConstant.LOADED_IFRAME:
        setActiveTab('commenting');
        break;
    }
  };

  // React.useEffect(() => {
  //   const crispElement: any = document.querySelector('.crisp-client');
  //   if (crispElement && crispElement.style) {
  //     crispElement.style.display = 'none';
  //   }

  //   return () => {
  //     if (crispElement && crispElement.style) {
  //       crispElement.style.display = 'block';
  //     }
  //   };
  // });

  React.useEffect(() => {
    window.addEventListener('message', eventHandler, false);
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  React.useEffect(() => {
    const { firebaseAuthToken, auth } = props;
    const getFirebaseAuthToken = async () => {
      const fiebaseInstance: any = firebaseApp;
      if (
        (fiebaseInstance && fiebaseInstance.auth() && fiebaseInstance.auth().currentUser) ||
        firebaseAuthToken
      ) {
        const firebaseUserAuthToken = firebaseAuthToken
          ? firebaseAuthToken
          : await fiebaseInstance.auth().currentUser.getIdToken(true);
        firebaseUserAuthToken &&
          checkTrialPeriod(firebaseUserAuthToken).then((res: any) => {
            setIsWriteAccess(res.isWriteAccess ? res.isWriteAccess : false);
          });
      }
    };
    if (!auth.isEmpty && auth.uid) {
      getFirebaseAuthToken();
    }
    // eslint-disable-next-line
  }, [props.auth]);

  React.useEffect(() => {
    const { auth } = props;
    const userDetails = localStorage.getItem('userDetails');

    if (project && project.project_url) {
      const firestore = firebase.firestore();
      const userPushNotificationTokens: any =
        project && project.userPushNotificationTokens ? project.userPushNotificationTokens : [];
      const userPushNotificationTokensFilter = JSON.parse(
        JSON.stringify(userPushNotificationTokens),
      );
      if (!auth.isEmpty && auth.uid) {
        const isExistToken = userPushNotificationTokensFilter.some(
          (ut: any) => ut.uid === auth.uid && ut.token,
        );
        if (!isExistToken && token) {
          userPushNotificationTokens.push({ uid: auth.uid, token });
        }
      }

      const userData = userDetails ? JSON.parse(userDetails) : [];
      if (props.auth.isEmpty && userData && userData.uid) {
        const isExistPublicUserToken = userPushNotificationTokensFilter.some(
          (ut: any) => ut.uid === userData.uid && ut.token,
        );
        if (!isExistPublicUserToken && token) {
          userPushNotificationTokens.push({ uid: userData.uid, token });
        }
      }
      if (userPushNotificationTokens.length) {
        firestore
          .collection('projects')
          .doc(props.match.params.id)
          .update({ userPushNotificationTokens });
      }
    }
    // eslint-disable-next-line
  }, [props.match.params.id, props.auth, project]);

  React.useEffect(() => {
    const firestore = firebase.firestore();
    firestore
      .collection('projects')
      .where(firebase.firestore.FieldPath.documentId(), '==', props.match.params.id)
      .onSnapshot((projectDetails: any) => {
        if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
          const projectDetailData = projectDetails.docs[0].data();
          setProject(projectDetailData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    // eslint-disable-next-line
  }, [props.match.params.id]);

  const {
    iframe,
    match: {
      params: { id },
    },
  } = props;

  const closeChatWindow = () => {
    props.openChatWindow(false);
  };

  const renderIframe = () => {
    const search = window.location.search;
    if (project.type === 'audio') {
      return (
        <div
          id='iframeContainer'
          style={{ zIndex: 10, height: search.includes('view=true') ? '100vh' : '' }}
        >
          <AudioPlayer large id={'project-'} url={project.project_url} />
        </div>
      );
    }

    if (project.type === 'video' || project.type === 'webcam') {
      const arrayVideo: any = [project.project_url];
      if (project.appendVideo && props.auth.email.includes('@reactgeeks.com')) {
        arrayVideo.push(project.appendVideo);
      }
      return (
        <div
          id='iframeContainer'
          className={'video-mode'}
          style={{ zIndex: 10, height: search.includes('view=true') ? '100vh' : '' }}
        >
          {project.project_url && (
            <RenderVideos
              isMediaCreatedByLoginUser={project.project_created_by === props.auth.uid}
              createdTime={project.createdAtUTC}
              blobURL={project.blobURL}
              videos={arrayVideo}
              parentComponent='projectDetails'
            />
          )}
        </div>
      );
    } else {
      return (
        <Iframe
          isWriteAccess={isWriteAccess}
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          url={project.project_url}
          iframe={iframe}
          projectId={id}
          toggleDrawing={toggleDrawing}
          setKeyboardEvent={setKeyboardEvent}
          project={project}
        />
      );
    }
  };
  if (project && project.project_url) {
    const search = window.location.search;
    const chatWindowStyle: any = {
      position: 'absolute',
      top: 50,
      right: props.auth.isEmpty ? 90 : 140,
      overflow: 'auto',
      zIndex: 1000,
    };
    if (isMobile) {
      chatWindowStyle.right = 0;
      chatWindowStyle.top = 0;
      // chatWindowStyle.height = '100vh';
      // chatWindowStyle.backgroundColor = 'white';
      // chatWindowStyle.width = '100vw';
    }
    return (
      <React.Fragment>
        {!search.includes('view=true') && (
          <Navbar
            isWriteAccess={isWriteAccess}
            toggleDrawing={toggleDrawing}
            setDrawing={setDrawing}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            path='/project/:id'
            iframe={iframe}
            setDrawerVisible={setDrawerVisible}
            keyboardEvent={keyboardEvent}
            projectData={project}
          />
        )}
        <div className='container section project-details'>
          <div className='card z-depth-0 card-container'>{renderIframe()}</div>

          {/* {(project.type === 'video' || project.type === 'webcam') && (
            <div
              style={{
                position: 'absolute',
                top: 50,
                right: props.auth.isEmpty ? 90 : 140,
                overflow: 'auto',
                zIndex: 999999,
              }}
            >
              <VideoProjectChat
                isWriteAccess={isWriteAccess}
                toggleDrawing={toggleDrawing}
                setDrawing={setDrawing}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                path='/project/:id'
                iframe={iframe}
                setDrawerVisible={setDrawerVisible}
                keyboardEvent={keyboardEvent}
                projectData={project}
              />
            </div>
          )} */}

          {/* {(project.type === 'video' || project.type === 'webcam') && project.liveStreamId && (
                <div
                  style={ 
                    position: 'absolute',
                    top: 50,
                    right: props.auth.isEmpty ? 90 : 140,
                    overflow: 'auto',
                    zIndex: 1000,
                  }}
                >
                  <VideoProjectChat
                    isWriteAccess={isWriteAccess}
                    pageID={'RFJ2ppf8KLvfg93lsrjt'}
                    circlePointerID={'chat-window'}
                    closeChatWindow={closeChatWindow}
                    direction={'right'}
                    resolved={false}
                    clearShowingMessageid={() => null}
                  />
                </div>
              )} */}

          {(project.type === 'video' || project.type === 'webcam' || project.type === 'audio') && (
            <div style={chatWindowStyle}>
              <VideoProjectChat
                isWriteAccess={isWriteAccess}
                pageID={'RFJ2ppf8KLvfg93lsrjt'}
                circlePointerID={'chat-window'}
                closeChatWindow={closeChatWindow}
                direction={'right'}
                resolved={false}
                clearShowingMessageid={() => null}
              />
            </div>
          )}
        </div>

        {persistCommentWindow && (project.type === 'image' || project.type === 'whiteBoard') && (
          <div style={{ width: '256px', padding: '18px' }}>
            {/* <RenderComments
                project={project}
              /> */}
          </div>
        )}
      </React.Fragment>
    );
  } else if (loading) {
    return (
      <div className='container center'>
        <p>Loading project...</p>
      </div>
    );
  } else {
    return (
      <div className='container center'>
        <p>No project found</p>
      </div>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    iframe: state.project.iframe,
    isOpenChatWindow: state.project.isOpenChatWindow,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    persistCommentWindow: state.project.persistCommentWindow,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    openChatWindow: (isOpenChatWindow: string) => dispatch(openChatWindowAction(isOpenChatWindow)),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProjectDetails);

import React from 'react';

const ArrowUp = () => (
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.5 15.0423V3.95898'
      stroke='#333333'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M3.95825 9.50065L9.49992 3.95898L15.0416 9.50065'
      stroke='#333333'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default ArrowUp;

import { Layout, Menu, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../../static/logo.png';
import { Logo } from '../svg/Logo';
import { inviteAction } from '../../store/actions/authActions';
import '../layout/style.css';
const { Header } = Layout;
const Invitation = (props) => {
  const [message, setMessage] = useState('Loading...');
  // const [invitationActionType, setInvitationActionType] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const adminId = urlParams.get('adminId');
    const userId = urlParams.get('userId');
    const organizationId = urlParams.get('organizationId');
    const actionType = urlParams.get('invitation');
    // setInvitationActionType(actionType);
    if (
      adminId &&
      userId &&
      organizationId &&
      actionType &&
      (actionType === 'accepted' || actionType === 'rejected')
    ) {
      inviteAction(adminId, userId, organizationId, actionType).then((resMessage) => {
        actionType === 'accepted' && window.localStorage.setItem('selectedOrganizationId', organizationId);
        setMessage(resMessage);
      });
    }
  }, []);
  return (
    <React.Fragment>
      <nav className='nav-wrapper grey darken-3'>
        <Layout className='layout'>
          <Header className='header'>
            <Menu theme='light' mode='horizontal' style={{ lineHeight: '64px', display: 'flex' }}>
              <Menu.Item className='nav-item' style={{ display: 'flex', flex: 1 }}>
                <div className='header_logo'>
                  <Link to='/'>
                    <div style={{ height: '52px' }} className='logo'>
                      {/* <img src={logo} alt='Logo' className='logo' /> */}
                      <Logo />
                    </div>
                  </Link>
                </div>
              </Menu.Item>
            </Menu>
          </Header>
        </Layout>
      </nav>
      <div className='container-center'>
        <h2>{message}</h2>
        {message !== 'Loading...' && (
          <Button
            onClick={() => {
              props.history.push('/');
            }}
            className='organization-btn add-org-btn'
          >
            Go To My Zooc
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default Invitation;

import { Drawer, 
    // Col, 
    // Row 
} from 'antd';
import React from 'react';
// import ColorContext from '../../context/ColorContext';
import { mobileCheck } from '../../utils';
import { Close } from '../svg';
import SubscriptionPlan from '../subscription-plan';
const isMobile = mobileCheck();

interface IProps {
    onCloseDrawer: (isShow: boolean) => void;
    visible: boolean;
    profile: any;
    organizationDetail: any;
}

export const SubscriptionDrawer: React.FC<IProps> = ({
    onCloseDrawer,
    visible,
    organizationDetail
}) => {

    return (
        <div>
            <Drawer
                title='Subscription'
                placement='right'
                closable={false}
                onClose={() => onCloseDrawer(false)}
                visible={visible}
                style={{ position: 'absolute', overflowY: 'auto' }}
                bodyStyle={isMobile ? { width: '95%', marginLeft: '3%' } : {}}
                width={isMobile ? '90vw' : '75vw'}
                className='profile-drawer-container'
            >
                <div className='drawer-close-btn' onClick={() => onCloseDrawer(false)}>
                    <Close />
                </div>
                <SubscriptionPlan organizationDetail={organizationDetail} />
            </Drawer>
        </div>
    );
};
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';
export const SAVE_IFRAME = 'SAVE_IFRAME';
export const LOADING = 'LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const SHOULD_DISABLE_UNDO_REDO = 'SHOULD_DISABLE_UNDO_REDO';
export const OPEN_CHAT_WINDOW = 'OPEN_CHAT_WINDOW';
export const OPEN_COMMENT_WINDOW = 'OPEN_COMMENT_WINDOW';

export const ADD_VIDEO_PROJECT = 'ADD_VIDEO_PROJECT';
export const UPDATE_VIDEO_PROJECT = 'UPDATE_VIDEO_PROJECT';
export interface IProjectCreate {
  project_url: string;
  title: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
  imageType: string;
  project_base64_image: any;
  appendVideo: string;
  videoStatus?: string;
}

export interface Iworkflow {
  step: string;
  total: number;
  color: string;
  id: string;
}

export interface ITag {
  title: string;
  color: string;
  id: string;
}

export interface ITranscript {
  time: number;
  text: string;
}

export interface IThread {
  member: IMemberUser;
  message: string;
  mediaTime: number;
  createdAt: ICreatedAt | string;
  replyCount: number;
  sequenceNo: number;
  id: string;
  isResolve: boolean;
  fromLeftPercentage?: number;
  readUserEmails?: string[];
  dummy?: boolean;
  page?: any;
}

export interface IworkflowCanvas {
  canvases: any;
  loadingMoreCanvas: boolean;
  isMoreCanvas: boolean;
  id: string;
  color: string;
  step: string;
}

export interface IMemberUser {
  id?: string;
  name: string;
  created_by?: string;
  image?: string;
  role?: string;
  email: string;
  status?: string;
}

export interface IMasterProject {
  id: string;
  name: string;
  created_by: string;
  imageUrl?: string;
  color?: string;
  imageThumbnailUrl?: string;
  memberUserIds?: string[];
  workflow?: any;
  members?: IMemberUser[];
  member_emails?: string[];
  tags?: ITag[];
}

export interface IAuthType {
  uid: string;
}
export interface CircleId {
  uid: string;
}
export interface IProject {
  firebaseAuthToken: string;
  title: string;
  member_emails: string[];
  project_url: string;
  id: string;
  auth: IAuthType;
  createdAt: ICreatedAt;
  project_base64_image: string;
  unreadFeedbackArray: CircleId[];
  type: string;
  project_created_by: string;
  imageType: string;
  jiraIssueUrl: string;
  trelloIssueUrl: string;
  project_tags: any;
  thumbnail_url: string;
  imageUrl?: string;
  selectedOrganizationData?: any;
  createProject(project: IProjectCreate, firebaseAuthToken: string): any;
}

export interface IPageFeedback {
  project_id: string;
  feedback: object[];
  id: string;
  url: string;
  auth: IAuthType;
  x?: any;
  json?: any;
}
export interface ICreatedAt {
  seconds: number;
}
export interface ICreateProjectAction {
  type: typeof CREATE_PROJECT_SUCCESS;
}
export interface ICreateProjectErrorAction {
  type: typeof CREATE_PROJECT_ERROR;
}

export interface ISaveIframeAction {
  type: typeof SAVE_IFRAME;
  payload: any;
}

export interface Loading {
  type: typeof LOADING;
  payload: any;
}

export interface StopLoading {
  type: typeof STOP_LOADING;
  payload: any;
}

export interface IListPropsTypes {
  isShowInitialStep?: boolean;
  loginUserEmail: string;
  loginUserId: string;
  projects: IProject[];
  loader: boolean;
  authId: string;
  profile: any;
  isWriteAccess: boolean;
  signUpDays: number;
  loadMoreCanvas: any;
  loadingMoreCanvas: boolean;
  isMoreCanvas: boolean;
  canvases: any;
  selectedWorkflowId: string;
  paginationLimit: number;
  selectedMasterProjectId: string;
  isKanbanView?: boolean;
  internalScroll?: boolean;
  selectedMasterProject: any;
  selectedOrganizationData: any;
  onDelete(event: any): void;
}

export interface DisableUndoRedo {
  type: typeof SHOULD_DISABLE_UNDO_REDO;
  payload: any;
}
export interface VideoProject {
  type: typeof ADD_VIDEO_PROJECT;
  payload: any;
}

export interface UpdateVideoProject {
  type: typeof UPDATE_VIDEO_PROJECT;
  payload: any;
}

export interface OpenChatWindow {
  type: typeof OPEN_CHAT_WINDOW;
  payload: any;
}

export interface OpenCommentWindow {
  type: typeof OPEN_COMMENT_WINDOW;
  payload: any;
}

export type ProjectTypes =
  | ICreateProjectAction
  | ICreateProjectErrorAction
  | ISaveIframeAction
  | Loading
  | StopLoading
  | DisableUndoRedo
  | OpenChatWindow
  | VideoProject
  | UpdateVideoProject
  | OpenCommentWindow;

export interface IProjectgColors {
  backgrounds: {
    color1: string;
    color2: string;
    color3: string;
  };
  text: {
    color1: string;
    color2: string;
    color3: string;
  };
  primaryBtn: {
    color1: string;
    color2: string;
    color3: string;
  };
  secondaryBtn: {
    color1: string;
    color2: string;
    color3: string;
  };
  dangerBtn: {
    color1: string;
    color2: string;
    color3: string;
  };
}

export interface IOrgProject {
  projectColors: IProjectgColors;
  logoUrl?: string;
  logoThumbnailUrl?: string;
  projectName: string;
  id: string;
  isDeleted: boolean;
}

export interface IOrg {
  members: IMemberUser[];
  projects: IOrgProject[];
  member_ids: string[];
}

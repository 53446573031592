import { Modal } from 'antd';
import React from 'react';
import { mobileCheck } from '../../utils';

interface MediaPermissionI {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}
const isMobile = mobileCheck();
const MediaPermission = ({ isOpen, setIsOpen }: MediaPermissionI) => {
  return (
    <Modal
      title='Navigator Information'
      visible={isOpen}
      style={{ textAlign: 'center' }}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <div id='microphone-permission-error'>
            <div>
              Unable to access microphone, please allow microphone permission in order to record
              audio and reload this page.
            </div>
            <img
              style={
                isMobile
                  ? { width: '90%', marginTop: '10px' }
                  : { width: '450px', marginTop: '10px' }
              }
              src='/images/allow-microphone.png'
              alt='Allow microphone'
            />
            <img
              style={
                isMobile
                  ? { width: '90%', margin: '10px 20px' }
                  : { width: '450px', display: 'grid', margin: 'auto' }
              }
              src='/images/allow-microphone-in-zooc.png'
              alt='Allow microphone in zooc'
            />
          </div>
    </Modal>
  );
};

export default MediaPermission;
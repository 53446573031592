import React from 'react';

export default () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.0001 27.3333C6.63608 27.3333 0.666748 21.364 0.666748 14C0.666748 6.63596 6.63608 0.666626 14.0001 0.666626C21.3641 0.666626 27.3334 6.63596 27.3334 14C27.3334 21.364 21.3641 27.3333 14.0001 27.3333ZM14.0001 24.6666C16.8291 24.6666 19.5422 23.5428 21.5426 21.5424C23.5429 19.542 24.6667 16.8289 24.6667 14C24.6667 11.171 23.5429 8.45788 21.5426 6.45749C19.5422 4.4571 16.8291 3.33329 14.0001 3.33329C11.1711 3.33329 8.458 4.4571 6.45761 6.45749C4.45722 8.45788 3.33341 11.171 3.33341 14C3.33341 16.8289 4.45722 19.542 6.45761 21.5424C8.458 23.5428 11.1711 24.6666 14.0001 24.6666V24.6666ZM12.6667 18H15.3334V20.6666H12.6667V18ZM12.6667 7.33329H15.3334V15.3333H12.6667V7.33329Z'
      fill='#EB5757'
    />
  </svg>
);

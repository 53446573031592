export const ADD_PAGE_COMMENT_SUCCESS = 'ADD_PAGE_COMMENT_SUCCESS';
export const ADD_PAGE_COMMENT_ERROR = 'ADD_PAGE_COMMENT_ERROR';
export interface InProgressVideo {
  projectId: string;
  pageId: string;
  circleId: string;
  messageId: string;
}
export interface IPage {
  feedback: string;
  sender: string;
  time: string;
  uid: string;
  uuid: string;
}
export interface IPageData {
  page_id: string;
  circle_id: string;
  project_id: string;
}
export interface IAddPageCommentAction {
  type: typeof ADD_PAGE_COMMENT_SUCCESS;
}
export interface IAddPageCommentActionError {
  type: typeof ADD_PAGE_COMMENT_ERROR;
}
export type PageTypes = IAddPageCommentActionError | IAddPageCommentAction;

export interface IFeedback {
  feedback: string;
  sender: string;
  time: string;
  uid: string;
  thumbnail: string;
  originalImage: string;
  attachment: string;
  attachedFileName: string;
  uuid: string;
}
export interface IFeedbackModel {
  id: number;
  state: boolean;
  unread?: number;
  coords: {
    x: number;
    y: number;
  };
  feedbacks?: IFeedback[];
}

import firebaseApp from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Spinner from './components/shared/spinner';
import { checkTrialPeriod } from './store/actions/projectActions';
const urlParams = new URLSearchParams(window.location.search);
const channelId = urlParams.get('channelId') || '';
const slackUserId = urlParams.get('slackUserId') || '';
const recordingType = urlParams.get('type') || 'audio';

interface IProps {
  component: React.ElementType;
  auth: { uid: string; isLoaded: boolean };
  iframe: any;
  exact?: boolean;
  path: string;
  rest?: any;
}

const PrivateRoute: React.SFC<IProps> = ({ component: Component, auth, iframe, ...rest }) => {
  const [isWriteAccess, setIsWriteAccess] = useState(true);
  const [signUpDays, setSignUpDays] = useState(-1);
  useEffect(() => {
    const getFirebaseAuthToken = async () => {
      const fiebaseInstance: any = firebaseApp;
      if (fiebaseInstance && fiebaseInstance.auth() && fiebaseInstance.auth().currentUser) {
        const firebaseUserAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
        firebaseUserAuthToken &&
          checkTrialPeriod(firebaseUserAuthToken).then((res: any) => {
            setIsWriteAccess(res.isWriteAccess ? res.isWriteAccess : false);
            setSignUpDays(res.dayDifference ? res.dayDifference : 0);
          });
      }
    };
    if (auth.uid) {
      getFirebaseAuthToken();
    }
  }, [auth]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isLoaded && auth.uid) {
          return (
            <React.Fragment>
              {!channelId &&
                props.match.path !== '/' &&
                props.match.path !== '/pt/:id' &&
                props.match.path !== '/pk/:id' && (
                  <Navbar
                    isWriteAccess={isWriteAccess}
                    path={rest.path}
                    iframe={iframe}
                    setDrawerVisible={() => null}
                    toggleDrawing={false}
                    setDrawing={() => null}
                    activeTab={''}
                    setActiveTab={() => null}
                    keyboardEvent={''}
                  />
                )}
              <Component signUpDays={signUpDays} {...props} />
            </React.Fragment>
          );
        }

        if (!auth.isLoaded) {
          return <Spinner />;
        }
        return (
          <Redirect
            to={{
              pathname: `/signIn`,
              search: `${
                channelId
                  ? `?channelId=${channelId}&type=${recordingType}&slackUserId=${slackUserId}`
                  : ''
              }`,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state: {
  firebase: { auth: { uid: string; isLoaded: boolean } };
  project: any;
}) => {
  return {
    auth: state.firebase.auth,
    iframe: state.project.iframe,
  };
};
export default connect(mapStateToProps)(PrivateRoute);

import { Button, Modal, Tooltip } from 'antd';
import 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { trackAnalytics } from '../../utils';
import RecordAudioFile from '../recording/RecordAudioFile';

const AudioModal = (props: any) => {
  const [audioRecorded, setAudioRecorded]: any = useState('');
  const [isRecording, setRecording] = useState(false);

  const { projectID, maxRecordTime, setLoading, onSubmit, onClose } = props;

  useEffect(() => {
    return () => {
      setAudioRecorded('');
      window.onbeforeunload = null;
    };
  }, []);

  if (isRecording || audioRecorded) {
    window.onbeforeunload = (event: any) => {
      const message = 'Sure you want to leave?';
      if (typeof event === 'undefined') {
        event = window.event;
      }
      if (event) {
        event.returnValue = message;
      }
      return message;
    };
  } else {
    window.onbeforeunload = null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Tooltip placement='left' title={'Close'}>
        <div className={'crossIconContainer'}>
          <Button
            size='small'
            type='primary'
            icon='cross'
            onClick={() => {
              if (audioRecorded) {
                Modal.confirm({
                  content: '',
                  title: 'Are you sure, your recording will be lost?',
                  onOk: async () => {
                    onClose(false);
                    setRecording(false);
                    setAudioRecorded('');
                    trackAnalytics('Chat window', 'Close audio recording clicked', {
                      projectId: projectID,
                    });
                  },
                });
              } else {
                onClose(false);
                setRecording(false);
                setAudioRecorded('');
              }
            }}
            shape='circle'
          />
        </div>
      </Tooltip>

      <RecordAudioFile
        maxRecordTime={maxRecordTime}
        projectID={projectID}
        setLoading={setLoading}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default AudioModal;

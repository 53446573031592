import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { IThread, ITranscript } from '../../types/projectTypes';
import { getVideoDurationFormat } from '../../utils';
import Iframe from '../iframe';
import RenderVideos from '../projects/RenderVideos';
import { DropdownArrow } from '../svg';
import './style.css';

const Media = ({
  blobURL,
  videoUrl,
  isMediaCreatedByLoginUser,
  createdAt = '',
  createdAtUTC = '',
  pauseVideo,
  changeThread,
  selctedThreadMediaTime,
  transcript,
  iframe,
  projectId,
  toggleDrawing,
  setKeyboardEvent,
  project,
  addComment,
  strokeColor,
  isChatMode,
  smallPlayer,
  changeVideoTime,
}: {
  blobURL: string;
  videoUrl: string;
  isMediaCreatedByLoginUser: boolean;
  createdAt: string;
  createdAtUTC: string;
  pauseVideo: (currentMediaTime: number) => void;
  changeThread?: (selectedThreadData: IThread) => void;
  selctedThreadMediaTime?: number;
  transcript: ITranscript[];

  iframe: any;
  projectId: any;
  toggleDrawing: any;
  setKeyboardEvent: any;
  project: any;
  addComment: any;
  strokeColor: any;
  isChatMode: boolean;
  smallPlayer?: boolean;
  changeVideoTime?: any;
}) => {
  const date = createdAtUTC ? moment(createdAtUTC).fromNow() : '';
  const dateFormat = 'MMM Do [at] h:mm:ss A z';
  const dateToolTip = createdAtUTC ? moment(createdAtUTC).format(dateFormat) : '';
  const [playing, setPlaying] = useState(false);

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <RenderVideos
          isMediaCreatedByLoginUser={isMediaCreatedByLoginUser}
          createdTime={createdAt}
          blobURL={blobURL}
          videos={[videoUrl]}
          parentComponent='projectDetails'
          pauseVideo={pauseVideo}
          changeThread={changeThread}
          selctedThreadMediaTime={selctedThreadMediaTime}
          setPlayingVideo={setPlaying}
          showVisulizer={project.type === 'audio'}
          smallPlayer={smallPlayer}
          projectId={projectId}
          changeVideoTime={changeVideoTime}
        />
        {project.type !== 'audio' && (
          <div
            style={{
              position: 'absolute',
              top: '0px',
              width: '100%',
              height: 'calc(100% - 68px)',
              overflow: 'hidden',
              visibility: playing || !isChatMode ? 'hidden' : 'visible',
            }}
          >
            <Iframe
              isWriteAccess={true}
              drawerVisible={false}
              //  url={`${window.location.origin}/whiteboard-for-comment.html`}
             // url={'http://app.zooc.io/whiteboard-for-comment.html'}
              url={'http://zooc.io/whiteboard-for-comment'}
              iframe={iframe}
              projectId={projectId}
              toggleDrawing={toggleDrawing}
              setKeyboardEvent={setKeyboardEvent}
              project={project}
              addComment={addComment}
              strokeColor={strokeColor}
            />
          </div>
        )}
      </div>
      {transcript.length ? (
        <div className='video-canvas-transcript-section'>
          <div className='video-canvas-time'>
            <Tooltip title={dateToolTip}>{date}</Tooltip>
          </div>
          <div className='video-canvas-transcript'>
            TRANSCRIPT
            <div>
              <DropdownArrow />
            </div>
          </div>
        </div>
      ) : null}
      {transcript.length ? (
        <div className='transcript-container'>
          {transcript &&
            transcript.map((data: ITranscript) => {
              const { time, text } = data;
              return (
                <div className='transcript-item'>
                  <div className='transcript-time'> {getVideoDurationFormat(time)} </div>
                  <div className='transcript-text'>{text}</div>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};

export default Media;

import { Button, Icon, Menu, Modal, Radio, Switch, Tooltip } from 'antd';
import firebase from 'firebase';
import Mousetrap from 'mousetrap';
import React, { Component } from 'react';
import ReactJoyride, { STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { eventConstant } from '../../constant';
import logo from '../../static/logo.png';
import { updateFirebaseAuthToken as updateFirebaseAuthTokenAction } from '../../store/actions/authActions';
import { openChatWindow as openChatWindowAction } from '../../store/actions/projectActions';
import { sendMessageToIframe } from '../../utils';
import { getOnBoardingSteps, trackAnalytics } from '../../utils';
import { mobileCheck, showFullFeature } from '../../utils';
import Notifications from '../notifications';
import CreateProject from '../projects/CreateProject';
import { Logo } from '../svg/Logo';
import './navItem.css';
import Onboarding from './Onboarding';
import { Settings } from './Settings';
import UploadModal from './UploadModal';

const isMobile = mobileCheck();

class NavItems extends Component<any, any> {
  iframe: any;
  constructor(props: any) {
    super(props);
    const REACT_APP_IS_BETA =
      process &&
      process.env &&
      process.env.REACT_APP_IS_BETA &&
      process.env.REACT_APP_IS_BETA.trim() === 'true'
        ? true
        : false;
    const { projectData = { type: '' } } = props;
    const { type } = projectData;
    const steps = getOnBoardingSteps(REACT_APP_IS_BETA ? type : 'beta');
    this.state = {
      detailProjectRun: false,
      toggleCommentryMode: true,
      isLoadedIframe: false,
      initialCommenting: true,
      isChatWindow: false,
      steps,
    };
    this.iframe = React.createRef();
    window.addEventListener('message', this.eventHandler, false);
  }
  componentWillUnmount() {
    this.props.openChatWindow(false);
  }

  handleDetailProjectJoyrideCallback = (data: any) => {
    const { status, action, index } = data;
    const { profile } = this.props;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({
        detailProjectRun: false,
      });
      if (!profile.isDetailOnboardingVisited) {
        trackAnalytics(
          'User onboarding',
          `project detail => clicked ${action}, completed step: ${index}`,
        );
        this.updateUserOnboarding({
          isDetailOnboardingVisited: true,
        });
      }
    }
  };

  updateUserOnboarding = (updateData: any) => {
    const { uid } = this.props.auth;
    if (uid) {
      const firestore = firebase.firestore();
      firestore
        .collection('users')
        .doc(uid)
        .set(updateData, { merge: true });
    }
  };

  openChat = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const notificationCircleId = urlParams.get('circleId');
    if (notificationCircleId) {
      this.setCommentryMode(true);
      const event = eventConstant.SHOW_COMMENTS;
      sendMessageToIframe(this.props.iframe, event, {});
    }
  };

  openDrawerChat = () => {
    this.setState({
      isChatWindow: !this.state.isChatWindow,
    });
  };

  setCommentryMode = (toggleCommentryMode: boolean) => {
    const { setDrawing } = this.props;
    setDrawing(false);
    this.setState({
      toggleCommentryMode,
    });
  };

  setDrawingMode = (drawingType: string, hideDrawing?: boolean) => {
    const { setDrawing, setActiveTab } = this.props;
    if (hideDrawing) {
      const event = eventConstant.HIDE_DRAWINGS;
      sendMessageToIframe(this.props.iframe, event, {});
      setDrawing(false);
    } else {
      if (drawingType === 'clearAll') {
        Modal.confirm({
          content: '',
          title: 'Delete all Annotations?',
          onOk: () => {
            const event = eventConstant.TOGGLE_DRAWING;
            sendMessageToIframe(this.props.iframe, event, {
              drawingType,
            });
            setDrawing(true);
            setActiveTab('freeDraw');
          },
        });
      } else {
        const event = eventConstant.TOGGLE_DRAWING;
        sendMessageToIframe(this.props.iframe, event, {
          drawingType,
        });
        setDrawing(true);
        setActiveTab(drawingType);
      }
    }
  };

  undoOrRedo = (isUndo: boolean) => {
    const event = eventConstant.UNDO_OR_REDO;
    sendMessageToIframe(this.props.iframe, event, {
      isUndo,
    });
  };

  eventHandler = (e: any) => {
    const eventData = e.data;
    const event = eventData.event;
    switch (event) {
      case eventConstant.LOADED_IFRAME:
        this.openChat();
        this.setState({
          isLoadedIframe: true,
        });
        // sendMessageToIframe(this.props.iframe, eventConstant.ADD_FEEDBACK, {});
        break;
      case eventConstant.BROWSER_MODE:
        this.setState({
          toggleCommentryMode: false,
        });

        break;
      case eventConstant.COMMENTRY_MODE:
        this.setState({
          toggleCommentryMode: true,
        });
        break;
      default:
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { keyboardEvent, path, undoOrRedo } = nextProps;
    if (undoOrRedo) {
      this.setState({
        undoDisabled: undoOrRedo.undo,
        redoDisabled: undoOrRedo.redo,
      });
    }
    if (path === '/project/:id' && keyboardEvent !== this.state.keyboardEvent) {
      if (this.state.toggleCommentryMode) {
        keyboardEvent &&
          trackAnalytics('Project Detail', `Keybaord shortcut event to ${keyboardEvent}`);
        switch (keyboardEvent) {
          case 'commenting':
            const { setActiveTab, iframe, setDrawing } = this.props;
            sendMessageToIframe(iframe, eventConstant.ADD_FEEDBACK, {});
            setDrawing(false);
            setActiveTab('commenting');
            break;
          case 'drawArrows':
            this.setDrawingMode('drawArrows');
            break;
          case 'freeDraw':
            this.setDrawingMode('freeDraw');
            break;
          case 'drawCircle':
            this.setDrawingMode('drawCircle');
            break;
          case 'drawRect':
            this.setDrawingMode('drawRect');
            break;
          case 'drawLine':
            this.setDrawingMode('drawLine');
            break;
          case 'addText':
            this.setDrawingMode('addText');
            break;
          default:
            break;
        }
      }
      if (keyboardEvent === 'browse') {
        const { iframe } = this.props;
        this.setCommentryMode(!this.state.toggleCommentryMode);
        const event = eventConstant.HIDE_COMMENTS;
        sendMessageToIframe(iframe, event, {});
      } else if (keyboardEvent === 'comment') {
        const { setActiveTab, iframe } = this.props;
        this.setCommentryMode(!this.state.toggleCommentryMode);
        const event = eventConstant.SHOW_COMMENTS;
        setActiveTab('commenting');
        sendMessageToIframe(iframe, event, {});
      }
      this.setState({ keyboardEvent });
    }
  }

  async componentDidMount() {
    const { path, auth, updateFirebaseAuthToken } = this.props;
    const loginUserId = auth.uid;
    const firestore = firebase.firestore();
    if (loginUserId) {
      firestore
        .collection('users')
        .where(firebase.firestore.FieldPath.documentId(), '==', loginUserId)
        .onSnapshot((usersDetails) => {
          if (usersDetails && usersDetails.docs && usersDetails.docs.length) {
            const usersDetailDocs = usersDetails.docs[0];
            const usersDetail = usersDetailDocs.data();
            if (usersDetail.isDetailOnboardingVisited) {
              this.setState({
                detailProjectRun: false,
              });
            } else {
              this.setState({
                detailProjectRun: true,
              });
            }
          }
        });
    }
    if (path === '/project/:id') {
      Mousetrap.bind(
        ['b', 'B', 'c', 'C', 'd', 'D', 'a', 'A', 'x', 'X', 'r', 'R', 'l', 'L', 't', 'T'],
        (e: any) => {
          if (this.state.toggleCommentryMode && !auth.isEmpty) {
            const { setActiveTab, iframe, setDrawing } = this.props;
            switch (e.keyCode) {
              case 99:
              case 67:
                sendMessageToIframe(iframe, eventConstant.ADD_FEEDBACK, {});
                setDrawing(false);
                setActiveTab('commenting');
                trackAnalytics('Project Detail', `Keybaord shortcut event to commenting`);
                break;
              case 100:
              case 68:
                this.setDrawingMode('freeDraw');
                trackAnalytics('Project Detail', `Keybaord shortcut event to freeDraw`);
                break;
              case 97:
              case 65:
                this.setDrawingMode('drawArrows');
                trackAnalytics('Project Detail', `Keybaord shortcut event to drawArrows`);
                break;
              case 120:
              case 88:
                this.setDrawingMode('drawRect');
                trackAnalytics('Project Detail', `Keybaord shortcut event to drawRect`);
                break;
              case 114:
              case 82:
                this.setDrawingMode('drawCircle');
                trackAnalytics('Project Detail', `Keybaord shortcut event to drawCircle`);
                break;
              case 108:
              case 76:
                this.setDrawingMode('drawLine');
                trackAnalytics('Project Detail', `Keybaord shortcut event to drawLine`);
                break;
              case 116:
              case 84:
                this.setDrawingMode('addText');
                trackAnalytics('Project Detail', `Keybaord shortcut event to addText`);
                break;
              default:
                break;
            }
          }
          if ((e.keyCode === 98 || e.keyCode === 66) && !auth.isEmpty) {
            const { setActiveTab, iframe } = this.props;
            this.setCommentryMode(!this.state.toggleCommentryMode);
            let event = eventConstant.HIDE_COMMENTS;
            if (this.state.toggleCommentryMode) {
              event = eventConstant.SHOW_COMMENTS;
              setActiveTab('commenting');
            }
            sendMessageToIframe(iframe, event, {});
          }
        },
      );
      Mousetrap.bind(['command+z', 'ctrl+z', 'command+shift+z', 'ctrl+shift+z'], (e: any) => {
        if (e.shiftKey && !this.state.redoDisabled && !auth.isEmpty) {
          this.undoOrRedo(false);
        } else if (!e.shiftKey && !this.state.undoDisabled) {
          this.undoOrRedo(true);
        }
      });
    }

    const fiebaseInstance: any = firebase;
    let firebaseAuthToken = '';
    if (fiebaseInstance && fiebaseInstance.auth() && fiebaseInstance.auth().currentUser) {
      firebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
      updateFirebaseAuthToken(firebaseAuthToken);
    }
  }

  public render() {
    const isShowFullFeature = showFullFeature();
    const REACT_APP_IS_BETA =
      process &&
      process.env &&
      process.env.REACT_APP_IS_BETA &&
      process.env.REACT_APP_IS_BETA.trim() === 'true'
        ? true
        : false;
    const {
      iframe,
      path,
      auth,
      setDrawerVisible,
      setDrawing,
      activeTab,
      setActiveTab,
      projectData = { type: '' },
      videoProject,
      isWriteAccess,
      persistCommentWindow,
    } = this.props;
    const { uid = '' } = auth;
    const { toggleCommentryMode, steps } = this.state;

    const renderVideoFrame = () => {
      if (
        projectData.type === 'video' ||
        projectData.type === 'webcam' ||
        projectData.type === 'audio'
      ) {
        return (
          <span style={{ marginRight: '15px' }}>
            <Tooltip placement='bottom' title={isMobile ? '' : 'Chat'}>
              <Button
                onClick={() => {
                  this.props.openChatWindow(true);
                  trackAnalytics('Project type video', `open Comments clicked`);
                }}
                type='primary'
                shape='circle'
                size='large'
                className={'comment-icon'}
              >
                <Icon
                  style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                  type='wechat'
                />
              </Button>
            </Tooltip>
          </span>
        );
      }
    };

    return (
      <>
        {/* { For now, Removed onboarding using false condition } */}
        {path === '/project/:id' && false && (
          <ReactJoyride
            callback={this.handleDetailProjectJoyrideCallback}
            run={this.state.detailProjectRun}
            continuous
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={steps}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
          />
        )}
        <Menu theme='light' mode='horizontal' style={{ lineHeight: '64px', display: 'flex' }}>
          <Menu.Item className='nav-item' style={{ display: 'flex', flex: 1 }}>
            <div className='header_logo'>
              <Link to='/' className='d-flex'>
                {false && <img src={logo} alt='Logo' className='logo' />}
                <Logo />
              </Link>
            </div>
          </Menu.Item>
          {path === '/project/:id' &&
            projectData.type !== 'video' &&
            projectData.type !== 'webcam' &&
            projectData.type !== 'audio' &&
            (projectData.type !== 'image' && projectData.type !== 'whiteBoard' && (
              <Menu.Item className='nav-item'>
                <span style={{ marginRight: '15px' }}>
                  <Tooltip
                    placement='bottom'
                    title={toggleCommentryMode ? 'Commenting Mode (b)' : 'Browsing Mode (b)'}
                  >
                    <span className='pr5'>Comment</span>
                    <Switch
                      disabled={!this.state.isLoadedIframe}
                      defaultChecked
                      className='switch_buttons'
                      checked={!toggleCommentryMode}
                      onChange={(val) => {
                        this.setCommentryMode(!toggleCommentryMode);
                        let event = eventConstant.HIDE_COMMENTS;
                        if (!val) {
                          event = eventConstant.SHOW_COMMENTS;
                          setActiveTab('commenting');
                        }
                        sendMessageToIframe(iframe, event, {});
                      }}
                    />
                    <span className='pl5'>Browse</span>
                  </Tooltip>
                </span>
              </Menu.Item>
            ))}

          <Menu.Item
            className='nav-item'
            style={{ display: 'flex', flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end' }}
          >
            <div id={'radioButton'} className='nav-item-list plus_button'>
              {REACT_APP_IS_BETA && (
                <>
                  {path === '/project/:id' &&
                    toggleCommentryMode &&
                    projectData.type !== 'video' &&
                    projectData.type !== 'webcam' &&
                    projectData.type !== 'audio' && (
                      <Radio.Group
                        style={{ marginRight: '15px' }}
                        defaultValue='commenting'
                        size='large'
                        value={activeTab}
                        disabled={!isWriteAccess}
                      >
                        <Tooltip placement='bottom' title='Add New Comments (c)'>
                          <Radio.Button
                            className={'commenting border-none'}
                            onClick={() => {
                              if (isWriteAccess) {
                                sendMessageToIframe(iframe, eventConstant.ADD_FEEDBACK, {});
                                setDrawing(false);
                                setActiveTab('commenting');
                                trackAnalytics('Project Detail', 'add new comments clicked');
                              }
                            }}
                            value='commenting'
                          >
                            <Icon
                              style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                              type='plus'
                            />
                          </Radio.Button>
                        </Tooltip>
                        <span className='drawing-options'>
                          <Tooltip placement='bottom' title={'Add New Drawings (d)'}>
                            <Radio.Button
                              className={'freeDraw border-none'}
                              onClick={() => {
                                if (isWriteAccess) {
                                  trackAnalytics('Project Detail', `editing tab clicked freeDraw`);
                                  this.setDrawingMode('freeDraw');
                                }
                              }}
                              value='freeDraw'
                            >
                              <Icon
                                style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                                type='edit'
                              />
                            </Radio.Button>
                          </Tooltip>

                          <Tooltip placement='bottom' title={'Add New Arrows (a)'}>
                            <Radio.Button
                              className={'drawArrows border-none'}
                              onClick={() => {
                                if (isWriteAccess) {
                                  trackAnalytics('Project Detail', `editing tab clicked Arrows`);
                                  this.setDrawingMode('drawArrows');
                                }
                              }}
                              value='drawArrows'
                            >
                              <Icon
                                style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                                type='arrow-right'
                              />
                            </Radio.Button>
                          </Tooltip>

                          <Tooltip placement='bottom' title={'Add New Boxes (x)'}>
                            <Radio.Button
                              className={'drawRect border-none'}
                              onClick={() => {
                                if (isWriteAccess) {
                                  this.setDrawingMode('drawRect');
                                  trackAnalytics('Project Detail', `editing tab clicked Boxes`);
                                }
                              }}
                              value='drawRect'
                            >
                              <Icon
                                style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                                type='border'
                              />
                            </Radio.Button>
                          </Tooltip>
                          <Tooltip placement='bottom' title={'Add New Circles (r)'}>
                            <Radio.Button
                              className={'drawCircle border-none'}
                              onClick={() => {
                                if (isWriteAccess) {
                                  this.setDrawingMode('drawCircle');
                                  trackAnalytics('Project Detail', `editing tab clicked Circles`);
                                }
                              }}
                              value='drawCircle'
                            >
                              <span style={{ padding: '5px', marginTop: '5px' }}>
                                <svg
                                  style={{
                                    fill: activeTab === 'drawCircle' ? '#039BE5' : '#444f5f',
                                    height: '20px',
                                  }}
                                  id='Capa_1'
                                  enable-background='new 0 0 551.13 551.13'
                                  viewBox='0 0 551.13 551.13'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='m275.565 551.13c-151.944 0-275.565-123.621-275.565-275.565s123.621-275.565 275.565-275.565 275.565 123.621 275.565 275.565-123.621 275.565-275.565 275.565zm0-516.684c-132.955 0-241.119 108.181-241.119 241.119s108.164 241.12 241.119 241.12 241.119-108.181 241.119-241.119-108.164-241.12-241.119-241.12z' />
                                </svg>
                              </span>
                            </Radio.Button>
                          </Tooltip>
                          <Tooltip placement='bottom' title={'Add New Lines (l)'}>
                            <Radio.Button
                              className={'drawLine border-none'}
                              onClick={() => {
                                if (isWriteAccess) {
                                  this.setDrawingMode('drawLine');
                                  trackAnalytics('Project Detail', `editing tab clicked drawLine`);
                                }
                              }}
                              value='drawLine'
                            >
                              <Icon
                                style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                                type='line'
                              />
                            </Radio.Button>
                          </Tooltip>
                          <Tooltip placement='bottom' title={'Add Text (t)'}>
                            <Radio.Button
                              className={'addText border-none'}
                              onClick={() => {
                                if (isWriteAccess) {
                                  this.setDrawingMode('addText');
                                  trackAnalytics('Project Detail', `editing tab clicked addText`);
                                }
                              }}
                              value='addText'
                            >
                              <Icon
                                style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                                type='font-size'
                              />
                            </Radio.Button>
                          </Tooltip>
                        </span>
                      </Radio.Group>
                    )}
                  {((path === '/project/:id' &&
                    toggleCommentryMode &&
                    activeTab !== 'commenting' &&
                    projectData.type !== 'video' &&
                    projectData.type !== 'webcam' &&
                    projectData.type !== 'audio') ||
                    (path === '/project/:id' && projectData.type === 'whiteBoard')) && (
                    <span style={{ marginRight: '15px' }}>
                      <Tooltip placement='bottom' title='Clear All Annotatons'>
                        <Button
                          disabled={!isWriteAccess}
                          type='primary'
                          shape='circle'
                          size='large'
                          onClick={() => {
                            if (isWriteAccess) {
                              this.setDrawingMode('clearAll');
                              trackAnalytics('Project Detail', `editing tab clicked clearAll`);
                            }
                          }}
                        >
                          <Icon
                            style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                            type='delete'
                          />
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                  {((path === '/project/:id' &&
                    toggleCommentryMode &&
                    activeTab !== 'commenting' &&
                    projectData.type !== 'video' &&
                    projectData.type !== 'webcam' &&
                    projectData.type !== 'audio') ||
                    (path === '/project/:id' && projectData.type === 'whiteBoard')) && (
                    <span style={{ marginRight: '15px' }}>
                      <Tooltip placement='bottom' title='Undo Annotation'>
                        <Button
                          type='primary'
                          shape='circle'
                          size='large'
                          disabled={this.state.undoDisabled || !isWriteAccess}
                          onClick={() => {
                            if (isWriteAccess) {
                              this.undoOrRedo(true);
                              trackAnalytics('Project Detail', `editing tab clicked undo`);
                            }
                          }}
                        >
                          <Icon
                            style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                            type='undo'
                          />
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                  {((path === '/project/:id' &&
                    toggleCommentryMode &&
                    activeTab !== 'commenting' &&
                    projectData.type !== 'video' &&
                    projectData.type !== 'webcam' &&
                    projectData.type !== 'audio') ||
                    (path === '/project/:id' && projectData.type === 'whiteBoard')) && (
                    <span style={{ marginRight: '15px' }}>
                      <Tooltip placement='bottom' title='Redo Annotation'>
                        <Button
                          type='primary'
                          shape='circle'
                          size='large'
                          disabled={this.state.redoDisabled || !isWriteAccess}
                          onClick={() => {
                            if (isWriteAccess) {
                              this.undoOrRedo(false);
                              trackAnalytics('Project Detail', `editing tab clicked redo`);
                            }
                          }}
                        >
                          <Icon
                            style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                            type='redo'
                          />
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                </>
              )}
              {path === '/project/:id' &&
                toggleCommentryMode &&
                projectData.type !== 'video' &&
                projectData.type !== 'webcam' &&
                projectData.type !== 'audio' &&
                !persistCommentWindow && (
                  <span style={{ marginRight: '15px' }}>
                    <Tooltip placement='bottom' title='Comments'>
                      <Button
                        className={'comment-icon'}
                        type='primary'
                        shape='circle'
                        size='large'
                        onClick={() => {
                          setDrawerVisible(true);
                          trackAnalytics('Project Detail', `open Comments clicked`);
                        }}
                      >
                        <Icon
                          onClick={() => setDrawerVisible(true)}
                          style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
                          type='wechat'
                        />
                      </Button>
                    </Tooltip>
                  </span>
                )}

              {renderVideoFrame()}
              {videoProject.length > 0 && <UploadModal videoProject={videoProject} />}
              {path === '/' && <CreateProject isWriteAccess={isWriteAccess} />}
              { isShowFullFeature && <Notifications loginUserId={uid} />}
              {!auth.isEmpty && false && <Settings />}
              {/* { For now, Removed onboarding using false condition } */}
              {false && <Onboarding />}
            </div>
          </Menu.Item>
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    undoOrRedo: state.project.undoOrRedo,
    isOpenChatWindow: state.project.isOpenChatWindow,
    videoProject: state.project.videoProject,
    persistCommentWindow: state.project.persistCommentWindow,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, any>) => {
  return {
    updateFirebaseAuthToken: (firebaseAuthToken: string) =>
      dispatch(updateFirebaseAuthTokenAction(firebaseAuthToken)),
    openChatWindow: (isOpenChatWindow: string) => dispatch(openChatWindowAction(isOpenChatWindow)),
  };
};

export default connect<any>(
  mapStateToProps,
  mapDispatchToProps,
)(NavItems as any) as any;

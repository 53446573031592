import { Button, notification, Progress } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import MediaDragDrop from './MediaDragDrop';
import './style.css';

let ajaxRequest: any = null;

const UploadDialogContent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const {
    createVideoTask,
    firebaseAuthToken,
    isLoading,
    ShowNewVideoCanvas,
    projectType,
    createImageTask,
    createPdfTask,
    createAudioTask,
    profile,
    auth,
    selectedOrganizationData,
  } = props;

  let loginUserFireaseToken = firebaseAuthToken;
  const CancelToken = axios.CancelToken;

  const handleFileUpload = async (files: any) => {
    let userQuota: any = {};
    if (profile.isManagedUserQuotaInOrganization) {
      userQuota = selectedOrganizationData.userQuota;
    } else {
      userQuota = profile.userQuota;
    }
    if (auth && auth.uid && userQuota) {
      userQuota.storageLimit = userQuota.storageLimit - files[0].size / 1024;
      profile.userQuota = userQuota;
      if (userQuota.storageLimit <= 0) {
        notification.error({
          message: 'Your current plan doesn’t include using this feature.',
        });
        return true;
      }
      if (projectType === 'image' || projectType === 'pdf' || projectType === 'audio') {
        const firestore = firebase.firestore();
        if (profile.isManagedUserQuotaInOrganization) {
          firestore
            .collection('organization')
            .doc(selectedOrganizationData.id)
            .update({
              userQuota,
            });
        } else {
          firestore
            .collection('users')
            .doc(auth.uid)
            .update({
              userQuota,
            });
        }
      }
    }
    if (projectType === 'image') {
      setLoading(true);
      const fileName = 'file' + new Date().getTime();
      const storageRef = firebase
        .storage()
        .ref()
        .child(fileName);
      const url = await storageRef.put(files[0]).then((videoData: any) => {
        return videoData.ref.getDownloadURL().then((urlTemp: string) => {
          return urlTemp;
        });
      });
      createImageTask(url, 'image', '');
      setLoading(false);
    } else if (projectType === 'audio') {
      setLoading(true);
      const fileName = 'file' + new Date().getTime();
      const storageRef = firebase
        .storage()
        .ref()
        .child(fileName);
      const url = await storageRef.put(files[0]).then((videoData: any) => {
        return videoData.ref.getDownloadURL().then((urlTemp: string) => {
          return urlTemp;
        });
      });
      createAudioTask(url);
      setLoading(false);
    } else if (projectType === 'pdf') {
      setLoading(true);
      const fileName = 'file' + new Date().getTime();
      const storageRef = firebase
        .storage()
        .ref()
        .child(fileName);
      const url = await storageRef.put(files[0]).then((pdfData: any) => {
        return pdfData.ref.getDownloadURL().then((urlTemp: string) => {
          return urlTemp;
        });
      });
      createPdfTask(url, '');
    } else {
      const fileName = uuid();
      if (!loginUserFireaseToken) {
        const fiebaseInstance: any = firebase;
        loginUserFireaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
      }
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('fileName', fileName);
      const { REACT_APP_PDF_TO_PNG_SERVER = 'http://localhost:3010' } = process.env;
      //  REACT_APP_PROXY_SERVER = 'http://localhost:4400',
      ajaxRequest = CancelToken.source();
      axios
        .request({
          headers: {
            Authorization: `Bearer ${loginUserFireaseToken}`,
          },
          method: 'post',
          url: `${REACT_APP_PDF_TO_PNG_SERVER}/post-video`,
          // projectType === 'pdf'
          //   ? `${REACT_APP_PDF_TO_PNG_SERVER}/`
          //   : `https://pdf2png-dev-service.zooc.io/post-video`,
          data: formData,
          onUploadProgress: (p: any) => {
            setPercentage((p.loaded / p.total) * 100);
            if (p.loaded === p.total) {
              // setLoading(true);
              // if (projectType === 'video') {
              const videoURL = `https://zooc-media-files.s3.eu-central-1.amazonaws.com/${fileName}.m3u8`;
              createVideoTask(videoURL, 'videoUpload', URL.createObjectURL(files[0]));
              // } else if (projectType === 'pdf') {
              //   setLoading(true);
              // }
            }
          },
          cancelToken: ajaxRequest.token,
        })
        // .then((data: any) => {
        //   const response = data.data;
        //   if (projectType === 'pdf' && response.status) {
        //     const { project_url, thumbnail_url } = response;
        //     createPdfTask(project_url, thumbnail_url);
        //   }
        //   //  setLoading(false);
        //   // const videoURL = `https://zooc-media-files.s3.eu-central-1.amazonaws.com/${response.fileName}.m3u8`;
        //   // createVideoTask(videoURL, 'videoUpload', URL.createObjectURL(files[0]));
        // })
        .catch(() => {
          setLoading(false);
          notification.error({
            message: 'Something went wrong',
          });
        });
    }
  };

  return (
    <>
      <div className='dialogBodyContainer videoUploadProgressContainer'>
        {percentage > 0 && percentage < 100 && (
          <Progress
            strokeColor={'#039BE5'}
            percent={percentage ? Math.floor(percentage) : 0}
            size='small'
            status={percentage === 100 ? 'success' : 'active'}
          />
        )}
        {(percentage === 100 || percentage === 0) && (
          <MediaDragDrop
            loader={loading || isLoading}
            handleFileUpload={handleFileUpload}
            projectType={projectType}
          />
        )}
      </div>
      <div className='dialogFooter'>
        <div className='dialogCTAsContainer' style={{ justifyContent: 'flex-end' }}>
          <div>
            <Button
              className='cancelButton'
              onClick={() => {
                ajaxRequest && ajaxRequest.cancel('Operation canceled by the user.');
                ShowNewVideoCanvas && ShowNewVideoCanvas(false);
              }}
            >
              Cancel
            </Button>
          </div>
          {/* <div style={{ marginLeft: '10px' }}>
            <Button className='uploadButton'>Upload</Button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UploadDialogContent as any;

import React from 'react';

export default () => (
  <svg width='13' height='11' viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4.13636 3.09405L6.50002 1.91222V8.27273L4.13636 7.0909V3.09405ZM3.40598 2.13793L7.68184 0V10.1849L3.40597 8.04702H1.18182C0.529118 8.04702 0 7.5179 0 6.8652V3.31974C0 2.66704 0.529118 2.13793 1.18182 2.13793H3.40598ZM2.95454 3.31974H1.18181V6.86519H2.95454V3.31974ZM11.0376 0.0443454C12.2399 1.24036 13 3.06471 13 5.09265C13 7.12059 12.2399 8.94493 11.0376 10.1409L10.1064 9.39595C11.1379 8.43343 11.8182 6.87257 11.8182 5.09265C11.8182 3.31273 11.1379 1.75186 10.1064 0.789339L11.0376 0.0443454ZM10.6363 5.09246C10.6363 3.66798 10.0838 2.3816 9.20323 1.51167L8.27108 2.25739C8.98603 2.9012 9.45451 3.92581 9.45451 5.09246C9.45451 6.25911 8.98603 7.28372 8.27108 7.92753L9.20323 8.67325C10.0838 7.80333 10.6363 6.51694 10.6363 5.09246Z'
      fill='#306AE1'
    />
  </svg>
);

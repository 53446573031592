import React from 'react';

const ArrowDown = () => (
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.5 3.95898V15.0423'
      stroke='#333333'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M15.0416 9.5L9.49992 15.0417L3.95825 9.5'
      stroke='#333333'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default ArrowDown;

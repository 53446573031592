import React from 'react';

export const Cover = () => {
  return (
    <svg
      width='370'
      height='63'
      viewBox='0 0 370 63'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d)'>
        <rect x='15.0713' y='13.5' width='339.929' height='32' rx='5' fill='#ECF5FF' />
        <rect x='15.5713' y='14' width='338.929' height='31' rx='4.5' stroke='#E0E0E0' />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='0.0712891'
          y='0.5'
          width='369.929'
          height='62'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='7.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};

export const Pencil = ({ active }: any) => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.7626 2.0835C14.3082 2.0835 14.8312 2.29045 15.2132 2.65621L17.7973 5.11108C18.182 5.47658 18.3982 5.9723 18.3982 6.48918C18.3982 7.00607 18.182 7.50179 17.7973 7.86728L8.3627 16.8273C7.78063 17.4652 6.95553 17.8571 6.01028 17.9187H1.73145V17.127L1.73415 13.7901C1.80514 12.9553 2.21367 12.1791 2.83686 11.6575L12.3109 2.65716C12.6951 2.28999 13.2176 2.0835 13.7626 2.0835ZM5.95143 16.3373C6.39662 16.3074 6.81102 16.1105 7.14377 15.7489L13.4454 9.76244L10.3167 6.79011L3.9782 12.8103C3.64057 13.0939 3.4317 13.4908 3.39811 13.8539V16.3358L5.95143 16.3373ZM11.4954 5.67066L14.6239 8.64285L16.6188 6.7477C16.6909 6.67913 16.7315 6.58615 16.7315 6.48918C16.7315 6.39222 16.6909 6.29923 16.6188 6.23067L14.0324 3.77359C13.961 3.70526 13.8639 3.66683 13.7626 3.66683C13.6612 3.66683 13.5641 3.70526 13.4928 3.77359L11.4954 5.67066Z'
        fill={active ? '#306AE1' : '#4F4F4F'}
      />
    </svg>
  );
};

export const Box = ({ active }: any) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.8975 2.0835H3.23079C2.35634 2.0835 1.64746 2.79238 1.64746 3.66683V16.3335C1.64746 17.2079 2.35634 17.9168 3.23079 17.9168H15.8975C16.7719 17.9168 17.4808 17.2079 17.4808 16.3335V3.66683C17.4808 2.79238 16.7719 2.0835 15.8975 2.0835ZM3.23079 16.3335V3.66683H15.8975V16.3335H3.23079Z'
        fill={active ? '#306AE1' : '#333333'}
      />
    </svg>
  );
};

export const Line = ({ active }: any) => {
  return (
    <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line
        x1='1.35735'
        y1='13.9037'
        x2='14.3065'
        y2='0.954581'
        stroke={active ? '#306AE1' : '#333333'}
        stroke-width='2'
      />
    </svg>
  );
};

export const Arrow = ({ active }: any) => {
  return (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.3885 3.361L1.24952 14.5L0.0644531 13.3149L11.2035 2.17593H4.84682V0.5H14.0645V9.71763H12.3885V3.361Z'
        fill={active ? '#306AE1' : '#333333'}
      />
    </svg>
  );
};

export const Circle = ({ active }: any) => {
  return (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0.897461 8.99984C0.897461 13.8093 5.00152 17.7082 10.0641 17.7082C15.1267 17.7082 19.2308 13.8093 19.2308 8.99984C19.2308 4.19036 15.1267 0.291504 10.0641 0.291504C5.00152 0.291504 0.897461 4.19036 0.897461 8.99984ZM17.5641 8.99984C17.5641 12.9349 14.2063 16.1248 10.0641 16.1248C5.92199 16.1248 2.56413 12.9349 2.56413 8.99984C2.56413 5.06481 5.92199 1.87484 10.0641 1.87484C14.2063 1.87484 17.5641 5.06481 17.5641 8.99984Z'
        fill={active ? '#306AE1' : '#333333'}
      />
    </svg>
  );
};

export const Text = ({ active }: any) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.23079 2.0835H15.8975C16.7719 2.0835 17.4808 2.79238 17.4808 3.66683V16.3335C17.4808 17.2079 16.7719 17.9168 15.8975 17.9168H3.23079C2.35634 17.9168 1.64746 17.2079 1.64746 16.3335V3.66683C1.64746 2.79238 2.35634 2.0835 3.23079 2.0835ZM3.23079 3.66683V16.3335H15.8975V3.66683H3.23079ZM10.3558 7.62516V12.3752H11.1475V13.9585H7.98079V12.3752H8.77246V7.62516H7.18913V8.41683H5.60579V6.04183H13.5225V8.41683H11.9391V7.62516H10.3558Z'
        fill={active ? '#306AE1' : '#333333'}
      />
    </svg>
  );
};

export const Record = ({ color }: any) => {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='9.7666' cy='9.5' r='9' fill={color} />
    </svg>
  );
};

export const Undo = ({ disable }: any) => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.49967 8.83317H12.4997C13.878 8.83317 14.9997 9.95484 14.9997 11.3332C14.9997 12.7115 13.878 13.8332 12.4997 13.8332H9.99967V15.4998H12.4997C14.7972 15.4998 16.6663 13.6307 16.6663 11.3332C16.6663 9.03567 14.7972 7.1665 12.4997 7.1665H7.49967V4.6665L3.33301 7.99984L7.49967 11.3332V8.83317Z'
        fill={disable ? '#ccc' : '#333333'}
      />
    </svg>
  );
};

export const Redo = ({ disable }: any) => {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.49967 15.4998H9.99967V13.8332H7.49967C6.12134 13.8332 4.99967 12.7115 4.99967 11.3332C4.99967 9.95484 6.12134 8.83317 7.49967 8.83317H12.4997V11.3332L16.6663 7.99984L12.4997 4.6665V7.1665H7.49967C5.20217 7.1665 3.33301 9.03567 3.33301 11.3332C3.33301 13.6307 5.20217 15.4998 7.49967 15.4998Z'
        fill={disable ? '#ccc' : '#333333'}
      />
    </svg>
  );
};

export const Erase = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.1546 12.8401L5.7371 16.4226C5.81435 16.5002 5.90618 16.5617 6.0073 16.6036C6.10841 16.6455 6.21681 16.667 6.32627 16.6668H16.3271V15.0001H10.5288L16.5471 8.9818C17.1971 8.33263 17.1971 7.2743 16.5471 6.62513L12.4213 2.50013C12.1084 2.18829 11.6847 2.01318 11.2429 2.01318C10.8012 2.01318 10.3775 2.18829 10.0646 2.50013L6.10627 6.45763L2.1446 10.4935C1.83793 10.8076 1.66709 11.2298 1.66896 11.6688C1.67083 12.1079 1.84526 12.5286 2.1546 12.8401ZM11.2429 3.67846L15.3679 7.80346L13.2129 9.95846L9.08793 5.83346L11.2429 3.67846ZM7.29043 7.63013L7.9096 7.0118L12.0346 11.1368L8.2371 14.9343C8.21682 14.9552 8.19763 14.9772 8.1796 15.0001H6.67127L3.33377 11.661L7.29043 7.63013Z'
        fill='#333333'
      />
    </svg>
  );
};

export const ChatFloatingIcon = () => (
  <svg width='86' height='86' viewBox='0 0 86 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d)'>
      <circle cx='43' cy='47' r='28' fill='white' />
    </g>
    <path
      d='M41 32.875C32.7148 32.875 26 38.3301 26 45.0625C26 47.9688 27.2539 50.6289 29.3398 52.7207C28.6074 55.6738 26.1582 58.3047 26.1289 58.334C26 58.4688 25.9648 58.668 26.041 58.8438C26.1172 59.0195 26.2812 59.125 26.4688 59.125C30.3535 59.125 33.2656 57.2617 34.707 56.1133C36.623 56.834 38.75 57.25 41 57.25C49.2852 57.25 56 51.7949 56 45.0625C56 38.3301 49.2852 32.875 41 32.875ZM33.5 46.9375C32.4629 46.9375 31.625 46.0996 31.625 45.0625C31.625 44.0254 32.4629 43.1875 33.5 43.1875C34.5371 43.1875 35.375 44.0254 35.375 45.0625C35.375 46.0996 34.5371 46.9375 33.5 46.9375ZM41 46.9375C39.9629 46.9375 39.125 46.0996 39.125 45.0625C39.125 44.0254 39.9629 43.1875 41 43.1875C42.0371 43.1875 42.875 44.0254 42.875 45.0625C42.875 46.0996 42.0371 46.9375 41 46.9375ZM48.5 46.9375C47.4629 46.9375 46.625 46.0996 46.625 45.0625C46.625 44.0254 47.4629 43.1875 48.5 43.1875C49.5371 43.1875 50.375 44.0254 50.375 45.0625C50.375 46.0996 49.5371 46.9375 48.5 46.9375Z'
      fill='#001DAD'
    />
    <g clip-path='url(#clip0)'>
      <path
        d='M55.5 44.9375C59.6426 44.9375 63 47.665 63 51.0312C63 52.4258 62.417 53.7031 61.4502 54.7314C61.8867 55.8857 62.7949 56.8643 62.8096 56.876C63.0029 57.0811 63.0557 57.3799 62.9443 57.6377C62.833 57.8955 62.5781 58.0625 62.2969 58.0625C60.4951 58.0625 59.0742 57.3096 58.2217 56.7061C57.375 56.9727 56.4609 57.125 55.5 57.125C51.3574 57.125 48 54.3975 48 51.0312C48 47.665 51.3574 44.9375 55.5 44.9375ZM55.5 55.7188C56.2822 55.7188 57.0557 55.5986 57.7969 55.3643L58.4619 55.1533L59.0332 55.5576C59.4522 55.8535 60.0264 56.1846 60.7178 56.4072C60.5039 56.0527 60.2959 55.6543 60.1348 55.2295L59.8242 54.4062L60.4277 53.7676C60.958 53.2021 61.5938 52.2676 61.5938 51.0312C61.5938 48.4473 58.8604 46.3438 55.5 46.3438C52.1397 46.3438 49.4063 48.4473 49.4063 51.0312C49.4063 53.6152 52.1397 55.7188 55.5 55.7188Z'
        fill='#001DAD'
      />
    </g>
    <defs>
      <filter
        id='filter0_d'
        x='0'
        y='0'
        width='86'
        height='86'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='-4' />
        <feGaussianBlur stdDeviation='7.5' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
      <clipPath id='clip0'>
        <rect width='15' height='15' fill='white' transform='matrix(-1 0 0 1 63 44)' />
      </clipPath>
    </defs>
  </svg>
);

import { Form, Icon, Modal, notification, Typography } from 'antd';
import firebase from 'firebase/app';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { trackAnalytics } from 'utils';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { slackConnectAction } from '../../store/actions/slackIntegrationAction';
import { CircleRightClick } from '../svg';

import './index.css';

interface IProps {
  showIntegrateModal: () => void;
  handleIntegrateCancel: () => void;
  integrateModalVisible: boolean;
  profile: any;
  auth: any;
  selectedOrganizationData: any;
}
const SlackConnect: React.FC<IProps> = ({ profile, auth, selectedOrganizationData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [webhookURL, setWebhookURL] = useState(profile.webhookURL || '');
  const [webhookURLError, setWebhookURLError] = useState<any>('success');
  const [slackModalVisible, setSlackModalVisible] = useState(false);

  const handleIntegrateCancel = () => {
    setSlackModalVisible(false);
  };

  const slackDisconnected = (e: any) => {
    e.stopPropagation();
    Modal.confirm({
      content: '',
      title: 'Are you sure, you want to be disconnected from slack account?',
      onOk() {
        setWebhookURL('');
        const firestore = firebase.firestore();
        const profileData = profile;
        const loginUserId = auth.uid;
        profileData.webhookURL = '';
        profileData.slackConnect = false;
        firestore
          .collection('users')
          .doc(loginUserId)
          .set(profileData, { merge: true });
      },
    });
  };

  const connectSlackAccount = async () => {
    const fiebaseInstance: any = firebase;
    !webhookURL && setWebhookURLError('error');
    const firestore = firebase.firestore();
    if (webhookURL) {
      const loginUserId = auth.uid;
      const profileData = profile;
      profileData.webhookURL = webhookURL;
      const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
      setIsLoading(true);
      trackAnalytics('Integration', 'Slack Webhook Clicked');
      firestore
        .collection('users')
        .doc(loginUserId)
        .set(profileData, { merge: true })
        .then(() => {
          slackConnectAction(firebaseToken).then((resMessage: any) => {
            if (resMessage.error) {
              profileData.webhookURL = '';
              firestore
                .collection('users')
                .doc(loginUserId)
                .set(profileData, { merge: true });
              notification.error({
                message: resMessage.message,
              });
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          });
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  const FormItem = Form.Item;
  const slackConnect = (profile && profile.slackConnect) || false;
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <Fragment>
          <div className={`trello-connect-btn ${slackConnect ? 'connected-trello' : ''}`}>
            {slackConnect ? (
              <Zbutton secondary btnColors={theme} onClick={slackDisconnected}>
                Disconnect
              </Zbutton>
            ) : (
              <Zbutton
                primary
                btnColors={theme}
                disabled={slackConnect || (userQuota && !userQuota.slackIntegration)}
                onClick={() => {
                  if (userQuota && userQuota.slackIntegration) {
                    setSlackModalVisible(true);
                  }
                }}
              >
                Connect
              </Zbutton>
            )}
          </div>
          <Modal
            title={slackConnect ? '' : 'Connect Slack'}
            visible={slackModalVisible}
            onOk={handleIntegrateCancel}
            onCancel={handleIntegrateCancel}
            footer={null}
          >
            {slackConnect ? (
              <div className='trello-success-container' style={{ color: theme.text.color2 }}>
                <span className='trello-success-message'>
                  <CircleRightClick />
                  <div style={{ marginLeft: 10 }}>Slack is connected</div>
                </span>
              </div>
            ) : (
              <div className='slack-integration-container'>
                <Form style={{ color: theme.text.color2 }}>
                  <div className='form-field step-slack-api'>
                    <Typography.Text strong={true}>
                      <p>Slack Webhook URL*</p>
                    </Typography.Text>
                    <FormItem
                      validateStatus={webhookURLError}
                      help={webhookURLError === 'error' && 'Slack Webhook URL key is required.'}
                    >
                      <Zinput
                        placeholder='Slack Webhook URL'
                        value={webhookURL}
                        onChange={(e: any) => {
                          setWebhookURL(e.target.value);
                          setWebhookURLError(e.target.value ? 'success' : 'error');
                        }}
                      />
                    </FormItem>
                    <div>
                      <a
                        target='_blank'
                        href={'https://my.slack.com/services/new/incoming-webhook'}
                      >
                        <span
                          className='integration-api'
                          style={{ color: theme.primaryBtn.color1 }}
                        >
                          Get your Webhook URL from here
                          <Icon type='key' style={{ marginLeft: 5 }} />
                        </span>
                      </a>
                    </div>
                  </div>
                </Form>
                <div style={{ width: '100%', textAlign: 'end' }}>
                  <Zbutton
                    primary
                    btnColors={theme}
                    style={{ width: 185 }}
                    loading={isLoading}
                    onClick={connectSlackAccount}
                  >
                    Connect my Slack account
                  </Zbutton>
                </div>
              </div>
            )}
          </Modal>
        </Fragment>
      )}
    </ColorContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(SlackConnect);
// export default SlackConnect;

import React from 'react';

export default () => (
  <svg
    width='487'
    height='485'
    viewBox='0 0 487 485'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M34.7857 17.3929C34.7857 12.6823 36.5069 8.60584 39.9492 5.1635C43.3916 1.72117 47.468 0 52.1786 0H434.821C439.532 0 443.608 1.72117 447.051 5.1635C450.493 8.60584 452.214 12.6823 452.214 17.3929V295.679H478.847C481.021 295.679 482.924 296.494 484.554 298.124C486.185 299.755 487 301.657 487 303.831V322.311C487 324.485 486.185 326.388 484.554 328.018C482.924 329.649 481.021 330.464 478.847 330.464H321.496L357.097 462.813C358.365 467.342 357.776 471.736 355.331 475.994C352.885 480.251 349.307 483.014 344.596 484.282C339.885 485.551 335.492 484.962 331.415 482.516C327.339 480.07 324.667 476.582 323.398 472.053L285.352 330.464H260.893V434.821C260.893 439.532 259.172 443.608 255.729 447.051C252.287 450.493 248.211 452.214 243.5 452.214C238.789 452.214 234.713 450.493 231.271 447.051C227.828 443.608 226.107 439.532 226.107 434.821V330.464H201.648L163.602 472.053C162.333 476.582 159.661 480.07 155.585 482.516C151.508 484.962 147.115 485.551 142.404 484.282C137.693 483.014 134.115 480.251 131.669 475.994C129.223 471.736 128.635 467.342 129.903 462.813L165.504 330.464H8.1529C5.97879 330.464 4.07645 329.649 2.44587 328.018C0.81529 326.388 0 324.485 0 322.311V303.831C0 301.657 0.81529 299.755 2.44587 298.124C4.07645 296.494 5.97879 295.679 8.1529 295.679H34.7857V17.3929ZM69.5714 295.679H417.429V34.7857H69.5714V295.679ZM104.357 278.286V248.663C104.357 246.127 105.218 243.953 106.939 242.141C108.66 240.329 110.789 239.424 113.325 239.424H199.746C202.283 239.424 204.411 240.329 206.133 242.141C207.854 243.953 208.714 246.127 208.714 248.663V278.286H104.357Z'
      fill='black'
    />
  </svg>
);

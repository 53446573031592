import { Button, Drawer, Icon, Tooltip } from 'antd';
import React from 'react';
import { mobileCheck } from '../../utils';
import { SettingsContent } from './SettingsContent';

const isMobile = mobileCheck();

interface IProps {
  className?: string;
}

export const Settings: (props: IProps) => JSX.Element = () => {
  const [visible, setVisible] = React.useState(false);
  const handleVisibleChange = (value: any) => setVisible(value);
  return (
    <>
      <Tooltip placement='bottom' title={isMobile ? '' : 'Settings'}>
        <Button
          className={'setting-icon'}
          onClick={() => setVisible(true)}
          type='primary'
          shape='circle'
          size='large'
        >
          <Icon style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }} type='menu' />
        </Button>
      </Tooltip>
      <Drawer
        placement='right'
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        className={'sidebar-comment-drawer'}
      >
        <SettingsContent handleSettingPopoverVisibleChange={handleVisibleChange} />
      </Drawer>
    </>
  );
};

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='9' height='20' viewBox='0 0 9 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9 1.5C9 2.32843 8.32843 3 7.5 3C6.67157 3 6 2.32843 6 1.5C6 0.671573 6.67157 0 7.5 0C8.32843 0 9 0.671573 9 1.5Z'
      fill={color || '#000'}
    />
    <path
      d='M9 7C9 7.82843 8.32843 8.5 7.5 8.5C6.67157 8.5 6 7.82843 6 7C6 6.17157 6.67157 5.5 7.5 5.5C8.32843 5.5 9 6.17157 9 7Z'
      fill={color || '#000'}
    />
    <path
      d='M9 12.5C9 13.3284 8.32843 14 7.5 14C6.67157 14 6 13.3284 6 12.5C6 11.6716 6.67157 11 7.5 11C8.32843 11 9 11.6716 9 12.5Z'
      fill={color || '#000'}
    />
    <path
      d='M9 18C9 18.8284 8.32843 19.5 7.5 19.5C6.67157 19.5 6 18.8284 6 18C6 17.1716 6.67157 16.5 7.5 16.5C8.32843 16.5 9 17.1716 9 18Z'
      fill={color || '#000'}
    />
    <path
      d='M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5Z'
      fill={color || '#000'}
    />
    <path
      d='M3 7C3 7.82843 2.32843 8.5 1.5 8.5C0.671573 8.5 0 7.82843 0 7C0 6.17157 0.671573 5.5 1.5 5.5C2.32843 5.5 3 6.17157 3 7Z'
      fill={color || '#000'}
    />
    <path
      d='M3 12.5C3 13.3284 2.32843 14 1.5 14C0.671573 14 0 13.3284 0 12.5C0 11.6716 0.671573 11 1.5 11C2.32843 11 3 11.6716 3 12.5Z'
      fill={color || '#000'}
    />
    <path
      d='M3 18C3 18.8284 2.32843 19.5 1.5 19.5C0.671573 19.5 0 18.8284 0 18C0 17.1716 0.671573 16.5 1.5 16.5C2.32843 16.5 3 17.1716 3 18Z'
      fill={color || '#000'}
    />
  </svg>
);

export default Icon;

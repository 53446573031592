/* tslint:disable */
import { Icon, Progress, Button } from 'antd';
import ColorContext from 'context/ColorContext';
import { Zbutton } from 'primitives';
import React, { useEffect } from 'react';
// import getUserMedia from 'getusermedia';
import { secondsToHms, trackAnalytics } from '../../utils/index';
// import PlayIcon from '../../recording/images/play-icon.png';
import { PauseIcon, RecordIcon } from '../projects/Chat/ChatSvgs';
import { mobileCheck } from '../../utils';
import './audioRecord.css';

const isMobile = mobileCheck();
let drawVisual: any = null;
let stopElement: any = null;
const InterviewAudioRecorder = (props: any) => {
  // const [micOptions, setMicOptions]: any = useState([]);

  const {
    maxRecordTime,
    background,
    onClose,
    isSmartComment = false,
    //  setIsAllowMicrophonePermission,
    blobObject,
    setBlobObject,
    timer,
    setTimer,
    isRecording,
    setRecording,
    loading,
    setLoading,
    percentage,
    isPaused,
    setIsPaused,
    onSubmit
  } = props;

  React.useEffect(() => {
    return () => {
      // @ts-ignore
      window && window.stream && window.stream.getTracks().forEach(function(track: any) {
        track.stop();
      });
    }
  },[])

  React.useEffect(() => {
    const stopAudioEvent = () => {
      const event = new CustomEvent('stopAudio', { detail: {} });
      if (stopElement) {
        stopElement.dispatchEvent(event);
      }
    };
    if (timer >= maxRecordTime * 60) {
      stopAudioEvent();
    }
  }, [timer]);
  React.useEffect(() => {
    stopElement = document.getElementById('stop');
    (async () => {
      const leftchannel: any = [];
      const rightchannel: any = [];
      let recorder: any = null;
      let recording: any = false;
      let recordingLength: any = 0;
      // let volume: any = null;
      let audioInput: any = null;
      let sampleRate: any = null;
      // @ts-ignore
      const AudioContext: any = window.AudioContext || window.webkitAudioContext;
      let context: any = null;
      let analyser: any = null;
      const canvas: any = document.getElementById('chat-window-canvas-audio-recorder');
      const canvasCtx: any = canvas.getContext('2d');
      const visualSelect: any = document.querySelector('#visSelect');
      const micSelectAudio: any = document.querySelector('#micSelectAudio');
      let stream: any = null;
      let tested: any = false;

      try {
        // @ts-ignore
        //  window.stream = stream = await getStream();
      } catch (_err) {
        //('Issue getting mic' + _err);
      }

      const deviceInfos = await navigator.mediaDevices.enumerateDevices();
      const mics = [];
      // let micOptionsArray = [];
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === 'audioinput') {
          mics.push(deviceInfo);
          const label = deviceInfo.label || 'Microphone ' + mics.length;
          const option = document.createElement('option');
          option.value = deviceInfo.deviceId;
          option.text = label;
          micSelectAudio.appendChild(option);
          // micOptionsArray.push({ value: deviceInfo.deviceId, text: label });
        }
      }
      // setMicOptions(micOptionsArray);

      function getStream(constraints: any) {
        if (!constraints) {
          constraints = { audio: true, video: false };
        }
        return navigator.mediaDevices.getUserMedia(constraints);
      }

      function setUpRecording() {
        context = new AudioContext();
        sampleRate = context.sampleRate;
        // creates a gain node
        // volume = context.createGain();
        // creates an audio node from teh microphone incoming stream
        audioInput = context.createMediaStreamSource(stream);

        // Create analyser
        analyser = context.createAnalyser();

        // connect audio input to the analyser
        audioInput.connect(analyser);

        // connect analyser to the volume control
        // analyser.connect(volume);

        const bufferSize = 2048;
        recorder = context.createScriptProcessor(bufferSize, 2, 2);

        // we connect the volume control to the processor
        // volume.connect(recorder);

        analyser.connect(recorder);

        // finally connect the processor to the output
        recorder.connect(context.destination);

        recorder.onaudioprocess = function(e: any) {
          // Check
          if (!recording) {
            return;
          }
          // Do something with the data, i.e Convert this to WAV
          const left = e.inputBuffer.getChannelData(0);
          const right = e.inputBuffer.getChannelData(1);
          if (!tested) {
            tested = true;
            // if this reduces to 0 we are not getting any sound
            // if (!left.reduce((a: any, b: any) => a + b)) {
            //   //('There seems to be an issue with your Mic');
            //   // clean up;
            //   stop();
            //   stream.getTracks().forEach(function (track: any) {
            //     track.stop();
            //   });
            //   context.close();
            // }
          }
          // we clone the samples
          leftchannel.push(new Float32Array(left));
          rightchannel.push(new Float32Array(right));
          recordingLength += bufferSize;
        };
        visualize();
      }

      function mergeBuffers(channelBuffer: any, recordingLength: any) {
        const result = new Float32Array(recordingLength);
        let offset = 0;
        const lng = channelBuffer.length;
        for (let i = 0; i < lng; i++) {
          const buffer = channelBuffer[i];
          result.set(buffer, offset);
          offset += buffer.length;
        }
        return result;
      }

      function interleave(leftChannel: any, rightChannel: any) {
        const length = leftChannel.length + rightChannel.length;
        const result = new Float32Array(length);

        let inputIndex = 0;

        for (let index = 0; index < length; ) {
          result[index++] = leftChannel[inputIndex];
          result[index++] = rightChannel[inputIndex];
          inputIndex++;
        }
        return result;
      }

      function writeUTFBytes(view: any, offset: any, string: any) {
        const lng = string.length;
        for (let i = 0; i < lng; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      }

      const start = () => {
        recording = true;
        // @ts-ignore
        // document.querySelector('#msg').style.visibility = 'visible'
        // reset the buffers for the new recording
        leftchannel.length = rightchannel.length = 0;
        recordingLength = 0;
        if (!context) {
          setUpRecording();
        }
      }
       // @ts-ignore
      window.start = start;

      const stop = async (isSubmit = false) => {
        trackAnalytics('Canvas', 'Send Recording Clicked', 'audio');
        recording = false;
        // @ts-ignore
        // document.querySelector('#msg').style.visibility = 'hidden'

        // we flat the left and right channels down
        const leftBuffer = mergeBuffers(leftchannel, recordingLength);
        const rightBuffer = mergeBuffers(rightchannel, recordingLength);
        // we interleave both channels together
        const interleaved = interleave(leftBuffer, rightBuffer);

        ///////////// WAV Encode /////////////////
        // from http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
        //

        // we create our wav file
        const buffer = new ArrayBuffer(44 + interleaved.length * 2);
        const view = new DataView(buffer);

        // RIFF chunk descriptor
        writeUTFBytes(view, 0, 'RIFF');
        view.setUint32(4, 44 + interleaved.length * 2, true);
        writeUTFBytes(view, 8, 'WAVE');
        // FMT sub-chunk
        writeUTFBytes(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        // stereo (2 channels)
        view.setUint16(22, 2, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, sampleRate * 4, true);
        view.setUint16(32, 4, true);
        view.setUint16(34, 16, true);
        // data sub-chunk
        writeUTFBytes(view, 36, 'data');
        view.setUint32(40, interleaved.length * 2, true);

        // write the PCM samples
        const lng = interleaved.length;
        let index = 44;
        const volume = 1;
        for (let i = 0; i < lng; i++) {
          view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
          index += 2;
        }

        // our final binary blob
          const blob = new Blob([view], { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(blob);
          setBlobObject({ blob, audioUrl });
        //  setRecording(false);
        if(isSubmit){
          setIsPaused(true);
          setLoading(true);
          // const stopButton: any = document.getElementById('stop');
          // stopButton.style.cursor = 'not-allowed';
          // const canvas: any = document.getElementById('chat-window-canvas-audio-recorder');
          // canvas.style.display = 'none';
          // const audioTag: any = document.getElementById('audio-player-container');
          // audioTag.style.display = 'inline-block';
        //  isPaused && setIsPaused(false);
          onSubmit();
        }
        // @ts-ignore
        // document.querySelector('#audio-player').setAttribute('src', audioUrl);
        // const link: any = document.querySelector('#download');
        // link.setAttribute('href', audioUrl);
        // link.download = 'output.wav';
      };

      // Visualizer function from
      // https://webaudiodemos.appspot.com/AudioRecorder/index.html
      //
      function visualize() {
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        const visualSetting = visualSelect.value;
        if (!analyser) {
          return;
        }

        if (visualSetting === 'sinewave') {
          analyser.fftSize = 2048;
          const bufferLength = analyser.fftSize;
          const dataArray = new Uint8Array(bufferLength);

          canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

          const draw = function() {
            drawVisual = requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = background ? background : '#C4C4C4';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#001DAD';

            canvasCtx.beginPath();

            const sliceWidth = (WIDTH * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0;
              const y = (v * HEIGHT) / 2;

              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }

              x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
          };

          draw();
        }
      }

      visualSelect.onchange = function() {
        window.cancelAnimationFrame(drawVisual);
        visualize();
      };

      micSelectAudio.onchange = async () => {
        stream.getTracks().forEach(function(track: any) {
          track.stop();
        });
        context.close();

        stream = await getStream({
          audio: {
            deviceId: { exact: micSelectAudio.value },
          },
          video: false,
        });
        // @ts-ignore
        window.stream = stream;
        setUpRecording();
      };

      function pause() {
        recording = false;
        stop(false);
        // context.suspend()
      }

      function resume() {
        recording = true;
        // context.resume();
      }

      // @ts-ignore
      document.querySelector('#pauseAudioRecord').onclick = () => {
        trackAnalytics('Canvas', 'Pause Recording Clicked', 'audio');
        pause();
        setIsPaused(true);
      };

      // @ts-ignore
      document.querySelector('#resumeAudioRecord').onclick = () => {
        trackAnalytics('Canvas', 'Resume Recording Clicked', 'audio');
        resume();
        setIsPaused(false);
      };
      // ('remove this after used', pause, resume)
      // @ts-ignore
      document.querySelector('#record').onclick = async (e) => {
        // ('Start recording')
        try {
            const _stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
            //, (err: any, _stream: any) => {
            const microphonePermissionError: any = document.getElementById(
              'microphone-permission-error',
            );
            microphonePermissionError.style.display = 'none';
            //  setIsAllowMicrophonePermission(true)
            // @ts-ignore
            window.stream = _stream;
            stream = _stream;
            setUpRecording();
            const playButton: any = document.getElementById('record');
            playButton.style.display = 'none';
            // const stopButton: any = document.getElementById('stop');
            // stopButton.style.display = 'inline-block';
            const settingsButton: any = document.getElementById('mySidenav');
            settingsButton.style.display = 'none';
            start();
            trackAnalytics('Canvas', 'Start Recording Clicked', 'audio');
            setRecording(true);
          } catch (err) {
            const microphonePermissionError: any = document.getElementById(
              'microphone-permission-error',
            );
            microphonePermissionError.style.display = 'block';
            //  setIsAllowMicrophonePermission(false)
            // alert(`Unable to access microphone ${`(${err.message}), please allow microphone permission in order to record audio and try again with refresh the page`}`)
          }
        //});
      };
      if (isSmartComment) {
        const playButton: any = document.getElementById('record');
        playButton.style.display = 'none';
        // const stopButton: any = document.getElementById('stop');
        // stopButton.style.display = 'inline-block';
        const settingsButton: any = document.getElementById('mySidenav');
        settingsButton.style.display = 'none';

        start();
        setRecording(true);
      }

      // @ts-ignore
      document.querySelector('#stop').onclick = (e) => {
        stop(true);
        isPaused && setIsPaused(false);
      };

      stopElement.addEventListener('stopAudio', () => {
        stop(true);
        isPaused && setIsPaused(false);
      });
      // @ts-ignore
      // document.querySelector('#cancel').onclick = (e) => {
      //   stop(true);
      //   isPaused && setIsPaused(false);
      // };
    })();
  }, []);

  const TimerComp = () => {
    useEffect(() => {
      const timerId = setInterval(() => {
        setTimer((timer: number) => timer + 1);
      }, 1000);
      return () => {
        clearInterval(timerId);
      };
    }, []);
    return secondsToHms(maxRecordTime * 60 - timer);
  };

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: isMobile ? '90%' : undefined,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: isMobile ? '100%' : '450px',
                height: '120px',
                background: theme.backgrounds.color1,
                borderRadius: '5px',
                padding: '0px 25px',
              }}
              className={`${isSmartComment ? 'smartCommentAudioWave' : ''}`}
            >
              <div className='d-flex'>
                <div className={'c-p d-flex'} id='record'>
                  <RecordIcon />
                </div>
                <div
                  className='d-flex'
                  id='resumeAudioRecord'
                  style={{
                    display: isPaused ? (isRecording && !loading ? 'flex' : 'none') : 'none',
                  }}
                >
                  <div className='c-p d-flex'>
                    <RecordIcon />
                  </div>
                </div>
                <div
                  style={{
                    display: isPaused ? 'none' : isRecording ? 'flex' : 'none',
                    width: '24px',
                  }}
                  className={'c-p d-flex d-none'}
                  id='pauseAudioRecord'
                >
                  <PauseIcon />
                </div>

                { !loading && <>
                <div className={`chat-window-timer-audio-recorder`}>
                  {isRecording && !isPaused && <TimerComp />}
                  {isRecording && isPaused && secondsToHms(maxRecordTime * 60 - timer)}
                </div>
                {!blobObject.blob && !isRecording && (
                  <div className={`chat-window-timer-audio-recorder`}>
                    {secondsToHms(maxRecordTime * 60)}
                  </div>
                )}
                </>
                }

              { !loading && !isMobile && blobObject && blobObject.audioUrl && isPaused && <div><audio
                controls
                src={blobObject.audioUrl}
                style={{marginLeft: '20px'}}
              />
              </div>
              }

              </div>

              <div style={{ display: isRecording && !isPaused ? 'contents' : 'none' }}>
                <canvas
                  style={{ maxHeight: '100px' }}
                  id={'chat-window-canvas-audio-recorder'}
                ></canvas>
              </div>

              <div style={{ display: isRecording && isPaused ? 'flex' : 'none' }}>
                {/* <Button className='audio-record-cln-btn' id='cancel'>
                                      Cancel
                                  </Button>
                                  <Button className={'audio-record-send-btn'} id='stop' type='primary'>
                                      Send
                                  </Button> */}
              </div>

              {/* audio Player */}
              {(loading || props.loading) && (
                <div
                  id={'chat-window-audio-player-container'}
                  className={`chat-window-audio-tag-recorder`}
                >
                  <Progress
                    showInfo={false}
                    percent={percentage}
                    strokeColor={theme.primaryBtn.color1}
                    status={percentage === 100 ? 'success' : 'active'}
                  />
                </div>
              )}
            </div>
          </div>

          { !loading && isMobile && blobObject && blobObject.audioUrl && isPaused && <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: isMobile ? '90%' : undefined,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: isMobile ? '100%' : '450px',
                height: '120px',
                background: theme.backgrounds.color1,
                borderRadius: '5px',
                padding: '0px 0px',
                marginTop: '10px'
              }}
              className={`${isSmartComment ? 'smartCommentAudioWave' : ''}`}
            >
             <div><audio
                controls
                src={blobObject.audioUrl}
                style={{marginLeft: '0px'}}
              />
              </div>
            </div>
          </div>
          }

          <div
            style={{ width: '95%' }}
            className={`dialogFooter ${isSmartComment ? 'smartCommentFooter' : ''}`}
          >
            <div className='dialogCTAsContainer' style={{ justifyContent: 'flex-end' }}>
              {/* sound source */}
              <div style={{ display: 'flex', flex: 1, marginTop: '12px' }}>
                <div style={{ position: 'relative' }} id='mySidenav'>
                  <Icon className='chat-window-audio-icon' type='setting' />
                  <select className={'select-audio-recorder'} id='visSelect'>
                    <option value='sinewave'>Wave</option>
                  </select>
                  <select
                    style={{ top: '0px' }}
                    className='chat-window-select-audio-recorder'
                    name=''
                    id='micSelectAudio'
                  ></select>
                </div>
              </div>
              <div>
                {isSmartComment ? (
                  <Button
                    id='cancel'
                    className='cancelButton'
                    style={{ marginRight: 10, visibility: 'hidden' }}
                    onClick={() => {
                      window.parent.postMessage({ url: '' }, '*');
                      trackAnalytics('Canvas', 'Cancelled Clicked on Dialog', 'audio');
                      onClose(false);
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  null
                )}
              </div>
              <div>
              <Zbutton
                    disabled={!timer || !isPaused || loading}
                    btnColors={theme}
                    id='deleteRecording'
                    secondary
                    style={{marginRight: '15px'}}
                    onClick={() => {
                      setBlobObject({});
                      setTimer(0);
                       // @ts-ignore
                      window.start();
                    }}
                  >
                    Delete Recording
                  </Zbutton>

                {isSmartComment ? (
                  <Button loading={loading} className='startRecordingButton' id='stop'>
                    Stop Recording
                  </Button>
                ) : (
                  <Zbutton
                    disabled={timer === 0}
                    loading={loading}
                    btnColors={theme}
                    id='stop'
                    primary
                  >
                    Send
                  </Zbutton>
                )}
              </div>
            </div>
          </div>
          <div id='microphone-permission-error' style={{ display: 'none' }}>
            <div>
              Unable to access microphone, please allow microphone permission in order to record
              audio.
            </div>
            <img
              style={
                isMobile
                  ? { width: '90%', marginTop: '10px' }
                  : { width: '500px', marginTop: '10px' }
              }
              src='/images/allow-microphone.png'
              alt='Allow microphone'
            />
            <img
              style={
                isMobile
                  ? { width: '90%', margin: '10px 20px' }
                  : { width: '500px', display: 'grid', margin: 'auto' }
              }
              src='/images/allow-microphone-in-zooc.png'
              alt='Allow microphone in zooc'
            />
          </div>
        </div>
      )}
    </ColorContext.Consumer>
  );
};

export default InterviewAudioRecorder;

import { Alert, Form, notification, Typography } from 'antd';
import firebase from 'firebase';
import React, { useState } from 'react';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import './auth.css';

interface IProps {
  form: any;
}

const SetNewPassword: React.FC<IProps> = ({
  form: { getFieldDecorator, validateFieldsAndScroll, resetFields },
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const reset = () => {
    setLoading(false);
    resetFields();
    notification.success({
      message: 'Your password has been changed successfully!',
    });
  };

  const chandleChangePassword = () => {
    validateFieldsAndScroll(async (err: any, values: any) => {
      setLoading(true);
      const { newPassword, oldPassword } = values;
      if (!err) {
        const user = firebase.auth().currentUser;
        if (user) {
          const credential = firebase.auth.EmailAuthProvider.credential(
            // @ts-ignore
            user.email,
            oldPassword,
          );
          return user
            .reauthenticateWithCredential(credential)
            .then(() => {
              user
                .updatePassword(newPassword)
                .then(() => {
                  statsAction('UPDATE_PASSWORD_SUCCESS', firebase);
                  reset();
                })
                .catch((error: any) => {
                  setErrorMessage(error.message);
                  setLoading(false);
                });
            })
            .catch((error: any) => {
              setErrorMessage(error.message);
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <ColorContext.Consumer>
        {({ theme }) => (
          <Form className='profile-input'>
            <Typography.Text strong={true}>Old Password</Typography.Text>
            <Form.Item>
              {getFieldDecorator('oldPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Current password!',
                    whitespace: true,
                  },
                ],
              })(<Zinput type='password' placeholder='Current password' />)}
            </Form.Item>
            <Typography.Text strong={true}>New Password</Typography.Text>
            <Form.Item>
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Please input new password!',
                    whitespace: true,
                  },
                ],
              })(<Zinput type='password' placeholder='New Password' />)}
            </Form.Item>
            <div style={{ width: '100%' }}>
              <Zbutton primary btnColors={theme} loading={loading} onClick={chandleChangePassword}>
                Save Changes
              </Zbutton>
            </div>
            {errorMessage && (
              <Alert
                className='err-alert'
                message='Password Change failed'
                description={errorMessage}
                type='error'
                closable
                showIcon
                onClose={() => setErrorMessage('')}
              />
            )}
          </Form>
        )}
      </ColorContext.Consumer>
    </div>
  );
};

export default Form.create<IProps>()(SetNewPassword);

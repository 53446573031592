import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/messaging';
import { notification } from 'antd';
// if (window.location.pathname.includes('/record/'))
//   alert()
var config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  appId: process.env.REACT_APP_FB_APP_ID,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASEURL,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  measurementId: 'G-1S8HQTQJJM',
};
firebase.initializeApp(config);

export const askPermission = async () => {
  try {
    const messaging = firebase.messaging();
    messaging.onMessage(function(payload) {
      const {
        url,
        message,
        body,
        icon,
        click_action,
        title,
        page_id,
        circle_id,
        page_path,
      } = payload.data;
      notification.open({
        duration: 10,
        className: 'notification-click',
        message: 'New Comment',
        description: message,
        onClick: () => {
          window.open(
            `${url}${url.includes('?') ? '&' : '?'}circleId=${circle_id}${
              page_path ? `&page=${page_path}` : ''
            }`,
            '_blank',
          );
        },
      });
    });
    messaging.usePublicVapidKey(process.env.REACT_APP_FB_PUBLIC_API_KEY);
    await messaging.requestPermission();
    let firebaseToken = window.localStorage.getItem('firebaseToken');
    if (!firebaseToken) {
      const token = await messaging.getToken();
      window.localStorage.setItem('firebaseToken', token);
    }
  } catch (error) {
    // throw new Error(error);
  }
};

export default firebase;

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.72727 4.28406L9.00001 2.64769V11.4545L5.72727 9.81818V4.28406ZM4.71596 2.96021L10.6364 0V14.1022L4.71594 11.142H1.63636C0.732625 11.142 0 10.4094 0 9.50566V4.59657C0 3.69283 0.732625 2.96021 1.63636 2.96021H4.71596ZM4.0909 4.59656H1.63635V9.50565H4.0909V4.59656ZM15.2829 0.0614014C16.9475 1.71742 18 4.24344 18 7.05136C18 9.85927 16.9475 12.3853 15.2829 14.0413L13.9935 13.0098C15.4217 11.6771 16.3636 9.51586 16.3636 7.05136C16.3636 4.58685 15.4217 2.42565 13.9935 1.09293L15.2829 0.0614014ZM14.7272 7.0511C14.7272 5.07874 13.9621 3.29759 12.7429 2.09308L11.4523 3.12561C12.4422 4.01704 13.0909 5.43574 13.0909 7.0511C13.0909 8.66646 12.4422 10.0852 11.4523 10.9766L12.7429 12.0091C13.9621 10.8046 14.7272 9.02346 14.7272 7.0511Z'
      fill={color || '#4F4F4F'}
    />
  </svg>
);

export default Icon;

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IMasterProject, IProject } from '../../types/projectTypes';
import CanvasItem from './CanvasItem';

/* stylelint-enable */
interface IInnerCanvasListProps {
  canvases: IProject[];
  selectedMasterProject: IMasterProject;
  history: any;
}
export default class InnerCanvasList extends React.Component<IInnerCanvasListProps, any> {
  shouldComponentUpdate(nextProps: IInnerCanvasListProps) {
    if (nextProps.canvases !== this.props.canvases) {
      return true;
    }

    return false;
  }

  render() {
    const { selectedMasterProject, history } = this.props;
    return this.props.canvases.map((canvas, index) => (
      <Draggable key={canvas.id} draggableId={canvas.id} index={index}>
        {(dragProvided, dragSnapshot) => (
          <CanvasItem
            key={canvas.id}
            canvas={canvas}
            isDragging={dragSnapshot.isDragging}
            provided={dragProvided}
            dragSnapshot={dragSnapshot}
            selectedMasterProject={selectedMasterProject}
            history={history}
          />
        )}
      </Draggable>
    ));
  }
}

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 3C1 1.89543 1.89543 1 3 1H10V19C10 20.1046 9.10457 21 8 21H3C1.89543 21 1 20.1046 1 19V3Z'
      stroke={color || `#4F4F4F`}
      stroke-width='2'
    />
    <path
      d='M10 1H17C18.1046 1 19 1.89543 19 3V14C19 15.1046 18.1046 16 17 16H12C10.8954 16 10 15.1046 10 14V1Z'
      stroke={color || `#4F4F4F`}
      stroke-width='2'
    />
  </svg>
);

export default Icon;

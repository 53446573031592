// import { DownOutlined } from '@ant-design/icons';
import { Form, Modal, notification } from 'antd'; // Button, Dropdown, Menu
import firebase from 'firebase';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { trackAnalytics } from 'utils';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { validateEmail } from '../../utils';
import { CircleRightClick } from '../svg';
import './members.css';

const MemberForm = (props: any) => {
  const {
    visible,
    setVisible,
    title,
    auth,
    organizationDetail,
    setIsSentInvitation,
    isSentInvitation,
    selectedOrganizationMatchedIndex,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedMemberRole, setSelectMemberRole] = useState('Admin');

  const reset = () => {
    setIsLoading(false);
    props.form.resetFields();
  };

  // const roleMenu = () => {
  //   const menu = (
  //     <Menu>
  //       <Menu.Item
  //         className='project-dropdown-item'
  //         key='admin'
  //         onClick={() => {
  //           setSelectMemberRole('Admin');
  //         }}
  //       >
  //         Admin
  //       </Menu.Item>
  //       <Menu.Item
  //         className='project-dropdown-item'
  //         key='2'
  //         onClick={() => {
  //           setSelectMemberRole('Collaborator');
  //         }}
  //       >
  //         Collaborator
  //       </Menu.Item>
  //       {/* <Menu.Item
  //         className='project-dropdown-item'
  //         key='3'
  //         onClick={() => {
  //           setSelectMemberRole('Can edit');
  //         }}
  //       >
  //         Can edit
  //       </Menu.Item> */}
  //     </Menu>
  //   );
  //   return menu;
  // };

  const send = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        trackAnalytics('Profile', 'Invited a User');
        const { emailAddress } = values;
        let addedEmails = [];
        if (emailAddress.includes(',')) {
          addedEmails = emailAddress.split(',');
        } else {
          addedEmails = [emailAddress];
        }
        const invalidEmails: string[] = [];
        addedEmails.forEach((email: string) => {
          if (!validateEmail(email)) {
            invalidEmails.push(email);
          }
        });
        if (invalidEmails.length) {
          const invalidEmailsError = `${invalidEmails.join(',')} ${
            invalidEmails.length === 1 ? 'is' : 'are'
          } not a valid email address.`;
          notification.error({
            message: invalidEmailsError,
          });
        } else {
          const oldMembers =
            organizationDetail &&
            organizationDetail[selectedOrganizationMatchedIndex] &&
            organizationDetail[selectedOrganizationMatchedIndex].members
              ? organizationDetail[selectedOrganizationMatchedIndex].members
              : [];
          const duplicateMembers = addedEmails.filter((email: string) =>
            oldMembers.some((member: { email: string }) => email === member.email),
          );
          if (!duplicateMembers.length) {
            const firestore = firebase.firestore();
            const addedEmailsWithStatus: any = [];
            // for (let i = 0; i < addedEmails.length; i++) {
            for (const email of addedEmails) {
              //  const email = addedEmails[i];
              let name = '';
              let image = '';
              const memberUserDetail: any = await firestore
                .collection('users')
                .where('email', '==', email)
                .get();
              if (memberUserDetail && memberUserDetail.docs && memberUserDetail.docs.length) {
                const memberUsersDetailDocs = memberUserDetail.docs[0];
                const memberUserData = memberUsersDetailDocs.data();
                name = memberUserData.fullName || '';
                image = memberUserData.photoURL || '';
              }
              addedEmailsWithStatus.push({
                name,
                image,
                status: 'PENDING',
                email,
                role: 'Admin',
                invite_by: auth.uid,
              });
            }

            const members = oldMembers.concat(addedEmailsWithStatus);
            setIsLoading(true);
            if (organizationDetail && organizationDetail[selectedOrganizationMatchedIndex]) {
              return firestore
                .collection('organization')
                .doc(organizationDetail[selectedOrganizationMatchedIndex].id)
                .set(
                  {
                    members,
                  },
                  { merge: true },
                )
                .then(() => {
                  statsAction('INVITE_MEMBER', firestore);
                  reset();
                  setIsSentInvitation(true);
                })
                .catch(() => {
                  setIsLoading(false);
                  return false;
                });
            }
          } else {
            const alreadyMemberError = `Already send an invitation on ${duplicateMembers.join(
              ',',
            )}`;
            notification.error({
              message: alreadyMemberError,
            });
          }
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div className={`add-member-form`} style={{ color: theme.text.color2 }}>
          <Modal
            visible={visible}
            title={isSentInvitation ? '' : <span className='add-memeber-form'>{title}</span>}
            onOk={setVisible}
            onCancel={() => {
              setVisible();
            }}
            footer={null}
          >
            <div
              className={`inivite-container ${
                isSentInvitation ? 'add-member-success-container' : ''
              }`}
            >
              {isSentInvitation ? (
                <div className='success-container'>
                  <CircleRightClick />
                  <div className='success-message-invitation'>Invitation sent</div>
                </div>
              ) : (
                <Form className={`white`}>
                  {/* <Dropdown overlay={roleMenu()}>
                      <Button className='member-role-dropdown'>
                        {selectedMemberRole}
                        <DownOutlined />
                      </Button>
                    </Dropdown> */}
                  <Form.Item className='copy-inivitation-form-item'>
                    {getFieldDecorator('emailAddress', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter an email address!',
                          whitespace: true,
                        },
                      ],
                    })(<Zinput placeholder='Enter an email address' />)}
                    <div style={{ marginLeft: 10 }}>
                      <Zbutton
                        key='submit'
                        primary
                        btnColors={theme}
                        style={{ width: 70, height: 50 }}
                        loading={isLoading}
                        // disabled={
                        //   selectedMasterProject.created_by !== auth.uid
                        // }
                        onClick={send}
                      >
                        Send
                      </Zbutton>
                    </div>
                  </Form.Item>
                  <div className='multiple-email-hint'>
                    You can paste multiple emails by separating them with a comma
                  </div>
                </Form>
              )}

              {!isSentInvitation && (
                <div style={{ marginTop: 10, visibility: 'hidden' }}>
                  <Zbutton
                    dashed
                    key='submit'
                    btnColors={theme}
                    style={{ height: 40, width: '100%' }}
                    // loading={isLoading}
                    // disabled={selectedMasterProject.created_by !== auth.uid}
                  >
                    Copy invitation link
                  </Zbutton>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </ColorContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create()(MemberForm));

import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Iframe from '../iframe';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CollapsedInIcon,
  CollapsedOutIcon,
  FitToZoom,
  FitToZoomOut,
  MinusIocnSVG,
  PlusIconSVG,
} from '../svg';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface IOwnInterface {
  url: string;
  iframe: any;
  projectId: string;
  toggleDrawing: boolean;
  setKeyboardEvent: any;
  project: any;
  addComment: any;
  strokeColor: string;
  history: any;
  setIsChatMode: any;
  isChatMode: boolean;
}
export default function PDFCanvas(props: IOwnInterface) {
  let refs: any = null;
  const [numPages, setNumPages] = useState(0);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [pageScale, setPageScale] = useState(1);
  const [fitToPdf, setFitToPdf] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageInputValue, setPageInputValue] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [pdfContentCanvasWidth, setPdfContentCanvasWidth] = useState(0);
  const [pdfContentCanvasHeight, setPdfContentCanvasHeight] = useState(0);
  const [isPdfSmallThenContainer, setIsPdfSmallThenContainer] = useState(false);

  const {
    iframe,
    projectId,
    toggleDrawing,
    setKeyboardEvent,
    project,
    addComment,
    strokeColor,
  } = props;

  useEffect(() => {
    const pathname = window.location.pathname;
    const pageUrlParams = new URLSearchParams(window.location.search);
    const page: any = pageUrlParams.get('page');
    if (!page) {
      const projectURL = `${pathname}${pathname.includes('?') ? '&' : '?'}page=1`;
      props.history.push(projectURL);
    }
  }, []);

  useEffect(() => {
    const pageUrlParams = new URLSearchParams(window.location.search);
    const page: any = pageUrlParams.get('page');
    if (page && pageNumber.toString() !== page) {
      setPageNumber(parseInt(page, 10));
    }
  }, [window.location.search]);

  function onDocumentLoadSuccess(pdfLoadData: any) {
    setNumPages(pdfLoadData.numPages);
    refs = new Array(pdfLoadData.numPages).reduce((acc, value) => {
      acc[value.id] = React.createRef();
      return acc;
    }, {});
  }

  const pageInputChange = (e: any) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value <= numPages) {
      setPageInputValue(e.target.value);
    }
  };

  const changePageNumber = (page: number) => {
    props.setIsChatMode(false);
    let pathname = window.location.pathname;
    const search = window.location.search;
    if (search.includes('recalc')) {
      pathname = pathname + '?recalc=true';
    }
    const projectURL = `${pathname}${pathname.includes('?') ? '&' : '?'}page=${page}`;
    props.history.push(projectURL);
  };

  const { url } = props;
  // const url =
  // "https://firebasestorage.googleapis.com/v0/b/zooc-app-dev.appspot.com/o/file1606149097724?alt=media&token=1186eb1e-36c1-4b5a-a789-dc555607df79"
  // long sample pdf
  // https://firebasestorage.googleapis.com/v0/b/zooc-app-dev.appspot.com/o/e21955b1-6d17-4003-923e-77210aa1a6ab.pdf?alt=media
  // https://firebasestorage.googleapis.com/v0/b/zooc-app-dev.appspot.com/o/file1608714907949?alt=media&token=0ad8f1de-bf39-4ddf-95ad-54b7e8aa74ef
  return (
    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
      <div style={{ display: 'flex', background: '#fff' }}>
        <div
          style={{
            overflowY: collapsed ? 'hidden' : 'scroll',
            width: collapsed ? '16px' : '172px',
            padding: collapsed ? '1px' : '15px',
          }}
          className='left-section-bar'
        >
          <div style={{ visibility: collapsed ? 'hidden' : 'visible' }}>
            {Array.from(new Array(numPages), (_el, pageThumbnailIndex) => (
              <>
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setPageNumber(pageThumbnailIndex + 1);
                    const page = pageThumbnailIndex + 1;
                    changePageNumber(page);
                    // fullPage && refs && refs[pageThumbnailIndex].current.scrollIntoView({
                    //   behavior: 'smooth',
                    //   block: 'start',
                    // });
                  }}
                >
                  <div ref={refs && refs[pageThumbnailIndex]} id={`${pageThumbnailIndex}`}></div>
                  <Page
                    className={`${pageThumbnailIndex + 1 === pageNumber ? 'selected-page' : ''}`}
                    key={`page_${pageThumbnailIndex + 1}`}
                    pageNumber={pageThumbnailIndex + 1}
                  />
                </div>
                <div className='page-number'>{pageThumbnailIndex + 1}</div>
              </>
            ))}
          </div>
          <div
            style={{ left: collapsed ? '16px' : '171px' }}
            className='ant-layout-sider-trigger-icon'
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {collapsed ? <CollapsedOutIcon /> : <CollapsedInIcon />}
          </div>
        </div>
        {
          // 320px = right chat bar
          // Collapsed Out
          // 172px = left page thumbnail
          // 30px padding
          // Center = calc(100vw - (320px + 172px + 30px))
          // Collapsed Out
          // 16px = left page thumbnail
          // 2px padding
          // Center = calc(100vw - (320px + 16px + 2px))
        }
        <div
          style={{
            width: collapsed ? `calc(100vw - 338px)` : 'calc(100vw - 512px)',
            position: 'relative',
          }}
          className='center-section'
        >
          <Page
            className={`pdf-content ${fitToPdf && 'fit-to-pdf'}`}
            onRenderSuccess={() => {
              setIsLoadingPdf(false);
              const pdfContentCanvas: any = document.querySelector('.pdf-content canvas');
              const pdfContent: any = document.querySelector('.center-section');
              if (pdfContentCanvas && pdfContentCanvas.getBoundingClientRect()) {
                const { height, width } = pdfContentCanvas.getBoundingClientRect();
                setPdfContentCanvasWidth(width);
                setPdfContentCanvasHeight(height);
                if (height < pdfContent.getBoundingClientRect().height) {
                  setIsPdfSmallThenContainer(true);
                } else {
                  setIsPdfSmallThenContainer(false);
                }
              }
              const element: any = document.getElementById(`${pageNumber}`);
              setPageInputValue(pageNumber);
              element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
            scale={fitToPdf ? 2 : pageScale}
            key={`page_${pageNumber}`}
            pageNumber={pageNumber}
          />
          <div
            style={{
              position: 'absolute',
              top: '0px',
              width: `${pdfContentCanvasWidth}px`,
              height: `${pdfContentCanvasHeight}px`,
              left: '0px',
              right: '0px',
              bottom: '0px',
              inset: 'auto',
              visibility: isLoadingPdf ? 'hidden' : 'visible',
            }}
            className={
              isPdfSmallThenContainer ? `pdf-iframe-container-auto-margin` : 'pdf-iframe-container'
            }
          >
            <Iframe
              isWriteAccess={true}
              drawerVisible={false}
              //url={`${window.location.origin}/whiteboard-for-comment.html`}
              // url={`https://zooc-video-website.firebaseapp.com`}
             // url={'http://app.zooc.io/whiteboard-for-comment.html'}
              url={'http://zooc.io/whiteboard-for-comment'}
              iframe={iframe}
              projectId={projectId}
              toggleDrawing={toggleDrawing}
              setKeyboardEvent={setKeyboardEvent}
              project={project}
              addComment={addComment}
              strokeColor={strokeColor}
              height={pdfContentCanvasHeight}
              width={pdfContentCanvasWidth}
              page={pageNumber}
              pageScale={pageScale}
              type={'pdf'}
            />
          </div>
        </div>
      </div>
      <div
        className='pdf-footer-control noselect'
        style={{
          left: collapsed ? '26px' : '182px',
          width: collapsed ? `calc(100vw - 340px)` : 'calc(100vw - 515px)',
          position: 'fixed',
          bottom: '27px',
        }}
      >
        <div className='pdf-pagination'>
          <span
            className='cursor-pointer'
            onClick={() => {
              if (pageNumber > 1) {
                setPageNumber(pageNumber - 1);
                changePageNumber(pageNumber - 1);
              }
            }}
          >
            <ArrowUpIcon />
          </span>
          <span>
            <input
              value={pageInputValue}
              type='text'
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  if (e.target.value) {
                    setPageNumber(parseInt(e.target.value, 10));
                    changePageNumber(e.target.value);
                  }
                }
              }}
              onChange={pageInputChange}
              onBlur={(e: any) => {
                if (e.target.value) {
                  setPageNumber(parseInt(e.target.value, 10));
                  changePageNumber(parseInt(e.target.value, 10));
                }
              }}
            />{' '}
            of {numPages}{' '}
          </span>
          <span
            className='cursor-pointer'
            onClick={() => {
              if (pageNumber < numPages) {
                setPageNumber(pageNumber + 1);
                changePageNumber(pageNumber + 1);
              }
            }}
          >
            <ArrowDownIcon />
          </span>
        </div>
        <div className='zoom-in-out-pdf'>
          {!fitToPdf && (
            <span
              onClick={() => {
                setIsLoadingPdf(true);
                setPageScale(pageScale + 0.0001);
                setFitToPdf(true);
              }}
            >
              <FitToZoom />
            </span>
          )}
          {fitToPdf && (
            <span
              onClick={() => {
                setIsLoadingPdf(true);
                setPageScale(pageScale - 0.0001);
                setFitToPdf(false);
              }}
            >
              <FitToZoomOut />
            </span>
          )}
          <span
            onClick={() => {
              if (!(pageScale < 0.4)) {
                setIsLoadingPdf(true);
                fitToPdf && setFitToPdf(false);
                pageScale > 0.1 && setPageScale(pageScale - 0.1);
              }
            }}
            className={`${pageScale < 0.4 ? 'disable-zoom' : ''}`}
          >
            <MinusIocnSVG />
          </span>
          <span
            onClick={() => {
              setIsLoadingPdf(true);
              fitToPdf && setFitToPdf(false);
              setPageScale(pageScale + 0.1);
            }}
          >
            <PlusIconSVG />
          </span>
        </div>
      </div>
    </Document>
  );
}

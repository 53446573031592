import { Form, Modal } from 'antd';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { trackAnalytics } from 'utils';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { getDefaultWorkflow } from '../../utils/dummyData';
import ColorPicker from '../shared/ColorPicker';
import ImageDragDrop from '../shared/ImageDragDrop';
import './style.css';

const ProjectForm = (props: any) => {
  const { visible, setVisible, title = '', auth, project, isEdit, profile } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [name, setName] = useState('');
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState('');
  const [updateColor, setUpdateColor] = useState('');
  const [background, setBackground] = useState<string>('#FFFFFF');
  const [colorState, setColorState] = useState({
    selectedIndex: isEdit ? 14 : 13,
    selectedColor: isEdit ? updateColor : '#BDBDBD',
  });

  useEffect(() => {
    setImageThumbnailUrl(isEdit && project.imageThumbnailUrl ? project.imageThumbnailUrl : '');
    setUpdateColor(isEdit && project.color ? project.color : '');
    setImageUrl(isEdit && project.imageUrl ? project.imageUrl : '');
    setName(isEdit && project.name ? project.name : '');
  }, [isEdit, project]);

  const reset = () => {
    setColorState({
      ...colorState,
      selectedIndex: isEdit ? 14 : 13,
      selectedColor: isEdit ? updateColor : '#BDBDBD',
    });
    setImageUrl('');
    setImageThumbnailUrl('');
    setIsLoading(false);
    props.form.resetFields();
  };

  const handleSubmit = (e: any) => {
    const projectId: string | null = window.localStorage.getItem('selectedProjectId');
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      const { projectName } = values;
      if (!err) {
        setIsLoading(true);
        const firestore = firebase.firestore();
        if (!isEdit) {
          const { fullName = '', firstName = '', lastName = '', photoURL = '', email } = profile;
          const workflow = getDefaultWorkflow();
          const chatId = await firestore
            .collection('chat')
            .add({
              messages: [],
              count: 0,
            })
            .then((obj): any => {
              return obj.id;
            })
            .catch(() => {
              setIsLoading(false);
              return false;
            });
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .add({
              projectId,
              name: projectName,
              imageUrl,
              color: colorState.selectedColor,
              imageThumbnailUrl,
              created_by: auth.uid,
              chatId,
              workflow,
              updatedTimeStamp,
              member_emails: [profile.email],
              member_ids: [auth.uid],
              members: [
                {
                  id: auth.uid,
                  name: fullName ? fullName : `${firstName} ${lastName}`,
                  image: photoURL,
                  role: 'Admin',
                  invite_by: auth.uid,
                  status: 'ACTIVE',
                  email,
                },
              ],
            })
            .then(() => {
              statsAction('MASTER_PROJECT_CREATED', firestore);
              trackAnalytics('Workspace', 'Created new Workspace');
              reset();
              setVisible();
            })
            .catch(() => {
              setIsLoading(false);
              return false;
            });
        } else {
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .doc(project.id)
            .set(
              {
                name: projectName,
                imageUrl,
                color: updateColor,
                imageThumbnailUrl,
                updatedTimeStamp,
              },
              { merge: true },
            )
            .then(() => {
              statsAction('MASTER_PROJECT_UPDATED', firestore);
              reset();
              setVisible();
            })
            .catch(() => {
              setIsLoading(false);
              return false;
            });
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <div className='project-form'>
      <Modal
        visible={visible}
        title={title}
        onOk={setVisible}
        onCancel={() => setVisible()}
        footer={null}
      >
        <ColorContext.Consumer>
          {({ theme }) => (
            <div className='add-project-container'>
              <Form className='white'>
                <Form.Item className='add-project-form-item'>
                  <div
                    className='project-selected-color'
                    style={{ background: isEdit ? updateColor : colorState.selectedColor }}
                  />
                  {getFieldDecorator('projectName', {
                    initialValue: isEdit && name ? name : '',
                    rules: [
                      {
                        required: true,
                        message: 'Please enter workspace name!',
                        whitespace: true,
                      },
                    ],
                  })(
                    <Zinput
                      placeholder='Enter Workspace Name'
                      secondary
                      bgcolor={theme.backgrounds.color1}
                    />,
                  )}
                  <ImageDragDrop
                    edit={false}
                    hideDragAndDrop={true}
                    url={imageUrl}
                    sendFeedback={(image: any, thumbnailImage: any) => {
                      image && setImageUrl(image);
                      thumbnailImage && setImageThumbnailUrl(thumbnailImage);
                      trackAnalytics('Workspace', 'Added image to workspace');
                      setIsUploadingImage(false);
                    }}
                    getThumbnailBlob={() => null}
                    setIsUploadingImage={setIsUploadingImage}
                  />
                </Form.Item>
                <Form.Item>
                  <ColorPicker
                    background={background}
                    setBackground={setBackground}
                    colorState={colorState}
                    setColorState={setColorState}
                    setUpdateColor={setUpdateColor}
                  />
                </Form.Item>
                <div className='add-project-submit-div'>
                  <Zbutton
                    primary
                    key='submit'
                    btnColors={theme}
                    style={{ width: '100%', height: 40 }}
                    loading={isLoading}
                    disabled={
                      isLoading || isUploadingImage || (isEdit && project.created_by !== auth.uid)
                    }
                    onClick={handleSubmit}
                  >
                    {isEdit ? 'Save Changes' : 'Create Workspace'}
                  </Zbutton>
                </div>
              </Form>
            </div>
          )}
        </ColorContext.Consumer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create()(ProjectForm));

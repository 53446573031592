export const SHOW_ADD_VIDEO_CANVAS = 'SHOW_ADD_VIDEO_CANVAS';
export const HIDE_ADD_VIDEO_CANVAS = 'HIDE_ADD_VIDEO_CANVAS';
export const CANVAS_FILTER_THREADS = 'CANVAS_FILTER_THREADS';

export interface VideoCanvasDialog {
  type: typeof SHOW_ADD_VIDEO_CANVAS;
  payload: any;
}

export interface CanvasFilterThreads {
  type: typeof CANVAS_FILTER_THREADS;
  payload: any;
}

export interface HideVideoCanvasDialog {
  type: typeof HIDE_ADD_VIDEO_CANVAS;
  payload: any;
}

export type NewCanvasTypes = HideVideoCanvasDialog | VideoCanvasDialog | CanvasFilterThreads;

import { DashboardKanbanIcon, DashboardTileIcon } from 'components/svg';
import React from 'react';
import { matchPath, withRouter } from 'react-router-dom';

const DashboardButtons = (props: any) => {
  const routes = [{ path: '/pt/:id' }, { path: '/pl/:id' }, { path: '/pk/:id' }];

  const currentRoute = routes.find((route) => !!matchPath(props.location.pathname, route));

  const selectedItem = !!currentRoute ? currentRoute.path : false;

  const selectedItems = routes.map((route) => route.path === selectedItem);

  const selectedColor = '#306AE1';
  const unSelectedColor = '#4F4F4F';

  const { id } = props.match.params;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div
        style={{ paddingRight: '15px', cursor: 'pointer' }}
        onClick={() => {
          id && props.history.push(`/pt/${id}`);
        }}
      >
        <DashboardTileIcon color={(selectedItems[0] && selectedColor) || unSelectedColor} />
      </div>
      {/* <div
        style={{ paddingRight: '15px', cursor: 'pointer' }}
        onClick={() => id && props.history.push(`/pk/${id}`)}
      >
        <DashboardListIcon color={(selectedItems[1] && selectedColor) || unSelectedColor} />
      </div> */}
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          id && props.history.push(`/pk/${id}`);
        }}
      >
        <DashboardKanbanIcon color={(selectedItems[2] && selectedColor) || unSelectedColor} />
      </div>
    </div>
  );
};

export default withRouter(DashboardButtons);

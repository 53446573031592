import React from 'react';

export default () => (
  <svg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M1.35065 0H9.45455C10.2005 0 10.8052 0.604706 10.8052 1.35065V2.2839L14.8571 0.257937V9.19658L10.8052 7.17062V8.1039C10.8052 8.84984 10.2005 9.45455 9.45455 9.45455H1.35065C0.604706 9.45455 0 8.84984 0 8.1039V1.35065C0 0.604706 0.604706 0 1.35065 0ZM10.8052 5.66054L13.5065 7.01118V2.44333L10.8052 3.79397V5.66054ZM1.35064 1.35064V8.10389H9.45454V1.35064H1.35064Z'
      fill='#306AE1'
    />
  </svg>
);

import chroma from 'chroma-js';
import React from 'react';
import Select from 'react-select';
import { ITag } from 'types/projectTypes';
import './tags.css';

interface IProps {
  setSelectedOption: React.Dispatch<React.SetStateAction<IData[]>>;
  tagList: ITag[];
  addedTags: ITag[];
}

interface IData {
  value: string;
  label: string;
  color: string;
}

interface Style {
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  data: IData;
}

const Chips: React.FC<IProps> = ({ tagList, setSelectedOption, addedTags }) => {
  const options = tagList.map((tag: ITag) => {
    return { value: tag.id, label: tag.title, color: tag.color };
  });

  const defaultOptions = addedTags.map((tag: ITag) => {
    return { value: tag.id, label: tag.title, color: tag.color };
  });

  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: Style) => {
      const color = chroma(data!.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data!.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data!.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data!.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles: any, { data }: Style) => {
      return {
        ...styles,
        backgroundColor: data!.color,
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles: any, { data }: Style) => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: data!.color,
        color: 'white',
      },
    }),
  };

  const handleChange = (option: any) => {
    setSelectedOption(option);
  };
  return (
    <Select
      onChange={handleChange}
      defaultValue={defaultOptions}
      options={options}
      isMulti
      closeMenuOnSelect={false}
      styles={colourStyles}
      placeholder='Select a tag from here.'
    />
  );
};

export default Chips;

import { Modal } from 'antd';
import React from 'react';

interface IProps {
    onCloseDrawer: (isShow: boolean) => void;
    visible: boolean;
}

export const ContactUsDrawer: React.FC<IProps> = ({
    onCloseDrawer,
    visible,
}) => {
    return (
        <Modal
            title='Contact'
            visible={visible}
            style={{ textAlign: 'center' }}
            onCancel={() => onCloseDrawer(false)}
            footer={null}
        >
            <div>
                Please send us an email to <a style={{ color: '#001DAD' }} href="mailto:support@zooc.io">support@zooc.io</a>
            </div>
        </Modal>
    );
};
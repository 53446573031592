let lastCall: any = '';
let lastCallTimer: any = '';
let previousCall: any = '';

const debounce = (f: any, t: any) => {
  return (args: any) => {
    previousCall = lastCall;
    lastCall = Date.now();
    if (previousCall && lastCall - previousCall <= t) {
      clearTimeout(lastCallTimer);
    }
    lastCallTimer = setTimeout(() => f(args), t);
  };
};

export default debounce;

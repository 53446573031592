import { IAuthAction, IAuthState, Type } from '../../types/authTypes';

const authReducer = (
  state: IAuthState = {
    status: Type.StatusIdle,
    authError: null,
    firebaseAuthToken: '',
    userProductPlan: 'freelancer',
  },
  action: IAuthAction,
) => {
  switch (action.type) {
    case Type.SignInRequest:
      return {
        ...state,
        authError: null,
        status: Type.StatusRunning,
      };

    case Type.SignInError:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.SignInError,
      };

    case Type.SignInSuccess:
      return {
        ...state,
        authError: null,
        status: Type.StatusIdle,
      };

    case Type.SignUpRequest:
      return {
        ...state,
        authError: null,
        status: Type.StatusRunning,
      };
    case Type.SignUpSuccess:
      return {
        ...state,
        authError: null,
        status: Type.StatusIdle,
      };
    case Type.SignUpError:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.SignUpError,
      };
    case Type.GoogleLogedIn:
      return {
        ...state,
        authError: null,
        status: Type.StatusIdle,
      };
    case Type.GoogleLogInError:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.GoogleLogInError,
      };
    case Type.githubLogedIn:
      return {
        ...state,
        authError: null,
        status: Type.StatusIdle,
      };
    case Type.githubLogInError:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.githubLogInError,
      };

    case Type.SignOutSuccess:
      return state;
    case Type.ClearAuthState:
      return {
        ...state,
        authError: null,
        status: Type.StatusIdle,
      };
    case Type.forgotPasswordRequest:
      return {
        ...state,
        authError: null,
        status: Type.StatusRunning,
      };
    case Type.forgotPasswordSuccess:
      return {
        ...state,
        authError: null,
        status: Type.forgotPasswordSuccess,
      };
    case Type.forgotPasswordError:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.forgotPasswordError,
      };
    case Type.verifyTokenRequest:
      return {
        ...state,
        authError: null,
        status: Type.StatusRunning,
      };
    case Type.verifyTokenSuccess:
      return {
        ...state,
        authError: null,
        status: Type.verifyTokenSuccess,
      };
    case Type.verifyTokenError:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.verifyTokenError,
      };
    case Type.resetPasswordRequest:
      return {
        ...state,
        authError: null,
        status: Type.StatusRunning,
      };
    case Type.resetPasswordSuccess:
      return {
        ...state,
        authError: null,
        status: Type.resetPasswordSuccess,
      };
    case Type.resetPasswordError:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.resetPasswordError,
      };
    case Type.updateEmailRequest:
      return {
        ...state,
        authError: null,
        status: Type.StatusRunning,
      };
    case Type.updateEmailSuccess:
      return {
        ...state,
        authError: null,
        status: Type.updateEmailSuccess,
      };
    case Type.updateEmailErrore:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.updateEmailErrore,
      };
    case Type.updateUserProfileRequest:
      return {
        ...state,
        authError: null,
        status: Type.StatusRunning,
      };
    case Type.updateUserProfileSuccess:
      return {
        ...state,
        authError: null,
        status: Type.StatusIdle,
      };
    case Type.updateUserProfileErrore:
      return {
        ...state,
        authError: action.err!.message,
        status: Type.updateUserProfileErrore,
      };
    case Type.firebaseAuthToken: {
      return { ...state, firebaseAuthToken: action.payload };
    }
    default:
      return state;
  }
};

export default authReducer;

import { Form, Icon, Modal, notification, Typography } from 'antd';
import firebase from 'firebase/app';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { jiraConnectAction } from '../../store/actions/jiraIntegrationAction';
import { trackAnalytics } from '../../utils';
import { CircleRightClick } from '../svg';
import './index.css';

const JiraConnect = (props: any) => {
  const { profile, selectedOrganizationData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [jiraHost, setJiraHost] = useState(profile.jiraHost || '');
  const [jiraEmail, setJiraEmail] = useState(profile.jiraHost || '');
  const [jiraAPIToken, setJiraAPIToken] = useState(profile.jiraAPIToken || '');
  const [hostError, seHostError] = useState<any>('success');
  const [emailError, setEmailError] = useState<any>('success');
  const [apiTokenError, setApiTokenError] = useState<any>('success');
  const [jiraModalVisible, setjiraModalVisible] = useState(false);

  const handleIntegrateCancel = () => {
    setjiraModalVisible(false);
  };

  const jiraDisconnected = (e: any) => {
    e.stopPropagation();
    Modal.confirm({
      content: '',
      title: 'Are you sure, you want to be disconnected from jira account?',
      onOk() {
        setJiraHost('');
        setJiraEmail('');
        setJiraAPIToken('');
        const firestore = firebase.firestore();
        const profileData = props.profile;
        const loginUserId = props.auth.uid;
        profileData.jiraHost = '';
        profileData.jiraEmail = '';
        profileData.jiraAPIToken = '';
        profileData.jiraConnect = false;
        firestore
          .collection('users')
          .doc(loginUserId)
          .set(profileData, { merge: true });
      },
    });
  };

  const connectJiraAccount = async () => {
    trackAnalytics('Integration', 'Connect to jira account clicked');
    !jiraHost && seHostError('error');
    !jiraEmail && setEmailError('error');
    !jiraAPIToken && setApiTokenError('error');
    const firestore = firebase.firestore();
    if (jiraHost && jiraEmail && jiraAPIToken) {
      const fiebaseInstance: any = firebase;
      const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
      const loginUserId = props.auth.uid;
      const profileData = props.profile;
      profileData.jiraHost = jiraHost;
      profileData.jiraEmail = jiraEmail;
      profileData.jiraAPIToken = jiraAPIToken;
      setIsLoading(true);
      firestore
        .collection('users')
        .doc(loginUserId)
        .set(profileData, { merge: true })
        .then(() => {
          jiraConnectAction(firebaseToken).then((resMessage: any) => {
            if (resMessage.error) {
              profileData.jiraHost = '';
              profileData.jiraEmail = '';
              profileData.jiraAPIToken = '';
              firestore
                .collection('users')
                .doc(loginUserId)
                .set(profileData, { merge: true });
              notification.error({
                message: resMessage.message,
              });
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          });
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  const FormItem = Form.Item;
  const jiraConnect = (profile && profile.jiraConnect) || false;
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <Fragment>
          <div className={`jira-connect-btn ${jiraConnect ? 'connected-jira' : null}`}>
            {jiraConnect ? (
              <Zbutton secondary btnColors={theme} onClick={jiraDisconnected}>
                Disconnect
              </Zbutton>
            ) : (
              <Zbutton
                primary
                btnColors={theme}
                disabled={jiraConnect || (userQuota && !userQuota.jiraIntegration)}
                onClick={() => {
                  if (userQuota && userQuota.jiraIntegration) {
                    setjiraModalVisible(true);
                  }
                }}
              >
                Connect
              </Zbutton>
            )}
          </div>
          <Modal
            title={jiraConnect ? '' : 'Connect Jira'}
            visible={jiraModalVisible}
            onOk={handleIntegrateCancel}
            onCancel={handleIntegrateCancel}
            footer={null}
          >
            {jiraConnect ? (
              <div className='jira-success-container' style={{ color: theme.text.color2 }}>
                <span className='jira-success-message'>
                  <CircleRightClick />
                  <div style={{ marginLeft: 10 }}>Jira is connected</div>
                </span>
              </div>
            ) : (
              <div className='jira-integration-container'>
                <Form style={{ color: theme.text.color2 }}>
                  <div className='form-field step-jira-host'>
                    <Typography.Text strong={true}>Jira Host*</Typography.Text>
                    <FormItem
                      validateStatus={hostError}
                      help={hostError === 'error' && 'Jira Host is required.'}
                    >
                      <Zinput
                        placeholder='https://yourcompany.atlassian.net/'
                        value={jiraHost}
                        onChange={(e: any) => {
                          setJiraHost(e.target.value);
                          seHostError(e.target.value ? 'success' : 'error');
                        }}
                      />
                    </FormItem>
                  </div>
                  <div className='form-field step-jira-email'>
                    <Typography.Text strong={true} className='settings-form-label'>
                      Jira Email*
                    </Typography.Text>
                    <FormItem
                      validateStatus={emailError}
                      help={emailError === 'error' && 'Jira Email is required.'}
                    >
                      <Zinput
                        placeholder='Jira Email'
                        value={jiraEmail}
                        onChange={(e: any) => {
                          setJiraEmail(e.target.value);
                          setEmailError(e.target.value ? 'success' : 'error');
                        }}
                      />
                    </FormItem>
                  </div>
                  {jiraConnect ? null : (
                    <div className='form-field step-jira-apitoken'>
                      <Typography.Text strong={true}>API Token*</Typography.Text>
                      <FormItem
                        validateStatus={apiTokenError}
                        help={apiTokenError === 'error' && 'API Token is required.'}
                      >
                        <Zinput
                          placeholder='API Token'
                          value={jiraAPIToken}
                          onChange={(e: any) => {
                            setJiraAPIToken(e.target.value);
                            setApiTokenError(e.target.value ? 'success' : 'error');
                          }}
                        />
                      </FormItem>
                      <a
                        onClick={() => trackAnalytics('Integration', 'Get api token clicked')}
                        target='_blank'
                        href={'https://id.atlassian.com/manage/api-tokens'}
                      >
                        <span
                          className='integration-api'
                          style={{ color: theme.primaryBtn.color1 }}
                        >
                          Get your API token from here
                          <Icon type='key' style={{ marginLeft: 5 }} />
                        </span>
                      </a>
                    </div>
                  )}
                </Form>
                <div style={{ width: '100%', textAlign: 'end' }}>
                  <Zbutton
                    primary
                    btnColors={theme}
                    style={{ width: 180 }}
                    loading={isLoading}
                    onClick={connectJiraAccount}
                  >
                    Connect my Jira account
                  </Zbutton>
                </div>
              </div>
            )}
          </Modal>
        </Fragment>
      )}
    </ColorContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(JiraConnect) as any;

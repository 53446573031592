import { Menu } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface IProps {
  onClick?: any;
  children: any;
  menuColors?: any;
}

const StyledMenu = styled(Menu)`
  border-radius: 5px;
  .ant-menu-submenu-horizontal > .ant-menu {
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    height: 30px !important;
  }
  .ant-menu-item:hover {
    color: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color3};
    background: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color1};
  }
  .ant-menu-item:focus {
    color: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color3};
    background: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color1};
  }
  .ant-menu-item:active {
    color: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color3};
    background: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color1};
  }
  .about-dropdown {
    border-bottom: none;
    &:hover {
      color: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color3};
      background: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color1};
    }
    li:hover {
      color: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color3};
      background: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color1};
    }
    li:focus: {
      color: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color3};
      background: ${({ menuColors: { primaryBtn } }: IProps) => primaryBtn.color1};
    }
  }
`;

const Zmenu: React.FC<IProps> = ({ children, onClick, menuColors }) => {
  return (
    <StyledMenu onClick={onClick} menuColors={menuColors}>
      {children}
    </StyledMenu>
  );
};

export default Zmenu;

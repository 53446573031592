import { Button, Icon, Tooltip } from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createProject as createProjectAction } from '../../store/actions/projectActions';
import { sendRecordingToSlack } from '../../store/actions/recordingAction';
import './index.css';

import { ShowNewVideoCanvas } from 'store/actions/newCanvasActions';
import { addThumnailURLInProject, copyURL, mobileCheck, trackAnalytics } from '../../utils';
import VideoDialogContent from '../newCanvas/VideoDialogContent';
import MobileRecordAudio from './MobileRecordAudio';
// import { url } from 'inspector';

const urlParams = new URLSearchParams(window.location.search);
const channelId = urlParams.get('channelId') || '';
const slackUserId = urlParams.get('slackUserId') || '';

const RecordingModal = (props: any) => {
  // const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState('video');
  const [projectId, setProjectId] = useState('');
  const [isSendingToSlack, setIsSendingToSlack] = useState(false);
  // const [loading, setLoading] = React.useState(false);
  const [copied, setCopied] = useState(false);

  const [userData, setUserData] = useState({
    userQuota: {
      recordVoiceClipTime: 0,
      screenRecordingCommentTime: 0,
      videoCommentTime: 0,
    },
  });
  // const [userStatus, setUserStatus] = React.useState(channelId ? 'validUser' : 'loading');
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const isMobile = mobileCheck();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  const { auth, profile, firebaseAuthToken } = props;

  // React.useEffect(() => {
  //   const crispElement: any = document.querySelector('.crisp-client');
  //   if (crispElement && crispElement.style) {
  //     crispElement.style.display = 'none';
  //   }

  //   return () => {
  //     if (crispElement && crispElement.style) {
  //       crispElement.style.display = 'block';
  //     }
  //   };
  // });

  React.useEffect(() => {
    if (!channelId) {
      window.addEventListener('message', receiveMessage, false);
    }
    function receiveMessage(event: any) {
      if (event && event.data && event.data.from && event.data.from === 'chromeExtension') {
      //  setUserStatus('validUser');
      }
    }
    // this code will ensure to stop loading if no event from parent
    // setTimeout(() => {
    //   setUserStatus((userStatusState) =>
    //     userStatusState === 'validUser' ? 'validUser' : 'inValidUser',
    //   );
    // }, 3000);
  }, []);

  useEffect(() => {
    if (auth && auth.uid) {
      firebase
        .firestore()
        .collection('users')
        .doc(auth.uid)
        .onSnapshot((doc: any) => {
          const userDetails = doc.data();
          userDetails && userDetails.userQuota && setUserData(userDetails);
        });
    }
  }, [auth]);

  const { userQuota } = userData;
  const { recordVoiceClipTime } = userQuota;
  // const sendDataToParent = async (
  //   downloadURL: any,
  //   imgUrl: any,
  //   videoFirebaseURL: string,
  //   videoS3URL?: string,
  // ) => {
  //   let imgFirebaseUrl = '';
  //   if (imgUrl) {
  //     imgFirebaseUrl = await sendImageToStore(imgUrl);
  //   }
  //   const projectType =
  //     match.params.recordType.charAt(0).toUpperCase() + match.params.recordType.slice(1);
  //   const projectData: any = {
  //     title: `${projectType} Recording`,
  //     project_url:
  //       isAWSRealTimeSaving && match.params.recordType !== 'audio' ? videoS3URL : downloadURL,
  //     member_emails: [],
  //     project_tags: [],
  //     type: match.params.recordType === 'screen' ? 'webcam' : match.params.recordType,
  //     imageType: 'base64',
  //     jiraIssueUrl: '',
  //     trelloIssueUrl: '',
  //     project_base64_image: '',
  //     appendVideo: '',
  //     thumbnail_url: imgFirebaseUrl,
  //   };

  //   props.createProject(projectData, props.auth.firebaseAuthToken).then((insertedId: any) => {
  //     if (channelId) {
  //       const fiebaseInstance: any = firebase;
  //       fiebaseInstance
  //         .auth()
  //         .currentUser.getIdToken(true)
  //         .then((firebaseToken: string) => {
  //           if (match.params.recordType === 'video') {
  //             sendRecordingToSlack(
  //               {
  //                 fileUrl: videoFirebaseURL,
  //                 channelId,
  //                 recordingType: 'Video',
  //                 slackUserId,
  //                 projectUrl: `${window.location.origin}/project/${insertedId}`,
  //               },
  //               firebaseToken,
  //               true,
  //             ).then(() => {
  //               setLoading(false);
  //             });
  //           } else if (match.params.recordType === 'screen') {
  //             sendRecordingToSlack(
  //               {
  //                 fileUrl: videoFirebaseURL,
  //                 channelId,
  //                 recordingType: 'Screen',
  //                 slackUserId,
  //                 projectUrl: `${window.location.origin}/project/${insertedId}`,
  //               },
  //               firebaseToken,
  //               true,
  //             ).then(() => {
  //               setLoading(false);
  //             });
  //           } else if (match.params.recordType === 'audio') {
  //             fiebaseInstance
  //               .auth()
  //               .currentUser.getIdToken(true)
  //               .then((firebaseTokenParent: string) => {
  //                 sendRecordingToSlack(
  //                   {
  //                     fileUrl: downloadURL,
  //                     channelId,
  //                     recordingType: 'Audio',
  //                     slackUserId,
  //                     projectUrl: `${window.location.origin}/project/${insertedId}`,
  //                   },
  //                   firebaseTokenParent,
  //                   true,
  //                 );
  //               });
  //           }
  //         });
  //     } else {
  //       window.parent.postMessage(`${insertedId}`, '*');
  //       setLoading(false);
  //     }
  //   });
  // };

  const createVideoTask = async (url: string, videoType: string, blobURL: string = '') => {
    const { createProject } = props;
    setMediaType(videoType);
    // setLoading(true);
    trackAnalytics('Canvas', 'Created a canvas', 'video');
    const { email } = profile;

    const selectedMasterProjectId = window.localStorage.getItem('selectedMasterProjectId')
      ? window.localStorage.getItem('selectedMasterProjectId')
      : '';
    let selectedMasterProject = {};
    if (selectedMasterProjectId) {
      const firestore = firebase.firestore();
      const selectedMasterProjectData: any = await firestore
        .collection('masterProjects')
        .where(firebase.firestore.FieldPath.documentId(), '==', selectedMasterProjectId)
        .get();

      if (
        selectedMasterProjectData &&
        selectedMasterProjectData.docs &&
        selectedMasterProjectData.docs.length
      ) {
        selectedMasterProject = selectedMasterProjectData.docs[0].data();
      }
    }

    const projectData = {
      title: 'Untitled task',
      project_url: url,
      member_emails: [email],
      project_tags: [],
      type: videoType, // videoRecord/videoUpload/videoByURL
      workflowId: window.localStorage.getItem('selectedWorkflowId')
        ? window.localStorage.getItem('selectedWorkflowId')
        : 'chromeExtenstion',
      masterProjectId: window.localStorage.getItem('selectedMasterProjectId')
        ? window.localStorage.getItem('selectedMasterProjectId')
        : 'chromeExtenstion',
      archived: 'no',
      blobURL,
    };
    setIsSendingToSlack(true);
    // replace the ID here with the dynamic ids
    createProject(projectData, firebaseAuthToken, selectedMasterProject).then(
      async (insertedId: any) => {
        if (insertedId) {
          if (videoType === 'videoRecord' || videoType === 'videoUpload') {
            addThumnailURLInProject(blobURL ? blobURL : url, insertedId);
          }

          ShowNewVideoCanvas(false);
          // setLoading(false);
          // history.push(`/c/${insertedId}`);
          setProjectId(insertedId);

          if (channelId) {
            const fiebaseInstance: any = firebase;
            fiebaseInstance
              .auth()
              .currentUser.getIdToken(true)
              .then((firebaseToken: string) => {
                if (videoType === 'videoRecord') {
                  sendRecordingToSlack(
                    {
                      fileUrl: '',
                      channelId,
                      recordingType: 'Video',
                      slackUserId,
                      projectUrl: `${window.location.origin}/c/${insertedId}`,
                    },
                    firebaseToken,
                    true,
                  ).then(() => {
                    // setLoading(false);
                    setIsSendingToSlack(false);
                  });
                } else if (videoType === 'audio') {
                  fiebaseInstance
                    .auth()
                    .currentUser.getIdToken(true)
                    .then((firebaseTokenParent: string) => {
                      sendRecordingToSlack(
                        {
                          fileUrl: url,
                          channelId,
                          recordingType: 'Audio',
                          slackUserId,
                          projectUrl: `${window.location.origin}/audio/${insertedId}`,
                        },
                        firebaseTokenParent,
                        true,
                      ).then(() => {
                        // setLoading(false);
                        setIsSendingToSlack(false);
                      });
                    });
                }
              });
          }
          // else {
          //   // window.parent.postMessage(`${insertedId}`, '*');
          //   setLoading(false);
          // }
        }
      },
    );
  };

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      {isMobile ? (
        <MobileRecordAudio recordVoiceClipTime={recordVoiceClipTime} />
      ) : (
        <div style={{ height: '300px', width: '500px', display: 'grid' }}>
          {projectId ? (
            <div>
              <div style={{ padding: '100px 0px 50px 0px' }}>
                {window.location.origin +
                  '/' +
                  (mediaType === 'audio' ? 'audio/' : 'c/') +
                  projectId}
                <Tooltip placement={'top'} title={`${copied ? 'Copied' : 'Copy'}`}>
                  <span
                    onClick={() => {
                      copyURL(
                        `${window.location.origin}/${
                          mediaType === 'audio' ? 'audio' : 'c'
                        }/${projectId}`,
                      );
                      setCopied(true);
                    }}
                    className={'project-copy'}
                  >
                    <Icon type='copy' className={'project-copy-icon'} />
                  </span>
                </Tooltip>
              </div>
              {/* window.parent.postMessage(`{url:projectId }`, '*'); */}

              <Button
                loading={(channelId && isSendingToSlack) || false}
                className='cancelButton startButtonBig'
                onClick={() => {
                  if (channelId) {
                    window.close();
                  } else {
                    window.parent.postMessage(
                      {
                        url: `${window.location.origin}/${
                          isAudioRecording ? 'audio' : 'c'
                        }/${projectId}`,
                      },
                      '*',
                    );
                  }
                }}
              >
                Done
              </Button>
            </div>
          ) : (
            <VideoDialogContent
              createVideoTask={createVideoTask}
              ShowNewVideoCanvas={ShowNewVideoCanvas}
              chromeExtension
              isAudioRecording={isAudioRecording}
              setIsAudioRecording={setIsAudioRecording}
            />
          )}
        </div>
      )}
    </div>
  );

  // return (
  //   <>
  //     <div className={`show-record-modal overlay`}>
  //       <div className='overlay-content'>
  //         <div
  //           style={{
  //             width: '650px',
  //             padding: '10px',
  //             height: '490px',
  //           }}
  //         >
  //           <div style={{ marginBottom: '25px' }}>
  //             <img src={logo} alt='Logo' className='logo' />
  //           </div>
  //           {_userStatus === 'validUser' && auth.uid && (
  //             <>
  //               {isMobile ? (
  //                 <MobileRecordAudio recordVoiceClipTime={recordVoiceClipTime} />
  //               ) : (
  //                 <Tabs
  //                   onChange={(e) =>
  //                     history.push(
  //                       `/${channelId ? 'slack' : 'record'}/${e}/${
  //                         props.location.search ? props.location.search : ''
  //                       }`,
  //                     )
  //                   }
  //                   activeKey={match.params.recordType}
  //                 >
  //                   <TabPane
  //                     tab={
  //                       <span>
  //                         <Icon type='audio' /> Audio{' '}
  //                       </span>
  //                     }
  //                     key='audio'
  //                   >
  //                     <div>
  //                       {/* <RecordAudio
  //                           maxRecordTime={recordVoiceClipTime}
  //                           onClose={() => null}
  //                           projectID={'to be decided'}
  //                           hideCrossIcon
  //                           background={'#ffffff'}
  //                           setLoading={setLoading}
  //                           showPercentage
  //                           setPercentage={setPercentage}
  //                           onSubmit={() => null}
  //                           onSubmitWithPercentage={(firebaseUrl: string) => {
  //                             setLoading(true);
  //                             sendDataToParent(firebaseUrl, '', '');
  //                           }}
  //                           firebaseAuthToken={props.auth && props.auth.firebaseAuthToken}
  //                         /> */}

  //                       <RecordAudioFile
  //                         maxRecordTime={userQuota && userQuota.recordVoiceClipTime}
  //                         large
  //                         showPercentage
  //                         background={'#ffffff'}
  //                         loading={loading}
  //                         setLoading={setLoading}
  //                         onSubmitWithPercentage={(firebaseUrl: string) => {
  //                           setLoading(true);
  //                           sendDataToParent(firebaseUrl, '', '');
  //                         }}
  //                       />
  //                     </div>
  //                   </TabPane>
  //                   <TabPane
  //                     tab={
  //                       <span>
  //                         <Icon type='video-camera' /> Video{' '}
  //                       </span>
  //                     }
  //                     key='video'
  //                   >
  //                     <VideoRecord
  //                       loading={loading}
  //                       isRecordModal={match.params.recordType}
  //                       sendToFirebase={async (videoBlobs: any, videoS3URL: string) => {
  //                         setLoading(true);
  //                         const blob: any = await fetch(videoBlobs).then((r) => r.blob());
  //                         getImageFromVideo(blob, (imgUrl: any) => {
  //                           if (isAWSRealTimeSaving) {
  //                             sendDataToParent('', imgUrl, '', videoS3URL);
  //                           } else {
  //                             sendVideoToStore(videoBlobs, imgUrl);
  //                           }
  //                         });
  //                       }}
  //                       setVideoUrl={() => null}
  //                       showSendButton
  //                       maxRecordTime={videoCommentTime}
  //                       setIsRecording={() => null}
  //                       isPublicChatWindow={isPublicChatWindow}
  //                       firebaseAuthToken={props.auth && props.auth.firebaseAuthToken}
  //                     />
  //                   </TabPane>
  //                   <TabPane
  //                     tab={
  //                       <span>
  //                         <Icon type='desktop' /> Screen{' '}
  //                       </span>
  //                     }
  //                     key='screen'
  //                   >
  //                     <VideoRecord
  //                       loading={loading}
  //                       isRecordModal={match.params.recordType}
  //                       sendToFirebase={async (videoBlobs: any, videoS3URL: string) => {
  //                         setLoading(true);
  //                         const blob: any = await fetch(videoBlobs).then((r) => r.blob());
  //                         getImageFromVideo(blob, (imgUrl: any) => {
  //                           if (isAWSRealTimeSaving) {
  //                             sendDataToParent('', imgUrl, '', videoS3URL);
  //                           } else {
  //                             sendVideoToStore(videoBlobs, imgUrl);
  //                           }
  //                         });
  //                       }}
  //                       setVideoUrl={() => null}
  //                       showSendButton
  //                       maxRecordTime={screenRecordingCommentTime}
  //                       setIsRecording={() => null}
  //                       isPublicChatWindow={isPublicChatWindow}
  //                       firebaseAuthToken={props.auth && props.auth.firebaseAuthToken}
  //                     />
  //                   </TabPane>
  //                 </Tabs>
  //               )}
  //               {loading && !isAWSRealTimeSaving && (
  //                 <Progress
  //                   strokeColor={'#039BE5'}
  //                   percent={percentage ? Math.floor(percentage) : 0}
  //                   size='small'
  //                   status={percentage === 100 ? 'success' : 'active'}
  //                 />
  //               )}
  //             </>
  //           )}
  //           {_userStatus === 'loading' ? (
  //             <div>Loading...</div>
  //           ) : (
  //             (_userStatus === 'inValidUser' || !auth.uid) && (
  //               <div>User not verified! Please login to Chrome Extension</div>
  //             )
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createProject: (project: any, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(RecordingModal);

// @ts-nocheck
import socketIOClient from 'socket.io-client';
const framerate = 15;
const audiobitrate = 22050;
let socket: any;
let state = 'stop';
declare global {
  interface Window {
    isSocketConnected: boolean;
  }
}
window.isSocketConnected = true;

export const startSendingChunks = (filename: string) => {
  consoleLog('call startSendingChunks' + filename);
  socket.emit('startSendingChunks', filename);
};

export const sendStream = (stream: any, fileName: string) => {
  console.log('call sendStream' + stream);
  socket.emit('binarystream', { stream, fileName });
};

export const stopSendingChunks = (filename: string) => {
  consoleLog('call stopSendingChunks');
  socket.emit('stopSendingChunks', filename);
};

function consoleLog(str: string) {
  return str;
}

const connectSocketServer = (firebaseAuthToken: string) => {
  const { REACT_APP_ZOOC_LIVE_URL = 'http://localhost:1437' } = process.env;
  const socketOptions = {
    origins: '*:*',
    secure: true,
    transport: ['websocket'],
    reconnection: true,
    reconnectionDelay: 1000,
    timeout: 15000,
    pingTimeout: 15000,
    pingInterval: 45000,
    query: { framespersecond: framerate, audioBitrate: audiobitrate, firebaseAuthToken },
  };
  socket = socketIOClient(REACT_APP_ZOOC_LIVE_URL, socketOptions);
  socket.on('connect_timeout', (timeout: number) => {
    consoleLog('Connection timed out = ' + timeout);
  });
  socket.on('error', (error: any) => {
    consoleLog('Connection error = ' + error);
  });

  socket.on('connect_error', () => {
    window.isSocketConnected = false;
    consoleLog('Connection Failed = ' + state);
  });

  socket.on('stream_update', (m: any) => {
    consoleLog('call stream_update = ' + m);
  });

  socket.on('message', (m: any) => {
    consoleLog('state on message= ' + state);
    consoleLog('recv server message' + m);
  });

  socket.on('fatal', (m: any) => {
    consoleLog('Fatal ERROR: unexpected:' + m);
    consoleLog('state on fatal error= ' + state);
    consoleLog('media recorder restarted');
  });

  socket.on('ffmpeg_stderr', (m: any) => {
    // this is the ffmpeg output for each frame
    consoleLog('FFMPEG:' + m);
  });

  socket.on('disconnect', (reason: any) => {
    consoleLog('state disconnected = ' + state);
    consoleLog('ERROR: server disconnected!');
    consoleLog('ERROR: server disconnected!' + reason);
    // connectSocketServer(firebaseAuthToken);
  });
  state = 'ready';
  consoleLog('state = ' + state);
  consoleLog('connect server successful');
};

export const connectServer = connectSocketServer;

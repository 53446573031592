import { Avatar, Button, Drawer, Modal, notification, Table } from 'antd';
import firebase from 'firebase';
import React, { useState } from 'react';
import { trackAnalytics, showFullFeature } from 'utils';
import ColorContext from '../../context/ColorContext';
import { mobileCheck } from '../../utils';
import MemberForm from '../dashboard/MemberForm';
import '../dashboard/members.css';
import { Close } from '../svg';
import './style.css';

const isMobile = mobileCheck();

interface IProps {
  onCloseDrawer: () => void;
  visible: boolean;
  profile: any;
  organizationDetail: any;
  auth?: any;
  selectedOrganizationData: any;
}

const Users: React.FC<IProps> = ({
  onCloseDrawer,
  visible,
  profile,
  organizationDetail,
  auth,
  selectedOrganizationData,
}) => {
  const isShowFullFeature = showFullFeature();
  // const { adminIds = [] } = profile;
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  const [memberFormVisible, setMemberFormVisible] = useState(false);
  const [isSentInvitation, setIsSentInvitation] = useState(false);

  const hideAddMemberForm = () => {
    setMemberFormVisible(false);
    setTimeout(() => {
      setIsSentInvitation(false);
    });
  };

  const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
  let selectedOrganizationMatchedIndex = organizationDetail.findIndex(
    (og: any) => og.id === selectedOrganizationId,
  );
  if (selectedOrganizationMatchedIndex === -1) {
    selectedOrganizationMatchedIndex = 0;
  }

  const allDataSource =
    (organizationDetail &&
      organizationDetail[selectedOrganizationMatchedIndex] &&
      organizationDetail[selectedOrganizationMatchedIndex].members) ||
    [];
  let dataSource = allDataSource.filter((allData: any) => allData.email !== profile.email);
  const isOwnerOrganization =
    (auth &&
      auth.uid &&
      organizationDetail &&
      organizationDetail[selectedOrganizationMatchedIndex] &&
      organizationDetail[selectedOrganizationMatchedIndex].created_by &&
      auth.uid === organizationDetail[selectedOrganizationMatchedIndex].created_by) ||
    false;
  // const dataSource1 = [
  //     {
  //         image: 'https://firebasestorage.googleapis.com/v0/b/zooc-app-dev.appspot.com/o/file-1599705078170.jpeg?alt=media&token=a70ff417-407e-49b6-9fd3-3653c4f1b5aa',
  //         name: "User 1",
  //         email: 'test1@gmail.com',
  //         role: "Admin",
  //         status: "ACTIVE"
  //     },
  //     {
  //         image: 'https://firebasestorage.googleapis.com/v0/b/zooc-app-dev.appspot.com/o/file-1599705078170.jpeg?alt=media&token=a70ff417-407e-49b6-9fd3-3653c4f1b5aa',
  //         name: "User 2",
  //         email: 'test1@gmail.com',
  //         role: "Admin",
  //         status: "ACTIVE"
  //     },
  // ];

  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (_text: any, record: any) => {
        return record.image ? (
          <Avatar className='thread-avtar' src={record.image} />
        ) : (
          <Avatar>{record.name && record.name[0] && record.name[0].toUpperCase()}</Avatar>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'eamil',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (_text: any, record: any) => {
        return auth && auth.uid && auth.uid !== record.id && isOwnerOrganization ? (
          <a
            onClick={() => {
              Modal.confirm({
                content: '',
                title: `Are you sure, you want to delete this user?`,
                onOk() {
                  dataSource = dataSource.filter((user: any) => {
                    if (user.email !== record.email) {
                      return user;
                    }
                  });
                  if (organizationDetail && organizationDetail[selectedOrganizationMatchedIndex]) {
                    const firestore = firebase.firestore();
                    return firestore
                      .collection('organization')
                      .doc(organizationDetail[selectedOrganizationMatchedIndex].id)
                      .set(
                        {
                          members: dataSource,
                        },
                        { merge: true },
                      )
                      .then(() => {
                        notification.success({
                          message: 'User has been deleted successfully.',
                        });
                      })
                      .catch(() => {
                        notification.error({
                          message: 'Something went wrong.',
                        });
                      });
                  }
                },
              });
            }}
          >
            Delete
          </a>
        ) : null;
      },
    },
  ];

  const addMember = () => {
    trackAnalytics('Profile', 'Invite Users Clicked');
    setMemberFormVisible(true);
  };

  return (
    <div>
      <MemberForm
        title='Invite new people'
        visible={memberFormVisible}
        setVisible={hideAddMemberForm}
        organizationDetail={organizationDetail}
        isSentInvitation={isSentInvitation}
        setIsSentInvitation={setIsSentInvitation}
        selectedOrganizationMatchedIndex={selectedOrganizationMatchedIndex}
      />
      <Drawer
        title='Users'
        placement='right'
        closable={false}
        onClose={() => onCloseDrawer()}
        visible={visible}
        style={{ position: 'absolute', overflowY: 'auto' }}
        width={isMobile ? '90vw' : '75vw'}
      >
        <div className='drawer-close-btn' onClick={() => onCloseDrawer()}>
          <Close />
        </div>
        <ColorContext.Consumer>
          {({ theme }) => (
            <div
              className='invite-users-container'
              style={{
                color: theme.text.color2,
                justifyContent: isMobile ? 'flex-start' : undefined,
              }}
            >
              <div
                style={{ width: '100%', marginTop: '5px', textAlign: 'right', marginBottom: '5px' }}
              >
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '5px',
                  }}
                >
                  <span>
                    {`${dataSource.length} / ${(userQuota && userQuota.totalUser) || 1} Users`}
                  </span>

                  {isOwnerOrganization && isShowFullFeature && (
                    <Button
                      onClick={addMember}
                      className='organization-btn add-org-btn'
                      disabled={userQuota && userQuota.totalUser <= dataSource.length}
                    >
                      Invite Users
                    </Button>
                  )}
                </div>
                <Table columns={columns} dataSource={dataSource} pagination={false} />
              </div>
            </div>
          )}
        </ColorContext.Consumer>
      </Drawer>
    </div>
  );
};

export default Users;

import { Button, Icon } from 'antd';
import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { secondsToHms } from '../../utils/index';
import SeekBar from '../playerComponents/seekbar';
import PlayIcon from '../recording/images/play-icon.png';
interface Props {
  url: string;
  id: string;
  large?: boolean;
}
const AudioPlayer = ({ url, id, large = false }: Props) => {
  const forwordStep = 10; // secouds
  const backwordStep = 10; // secouds
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [playedOnce, setPlayedOnce] = useState(true);
  const [progress, setProgress]: any = useState({});
  const [duration, setDuration]: any = useState(0);
  const [videoPlayedDuration, setVideoPlayedDuration]: any = useState(0);

  const [playedPercentage, setPlayedPercentage] = useState(0);

  let audioRef: any = useRef(null);
  useEffect(() => {
    return () => {
      setPlayedOnce(true);
    };
  }, []);
  const showVisual = () => {
    if (playedOnce) {
      setPlayedOnce(false);
    } else {
      return;
    }
    let audio: any = document.getElementById('audio' + id);

    // var audio: any = audioRef
    audio = audio.firstChild;
    // audio.crossOrigin = 'anonymous';

    audio.play();
    const context = new AudioContext();
    const src = context.createMediaElementSource(audio);
    const analyser = context.createAnalyser();

    const canvas: any = document.getElementById('canvas' + id);
    canvas.width = large ? window.innerWidth : 500;
    canvas.height = large ? window.innerHeight - 65 : 250;
    const ctx = canvas.getContext('2d');

    src.connect(analyser);
    analyser.connect(context.destination);

    analyser.fftSize = 256;

    const bufferLength = analyser.frequencyBinCount;

    const dataArray = new Uint8Array(bufferLength);

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const barWidth = (WIDTH / bufferLength) * 2.5;
    let barHeight;
    let x = 0;

    const renderFrame = () => {
      requestAnimationFrame(renderFrame);

      x = 0;

      analyser.getByteFrequencyData(dataArray);

      ctx.fillStyle = 'rgb(196, 196, 196)';
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];
        ctx.fillStyle = 'rgb(3,155,229)';
        ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }
    };

    audio.play();

    audio.onended = () => {
      setPlaying(false);
    };
    renderFrame();
  };

  const seekChange = (seekChangeValue: any) => {
    if (duration && seekChangeValue) {
      setPlayedPercentage(seekChangeValue);
      audioRef.seekTo(seekChangeValue / 100);
    }
  };

  const handleProgress = (progressData: {
    playedSeconds: number;
    played: number;
    loaded: number;
  }) => {
    const { playedSeconds, played, loaded } = progressData;
    setVideoPlayedDuration(playedSeconds);
    setPlayedPercentage(played * 100);
    loaded && setProgress(loaded * 100);
    if (played === 1) {
      setPlaying(false);
    }
    // We only want to update time slider if we are not currently seeking
  };

  const backwardAudio = () => {
    const backwardVideoDuration = videoPlayedDuration - backwordStep;
    if (backwardVideoDuration > 0) {
      audioRef.seekTo(backwardVideoDuration);
    } else {
      audioRef.seekTo(0);
    }
  };

  const forwordAudio = () => {
    const backwardVideoDuration = videoPlayedDuration + forwordStep;
    if (backwardVideoDuration < duration) {
      audioRef.seekTo(backwardVideoDuration - 1);
    }
  };

  return (
    <div id={'content'}>
      <canvas className={'canvas-audio'} id={'canvas' + id}></canvas>

      <ReactPlayer
        config={{
          file: {
            forceAudio: true,
            attributes: {
              crossOrigin: 'anonymous',
            },
          },
        }}
        // crossOrigin
        playing={playing}
        ref={(player) => {
          audioRef = player;
        }}
        playbackRate={speed}
        id={'audio' + id}
        className={'audioPlayer'}
        url={url}
        onProgress={handleProgress}
        onDuration={setDuration}
      />
      <div className='play-audio-button'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <Button
            id={'play-audio-button' + id}
            onClick={() => {
              setPlaying((isplaying: boolean) => !isplaying);
              showVisual();
            }}
            //
            className={` ${
              large ? ` play-audio-button-large ${!playing ? ' play-icon' : ''}` : ''
            }`}
            shape={'circle'}
            type='primary'
            size={'large'}
            icon={playing ? 'pause' : ''}
          >
            {!playing && (
              <img
                className={large ? 'play-Icon-audio-record' : 'play-Icon-audio-record-small'}
                src={PlayIcon}
                alt='play'
              />
            )}
          </Button>
          <span style={{ padding: '7px', color: 'rgba(116, 116, 116, 0.9)' }}>
            {secondsToHms(videoPlayedDuration)}
          </span>

          <span style={{ flex: 1 }} className='seekbar-wrapper'>
            <SeekBar
              bgcolor={'#039BE5'}
              playedPercentage={playedPercentage}
              bufferPercentage={progress}
              seekChange={seekChange}
              duration={duration}
              id={url}
            />
          </span>
          <span style={{ padding: '7px', color: 'rgba(116, 116, 116, 0.9)' }}>
            {duration && duration !== Infinity ? secondsToHms(duration) : ''}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '0px 10px',
          }}
        >
          <span
            onClick={() =>
              setSpeed((currentSpeed: number) => (currentSpeed < 3 ? currentSpeed + 0.5 : 1))
            }
            className={'noselect'}
            style={{
              padding: large ? '0px 20px' : 0,
              fontSize: '31px',
              cursor: 'pointer',
              color: '#888888',
              width: large ? '60px' : '40px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {speed + 'x'}
          </span>

          <Row style={{ flex: 1, fontSize: '31px', color: '#888888' }}>
            <Col onClick={backwardAudio} style={{ padding: 10 }} offset={4} span={8}>
              <span style={{ cursor: 'pointer', display: 'grid' }}>
                <Icon type='undo' />
                <span style={{ fontSize: '10px', marginTop: '5px' }}>10</span>
              </span>
            </Col>
            <Col onClick={forwordAudio} style={{ padding: 10 }} span={8}>
              <span style={{ cursor: 'pointer', display: 'grid' }}>
                <Icon type='redo' />
                <span style={{ fontSize: '10px', marginTop: '5px' }}>10</span>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;

/* tslint:disable */
import { Icon, notification, Progress } from 'antd';
import ColorContext from 'context/ColorContext';
import firebase from 'firebase/app';
import 'firebase/storage';
import { Zbutton } from 'primitives';
import React, { useEffect, useState, useRef } from 'react';
import { secondsToHms } from '../../../utils/index';
import '../../recording/audioRecord.css';
// import PlayIcon from '../../recording/images/play-icon.png';
import { PauseIcon, RecordIcon } from './ChatSvgs';
import MediaPermissionModal from '../../../components/recording/MediaPermissionModal';

let drawVisual: any = null;
let stop: any = null;
const RecordAudioFile = (props: any) => {
  const [isOpen, setIsOpen ] = useState(false);
  const isAllowPermissionRef = useRef(false);
  const [blobObject, setBlobObject]: any = useState({});
  const [timer, setTimer] = useState(0);
  const [isRecording, setRecording] = useState(false);

  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  // const [micOptions, setMicOptions]: any = useState([]);

  const {
    maxRecordTime,
    showPercentage,
    onSubmitWithPercentage,
    onSubmit,
    background,
    onClose,
    profile,
    auth,
  } = props;

  React.useEffect(() => {
    if (timer >= maxRecordTime * 60) {
      setRecording(false);
      setTimer(0);
      stop && stop();
    }
  }, [timer]);
  React.useEffect(() => {
    (async () => {
      const leftchannel: any = [];
      const rightchannel: any = [];
      let recorder: any = null;
      let recording: any = false;
      let recordingLength: any = 0;
      // let volume: any = null;
      let audioInput: any = null;
      let sampleRate: any = null;
      // @ts-ignore
      const AudioContext: any = window.AudioContext || window.webkitAudioContext;
      let context: any = null;
      let analyser: any = null;
      const canvas: any = document.getElementById('chat-window-canvas-audio-recorder');
      const canvasCtx: any = canvas.getContext('2d');
      const visualSelect: any = document.querySelector('#visSelect');
      const micSelectAudio: any = document.querySelector('#micSelectAudio');
      let stream: any = null;
      let tested: any = false;

      try {
        isAllowPermissionRef.current = true;
        // @ts-ignore
        window.stream = stream = await getStream();
      } catch (err) {
        isAllowPermissionRef.current = false;
        console.error("error in audio permission:", err);
        return false;
        // Issue getting mic
      }

      const deviceInfos = await navigator.mediaDevices.enumerateDevices();
      const mics = [];
      // let micOptionsArray = [];
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === 'audioinput') {
          mics.push(deviceInfo);
          const label = deviceInfo.label || 'Microphone ' + mics.length;
          const option = document.createElement('option');
          option.value = deviceInfo.deviceId;
          option.text = label;
          micSelectAudio.appendChild(option);
          // micOptionsArray.push({ value: deviceInfo.deviceId, text: label });
        }
      }
      // setMicOptions(micOptionsArray);

      function getStream(constraints: any) {
        if (!constraints) {
          constraints = { audio: true, video: false };
        }
        return navigator.mediaDevices.getUserMedia(constraints);
      }

      setUpRecording();

      function setUpRecording() {
        context = new AudioContext();
        sampleRate = context.sampleRate;

        // creates a gain node
        // volume = context.createGain();
        // creates an audio node from teh microphone incoming stream
        audioInput = context.createMediaStreamSource(stream);

        // Create analyser
        analyser = context.createAnalyser();

        // connect audio input to the analyser
        audioInput.connect(analyser);

        // connect analyser to the volume control
        // analyser.connect(volume);

        const bufferSize = 2048;
        recorder = context.createScriptProcessor(bufferSize, 2, 2);

        // we connect the volume control to the processor
        // volume.connect(recorder);

        analyser.connect(recorder);

        // finally connect the processor to the output
        recorder.connect(context.destination);

        recorder.onaudioprocess = function(e: any) {
          // Check
          if (!recording) {
            return;
          }
          // Do something with the data, i.e Convert this to WAV,
          const left = e.inputBuffer.getChannelData(0);
          const right = e.inputBuffer.getChannelData(1);
          if (!tested) {
            tested = true;
            // if this reduces to 0 we are not getting any sound
            if (!left.reduce((a: any, b: any) => a + b)) {
              // There seems to be an issue with your Mic
              // clean up;
              stop();
              stream.getTracks().forEach(function(track: any) {
                track.stop();
              });
              context.close();
            }
          }
          // we clone the samples
          leftchannel.push(new Float32Array(left));
          rightchannel.push(new Float32Array(right));
          recordingLength += bufferSize;
        };
        visualize();
      }

      function mergeBuffers(channelBuffer: any, recordingLength: any) {
        const result = new Float32Array(recordingLength);
        let offset = 0;
        const lng = channelBuffer.length;
        for (let i = 0; i < lng; i++) {
          const buffer = channelBuffer[i];
          result.set(buffer, offset);
          offset += buffer.length;
        }
        return result;
      }

      function interleave(leftChannel: any, rightChannel: any) {
        const length = leftChannel.length + rightChannel.length;
        const result = new Float32Array(length);

        let inputIndex = 0;

        for (let index = 0; index < length; ) {
          result[index++] = leftChannel[inputIndex];
          result[index++] = rightChannel[inputIndex];
          inputIndex++;
        }
        return result;
      }

      function writeUTFBytes(view: any, offset: any, string: any) {
        const lng = string.length;
        for (let i = 0; i < lng; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      }

      function start() {
        recording = true;
        // @ts-ignore
        // document.querySelector('#msg').style.visibility = 'visible'
        // reset the buffers for the new recording
        leftchannel.length = rightchannel.length = 0;
        recordingLength = 0;
        if (!context) {
          setUpRecording();
        }
      }

      const stop = async (isDelete?: any) => {
        recording = false;
        // @ts-ignore
        // document.querySelector('#msg').style.visibility = 'hidden'

        // we flat the left and right channels down
        const leftBuffer = mergeBuffers(leftchannel, recordingLength);
        const rightBuffer = mergeBuffers(rightchannel, recordingLength);
        // we interleave both channels together
        const interleaved = interleave(leftBuffer, rightBuffer);

        ///////////// WAV Encode /////////////////
        // from http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
        //

        // we create our wav file
        const buffer = new ArrayBuffer(44 + interleaved.length * 2);
        const view = new DataView(buffer);

        // RIFF chunk descriptor
        writeUTFBytes(view, 0, 'RIFF');
        view.setUint32(4, 44 + interleaved.length * 2, true);
        writeUTFBytes(view, 8, 'WAVE');
        // FMT sub-chunk
        writeUTFBytes(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        // stereo (2 channels)
        view.setUint16(22, 2, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, sampleRate * 4, true);
        view.setUint16(32, 4, true);
        view.setUint16(34, 16, true);
        // data sub-chunk
        writeUTFBytes(view, 36, 'data');
        view.setUint32(40, interleaved.length * 2, true);

        // write the PCM samples
        const lng = interleaved.length;
        let index = 44;
        const volume = 1;
        for (let i = 0; i < lng; i++) {
          view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
          index += 2;
        }

        // our final binary blob
        if (!isDelete) {
          const blob = new Blob([view], { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(blob);
          setBlobObject({ blob, audioUrl });
          setRecording(false);
          setIsPaused(false);
          setLoading(true);
          const stopButton: any = document.getElementById('stop');
          stopButton.style.display = 'none';
          const canvas: any = document.getElementById('chat-window-canvas-audio-recorder');
          canvas.style.display = 'none';
          // const audioTag: any = document.getElementById('audio-player-container');
          // audioTag.style.display = 'inline-block';

          sendAudioToStore(blob);
        } else {
          clearAudio();
        }
        // @ts-ignore
        // document.querySelector('#audio-player').setAttribute('src', audioUrl);
        // const link: any = document.querySelector('#download');
        // link.setAttribute('href', audioUrl);
        // link.download = 'output.wav';
         // @ts-ignore
         window && window.stream && window.stream.getTracks()[0].stop();
      };

      // Visualizer function from
      // https://webaudiodemos.appspot.com/AudioRecorder/index.html
      //
      function visualize() {
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        const visualSetting = visualSelect.value;
        if (!analyser) {
          return;
        }

        if (visualSetting === 'sinewave') {
          analyser.fftSize = 2048;
          const bufferLength = analyser.fftSize;
          const dataArray = new Uint8Array(bufferLength);

          canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

          const draw = function() {
            drawVisual = requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = background ? background : '#C4C4C4';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#001DAD';

            canvasCtx.beginPath();

            const sliceWidth = (WIDTH * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0;
              const y = (v * HEIGHT) / 2;

              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }

              x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
          };

          draw();
        }
      }

      visualSelect.onchange = function() {
        window.cancelAnimationFrame(drawVisual);
        visualize();
      };

      micSelectAudio.onchange = async () => {
        stream.getTracks().forEach(function(track: any) {
          track.stop();
        });
        context.close();
        try{
          stream = await getStream({
            audio: {
              deviceId: { exact: micSelectAudio.value },
            },
            video: false,
          });
          // @ts-ignore
          window.stream = stream;
        } catch (error) {
          console.error("error in audio permission:", error);
          return false;
        }
        setUpRecording();
      };

      function pause() {
        recording = false;
        // context.suspend()
      }

      function resume() {
        recording = true;
        // context.resume();
      }

      // @ts-ignore
      document.querySelector('#pauseAudioRecord').onclick = () => {
        pause();
        setIsPaused(true);
      };

      // @ts-ignore
      document.querySelector('#resumeAudioRecord').onclick = () => {
        resume();
        setIsPaused(false);
      };
      // @ts-ignore
      document.querySelector('#record').onclick = (e) => {
        const playButton: any = document.getElementById('record');
        playButton.style.display = 'none';
        // const stopButton: any = document.getElementById('stop');
        // stopButton.style.display = 'inline-block';
        const settingsButton: any = document.getElementById('mySidenav');
        settingsButton.style.display = 'none';

        start();
        setRecording(true);
      };
      // @ts-ignore
      document.querySelector('#stop').onclick = (e) => {
        stop();
        isPaused && setIsPaused(false);
      };
      // @ts-ignore
      document.querySelector('#cancel').onclick = (e) => {
        stop(true);
        isPaused && setIsPaused(false);
      };
    })();

    return () => {
      // @ts-ignore
      window && window.stream && window.stream.getTracks().forEach(function(track: any) {
        track.stop();
      });
    }
    // const audio: any = document.getElementById('audio-player');

    // audio.onended = () => {
    //   setPlaying(false);
    // };

    // audio.addEventListener(
    //   'timeupdate',
    //   () => {
    //     if (audioPlayBackTimer.current) {
    //       audioPlayBackTimer.current.innerHTML = secondsToHms(audio.currentTime);
    //     }
    //   },
    //   false,
    // );
  }, []);

  const sendAudioToStore = async (blob: any) => {
    props.setLoading && props.setLoading(true);
    onClose && onClose(false);
    const fileName = 'file' + new Date().getTime();
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    /* Increase total totalVoiceClips count */
    const userQuota = profile.userQuota;
    if (
      auth &&
      auth.uid &&
      userQuota &&
      userQuota.totalVoiceClips &&
      userQuota.totalVoiceClips > 0
    ) {
      userQuota.totalVoiceClips = userQuota.totalVoiceClips - 1;
      if (blob.size) {
        userQuota.storageLimit = userQuota.storageLimit - blob.size / 1024;
      }
      profile.userQuota = userQuota;
      if (userQuota.storageLimit <= 0) {
        notification.error({
          message: 'Your current plan doesn’t include using this feature.',
        });
        return true;
      }
      const firestore = firebase.firestore();
      firestore
        .collection('users')
        .doc(auth.uid)
        .update({
          userQuota: userQuota,
        });
    }
    if (showPercentage) {
      const firebaseUploadObject: any = storageRef.put(blob);
      firebaseUploadObject.on(
        'state_changed',
        (snapshot: any) => {
          const progress: any = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercentage(progress.toFixed(2));
        },
        () => {
          notification.error({
            description: '',
            message: 'upload cancelled!',
          });
          setLoading(false);
          props.setLoading && props.setLoading(false);
          clearAudio();
        },
        () => {
          return firebaseUploadObject.snapshot.ref
            .getDownloadURL()
            .then(async (downloadURL: any) => {
              setLoading(false);
              props.setLoading && props.setLoading(false);
              // clearAudio();
              const audioURL = downloadURL;
              onSubmitWithPercentage && onSubmitWithPercentage(audioURL);
              onSubmit && onSubmit(audioURL);
            });
        },
      );
    } else {
      return storageRef.put(blob).then((audioData: any) => {
        return audioData.ref.getDownloadURL().then((url: string) => {
          setLoading(false);
          props.setLoading && props.setLoading(false);
          clearAudio();
          return url;
        });
      });
    }
  };

  const clearAudio = () => {
    setBlobObject({});
    setRecording(false);
    setIsPaused(false);
    const playButton: any = document.getElementById('record');
    if (playButton) {
      playButton.style.display = 'flex';
    }
    const ResumeButton: any = document.getElementById('resumeAudioRecord');
    ResumeButton.style.display = 'none';

    // const audioTag: any = document.getElementById('audio-player-container');
    // if (audioTag) {
    //   audioTag.style.display = 'none';
    // }
    const settingsButton: any = document.getElementById('mySidenav');
    if (settingsButton) {
      settingsButton.style.display = 'flex';
    }
    setTimer(0);
  };

  const TimerComp = () => {
    useEffect(() => {
      const timerId = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
      return () => {
        clearInterval(timerId);
      };
    }, []);
    return secondsToHms(maxRecordTime * 60 - timer);
  };

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div
          className='d-flex'
          style={{
            width: '268px',
            justifyContent: 'space-between',
            padding: '0 16px 0 16px',
            height: '50px',
          }}
        >
          {/* <audio id='audio-player' style={{ display: 'none' }} controls></audio> */}
          <div className='d-flex'>
            <MediaPermissionModal isOpen={isOpen} setIsOpen={setIsOpen}/>
            <div className={'c-p d-flex'} id='record' onClick={() => !isAllowPermissionRef.current && setIsOpen(true)}>
              <RecordIcon />
            </div>
            <div
              className='d-flex'
              id='resumeAudioRecord'
              style={{
                display: isPaused ? (isRecording ? 'flex' : 'none') : 'none',
              }}
            >
              <div className='c-p d-flex'>
                <RecordIcon />
              </div>
            </div>
            <div
              style={{
                display: isPaused ? 'none' : isRecording ? 'flex' : 'none',
                width: '24px',
              }}
              className={'c-p d-flex d-none'}
              id='pauseAudioRecord'
            >
              <PauseIcon />
            </div>
            {/* <div className='m-4 c-p' id='stop' style={{ display: 'none' }}>
                <div style={{ backgroundColor: 'red' }} className={`stop-icon`}></div>
              </div> */}

            <div className={`chat-window-timer-audio-recorder`}>
              {isRecording && !isPaused && <TimerComp />}
              {isRecording && isPaused && secondsToHms(maxRecordTime * 60 - timer)}
            </div>
            {!blobObject.blob && !isRecording && (
              <div className={`chat-window-timer-audio-recorder`}>
                {secondsToHms(maxRecordTime * 60)}
              </div>
            )}
          </div>

          <div style={{ display: isRecording && !isPaused ? 'contents' : 'none' }}>
            <canvas id={'chat-window-canvas-audio-recorder'}></canvas>
          </div>

          <div style={{ display: isRecording && isPaused ? 'contents' : 'none' }}>
            <Zbutton secondary btnColors={theme} style={{ marginRight: 10 }} id='cancel'>
              Cancel
            </Zbutton>
            <Zbutton btnColors={theme} id='stop' primary>
              Send
            </Zbutton>
          </div>

          {/* audio Player */}
          {(loading || props.loading) && (
            <div
              id={'chat-window-audio-player-container'}
              className={`chat-window-audio-tag-recorder`}
            >
              <Progress
                showInfo={false}
                percent={percentage}
                strokeColor={theme.primaryBtn.color1}
                status={percentage === 100 ? 'success' : 'active'}
              />
            </div>
          )}

          {/* sound source */}
          <div id='mySidenav'>
            <Icon className='chat-window-audio-icon' type='setting' />
            <select className={'select-audio-recorder'} id='visSelect'>
              <option value='sinewave'>Wave</option>
            </select>
            <select
              className='chat-window-select-audio-recorder'
              name=''
              id='micSelectAudio'
            ></select>
          </div>
          {/* <select className={'select-audio-recorder'} id='visSelect'>
              <option value='sinewave'>Wave</option>
            </select>
            <div
              style={{
                background: background ? background : '#C4C4C4',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              className={`${props.large ? 'audio-player-container-large' : ''} audio-player-container`}
            >
              <div
                id={'audio-player-container'}
                style={{ padding: '0px 10px 0px 0px' }}
                className={`audio-tag-recorder ${props.large ? '' : 'audio-tag-recorder-small'}`}
              >
                <audio id='audio-player' style={{ display: 'none' }} controls></audio>
                {loading || props.loading ? (
                  <>
                    <Progress
                      showInfo={false}
                      percent={percentage}
                      status={percentage === 100 ? 'success' : 'active'}
                    />
                    <div>Uploading...</div>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => clearAudio()}
                      shape={'circle'}
                      type='primary'
                      size={'large'}
                      icon={'delete'}
                      style={{ borderColor: 'red', backgroundColor: 'red', margin: 10 }}
                    />
                    <Button
                      onClick={() => setPlaying((playValue) => !playValue)}
                      className={`play-audio-button-large ${
                        props.large ? ` ${playing ? '' : 'play-icon'}` : ''
                      }`}
                      shape={'circle'}
                      type='primary'
                      size={'large'}
                      icon={playing ? 'pause' : ''}
                    >
                      {!playing && (
                        <img
                          className={props.large ? 'play-Icon-audio-record' : 'play-Icon-audio-record'}
                          src={PlayIcon}
                          alt='play'
                        />
                      )}
                    </Button>
                    <Button
                      onClick={async () => {
                        onClose && onClose(false);
                        const audio: any = document.getElementById('audio-player');
                        audio.src = null;
                        const audioUrl = await sendAudioToStore();
                        setPlaying(false);
                        onSubmit && onSubmit(audioUrl);
                      }}
                      shape={'circle'}
                      type='primary'
                      size={'large'}
                      icon={'like'}
                      style={{ borderColor: 'green', backgroundColor: 'green', margin: 10 }}
                    />
                    <div style={{ fontSize: '20px', fontWeight: 400 }} ref={audioPlayBackTimer} />
                  </>
                )}
              </div>
              <div className='chat-window-audio-recorder-container'>
                <div className={'d-flex'}>
                  <span className={'d-flex m-4 c-p'} id='record'>
                    <RecordIcon />
                  </span>
                  <span
                    className='m-4 c-p'
                    id='pauseAudioRecord'
                    style={{
                      display: isPaused ? 'none' : isRecording ? 'block' : 'none',
                    }}
                  >
                    {' '}
                    <PauseIcon />{' '}
                  </span>

                  <span
                    className='m-4 c-p'
                    id='resumeAudioRecord'
                    style={{
                      display: isPaused ? (isRecording ? 'block' : 'none') : 'none',
                    }}
                  >
                    <RecordIcon />
                  </span>

                  <span className='m-4 c-p' id='stop' style={{ display: 'none' }}>
                    <span style={{ backgroundColor: 'red' }} className={`stop-icon`}></span>
                  </span>

                  <span className={`timer-audio-recorder`} style={{ marginTop: '-5px' }}>
                    {isRecording && !isPaused && <TimerComp />}
                    {isRecording && isPaused && secondsToHms(maxRecordTime * 60 - timer)}
                  </span>
                  {!blobObject.blob && !isRecording && (
                    <span className={`timer-audio-recorder m-4`} style={{ marginTop: '-5px' }}>
                      {secondsToHms(maxRecordTime * 60)}
                    </span>
                  )}
                </div>
                <span id='mySidenav'>
                  {!micModal && (
                    <Icon
                      className='chat-window-audio-icon'
                      onClick={() => setMicModal((value) => !value)}
                      type='setting'
                    />
                  )}
                  <span
                    style={{
                      width: '170px',
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      display: micModal ? 'flex' : 'none',
                    }}
                  >
                    <Button
                      onClick={() => setMicModal((value) => !value)}
                      shape={'circle'}
                      type='primary'
                      size={'large'}
                      icon={'close'}
                      style={{ display: micModal ? 'block' : 'none' }}
                    />
                    <select
                      style={{ display: micModal ? 'block' : 'none', width: '110px' }}
                      name=''
                      id='micSelectAudio'
                    ></select>
                  </span>
                </span>
              </div>
              <span style={{ display: isRecording ? 'flex' : 'none' }}>
                <canvas
                  className={`${props.large ? 'canvas-audio-recorder-large' : ''}`}
                  id={'canvas-audio-recorder'}
                ></canvas>
              </span>
            </div> */}
        </div>
      )}
    </ColorContext.Consumer>
  );
};

export default RecordAudioFile;

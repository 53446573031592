import { Button, Form, Icon, List, Modal, Tooltip } from 'antd';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { trackAnalytics } from '../../utils';
import { CustomAlert } from '../shared/CustomAlert';
import EmailMultipleSelect from '../shared/EmailMultipleSelect';

import './style.css';

const TeamList: (props: any) => JSX.Element = (props: any) => {
  const [validEmails, setValidEmails] = useState([]);
  const [invitedEmails, setInvitedEmails] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const [invalidEmailsError, setInvalidEmailsError] = useState('');
  const [invited, setInvited] = useState('');
  const [deletedMember, setDeletedMember] = useState(false);

  useEffect(() => {
    const loginUserId = props.auth.uid;
    const firestore = firebase.firestore();
    firestore
      .collection('users')
      .where(firebase.firestore.FieldPath.documentId(), '==', loginUserId)
      .onSnapshot((usersDetails) => {
        if (usersDetails && usersDetails.docs && usersDetails.docs.length) {
          const usersDetailDocs = usersDetails.docs[0];
          const usersDetail = usersDetailDocs.data();
          if (usersDetail.emails) {
            setInvitedEmails(usersDetail.emails);
          }
        }
      });
  }, [props.auth]);

  const deleteMember = (email: string) => {
    const loginUserId = props.auth.uid;
    const firestore = firebase.firestore();
    firestore
      .collection('users')
      .where(firebase.firestore.FieldPath.documentId(), '==', loginUserId)
      .get()
      .then((usersDetails: any) => {
        if (usersDetails && usersDetails.docs && usersDetails.docs.length) {
          const usersDetailDocs = usersDetails.docs[0];
          const usersDetail = usersDetailDocs.data();
          const updateData: any = {};
          updateData.emails = [];
          const addedEmails: any = usersDetail.emails;
          const filterEmails = addedEmails.filter((data: any) => data.email !== email);
          updateData.emails = filterEmails;
          firestore
            .collection('users')
            .doc(usersDetailDocs.id)
            .set(updateData, { merge: true })
            .then(() => {
              // Remove admin id from meber user document.
              firestore
                .collection('users')
                .where('email', '==', email)
                .get()
                .then((memberUserDetail: any) => {
                  if (memberUserDetail && memberUserDetail.docs && memberUserDetail.docs.length) {
                    const memberUsersDetailDocs = memberUserDetail.docs[0];
                    const memberUserId = memberUsersDetailDocs.id;
                    const memberUserData = memberUsersDetailDocs.data();
                    const adminIds = memberUserData.adminIds || [];
                    const matchedAdminIndex = adminIds.indexOf(loginUserId);
                    if (matchedAdminIndex > -1) {
                      adminIds.splice(matchedAdminIndex, 1);
                      memberUserData.adminIds = adminIds;
                    }
                    firestore
                      .collection('users')
                      .doc(memberUserId)
                      .set(memberUserData, { merge: true });
                    setInvitedEmails(updateData.emails);
                    setDeletedMember(true);
                  }
                });
            });
        }
      });
  };

  const inviteMember = () => {
    const loginUserId = props.auth.uid;
    const firestore = firebase.firestore();
    const filterValidEmails: string[] = [];
    validEmails.forEach((validEmail: string) => {
      if (!invitedEmails.some((obj: any) => obj.email === validEmail)) {
        filterValidEmails.push(validEmail);
      }
    });
    firestore
      .collection('users')
      .where(firebase.firestore.FieldPath.documentId(), '==', loginUserId)
      .get()
      .then((usersDetails: any) => {
        if (usersDetails && usersDetails.docs && usersDetails.docs.length) {
          const usersDetailDocs = usersDetails.docs[0];
          const usersDetail = usersDetailDocs.data();
          const updateData: any = {};
          updateData.emails = [];
          let addedEmails: any = filterValidEmails;
          setInvited(
            filterValidEmails.length ? 'Member invited successfully!' : 'Member already invited!',
          );
          addedEmails = filterValidEmails.map((email: any) => {
            return {
              status: 'PENDING',
              email,
            };
          });
          updateData.emails = usersDetail.emails
            ? usersDetail.emails.concat(addedEmails)
            : addedEmails;
          setInvitedEmails(updateData.emails);
          props.form.setFieldsValue({
            ['invitees']: [],
          });
          setInvalidEmailsError('');
          return firestore
            .collection('users')
            .doc(usersDetailDocs.id)
            .set(updateData, { merge: true });
        }
      });
    trackAnalytics('Invite Members', 'Invite members clicked', '');
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setInvited('');
  //   }, 4000);
  // }, [invited]);

  useEffect(() => {
    setTimeout(() => {
      setDeletedMember(false);
    }, 4000);
  }, [deletedMember]);

  const showDeleteUser = (email: string, e: any) => {
    e.stopPropagation();
    Modal.confirm({
      content: '',
      title: 'Do you want to delete this user?',
      onOk() {
        deleteMember(email);
        trackAnalytics('Invite Members', 'Delete user confirm clicked', '');
      },
    });
  };

  const updateValidEmails = (emails: any) => {
    setValidEmails(emails);
    if (emails.length > 0) {
      trackAnalytics('Invite Members', 'Input value added', emails);
    }
  };

  const updateInvalidEmailsError = (error: any) => {
    setInvalidEmailsError(error);
  };

  const updateAllEmails = (addedEmails: any) => {
    setAllEmails(addedEmails || []);
  };

  return (
    <div className='team-member-container'>
      {deletedMember && (
        <p className='deleted-member-msg'>{'Team member has been deleted successfully!'}</p>
      )}
      <div className='team-member-list'>
        <List
          size='small'
          bordered
          dataSource={invitedEmails}
          renderItem={(item: any) => (
            <List.Item>
              {item.email}
              <div className='team-member-action-container'>
                {item.status === 'PENDING' && (
                  <Tooltip title='Pending'>
                    <Icon
                      className='team-member-action'
                      type='info-circle'
                      style={{ fontSize: '18px', color: '#039BE5' }}
                    />
                  </Tooltip>
                )}
                {item.status === 'ACTIVE' && (
                  <Tooltip title='Invited'>
                    <Icon
                      className='team-member-action'
                      type='check-circle'
                      theme='twoTone'
                      twoToneColor='#52c41a'
                      style={{ fontSize: '18px' }}
                    />
                  </Tooltip>
                )}
                <Tooltip title='Rejected'>
                  {item.status === 'REJECTED' && (
                    <Icon
                      className='team-member-action'
                      type='warning'
                      theme='twoTone'
                      twoToneColor='red'
                      style={{ fontSize: '18px' }}
                    />
                  )}
                </Tooltip>
                <Tooltip title='Delete'>
                  <Icon
                    onClick={(e) => showDeleteUser(item.email, e)}
                    className='team-member-action'
                    type='delete'
                    style={{ fontSize: '18px' }}
                  />
                </Tooltip>
              </div>
            </List.Item>
          )}
        />
      </div>
      <div className='new-member-container'>
        <h3>New member emails</h3>
        <EmailMultipleSelect
          allEmails={allEmails}
          updateAllEmails={updateAllEmails}
          memberEMailEditable={true}
          options={[]}
          type={'tags'}
          emails={validEmails}
          updateValidEmails={updateValidEmails}
          form={props.form}
          invalidEmailsError={invalidEmailsError}
          updateInvalidEmailsError={updateInvalidEmailsError}
          invitedEmails={invitedEmails}
        />
      </div>
      <div className='invite-member'>
        <Button
          disabled={invalidEmailsError || !validEmails.length ? true : false}
          type='primary'
          onClick={inviteMember}
        >
          Invite Members
        </Button>
      </div>
      {invited && (
        <div className='member-invited-message'>
          <CustomAlert message={invited} type='success' />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create()(TeamList));

import { Modal } from 'antd';
import firebaseApp from 'firebase/app';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  createProject as createProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { trackAnalytics } from '../../utils';
// import RecordAudio from '../projects/Chat/RecordAudio';
import AudioRecorderAddProject from '../recording/AudioRecorderAddProject';
import ByURLDialogContent from './ByURLDialogContent';
import './style.css';
import UploadDialogContent from './UploadDialogContent';

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
}

const AudioCanvasDialog = (props: {
  canvas: any;
  selectedWorkflowId?: string;
  selectedMasterProjectId?: string;
  history?: any;
  createProject: any;
  resetLoader: any;
  firebaseAuthToken: string;
  profile: any;
  isAudioModal: boolean;
  setIsAudioModal: any;
  propsLoading: boolean;
  selectedMasterProject: any;
  auth: any;
  selectedOrganizationData: any;
}) => {
  const {
    selectedWorkflowId,
    selectedMasterProjectId,
    history,
    createProject,
    firebaseAuthToken,
    profile,
    isAudioModal,
    setIsAudioModal,
    // propsLoading,
    selectedMasterProject,
    auth,
    selectedOrganizationData,
  } = props;
  const [selectedTab, setSelectedTab] = useState('Record');
  const createAudioTask = async (url: string) => {
    trackAnalytics('Canvas', 'Created a canvas', 'audio');
    const { email } = profile;
    const projectData = {
      title: 'Untitled task',
      project_url: url,
      member_emails: [email],
      project_tags: [],
      type: 'audio',
      workflowId: selectedWorkflowId,
      masterProjectId: selectedMasterProjectId,
      archived: 'no',
      // project_base64_image: blobURL,
      // thumbnail_url: thumbnailURL
    };
    let userFirebaseAuthToken = firebaseAuthToken;
    const fiebaseInstance: any = firebaseApp;
    if (
      !firebaseAuthToken &&
      fiebaseInstance &&
      fiebaseInstance.auth() &&
      fiebaseInstance.auth().currentUser
    ) {
      userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    createProject(projectData, userFirebaseAuthToken, selectedMasterProject).then(
      async (insertedId: any) => {
        if (insertedId) {
          const slackMessage = `${profile.fullName} created audio type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/audio/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );
          history.push(`/audio/${insertedId}`);
          setIsAudioModal(false);
        }
      },
    );
  };
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization && selectedOrganizationData) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    if (profile) {
      userQuota = profile.userQuota;
    }
  }
  return (
    <div>
      <Modal
        visible={isAudioModal}
        footer={null}
        closable={false}
        maskClosable={true}
        onOk={() => setIsAudioModal(false)}
        onCancel={() => setIsAudioModal(false)}
        bodyStyle={{ height: '300px', width: '500px' }}
      >
        <div className='dialogContainer'>
          <div className='dialogTabsContainer'>
            <div
              className={`dialogTab dialogTab1 ${selectedTab === 'Record' && 'dialogTabSelected'}`}
              onClick={() => setSelectedTab('Record')}
            >
              Record
            </div>
            <div
              className={`dialogTab dialogTab2 ${selectedTab === 'Upload' && 'dialogTabSelected'}`}
              onClick={() => setSelectedTab('Upload')}
            >
              Upload
            </div>
            <div
              className={`dialogTab dialogTab3 ${selectedTab === 'ByURL' && 'dialogTabSelected'}`}
              onClick={() => setSelectedTab('ByURL')}
            >
              By URL
            </div>
          </div>
          {selectedTab === 'Record' && isAudioModal ? (
            <AudioRecorderAddProject
              maxRecordTime={(userQuota && userQuota.recordVoiceClipTime) || 10}
              projectID={'dasdasd'}
              setLoading={() => null}
              onClose={() => setIsAudioModal(false)}
              showPercentage
              onSubmit={createAudioTask}
              background={'rgb(236, 245, 255)'}
              profile={profile}
              auth={auth}
              selectedOrganizationData={selectedOrganizationData}
            />
          ) : selectedTab === 'Upload' && isAudioModal ? (
            <UploadDialogContent
              ShowNewVideoCanvas={setIsAudioModal}
              projectType='audio'
              createAudioTask={createAudioTask}
              profile={profile}
              auth={auth}
              selectedOrganizationData={selectedOrganizationData}
            />
          ) : (
            isAudioModal && (
              <ByURLDialogContent
                type={'audio'}
                createAudioTask={createAudioTask}
                ShowNewVideoCanvas={setIsAudioModal}
              />
            )
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    canvas: state.canvas,
    propsLoading: state.project.loading,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetLoader: () => dispatch(resetAction()),
    createProject: (project: Project, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioCanvasDialog as any) as any;

import React from 'react';

export default () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 256 257'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMinYMin meet'
  >
    <defs>
      <linearGradient x1='49.998%' y1='.706%' x2='49.998%' y2='96.99%' id='a'>
        <stop stop-color='#86BBE5' offset='0%' />
        <stop stop-color='#1072BA' offset='100%' />
      </linearGradient>
    </defs>
    <path
      d='M127.789.035s75.32-3.38 115.253 72.328H121.38s-22.96-.74-42.573 27.114c-5.634 11.691-11.69 23.734-4.894 47.468-9.79-16.586-51.975-90.04-51.975-90.04S51.693 3.028 127.788.035z'
      fill='#EF3F36'
    />
    <path
      d='M239.133 192.229s-34.756 66.94-120.253 63.63c10.564-18.276 60.848-105.358 60.848-105.358s12.149-19.508-2.183-50.425c-7.29-10.74-14.72-21.973-38.664-27.96 19.262-.175 103.95 0 103.95 0s31.726 52.715-3.698 120.113z'
      fill='#FCD900'
    />
    <path
      d='M16.973 192.757s-40.601-63.56 5.035-135.958c10.529 18.276 60.813 105.358 60.813 105.358s10.846 20.283 44.756 23.31c12.924-.95 26.375-1.76 43.56-19.472C161.663 182.757 119.16 256 119.16 256s-61.552 1.127-102.188-63.243z'
      fill='#61BC5B'
    />
    <path
      d='M118.845 256.493l17.113-71.412s18.804-1.48 34.58-18.769c-9.79 17.22-51.693 90.181-51.693 90.181z'
      fill='#5AB055'
    />
    <path
      d='M70.462 129.056c0-31.48 25.53-57.01 57.01-57.01 31.48 0 57.01 25.53 57.01 57.01 0 31.481-25.53 57.01-57.01 57.01-31.48-.035-57.01-25.529-57.01-57.01z'
      fill='#FFF'
    />
    <path
      d='M80.004 129.056c0-26.198 21.234-47.467 47.468-47.467 26.198 0 47.467 21.234 47.467 47.467 0 26.199-21.233 47.468-47.467 47.468-26.199 0-47.468-21.269-47.468-47.468z'
      fill='url(#a)'
    />
    <path
      d='M242.795 72.152l-70.462 20.67s-10.634-15.6-33.487-20.67c19.825-.106 103.949 0 103.949 0z'
      fill='#EACA05'
    />
    <path
      d='M72.54 144.339c-9.896-17.149-50.602-87.434-50.602-87.434l52.186 51.622s-5.353 11.022-3.345 26.797l1.76 9.015z'
      fill='#DF3A32'
    />
  </svg>
);

import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { sendStream, stopSendingChunks } from '../../utils/realtimeStream';
const { REACT_APP_IS_AWS_REAL_TIME_SAVING = 'false' } = process.env;
const isAWSRealTimeSaving = REACT_APP_IS_AWS_REAL_TIME_SAVING.trim() === 'true' ? true : false;

interface ReactMediaRecorderRenderProps {
  error: string;
  muteAudio: () => void;
  unMuteAudio: () => void;
  startRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => void;
  mediaBlobUrl: null | string;
  //  mediaChunkBlob: null | Blob;
  status: StatusMessages;
  isAudioMuted: boolean;
  previewStream: MediaStream | null;
}

interface ReactMediaRecorderProps {
  render: (props: ReactMediaRecorderRenderProps) => ReactElement;
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
  screen?: boolean;
  onStop?: (blobUrl: string) => void;
  blobPropertyBag?: BlobPropertyBag;
  mediaRecorderOptions?: MediaRecorderOptions | null;
  fileName: string;
}

type StatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped';

enum RecorderErrors {
  AbortError = 'media_aborted',
  NotAllowedError = 'permission_denied',
  NotFoundError = 'no_specified_media_found',
  NotReadableError = 'media_in_use',
  OverconstrainedError = 'invalid_media_constraints',
  TypeError = 'no_constraints',
  NONE = '',
  NO_RECORDER = 'recorder_error',
}
let isStopped = false;
export const ReactMediaRecorder = ({
  render,
  audio = true,
  video = false,
  onStop = () => null,
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions = null,
  fileName = '',
}: ReactMediaRecorderProps) => {
  // ("call media recorder ")
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [status, setStatus] = useState<StatusMessages>('idle');
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  // const [mediaChunkBlob, setMediaChunkBlob] = useState<Blob | null>(null);
  const [error, setError] = useState<keyof typeof RecorderErrors>('NONE');

  useEffect(() => {
    return () => {
       // @ts-ignore
      if(window.stream){
       // @ts-ignore
         window.stream.getTracks().forEach((track: any) => {
          track.stop();
        });

          // @ts-ignore
         window.stream.getAudioTracks().forEach(function (track: any) {
          track.stop();
        });

         // @ts-ignore
         window.stream.getVideoTracks().forEach((track: any) => {
          track.stop();
        });
         // @ts-ignore
        window.stream = null;
      }
    };
  }, []);

  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');
    const requiredMedia: MediaStreamConstraints = {
      audio: typeof audio === 'boolean' ? !!audio : audio,
      video: typeof video === 'boolean' ? !!video : video,
    };
    try {
      if (screen) {
        // const screenConstraints = {
        //     audio: {
        //         sampleRate: 22050,
        //         echoCancellation: true
        //     },
        //     video: {
        //         frameRate: { ideal: 15 }
        //     }
        // };
        // @ts-ignore
        // const stream: any = (await window.navigator.mediaDevices.getDisplayMedia(screenConstraints)) as MediaStream;
        const stream = (await window.navigator.mediaDevices.getDisplayMedia({
          video: video || true,
        })) as MediaStream;
        if (audio) {
          const audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio,
          });
          audioStream.getAudioTracks().forEach((audioTrack) => stream.addTrack(audioTrack));
        }
        mediaStream.current = stream;
         // @ts-ignore
         window.stream = stream;
      } else {
        const stream: any = await window.navigator.mediaDevices.getUserMedia(requiredMedia);
        mediaStream.current = stream;
         // @ts-ignore
         window.stream = stream;
      }
      setStatus('idle');
    } catch (error) {
      setError(error.name);
      setStatus('idle');
    }
  }, [audio, video, screen]);

  useEffect(() => {
    if (!window.MediaRecorder) {
      throw new Error('Unsupported Browser');
    }

    if (screen) {
      // @ts-ignore
      if (!window.navigator.mediaDevices.getDisplayMedia) {
        throw new Error("This browser doesn't support screen capturing");
      }
    }

    const checkConstraints = (mediaType: MediaTrackConstraints) => {
      const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
      const unSupportedConstraints = Object.keys(mediaType).filter(
        (constraint) => !(supportedMediaConstraints as { [key: string]: any })[constraint],
      );

      if (unSupportedConstraints.length > 0) {
        // console.error(
        //   `The constraints ${unSupportedConstraints.join(
        //     ","
        //   )} doesn't support on this browser. Please check your ReactMediaRecorder component.`
        // );
      }
    };

    if (typeof audio === 'object') {
      checkConstraints(audio);
    }
    if (typeof video === 'object') {
      checkConstraints(video);
    }

    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        // console.error(
        //   `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
        // );
      }
    }

    async function loadStream() {
      await getMediaStream();
    }

    if (!mediaStream.current) {
      loadStream();
    }
  }, [audio, screen, video, getMediaStream, mediaRecorderOptions]);

  // Media Recorder Handlers

  const startRecording = async () => {
    setError('NONE');
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      mediaRecorder.current = new MediaRecorder(mediaStream.current);
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onerror = () => {
        setError('NO_RECORDER');
        setStatus('idle');
      };
      mediaRecorder.current.start(250);
      setStatus('recording');
    }
  };

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
    console.log("isAWSRealTimeSaving==",isAWSRealTimeSaving,"isStopped:", isStopped,  isAWSRealTimeSaving && !isStopped)
    isAWSRealTimeSaving && !isStopped && sendStream(data, fileName);
  };

  const onRecordingStop = () => {
    const blobProperty: BlobPropertyBag =
      blobPropertyBag || video ? { type: 'video/mp4' } : { type: 'audio/wav' };
    const blob = new Blob(mediaChunks.current, blobProperty);
    const url = URL.createObjectURL(blob);
    setStatus('stopped');
    setMediaBlobUrl(url);
    onStop(url);
  };

  const muteAudio = (mute: boolean) => {
    setIsAudioMuted(mute);
    if (mediaStream.current) {
      mediaStream.current.getAudioTracks().forEach((audioTrack) => (audioTrack.enabled = !mute));
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = () => {
    isStopped = true;
    // ("call stop recording")
    if (mediaRecorder.current) {
      setStatus('stopping');
      if (isAWSRealTimeSaving) {
        setTimeout(() => {
          // ("call stop recording = fileName = ", fileName);
          // if (mediaRecorder && mediaRecorder.current) {
          //   ("call stopped")
          // } else {
          //   // ("call not stopped")
          // }
          mediaRecorder && mediaRecorder.current && mediaRecorder.current.stop();
          stopSendingChunks(fileName);
          isStopped = false;
        }, 1000);
      } else {
        mediaRecorder.current.stop();
      }
    }
  };

  return render({
    error: RecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    mediaBlobUrl,
    // mediaChunkBlob,
    status,
    isAudioMuted,
    previewStream: mediaStream.current
      ? new MediaStream(mediaStream.current.getVideoTracks())
      : null,
  });
};

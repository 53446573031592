const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;

export const slackConnectAction = (firebaseToken: string) => {
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/slackConnect`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return { error: false, message: `Your slack account has been connected successfully.` };
      } else {
        return { error: true, message: data.message };
      }
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

export const sendMessageToSlack = (
  firebaseToken: string,
  message: string,
  projectId: string,
  projectURL: string,
  initials: string,
  notificationId: string,
) => {
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/sendMessageToSlack`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ message, projectId, projectURL, initials, notificationId }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return { error: false, message: `Your trello account has been connected successfully.` };
      } else {
        return { error: true, message: data.message };
      }
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

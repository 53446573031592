import { Button, Input, Tooltip } from 'antd';
import { QuestionIcon } from 'components/svg';
import React, { useState } from 'react';
import './style.css';

const ByURLDialogContent = ({
  createVideoTask,
  createImageTask,
  //  createPdfToImage,
  uploadPdfByURL,
  ShowNewVideoCanvas,
  type,
  loading,
  createAudioTask,
}: any) => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const handleSubmit = () => {
    if (url.trim()) {
      if (type === 'image') {
        createImageTask(url.trim(), '', '');
      } else if (type === 'video') {
        createVideoTask(url.trim(), 'videoByURL');
      } else if (type === 'pdf') {
        uploadPdfByURL(url.trim());
        //  createPdfToImage(url.trim());
      } else if (type === 'audio') {
        createAudioTask(url.trim());
      }
    } else {
      const taskType = type[0].toUpperCase() + type.slice(1);
      setError(`${taskType} URL is required.`);
    }
  };

  return (
    <>
      <div className='dialogBodyContainer'>
        <div className='byURLBody'>
          <div>
            <Input
              value={url}
              onChange={(e: any) => {
                setUrl(e.target.value);
                setError('');
              }}
              placeholder={`Paste any ${type} URL`}
              className='dialogByURLInput'
            />
            <span className='error'>{error}</span>
          </div>
          <Tooltip
            placement='top'
            title='Youtube, SoundCloud, Facebook, Vimeo, Twitch, Streamable, Wistia, Dailymotion, Mixcloud, Vidyard or your own hosted video'
          >
            {type === 'video' && (
              <div className='byURLQuestionIcon'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <QuestionIcon />
                </div>
                <div style={{ marginLeft: '10px' }}>What video websites do we support?</div>
              </div>
            )}
          </Tooltip>
        </div>
      </div>
      <div className='dialogFooter'>
        <div className='dialogCTAsContainer' style={{ justifyContent: 'flex-end' }}>
          <div>
            <Button
              className='cancelButton'
              onClick={() => {
                ShowNewVideoCanvas && ShowNewVideoCanvas(false);
              }}
            >
              Cancel
            </Button>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <Button
              loading={loading}
              disabled={loading}
              className='uploadButton'
              onClick={handleSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ByURLDialogContent;

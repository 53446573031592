import React from 'react';

export default () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.3125 1.875C1.26277 1.875 1.21508 1.89475 1.17992 1.92992C1.14475 1.96508 1.125 2.01277 1.125 2.0625V3.1875C1.125 3.291 1.209 3.375 1.3125 3.375H10.6875C10.7372 3.375 10.7849 3.35525 10.8201 3.32008C10.8552 3.28492 10.875 3.23723 10.875 3.1875V2.0625C10.875 2.01277 10.8552 1.96508 10.8201 1.92992C10.7849 1.89475 10.7372 1.875 10.6875 1.875H1.3125ZM0 2.0625C0 1.338 0.588 0.75 1.3125 0.75H10.6875C11.412 0.75 12 1.338 12 2.0625V3.1875C12 3.5356 11.8617 3.86944 11.6156 4.11558C11.3694 4.36172 11.0356 4.5 10.6875 4.5H1.3125C0.964403 4.5 0.630564 4.36172 0.384422 4.11558C0.138281 3.86944 0 3.5356 0 3.1875L0 2.0625ZM1.3125 5.25C1.46168 5.25 1.60476 5.30926 1.71025 5.41475C1.81574 5.52024 1.875 5.66332 1.875 5.8125V9.9375C1.875 10.041 1.959 10.125 2.0625 10.125H9.9375C9.98723 10.125 10.0349 10.1052 10.0701 10.0701C10.1052 10.0349 10.125 9.98723 10.125 9.9375V5.8125C10.125 5.66332 10.1843 5.52024 10.2898 5.41475C10.3952 5.30926 10.5383 5.25 10.6875 5.25C10.8367 5.25 10.9798 5.30926 11.0852 5.41475C11.1907 5.52024 11.25 5.66332 11.25 5.8125V9.9375C11.25 10.2856 11.1117 10.6194 10.8656 10.8656C10.6194 11.1117 10.2856 11.25 9.9375 11.25H2.0625C1.7144 11.25 1.38056 11.1117 1.13442 10.8656C0.888281 10.6194 0.75 10.2856 0.75 9.9375V5.8125C0.75 5.66332 0.809263 5.52024 0.914752 5.41475C1.02024 5.30926 1.16332 5.25 1.3125 5.25ZM4.6875 6C4.53832 6 4.39524 6.05926 4.28975 6.16475C4.18426 6.27024 4.125 6.41332 4.125 6.5625C4.125 6.71168 4.18426 6.85476 4.28975 6.96025C4.39524 7.06574 4.53832 7.125 4.6875 7.125H7.3125C7.46168 7.125 7.60476 7.06574 7.71025 6.96025C7.81574 6.85476 7.875 6.71168 7.875 6.5625C7.875 6.41332 7.81574 6.27024 7.71025 6.16475C7.60476 6.05926 7.46168 6 7.3125 6H4.6875Z'
      fill='#333333'
    />
  </svg>
);

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.18182 13.7019V14.4746H4.63636V16.0201H12.3636V14.4746H10.8182V13.7019H15.4545C16.3081 13.7019 17 13.01 17 12.1564V2.11098C17 1.25744 16.3081 0.565521 15.4545 0.565521H1.54545C0.691923 0.565521 0 1.25744 0 2.11098V12.1564C0 13.01 0.691923 13.7019 1.54545 13.7019H6.18182ZM15.4545 12.1564H1.54544V2.11097H15.4545V12.1564Z'
      fill={color || '#001DAD'}
    />
  </svg>
);

export default Icon;

import firebase from 'firebase/app';
const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;

export const cancelSubscription = async (firebaseToken: string, postData: any) => {
  const fiebaseInstance: any = firebase;
  const firebaseAuthToken = firebaseToken
    ? firebaseToken
    : await fiebaseInstance.auth().currentUser.getIdToken(true);
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/cancelSubscription`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseAuthToken}`,
    },
    body: JSON.stringify(postData),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return { error: false, message: data.message };
      } else {
        return { error: true, message: data.message };
      }
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

export const updateSubscription = async (firebaseToken: string, postData: any) => {
  const fiebaseInstance: any = firebase;
  const firebaseAuthToken = firebaseToken
    ? firebaseToken
    : await fiebaseInstance.auth().currentUser.getIdToken(true);
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/updateSubscription`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseAuthToken}`,
    },
    body: JSON.stringify(postData),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status) {
        return { error: false, message: data.message };
      } else {
        return { error: true, message: data.message };
      }
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

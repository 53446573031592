import { Button, Icon, Tooltip } from 'antd';
import React from 'react';
// import ReactDOM from 'react-dom';
import { secondsToHms } from '../../utils/index';

class RenderVideosBlob extends React.Component<any, any> {
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (JSON.stringify(nextProps.videos) !== JSON.stringify(prevState.videos)) {
      // const video: any = document.getElementById('videoPlayer' + nextProps.id);
      // nextProps.autoPlay && video.play();
      return {
        src: nextProps.videos[0],
        videos: nextProps.videos,
      };
    }
    return null;
  }
  video: any;
  mouseIsOver: any = false;
  constructor(props: any) {
    super(props);
    this.state = {
      index: 0,
      src: this.props.videos[0],
      playedOnce: false,
      muted: !this.props.unMute,
      videos: this.props.videos,
    };
  }

  componentDidMount() {
    this.video = document.getElementById('videoPlayer' + this.props.id);
    // this.video = document.getElementById('videoPlayer' + this.props.id);
    this.props.autoPlay && this.video.play();
    this.video.ontimeupdate = this.onTimeUpdate;
    this.video.addEventListener('ended', () => {
      const playIcon: any = document.getElementById('playIcon' + this.props.id);
      const pauseIcon: any = document.getElementById('pauseIcon' + this.props.id);
      if (pauseIcon && playIcon) {
        pauseIcon.style.display = 'none';
        playIcon.style.display = 'flex';
      }
      if (this.state.index < this.props.videos.length - 1) {
        const nextIndex = this.state.index + 1;
        this.setState(
          {
            index: nextIndex,
            src: this.props.videos[nextIndex],
          },
          () => {
            this.video.play();
          },
        );
        pauseIcon.style.display = 'flex';
        playIcon.style.display = 'none';
      }
    });
  }

  // componentDidUpdate() {
  //   this.video = document.getElementById('videoPlayer' + this.props.id);
  //   this.props.autoPlay && this.video.play();
  //   this.video.ontimeupdate = this.onTimeUpdate;
  // }

  onTimeUpdate = () => {
    const text: any = document.getElementById('currentTime' + this.props.id);
    const text2: any = document.getElementById('totalTime' + this.props.id);
    if (text) {
      text.innerHTML = secondsToHms(this.video.currentTime);
      text2.innerHTML =
        this.video.duration && this.video.duration !== Infinity
          ? secondsToHms(this.video.duration)
          : '0:00';
    }
  };
  playPause = () => {
    const playIcon: any = document.getElementById('playIcon' + this.props.id);
    const pauseIcon: any = document.getElementById('pauseIcon' + this.props.id);
    if (this.video.paused) {
      this.video.play();
      playIcon.style.display = 'none';
      pauseIcon.style.display = 'flex';
    } else {
      this.video.pause();
      pauseIcon.style.display = 'none';
      playIcon.style.display = 'flex';
    }
  };
  backward = () => {
    this.video.currentTime -= 5;
  };
  forward = () => {
    this.video.currentTime += 5;
  };
  fullScreen = () => {
    if (this.video.requestFullscreen) {
      this.video.requestFullscreen();
    } else if (this.video.mozRequestFullScreen) {
      this.video.mozRequestFullScreen();
    } else if (this.video.webkitRequestFullscreen) {
      this.video.webkitRequestFullscreen();
    } else if (this.video.msRequestFullscreen) {
      this.video.msRequestFullscreen();
    }
  };
  muteOrUnmute = () => {
    const soundButton: any = document.getElementById('soundButton' + this.props.id);

    soundButton.style.color = this.video.muted ? '#039BE5' : 'grey';
    this.video.muted = !this.video.muted;
  };
  mouseStatus = (value: boolean) => (this.mouseIsOver = value);
  // onMouseLeave = (e: any) => {
  //   e.stopPropagation();
  // };
  render() {
    const { id, className, showAlways, showPlayIcon, noHeader } = this.props;
    const { playedOnce, src, muted } = this.state;
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <video
          onClick={this.playPause}
          className={className}
          onMouseMove={() => {
            if (!showAlways && showPlayIcon && playedOnce) {
              const videoControls: any = document.getElementById('videoControls' + this.props.id);
              if (videoControls.style.display === 'none') {
                videoControls.style.display = 'flex';
                setTimeout(() => {
                  if (!this.mouseIsOver) {
                    videoControls.style.display = 'none';
                  }
                }, 3000);
              }
            }
          }}
          id={'videoPlayer' + id}
          style={{
            backgroundColor: '#444F60',
            width: '100%',
            height: showAlways ? (noHeader ? 'calc(100vh - 67px)' : 'calc(100vh - 134px)') : '100%',
          }}
          src={src}
          playsinline
          muted={muted}
        />
        {showPlayIcon && src && (
          <div
            id={'playIconCentered' + id}
            onClick={(event: any) => event.stopPropagation()}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: '0px',
            }}
          >
            <Button
              onClick={() => {
                this.playPause();
                this.setState({
                  playedOnce: true,
                });
                const playIconCentered: any = document.getElementById(
                  'playIconCentered' + this.props.id,
                );
                playIconCentered.style.display = 'none';
              }}
              style={{
                fontSize: '40px',
                display: this.props.autoPlay ? 'none' : 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                position: 'absolute',
                top: '50%',
                marginTop: '-20px',
                right: 0,
                left: '50%',
                marginLeft: '-15px',
                bottom: 0,
              }}
              type='link'
              shape='circle'
              icon='caret-right'
            />
          </div>
        )}

        <div
          onMouseOver={() => {
            if (!showAlways && showPlayIcon && playedOnce) {
              const videoControls: any = document.getElementById('videoControls' + this.props.id);
              videoControls.style.display = 'flex';
              this.mouseStatus(true);
            }
          }}
          onMouseOut={(event: any) => {
            const videoControls: any = document.getElementById('videoControls' + this.props.id);

            const e = event.toElement || event.relatedTarget;
            if ((e && e.parentNode === videoControls) || e === videoControls) {
              return;
            }
            if (!showAlways && showPlayIcon && playedOnce) {
              setTimeout(() => {
                if (!this.mouseIsOver) {
                  videoControls.style.display = 'none';
                }
              }, 3000);
              this.mouseStatus(false);
            }
          }}
          id={'videoControls' + id}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            display: showAlways ? 'flex' : 'none',
            padding: '7px',
            justifyContent: 'center',
            backgroundColor: showAlways ? 'white' : 'rgba(255, 255, 255, 0.8)',
            width: '100%',
          }}
        >
          <div
            // onMouseOut={this.onMouseLeave}
            style={{
              width: '300px',
              height: showAlways ? '60px' : '30px',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ position: 'absolute', left: 0, fontSize: 14 }}>
              <span id={'currentTime' + id} />/<span id={'totalTime' + id} />
            </div>
            <Tooltip title='Rewind 5 seconds' placement='top'>
              <Button
                onClick={this.backward}
                style={{ fontSize: 18 }}
                type='link'
                shape='circle'
                icon='undo'
              />
            </Tooltip>
            <Button
              onClick={this.playPause}
              id={'playIcon' + id}
              style={{
                fontSize: '30px',
                display: this.props.autoPlay ? 'none' : 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
              type='link'
              shape='circle'
              icon='caret-right'
            />
            <Button
              onClick={this.playPause}
              id={'pauseIcon' + id}
              style={{
                fontSize: '30px',
                display: this.props.autoPlay ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
              type='link'
              shape='circle'
              icon='pause'
            />
            <Tooltip title='Forward 5 seconds' placement='top'>
              <Button
                onClick={this.forward}
                style={{ fontSize: 18 }}
                type='link'
                shape='circle'
                icon='redo'
              />
            </Tooltip>
            <div style={{ position: 'absolute', right: 0 }}>
              <Button
                onClick={this.fullScreen}
                style={{ fontSize: 15 }}
                type='link'
                shape='circle'
                icon='fullscreen'
              />
              <Button
                onClick={this.muteOrUnmute}
                id={'soundButton' + id}
                style={{ fontSize: 15, color: this.state.muted ? 'grey' : '#039BE5' }}
                type='link'
                shape='circle'
                icon='sound'
              />
            </div>
          </div>
        </div>

        {noHeader && (
          <Tooltip title='Open in a new window' placement='bottomRight'>
            <div
              id={'videoControls' + id}
              style={{
                position: 'absolute',
                backgroundColor: 'rgba(255,255,255,0.1)',
                right: 10,
                padding: '2px',
                zIndex: 11,
                top: 10,
                fontSize: 20,
              }}
            >
              <Icon
                onClick={() => {
                  window.open(window.location.href.replace('view=true', ''), '_blank');
                }}
                type='link'
                style={{ color: 'white' }}
              />
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default RenderVideosBlob;

import React from 'react';

export default () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M2.33342 0.666656H15.6668C16.5872 0.666656 17.3334 1.41285 17.3334 2.33332V15.6667C17.3334 16.5871 16.5872 17.3333 15.6668 17.3333H2.33342C1.41294 17.3333 0.666748 16.5871 0.666748 15.6667V2.33332C0.666748 1.41285 1.41294 0.666656 2.33342 0.666656ZM2.33342 2.33333V15.6667H15.6667V2.33333H2.33342ZM9.83342 6.49999V11.5H10.6667V13.1667H7.33342V11.5H8.16675V6.49999H6.50008V7.33332H4.83342V4.83332H13.1667V7.33332H11.5001V6.49999H9.83342Z'
      fill='#4F4F4F'
    />
  </svg>
);

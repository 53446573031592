import React from 'react';

export const CanvasCreateIcon = () => (
  <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16.5' cy='16' r='16' fill='#001DAD' />
    <line
      x1='10.5'
      y1='16'
      x2='22.5'
      y2='16'
      stroke='white'
      stroke-width='2'
      stroke-linecap='round'
    />
    <line
      x1='16.5'
      y1='22'
      x2='16.5'
      y2='10'
      stroke='white'
      stroke-width='2'
      stroke-linecap='round'
    />
  </svg>
);

export const MenuIcon = () => (
  <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.5 0H4.21429V28H1.5V0ZM11 5.6H20.5V8.4H11V5.6ZM11 11.2H20.5V14H11V11.2Z'
      fill='#333333'
    />
    <path
      d='M24.5667 0H17.2333H17.1409H5.5V28H17.1424H17.2333H24.5667C26.1844 28 27.5 26.7442 27.5 25.2V2.8C27.5 1.2558 26.1844 0 24.5667 0ZM24.5667 25.2H17.2333H17.1409H8.43333V2.8H17.1424H17.2333H24.5667V25.2Z'
      fill='#333333'
    />
  </svg>
);

export const ChatIcon = () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.875 2.70014H24.125C24.5726 2.70014 25.0018 2.87926 25.3182 3.19809C25.6347 3.51693 25.8125 3.94937 25.8125 4.40027V24.098L22.4375 20.6978C21.8047 20.0601 20.9464 19.7017 20.0514 19.7015H3.875C3.42745 19.7015 2.99822 19.5224 2.68176 19.2035C2.36529 18.8847 2.1875 18.4523 2.1875 18.0014V4.40027C2.1875 3.94937 2.36529 3.51693 2.68176 3.19809C2.99822 2.87926 3.42745 2.70014 3.875 2.70014ZM24.125 1C25.0201 1 25.8785 1.35824 26.5115 1.99592C27.1444 2.63359 27.5 3.49846 27.5 4.40027V26.1501C27.5 26.3183 27.4504 26.4827 27.3576 26.6225C27.2648 26.7624 27.1329 26.8713 26.9786 26.9355C26.8243 26.9998 26.6545 27.0165 26.4908 26.9835C26.3271 26.9505 26.1768 26.8693 26.0589 26.7503L21.2444 21.8998C20.928 21.5809 20.4989 21.4017 20.0514 21.4016H3.875C2.97989 21.4016 2.12145 21.0434 1.48851 20.4057C0.855579 19.768 0.5 18.9032 0.5 18.0014V4.40027C0.5 3.49846 0.855579 2.63359 1.48851 1.99592C2.12145 1.35824 2.97989 1 3.875 1L24.125 1Z'
      fill='#333333'
      stroke='#333333'
    />
    <path
      d='M9.5 10.5C9.5 10.9641 9.31562 11.4092 8.98744 11.7374C8.65925 12.0656 8.21413 12.25 7.75 12.25C7.28587 12.25 6.84075 12.0656 6.51256 11.7374C6.18437 11.4092 6 10.9641 6 10.5C6 10.0359 6.18437 9.59075 6.51256 9.26256C6.84075 8.93437 7.28587 8.75 7.75 8.75C8.21413 8.75 8.65925 8.93437 8.98744 9.26256C9.31562 9.59075 9.5 10.0359 9.5 10.5ZM15.5 10.5C15.5 10.9641 15.3156 11.4092 14.9874 11.7374C14.6592 12.0656 14.2141 12.25 13.75 12.25C13.2859 12.25 12.8408 12.0656 12.5126 11.7374C12.1844 11.4092 12 10.9641 12 10.5C12 10.0359 12.1844 9.59075 12.5126 9.26256C12.8408 8.93437 13.2859 8.75 13.75 8.75C14.2141 8.75 14.6592 8.93437 14.9874 9.26256C15.3156 9.59075 15.5 10.0359 15.5 10.5ZM21.5 10.5C21.5 10.9641 21.3156 11.4092 20.9874 11.7374C20.6592 12.0656 20.2141 12.25 19.75 12.25C19.2859 12.25 18.8408 12.0656 18.5126 11.7374C18.1844 11.4092 18 10.9641 18 10.5C18 10.0359 18.1844 9.59075 18.5126 9.26256C18.8408 8.93437 19.2859 8.75 19.75 8.75C20.2141 8.75 20.6592 8.93437 20.9874 9.26256C21.3156 9.59075 21.5 10.0359 21.5 10.5Z'
      fill='#333333'
    />
  </svg>
);

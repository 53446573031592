import { Alert, Card, Col, Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form'; // tslint:disable-line
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import Button from '../../primitives/SignUpButtons';
import {
  clearAuthState as clearAuthAction,
  forgotPassword as forgotPasswordAction,
} from '../../store/actions/authActions';
import { IAuthAction, Type } from '../../types/authTypes';
import { ButtonEvent } from '../../types/syntheticEventsTypes';
import { trackAnalytics } from '../../utils';
import { randomImage } from '../../utils/dummyData';
import Spinner from '../shared/spinner';
import { Logo } from '../svg/Logo';
import './auth.css';

const urlParams = new URLSearchParams(window.location.search);
const channelId = urlParams.get('channelId') || '';
const slackUserId = urlParams.get('slackUserId') || '';
const recordingType = urlParams.get('type') || 'audio';

const FormItem = Form.Item;
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      offset: 4,
      span: 16,
    },
    xs: {
      offset: 0,
      span: 24,
    },
  },
};

interface ICreds {
  email: string;
  password: string;
}

interface IProps extends FormComponentProps {
  children?: JSX.Element | JSX.Element[];
  forgotPassword: (email: string) => void;
  clearAuthState: () => void;
  authError: string;
  status: string;
  image: string;
  auth: {
    isLoaded: boolean;
    uid: string;
  };
}

const ResetPassword: React.SFC<IProps> = ({
  form,
  status,
  forgotPassword,
  authError,
  auth,
  clearAuthState,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [image, setImage] = useState<any>('');
  // will uncomment on adding functionality
  useEffect(() => {
    status === Type.StatusRunning ? setLoading(true) : setLoading(false);
    if (status === Type.forgotPasswordError) {
      // notification.error({
      //   description: authError,
      //   message: 'Send Reset Email Failed',
      // });

      setErrorMessage(authError);
      return () => clearAuthState();
    }

    setImage(randomImage);

    if (status === Type.forgotPasswordSuccess) {
      // notification.success({
      //   description: 'Link has been sent to you mailbox',
      //   message: 'Reset Email Sent',
      // });
      setSuccess(true);
      return () => clearAuthState();
    }
  }, [authError, status, clearAuthState]);

  const handleSubmit = (e: ButtonEvent) => {
    e.preventDefault();
    form.validateFields((err: any, values: ICreds) => {
      if (!err) {
        trackAnalytics('User', 'Forgot password clicked');
        forgotPassword(values.email);
      }
    });
  };
  if (auth.isLoaded && auth.uid) {
    if (channelId) {
      return (
        <Redirect
          to={{
            pathname: `/slack/${recordingType}`,
            search: `?channelId=${channelId}&slackUserId=${slackUserId}`,
          }}
        />
      );
    } else {
      return <Redirect to='/' />;
    }
  }
  if (auth.isLoaded && !auth.uid) {
    return (
      <Row>
        <Col
          className='hidden-on-mobile login-image'
          span={12}
          style={{ backgroundImage: `url(${process.env.REACT_APP_WEBSITE_BASE_URL}/${image})` }}
        ></Col>
        <Col className='mobile-view auth-scroll-form' style={{ overflow: 'auto' }} span={12}>
          <Card style={{ height: '100vh', border: '0px' }}>
            <div style={{ maxWidth: 500, margin: 'auto', overflow: 'auto' }}>
              <FormItem {...tailFormItemLayout} className='text-left'>
                <a href={'https://zooc.io'}>
                  <Logo />
                </a>
              </FormItem>
              <FormItem {...tailFormItemLayout} className='text-left'>
                <div className={'link-create-account'}>
                  <p>Reset Password</p>
                </div>
              </FormItem>
              <Form onSubmit={handleSubmit}>
                <FormItem {...tailFormItemLayout}>
                  {form.getFieldDecorator('email', {
                    rules: [
                      {
                        message: 'Email Please!',
                        required: true,
                      },
                    ],
                  })(<Input className='ant-input' placeholder='Email' type='text' />)}
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                  <Button loading={loading} htmlType='submit' primary>
                    Send Reset Email
                  </Button>
                </FormItem>

                <FormItem {...tailFormItemLayout}>
                  <Link
                    style={{ marginLeft: 5, color: '#001DAD' }}
                    onClick={() => trackAnalytics('User', 'SignIn to an existing account clicked')}
                    to={`/signIn${
                      channelId
                        ? `?channelId=${channelId}&type=${recordingType}&slackUserId=${slackUserId}`
                        : ''
                    }`}
                  >
                    SignIn to an existing account?
                  </Link>
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                  {errorMessage && (
                    <Alert
                      className='text-left'
                      message='Send Reset Email Failed'
                      description={errorMessage}
                      type='error'
                      closable
                      showIcon
                      onClose={() => setErrorMessage('')}
                    />
                  )}
                  {success && (
                    <Alert
                      className='text-left'
                      message='Link has been sent to you mailbox'
                      description={'Reset Email Sent'}
                      type='success'
                      closable
                      showIcon
                      onClose={() => setSuccess(false)}
                    />
                  )}
                </FormItem>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
  return <Spinner />;
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any };
}) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    status: state.auth.status,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    clearAuthState: () => dispatch(clearAuthAction()),
    forgotPassword: (email: string) => dispatch(forgotPasswordAction(email)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create<IProps>()(withRouter(ResetPassword)));

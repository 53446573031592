import { Button } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { mobileCheck } from '../../utils';
import Spinner from '../shared/spinner';

const isMobile = mobileCheck();

export interface IUploadFileModelProps {
  loader: boolean;
  handleFileUpload: any;
  projectType?: any;
}

const ImageDragDrop: (props: IUploadFileModelProps) => JSX.Element = ({
  loader,
  handleFileUpload,
  projectType,
}) => {
  const [sendingFile, setSendingFile] = React.useState(false);

  useEffect(() => {
    setSendingFile(loader);
  }, [loader]);

  const onDrop = useCallback((files) => {
    handleFileUpload(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      projectType === 'image'
        ? 'image/*'
        : projectType === 'pdf'
        ? 'application/pdf'
        : projectType === 'video'
        ? 'video/*'
        : projectType === 'audio'
        ? 'audio/*'
        : undefined,
  });

  return (
    <React.Fragment>
      <div style={{ width: '100%' }}>
        <div className='imagesSection'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className='dialogUploadDropZone'>
                <div style={{ fontWeight: 'bold' }}>Drop the file here ...</div>
              </div>
            ) : sendingFile ? (
              <div>
                <div className='form-container'>
                  <Spinner />
                </div>
              </div>
            ) : (
              <div className='dialogUploadDropZone cursor-pointer'>
                <div style={{ fontWeight: 'bold' }}>
                  {!isMobile && `Drop your ${projectType} file here, or`}
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='browse-form-button'
                    block={true}
                  >
                    Browse
                  </Button>
                </div>
                <div style={{ fontSize: '10px' }}>
                  {projectType === 'image'
                    ? 'png, jpg, jpeg, ...'
                    : projectType === 'pdf'
                    ? 'PDF'
                    : projectType === 'audio'
                    ? 'MP3, WAV'
                    : 'MP4, 3GP, OGG, WMV, WEBM, FLV, AVI, WAV ...'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImageDragDrop;

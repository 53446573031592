import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.49982 3C6.7176 3.00099 5.96771 3.31217 5.41459 3.86528C4.86148 4.41839 4.55031 5.16828 4.54932 5.9505H6.04932C6.04932 5.15025 6.70032 4.5 7.49982 4.5C8.29932 4.5 8.95032 5.15025 8.95032 5.9505C8.95032 6.399 8.58957 6.7245 8.03832 7.17C7.85823 7.31107 7.68527 7.46101 7.52007 7.61925C6.77157 8.367 6.74982 9.16125 6.74982 9.24975V9.75H8.24982L8.24907 9.27525C8.24982 9.26325 8.27382 8.98575 8.57982 8.6805C8.69232 8.568 8.83407 8.4555 8.98107 8.337C9.56532 7.86375 10.4496 7.149 10.4496 5.9505C10.449 5.16829 10.138 4.41828 9.58499 3.8651C9.03196 3.31193 8.28202 3.00079 7.49982 3ZM6.74982 10.5H8.24982V12H6.74982V10.5Z'
      fill='#4F4F4F'
    />
    <path
      d='M7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C11.6355 15 15 11.6355 15 7.5C15 3.3645 11.6355 0 7.5 0ZM7.5 13.5C4.19175 13.5 1.5 10.8082 1.5 7.5C1.5 4.19175 4.19175 1.5 7.5 1.5C10.8082 1.5 13.5 4.19175 13.5 7.5C13.5 10.8082 10.8082 13.5 7.5 13.5Z'
      fill={color || '#4F4F4F'}
    />
  </svg>
);

export default Icon;

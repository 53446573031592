import React from 'react';

export default () => (
  <svg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M11.25 0H4.5C4.0875 0 3.75 0.3375 3.75 0.75V2.25H0.75C0.3375 2.25 0 2.5875 0 3V7.5C0 7.9125 0.3375 8.25 0.75 8.25H1.5V10.5L3.75 8.25H6.75C7.1625 8.25 7.5 7.9125 7.5 7.5V6H8.25L10.5 8.25V6H11.25C11.6625 6 12 5.6625 12 5.25V0.75C12 0.3375 11.6625 0 11.25 0ZM6.75 7.5H3.375L2.25 8.625V7.5H0.75V3H3.75V5.25C3.75 5.6625 4.0875 6 4.5 6H6.75V7.5ZM11.25 5.25H9.75V6.375L8.625 5.25H4.5V0.75H11.25V5.25Z'
      fill='#001DAD'
    />
  </svg>
);

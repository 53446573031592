import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { secondsToHms } from '../../../utils/index';
import { NextPlay, PauseIconPlayer, PlayIconPlayer, PrevPlay, SoundIcon, Waves } from './ChatSvgs';

interface Props {
  url: string;
  id: string;
  large?: boolean;
}
const AudioPlayer = ({ url, id, large = false }: Props) => {
  const forwordStep = 10; // secouds
  const backwordStep = 10; // secouds
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [playedOnce, setPlayedOnce] = useState(true);
  const [duration, setDuration]: any = useState(0);
  const [videoPlayedDuration, setVideoPlayedDuration]: any = useState(0);
  const [isMute, setIsMute] = useState(false);

  let audioRef: any = useRef(null);
  const initial: any = useRef(true);
  useEffect(() => {
    return () => {
      setPlayedOnce(true);
    };
  }, []);

  const showVisual = () => {
    if (playedOnce) {
      setPlayedOnce(false);
    } else {
      return;
    }
    let audio: any = document.getElementById('audio' + id);

    // var audio: any = audioRef
    audio = audio.firstChild;
    // audio.crossOrigin = 'anonymous';

    audio.play();
    const context = new AudioContext();
    const src = context.createMediaElementSource(audio);
    const analyser = context.createAnalyser();

    const canvas: any = document.getElementById('canvas' + id);
    canvas.width = large ? window.innerWidth : 500;
    canvas.height = large ? window.innerHeight - 65 : 250;
    const ctx = canvas.getContext('2d');

    src.connect(analyser);
    analyser.connect(context.destination);

    analyser.fftSize = 256;

    const bufferLength = analyser.frequencyBinCount;

    const dataArray = new Uint8Array(bufferLength);

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const barWidth = (WIDTH / bufferLength) * 2.5;
    let barHeight;
    let x = 0;

    const renderFrame = () => {
      requestAnimationFrame(renderFrame);

      x = 0;

      analyser.getByteFrequencyData(dataArray);

      ctx.fillStyle = '#F2F2F2';
      ctx.fillRect(0, 0, WIDTH, HEIGHT);

      // function drawBorder(xPos: any, yPos: any, width: any, height: any, thickness = .1) {
      //     ctx.fillStyle = '#FFF';
      //     ctx.fillRect(xPos - (thickness), yPos - (thickness), width + (thickness * 2), height + (thickness * 2));
      // }

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];
        ctx.fillStyle = '#306AE1';
        // drawBorder(x, HEIGHT - barHeight, barWidth, barHeight)
        ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
        x += barWidth + 1;
      }
    };

    audio.play();

    audio.onended = () => {
      setPlaying(false);
    };
    renderFrame();
  };

  const handleProgress = (progressData: {
    playedSeconds: number;
    played: number;
    loaded: number;
  }) => {
    const { playedSeconds, played } = progressData;
    setVideoPlayedDuration(playedSeconds);
    if (played === 1) {
      setPlaying(false);
    }
  };

  const backwardAudio = () => {
    const backwardVideoDuration = videoPlayedDuration - backwordStep;
    if (backwardVideoDuration > 0) {
      audioRef.seekTo(backwardVideoDuration);
    } else {
      audioRef.seekTo(0);
    }
  };

  const forwordAudio = () => {
    const backwardVideoDuration = videoPlayedDuration + forwordStep;
    if (backwardVideoDuration < duration) {
      audioRef.seekTo(backwardVideoDuration - 1);
    }
  };

  return (
    <div id={'chat-window-audio-content'}>
      <div className={'chat-window-player'}>
        <canvas className={'chat-window-canvas'} id={'canvas' + id}></canvas>
        {!playing && (
          <div style={{ position: 'absolute', bottom: '-4px' }}>
            <Waves />
          </div>
        )}
      </div>
      <ReactPlayer
        config={{
          file: {
            forceAudio: true,
            attributes: {
              crossOrigin: 'anonymous',
            },
          },
        }}
        // crossOrigin
        playing={playing}
        ref={(player) => {
          audioRef = player;
        }}
        playbackRate={speed}
        id={'audio' + id}
        className={'audioPlayer'}
        url={url}
        onProgress={handleProgress}
        onDuration={setDuration}
        mute={isMute}
        volume={isMute ? 0 : 1}
      />
      <div className='chat-window-audio-player-content'>
        <span
          onClick={() =>
            setSpeed((currentSpeed: number) => (currentSpeed < 3 ? currentSpeed + 0.5 : 1))
          }
          className={`noselect chat-window-audio-player-speed ${playing ? 'd-flex' : 'd-none'}`}
        >
          {speed + 'x'}
        </span>
        <span className='chat-window-audio-timer'>
          {videoPlayedDuration > 0 && (
            <>
              <span>{secondsToHms(videoPlayedDuration)}</span>
              {' / '}
            </>
          )}
          <span>{duration && duration !== Infinity ? secondsToHms(duration) : ''}</span>
        </span>
        <span
          id={'play-audio-button' + id}
          onClick={() => {
            setPlaying((isplaying: boolean) => !isplaying);
            showVisual();
            initial.current = false;
          }}
          className='chat-window-play-pause-icon'
        >
          {playing ? <PauseIconPlayer /> : <PlayIconPlayer />}
        </span>
        <span
          onClick={backwardAudio}
          className={`chat-window-prev-icon ${playing ? 'd-flex' : 'd-none'}`}
        >
          <PrevPlay />
        </span>

        <span
          onClick={forwordAudio}
          className={`chat-window-next-icon ${playing ? 'd-flex' : 'd-none'}`}
        >
          <NextPlay />
        </span>

        <span
          onClick={() => {
            setIsMute((oldSound) => !oldSound);
          }}
          className={`chat-window-soundIcon ${playing ? 'd-flex' : 'd-none'}`}
        >
          <SoundIcon active={isMute} />
        </span>
      </div>
    </div>
  );
};

export default AudioPlayer;

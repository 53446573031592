import Add from './Add';
import AddIcon from './AddIcon';
import ApplyFilter from './ApplyFilter';
import ArchivedIcon from './ArchivedIcon';
import ArrowBack from './ArrowBack';
import ArrowRight from './ArrowRight';
import AssanaIcon from './AsanaIcon';
import Audio from './Audio';
import { default as AudioIcon } from './AudioIcon';
import BackCircleIcon from './BackCircleIcon';
import BackIcon from './BackIcon';
import BackwardArrow from './BackwardArrow';
import BackwardMediaIcon from './BackwardMediaIcon';
import Basecamp from './Basecamp';
import CanvasAudioIcon from './CanvasIcons/CanvasAudioIcon';
import CanvasFolderIcon from './CanvasIcons/CanvasFolderIcon';
import CanvasImageIcon from './CanvasIcons/CanvasImageIcon';
import CanvasLiveWebsiteIcon from './CanvasIcons/CanvasLiveWebsiteIcon';
import CanvasPDFIcon from './CanvasIcons/CanvasPDFIcon';
import CanvasTextIcon from './CanvasIcons/CanvasTextIcon';
import CanvasVideoIcon from './CanvasIcons/CanvasVideoIcon';
import CanvasWhiteboardIcon from './CanvasIcons/CanvasWhiteboardIcon';
import Chat from './Chat';
import ChatIconSVG from './ChatIcon';
import Chrome from './Chrome';
import CircleRightClick from './CircleRightClick';
import Close from './Close';
import DashboardKanbanIcon from './DashboardKanbanIcon';
import DashboardListIcon from './DashboardListIcon';
import DashboardTileIcon from './DashboardTileIcon';
import DefaultFilter from './DefaultFilter';
import Delete from './Delete';
import DropdownArrow from './DropdownArrow';
import Ellipses from './Ellipses';
import EllipsesHorizontal from './EllipsesHorizontal';
import EllipsesVertical from './EllipsesVertical';
import ErrorIcon from './ErrorIcon';
import ForwardArrow from './ForwardArrow';
import ForwardMediaIcon from './ForwardMediaIcon';
import GitHub from './GitHub';
import Google from './Google';
import ImagePlaceholder from './ImagePlaceholder';
import Jira from './Jira';
import MaximizePlayerIcon from './MaximizePlayerIcon';
import MondayIcon from './MondayIcon';
import More from './More';
import CameraIcon from './NewCanvasDialogIcons/CameraIcon';
import DisableCameraIcon from './NewCanvasDialogIcons/DisableCameraIcon';
import DraggableIcon from './NewCanvasDialogIcons/DraggableIcon';
import MicrophoneIcon from './NewCanvasDialogIcons/MicrophoneIcon';
import QuestionIcon from './NewCanvasDialogIcons/QuestionIcon';
import ScreenIcon from './NewCanvasDialogIcons/ScreenIcon';
import SettingsIcon from './NewCanvasDialogIcons/SettingsIcon';
import NotificationBell from './NotificationBell';
import Notify from './Notify';
import PauseIcon from './PauseIcon';
import PDFIcon from './PDFIcon';
import PdfSmallIcon from './PdfSmallIcon';
import PlayIcon from './PlayIcon';
import RecordVideoIcon from './RecordVideoIcon';
import SearchIcon from './SearchIcon';
import SlackIcon from './SlackIcon';
import TextCanvasIcon from './TextCanvasIcon';
import TextIcon from './TextIcon';
import TrelloIcon from './TrelloIcon';
import TripleDot from './TripleDot';
import VectorIcon from './VectorIcon';
import VideoIcon from './VideoIcon';
import VideoTaskIcon from './VideoTaskIcon';
import VolumeSVG from './Volume';
import VolumeIcon from './VolumeIcon';
import VolumeMuteSVG from './VolumeMute';
import WebsiteIcon from './WebsiteIcon';
import WhiteBoard from './WhiteBoard';
import WhiteBoardIcon from './WhiteBoardIcon';
import PlusIconSVG from './PlusIcon';
import MinusIocnSVG from './MinusIcon';
import ArrowUpIcon from './ArrowUp';
import ArrowDownIcon from './ArrowDown';
import CollapsedInIcon from './CollapsedInIcon';
import CollapsedOutIcon from './CollapsedOutIcon';
import FitToZoom from './FitToZoom';
import FitToZoomOut from './FitToZoomOut';

export {
  DefaultFilter,
  ApplyFilter,
  ArchivedIcon,
  RecordVideoIcon,
  VideoTaskIcon,
  VectorIcon,
  VolumeSVG,
  VolumeMuteSVG,
  AddIcon,
  ImagePlaceholder,
  CircleRightClick,
  Delete,
  ChatIconSVG,
  VideoIcon,
  AudioIcon,
  PDFIcon,
  WhiteBoard,
  TextIcon,
  ArrowRight,
  GitHub,
  Google,
  Ellipses,
  Add,
  EllipsesVertical,
  EllipsesHorizontal,
  DashboardTileIcon,
  DashboardListIcon,
  DashboardKanbanIcon,
  PdfSmallIcon,
  Audio,
  WebsiteIcon,
  WhiteBoardIcon,
  TextCanvasIcon,
  DropdownArrow,
  CanvasAudioIcon,
  CanvasFolderIcon,
  CanvasImageIcon,
  CanvasLiveWebsiteIcon,
  CanvasPDFIcon,
  CanvasTextIcon,
  CanvasVideoIcon,
  CanvasWhiteboardIcon,
  CameraIcon,
  ScreenIcon,
  MicrophoneIcon,
  SettingsIcon,
  QuestionIcon,
  DisableCameraIcon,
  DraggableIcon,
  More,
  Close,
  ArrowBack,
  BackIcon,
  BackCircleIcon,
  SearchIcon,
  TripleDot,
  BackwardMediaIcon,
  ForwardMediaIcon,
  PlayIcon,
  VolumeIcon,
  MaximizePlayerIcon,
  PauseIcon,
  NotificationBell,
  Notify,
  Chrome,
  SlackIcon,
  TrelloIcon,
  AssanaIcon,
  Basecamp,
  MondayIcon,
  Jira,
  BackwardArrow,
  ForwardArrow,
  Chat,
  ErrorIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIconSVG,
  MinusIocnSVG,
  CollapsedInIcon,
  CollapsedOutIcon,
  FitToZoom,
  FitToZoomOut,
};

import firebase from 'firebase/app';
import {
  ADD_PAGE_COMMENT_ERROR,
  ADD_PAGE_COMMENT_SUCCESS,
  IPage,
  IPageData,
} from '../../types/pageTypes';
import { sendMessageToIframe } from '../../utils';
import { statsAction } from './authActions';
const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;
export const deleteFromVideoStorage = (firebaseToken: string, videoUrl: string) => {
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/deleteFromVideoStorage`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ videoUrl }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data.data;
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

export const addPageComment = (pageComment: IPage, data: IPageData, pageId: string) => {
  return async (
    dispatch: (o: { type: string }, e?: {}) => void,
    getState: () => { firebase: { auth: { uid: string; firebaseAuthToken: string } } },
    { getFirestore }: any,
  ) => {
    const firestore = getFirestore();
    const userIdAndPage = await getUserIdAndPage(getState(), firestore, data.project_id, pageId);
    if (userIdAndPage.status) {
      let authorId: any = getState().firebase.auth.uid;
      if (!authorId) {
        authorId = localStorage.getItem('userDetails');
        authorId = authorId && JSON.parse(authorId).uid;
      }
      /* Start update unread count on add a new feedback */
      firebase.firestore();
      firestore
        .collection('projects')
        .where(firebase.firestore.FieldPath.documentId(), '==', data.project_id)
        .get()
        .then((projectDetails: any) => {
          if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
            const projectDetailData: any = projectDetails.docs[0].data();
            if (projectDetailData && projectDetailData.project_created_by !== authorId) {
              const unreadFeedbackArray = projectDetailData.unreadFeedbackArray
                ? projectDetailData.unreadFeedbackArray
                : [];
              if (projectDetailData.unreadFeedbackArray.indexOf(data.circle_id) === -1) {
                unreadFeedbackArray.push(data.circle_id);
                firestore
                  .collection('projects')
                  .doc(data.project_id)
                  .update({ unreadFeedbackArray });
              }
            }
          }
        });
      /* End update unread count on add a new feedback */

      const newFeedbacks = [...userIdAndPage.feedbacks];
      userIdAndPage.feedbacks.forEach((item: any, index: number) => {
        if (item.circle_pointer.id === data.circle_id) {
          if (newFeedbacks[index].comment) {
            newFeedbacks[index].resolved = false;
            newFeedbacks[index].readBy = [userIdAndPage.authID];
            newFeedbacks[index].comment.push(pageComment);
          }
        }
      });
      statsAction('TEXT_COMMENT_SENT', firestore);
      await sendDataToDB(newFeedbacks, firestore, userIdAndPage.page.id, dispatch);
    }
  };
};

export const addDrawing = (drawingJson: IPage, data: any, pageId: string) => {
  return async (
    dispatch: (o: { type: string }, e?: {}) => void,
    getState: () => { firebase: { auth: { uid: string } } },
    { getFirestore }: any,
  ) => {
    const firestore = getFirestore();
    const userIdAndPage = await getUserIdAndPage(getState(), firestore, data.project_id, pageId);
    const { pagePath } = data;
    if (userIdAndPage.status) {
      statsAction('TEXT_COMMENT_SENT', firestore);
      const { annotations = [] } = userIdAndPage;
      let newPage = true;
      annotations &&
        annotations.forEach((item: any, index: number) => {
          if (item.pagePath === pagePath) {
            annotations[index] = { pagePath, annotation: drawingJson };
            newPage = false;
          }
        });
      if (newPage) {
        annotations.push({ pagePath, annotation: drawingJson });
      }
      await sendDrawingDataToDB(annotations, firestore, userIdAndPage.page.id, dispatch);
    }
  };
};

function sendDrawingDataToDB(annotations: any, firestore: any, pageId: string, dispatch: any) {
  const newAnnotationsCleaned = JSON.parse(JSON.stringify(annotations));
  firestore
    .collection('pages')
    .doc(pageId)
    .set({ annotations: newAnnotationsCleaned }, { merge: true })
    .then(() => {
      dispatch({ type: ADD_PAGE_COMMENT_SUCCESS });
    })
    .catch((err: Error) => {
      dispatch({ type: ADD_PAGE_COMMENT_ERROR }, err);
    });
}

export const changeReadBy = (data: any, pages: any) => {
  return async (
    dispatch: (o: { type: string }, e?: {}) => void,
    getState: () => { firebase: { auth: { uid: string } } },
    { getFirestore }: any,
  ) => {
    const firestore = getFirestore();
    const userIdAndPage = await getUserIdAndPage(
      getState(),
      firestore,
      pages[0].project_id,
      pages[0].id,
    );
    let sendData = false;
    if (userIdAndPage.status) {
      let authorId: any = getState().firebase.auth.uid;
      if (!authorId) {
        authorId = localStorage.getItem('userDetails');
        authorId = authorId && JSON.parse(authorId).uid;
      }
      /* Start update unread count on add a new feedback */
      await firebase.firestore();
      const projectDetails = await firestore
        .collection('projects')
        .where(firebase.firestore.FieldPath.documentId(), '==', pages[0].project_id)
        .get();
      if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
        const projectDetailData = projectDetails.docs[0].data();
        if (projectDetailData.project_created_by === authorId) {
          const unreadFeedbackArray = projectDetailData.unreadFeedbackArray
            ? projectDetailData.unreadFeedbackArray
            : [];
          const index = unreadFeedbackArray.indexOf(data.circle_pointer.id);
          if (index > -1) {
            unreadFeedbackArray.splice(index, 1);
          }
          firestore
            .collection('projects')
            .doc(pages[0].project_id)
            .update({ unreadFeedbackArray });
        }
      }
      /* End update unread count on add a new feedback */

      const newFeedbacks = [...userIdAndPage.feedbacks];
      userIdAndPage.feedbacks.forEach((item: any, index: number) => {
        if (item.circle_pointer.id === data.circle_pointer.id) {
          const authID = userIdAndPage.authID;
          if (newFeedbacks[index].readBy) {
            if (authID && !newFeedbacks[index].readBy.includes(authID)) {
              newFeedbacks[index].readBy.push(authID);
              sendData = true;
            }
          } else {
            newFeedbacks[index].readBy = [authID];
            sendData = true;
          }
        }
      });
      if (sendData) {
        await sendDataToDB(newFeedbacks, firestore, userIdAndPage.page.id, dispatch);
      }
    }
  };
};

export const resolvePage = (circleId: string, pages: any, iframe: any) => {
  return async (
    dispatch: (o: { type: string }, e?: {}) => void,
    getState: () => { firebase: { auth: { uid: string } } },
    { getFirestore }: any,
  ) => {
    const firestore = getFirestore();
    const userIdAndPage = await getUserIdAndPage(getState(), firestore, pages.project_id, pages.id);
    if (userIdAndPage.status) {
      const newFeedbacks = [...userIdAndPage.feedbacks];
      userIdAndPage.feedbacks.forEach((item: any, index: number) => {
        if (item.circle_pointer.id === circleId) {
          newFeedbacks[index].resolved = !newFeedbacks[index].resolved;
        }
      });
      await sendDataToDB(newFeedbacks, firestore, userIdAndPage.page.id, dispatch);
      sendMessageToIframe(iframe, 'HIDE_NODE', { key: circleId });
    }
  };
};

function sendDataToDB(newFeedbacks: any, firestore: any, pageId: string, dispatch: any) {
  const newFeedbacksCleaned = JSON.parse(JSON.stringify(newFeedbacks));
  firestore
    .collection('pages')
    .doc(pageId)
    .set({ feedback: [...newFeedbacksCleaned] }, { merge: true })
    .then(() => {
      dispatch({ type: ADD_PAGE_COMMENT_SUCCESS });
    })
    .catch((err: Error) => {
      dispatch({ type: ADD_PAGE_COMMENT_ERROR }, err);
    });
}

async function getUserIdAndPage(getState: any, firestore: any, projectId: string, pageId: string) {
  const authorId = getState.firebase.auth.uid;
  const userDetails: any = localStorage.getItem('userDetails');
  const authID = authorId ? authorId : userDetails && JSON.parse(userDetails).uid;
  const pageDocument = await firestore
    .collection('pages')
    .where('project_id', '==', projectId)
    .where(firebase.firestore.FieldPath.documentId(), '==', pageId)
    .get();
  if (pageDocument && pageDocument.docs && pageDocument.docs.length) {
    const page = pageDocument.docs[0];
    const { feedback: feedbacks, annotations } = page.data();
    return { status: true, feedbacks, authID, page, annotations };
  } else {
    return { status: false };
  }
}

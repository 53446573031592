import styled from '@emotion/styled';
import React from 'react';
import ProjectTile from '../../components/projects/project-tile';
import { IMasterProject, IProject } from '../../types/projectTypes';

const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`;

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent

interface IOwnProps {
  canvas: IProject;
  isDragging: boolean;
  provided: any;
  key: string;
  dragSnapshot: any;
  selectedMasterProject: IMasterProject;
  history: any;
}

export default class CanvasItem extends React.PureComponent<IOwnProps, any> {
  render() {
    const { canvas, provided, key, dragSnapshot, selectedMasterProject, history } = this.props;
    return (
      <div>
        <Content>
          <ProjectTile
            index={key}
            projectDetail={canvas}
            history={history}
            masterProjectName={selectedMasterProject.name || ''}
            isKanbanView
            provided={provided}
            snapshot={dragSnapshot}
            internalScroll
          />
        </Content>
      </div>
    );
  }
}

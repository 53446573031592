// @ts-nocheck
import firebase from 'firebase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { createProject as createProjectAction } from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import {
  IMasterProject,
  IProject,
  IProjectCreate,
  IworkflowCanvas,
} from '../../types/projectTypes';
import {
  createThumbnailImg,
  defaultTag,
  isDataURL,
  sendImageToStore,
  trackAnalytics,
  showFullFeature,
  getLastSelectedWorkflowAndMasterProjectId
} from '../../utils';
import Notifications from '../notifications';
// import { EllipsesVertical } from '../svg';
import DashboardButtons from './DashboardButtons';
import Filter from './filter';
import './kanban-styles.css';
import KanbanList from './KanbanList';
import ProjectSelectDropdown from './ProjectSelectDropdown';
import './style.css';
import NewSelectDropdown from './NewSelectDropdown';


interface IOwnProps {
  projects: IProject[];
  auth?: any;
  isWriteAccess: boolean;
  signUpDays: number;
  selectedMasterProject: IMasterProject;
  projectMenu: any;
  onDelete: any;
  loader: boolean;
  profile: any;
  workflowCanvas: IworkflowCanvas[];
  loadMoreCanvas: any;
  paginationLimit: number;
  updateStateOnDragEnd: (asdf: any) => void;
  createProject: any;
  firebaseAuthToken: any;
  selectedOrganizationData: any;
  history: any;
  selectedWorkflowId?: string;
  updateSelectedWorkflowId?: any;
}

class Dashboard extends Component<IOwnProps, any> {
  constructor(props: any) {
    super(props);
    const { workflowCanvas } = props;
    this.state = {
      loader: false,
      workflowCanvas,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    const { workflowCanvas } = nextProps;
    if (JSON.stringify(workflowCanvas) !== JSON.stringify(this.state.workflowCanvas)) {
      this.setState({ workflowCanvas });
    }
  }

  onPaste = (e: any) => {
    const { selectedWorkflowId } = this.props;
    const items = e && (e.clipboardData || e.originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
      }
    }
    if (blob !== null) {
      const reader = new FileReader();
      reader.onload = async (eData: any) => {
        if (isDataURL(eData.target.result)) {
          this.setState({
            loader: true,
          });
          const thumbnailBase64Img = await createThumbnailImg(eData.target.result);
          const originalImg: any = await sendImageToStore(eData.target.result);
          this.createBase64Project(originalImg, thumbnailBase64Img, selectedWorkflowId);
        }
      };
      reader.readAsDataURL(blob);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  createBase64Project = async (
    originalImg: string,
    thumbnailBase64Img: string,
    selectedWorkflowId: string,
  ) => {
    const projectType = 'image';
    const { createProject, auth, firebaseAuthToken, profile, selectedMasterProject } = this.props;

    const loginUserEmail = auth && auth.email;
    const selectedMasterProjectId = (selectedMasterProject && selectedMasterProject.id) || '';

    trackAnalytics('Project', 'Created a canvas', 'Image');
    const projectData: any = {
      title: 'Image',
      project_url: originalImg,
      member_emails: [loginUserEmail],
      project_tags: [],
      type: projectType,
      imageType: 'base64',
      jiraIssueUrl: '',
      trelloIssueUrl: '',
      project_base64_image: thumbnailBase64Img,
      workflowId: selectedWorkflowId,
      masterProjectId: selectedMasterProjectId,
      archived: 'no',
    };

    const fiebaseInstance: any = firebase;
    let firebaseUserAuthToken = firebaseAuthToken;
    if (
      !firebaseAuthToken &&
      fiebaseInstance &&
      fiebaseInstance.auth() &&
      fiebaseInstance.auth().currentUser
    ) {
      firebaseUserAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    createProject(projectData, firebaseUserAuthToken, selectedMasterProject).then(
      (insertedId: any) => {
        this.setState({
          loader: false,
        });
        const projectTypeLabel = defaultTag(projectType);
        if (insertedId) {
          const slackMessage = `${profile.firstName}${' '}${profile.lastName
            } created ${projectTypeLabel} type project`;
          const notificationId = uuid();
          const projectURL = `${window.location.href}project/${insertedId}?notificationId=${notificationId}`;
          firebaseAuthToken &&
            sendMessageToSlack(
              firebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );
        }
      },
    );
  };

  render() {
    const isShowFullFeature = showFullFeature();
    const {
      loader,
      projects = [],
      auth,
      profile,
      isWriteAccess,
      signUpDays,
      selectedMasterProject,
      projectMenu,
      onDelete,
      selectedOrganizationData,
      loadMoreCanvas,
      paginationLimit,
      updateStateOnDragEnd,
      history,
      selectedWorkflowId,
      updateSelectedWorkflowId
    } = this.props;

    const { workflowCanvas } = this.state;
    // const actionMenu = () => {
    //   const menu = (
    //     <Menu>
    //       {['Filter', 'Assign', 'Add Canvas'].map((action: string) => (
    //         <Menu.Item
    //           className='project-dropdown-item'
    //           key='1'
    //         // onClick={() => {
    //         //   if (action === 'Add Canvas') {
    //         //   }
    //         // }}
    //         >
    //           {action}
    //         </Menu.Item>
    //       ))}
    //     </Menu>
    //   );
    //   return menu;
    // };

    // const datas: any[] = [
    //   { step: 'Open', total: 13, color: '#828282' },
    //   { step: 'In Progress', total: 4, color: ' #2D9CDB' },
    //   { step: 'Review', total: 6, color: '#F2C94C' },
    //   { step: 'Blocked', total: 18, color: '#EB5757' },
    //   { step: 'Completed', total: 6, color: '#27AE60' },
    //   { step: 'Blocked 2', total: 18, color: '#EB5757' },
    //   { step: 'Review 2', total: 6, color: '#F2C94C' },
    // ];
    const { members = [], tags = [] } = selectedMasterProject;
    return (
      <div className='kanbanView'>
        <div className='kanbanHeader'>
          <ProjectSelectDropdown
            selectedMasterProject={selectedMasterProject}
            projectMenu={projectMenu}
            isStyle={true}
          />
          <div className="kanbanViewAddNewCanvas">
            <NewSelectDropdown
              selectedOrganizationData={selectedOrganizationData}
              selectedMasterProject={selectedMasterProject}
              selectedWorkflowId={selectedWorkflowId}
              selectedMasterProjectId={
                (selectedMasterProject && selectedMasterProject.id) || ''
              }
            />
          </div>

          <div className='kanbanSearchContainer'>
            {/* <div>NEW BUTTON</div>
            <div>PROJECT MEMBERS</div> */}
            <div style={{ width: '100%' }}></div>
            <div className='dashboardButtonsContainer'>
              <div className='filter-and-notification-container filter-and-notification-container-kanban'>
                {false && <Filter tags={tags} members={members} />}
                { isShowFullFeature && <Notifications loginUserId={auth.uid} />}
              </div>
              <DashboardButtons />
            </div>
          </div>
        </div>
        <KanbanList
          history={history}
          loader={loader}
          innerLoader={this.state.loader}
          activeColumnId={this.props.selectedWorkflowId}
          setActiveColumnId={(id) => {
            updateSelectedWorkflowId(id);
            window.localStorage.setItem('selectedWorkflowId', id);
            getLastSelectedWorkflowAndMasterProjectId();
          }}
          projects={projects}
          internalScroll={true}
          profile={profile}
          signUpDays={signUpDays}
          isWriteAccess={isWriteAccess}
          onDelete={onDelete}
          workflowCanvas={workflowCanvas}
          loadMoreCanvas={loadMoreCanvas}
          paginationLimit={paginationLimit}
          selectedMasterProject={selectedMasterProject}
          updateStateOnDragEnd={updateStateOnDragEnd}
          selectedOrganizationData={selectedOrganizationData}
        />
        {/* <div className='kanbanContainer'>
          {workflowCanvas.map((workflowStep: IworkflowCanvas, workflowIndex: number) => {
            const {
              id,
              color = '',
              step,
              canvases = [],
              loadingMoreCanvas = false,
              isMoreCanvas = false,
            } = workflowStep;
            return (
              <div
                key={workflowIndex}
                className={`kanbanColumn ${this.state.activeColumnId === id && 'selected-column'}`}
                style={{
                  background: hexToRGBA(color, 30),
                }}
                onClick={() => {
                  if (!this.state.loader) {
                    this.setState({ activeColumnId: id });
                  }
                }}
              >
                <div className='kanbanColumnHeader'>
                  <div className='kanbanColumnText'>
                    {step} {canvases.length ? `(${canvases.length})` : ''}
                  </div>
                </div>
                <div className='columnTickets'>
                  <ProjectList
                    selectedMasterProjectId={
                      (selectedMasterProject && selectedMasterProject.id) || ''
                    }
                    isWriteAccess={isWriteAccess}
                    signUpDays={signUpDays}
                    profile={profile}
                    authId={auth && auth.uid}
                    loader={
                      this.state.activeColumnId && this.state.activeColumnId === id
                        ? this.state.loader
                        : loader
                    }
                    loginUserEmail={auth && auth.email}
                    loginUserId={auth && auth.uid}
                    projects={projects}
                    onDelete={onDelete}
                    canvases={canvases || []}
                    loadingMoreCanvas={loadingMoreCanvas}
                    isMoreCanvas={isMoreCanvas}
                    loadMoreCanvas={loadMoreCanvas}
                    selectedWorkflowId={id}
                    paginationLimit={paginationLimit}
                    isKanbanView={true}
                    selectedMasterProject={selectedMasterProject}
                    selectedOrganizationData={selectedOrganizationData}
                  />
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, any>) => {
  return {
    createProject: (
      project: IProjectCreate,
      firebaseAuthToken: string,
      selectedMasterProject: any,
    ) => dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

const mapStateToProps = (state: any) => {
  return {
    firebaseAuthToken: state.auth.firebaseAuthToken,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

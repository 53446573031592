import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { hexToRGBA } from 'utils';
import Spinner from '../components/shared/spinner';
import { IMasterProject, IProject } from '../types/projectTypes';
import { grid } from './constants';
import CanvasList from './primatives/CanvasList';

const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;

interface IOwnProps {
  id: string;
  index: number;
  workflowIndex: number;
  color: string;
  canvases: IProject[];
  step: string;
  selectedMasterProject: IMasterProject;
  history: any;
  isScrollable: boolean;
  isCombineEnabled: boolean;
  setActiveColumnId: any;
  activeColumnId: any;
  loader: any;
}

export default class Column extends Component<IOwnProps, any> {
  render() {
    const {
      id,
      workflowIndex,
      color,
      canvases,
      step,
      selectedMasterProject,
      history,
      isScrollable,
      isCombineEnabled,
      setActiveColumnId,
      activeColumnId,
      loader,
    } = this.props;
    return (
      <div>
        <Draggable draggableId={id} key={id} index={workflowIndex}>
          {(provided, snapshot) => (
            <Container
              className={`${activeColumnId === id && 'selected-column'}`}
              onClick={() => {
                setActiveColumnId(id);
              }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              key={id}
            >
              <div
                className='kanbanColumnHeader'
                style={{
                  userSelect: 'none',
                  background: snapshot.isDragging ? hexToRGBA(color, 40) : hexToRGBA(color, 50),
                }}
              >
                <div className='kanbanColumnText' {...provided.dragHandleProps}>
                  {step} {canvases.length ? `(${canvases.length})` : ''}
                </div>
              </div>
              {activeColumnId === id && loader ? (
                <div style={{ minWidth: '250px', height: '77vh' }}>
                  <Spinner />
                </div>
              ) : (
                <CanvasList
                  history={history}
                  selectedMasterProject={selectedMasterProject}
                  workflowIndex={workflowIndex}
                  listType='canvas'
                  canvases={canvases}
                  color={color}
                  internalScroll={isScrollable}
                  isCombineEnabled={Boolean(isCombineEnabled)}
                  isDropDisabled={false}
                  ignoreContainerClipping={false}
                />
              )}
            </Container>
          )}
        </Draggable>
      </div>
    );
  }
}

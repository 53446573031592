// import {
//   Checkbox,
//   Icon,
//   Input,
//   // Avatar, Row, Col
// } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import ChatWindow from './ChatWindow';
import './ChatWindowStyles.css';

const ChatWindowNew = (props: any) => {
  const {
    addMessageInThread,
    isWriteAccess,
    projectId,
    // chatData = {
    //   messages: [],
    //   count: 0,
    //   chatId: '',
    // },
    selectedMasterProject,
  } = props;
  const [stateProjectId, setStateProejctId] = useState(projectId);

  useEffect(() => {
    setStateProejctId(projectId);
  }, [projectId]);

  return (
    <>
      {/* <Input
        className='chat-window-search-input'
        prefix={<Icon type='search' />}
        placeholder={'Search discussions'}
      />
      <div className='chat-show-resolved'>
        Show resolved <Checkbox/>
      </div> */}

      {projectId === stateProjectId && (
        <ChatWindow
          isWriteAccess={isWriteAccess}
          projectID={selectedMasterProject.id}
          pageID={'8MWPgIoFNapXMHpeoSzu'}
          circlePointerID={'c575-b4ef-3dee-1c7f_89f1'}
          direction={'right'}
          resolved={false}
          isChatWindow
          // chatData={chatData}
          addMessageInThread={addMessageInThread}
          chatId={selectedMasterProject.chatId}
          // clearShowingMessageid={alert}
        />
      )}
    </>
  );
};

export default withRouter(ChatWindowNew);

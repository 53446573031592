import React from 'react';

export default () => (
  <svg width='18' height='16' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.08859 9.79732C1.08859 10.3977 1.57682 10.8859 2.17718 10.8859H7.62014C8.22049 10.8859 8.70873 10.3977 8.70873 9.79732V3.26577H9.79732V2.17718H7.62014V1.08859C7.62014 0.488233 7.1319 0 6.53155 0H3.26577C2.66541 0 2.17718 0.488233 2.17718 1.08859V2.17718H0V3.26577H1.08859V9.79732ZM3.26577 1.08859H6.53155V2.17718H3.26577V1.08859ZM2.72148 3.26577H7.62014L7.62068 9.79732H2.17718V3.26577H2.72148Z'
      fill='#BDBDBD'
    />
    <path
      d='M3.26562 4.35428H4.35422V8.70864H3.26562V4.35428ZM5.44281 4.35428H6.5314V8.70864H5.44281V4.35428Z'
      fill='#BDBDBD'
    />
  </svg>
);

import { Form, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

interface TagsSelectOptionProps {
  tags: string;
  name: string;
}

interface TagsMultipleSelectProps {
  allTags: string[];
  loginUserEmail: string;
  memberEMailEditable: boolean;
  tags: string[];
  invalidTagsError: string;
  type: string;
  options: TagsSelectOptionProps[];
  auth: any;
  updateAllTags(tags: string[]): void;
  updateValidTags(tags: string[]): void;
  updateInvalidTagsError(error: string): void;
}

const TagsMultipleSelect = (props: TagsMultipleSelectProps & FormComponentProps) => {
  const FormItem = Form.Item;
  const { form, memberEMailEditable, allTags } = props;

  useEffect(() => {
    form.setFieldsValue({
      ['invitees']: allTags ? allTags : [],
    });
  }, [allTags]);

  const onChangeTag = (values: any): any => {
    props.updateAllTags(values || []);
  };
  const { type } = props;
  return (
    <div className={'tag-multiple-select'}>
      <Form>
        <FormItem>
          <Select
            disabled={!memberEMailEditable}
            dropdownStyle={{ display: 'none' }}
            mode={type}
            tokenSeparators={[',', ';', ' ']}
            onChange={onChangeTag}
            value={allTags}
          ></Select>
        </FormItem>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create()(TagsMultipleSelect));

import { Button, notification } from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createThumbnailImg, mobileCheck, trackAnalytics } from '../../utils';
import { ImagePlaceholder } from '../svg';
import './ImageDragDrop.css';
import Spinner from './spinner';

const isMobile = mobileCheck();

export interface IUploadFileModelProps {
  sendFeedback: any;
  url: string;
  edit: boolean;
  loader: boolean;
  getThumbnailBlob?: any;
  hideDragAndDrop?: boolean;
  setIsUploadingImage?: any;
  isUploadAndSave?: boolean;
  isPdf?: boolean;
  profile: any;
  auth: any;
  organization: any;
}

const ImageDragDrop: (props: IUploadFileModelProps) => JSX.Element = ({
  sendFeedback,
  url,
  edit,
  loader,
  getThumbnailBlob,
  hideDragAndDrop,
  setIsUploadingImage,
  isUploadAndSave,
  isPdf,
  profile,
  auth,
  organization,
}) => {
  const [sendingFile, setSendingFile] = React.useState(false);
  const [thumbnail, setThumbnail] = React.useState(url ? url : '');
  const isDataUrlString = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  useEffect(() => {
    setThumbnail(url);
  }, [url]);

  useEffect(() => {
    setSendingFile(loader);
  }, [loader]);

  const getImageBlob = (data: any) => {
    let blob: any = null;
    const item = data[0];
    const imageExtension = item.name.split('.').pop();
    if (item.type.indexOf('image') === 0) {
      blob = item;
    }

    if (blob !== null) {
      setSendingFile(true);
      setIsUploadingImage && setIsUploadingImage(true);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        if (isDataURL(e.target.result)) {
          const originalImg: any = await sendImageToStore(e.target.result, imageExtension);
          createThumbNail(e.target.result, originalImg, imageExtension).then(
            (thumbnailImg: any) => {
              if (sendFeedback) {
                let userQuota: any = {};
                if (profile.isManagedUserQuotaInOrganization) {
                  userQuota = organization.selectOrganizationDetails.userQuota;
                } else {
                  userQuota = profile.userQuota;
                }
                if (blob.size && profile && userQuota) {
                  if (auth && auth.uid && userQuota) {
                    userQuota.storageLimit = userQuota.storageLimit - blob.size / 1024;
                    if (userQuota.storageLimit <= 0) {
                      notification.error({
                        message: 'Your current plan doesn’t include using this feature.',
                      });
                      setSendingFile(false);
                      return true;
                    }
                    const firestore = firebase.firestore();
                    if (profile.isManagedUserQuotaInOrganization) {
                      if (organization.selectOrganizationDetails.id) {
                        firestore
                          .collection('organization')
                          .doc(organization.selectOrganizationDetails.id)
                          .update({
                            userQuota,
                          });
                      }
                    } else {
                      firestore
                        .collection('users')
                        .doc(auth.uid)
                        .update({
                          userQuota,
                        });
                    }
                  }
                }
                sendFeedback(originalImg, thumbnailImg, true);
                setThumbnail(thumbnailImg);
              }
              !isUploadAndSave && setSendingFile(false);
              setIsUploadingImage && setIsUploadingImage(false);
              // onClose();
            },
          );
        } else {
          // setValue(e.target.result);
        }
      };
      reader.readAsDataURL(blob);
    }
  };

  const getFile = (data: any) => {
    const file = data[0];
    if (file !== null) {
      setSendingFile(true);
      setIsUploadingImage && setIsUploadingImage(true);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const fileExtension = file.name.split('.').pop();
        const originalFile: any = await sendFileToStore(e.target.result, fileExtension);
        if (sendFeedback) {
          if (file.size && profile) {
            let userQuota: any = {};
            if (profile.isManagedUserQuotaInOrganization) {
              userQuota = organization.selectOrganizationDetails.userQuota;
            } else {
              userQuota = profile.userQuota;
            }
            if (auth && auth.uid && userQuota) {
              userQuota.storageLimit = userQuota.storageLimit - file.size / 1024;
              if (userQuota.storageLimit <= 0) {
                notification.error({
                  message: 'Your current plan doesn’t include using this feature.',
                });
                setSendingFile(false);
                return true;
              }
              const firestore = firebase.firestore();
              if (profile.isManagedUserQuotaInOrganization) {
                if (organization.selectOrganizationDetails.id) {
                  firestore
                    .collection('organization')
                    .doc(organization.selectOrganizationDetails.id)
                    .update({
                      userQuota,
                    });
                }
              } else {
                firestore
                  .collection('users')
                  .doc(auth.uid)
                  .update({
                    userQuota,
                  });
              }
            }
          }
          sendFeedback(originalFile, '', false, true, file.name);
        }
        !isUploadAndSave && setSendingFile(false);
        setIsUploadingImage && setIsUploadingImage(false);
        //  onClose();
      };
      reader.readAsDataURL(file);
    }
  };

  const isDataURL = (s: string) => {
    return !!s.match(isDataUrlString);
  };

  const createThumbNail = (data: string, originalImageUrl: string, imageExtension: string) =>
    new Promise((resolve) => {
      const img = new Image();
      img.src = data;
      img.onload = async () => {
        if (img.height > 200 || img.width > 200) {
          const elem = document.createElement('canvas');
          let maxWidth = 200;
          let maxHeight = 200;
          if (img.height > img.width) {
            maxHeight = 200;
            maxWidth = (img.width / img.height) * maxHeight;

            elem.height = maxHeight;
            elem.width = maxWidth;
          } else {
            maxWidth = 200;
            maxHeight = (img.height / img.width) * maxWidth;

            elem.height = maxHeight;
            elem.width = maxWidth;
          }
          const ctx: any = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, maxWidth, maxHeight);
          const dataUrl = elem.toDataURL();
          if (getThumbnailBlob) {
            const base64Image = await createThumbnailImg(data);
            getThumbnailBlob(base64Image);
          }
          const thumbnailImage: any = await sendImageToStore(dataUrl, imageExtension);
          resolve(thumbnailImage);
        } else {
          resolve(originalImageUrl);
        }
      };
    });

  const sendImageToStore = (dataUrl: string, imageExtension: string) => {
    const fileName = `file-${new Date().getTime()}.${imageExtension}`;
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    return storageRef.putString(dataUrl, 'data_url').then((imageData: any) => {
      return imageData.ref.getDownloadURL().then((downloadImgURL: string) => {
        return downloadImgURL;
      });
    });
  };

  const sendFileToStore = (dataUrl: string, fileExtension: string) => {
    const fileName = `file-${new Date().getTime()}.${fileExtension}`;
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    return storageRef.putString(dataUrl, 'data_url').then((fileData: any) => {
      return fileData.ref.getDownloadURL().then((downloadUrl: string) => {
        return downloadUrl;
      });
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    trackAnalytics('Create project', 'New image seleted');
    sendFile(acceptedFiles);
  }, []);

  // const onUpload = (e: any) => {
  //   sendFile(e);
  // };

  const sendFile = (file: any) => {
    if (file && file[0] && file[0].type && file[0].type.indexOf('image') === 0) {
      getImageBlob(file);
    } else {
      getFile(file);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: isPdf ? 'application/pdf' : 'image/*',
  });

  if (hideDragAndDrop) {
    return (
      <React.Fragment>
        {!edit ? (
          <div>
            <div className='imagesSection'>
              <span {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the file here ...</p>
                ) : sendingFile ? (
                  <div>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='form-button image-browse-loader background-image'
                      block={true}
                      style={{
                        backgroundImage: `url("/images/small-loader.gif")`,
                        backgroundSize: 'contain',
                      }}
                    ></Button>
                  </div>
                ) : (
                  <div>
                    {!mobileCheck() && !hideDragAndDrop && <p>Drag and drop file here or</p>}
                    {thumbnail ? (
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='form-button image-browse background-image'
                        style={{ backgroundImage: `url(${thumbnail})` }}
                        block={true}
                      ></Button>
                    ) : (
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='form-button image-browse'
                        block={true}
                      >
                        <ImagePlaceholder />
                      </Button>
                    )}
                  </div>
                )}
              </span>
            </div>
          </div>
        ) : null}
        {/* <div className='chooseFile'>
          <input type='file' onChange={(e: any) => onUpload(e.target.files)} />
        </div> */}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div style={{ width: '100%' }}>
          <div className='imagesSection'>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className='dialogUploadDropZone'>
                  <div style={{ fontWeight: 'bold' }}>Drop the file here ...</div>
                </div>
              ) : sendingFile ? (
                <div>
                  <div className='form-container'>
                    <Spinner />
                  </div>
                </div>
              ) : (
                <div className='dialogUploadDropZone cursor-pointer'>
                  <div style={{ fontWeight: 'bold' }}>
                    {!isMobile && `Drop your ${isPdf ? 'pdf' : 'image'} file here, or`}
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='browse-form-button'
                      block={true}
                    >
                      Browse
                    </Button>
                  </div>
                  <div style={{ fontSize: '10px' }}>{isPdf ? 'pdf' : 'JPG, PNG, GIF ...'}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
      // <React.Fragment>
      //   {!edit ? (
      //     <div>
      //       <div className='imagesSection'>
      //         <div {...getRootProps()}>
      //           <input {...getInputProps()} />
      //           {isDragActive ? (
      //             <p>Drop the file here ...</p>
      //           ) : sendingFile ? (
      //             <div>
      //               <div className='form-container'>
      //                 <Spinner />
      //               </div>
      //               {/* <p className='sendingFile'>Sending File</p> */}
      //             </div>
      //           ) : (
      //             <div>
      //               {!mobileCheck() && <p>Drag and drop file here or</p>}
      //               <Button type='primary' htmlType='submit' className='form-button' block={true}>
      //                 Browse
      //               </Button>
      //             </div>
      //           )}
      //         </div>
      //       </div>
      //     </div>
      //   ) : null}
      //   {thumbnail && !hideDragAndDrop && (
      //     <div className={'drop-image-preview'}>
      //       <img height={'50px'} src={thumbnail} />
      //     </div>
      //   )}
      // </React.Fragment>
    );
  }
};
const mapStateToProps = (state: any) => {
  return {
    organization: state.organization,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};
const mapDispatchToProps = () => {
  return {};
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ImageDragDrop);

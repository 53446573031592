import 'ant-design-pro/dist/ant-design-pro.css';
import AvatarList from 'ant-design-pro/lib/AvatarList';
import { Avatar, Tooltip } from 'antd';
import ColorContext from 'context/ColorContext';
import React, { useState } from 'react';
import { trackAnalytics } from 'utils';
import { AddIcon } from '../svg';
import './members.css';
import CanvasMemberDialog from './members/CanvasMemberDialog';

const Members = ({ selectedMasterProject, organizationDetail, auth }: any) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  const handleCloseMemberModal = () => {
    setCanvasMemberModalVisible(false);
  };

  // const hideAddMemberForm = () => {
  //   setMemberFormVisible(false);
  //   setTimeout(() => {
  //     setIsSentInvitation(false);
  //   });
  // };

  const [canvasMemberModalVisible, setCanvasMemberModalVisible] = useState(false);

  const { members = [] } = selectedMasterProject;
  const activeMembers = members.filter(
    (member: { image: string; name: string; status: string }) => {
      return member.status ? member.status === 'ACTIVE' : true;
    },
  );

  const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
  let selectedOrganizationMatchedIndex = organizationDetail.findIndex(
    (og: any) => og.id === selectedOrganizationId,
  );
  if (selectedOrganizationMatchedIndex === -1) {
    selectedOrganizationMatchedIndex = 0;
  }

  // const activeMembers = members;
  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div className='members-container'>
          {/* <MemberForm
            title='Invite new people'
            visible={memberFormVisible}
            setVisible={hideAddMemberForm}
            selectedMasterProject={selectedMasterProject}
            isSentInvitation={isSentInvitation}
            setIsSentInvitation={setIsSentInvitation}
          /> */}
          <CanvasMemberDialog
            visible={canvasMemberModalVisible}
            closeModal={handleCloseMemberModal}
            organizationDetail={organizationDetail}
            selectedOrganizationMatchedIndex={selectedOrganizationMatchedIndex}
            isAllowNewMember={true}
            auth={auth}
            memberList={
              (organizationDetail &&
                organizationDetail[selectedOrganizationMatchedIndex] &&
                organizationDetail[selectedOrganizationMatchedIndex].members) ||
              []
            }
            masterProjectId={selectedMasterProject && selectedMasterProject.id}
            addedMembers={members}
            workspace={true}
          />
          <AvatarList size={33} maxLength={5}>
            {activeMembers.map((member: any, index: number) => {
              const { email = '', name = '', image, id } = member;
              if (image) {
                // return <AvatarList.Item key={email} tips={name} src={image} />;
                return (
                  <Tooltip title={name} key={index}>
                    <li className='antd-pro-avatar-list-avatarItem' key={id}>
                      <Avatar src={image} />
                    </li>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={name} key={index}>
                    <li className='antd-pro-avatar-list-avatarItem' key={id}>
                      <Avatar key={email}>
                        {`${name && name[0] && name[0].toUpperCase()}${
                          name && name[1] ? name[1].toUpperCase() : ''
                        }`}
                      </Avatar>
                    </li>
                  </Tooltip>
                );
              }
            })}
          </AvatarList>
          <Tooltip title={'Add Member'} key={'add-member'}>
            <span
              className={`add-member ${!activeMembers.length ? 'empty-member' : ''}`}
              style={{
                background: theme.backgrounds.color1,
                color: theme.primaryBtn.color3,
                ...(hover && {
                  color: theme.primaryBtn.color3,
                  background: theme.primaryBtn.color1,
                }),
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                trackAnalytics('Members', 'Add Member Clicked');
                setCanvasMemberModalVisible(true);
              }}
            >
              <AddIcon />
            </span>
          </Tooltip>
        </div>
      )}
    </ColorContext.Consumer>
  );
};

export default Members;

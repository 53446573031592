import React from 'react';
import styled from 'styled-components';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: any;
  bgColor: any;
}

interface UnreadProps {
  bgColor: any;
}

interface ISelectedProps {
  bgColor: any;
}

const SelectedWorkspaceWraper = styled.section`
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 14px;
  margin: 8.5px 22px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
    background: ${({ bgColor }: IProps) => bgColor};
  }
  &:nth-child(1) {
    margin-top: 28px;
  }
`;

const SelectedWorkspace = styled.section`
  position: relative;
  opacity: 1;
  background-color: #9b9b9b;
  &:before {
    position: absolute;
    content: ' ';
    background: ${({ bgColor }: ISelectedProps) => bgColor};
    border-radius: 0px 5px 5px 0px;
    height: 50px;
    width: 5px;
    left: -22px;
  }
`;
const UnreadWorkspace = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  &:before {
    position: absolute;
    content: ' ';
    background: ${({ bgColor }: UnreadProps) => bgColor};
    border-radius: 0px 3px 3px 0px;
    height: 7px;
    width: 5px;
    left: -22px;
  }
`;

export const ZselectedWorkspaceWraper: React.FC<IProps> = ({ children, onClick, bgColor }) => {
  return (
    <SelectedWorkspaceWraper onClick={onClick} bgColor={bgColor}>
      {children}
    </SelectedWorkspaceWraper>
  );
};

export const ZselectedWorkspace: React.FC<ISelectedProps> = ({ bgColor }) => (
  <SelectedWorkspace bgColor={bgColor} />
);
export const ZunreadWorkspace: React.FC<UnreadProps> = ({ bgColor }) => (
  <UnreadWorkspace bgColor={bgColor} />
);

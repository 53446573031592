import { Form, Modal } from 'antd';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import ColorContext from '../../../context/ColorContext';
import { Zbutton, Zinput } from '../../../primitives';
import { statsAction } from '../../../store/actions/authActions';
import { IMasterProject, ITag } from '../../../types/projectTypes';
import ColorPicker from '../../shared/ColorPicker';
import './tags.css';

interface IProps {
  tagModalVisible: boolean;
  handleCancel: () => void;
  project: IMasterProject;
  isEdit: boolean;
  selectedTag: ITag;
  form: any;
}

const TagsDialog: React.FC<IProps> = ({
  tagModalVisible,
  handleCancel,
  isEdit,
  project,
  selectedTag,
  form: { getFieldDecorator, validateFields, resetFields },
}) => {
  const [updateColor, setUpdateColor] = useState('');
  const [tagTitle, setTagTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [colorState, setColorState] = useState({
    selectedIndex: isEdit ? 14 : 3,
    selectedColor: isEdit ? updateColor : '#219653',
  });
  const [background, setBackground] = useState<string>('#FFFFFF');
  const [isTitle, setIsTitle] = useState(false);

  useEffect(() => {
    setTagTitle(isEdit && selectedTag.title ? selectedTag.title : '');
    setUpdateColor(isEdit && selectedTag.color ? selectedTag.color : '');
  }, [isEdit, selectedTag]);

  const reset = () => {
    setColorState({
      ...colorState,
      selectedIndex: isEdit ? 14 : 3,
      selectedColor: isEdit ? updateColor : '#219653',
    });
    setIsLoading(false);
    resetFields();
    handleCancel();
    setIsTitle(false);
  };

  const handleAddTag = () => {
    validateFields((err: any, values: any) => {
      const { tagName } = values;
      let tagExists: any;
      if (!isEdit) {
        tagExists = project.tags
          ? project.tags.filter((T) => {
              if (tagName === T.title) {
                setIsTitle(true);
              }
              return tagName === T.title;
            })
          : [];
      } else {
        tagExists = [];
      }

      const newTag = {
        id: uuid(),
        title: tagName,
        color: colorState.selectedColor,
      };
      const tags = project.tags ? [...project.tags, newTag] : [newTag];
      const updatedTag =
        project.tags &&
        project.tags.map((tag: ITag) => {
          if (tag.id === selectedTag.id) {
            return {
              ...tag,
              title: tagName,
              color: updateColor,
            };
          }
          return tag;
        });

      if (!err) {
        if (tagExists && tagExists.length === 0) {
          setIsLoading(true);
          const firestore = firebase.firestore();
          if (!isEdit) {
            const updatedTimeStamp = new Date().getTime();
            return firestore
              .collection('masterProjects')
              .doc(project.id)
              .set(
                {
                  tags,
                  updatedTimeStamp,
                },
                { merge: true },
              )
              .then(() => {
                statsAction('CREATE_TAG', firestore);
                reset();
              })
              .catch(() => {
                setIsLoading(false);
                return false;
              });
          } else {
            const updatedTimeStamp = new Date().getTime();
            return firestore
              .collection('masterProjects')
              .doc(project.id)
              .set({
                ...project,
                tags: updatedTag,
                updatedTimeStamp,
              })
              .then(() => {
                statsAction('UPDATE_TAG', firestore);
                reset();
              })
              .catch(() => {
                setIsLoading(false);
                return false;
              });
          }
        }
      }
    });
  };

  return (
    <div>
      <Modal
        visible={tagModalVisible}
        onOk={handleCancel}
        onCancel={() => {
          handleCancel();
          isTitle && reset();
        }}
        footer={null}
      >
        <ColorContext.Consumer>
          {({ theme }) => (
            <div className='tag-dialog-container' style={{ color: theme.text.color2 }}>
              <h2>{isEdit ? 'Edit Tag' : 'Add Tag'}</h2>
              {/* <p>Some contents...</p> */}
              <Form className='white'>
                <Form.Item>
                  <div
                    className='selected-color'
                    style={{ background: isEdit ? updateColor : colorState.selectedColor }}
                  />
                  {getFieldDecorator('tagName', {
                    initialValue: isEdit && tagTitle ? tagTitle : '',
                    rules: [
                      {
                        required: true,
                        message: 'Please input tag name!',
                        whitespace: true,
                      },
                    ],
                  })(<Zinput placeholder='Enter Tag Name' secondary />)}
                </Form.Item>
                {isTitle && (
                  <div>
                    <p style={{ color: 'red', marginTop: 0 }}>
                      The given tag is allready exist. Please try a new one
                    </p>
                  </div>
                )}
                <Form.Item>
                  <ColorPicker
                    background={background}
                    setBackground={setBackground}
                    colorState={colorState}
                    setColorState={setColorState}
                    setUpdateColor={setUpdateColor}
                  />
                </Form.Item>
                <div className='add-tag-btn-container'>
                  <Zbutton
                    secondary
                    btnColors={theme}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      handleCancel();
                      isTitle && reset();
                    }}
                  >
                    Cancel
                  </Zbutton>
                  <Zbutton
                    key='submit'
                    primary
                    btnColors={theme}
                    loading={isLoading}
                    onClick={handleAddTag}
                  >
                    {isEdit ? 'Save Changes' : 'Create Tag'}
                  </Zbutton>
                </div>
              </Form>
            </div>
          )}
        </ColorContext.Consumer>
      </Modal>
    </div>
  );
};

export default Form.create<IProps>()(TagsDialog);

import { FundProjectionScreenOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Icon, notification, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import firebase from 'firebase/app';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { trackAnalytics } from '../../utils';
import { connectServer, startSendingChunks } from '../../utils/realtimeStream';
import RenderVideosBlob from '../projects/RenderVideosBlob';
import { RecordingTimer } from '../shared/RecordingTimer';
import { ReactMediaRecorder } from './MediaRecorder';
const { REACT_APP_IS_AWS_REAL_TIME_SAVING = 'false' } = process.env;
const isAWSRealTimeSaving = REACT_APP_IS_AWS_REAL_TIME_SAVING.trim() === 'true' ? true : false;
const VideoRecord = (props: any) => {
  const streamToBeClearedRef = useRef<any>(null)
  const [fileName, setFileName] = React.useState('');
  const [videoS3URL, setVideoS3URL] = React.useState('');
  const [isTimer, shouldStartTimer] = React.useState(false);
  const [hideRecord, setHideRecord] = React.useState(true);
  const [startRecordingTitle, setStartRecordingTitle] = React.useState('Start Recording');
  const [isStopped, setIsStopped] = React.useState(false);
  const [isStopping, setIsStopping] = React.useState(false);
  const [isStartClicked, setIsStartClicked] = React.useState(false);
  useEffect(() => {
    const recordingFileName = `recording-${uuid()}`;
    setFileName(recordingFileName);
    setVideoS3URL(
      `https://zooc-media-files.s3.eu-central-1.amazonaws.com/${recordingFileName}.m3u8`,
    );
  }, []);
  const counterText: any = useRef(null);
  const videoDivRef: any = useRef();
  const fiebaseInstance: any = firebase;
  let recordTimeInSeconds = 0;
  let startTime = moment().utc();
  let endTime = moment().utc();
  let firebaseToken = '';
  const {
    isRecordModal,
    loading,
    sendToFirebase,
    showSendButton,
    maxRecordTime,
    setIsRecording,
    setVideoUrl,
  } = props;

  useEffect(() => {
    return () => {
      streamToBeClearedRef.current &&
        streamToBeClearedRef.current.getTracks().forEach((track: any) => {
          track.stop();
        });
    };
  }, [props.firebaseAuthToken]);

  const getFirebaseToken = async () => {
    firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    connectServer(firebaseToken);
  };

  const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      navigator.getUserMedia(
        { audio: true, video: true },
        () => {
          setStartRecordingTitle('Start Recording');
        },
        () => {
          setStartRecordingTitle('Please allow to access microphone and camera.');
        },
      );
    }, []);

    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);

    if (!stream) {
      return null;
    }
    return (
      <div
        style={{
          maxHeight: '272px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <video style={{ maxHeight: '272px', width: '90%' }} ref={videoRef} autoPlay />
      </div>
    );
  };

  if (hideRecord) {
    return (
      <div
        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', height: '100%' }}
      >
        <Button
          type='primary'
          htmlType='submit'
          style={{ width: '200px' }}
          className='settings-form-buttons'
          onClick={async () => {
            setHideRecord(false);
            if (isAWSRealTimeSaving) {
              if (props.firebaseAuthToken) {
                isAWSRealTimeSaving && connectServer(props.firebaseAuthToken);
                firebaseToken = props.firebaseAuthToken;
              } else {
                getFirebaseToken();
              }
            }
          }}
        >
          Start Recording
        </Button>
      </div>
    );
  }
  const stopVideoRecording = (message: string) => {
    streamToBeClearedRef.current &&
      streamToBeClearedRef.current.getTracks().forEach((track: any) => {
        track.stop();
      });
    setIsStopped(true);
    shouldStartTimer(false);
    setIsRecording(false);
    trackAnalytics('Chat window', message);
  };
  return (
    <div>
      <ReactMediaRecorder
        fileName={fileName}
        video={isRecordModal === 'video'}
        screen={isRecordModal === 'screen'}
        onStop={(BlobUrl) => {
          !showSendButton ? sendToFirebase(BlobUrl, videoS3URL) : setVideoUrl(BlobUrl);
        }}
        render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream }: any) => {
          if (!isTimer && status === 'recording') {
            setIsStopped(true);
            stopRecording();
            trackAnalytics('Chat window', 'Stop recording');
            streamToBeClearedRef.current &&
              streamToBeClearedRef.current.getTracks().forEach((track: any) => {
                track.stop();
              });
          }
          if (isTimer && (status === 'idle' || status === 'stopped')) {
            startRecording();
            setIsStopped(false);
            setIsStartClicked(true);
          }
          streamToBeClearedRef.current = previewStream;
          if (isRecordModal === 'video') {
            return (
              <div>
                {isTimer && (
                  <RecordingTimer startTimer={shouldStartTimer} maxRecordTime={maxRecordTime} />
                )}
                <div style={{ position: 'relative' }}>
                  {
                    <div
                      key='loader-dev'
                      style={{
                        position: 'absolute',
                        textAlign: 'center',
                        height: '272px',
                        width: '100%',
                        color: 'white',
                        zIndex: 1,
                        fontSize: '40px',
                        display: 'none',
                        textShadow: '1px 0px #000',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      ref={counterText}
                    ></div>
                  }
                  {status === 'recording' && <VideoPreview stream={previewStream} />}
                </div>

                {status === 'stopping' && <div>Loading...</div>}
                {(status === 'idle' || status === 'stopped') && (
                  <div
                    ref={videoDivRef}
                    style={{
                      maxHeight: '272px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ maxHeight: '272px', width: '90%' }}>
                      <RenderVideosBlob videos={[mediaBlobUrl]} id={'video'} showPlayIcon unMute />
                    </div>
                  </div>
                )}
                <div className='media-control'>
                  <Tooltip placement='top' title={startRecordingTitle}>
                    <Button
                      shape={'circle'}
                      type={status === 'recording' ? 'default' : 'primary'}
                      htmlType='submit'
                      disabled={status === 'recording' || isStartClicked}
                      onClick={async () => {
                        if (startRecordingTitle === 'Start Recording') {
                          const windowObj: any = window;
                          if (windowObj && !windowObj.isSocketConnected) {
                            notification.error({
                              message:
                                'Something went wrong with socket connection, Please try again or contact with admin.',
                            });
                            return false;
                          }
                          startSendingChunks(fileName);
                          startTime = moment().utc();
                          setIsStopped(false);
                          setIsStartClicked(true);
                          if (
                            videoDivRef &&
                            videoDivRef.current &&
                            videoDivRef.current.clientHeight
                          ) {
                            counterText.current.style.height = `${videoDivRef.current.clientHeight}px`;
                          }
                          counterText.current.style.display = 'flex';
                          const interval = setInterval(() => {
                            const currentCounterString =
                              (counterText &&
                                counterText.current &&
                                counterText.current.innerHTML) ||
                              'Recording in 3';
                            const currentCounterArray = currentCounterString.split(' ');
                            let currentCounterValue = parseInt(
                              currentCounterArray[currentCounterArray.length - 1],
                              10,
                            );
                            if (currentCounterValue <= 1) {
                              clearInterval(interval);
                              shouldStartTimer(true);
                              setIsRecording(true);
                              trackAnalytics('Chat window', 'Recording video');
                              counterText.current.style.display = 'none';
                            } else {
                              counterText.current.innerHTML = `Recording in ${--currentCounterValue}`;
                            }
                          }, 600);
                        }
                      }}
                    >
                      <VideoCameraOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip placement='top' title={'Stop Recording'}>
                    <Button
                      loading={isStopping}
                      type='primary'
                      shape={'circle'}
                      htmlType='submit'
                      disabled={!(status === 'recording' && !isStopping)}
                      className='stop-button'
                      onClick={() => {
                        setIsStopping(true);
                        endTime = moment().utc();
                        recordTimeInSeconds = moment(endTime).diff(moment(startTime), 'seconds');
                        if (recordTimeInSeconds >= 4) {
                          setIsStopping(false);
                          stopRecording();
                          stopVideoRecording('Video recording stop');
                        } else {
                          setTimeout(() => {
                            setIsStopping(false);
                            stopRecording();
                            stopVideoRecording('Video recording stop');
                          }, (4 - recordTimeInSeconds) * 1000);
                        }
                      }}
                    >
                      {!isStopping && <Icon type='stop' />}
                    </Button>
                  </Tooltip>
                  {showSendButton && (
                    <Tooltip placement='top' title={'Send Recording'}>
                      <Button
                        shape={'circle'}
                        type='primary'
                        disabled={!isStopped}
                        htmlType='submit'
                        loading={loading}
                        onClick={() => {
                          setIsStopped(false);
                          setVideoUrl('');
                          sendToFirebase(mediaBlobUrl, videoS3URL);
                        }}
                      >
                        {!loading && <Icon type='upload' />}
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <div>
                {isTimer && (
                  <RecordingTimer startTimer={shouldStartTimer} maxRecordTime={maxRecordTime} />
                )}
                <div style={{ position: 'relative' }}>
                  {
                    <div
                      key='loader-div'
                      style={{
                        position: 'absolute',
                        textAlign: 'center',
                        height: '272px',
                        width: '100%',
                        color: 'white',
                        zIndex: 1,
                        fontSize: '40px',
                        display: 'none',
                        textShadow: '1px 0px #000',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      ref={counterText}
                    ></div>
                  }
                  {status === 'recording' && (
                    <div>
                      <VideoPreview stream={previewStream} />
                    </div>
                  )}
                </div>
                {status === 'stopping' && <div>Loading...</div>}
                {(status === 'idle' || status === 'stopped') && (
                  <div
                    ref={videoDivRef}
                    style={{
                      maxHeight: '272px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ maxHeight: '272px', width: '90%' }}>
                      <RenderVideosBlob videos={[mediaBlobUrl]} id={'screen'} showPlayIcon unMute />
                    </div>
                    {/* <video
                      style={{ maxHeight: '272px', width: '90%' }}
                      src={mediaBlobUrl}
                      controls
                    /> */}
                  </div>
                )}
                <div className='media-control'>
                  <Tooltip placement='top' title={startRecordingTitle}>
                    <Button
                      shape={'circle'}
                      type={status === 'recording' ? 'default' : 'primary'}
                      htmlType='submit'
                      disabled={status === 'recording' || isStartClicked}
                      onClick={async () => {
                        if (startRecordingTitle === 'Start Recording') {
                          const windowObj: any = window;
                          if (windowObj && !windowObj.isSocketConnected) {
                            notification.error({
                              message:
                                'Something went wrong with socket connection, Please try again or contact with admin.',
                            });
                            return false;
                          }
                          startSendingChunks(fileName);
                          startTime = moment().utc();
                          setIsStopped(false);
                          setIsStartClicked(true);
                          if (
                            videoDivRef &&
                            videoDivRef.current &&
                            videoDivRef.current.clientHeight
                          ) {
                            counterText.current.style.height = `${videoDivRef.current.clientHeight}px`;
                          }
                          counterText.current.style.display = 'flex';
                          counterText.current.innerHTML = 'Recording in 3';
                          const interval = setInterval(() => {
                            const currentCounterString =
                              (counterText &&
                                counterText.current &&
                                counterText.current.innerHTML) ||
                              'Recording in 3';
                            const currentCounterArray = currentCounterString.split(' ');
                            let currentCounterValue = parseInt(
                              currentCounterArray[currentCounterArray.length - 1],
                              10,
                            );
                            if (currentCounterValue <= 1) {
                              clearInterval(interval);
                              shouldStartTimer(true);
                              setIsRecording(true);
                              trackAnalytics('Chat window', 'Screen Recording');
                              counterText.current.style.display = 'none';
                            } else {
                              counterText.current.innerHTML = `Recording in ${--currentCounterValue}`;
                            }
                          }, 600);
                        }
                      }}
                    >
                      <FundProjectionScreenOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip placement='top' title={'Stop Recording'}>
                    <Button
                      loading={isStopping}
                      type='primary'
                      shape={'circle'}
                      htmlType='submit'
                      className='stop-button'
                      disabled={!(status === 'recording' && !isStopping)}
                      onClick={() => {
                        setIsStopping(true);
                        endTime = moment().utc();
                        recordTimeInSeconds = moment(endTime).diff(moment(startTime), 'seconds');
                        if (recordTimeInSeconds >= 4) {
                          setIsStopping(false);
                          stopRecording();
                          stopVideoRecording('Screen recording stop');
                        } else {
                          setTimeout(() => {
                            setIsStopping(false);
                            stopRecording();
                            stopVideoRecording('Screen recording stop');
                          }, (4 - recordTimeInSeconds) * 1000);
                        }
                      }}
                    >
                      {!isStopping && <Icon type='stop' />}
                    </Button>
                  </Tooltip>
                  {showSendButton && (
                    <Tooltip placement='top' title={'Send Recording'}>
                      <Button
                        shape={'circle'}
                        type='primary'
                        disabled={!isStopped}
                        htmlType='submit'
                        loading={loading}
                        onClick={() => {
                          setIsStopped(false);
                          setVideoUrl('');
                          sendToFirebase(mediaBlobUrl, videoS3URL);
                        }}
                      >
                        {!loading && <Icon type='upload' />}
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default VideoRecord;

import { Button, Drawer, Icon, Modal, notification, Tooltip } from 'antd';
import { Progress } from 'antd';
import firebase from 'firebase/app';
import firebaseApp from 'firebase/app';
import 'firebase/storage';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import {
  createProject as createProjectAction,
  updateVideoProject as updateVideoProjectAction,
} from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { getImageFromVideo, sendImageToStore, sendVideoToStore } from '../../utils';
import { trackAnalytics } from '../../utils';
import LeftArrow from './assects/next.png';

interface IProps {
  videoProject: any;
  updateVideoProject: any;
  createProject: any;
  firebaseAuthToken: string;
  profile: any;
  auth: any;
}
const videoFirebaseObject: any = [];

const UploadModal = (props: IProps) => {
  const [visible, setVisible] = React.useState(false);
  const [showArrow, setShowArrow] = React.useState(false);

  const { videoProject, updateVideoProject, createProject } = props;
  React.useEffect(() => {
    window.onbeforeunload = (event: any) => {
      const message = 'Sure you want to leave?';
      if (typeof event === 'undefined') {
        event = window.event;
      }
      if (event) {
        event.returnValue = message;
      }
      return message;
    };
    const firestore = firebase.firestore();
    if (!(props.profile.preferences && props.profile.preferences.seenArrow)) {
      setShowArrow(true);
      firestore
        .collection('users')
        .doc(props.auth.uid)
        .set(
          {
            preferences: {
              seenArrow: true,
            },
          },
          { merge: true },
        );
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  React.useEffect(() => {
    if (!visible) {
      trackAnalytics('Canvas', `Upload progress modal clicked`);
      if (videoProject.length > 0) {
        const videoProjectTemp = videoProject;
        videoProject.map((obj: any, index: number) => {
          if (videoProjectTemp[index].percent === 100 && videoProjectTemp[index].projectId) {
            videoProjectTemp.splice(index, 1);
            videoFirebaseObject.splice(index, 1);
            updateVideoProject(JSON.parse(JSON.stringify(videoProjectTemp)));
            obj.percent =
              obj.percent === 100 && videoProjectTemp[index] && videoProjectTemp[index].projectId;
          }
        });
      }
    }
  }, [visible]);

  React.useEffect(() => {
    const videoProjectTemp = videoProject;
    if (videoProject.length > 0) {
      videoProject.map(async (obj: any, index: number) => {
        if (!obj.status) {
          videoProjectTemp[index].status = 'active';
          const blob: any = await fetch(obj.videoLocalUrl).then((r) => r.blob());
          videoFirebaseObject[index] = sendVideoToStore(blob);
          const imageReady = (imgSrc: any) => {
            videoFirebaseObject[index].imgSrc = imgSrc;
            videoProjectTemp[index].imgSrc = imgSrc;
            updateVideoProject(JSON.parse(JSON.stringify(videoProjectTemp)));
          };
          getImageFromVideo(blob, imageReady);
          videoFirebaseObject[index].on(
            'state_changed',
            (snapshot: any) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              videoProjectTemp[index].percent = progress;
              updateVideoProject(JSON.parse(JSON.stringify(videoProjectTemp)));
            },
            () => {
              notification.error({
                description: '',
                message: 'upload cancelled!',
              });
            },
            () => {
              const { firebaseAuthToken } = props;
              const { profile } = props;
              videoFirebaseObject[index].snapshot.ref
                .getDownloadURL()
                .then(async (downloadURL: any) => {
                  const mediaURL = downloadURL;
                  const imgFirebaseUrl = await sendImageToStore(obj.imgSrc);
                  let userFirebaseAuthToken = firebaseAuthToken;
                  const fiebaseInstance: any = firebaseApp;
                  if (
                    !firebaseAuthToken &&
                    fiebaseInstance &&
                    fiebaseInstance.auth() &&
                    fiebaseInstance.auth().currentUser
                  ) {
                    userFirebaseAuthToken = await fiebaseInstance
                      .auth()
                      .currentUser.getIdToken(true);
                  }
                  createProject(
                    {
                      title: obj.currentProjectTitle,
                      project_url: mediaURL,
                      member_emails: [],
                      type: obj.projectType,
                      imageType: 'computer',
                      jiraIssueUrl: '',
                      trelloIssueUrl: '',
                      project_tags: obj.allTags || [],
                      appendVideo: obj.appendVideo || '',
                      thumbnail_url: imgFirebaseUrl,
                    },
                    userFirebaseAuthToken,
                  ).then((insertedId: any) => {
                    if (insertedId) {
                      videoProjectTemp[index].projectId = insertedId;
                      const slackMessage = `${profile.fullName} created ${obj.projectType} type '${obj.currentProjectTitle}' project`;
                      const notificationId = uuid();
                      const projectURL = `${window.location.origin}/c/${insertedId}?notificationId=${notificationId}`;
                      userFirebaseAuthToken &&
                        sendMessageToSlack(
                          userFirebaseAuthToken,
                          `${slackMessage}`,
                          insertedId,
                          projectURL,
                          profile.initials ? profile.initials : '',
                          notificationId,
                        );
                      updateVideoProject(JSON.parse(JSON.stringify(videoProjectTemp)));
                    }
                  });
                });
            },
          );
        }
      });
    }
  }, [videoProject]);

  const deleteProject = async (index: any) => {
    await videoFirebaseObject[index].cancel();
    videoProject.splice(index, 1);
    videoFirebaseObject.splice(index, 1);
    updateVideoProject(JSON.parse(JSON.stringify(videoProject)));
  };

  return (
    <span id={'upload-file-modal'} style={{ marginRight: '15px' }}>
      {showArrow && (
        <span className='bounce upload-modal-arrow'>
          <img style={{ width: '20px' }} src={LeftArrow} alt={'arrow'} />
        </span>
      )}
      <Tooltip placement='bottom' title='Pending Canvases'>
        <Button
          onClick={() => {
            setVisible(true);
            setShowArrow(false);
          }}
          type='primary'
          shape='circle'
          size='large'
        >
          <Icon
            style={{ fontSize: '20px', marginLeft: '9px', marginTop: '3px' }}
            type='cloud-upload'
          />
        </Button>
      </Tooltip>
      <Drawer
        title='Upload Status'
        placement='right'
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        className={'sidebar-comment-drawer'}
      >
        <div style={{ overflowY: 'scroll', maxHeight: '95vh', overflowX: 'hidden' }}>
          {videoProject.map((obj: any, index: number) => {
            const { percent = 0 } = obj;
            return (
              <div style={{ marginBottom: '10px' }}>
                <div>{obj.currentProjectTitle}</div>
                <Progress
                  strokeColor={'#039BE5'}
                  percent={percent ? Math.floor(percent) : 0}
                  size='small'
                  status={percent === 100 ? 'success' : 'active'}
                />
                {percent !== 100 ? (
                  <>
                    {videoFirebaseObject[index] &&
                    videoFirebaseObject[index].state_ === 'running' ? (
                      <Tooltip placement='bottom' title='Pause'>
                        <Icon
                          style={{ padding: 5 }}
                          onClick={() => videoFirebaseObject[index].pause()}
                          type='pause'
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip placement='bottom' title='Play'>
                        <Icon
                          style={{ padding: 5 }}
                          type='caret-right'
                          onClick={() => videoFirebaseObject[index].resume()}
                        />
                      </Tooltip>
                    )}
                    <Tooltip placement='bottom' title='Delete Project'>
                      <Icon
                        style={{ padding: 5 }}
                        onClick={() => {
                          Modal.confirm({
                            content: '',
                            title: 'Do you want to delete this project?',
                            onOk() {
                              deleteProject(index);
                            },
                          });
                        }}
                        type='delete'
                      />
                    </Tooltip>
                  </>
                ) : null}
                {obj.projectId && (
                  <Tooltip placement='bottom' title='Open in a new tab'>
                    <Icon
                      onClick={() => {
                        window.open(`https://app.zooc.io/project/${obj.projectId}`, '_blank');
                        // videoProject.splice(index, 1);
                        // videoFirebaseObject.splice(index, 1);
                        // updateVideoProject(JSON.parse(JSON.stringify(videoProject)));
                      }}
                      style={{ padding: 5 }}
                      type='link'
                    />
                  </Tooltip>
                )}
              </div>
            );
          })}
        </div>
      </Drawer>
    </span>
  );
};

const mapStateToProps = (state: any) => {
  return {
    firebaseAuthToken: state.auth.firebaseAuthToken,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, any>) => {
  return {
    updateVideoProject: (project: any) => dispatch(updateVideoProjectAction(project)),
    createProject: (project: any, firebaseAuthToken: string) =>
      dispatch(createProjectAction(project, firebaseAuthToken)),
  };
};

export default connect<any>(
  mapStateToProps,
  mapDispatchToProps,
)(UploadModal) as any;

import { Button, Input } from 'antd';
import firebase from 'firebase';
import React, { Fragment, useEffect, useState } from 'react';
import { trackAnalytics } from 'utils';
import { IOrgProject } from '../../../types/projectTypes';
import { mobileCheck } from '../../../utils';
import CanvasFrame from './CanvasFrame';
import DashboardFrame from './DashboardFrame';
import OrgColorList from './OrgColorList';
import OrgLogo from './OrgLogo';

const isMobile = mobileCheck();
interface IProps {
  orgId: string;
  selectedProject: IOrgProject;
  profile: any;
  selectedOrganizationData: any;
  projects: IOrgProject[];
}

const OrgProjecColor: React.FC<IProps> = ({
  selectedProject,
  orgId,
  profile,
  selectedOrganizationData,
  projects
}) => {
  const { projectColors, projectName, id } = selectedProject;
  const [isEdit, setIsEdit] = useState(false);
  const [projectTitle, setProjectTitle] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [background, setBackground] = useState('#001DAD');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const activeProjectCount = projects && projects.filter((project: IOrgProject) => !project.isDeleted).length;
  let userQuota: any = {};
  let plan = '';
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
    plan = selectedOrganizationData.plan;
  } else {
    userQuota = profile.userQuota;
    plan = profile.plan;
  }

  const handleClick = (color: string, colorName: string) => {
    trackAnalytics('Settings', 'Opened Picker for' + colorName);
    setDisplayColorPicker(!displayColorPicker);
    setSelectedColor(colorName);
    setBackground(color);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
    setSelectedColor('');
  };

  useEffect(() => {
    return () => {
      setProjectTitle(projectName);
    };
  }, [projectName]);

  const handleChangeColor = (color: any, pickedColor: string) => {
    const firestore = firebase.firestore();
    const orgRef = firestore.collection('organization').doc(orgId);
    orgRef.get().then((doc: any) => {
      if (doc.exists) {
        const projects = doc.data().projects;
        const newColor = projects.map((project: any) => {
          if (project.id === id) {
            return {
              ...project,
              projectColors: {
                ...projectColors,
                [pickedColor.split('.')[0]]: {
                  // @ts-ignore
                  ...projectColors[pickedColor.split('.')[0]],
                  [pickedColor.split('.')[1]]: color.hex,
                },
              },
            };
          } else {
            return project;
          }
        });
        orgRef.set(
          {
            projects: newColor,
          },
          { merge: true },
        );
      }
    });
  };

  const updateProjectTitle = (value: string, isUpdate: boolean) => {
    if (isUpdate) {
      const firestore = firebase.firestore();
      const orgRef = firestore.collection('organization').doc(orgId);
      orgRef.get().then((doc: any) => {
        if (doc.exists) {
          trackAnalytics('Settings', 'Changed Project Title');
          const projects = doc.data().projects;
          const updatedTitle = projects.map((project: any) => {
            if (project.id === id) {
              return {
                ...project,
                projectName: value,
              };
            } else {
              return project;
            }
          });
          orgRef.set(
            {
              projects: updatedTitle,
            },
            { merge: true },
          );
        }
      });
    } else {
      setProjectTitle(value);
    }
  };

  const deleteOrgProject = () => {
    const firestore = firebase.firestore();
    const orgRef = firestore.collection('organization').doc(orgId);
    orgRef.get().then((doc: any) => {
      trackAnalytics('Settings', 'Delete Project Clicked');
      if (doc.exists) {
        const projects = doc.data().projects;
        const deletedProject = projects.map((project: any) => {
          if (project.id === id) {
            return {
              ...project,
              isDeleted: true,
            };
          } else {
            return project;
          }
        });
        orgRef.set(
          {
            projects: deletedProject,
          },
          { merge: true },
        );
      }
    });
  };

  const { backgrounds, text, primaryBtn, secondaryBtn, dangerBtn } = projectColors;

  return (
    <div className='project-detail-container'>
      {projectColors && backgrounds ? (
        <Fragment>
          <div className='brand-image-container'>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {!isMobile && <OrgLogo selectedProject={selectedProject} orgId={orgId} />}
              <div className='project-title-container'>
                {!isEdit && (
                  <div className='project-detail-title'>
                    <div
                      className='canvas-detail-title-text cursor-pointer'
                      onClick={() => {
                        setIsEdit(true);
                        updateProjectTitle(projectName, false);
                      }}
                    >
                      {projectName}
                    </div>
                    {id && (
                      <span
                        className='edit-project-title cursor-pointer'
                        onClick={() => {
                          setIsEdit(true);
                          updateProjectTitle(projectName, false);
                        }}
                      >
                        Edit
                      </span>
                    )}
                  </div>
                )}
                {isEdit && (
                  <div className='project-detail-title'>
                    <Input
                      className='canvas-title-edit-input'
                      placeholder='Enter project title'
                      value={projectTitle}
                      onChange={(e: any) => {
                        updateProjectTitle(e.target.value, false);
                      }}
                      onBlur={() => {
                        updateProjectTitle(projectTitle, true);
                        setIsEdit(false);
                      }}
                      onKeyPress={(event: any) => {
                        if (event.key === 'Enter') {
                          updateProjectTitle(projectTitle, true);
                          setIsEdit(false);
                        }
                      }}
                    />
                  </div>
                )}
                {/* <span>Ante vel nisl vestibulum tristique nibh.</span> */}
              </div>
            </div>
            {plan !== 'freelancer' && !isMobile && activeProjectCount > 1 && (
              <Button className='delete-org-btn organization-btn' onClick={deleteOrgProject}>
                DELETE PROJECT
              </Button>
            )}
          </div>
          {userQuota && userQuota.whitelabel && !isMobile ? (
            <>
              <div className='project-color-container'>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>Backgrounds</p>
                  <div className='colors'>
                    <OrgColorList
                      bgColor={backgrounds.color1}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      colorName={'backgrounds.color1'}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={backgrounds.color2}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      colorName={'backgrounds.color2'}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={backgrounds.color3}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      colorName={'backgrounds.color3'}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                  </div>
                </span>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>Text</p>
                  <div className='colors'>
                    <OrgColorList
                      bgColor={text.color1}
                      colorName={'text.color1'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={text.color2}
                      colorName={'text.color2'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={text.color3}
                      colorName={'text.color3'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                  </div>
                </span>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>Buttons A</p>
                  <div className='colors'>
                    <OrgColorList
                      bgColor={primaryBtn.color1}
                      colorName={'primaryBtn.color1'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={primaryBtn.color2}
                      colorName={'primaryBtn.color2'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={primaryBtn.color3}
                      colorName={'primaryBtn.color3'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                  </div>
                </span>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>Buttons B</p>
                  <div className='colors'>
                    <OrgColorList
                      bgColor={secondaryBtn.color1}
                      colorName={'secondaryBtn.color1'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={secondaryBtn.color2}
                      colorName={'secondaryBtn.color2'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={secondaryBtn.color3}
                      colorName={'secondaryBtn.color3'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                  </div>
                </span>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>Buttons C</p>
                  <div className='colors'>
                    <OrgColorList
                      bgColor={dangerBtn.color1}
                      colorName={'dangerBtn.color1'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={dangerBtn.color2}
                      colorName={'dangerBtn.color2'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                    <OrgColorList
                      bgColor={dangerBtn.color3}
                      colorName={'dangerBtn.color3'}
                      selectedColor={selectedColor}
                      handleClick={handleClick}
                      background={background}
                      handleClose={handleClose}
                      displayColorPicker={displayColorPicker}
                      handleChangeColor={handleChangeColor}
                    />
                  </div>
                </span>
              </div>
              <p>Theme Customization</p>
              <span className='frams-container'>
                <span className='frams'>
                  <DashboardFrame projectColors={projectColors} />
                </span>
                <span className='frams'>
                  <CanvasFrame projectColors={projectColors} />
                </span>
              </span>
            </>
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
};

export default OrgProjecColor;

import React from 'react';

export default () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M18.4203 8.03866C18.5357 8.15407 18.6005 8.3106 18.6005 8.47381C18.6005 8.63702 18.5357 8.79355 18.4203 8.90895L15.3742 11.955L18.4203 15.001C18.5357 15.1164 18.6005 15.2729 18.6005 15.4361C18.6005 15.5994 18.5357 15.7559 18.4203 15.8713C18.3048 15.9867 18.1483 16.0515 17.9851 16.0515C17.8219 16.0515 17.6654 15.9867 17.55 15.8713L14.0688 12.3901C13.9534 12.2747 13.8885 12.1182 13.8885 11.955C13.8885 11.7918 13.9534 11.6352 14.0688 11.5198L17.55 8.03866C17.6654 7.92325 17.8219 7.85842 17.9851 7.85842C18.1483 7.85842 18.3048 7.92325 18.4203 8.03866Z'
      fill='#333333'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M22.984 11.9245C22.9841 12.0055 22.9682 12.0856 22.9373 12.1604C22.9064 12.2352 22.861 12.3032 22.8038 12.3604C22.7465 12.4177 22.6786 12.463 22.6038 12.494C22.529 12.5249 22.4488 12.5408 22.3678 12.5407L14.5352 12.5407C14.4543 12.5407 14.3742 12.5247 14.2994 12.4938C14.2247 12.4628 14.1567 12.4174 14.0995 12.3602C14.0423 12.303 13.9969 12.2351 13.9659 12.1603C13.935 12.0855 13.919 12.0054 13.919 11.9245C13.919 11.8436 13.935 11.7635 13.9659 11.6887C13.9969 11.614 14.0423 11.546 14.0995 11.4888C14.1567 11.4316 14.2247 11.3862 14.2994 11.3552C14.3742 11.3243 14.4543 11.3083 14.5352 11.3083L22.3678 11.3083C22.4488 11.3082 22.529 11.3241 22.6038 11.355C22.6786 11.386 22.7465 11.4313 22.8038 11.4886C22.861 11.5458 22.9064 11.6138 22.9373 11.6886C22.9682 11.7634 22.9841 11.8436 22.984 11.9245ZM9.92962 11.9245C9.92972 12.0055 9.91386 12.0856 9.88293 12.1604C9.852 12.2352 9.80662 12.3032 9.74938 12.3604C9.69214 12.4177 9.62418 12.463 9.54937 12.494C9.47457 12.5249 9.3944 12.5408 9.31346 12.5407L1.48082 12.5407C1.39991 12.5407 1.31978 12.5247 1.24502 12.4938C1.17027 12.4628 1.10234 12.4174 1.04513 12.3602C0.98791 12.303 0.942523 12.2351 0.911558 12.1603C0.880593 12.0855 0.864655 12.0054 0.864655 11.9245C0.864655 11.8436 0.880593 11.7635 0.911558 11.6887C0.942523 11.614 0.98791 11.546 1.04513 11.4888C1.10234 11.4316 1.17027 11.3862 1.24503 11.3552C1.31978 11.3243 1.39991 11.3083 1.48082 11.3083L9.31346 11.3083C9.3944 11.3082 9.47457 11.3241 9.54937 11.355C9.62418 11.386 9.69214 11.4313 9.74938 11.4886C9.80662 11.5458 9.852 11.6138 9.88293 11.6886C9.91386 11.7634 9.92972 11.8436 9.92962 11.9245Z'
      fill='#333333'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.42862 8.03866C5.54403 7.92325 5.70056 7.85842 5.86377 7.85842C6.02698 7.85842 6.18351 7.92325 6.29892 8.03866L9.78009 11.5198C9.89549 11.6352 9.96033 11.7918 9.96033 11.955C9.96033 12.1182 9.89549 12.2747 9.78009 12.3901L6.29892 15.8713C6.18351 15.9867 6.02698 16.0515 5.86377 16.0515C5.70056 16.0515 5.54403 15.9867 5.42862 15.8713C5.31322 15.7559 5.24838 15.5994 5.24838 15.4362C5.24838 15.2729 5.31321 15.1164 5.42862 15.001L8.47465 11.955L5.42862 8.90895C5.31322 8.79355 5.24838 8.63702 5.24838 8.47381C5.24838 8.3106 5.31322 8.15407 5.42862 8.03866Z'
      fill='#333333'
    />
  </svg>
);

import { CANVAS_FILTER_THREADS, SHOW_ADD_VIDEO_CANVAS } from '../../types/newCanvasTypes';
const { REACT_APP_PDF_TO_PNG_SERVER } = process.env;

export const ShowNewVideoCanvas = (data: any) => {
  return {
    type: SHOW_ADD_VIDEO_CANVAS,
    payload: data,
  };
};

export const canvasFilterThreads = (data: any) => {
  return {
    type: CANVAS_FILTER_THREADS,
    payload: data,
  };
};

export const convertPdfToPng = (firebaseToken: string, pdfURL: string) => {
  return fetch(`${REACT_APP_PDF_TO_PNG_SERVER}/pdf-url-to-image`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ firebaseToken, pdfURL }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

export const uploadPdfByURLAction = (firebaseToken: string, pdfURL: string) => {
  return fetch(`${REACT_APP_PDF_TO_PNG_SERVER}/uploadPdfByURL`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ firebaseToken, pdfURL }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(() => {
      return { error: true, message: 'Something went wrong.' };
    });
};

import { v4 as uuid } from 'uuid';

const images = [
  'assets/login-img/people-2564955_1280.jpg',
  'assets/login-img/woman-792162_1280.jpg',
  'assets/login-img/office-620817_1280.jpg',
  'assets/login-img/student-849825_1280.jpg',
  'assets/login-img/zooc1.png',
  'assets/login-img/zooc2.png',
  'assets/login-img/zooc3.png',
  'assets/login-img/zooc4.png',
  'assets/login-img/zooc5.png',
  'assets/login-img/zooc6.png',
  'assets/login-img/zooc7.png',
  'assets/login-img/zooc8.png',
  'assets/login-img/zooc9.png',
  'assets/login-img/zooc10.png',
];

export const randomImage = images[Math.floor(Math.random() * images.length)];

interface Iworkflow {
  step: string;
  total: number;
  color: string;
  id: string;
}

interface IProjectgColors {
  backgrounds: {
    color1: string;
    color2: string;
    color3: string;
  };
  text: {
    color1: string;
    color2: string;
    color3: string;
  };
  primaryBtn: {
    color1: string;
    color2: string;
    color3: string;
  };
  secondaryBtn: {
    color1: string;
    color2: string;
    color3: string;
  };
  dangerBtn: {
    color1: string;
    color2: string;
    color3: string;
  };
}

export const getDefaultWorkflow = () => {
  const workflow: Iworkflow[] = [
    { step: 'Open', total: 0, color: '#828282', id: uuid() },
    { step: 'In Progress', total: 0, color: ' #2D9CDB', id: uuid() },
    { step: 'Review', total: 0, color: '#F2C94C', id: uuid() },
    { step: 'Blocked', total: 0, color: '#EB5757', id: uuid() },
    { step: 'Completed', total: 0, color: '#27AE60', id: uuid() },
  ];
  return workflow;
};

export const colors: string[] = [
  '#EB5757',
  '#F2994A',
  '#F2C94C',
  '#219653',
  '#4F4F4F',
  '#6FCF97',
  '#2F80ED',
  '#2D9CDB',
  '#56CCF2',
  '#9B51E0',
  '#BB6BD9',
  '#333333',
  '#828282',
  '#BDBDBD',
  // '#FFFFFF',
];

export const projectColors: IProjectgColors = {
  backgrounds: {
    color1: '#ECF5FF',
    color2: '#FFFFFF',
    color3: '#BDBDBD',
  },
  text: {
    color1: '#333333',
    color2: '#4F4F4F',
    color3: '#828282',
  },
  primaryBtn: {
    color1: '#001DAD',
    color2: '#306AE1',
    color3: '#FFFFFF',
  },
  secondaryBtn: {
    color1: '#E0E0E0',
    color2: '#F2F2F2',
    color3: '#4F4F4F',
  },
  dangerBtn: {
    color1: '#EB5757',
    color2: '#F57A7A',
    color3: '#FFFFFF',
  },
};

import React from 'react';

export default () => (
  <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M4.14602 7.35396C4.09946 7.30751 4.06252 7.25234 4.03731 7.19159C4.0121 7.13085 3.99913 7.06573 3.99913 6.99996C3.99913 6.93419 4.0121 6.86907 4.03731 6.80833C4.06252 6.74758 4.09946 6.69241 4.14602 6.64596L6.79302 3.99996L4.14602 1.35396C4.09953 1.30747 4.06266 1.25228 4.0375 1.19154C4.01234 1.1308 3.99939 1.0657 3.99939 0.999961C3.99939 0.934217 4.01234 0.869116 4.0375 0.808377C4.06266 0.747637 4.09953 0.692448 4.14602 0.64596C4.19251 0.599473 4.2477 0.562596 4.30844 0.537437C4.36918 0.512278 4.43428 0.499329 4.50002 0.499329C4.56577 0.499329 4.63087 0.512278 4.69161 0.537437C4.75235 0.562596 4.80753 0.599473 4.85402 0.645961L7.85402 3.64596C7.90059 3.69241 7.93753 3.74758 7.96273 3.80833C7.98794 3.86907 8.00092 3.93419 8.00092 3.99996C8.00092 4.06573 7.98794 4.13085 7.96273 4.19159C7.93753 4.25234 7.90059 4.30752 7.85402 4.35396L4.85402 7.35396C4.80758 7.40052 4.7524 7.43747 4.69166 7.46267C4.63091 7.48788 4.56579 7.50085 4.50002 7.50085C4.43425 7.50085 4.36913 7.48788 4.30839 7.46267C4.24764 7.43747 4.19247 7.40052 4.14602 7.35396Z'
      fill='black'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.5 4C7.5 4.13261 7.44732 4.25979 7.35355 4.35355C7.25979 4.44732 7.13261 4.5 7 4.5L0.5 4.5C0.367392 4.5 0.240214 4.44732 0.146446 4.35355C0.0526781 4.25978 3.21184e-08 4.13261 4.37114e-08 4C5.53044e-08 3.86739 0.0526782 3.74021 0.146446 3.64645C0.240214 3.55268 0.367392 3.5 0.5 3.5L7 3.5C7.13261 3.5 7.25979 3.55268 7.35355 3.64645C7.44732 3.74021 7.5 3.86739 7.5 4Z'
      fill='black'
    />
  </svg>
);

import {
  ADD_VIDEO_PROJECT,
  CREATE_PROJECT_ERROR,
  CREATE_PROJECT_SUCCESS,
  LOADING,
  OPEN_CHAT_WINDOW,
  OPEN_COMMENT_WINDOW,
  ProjectTypes,
  SAVE_IFRAME,
  SHOULD_DISABLE_UNDO_REDO,
  STOP_LOADING,
  UPDATE_VIDEO_PROJECT,
} from '../../types/projectTypes';
const persistCommentWindow: any = localStorage.getItem('persistCommentWindow');
const initState = {
  loading: false,
  videoProject: [],
  persistCommentWindow: JSON.parse(persistCommentWindow),
};

const projectReducer = (state = initState, action: ProjectTypes) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case STOP_LOADING:
      return { ...state, loading: false };
    case CREATE_PROJECT_SUCCESS:
      return { ...state, loading: false };
    case CREATE_PROJECT_ERROR:
      return { ...state, loading: false };
    case SAVE_IFRAME: {
      return { ...state, iframe: action.payload };
    }
    case SHOULD_DISABLE_UNDO_REDO: {
      return { ...state, undoOrRedo: action.payload };
    }
    case OPEN_CHAT_WINDOW: {
      return { ...state, isOpenChatWindow: action.payload };
    }
    case OPEN_COMMENT_WINDOW: {
      localStorage.setItem('persistCommentWindow', action.payload);
      return { ...state, persistCommentWindow: action.payload };
    }
    case ADD_VIDEO_PROJECT: {
      return { ...state, videoProject: [...state.videoProject, action.payload] };
    }
    case UPDATE_VIDEO_PROJECT: {
      return { ...state, videoProject: action.payload };
    }
    default:
      return state;
  }
};

export default projectReducer;

import React from 'react';

export default () => (
  <svg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M30.4604 22.5269C31.1271 22.9118 31.1271 23.8741 30.4604 24.259L20.7104 29.8881C20.0438 30.273 19.2104 29.7919 19.2104 29.0221L19.2104 17.7638C19.2104 16.994 20.0438 16.5128 20.7104 16.8977L30.4604 22.5269Z'
      fill='#4F4F4F'
    />
  </svg>
);

import {
  OranizationTypes,
  UPDATE_SELECT_ORGANIZATION_DETAILS,
} from '../../types/organizationTypes';

const initState = {
  selectOrganizationDetails: { userQuota: {} },
};

const organizationReducer = (state = initState, action: OranizationTypes) => {
  switch (action.type) {
    case UPDATE_SELECT_ORGANIZATION_DETAILS:
      return { ...state, selectOrganizationDetails: action.payload };
    default:
      return state;
  }
};

export default organizationReducer;

import { Avatar, Modal, Popover, Tooltip } from 'antd';
import firebase from 'firebase';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { deleteLoginUserAllNotifications } from '../../store/actions/projectActions';
import { Delete, NotificationBell, Notify } from '../svg';
import './style.css';

interface IOwnProps {
  loginUserId: string;
  auth: {
    isEmpty: boolean;
  };
  deleteLoginUserAllNotifications(): void;
}

const Notifications = (props: IOwnProps) => {
  const { loginUserId } = props;
  const [notifications, setNotifications] = useState([]);
  const [unReadCount, setUnreadCount] = useState(false);
  const [visibleNotificationPopover, setVisibleNotificationPopover] = useState(false);

  useEffect(() => {
    if (loginUserId) {
      firebase
        .firestore()
        .collection('notifications')
        .doc(loginUserId)
        .onSnapshot((doc: any) => {
          const notificationsData = doc.data();
          if (notificationsData) {
            let notificationArray = notificationsData.notifications
              ? notificationsData.notifications
              : [];

            notificationArray = notificationArray.sort((a: { createdAt: Date }, b: any) => {
              const result = new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
              return result;
            });
            setNotifications(JSON.parse(JSON.stringify(notificationArray)));
            setUnreadCount(notificationsData.unReadCount ? notificationsData.unReadCount : false);
            const urlParams = new URLSearchParams(window.location.search);
            const notificationId = urlParams.get('notificationId');
            if (notificationId) {
              const deleteNotificationIndex = notificationArray.findIndex(
                (obj: any) => obj.id === notificationId,
              );
              if (deleteNotificationIndex !== -1) {
                const allReverseNotificationArray = JSON.parse(JSON.stringify(notificationArray));
                deleteNotification(allReverseNotificationArray, deleteNotificationIndex);
              }
            }
          }
        });
    }
  }, [loginUserId]);

  const clearAllNotifications = () => {
    Modal.confirm({
      content: '',
      title: 'Are you sure, Do you want to delete all notifications?',
      onOk: () => {
        props.deleteLoginUserAllNotifications();
        setNotifications([]);
        setUnreadCount(false);
      },
    });
  };

  const updateNotificationUnreadCountZero = () => {
    firebase
      .firestore()
      .collection('notifications')
      .doc(loginUserId)
      .set({ unReadCount: false }, { merge: true });
  };

  const deleteNotification = (allNotificationArray: any, index: number) => {
    const allNotifications = JSON.parse(JSON.stringify(allNotificationArray));
    allNotifications.splice(index, 1);
    firebase
      .firestore()
      .collection('notifications')
      .doc(loginUserId)
      .set({ notifications: allNotifications, unReadCount: false }, { merge: true });
  };

  const content =
    notifications && notifications.length ? (
      <>
        <div className='notifications-title'>
          <span>Notifications</span>
          <a
            onClick={() => {
              setVisibleNotificationPopover(false);
              clearAllNotifications();
            }}
          >
            Clear all
          </a>
        </div>
        <div className='notification-window'>
          <ul className='notification-list'>
            {notifications.map((notification, index) => {
              const {
                message = '',
                url,
                initials = 'U',
                createdAt,
                userName = '',
                userImage = '',
              } = notification;
              let dateFormat = 'MMM Do [at] h:mm:ss A z';
              if (
                moment
                  .utc(createdAt)
                  .local()
                  .years() !== new Date().getFullYear()
              ) {
                dateFormat = 'MMM Do, YYYY [at] h:mm:ss A z';
              }
              const dateToolTip =
                moment
                  .utc(createdAt)
                  .local()
                  .format(dateFormat) || '';
              return (
                <li key={index}>
                  <a style={{ textDecoration: 'none' }} href={url}>
                    <div className='avatar-container'>
                      <div className='avatar'>
                        {userImage ? (
                          <Avatar src={userImage} />
                        ) : (
                          <Avatar>{(initials && initials[0].toUpperCase()) || 'U'}</Avatar>
                        )}
                        {/* <Avatar src='https://cdn2.iconfinder.com/data/icons/circle-avatars-1/128/039_girl_avatar_profile_woman_headband-512.png'>
                           {initials.toUpperCase()}
                         </Avatar> */}
                        <div className='avatar-name'>
                          <span>{userName ? userName : 'User'}</span>
                          <Tooltip placement='topLeft' title={dateToolTip}>
                            <span className='notificantion-date'>
                              {moment
                                .utc(createdAt)
                                .local()
                                .fromNow()}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className='hide-delete-btn'
                        onClick={(e) => {
                          e.preventDefault();
                          deleteNotification(notifications, index);
                        }}
                      >
                        <Tooltip title='Delete'>
                          <Delete />
                        </Tooltip>
                      </div>
                    </div>
                    <div className='break-message'>
                      {message.slice(0, 100) + (message.length > 100 ? '...' : '')}
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    ) : (
      <p style={{ padding: 15 }}>Notifications not found.</p>
    );
  return (
    <>
      <Popover
        content={content}
        placement='bottomRight'
        style={{ marginTop: 0 }}
        visible={visibleNotificationPopover}
        onVisibleChange={(currentVisible) => {
          if (currentVisible && unReadCount && loginUserId) {
            updateNotificationUnreadCountZero();
          }
          setVisibleNotificationPopover(currentVisible);
        }}
      >
        <div style={{ position: 'relative', cursor: 'pointer' }}>
          {!!unReadCount ? (
            <div className='notification-badge'>
              <Notify />
            </div>
          ) : null}
          <NotificationBell />
        </div>
      </Popover>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteLoginUserAllNotifications: () => dispatch(deleteLoginUserAllNotifications()),
  };
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Notifications);

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, notification, } from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import {
  createProject as createProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
// import RecordAudio from '../projects/Chat/RecordAudio';
import InterviewAudioRecorder from '../recording/InterviewAudioRecorder';
import './style.css';
import { mobileCheck } from '../../utils';
import { signIn as signInAction } from '../../store/actions/authActions';
import smartgeeksLogo from '../../static/smarkgeeks-logo.svg';
import { Logo } from '../svg/Logo';

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
}

interface ICreds {
  email: string;
  password: string;
}

const InterviewCanvasDialog = (props: any) => {
  const submitBtn: any = useRef(null);
  const [isDetails, setIsDetails]: any = useState(false);

  const [blobObject, setBlobObject]: any = useState({});
  const [timer, setTimer] = useState(0);
  const [isRecording, setRecording] = useState(false);

  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // @ts-ignore
  const params = (new URL(document.location)).searchParams;
  const isSmartGeeks = params.get("sm") || false;
  // const [isAllowMicrophonePermission, setIsAllowMicrophonePermission] = useState(true);
  const { history, createProject, form } = props;

  //   const selectedWorkflowId = 'open-1dd67dc9-caac-4fd1-bf1d-b8f3b9797d2f';
  //   const selectedMasterProjectId = 'selectedMasterProjectId== hKyDrhnVQsZVWSnnobMC';
  //   const memberEmail = 'manrajparmar793+raj1@gmail.com';
  //   const selectedMasterProject = {
  //     member_ids: ['zfLjovANzjaBcItZ4WsgmxHy84231'],
  //   };
  const selectedWorkflowId = props.match.params.workflowId || '664d3444-5685-4577-aa6f-99fddda4dfd3'; // interview canvas workflow id
  // const selectedWorkflowId = 'open-0e9136e8-8893-481e-9198-7cc753b4167f'; // dummpy
  // 'open-754507f5-fd5e-4bc0-9842-4593f3792468';
  // 'open-664d3444-5685-4577-aa6f-99fddda4dfd3';
  const selectedMasterProjectId = props.match.params.projectId || 'h6mJwL1cqZKOfA1Ktlvx'; // interview type master project id (need to confirm)
  // const selectedMasterProjectId = 'UrR8uwZo7yfjw3Y0KfJW'; // dummy
  const memberEmail = 'aemal@zooc.io';
  // const selectedMasterProject = {
  //   member_ids: ['sK4OQ22hYHcSWw4O0LPW3j8zY3K3'],
  // };

  const isMobile = mobileCheck();
  const FormItem = Form.Item;
  const tailFormItemLayout = {
    wrapperCol: {
      //   sm: {
      //     offset: 4,
      //     span: 16,
      //   },
      //   xs: {
      //     offset: 0,
      //     span: 24,
      //   },
    },
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const detail = urlParams.get('detail') || false;
    setIsDetails(detail);
    false &&
      props.signIn({
        email: 'interviewer@gmail.com',
        password: 'interviewer@123',
      });
    // const firestore = firebase.firestore();
    //   firestore
    //     .collection('masterProjects')
    //     .doc(selectedMasterProjectId)
    //     .get()
    //     .then((doc: any) => {
    //     })
  }, []);

  const createInterviewTask = () => {
    submitBtn.current && submitBtn.current.click();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields(async (err: any, values: any) => {
      if (!err) {
        setLoading(true);
        const fileName = 'file' + new Date().getTime();
        const storageRef = firebase
          .storage()
          .ref()
          .child(fileName);
    
          const firebaseUploadObject: any = storageRef.put(blobObject.blob);
          firebaseUploadObject.on(
            'state_changed',
            (snapshot: any) => {
              const progress: any = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setPercentage(progress.toFixed(2));
            },
            () => {
              notification.error({
                description: '',
                message: 'upload cancelled!',
              });
              setLoading(false);
              clearAudio();
            },
            () => {
              return firebaseUploadObject.snapshot.ref.getDownloadURL().then((downloadURL: any) => {
                // clearAudio();
                const audioURL = downloadURL;
              //  createInterviewTask(audioURL);
        const firestore = firebase.firestore();
        firestore
          .collection('masterProjects')
          .doc(selectedMasterProjectId)
          .get()
          .then(async (doc: any) => {
            const selectedMasterProject = doc.data();
            const { fullName, email = '', phone = '', country, company, upworkProfileURL } = values;
            const firestore = firebase.firestore();
            const fullNameChat = await firestore.collection('chat').add({
              count: 4,
              messages: [
                {
                  attachedFileName: '',
                  attachment: '',
                  audioUrl: '',
                  blobURL: '',
                  email: '',
                  feedback: `Name: ${fullName}`,
                  originalImage: '',
                  pageIDValue: '',
                  photoURL: '',
                  sender: fullName,
                  thumbnail: '',
                  time: new Date().getTime(),
                  uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                  userAgent: '',
                  uuid: '51d6d78d-e246-49a0-b81d-d50f1ac9477b',
                  videoUrl: '',
                },
                {
                  attachedFileName: '',
                  attachment: '',
                  audioUrl: '',
                  blobURL: '',
                  email: '',
                  feedback: `Email: ${email}`,
                  originalImage: '',
                  pageIDValue: '',
                  photoURL: '',
                  sender: fullName,
                  thumbnail: '',
                  time: new Date().getTime(),
                  uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                  userAgent: '',
                  uuid: 'c42b6651-a07d-4a82-a570-bc57f966d8bf',
                  videoUrl: '',
                },
                {
                  attachedFileName: '',
                  attachment: '',
                  audioUrl: '',
                  blobURL: '',
                  email: '',
                  feedback: `Phone: ${phone}`,
                  originalImage: '',
                  pageIDValue: '',
                  photoURL: '',
                  sender: fullName,
                  thumbnail: '',
                  time: new Date().getTime(),
                  uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                  userAgent: '',
                  uuid: 'e943acad-e253-4a45-a164-a4837733caef',
                  videoUrl: '',
                },
                {
                  attachedFileName: '',
                  attachment: '',
                  audioUrl: '',
                  blobURL: '',
                  email: '',
                  feedback: `Country: ${country}`,
                  originalImage: '',
                  pageIDValue: '',
                  photoURL: '',
                  sender: fullName,
                  thumbnail: '',
                  time: new Date().getTime(),
                  uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                  userAgent: '',
                  uuid: 'dfc90c6c-58ec-418b-bbf4-6aca1d751c39',
                  videoUrl: '',
                },
                {
                  attachedFileName: '',
                  attachment: '',
                  audioUrl: '',
                  blobURL: '',
                  email: '',
                  feedback: `Company: ${company}`,
                  originalImage: '',
                  pageIDValue: '',
                  photoURL: '',
                  sender: fullName,
                  thumbnail: '',
                  time: new Date().getTime(),
                  uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                  userAgent: '',
                  uuid: 'dfc90c6c-58ec-418b-bbf4-6aca1d751c39',
                  videoUrl: '',
                },
                {
                  attachedFileName: '',
                  attachment: '',
                  audioUrl: '',
                  blobURL: '',
                  email: '',
                  feedback: `Upwork/Freelancer Profile URL: ${upworkProfileURL}`,
                  originalImage: '',
                  pageIDValue: '',
                  photoURL: '',
                  sender: fullName,
                  thumbnail: '',
                  time: new Date().getTime(),
                  uid: 'zfLjovANzjaBcItZ4WsgmxHy84231',
                  userAgent: '',
                  uuid: 'dfc90c6c-58ec-418b-bbf4-6aca1d751c39',
                  videoUrl: '',
                },
              ],
            });
            // const emailChat = await firestore.collection('chat').add({
            //   messages: [],
            //   count: 0,
            // });
            // const phoneChat = await firestore.collection('chat').add({
            //   messages: [],
            //   count: 0,
            // });
            // const countryChat = await firestore.collection('chat').add({
            //   messages: [],
            //   count: 0,
            // });
            const projectData = {
              title: `${fullName}`,
              project_url: audioURL,
              member_emails: [email],
              project_tags: [],
              type: 'audio',
              isInterviewCanvas: true,
              isPublicUser: true,
              workflowId: selectedWorkflowId,
              masterProjectId: selectedMasterProjectId,
              archived: 'no',
              isPublic: true,
              threads: [
                {
                  createdAt: new Date().getTime(),
                  fromLeftPercentage: 7.352939241162668,
                  id: fullNameChat.id,
                  isResolve: false,
                  mediaTime: 1.173333,
                  member: {
                    email: memberEmail,
                    image: '',
                    name: `Candidate Info`,
                  },
                  message: `Name: ${fullName}`,
                  page: null,
                  readUserEmails: [memberEmail],
                  replyCount: 0,
                  sequenceNo: 1,
                },
                // {
                //   createdAt: new Date().getTime(),
                //   fromLeftPercentage: 7.352939241162668,
                //   id: emailChat.id,
                //   isResolve: false,
                //   mediaTime: 1.173333,
                //   member: {
                //     email: memberEmail,
                //     image: '',
                //     name: 'Candidate Info',
                //   },
                //   message: `Email: ${email}`,
                //   page: null,
                //   readUserEmails: [memberEmail],
                //   replyCount: 0,
                //   sequenceNo: 2,
                // },
                // {
                //   createdAt: new Date().getTime(),
                //   fromLeftPercentage: 7.352939241162668,
                //   id: phoneChat.id,
                //   isResolve: false,
                //   mediaTime: 1.173333,
                //   member: {
                //     email: memberEmail,
                //     image: '',
                //     name: 'Candidate Info',
                //   },
                //   message: `Phone: ${phone}`,
                //   page: null,
                //   readUserEmails: [memberEmail],
                //   replyCount: 0,
                //   sequenceNo: 3,
                // },
                // {
                //   createdAt: new Date().getTime(),
                //   fromLeftPercentage: 7.352939241162668,
                //   id: countryChat.id,
                //   isResolve: false,
                //   mediaTime: 1.173333,
                //   member: {
                //     email: memberEmail,
                //     image: '',
                //     name: 'Candidate Info',
                //   },
                //   message: `Country: ${country}`,
                //   page: null,
                //   readUserEmails: [memberEmail],
                //   replyCount: 0,
                //   sequenceNo: 4,
                // },
              ],
            };
            createProject(projectData, '', selectedMasterProject).then(async (insertedId: any) => {
              setLoading(false);
              if (insertedId) {
                history.push(`/audio/${insertedId}?threadId=${fullNameChat.id}`);
              }
            }).catch(() => {
              setLoading(false);
            })
          });
        });
      },
    );
      }else{
        setLoading(false);
      }
    });
  };

  const clearAudio = () => {
    setBlobObject({});
    setRecording(false);
    setIsPaused(false);
    const playButton: any = document.getElementById('record');
    if (playButton) {
      playButton.style.display = 'flex';
    }
    const ResumeButton: any = document.getElementById('resumeAudioRecord');
    ResumeButton.style.display = 'none';
    const settingsButton: any = document.getElementById('mySidenav');
    if (settingsButton) {
      settingsButton.style.display = 'flex';
    }
    setTimer(0);
  };

  return (
    <div
      className='interview-page'
      style={
        isMobile
          ? { width: '90%', margin: '10px 20px' }
          : { width: '500px', display: 'grid', margin: 'auto' }
      }
    > 
    
      { isSmartGeeks ? 
       <img src={smartgeeksLogo} style={{margin: '20px auto 0px auto'}} /> :
       <div style={{margin: '18px auto 0px auto'}}><Logo/></div>
       }
      <p style={{ marginTop: isSmartGeeks ? '20px' : '10px' }}>
        <b>Instruction:</b> Please fill this form and record your voice. In the recording please
        give an introduction about your self and your career. Record at least 1 minute long, but
        feel free to utilize the entire length of the audio recording.
      </p>
      <Form onSubmit={handleSubmit}>
        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('fullName', {
            rules: [
              {
                message: 'Full Name Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Full Name' />)}
        </FormItem>
        {isDetails == 'true' ? (
          <FormItem
            {...tailFormItemLayout}
            style={{
              marginBottom: 10,
              marginRight: 'auto',
              marginLeft: 'auto',
              width: isMobile ? '90%' : '450px',
            }}
          >
            {form.getFieldDecorator('email', {
              rules: [
                {
                  message: 'Email Please!',
                  required: true,
                  type: 'email',
                },
              ],
              initialValue: '',
            })(<Input className='ant-input' placeholder={`Email`} />)}
          </FormItem>
        ) : null}
        {isDetails == 'true' ? (
          <FormItem
            {...tailFormItemLayout}
            style={{
              marginBottom: 10,
              marginRight: 'auto',
              marginLeft: 'auto',
              width: isMobile ? '90%' : '450px',
            }}
          >
            {form.getFieldDecorator('phone', {
              rules: [
                {
                  message: 'Phone Number Please!',
                  required: true,
                },
              ],
            })(<Input className='ant-input' type='text' placeholder='Phone Number' />)}
          </FormItem>
        ) : null}
        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('country', {
            rules: [
              {
                message: 'Country Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Country' />)}
        </FormItem>

        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('company', {
            rules: [
              {
                message: 'Company Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Company' />)}
        </FormItem>

        <FormItem
          {...tailFormItemLayout}
          style={{
            marginBottom: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
            width: isMobile ? '90%' : '450px',
          }}
        >
          {form.getFieldDecorator('upworkProfileURL', {
            rules: [
              {
                message: 'Upwork/Freelancer Profile URL Please!',
                required: true,
              },
            ],
          })(<Input className='ant-input' type='text' placeholder='Upwork/Freelancer Profile URL' />)}
        </FormItem>

        <InterviewAudioRecorder
           blobObject={blobObject}
           setBlobObject={setBlobObject}
           timer={timer}
           setTimer={setTimer}
           isRecording={isRecording}
           setRecording={setRecording}
           loading={loading}
           setLoading={setLoading}
           percentage={percentage}
           setPercentage={setPercentage}
           isPaused={isPaused}
           setIsPaused={setIsPaused}
          //  setIsAllowMicrophonePermission={setIsAllowMicrophonePermission}
          maxRecordTime={2}
          projectID={'dasdasd'}
        //  setLoading={() => null}
          onClose={() => null}
          showPercentage
          onSubmit={createInterviewTask}
          background={'rgb(236, 245, 255)'}
        />
        <input style={{ visibility: 'hidden' }} type='submit' ref={submitBtn} />

        {false && (
          <Form.Item>
            <Button ref={submitBtn} type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        )}
        {/* { !isAllowMicrophonePermission && <>
                <div>
                Unable to access microphone, please allow microphone permission in order to record audio and try again with refresh the page
                </div>
                <img style={
                    isMobile
                        ? { width: '90%', marginTop: '10px' }
                        : { width: '500px', marginTop: '10px' }
                } src="/images/allow-microphone.png" alt="Allow microphone" />
                <img style={
                    isMobile
                        ? { width: '90%', margin: '10px 20px' }
                        : { width: '500px', display: 'grid', margin: 'auto' }
                } src="/images/allow-microphone-in-zooc.png" alt="Allow microphone in zooc" />
                </>} */}
      </Form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    propsLoading: state.project.loading,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetLoader: () => dispatch(resetAction()),
    signIn: (creds: ICreds) => dispatch(signInAction(creds)),
    createProject: (project: Project, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create()(InterviewCanvasDialog as any)) as any;

import React, { useEffect, useState } from 'react';
import './index.css';
import OutsideAlerter from './OutsideAlerter';

const DropDown = (props: any) => {
  const [toggle, setToggle] = useState(false);
  const { buttonComponent, position, classes, items, fixedPosition } = props;
  // useEffect(() => {
  //     // Close the dropdown if the user clicks outside of it
  //     window.onclick = function (event: any) {
  //         if (!event.target.matches('.dropbtn')) {
  //             var dropdowns = document.getElementsByClassName("dropdown-content");
  //             var i;
  //             for (i = 0; i < dropdowns.length; i++) {
  //                 var openDropdown = dropdowns[i];
  //                 if (openDropdown.classList.contains('show')) {
  //                     openDropdown.classList.remove('show');
  //                 }
  //             }
  //         }
  //     }
  // }, []);

  const toggleFunction = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    const bottomControls: any = document.getElementById('bottomControls');
    if (bottomControls && toggle) {
      bottomControls.style.borderRadius = '0px 0px 15px 15px';
    } else if (bottomControls && !toggle) {
      bottomControls.style.borderRadius = '15px';
    }
  }, [toggle]);

  const toggleClose = () => {
    setToggle(false);
  };

  return (
    <>
      <OutsideAlerter toggle={toggle} handleOutSideClick={toggleClose}>
        <div className='dropdown-za'>
          <div onClick={toggleFunction} className='dropbtn'>
            {buttonComponent ? buttonComponent() : 'Select'}
          </div>
          <div
            id='myDropdown'
            className={`
                        ${toggle ? 'show-modal' : ''} dropdown-content-za 
                        ${fixedPosition ? 'fixed-position-drop-down-za' : ''}
                        position-za-${position} ${classes}`}
          >
            {items}
          </div>
        </div>
      </OutsideAlerter>
    </>
  );
};

export default DropDown;

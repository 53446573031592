import { Drawer } from 'antd';
import React from 'react';
import { mobileCheck } from '../../utils';
import { Close } from '../svg';
import Workflow from './Workflow';

const isMobile = mobileCheck();

const WorkflowDrawer = (props: any) => {
  const {
    setWorkflowDrawerVisible,
    WorkflowDrawerVisible,
    selectedMasterProject,
    getCanvases,
    workflowIndex,
  } = props;

  return (
    <div>
      <Drawer
        title='Modify Workflow'
        placement='right'
        closable={false}
        onClose={() => setWorkflowDrawerVisible()}
        visible={WorkflowDrawerVisible}
        style={{ position: 'absolute' }}
        width={isMobile ? '90vw' : '75vw'}
      >
        <div
          style={{ margin: isMobile ? '25px' : undefined }}
          className={isMobile ? '' : 'drawer-container'}
        >
          <Workflow
            isEdit={true}
            selectedMasterProject={selectedMasterProject}
            getCanvases={getCanvases}
            selectedWorkflowIndex={workflowIndex}
          />
        </div>
        <div className='drawer-close-btn' onClick={() => setWorkflowDrawerVisible()}>
          <Close />
        </div>
      </Drawer>
    </div>
  );
};

export default WorkflowDrawer;

import React from 'react';

export default () => (
  <svg width='45' height='58' viewBox='0 0 45 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.7337 33.0393L23.7528 32.9487C24.1236 31.4016 24.5902 29.4725 24.2259 27.7248C23.983 26.346 22.9794 25.8087 22.1229 25.7699C21.1129 25.7246 20.2116 26.3071 19.9879 27.1551C19.5661 28.7087 19.9432 30.8319 20.6335 33.5377C19.7642 35.635 18.3771 38.6839 17.3608 40.4964C15.4688 41.4868 12.9311 43.0145 12.554 44.9435C12.4773 45.2996 12.5668 45.7527 12.7777 46.1605C13.0142 46.6136 13.3913 46.9632 13.8324 47.1315C14.0241 47.2027 14.2543 47.2609 14.5227 47.2609C15.6477 47.2609 17.4695 46.3417 19.8984 42.1212C20.2692 41.9982 20.6527 41.8688 21.0234 41.7393C22.7621 41.1437 24.5646 40.5223 26.1946 40.244C27.9972 41.2214 30.049 41.8493 31.4425 41.8493C32.8232 41.8493 33.3665 41.0208 33.571 40.5223C33.929 39.6484 33.7564 38.548 33.1747 37.9589C32.331 37.1174 30.2791 36.8973 27.0831 37.2987C25.5107 36.3277 24.4815 35.0071 23.7337 33.0393ZM16.7216 42.8721C15.8331 44.1797 15.1619 44.8335 14.7976 45.1183C15.2259 44.3221 16.0632 43.4806 16.7216 42.8721ZM22.321 27.6277C22.6534 28.2038 22.6087 29.9451 22.353 30.8254C22.0398 29.5373 21.995 27.7118 22.1804 27.4982C22.2315 27.5047 22.2763 27.5435 22.321 27.6277ZM22.2188 35.4279C22.9027 36.6254 23.7656 37.6547 24.718 38.4185C23.3374 38.7357 22.0781 39.26 20.9531 39.7261C20.6847 39.8362 20.4226 39.9462 20.1669 40.0498C21.017 38.4897 21.7266 36.7225 22.2188 35.4279ZM32.1648 39.6679C32.1712 39.6808 32.1776 39.7002 32.1392 39.7261H32.1264L32.1136 39.7455C32.0625 39.7779 31.5383 40.0886 29.282 39.1888C31.8771 39.0659 32.1584 39.6614 32.1648 39.6679ZM44.3991 14.5388L30.6435 0.608482C30.2599 0.220089 29.7422 0 29.1989 0H2.04545C0.914062 0 0 0.92567 0 2.07143V55.9286C0 57.0743 0.914062 58 2.04545 58H42.9545C44.0859 58 45 57.0743 45 55.9286V16.0083C45 15.458 44.7827 14.9272 44.3991 14.5388ZM40.2827 16.9598H28.2528V4.77723L40.2827 16.9598ZM40.3977 53.3393H4.60227V4.66071H23.9062V18.6429C23.9062 19.3639 24.1891 20.0554 24.6926 20.5653C25.196 21.0752 25.8789 21.3616 26.5909 21.3616H40.3977V53.3393Z'
      fill='#4F4F4F'
    />
  </svg>
);

import { Button, Drawer, Icon, Modal, Tooltip } from 'antd';
import Clipboard from 'clipboard';
import firebase from 'firebase/app';
import 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { trackAnalytics } from '../../utils';
import TeamList from '../team';

interface IProps {
  closeShareProject?: any;
  visible: boolean;
  setVisible: (param: boolean) => void;
  title: string;
  project?: {
    id: string;
    project_url: string;
    imagebase64?: any;
    title: any;
    imageUrl?: string;
    thumbnail_url?: string;
  };
  handleProjectUrl: (url: string) => void;
}
export const ProjectForm: (props: IProps) => JSX.Element = ({
  visible,
  setVisible,
  title,
  project,
  closeShareProject,
  handleProjectUrl,
}): JSX.Element => {
  const [copied, setCopied] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  let urlRefs: any = '';

  const handleOk = () => {
    setVisible(false);
    closeShareProject && closeShareProject();
  };

  const b64toBlob = (dataURI: any) => {
    dataURI = dataURI.replace(/^data:image\/[a-z]+;base64,/, '');
    const byteString = atob(dataURI);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  };

  const apiData = async () => {
    if (project && project.id) {
      if (project.imagebase64 && !project.imageUrl) {
        setLoading(true);
        const blob = b64toBlob(project.imagebase64);
        const fileName = 'file' + new Date().getTime();
        const storageRef = firebase
          .storage()
          .ref()
          .child(fileName);
        return storageRef.put(blob).then((videoData: any) => {
          return videoData.ref.getDownloadURL().then((url: string) => {
            const firestore = firebase.firestore();
            firestore
              .collection('projects')
              .doc(project.id)
              .update({ imageUrl: url });
            setLoading(false);
            handleProjectUrl(url);
            return url;
          });
        });
      }
    }
  };

  useEffect(() => {
    apiData();
  }, [project]);

  useEffect(() => {
    if (copied || copiedEmail) {
      setTimeout(() => {
        setCopied(false);
        setCopiedEmail(false);
      }, 1500);
    }
  }, [copied, copiedEmail]);

  const copyUrl = () => {
    const textArea = document.createElement('textarea');
    textArea.value = urlRefs.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    setCopied(true);
    trackAnalytics('Project List', 'Project link copied', '');
  };

  const getBaseURL = (projectId: string) => {
    const url = window.location.href;
    const splitUrl = url.split('/');
    return `${splitUrl[0]}//${splitUrl[2]}/project/${projectId}`;
  };
  const onDrawerClose = () => {
    setDrawerVisible(false);
  };
  const handleCopyClick = () => {
    let thumbnailUrl =
      'https://firebasestorage.googleapis.com/v0/b/feedback-hero.appspot.com/o/file1587479432140?alt=media&token=97a52f5e-2add-409d-a03b-d2da7e7d3ec1';
    if (project && project.id) {
      thumbnailUrl = project.imageUrl
        ? project.imageUrl
        : project.thumbnail_url
        ? project.thumbnail_url
        : thumbnailUrl;
    }

    const tableMarkup = `
      <div>
      <div style="text-align: center;">
          <img
              style="height: 20px; margin-bottom: 20px;"
              src="https://zooc.io/assets/img/logos/logo.png"
              alt="zooc.io" />
      </div>
      <div>
        <div style="text-align: center;">
          <div>${project && project.title}</div>
          <a href="${project && project.id && getBaseURL(project.id)}" target="_blank">
            <img
                style="height: 140px; border-radius: 10px"
                src="${thumbnailUrl}"
            />
          </a>
          </div>
        </div>
      </div>`;
    const clipboard = new Clipboard(`#copybtn${project && project.id}`, {
      target: () => {
        setCopiedEmail(true);
        let fakeHandler: any = null;
        let fakeHandlerCallback: any = null;
        let fakeElem: any = null;

        const removeFake = () => {
          if (fakeHandler) {
            document.body.removeEventListener('click', fakeHandlerCallback);
            fakeHandler = null;
            fakeHandlerCallback = null;
          }
          if (fakeElem) {
            document.body.removeChild(fakeElem);
            fakeElem = null;
          }
        };

        const isRTL = document.documentElement.getAttribute('dir') === 'rtl';

        removeFake();

        fakeHandlerCallback = () => {
          return removeFake();
        };

        fakeElem = document.createElement('div');
        // Prevent zooming on iOS
        fakeElem.style.fontSize = '12pt';
        // Reset box model
        fakeElem.style.border = '0';
        fakeElem.style.padding = '0';
        fakeElem.style.margin = '0';
        // Move element out of screen horizontally
        fakeElem.style.position = 'absolute';
        fakeElem.style[isRTL ? 'right' : 'left'] = '-9999px';
        // Move element to the same position vertically
        const yPosition = window.pageYOffset || document.documentElement.scrollTop;
        fakeElem.addEventListener('focus', window.scrollTo(0, yPosition));
        fakeElem.style.top = yPosition + 'px';

        fakeElem.setAttribute('readonly', '');
        fakeElem.innerHTML = tableMarkup;
        // fakeElem.innerHTML = videoelementString;
        document.body.appendChild(fakeElem);

        return fakeElem;
      },
    });
    clipboard.on('success', (e) => {
      e.clearSelection();
    });
    const copyBtn: any = document.getElementById(`copybtn${project && project.id}`);
    copyBtn.click();
  };

  return (
    <Modal
      width={'532px'}
      visible={visible}
      title={`${title}`}
      onOk={handleOk}
      onCancel={() => handleOk()}
      footer={[
        <Button key='back' onClick={() => handleOk()}>
          Close
        </Button>,
      ]}
    >
      <div className='container'>
        <span className={'project-share-url'}>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Public User:</div>
          <div style={{ margin: '0 0 10px 10px' }}>
            Share with public users by copying the below link:
          </div>
          <span
            ref={(el) => {
              urlRefs = el;
            }}
            style={{ fontWeight: 500, marginLeft: '10px' }}
          >
            {project && project.id && getBaseURL(project.id)}
          </span>
          <Tooltip title='Copy'>
            <span onClick={copyUrl} className={'project-copy'}>
              <Icon type='copy' className={'project-copy-icon'} />
            </span>
          </Tooltip>
          {copied && <span className={'copied'}>Copied!</span>}

          <div style={{ fontWeight: 'bold', margin: '30px 0 10px 0' }}>Invited Users:</div>

          <div>
            <p style={{ marginLeft: '10px' }}>
              Share this project by{' '}
              <span
                className={'invite-new-member'}
                onClick={() => {
                  setDrawerVisible(true);
                  trackAnalytics('Project List', 'Inviting team mates clicked', '');
                }}
              >
                inviting team mates
              </span>{' '}
              and then can share the project with them from the Edit dialog.
            </p>
          </div>

          <div>
            <div style={{ fontWeight: 'bold', margin: '30px 0 10px 0' }}>Copy for email:</div>
            copy canvas for email and paste it email body
            {loading ? null : (
              <Tooltip title='Copy'>
                <span
                  onClick={handleCopyClick}
                  id={`copybtn${project && project.id}`}
                  className={'project-copy'}
                >
                  <Icon type='copy' className={'project-copy-icon'} />
                </span>
              </Tooltip>
            )}
          </div>
          {copiedEmail && <span className={'copied'}>Copied!</span>}
        </span>
      </div>
      <Drawer
        title='Team Members'
        placement='right'
        closable={false}
        onClose={onDrawerClose}
        visible={drawerVisible}
        className={'sidebar-team-member-drawer'}
      >
        <TeamList />
      </Drawer>
    </Modal>
  );
};

export default ProjectForm;

import {
  Icon,
  Select,
  //  Switch
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { eventConstant } from '../../constant';
import { trackAnalytics } from '../../utils';
import { sendMessageToIframe } from '../../utils';
import debounce from '../../utils/debounce';
const { Option } = Select;

const RenderFeedbackPoints = (props: any) => {
  const [searchText, setSearchText] = useState('');
  const [selectedPagePath, setSelectedPagePath] = useState('All');
  const [selectedCommentFilter, setSelectedCommentFilter] = useState('active');
  const {
    auth,
    project,
    feedbackData,
    chatWindow,
    allPagePath,
    currentShowingMessageid,
    iframe,
    closeChatOnFilter,
    openFeedback,
    clearSearch,
  } = props;

  let feedbacks = (feedbackData && feedbackData[0] && feedbackData[0].feedback) || [];

  // this code will remove the deleted messages from the comments
  const feedbacksTemp = JSON.parse(JSON.stringify(feedbacks));
  feedbacks.map((feedback: any, index: any) => {
    feedbacksTemp[index].comment = feedback.comment.filter((comment: any) => {
      return !comment.messageDeleted;
    });
  });
  feedbacks = feedbacksTemp;

  if (selectedPagePath !== 'All') {
    feedbacks = feedbacks.filter((feedback: any) => {
      if (
        selectedPagePath === '/' &&
        (feedback.circle_pointer.pagePath === '' || feedback.circle_pointer.pagePath === '/')
      ) {
        return true;
      }
      return feedback.circle_pointer.pagePath === selectedPagePath;
    });
  }

  if (selectedCommentFilter === 'hideAll') {
    feedbacks = [];
  }

  if (selectedCommentFilter === 'resolved') {
    feedbacks = feedbacks.filter((feedback: any) => feedback.resolved);
  }
  if (selectedCommentFilter === 'active') {
    feedbacks = feedbacks.filter((feedback: any) => !feedback.resolved);
  }
  if (selectedCommentFilter === 'unread') {
    let authId: any = auth.uid;
    if (!authId) {
      authId = localStorage.getItem('userDetails');
      authId = authId && JSON.parse(authId).uid;
    }
    feedbacks = feedbacks.filter((feedback: any) => !feedback.readBy.includes(authId));
  }

  feedbacks = feedbacks.map((v: any) => {
    const comments = v.comment;
    return {
      ...v,
      lastComment: (comments && comments.length && comments[comments.length - 1]) || '',
    };
  });

  feedbacks = feedbacks.filter((feedback: any) => feedback.lastComment);

  feedbacks = feedbacks.sort((a: any, b: any) => {
    const c: any = new Date(a.lastComment.time);
    const d: any = new Date(b.lastComment.time);
    return d - c;
  });

  const commentSearch = (comment: any) => {
    const searchTextArray: any = searchText.split(' ');
    return comment.filter((item: any) => {
      return searchTextArray.every((el: any) => {
        return item.feedback.toLowerCase().indexOf(el.toLowerCase()) > -1;
      });
    });
  };

  feedbacks.map((feedback: any, index: number) => {
    if (searchText) {
      feedbacks[index].comment = commentSearch(feedback.comment);
    } else {
      const comments = feedback.comment;
      feedbacks[index].comment =
        (comments && comments.length && [comments[comments.length - 1]]) || [];
    }
  });
  const handleSearchInputChange = (e: any) => {
    setSearchText(e.target.value);
    const debouncedCaller: any = debounce((searchedValue: string) => {
      trackAnalytics('Project list', 'User searched', searchedValue);
    }, 500);
    debouncedCaller(e.target.value);
  };

  const onPageDropdownChange = (value: string) => {
    trackAnalytics('Project Detail', 'Page filter changed', value);
    setSelectedPagePath(value);
  };

  const handleCommentFilterChange = (value: string) => {
    let event = eventConstant.SHOW_ACTIVE_FEEDBACK;
    switch (value) {
      case 'resolved':
        event = eventConstant.SHOW_RESOLVED_FEEDBACK;
        break;
      case 'unread':
        event = eventConstant.SHOW_UNREAD_FEEDBACK;
        break;
      case 'active':
        event = eventConstant.SHOW_ACTIVE_FEEDBACK;
        break;
      case 'all':
        event = eventConstant.SHOW_ALL_FEEDBACK;
        break;
      case 'hideAll':
        event = eventConstant.HIDE_ALL_FEEDBACK;
        break;
    }
    sendMessageToIframe(iframe, event, {});
    setSelectedCommentFilter(value);
    closeChatOnFilter();
  };

  return (
    <div>
      {/* <button onClick={}>Persist</button> */}
      <div className='input-search-comment-container'>
        <input
          // ref={this.searchInput}
          value={searchText}
          onChange={handleSearchInputChange}
          className='input-search-comment-field'
          placeholder='Type to search'
        />
        {!searchText.length ? (
          <Icon className={'input-search-comment-icon'} type='search' />
        ) : (
          <Icon
            onClick={() => {
              clearSearch();
              setSearchText('');
            }}
            className={'input-search-comment-icon'}
            type='close'
          />
        )}
      </div>
      {project && project.type && project.type === 'liveWebsite' && (
        <Select
          showSearch
          style={{ width: '100%', marginBottom: '15px' }}
          placeholder='Select a page to filter'
          optionFilterProp='children'
          onChange={onPageDropdownChange}
          filterOption={(input: string, option: any) =>
            option.props.children &&
            option.props.children.toLowerCase &&
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {allPagePath.map((page: string) => {
            return page === 'All' ? (
              <Option value={page}>{page} Pages</Option>
            ) : (
              <Option value={page}>{page} Page</Option>
            );
          })}
        </Select>
      )}
      <Select
        placeholder='Select'
        defaultValue={selectedCommentFilter}
        value={selectedCommentFilter}
        style={{ width: '100%', marginBottom: '15px' }}
        onChange={handleCommentFilterChange}
      >
        <Option value='all'>Show All</Option>
        <Option value='hideAll'>Hide All</Option>
        <Option value='active'>
          <p className={`sidebar-comment-circle read-comment`} />
          Show Active
        </Option>
        <Option value='resolved'>
          <p className={`sidebar-comment-circle resolved-comment`} />
          Show Resolved
        </Option>
        <Option value='unread'>
          <p className={`sidebar-comment-circle unread-comment`} />
          Show Unread
        </Option>
      </Select>
      <div className='sidebar-comment-content'>
        {feedbacks.map((feedback: any) => {
          const comments = feedback.comment;
          const readByArray: any = feedback.readBy;
          return comments.map((comment: any) => {
            if (comment) {
              const commentMsg = comment.feedback;
              let commentType = '';
              let authId: any = auth.uid;
              if (!authId) {
                authId = localStorage.getItem('userDetails');
                authId = authId && JSON.parse(authId).uid;
              }
              if (feedback.resolved) {
                commentType = 'resolved-comment';
              } else if (readByArray && readByArray.includes(authId)) {
                commentType = 'read-comment';
              } else {
                commentType = 'unread-comment';
              }
              let isSelected = false;
              if (
                chatWindow &&
                chatWindow.visible &&
                comment.uuid &&
                searchText &&
                currentShowingMessageid === comment.uuid
              ) {
                isSelected = true;
              }
              return (
                <div
                  key={comment.uuid}
                  className={`sidebar-comment-wrapper ${isSelected ? 'selected-comment' : ''}`}
                  onClick={() =>
                    openFeedback(
                      feedback.circle_pointer.pagePath,
                      feedback.circle_pointer.id,
                      comment.uuid,
                    )
                  }
                >
                  <div>
                    <p className={`sidebar-comment-circle ${commentType}`} />
                    <div className='sidebar-comment'>
                      <div className='sidebar-comment-sender'>{comment.sender}</div>
                      <div className='sidebar-comment-message-time'>
                        {moment(comment.time).fromNow()}
                      </div>
                    </div>
                  </div>
                  <div className='sidebar-comment-message'>
                    <Highlighter
                      highlightClassName='matched-comment-highlight'
                      searchWords={searchText.split(' ')}
                      autoEscape={true}
                      textToHighlight={
                        commentMsg.slice(0, 70) + (commentMsg.length > 70 ? '...' : '')
                      }
                    />
                  </div>
                  {project && project.type && project.type === 'liveWebsite' && (
                    <div className='sidebar-comment-page'>
                      Comment left on{' '}
                      {feedback.circle_pointer.pagePath ? feedback.circle_pointer.pagePath : '/'}
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          });
        })}
      </div>
    </div>
  );
};

export default RenderFeedbackPoints as any;

import React from 'react';

export default () => (
  <svg width='76' height='58' viewBox='0 0 76 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M61 33C68.7328 33 75 38.0914 75 44.375C75 46.9781 73.9117 49.3625 72.107 51.282C72.9219 53.4367 74.6172 55.2633 74.6445 55.2852C75.0055 55.668 75.1039 56.2258 74.8961 56.707C74.6883 57.1883 74.2125 57.5 73.6875 57.5C70.3242 57.5 67.6719 56.0945 66.0805 54.968C64.5 55.4656 62.7938 55.75 61 55.75C53.2672 55.75 47 50.6586 47 44.375C47 38.0914 53.2672 33 61 33ZM61 53.125C62.4602 53.125 63.9039 52.9008 65.2875 52.4633L66.5289 52.0695L67.5953 52.8242C68.3773 53.3766 69.4492 53.9945 70.7398 54.4102C70.3406 53.7484 69.9523 53.0047 69.6516 52.2117L69.0719 50.675L70.1984 49.4828C71.1883 48.4273 72.375 46.6828 72.375 44.375C72.375 39.5516 67.2727 35.625 61 35.625C54.7273 35.625 49.625 39.5516 49.625 44.375C49.625 49.1984 54.7273 53.125 61 53.125Z'
      fill='#E0E0E0'
    />
    <path
      d='M33 0C14.7727 0 0 12.0012 0 26.8125C0 33.2062 2.75859 39.0586 7.34766 43.6605C5.73633 50.1574 0.348047 55.9453 0.283594 56.0098C0 56.3063 -0.0773437 56.7445 0.0902344 57.1313C0.257812 57.518 0.61875 57.75 1.03125 57.75C9.57773 57.75 15.9844 53.6508 19.1555 51.1242C23.3707 52.7098 28.05 53.625 33 53.625C51.2273 53.625 66 41.6238 66 26.8125C66 12.0012 51.2273 0 33 0ZM16.5 30.9375C14.2184 30.9375 12.375 29.0941 12.375 26.8125C12.375 24.5309 14.2184 22.6875 16.5 22.6875C18.7816 22.6875 20.625 24.5309 20.625 26.8125C20.625 29.0941 18.7816 30.9375 16.5 30.9375ZM33 30.9375C30.7184 30.9375 28.875 29.0941 28.875 26.8125C28.875 24.5309 30.7184 22.6875 33 22.6875C35.2816 22.6875 37.125 24.5309 37.125 26.8125C37.125 29.0941 35.2816 30.9375 33 30.9375ZM49.5 30.9375C47.2184 30.9375 45.375 29.0941 45.375 26.8125C45.375 24.5309 47.2184 22.6875 49.5 22.6875C51.7816 22.6875 53.625 24.5309 53.625 26.8125C53.625 29.0941 51.7816 30.9375 49.5 30.9375Z'
      fill='#E0E0E0'
    />
  </svg>
);

// import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import {
  // Icon,
  notification,
  // Slider,
  // Tooltip
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import {
  getVideoDurationFormat,
  //  mobileCheck
} from '../../../utils';
import {
  // FullScreenButton,
  FullScreenButtons,
  FullScreenCinemaButtons,
  NextPlay,
  PauseIconPlayer,
  PlayIconPlayer,
  PrevPlay,
  SoundIcon,
} from './ChatSvgs';

// import useFullScreen from '../../playerComponents/fullscreen';
// import SeekBar from '../../playerComponents/seekbar';
// import PlayIcon from '../../recording/images/play-icon.png';
// import { VolumeMuteSVG, VolumeSVG } from '../../svg';
// import SideDrawerComponent from './SideDrawerComponent';

interface RenderVideoProps {
  videos: [string];
  iframeClass?: string;
  parentComponent: string;
  isMediaCreatedByLoginUser: boolean;
  createdTime: any;
  blobURL: string;
  setShowCinemaModal: any;
  setPlayerData?: any;
  containerClass: string;
  isChatWindow?: boolean;
}
const RenderVideos = ({
  videos,
  iframeClass = '',
  parentComponent,
  isMediaCreatedByLoginUser,
  createdTime,
  blobURL,
  setShowCinemaModal,
  setPlayerData,
  containerClass,
  isChatWindow,
}: RenderVideoProps) => {
  const forwordStep = 10; // secouds
  const backwordStep = 10; // secouds
  // const isMobileView = mobileCheck();
  // let hideVideoControlTimeout: any ='';
  let playerRef: any = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef(null);
  const [isShowedError, setIsShowedError] = useState(false);
  const [isShowingBlobURL, setIsShowingBlobURL] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoPlayedDuration, setVideoPlayedDuration] = useState(0);
  const [speed, setSpeed] = useState(1);
  // const [loading, setLoading] = useState(true);
  // const [bufferPercentage, setBufferPercentage] = useState(0);
  // const [isPlayerMouseOver, setIsPlayerMouseOver] = useState(false);
  // const [isVidoControlMouseOver, setIsVideoControlMouseOver] = useState(false);
  // const [isVideoControlHide, setIsVideoControlHide] = useState(false);
  // const [volume, setVolume] = useState(0.8);
  // const [isInitial, setIsInitial] = useState(true);

  // const [playedPercentage, setPlayedPercentage] = useState(0);
  // const [isShowIframe, setIsShowIframe] = useState(
  //   parentComponent === 'feedbackModal' ? false : true,
  // );
  const [videoURL, setVideoURL] = useState('');

  // const hideLoader = () => {
  //   if (parentComponent === 'feedbackModal') {
  //     setIsShowIframe(true);
  //     setLoading(false);
  //   }
  // };

  const getVideoURL = (videoURLString: string) => {
    if (!videoURLString) {
      return '';
    }
    if (videoURLString.includes('https://embed.api.video/vod')) {
      const videoURLSlashArray = videoURLString.split('/');
      const apiVideoId = videoURLSlashArray[videoURLSlashArray.length - 1];
      return `https://cdn.api.video/vod/${apiVideoId}/hls/manifest.m3u8`;
    }
    return videoURLString;
  };

  useEffect(() => {
    if (isMediaCreatedByLoginUser && blobURL) {
      const currentUTCTime = moment().utc();
      const minutes = moment(currentUTCTime).diff(moment(createdTime), 'minutes');
      if (minutes < 1) {
        setVideoURL(blobURL);
        setIsShowingBlobURL(true);
      } else {
        setVideoURL(videos[0]);
      }
    } else {
      setVideoURL(getVideoURL(videos[0]));
    }
  }, [videos, isMediaCreatedByLoginUser, createdTime, blobURL]);

  useEffect(() => {
    if (playing && !isShowedError && !videoDuration) {
      notification.error({
        description: '',
        message: 'This video is being processed, please wait a few seconds.',
      });
      setIsShowedError(true);
    }
  }, [playing, isShowedError, videoDuration]);

  const playPauseVideo = () => {
    setPlaying((playing) => !playing); // tslint:disable-line
  };

  // useEffect(() => {
  // if (!isPlayerMouseOver && !isVidoControlMouseOver) {
  // setIsVideoControlHide(true);
  // }
  // }, [isPlayerMouseOver, isVidoControlMouseOver]);

  // const playerMouseEnter = () => {
  // setIsVideoControlHide(false);
  //  clearTimeout(hideVideoControlTimeout)
  // setIsPlayerMouseOver(true);
  // setIsVideoControlMouseOver(false);
  // };

  // const playerMouseOut = () => {
  // setIsPlayerMouseOver(false);
  // hideVideoControlTimeout = setTimeout(() => {
  //   setIsPlayerMouseOver(false);
  // }, 3000);
  // };

  // const videoControlMouseEnter = () => {
  //   setIsVideoControlHide(true);
  //   setIsPlayerMouseOver(false);
  //   setIsVideoControlMouseOver(true);
  // };

  // const videoControlMouseOut = () => {
  //   setIsVideoControlMouseOver(false);
  // };

  // const volumeChange = (volumeValue: any) => {
  //   if (!volumeValue) {
  //     setIsMute(true);
  //   } else {
  //     isMute && setIsMute(false);
  //   }
  //   setVolume(volumeValue / 100);
  // };
  // const seekChange = (seekChangeValue: any) => {
  //   if (videoDuration) {
  //     setPlayedPercentage(seekChangeValue);
  //     playerRef.seekTo(seekChangeValue / 100);
  //   }
  // };

  const handleProgress = (progress: { playedSeconds: number; played: number; loaded: number }) => {
    const { playedSeconds, played } = progress;
    setVideoPlayedDuration(playedSeconds);
    // setPlayedPercentage(played * 100);
    // loaded && setBufferPercentage(loaded * 100);
    if (played === 1) {
      setPlaying(false);
    }
    // We only want to update time slider if we are not currently seeking
  };
  const handleDuration = (duration: number) => {
    setVideoDuration(duration);
  };

  const backwardVideo = () => {
    const backwardVideoDuration = videoPlayedDuration - backwordStep;
    if (backwardVideoDuration > 0) {
      playerRef.seekTo(backwardVideoDuration);
    } else {
      playerRef.seekTo(0);
    }
  };

  const forwordVideo = () => {
    const backwardVideoDuration = videoPlayedDuration + forwordStep;
    if (backwardVideoDuration < videoDuration) {
      playerRef.seekTo(backwardVideoDuration - 1);
    }
  };

  return (
    <>
      <div
        ref={videoRef}
        className={
          parentComponent === 'feedbackModal' ? iframeClass + ' video-container' : 'video-container'
        }
        style={{
          height: isChatWindow ? '88px' : '100%',
          width: isChatWindow ? '180px' : '268px',
          overflow: 'hidden',
          marginLeft: isChatWindow ? '5px' : 0,
          borderRadius: '5px',
        }}
        // onMouseEnter={playerMouseEnter}
        // onMouseOut={playerMouseOut}
      >
        {isShowingBlobURL && parentComponent !== 'feedbackModal' && isChatWindow && (
          <div className='project-blob-processing'>This video is being processed</div>
        )}
        <ReactPlayer
          progressInterval={50}
          mute={isMute}
          onClick={playPauseVideo}
          playbackRate={speed}
          playing={playing}
          volume={isMute ? 0 : 1}
          className={`chat-window-react-player-small cover-full-width`}
          url={videoURL} // 'https://cdn.api.video/vod/vi1pz7g8CPh9YUYTUn7iTdbF/hls/manifest.m3u8'
          onProgress={handleProgress}
          onDuration={handleDuration}
          ref={(player) => {
            playerRef = player;
          }}
        />
        {
          <div
            // onMouseEnter={videoControlMouseEnter}
            // onMouseOut={videoControlMouseOut}
            // style={{ pointerEvents: 'none', opacity: isPlayerMouseOver && !isInitial ? 1 : 0 }}
            className={containerClass}
            //
          >
            <span
              onClick={() =>
                setSpeed((currentSpeed: number) => (currentSpeed < 3 ? currentSpeed + 0.5 : 1))
              }
              className={`noselect chat-window-audio-player-speed ${playing ? 'd-flex' : 'd-none'}`}
            >
              {speed + 'x'}
            </span>

            {!isShowingBlobURL && (
              <span
                onClick={backwardVideo}
                className={`${
                  isChatWindow ? 'chat-window-prev-icon' : 'chat-window-prev-icon-responsive'
                }`}
                style={{ display: playing ? 'flex' : 'none' }}
              >
                {/* <Tooltip placement='top' title='Forward'> */}

                <PrevPlay />
                {/* </Tooltip> */}
              </span>
            )}
            {videoURL &&
              (playing ? (
                <span
                  onClick={playPauseVideo}
                  className={`${
                    isChatWindow
                      ? 'chat-window-play-pause-icon'
                      : 'chat-window-play-pause-icon-responsive'
                  }`}
                >
                  <PauseIconPlayer />
                </span>
              ) : (
                // </Tooltip>
                // <Tooltip placement='top' title='Play'>
                <span
                  className={`pointer ${
                    isChatWindow
                      ? 'chat-window-play-pause-icon'
                      : 'chat-window-play-pause-icon-responsive'
                  }`}
                  onClick={playPauseVideo}
                >
                  <PlayIconPlayer />
                </span>
                // </Tooltip>
              ))}

            <span
              onClick={() => {
                setIsMute((oldSound) => !oldSound);
              }}
              className={`chat-window-soundIcon ${playing ? 'd-flex' : 'd-none'}`}
            >
              <SoundIcon active={isMute} />
            </span>
            {!isShowingBlobURL && (
              <span
                onClick={forwordVideo}
                className={`${
                  isChatWindow ? 'chat-window-next-icon' : 'chat-window-next-icon-responsive'
                }`}
                style={{ display: playing ? 'flex' : 'none' }}
              >
                <NextPlay />
                {/* </Tooltip> */}
              </span>
            )}
            <span className={`chat-window-full-screen ${playing ? 'd-flex' : 'd-none'}`}>
              <FullScreenButtons />
              <span
                style={{ top: isChatWindow ? '' : '0px' }}
                className={'chat-window-full-screen-cinema'}
                onClick={() => {
                  setPlaying(false);
                  setPlayerData &&
                    setPlayerData({
                      videoURL: videos[0],
                      iframeClass,
                      isMediaCreatedByLoginUser,
                      createdTime,
                      blobURL,
                    });
                  setShowCinemaModal(videoURL);
                }}
              >
                <FullScreenCinemaButtons />
              </span>
            </span>
            {!isShowingBlobURL && (
              <div className='chat-window-audio-timer'>
                {videoPlayedDuration > 0 && (
                  <span>
                    {getVideoDurationFormat(videoPlayedDuration)}
                    {' / '}
                  </span>
                )}
                <span>
                  {videoDuration && videoDuration !== Infinity
                    ? getVideoDurationFormat(videoDuration)
                    : ''}
                </span>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default React.memo(RenderVideos);

import { DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import ColorContext from 'context/ColorContext';
import firebase from 'firebase';
import { Zmenu } from 'primitives';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { statsAction } from 'store/actions/authActions';
import { IOrgProject } from 'types/projectTypes';
import { projectColors } from 'utils/dummyData';
import { v4 as uuid } from 'uuid';
import { mobileCheck } from '../../../utils';
import { Close } from '../../svg';
import './branding.css';
import EditOrganizationDialog from './EditOrganizationDialog';
import OrgProjecColor from './OrgProjecColor';
import OrgProjectList from './OrgProjectList';

const isMobile = mobileCheck();

interface IProps {
  onCloseDrawer: () => void;
  visible: boolean;
  auth: any;
  profile: any;
  // getProjects: () => void;
  getOrgProjects: () => void;
  organizationDetail: any;
  selectedOrgProject: IOrgProject;
  handleSelectedProject: (project: IOrgProject) => void;
  changeOrganization: any;
  selectedOrganizationIndex: number;
  selectedOrganizationData: any;
}

const OrganizationDrawer: React.FC<IProps> = ({
  onCloseDrawer,
  visible,
  profile,
  auth,
  getOrgProjects,
  organizationDetail,
  selectedOrgProject,
  handleSelectedProject,
  changeOrganization,
  selectedOrganizationIndex,
  selectedOrganizationData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [editOrganizationIndex, setEditOrganizationIndex] = useState(0);
  const [showEditOrganizationDialog, setShowEditOrganizationDialog] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  const addOrganization = () => {
    const { fullName = '', firstName = '', lastName = '', photoURL = '', email } = profile;
    setIsLoading(true);
    const firestore = firebase.firestore();
    firestore
      .collection('organization')
      .add({
        name: fullName ? `${fullName}'s Organization` : '',
        member_ids: [auth.uid],
        members: [
          {
            id: auth.uid,
            name: fullName ? fullName : `${firstName} ${lastName}`,
            image: photoURL,
            role: 'Admin',
            invite_by: auth.uid,
            status: 'ACTIVE',
            email,
          },
        ],
        projects: [
          {
            projectColors,
            projectName: 'Untitled project',
            id: uuid(),
          },
        ],
      })
      .then((org: any) => {
        firestore
          .collection('organization')
          .doc(org.id)
          .get()
          .then((doc: any) => {
            if (doc.exists) {
              const batch = firestore.batch();
              const projectId = doc.data().projects[0].id;
              window.localStorage.setItem('selectedProjectId', projectId);
              firestore
                .collection('masterProjects')
                .where('created_by', '==', auth.uid)
                .get()
                .then((snap: any) => {
                  if (!snap.empty) {
                    snap.forEach((mProject: any) => {
                      const updatedTimeStamp = new Date().getTime();
                      const masterProjectsRef = firestore
                        .collection('masterProjects')
                        .doc(mProject.id);
                      batch.set(
                        masterProjectsRef,
                        { projectId, updatedTimeStamp },
                        { merge: true },
                      );
                    });
                    batch.commit().then(() => {
                      statsAction('ORGANIZATION_CREATED', firestore);
                      setIsLoading(false);
                      getOrgProjects();
                      // getProjects()
                    });
                  }
                });
            }
          });
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error('Error adding document: ', error);
      });
  };

  const organizationMenu = () => {
    const menu = (
      <ColorContext.Consumer>
        {({ theme }) => (
          <Zmenu menuColors={theme}>
            {organizationDetail.map((org: any, index: number) => {
              const { name = '', created_by } = org;
              return (
                <Menu.Item
                  className='project-dropdown-item'
                  key={org.id}
                  onClick={() => {
                    window.localStorage.setItem('selectedOrganizationId', org.id);
                    getOrgProjects();
                    changeOrganization(index);
                  }}
                >
                  {name ? name : 'Oraganization-' + (index + 1)}
                  {auth && auth.uid && auth.uid === created_by && (
                    <span
                      className='edit-organization-text'
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditOrganizationIndex(index);
                        setShowEditOrganizationDialog(true);
                      }}
                    >
                      Edit
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </Zmenu>
        )}
      </ColorContext.Consumer>
    );
    return menu;
  };
  const { id = '', projects = [] } =
    organizationDetail && organizationDetail[selectedOrganizationIndex]
      ? organizationDetail[selectedOrganizationIndex]
      : {};
  return (
    <Drawer
      title={
        organizationDetail && organizationDetail.length > 1 ? (
          <ColorContext.Consumer>
            {({ theme }) => (
              <div className='project-selection'>
                <EditOrganizationDialog
                  organizationModalVisible={showEditOrganizationDialog}
                  handleCancel={() => {
                    setShowEditOrganizationDialog(false);
                  }}
                  selectedOrganizationIndex={editOrganizationIndex}
                  organizations={organizationDetail}
                />
                <div className='workflow-container'>
                  <span className='project-dropdown-label'>Organization</span>
                  <Dropdown overlay={organizationMenu()}>
                    <Button
                      className='project-dropdown'
                      style={{
                        color: theme.text.color2,
                        ...(hover && { color: theme.primaryBtn.color2 }),
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {organizationDetail &&
                      organizationDetail[selectedOrganizationIndex] &&
                      organizationDetail[selectedOrganizationIndex].name
                        ? organizationDetail[selectedOrganizationIndex].name
                        : `Organization-${selectedOrganizationIndex + 1}`}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            )}
          </ColorContext.Consumer>
        ) : (
          'Projects'
        )
      }
      placement='right'
      closable={false}
      onClose={() => onCloseDrawer()}
      visible={visible}
      style={{ position: 'absolute' }}
      width={isMobile ? '90vw' : '80vw'}
      className={
        organizationDetail && organizationDetail.length > 1 ? 'oraginzation-list-drawer' : ''
      }
    >
      <div className='branding-container'>
        {organizationDetail.length !== 0 ? (
          <Fragment>
            {/* {organizationDetail.map((org: any) => { */}
            {/* const { id, projects } = org;
              return ( */}
            <Fragment key={id}>
              <OrgProjectList
                profile={profile}
                projects={projects}
                handleSelectedProject={handleSelectedProject}
                selectedProject={selectedOrgProject}
                orgId={id}
                getOrgProjects={getOrgProjects}
                selectedOrganizationData={selectedOrganizationData}
              />
              {!isMobile && (
                <OrgProjecColor
                  profile={profile}
                  selectedProject={selectedOrgProject}
                  orgId={id}
                  selectedOrganizationData={selectedOrganizationData}
                  projects={projects}
                />
              )}
            </Fragment>
            {/* ); */}
            {/* })} */}
          </Fragment>
        ) : (
          <div className='add-organization'>
            <div className='add-org-detail'>
              <h2>You don’t have setup an organisation yet.</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a{' '}
              </p>
            </div>
            <Button
              onClick={addOrganization}
              className='organization-btn add-org-btn'
              loading={isLoading}
            >
              START AN ORGANISATION NOW
            </Button>
          </div>
        )}
      </div>
      <div className='drawer-close-btn' onClick={() => onCloseDrawer()}>
        <Close />
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(OrganizationDrawer);

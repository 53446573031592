// import { Modal } from 'antd';
import { notification } from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import React from 'react';
import { trackAnalytics } from '../../../utils';
import RecordVideoFile from './RecordVideoFile';
const fiebaseInstance: any = firebase;
const { REACT_APP_IS_AWS_REAL_TIME_SAVING = 'false' } = process.env;
const isAWSRealTimeSaving = REACT_APP_IS_AWS_REAL_TIME_SAVING.trim() === 'true' ? true : false;

const RecordVideo = (props: any) => {
  const [loading, setLoading] = React.useState(false);
  const [isRocording, setIsRecording] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');

  const {
    isRecordModal,
    setIsRecordModal,
    sendFeedback,
    pageID,
    sendVideoToStore,
    setSendingImage,
    updateFeedback,
    isPublicChatWindow,
    firebaseAuthToken,
    profile,
    auth,
    organization,
  } = props;
  let firebaseToken = '';
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = organization.selectOrganizationDetails.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  React.useEffect(() => {
    return () => {
      console.log("call record video")
         // @ts-ignore
         console.log("window.stream before rcv - ", window.stream)
         // @ts-ignore
         if (window.stream) {
           // @ts-ignore
           window && window.stream && window.stream.getTracks().forEach(function (track: any) {
             track.stop();
           });
           // @ts-ignore
           window.stream.getAudioTracks().forEach(function (track) {
             track.stop();
           });
           // @ts-ignore
           window.stream.getVideoTracks().forEach(function (track) {
             track.stop();
           });
           // @ts-ignore
           window.stream = null;
            // @ts-ignore
           console.log("window.stream after - ", window.stream)
         }
      window.onbeforeunload = null;
    };
  }, []);

  const sendToFirebase = async (mediaBlobUrl: any, projectS3URL: string) => {
    trackAnalytics(
      'Feedback modal',
      `Send video ${isRecordModal === 'screen' ? 'recording' : ''} clicked'`,
    );
    setLoading(true);
    setIsRecordModal('');
    updateFeedback(1, 'video');
    let mediaURL = '';
    firebaseToken = firebaseAuthToken;
    if (!firebaseToken) {
      firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    const blob: any = await fetch(mediaBlobUrl).then((r) => r.blob());
    if (isAWSRealTimeSaving) {
      mediaURL = projectS3URL;
    } else {
      const videoFirebaseUrl = await sendVideoToStore(blob);
      mediaURL = videoFirebaseUrl;
    }
    await sendFeedback('', '', false, pageID, false, '', '', mediaURL, '', mediaBlobUrl);
    setSendingImage(false);
    setLoading(false);
    /* Increase total totalVoiceClips count */
    if (auth && auth.uid && userQuota) {
      let isChangedUserQuota = false;
      if (
        userQuota.totalVideoComments &&
        userQuota.totalVideoComments > 0 &&
        isRecordModal === 'video'
      ) {
        userQuota.totalVideoComments = userQuota.totalVideoComments - 1;
        isChangedUserQuota = true;
      } else if (
        userQuota.totalScreenRecordings &&
        userQuota.totalScreenRecordings > 0 &&
        isRecordModal === 'screen'
      ) {
        userQuota.totalScreenRecordings = userQuota.totalScreenRecordings - 1;
        isChangedUserQuota = true;
      }
      if (isChangedUserQuota && blob.size) {
        userQuota.storageLimit = userQuota.storageLimit - blob.size / 1024;
        if (userQuota.storageLimit <= 0) {
          notification.error({
            message: 'Your current plan doesn’t include using this feature.',
          });
          return true;
        }
        const firestore = firebase.firestore();
        if (profile.isManagedUserQuotaInOrganization) {
          if (organization.selectOrganizationDetails.id) {
            firestore
              .collection('organization')
              .doc(organization.selectOrganizationDetails.id)
              .update({
                userQuota,
              });
          }
        } else {
          firestore
            .collection('users')
            .doc(auth.uid)
            .update({
              userQuota,
            });
        }
      }
    }
  };
  const recordingType =
    isRecordModal === 'screen' ? 'screenRecordingCommentTime' : 'videoCommentTime';

  if (videoUrl || isRocording) {
    window.onbeforeunload = (event: any) => {
      const message = 'Sure you want to leave?';
      if (typeof event === 'undefined') {
        event = window.event;
      }
      if (event) {
        event.returnValue = message;
      }
      return message;
    };
  } else {
    window.onbeforeunload = null;
  }

  return isRecordModal ? (
    <div>
      <div onClick={() => setIsRecordModal('')} className='recording-modal-blur-background'></div>
      <RecordVideoFile
        loading={loading}
        isRecordModal={isRecordModal}
        sendToFirebase={sendToFirebase}
        setVideoUrl={setVideoUrl}
        showSendButton
        maxRecordTime={userQuota && userQuota[recordingType]}
        setIsRecording={setIsRecording}
        isPublicChatWindow={isPublicChatWindow}
        firebaseAuthToken={firebaseAuthToken}
      />
    </div>
  ) : null;
};

export default RecordVideo;

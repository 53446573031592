import {
  Alert,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Menu,
  notification,
  Row,
  Typography,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import {
  // Zbutton,
  Zinput,
} from '../../primitives';
import Button from '../../primitives/SignUpButtons';
// import logo from '../../static/logo.png';
import {
  checkExistUser as checkExistUserAction,
  clearAuthState as clearAuthStateAction,
  getGitHubLogingResult,
  getGoogleLogingResult,
  inviteAction,
  loginWithGitHub,
  loginWithGoogle,
  signUp as signUpAction,
} from '../../store/actions/authActions';
import { IAuthAction, IUser, Type } from '../../types/authTypes';
import { ButtonEvent } from '../../types/syntheticEventsTypes';
import { trackAnalytics } from '../../utils';
import { mobileCheck, showFullFeature } from '../../utils';
import { randomImage } from '../../utils/dummyData';
import { ArrowRight
  , GitHub, Google 
} from '.././svg';
import '../layout/style.css';
import Spinner from '../shared/spinner';
import { Logo } from '../svg/Logo';

import './auth.css';

const isMobile = mobileCheck();
const urlParams = new URLSearchParams(window.location.search);
const channelId = urlParams.get('channelId') || '';
const slackUserId = urlParams.get('slackUserId') || '';
const recordingType = urlParams.get('type') || 'audio';

ReactGA.initialize('UA-155085731-1');
ReactGA.pageview(window.location.pathname + window.location.search);
const { Header } = Layout;
const FormItem = Form.Item;
const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      offset: 4,
      span: 16,
    },
    xs: {
      offset: 0,
      span: 24,
    },
  },
};

interface IProps extends FormComponentProps {
  children?: JSX.Element | JSX.Element[];
  signUp: (arg0: IUser) => any;
  checkExistUser: (emaildId: string) => any;
  clearAuthState: () => void;
  googleLogin: () => void;
  gitHubLogin: () => void;
  googleLogInSuccess: (adminId: string, emailId: string, appSumoId: string) => void;
  gitHubLoginSucces: (adminId: string, emailId: string, appSumoId: string) => void;
  chosenImage: (image: string | null) => void;
  authError: string;
  status: string;
  image: string;
  auth: {
    isLoaded: boolean;
    uid: string;
  };
  history: {
    location: any;
    push(url: string): void;
  };
  match: any;
}
const SignUp: React.SFC<IProps> = ({
  form,
  auth,
  authError,
  status,
  clearAuthState,
  checkExistUser,
  signUp,
  history,
  googleLogin,
  gitHubLogin,
  googleLogInSuccess,
  gitHubLoginSucces,
  match,
}): JSX.Element => {
  const isShowFullFeature = showFullFeature();
  const [loading, setLoading] = useState<boolean>(false);
  const [adminId, setAdminId] = useState<string>('');
  const [organizationId, setOrganizationId] = useState<string>('');
  const [existUserId, setExistUserId] = useState<string>('');
  const [emailId, setEmailId] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState<boolean>(false);
  const [isErrorAppSumo, setIsErrorAppSumo] = useState<boolean>(false);

  const [image, setImage] = useState<any>('');

  useEffect(() => {

    // window.localStorage.removeItem('userId');
    // window.localStorage.removeItem('selectedMasterProjectId');
    // window.localStorage.removeItem('selectedWorkflowId');
    // window.localStorage.removeItem('selectedProjectId');
    // window.localStorage.removeItem('firebaseToken');
    // window.localStorage.removeItem('selectedOrganizationId');

    const adminDbId = urlParams.get('adminId');
    const organizationDbId = urlParams.get('organizationId');
    const inviteEmailId = urlParams.get('emailId') || '';
    adminDbId && setAdminId(adminDbId);
    organizationDbId && setOrganizationId(organizationDbId);
    inviteEmailId && setEmailId(decodeURIComponent(inviteEmailId));
    checkExistUser(decodeURIComponent(inviteEmailId)).then((userId: string) => {
      setExistUserId(userId);
    });
    if (match.params.appSumoId) {
      checkAppSumoCode(match.params.appSumoId);
    }
  }, []);

  const inviteActionClicked = (actionType: string) => {
    inviteAction(adminId, existUserId, organizationId, actionType).then((resMessage: string) => {
      actionType === 'accepted' && window.localStorage.setItem('selectedOrganizationId', organizationId);
      setMessage(resMessage);
    });
  };

  useEffect(() => {
    status === Type.StatusRunning ? setLoading(true) : setLoading(false);
    if (
      status === Type.SignInError ||
      status === Type.githubLogInError ||
      status === Type.GoogleLogInError
    ) {
      setErrorMessage(authError);
      return () => clearAuthState();
    }

    setImage(randomImage);

    if (localStorage.getItem('socialMediaLoginRedirected') === 'true') {
      let appSumoId = '';

      form.validateFields(['appSumoId'], {}, (err, values) => {
        if (values.appSumoId && !err) {
          appSumoId = success ? values.appSumoId : '';
        }
      });
      googleLogInSuccess(adminId, emailId, appSumoId);
      gitHubLoginSucces(adminId, emailId, appSumoId);
      localStorage.setItem('socialMediaLoginRedirected', 'false');
    }
  }, [authError, status, clearAuthState, googleLogInSuccess, gitHubLoginSucces]);

  const googleHandleSignIn = (event: any) => {
    event.preventDefault();
    trackAnalytics('User', 'Sign Up wth Google Clicked');
    localStorage.setItem('socialMediaLoginRedirected', 'true');
    googleLogin();
  };

  const gitHubHandleSignIn = (event: any) => {
    event.preventDefault();
    trackAnalytics('User', 'Sign Up wth Github Clicked');
    localStorage.setItem('socialMediaLoginRedirected', 'true');
    gitHubLogin();
  };

  const checkAppSumoCode = (value?: any) => {
    form.validateFields(['appSumoId'], {}, (err, values) => {
      if (value && !values.appSumoId) {
        values.appSumoId = value;
      }
      if (!err) {
        // setLoading(true);
        const AppSumoMessage = 'The AppSumo Code you used is not correct or already used';
        // const AppSumoMessageSuccess = 'Congratulations! You have successfully redeemed your AppSumo Code.'
        const firestore = firebase.firestore();
        firestore
          .collection('redemption')
          .doc(values.appSumoId)
          .get()
          .then((doc: any) => {
            if (!doc.exists) {
              setErrorMessage(AppSumoMessage);
              setSuccess(false);
              setIsErrorAppSumo(true);
              // setLoading(false)
            } else {
              if (doc.data().isUsed) {
                setErrorMessage(AppSumoMessage);
                setIsErrorAppSumo(true);
                setSuccess(false);
                // setLoading(false)
              } else {
                setErrorMessage('');
                // setErrorMessage(AppSumoMessageSuccess);
                setSuccess(true);
                setIsErrorAppSumo(false);
                // setLoading(false)
              }
            }
          });
      }
    });
  };

  const handleSubmit = (e: ButtonEvent) => {
    e.preventDefault();
    form.validateFields((err: any, values: IUser) => {
      if (!err) {
        values.adminIds = [adminId];
        values.organizationId = organizationId;
        values.appSumoId = success ? values.appSumoId : '';
        signUp(values).then((obj: any) => {
          if (obj && obj.message) {
            setErrorMessage(obj.message);
            return;
          }
          window.localStorage.setItem('selectedOrganizationId', organizationId);
          trackAnalytics('User', 'Signed up');
          setAdminId('');
          // this will set appsumoId used to true on firebase
          if (success) {
            if (values.appSumoId) {
              notification.success({
                message: 'Congratulations!',
                description: 'You have successfully redeemed your AppSumo Code.',
              });
              const firestore = firebase.firestore();
              firestore
                .collection('redemption')
                .doc(values.appSumoId)
                .set(
                  {
                    isUsed: true,
                    usedByOrganisationID: obj.usedByOrganisationID,
                    userId: obj.uid,
                  },
                  {
                    merge: true,
                  },
                );
            }
          }
        });
      }
    });
  };
  if (auth.isLoaded && auth.uid && !adminId) {
    if (channelId) {
      return (
        <Redirect
          to={{
            pathname: `/record/${recordingType}`,
            search: `?channelId=${channelId}&slackUserId=${slackUserId}`,
          }}
        />
      );
    } else {
      window.localStorage.setItem('selectedOrganizationId', organizationId);
      return <Redirect to='/' />;
    }
  }
  if (existUserId) {
    return (
      <React.Fragment>
        <nav className='nav-wrapper grey darken-3'>
          <Layout className='layout'>
            <Header className='header'>
              <Menu theme='light' mode='horizontal' style={{ lineHeight: '64px', display: 'flex' }}>
                <Menu.Item className='nav-item' style={{ display: 'flex', flex: 1 }}>
                  <div className='header_logo'>
                    <Link to='/'>
                      <a href={'https://zooc.io'}>
                        <Logo />
                      </a>
                    </Link>
                  </div>
                </Menu.Item>
              </Menu>
            </Header>
          </Layout>
        </nav>
        <div className='container-center'>
          {message && <h2>{message}</h2>}
          {!message && adminId && existUserId && (
            <div>
              <h2>
                Do you want to accept an invitation to provide feedback to his canvases on Zooc?
              </h2>
              <div className='invitation-action-btn-container'>
                <div className='invitation-action-btn'>
                  <Button primary onClick={() => inviteActionClicked('accepted')}>
                    Accepted
                  </Button>
                </div>
                <div className='invitation-action-btn'>
                  <Button primary onClick={() => inviteActionClicked('rejected')}>
                    Rejected
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
  if (auth.isLoaded) {
    //  && !auth.uid
    return (
      <Row>
        <Col
          className='hidden-on-mobile login-image'
          span={12}
          style={{ backgroundImage: `url(${process.env.REACT_APP_WEBSITE_BASE_URL}/${image})` }}
        ></Col>
        <Col className='mobile-view auth-scroll-form' span={12}>
          <Card style={{ height: '100vh', border: 'none' }}>
            <div style={{ maxWidth: 600, margin: 'auto', overflow: 'auto', width: '105%' }}>
              <Row>
                <Col className={isMobile ? 'sign-in-mobile-header-logo' : ''} span={12}>
                  <FormItem {...tailFormItemLayout}>
                    <a href={'https://zooc.io'}>
                      <Logo />
                    </a>
                  </FormItem>
                </Col>
                <Col className={isMobile ? 'sign-in-mobile-header-text' : ''} span={12}>
                  <FormItem {...tailFormItemLayout}>
                    <p>
                      {!isMobile && <>Already a member?</>}
                      <Link
                        style={{ marginLeft: 5, color: '#001DAD' }}
                        to='/signIn'
                        onClick={() => {
                          setErrorMessage('');
                          clearAuthState();
                          trackAnalytics('User', 'Clicked on sign in');
                          history.push(
                            `/signIn${
                              channelId
                                ? `?channelId=${channelId}&type=${recordingType}&slackUserId=${slackUserId}`
                                : ''
                            }`,
                          );
                        }}
                      >
                        Sign in
                      </Link>
                    </p>
                  </FormItem>
                </Col>
              </Row>
              {!isMobile && (
                <FormItem {...tailFormItemLayout}>
                  <div className={'signin-and-signup'}>
                    <p>Sign Up</p>
                  </div>
                </FormItem>
              )}

              <FormItem style={{ margin: isMobile ? 0 : undefined }} {...tailFormItemLayout}>
                {errorMessage && (
                  <Alert
                    className='text-left'
                    message={isErrorAppSumo ? `Sorry!` : success ? 'Success' : 'Sign Up Failed'}
                    description={errorMessage}
                    type={success ? 'success' : 'error'}
                    closable
                    showIcon
                    onClose={() => setErrorMessage('')}
                  />
                )}
              </FormItem>
              <Form onSubmit={handleSubmit}>
                <FormItem {...tailFormItemLayout}></FormItem>

                {isMobile && (
                  <div className={'signin-and-signup'}>
                    <p>Sign Up</p>
                  </div>
                )}
                <FormItem {...tailFormItemLayout} style={{ marginBottom: 10 }}>
                  {form.getFieldDecorator('fullName', {
                    rules: [
                      {
                        message: 'Name Please!',
                        required: true,
                      },
                    ],
                  })(<Input className='ant-input' type='text' placeholder='Full Name' />)}
                </FormItem>
                <FormItem {...tailFormItemLayout} style={{ marginBottom: 10 }}>
                  {form.getFieldDecorator('email', {
                    rules: [
                      {
                        message: 'Email Please!',
                        required: true,
                      },
                    ],
                    initialValue: emailId,
                  })(
                    <Input
                      disabled={emailId ? true : false}
                      className='ant-input'
                      placeholder='Email'
                    />,
                  )}
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                  {form.getFieldDecorator('password', {
                    rules: [
                      {
                        message: 'Password Please!',
                        required: true,
                      },
                    ],
                  })(<Input className='ant-input' type='password' placeholder='Password' />)}
                </FormItem>

                {match.path === '/signUp/sumo/:appSumoId?' && (
                  <Form style={{ marginBottom: 30 }}>
                    <Typography.Text strong={true}>Redeem new AppSumo code</Typography.Text>
                    <Form.Item {...tailFormItemLayout}>
                      {form.getFieldDecorator('appSumoId', {
                        initialValue: match.params.appSumoId || '',
                        rules: [
                          {
                            required: false,
                            message: 'Please input AppSumo code!',
                            whitespace: true,
                          },
                        ],
                      })(<Zinput onChange={() => setSuccess(false)} placeholder='AppSumo code' />)}
                      <div
                        style={{
                          color: success ? '#52c41a' : undefined,
                          cursor: 'pointer',
                          position: 'absolute',
                          left: 0,
                          bottom: -33,
                        }}
                        onClick={checkAppSumoCode}
                        className='check-button-app-sumo'
                      >
                        {success ? 'Verified' : 'Verify'}
                      </div>
                    </Form.Item>
                  </Form>
                )}

                <FormItem {...tailFormItemLayout} style={{ marginBottom: 10 }}>
                  <Button htmlType='submit' loading={loading} primary>
                    Let's Start
                    <ArrowRight />
                  </Button>
                </FormItem>
              </Form>
              
              { isShowFullFeature && <>
              <FormItem {...tailFormItemLayout} style={{ marginBottom: 15 }}>
                <Divider>Or</Divider>
              </FormItem>
              <FormItem {...tailFormItemLayout} style={{ marginBottom: 10 }}>
                <Button secondary onClick={googleHandleSignIn}>
                  <Google />
                  <span style={{ marginLeft: 8 }}>Sign Up with Google</span>
                </Button>
              </FormItem>
              <FormItem {...tailFormItemLayout}>
                <Button secondary onClick={gitHubHandleSignIn}>
                  <GitHub />
                  <span style={{ marginLeft: 8 }}>Sign Up with GitHub</span>
                </Button>
              </FormItem>
              </>
            }
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
  return <Spinner />;
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any };
}) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    status: state.auth.status,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    clearAuthState: () => dispatch(clearAuthStateAction()),
    signUp: (creds: IUser) => dispatch(signUpAction(creds)),
    checkExistUser: (email: string) => dispatch(checkExistUserAction(email)),
    googleLogin: () => dispatch(loginWithGoogle()),
    googleLogInSuccess: (adminId: string, emailId: string, appsumoCode: string) =>
      dispatch(getGoogleLogingResult(adminId, emailId, appsumoCode)),
    gitHubLogin: () => dispatch(loginWithGitHub()),
    gitHubLoginSucces: (adminId: string, emailId: string, appsumoCode: string) =>
      dispatch(getGitHubLogingResult(adminId, emailId, appsumoCode)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create()(SignUp));

import React from 'react';

interface IProjectgColors {
  handleUpdateColor: (orgColor: any) => void;
  theme: {
    backgrounds: {
      color1: string;
      color2: string;
      color3: string;
    };
    text: {
      color1: string;
      color2: string;
      color3: string;
    };
    primaryBtn: {
      color1: string;
      color2: string;
      color3: string;
    };
    secondaryBtn: {
      color1: string;
      color2: string;
      color3: string;
    };
    dangerBtn: {
      color1: string;
      color2: string;
      color3: string;
    };
  };
}

export default React.createContext<IProjectgColors>({
  theme: {
    backgrounds: {
      color1: '',
      color2: '',
      color3: '',
    },
    text: {
      color1: '',
      color2: '',
      color3: '',
    },
    primaryBtn: {
      color1: '',
      color2: '',
      color3: '',
    },
    secondaryBtn: {
      color1: '',
      color2: '',
      color3: '',
    },
    dangerBtn: {
      color1: '',
      color2: '',
      color3: '',
    },
  },
  handleUpdateColor: (orgColor: any) => orgColor,
});

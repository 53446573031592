import { Button, Drawer, Dropdown, Icon, Input, Menu, Modal, Tooltip } from 'antd';
import firebase from 'firebase';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setTimeout } from 'timers';
import { canvasFilterThreads } from '../../store/actions/newCanvasActions';
import { IThread } from '../../types/projectTypes';
import {
  copyURL,
  getQueryStringValue,
  getVideoDurationFormat,
  mobileCheck,
  sendMessageToIframe,
} from '../../utils';
import DropDownCustom from '../dropDown';
import Iframe from '../iframe';
import ChatWindow from '../projects/Chat/ChatWindow';
import { BackCircleIcon, Chat, CircleRightClick, TripleDot } from '../svg';
import { Logo } from '../svg/Logo';
import TextDetail from '../textDetail';
import { ChatFloatingIcon } from './CanvasSvgs';
import CanvasHeader from './header';
import PDFJS from './PDFCanvas';
import './style.css';
import thread from './thread';
import Thread from './thread';
import ThreadItem from './thread/ThreadItem';
import VideoCanvas from './VideoCanvas';

const Canvas = (props: any) => {
  const { profile, canvasFilterThreadsAction, threads, auth = [] } = props;
  const { fullName = '', photoURL = '', email = '' } = profile;
  const { uid = '' } = auth;
  const canvasId = props.match.params.id || '';
  const [selectedOrganizationData, setSelectedOrganizationData] = useState({});
  const [isChatMode, setIsChatMode] = useState(false);
  const [initial, setInitial] = useState(true);
  const [mediaPlayedTime, setMediaPlayedTime] = useState(0);
  const [threadChatId, setThreadChatId] = useState('');
  const [clearOldChat, setClearOldChat] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [workflow, setWorkflow] = useState([]);
  const [keyboardEvent, setKeyboardEvent] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#001DAD');
  const [activeTab, setActiveTab] = useState('commenting');
  const [masterProjectId, setMasterProjectId] = useState('');
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [inputPassword, setInputPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [chatWindowOpen, setChatWindowOpen] = useState(false);

  const syncWithIframe = (data: any, event: string) => {
    if (props.iframe.current) {
      // let isImageTypeProject = checkImageURL(props.props.url);
      const el = props.iframe.current.contentWindow;
      sendMessageToIframe(props.iframe, event, {});
      data.isImageTypeProject = getQueryStringValue('recalc') || props.match.path === '/pdf/:id';
      el.postMessage({ event, data }, '*');
    }
  };

  const [filterCheckbox, setFilterCheckbox]: any = useState({
    isShowAll: false,
    isShowUnread: false,
    isShowResolved: false,
  });
  const initialCanvasState = {
    title: '',
    project_url: '',
    createdAtUTC: '',
    tags: [],
    members: [],
    threads: [],
    workflowId: '',
    transcript: [],
    archived: 'no',
    blobURL: '',
    project_created_by: '',
    userNotes: '',
    jiraIssueUrl: '',
    trelloIssueUrl: '',
    canvasPassword: '',
    type: '',
    isInterviewCanvas: false,
  };

  const [canvasDetails, setCanvasDetails] = useState(initialCanvasState);
  const [toggleDrawing, setDrawing] = useState(false);
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [selectedThread, setSelectedThread] = useState<IThread>({
    member: {
      image: photoURL,
      name: fullName,
      email,
    },
    message: '',
    mediaTime: mediaPlayedTime,
    createdAt: '',
    replyCount: 0,
    sequenceNo: canvasDetails.threads ? canvasDetails.threads.length + 1 : 1,
    id: '',
    isResolve: false,
    readUserEmails: [email],
  });
  const oldChatId = useRef('');
  const isCheckedOnce = useRef(true);
  const isMobile = mobileCheck();
  const isInterviewCanvas = (canvasDetails && canvasDetails.isInterviewCanvas) || false;

  useEffect(() => {
    if(type === 'whiteboard' && thread.length === 1){
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      },2000)
    }
  },[threads, canvasDetails.type])

  useEffect(() => {
    const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
    if (selectedOrganizationId) {
      const firestore = firebase.firestore();
      firestore
        .collection('organization')
        .doc(selectedOrganizationId)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const currentOrganizationData = doc.data();
            setSelectedOrganizationData(currentOrganizationData);
          }
        });
    }
  }, []);

  useEffect(() => {
    let filterThreads = canvasDetails.threads || [];
    if (!filterCheckbox.isShowAll) {
      if (filterCheckbox.isShowResolved) {
        filterThreads = filterThreads.filter((thread: IThread) => thread.isResolve);
      } else {
        filterThreads = filterThreads.filter((thread: IThread) => !thread.isResolve);
      }
      if (filterCheckbox.isShowUnread) {
        filterThreads = filterThreads.filter((thread: IThread) => {
          const readUserEmails = thread.readUserEmails || [];
          return !readUserEmails.includes(email);
        });
      }
    }
    canvasFilterThreadsAction(filterThreads);
  }, [filterCheckbox]);

  useEffect(() => {
    const pageUrlParams = new URLSearchParams(window.location.search);
    const threadId: any = pageUrlParams.get('threadId');
    if (!threadId) {
      const newSelectedThreadData = JSON.parse(JSON.stringify(selectedThread));
      newSelectedThreadData.mediaTime = 0;
      setSelectedThread(newSelectedThreadData);
      setIsChatMode(false);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (props.location.search) {
      const pageUrlParams = new URLSearchParams(window.location.search);
      const threadId: any = pageUrlParams.get('threadId');
      if (threadId) {
        setThreadChatId(threadId);
        const firestore = firebase.firestore();
        firestore
          .collection('chat')
          .where(firebase.firestore.FieldPath.documentId(), '==', threadId)
          .onSnapshot((chatDetails: any) => {
            if (chatDetails && chatDetails.docs && chatDetails.docs.length) {
              //  setThreadChatId(chatDetails.docs[0].id);
              // setThreadChatMessages(constchatDetailData.messages);
              setIsChatMode(true);
              setChatWindowOpen(true);
              if (oldChatId.current !== threadId) {
                setClearOldChat(true);
              }
              oldChatId.current = threadId;
              const oldThreads: IThread[] = canvasDetails.threads || [];
              const matchedThreadIndex = oldThreads.findIndex(
                (thread: IThread) => thread.id === threadId,
              );
              const canvasThread: any = oldThreads.find((canvasThreadTemp: IThread) => {
                return canvasThreadTemp.id === threadId;
              });
              if (canvasThread && canvasThread.id) {
                const readUserEmails: any = canvasThread.readUserEmails || [];
                const newSelectedThreadData: any = canvasThread;
                if (!readUserEmails.includes(email)) {
                  readUserEmails.push(email);
                  newSelectedThreadData.readUserEmails = readUserEmails;
                }
                setSelectedThread(newSelectedThreadData);
                oldThreads[matchedThreadIndex] = canvasThread;
                firestore
                  .collection('projects')
                  .doc(props.match.params.id)
                  .update({
                    threads: oldThreads,
                  });
              }
            } else {
              setIsChatMode(true);
              setChatWindowOpen(true);
              setSelectedThread({
                member: {
                  image: photoURL,
                  name: fullName,
                  email,
                },
                message: '',
                mediaTime: mediaPlayedTime,
                createdAt: '',
                replyCount: 0,
                sequenceNo: canvasDetails.threads ? canvasDetails.threads.length + 1 : 1,
                id: '',
                isResolve: false,
                readUserEmails: [email],
                dummy: true,
              });
            }
          });
      }
    }
  }, [props.location.search]);

  React.useEffect(() => {
    setLoading(true);
    const firestore = firebase.firestore();
    firestore
      .collection('projects')
      .where(firebase.firestore.FieldPath.documentId(), '==', props.match.params.id)
      .onSnapshot(async (projectDetails: any) => {
        if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
          const projectDetailData = projectDetails.docs[0].data();
          let isMember = false;
          if (!projectDetailData.isPublic) {
            if (projectDetailData.masterProjectId) {
              const masterProjectDoc = await firestore
                .collection('masterProjects')
                .doc(projectDetailData.masterProjectId)
                .get();
              if (masterProjectDoc.exists) {
                const masterProjectDocData: any = masterProjectDoc.data();
                if (masterProjectDocData.member_ids) {
                  masterProjectDocData.member_ids &&
                    masterProjectDocData.member_ids.map((member_id: any) => {
                      if (props.auth && ((member_id === props.auth.uid) || (member_id === props.auth.email))) {
                        isMember = true;
                      }
                    });
                }
              }
            }
            if (projectDetailData.canvasPassword && !isMember && isCheckedOnce.current) {
              setShowPasswordField(true);
            } else {
              setShowPasswordField(false);
            }
            isCheckedOnce.current = false;
          }

          if (
            isMember ||
            projectDetailData.isPublic ||
            projectDetailData.canvasPassword ||
            !props.auth.isLoaded
          ) {
            let filterThreads = projectDetailData.threads || [];
            if (!filterCheckbox.isShowAll) {
              if (filterCheckbox.isShowResolved) {
                filterThreads = filterThreads.filter((thread: IThread) => thread.isResolve);
              } else {
                filterThreads = filterThreads.filter((thread: IThread) => !thread.isResolve);
              }
              if (filterCheckbox.isShowUnread) {
                filterThreads = filterThreads.filter((thread: IThread) => {
                  const readUserEmails = thread.readUserEmails || [];
                  return !readUserEmails.includes(email);
                });
              }
            }
            setError('');
            canvasFilterThreadsAction(filterThreads);
            setCanvasDetails(projectDetailData);
            setInitial(false);
            if (projectDetailData.masterProjectId) {
              setMasterProjectId(projectDetailData.masterProjectId);
            }
            const canvasThreads = projectDetailData.threads || [];
            const pageUrlParams = new URLSearchParams(window.location.search);
            const threadId = pageUrlParams.get('threadId');
            const canvasThread = canvasThreads.find((canvasThreadTemp: IThread) => {
              return canvasThreadTemp.id === threadId;
            });
            if (canvasThread && canvasThread.id) {
              changeThread(canvasThread, projectDetailData);
            }
          } else {
            setError('You dont have access to this canvas!');
          }
        } else {
          setCanvasDetails(initialCanvasState);
        }
        setLoading(false);
      });
  }, [props.auth]);

  /* we should keep seperate get masterProjects data on change of master project id, instead of promise and on change of get projects. */
  useEffect(() => {
    if (masterProjectId) {
      const firestore = firebase.firestore();
      firestore
        .collection('masterProjects')
        .where(firebase.firestore.FieldPath.documentId(), '==', masterProjectId)
        .onSnapshot((masterProjectDetails: any) => {
          if (
            masterProjectDetails &&
            masterProjectDetails.docs &&
            masterProjectDetails.docs.length
          ) {
            const masterProjectDetailsData = masterProjectDetails.docs[0].data();
            setTagList(masterProjectDetailsData.tags || []);
            setWorkflow(masterProjectDetailsData.workflow || []);
            setMemberList(masterProjectDetailsData.members || []);
          }
        });
    }
  }, [masterProjectId]);

  const addComment = async () => {
    const firestore = firebase.firestore();
    return await firestore
      .collection('chat')
      .add({
        messages: [],
        count: 0,
      })
      .then((obj): any => {
        if (obj.id) {
          setThreadChatId(obj.id);
          // setThreadChatMessages([]);
          setIsChatMode(true);
          setChatWindowOpen(true);
          // setTimeout(() => {
          //   setIsChatMode(false);
          // }, 0)
          // addMessageInThread({
          //   message: "Untitled thread",
          //   createdAt: moment()
          //     .utc()
          //     .valueOf(),
          //   messageId: obj.id,
          //   chatMessageCount: 0
          // }, obj.id)

          let oldThreads: IThread[] = canvasDetails.threads || [];
          const sequenceNo = oldThreads.length + 1;
          // const { fullName = '', photoURL = '', email = '' } = profile;
          const pageUrlParams = new URLSearchParams(window.location.search);
          const page: any = pageUrlParams.get('page');
          let message = 'Untitled thread';
          if(type === 'audio'){
            message = `${message} (${getVideoDurationFormat(mediaPlayedTime)})`
          }
          if (type === 'pdf' && page) {
            message = `Page ${page}: Untitled thread`;
          }
          if (oldThreads.length) {
            oldThreads.unshift({
              member: {
                image: photoURL,
                name: fullName,
                email,
              },
              message,
              mediaTime: mediaPlayedTime,
              createdAt: moment()
                .utc()
                .valueOf()
                .toString(),
              replyCount: 0,
              sequenceNo,
              id: obj.id,
              isResolve: false,
              readUserEmails: [email],
              page,
            });
          } else {
            oldThreads = [
              {
                member: {
                  image: photoURL,
                  name: fullName,
                  email,
                },
                message,
                mediaTime: mediaPlayedTime,
                createdAt: moment()
                  .utc()
                  .valueOf()
                  .toString(),
                replyCount: 0,
                sequenceNo,
                id: obj.id,
                isResolve: false,
                readUserEmails: [email],
                page,
              },
            ];
          }
          setSelectedThread({
            member: {
              image: photoURL,
              name: fullName,
              email,
            },
            message,
            mediaTime: mediaPlayedTime,
            createdAt: moment()
              .utc()
              .valueOf()
              .toString(),
            replyCount: 0,
            sequenceNo,
            id: obj.id,
            isResolve: false,
            readUserEmails: [email],
            page,
          });
          firestore
            .collection('projects')
            .doc(props.match.params.id)
            .update({
              threads: oldThreads,
            });
          return obj.id;
        }
      });
  };

  const changeVideoTime = () => {
    setIsChatMode(false);
    const newSelectedThreadData = JSON.parse(JSON.stringify(selectedThread));
    newSelectedThreadData.mediaTime = 0;
    setSelectedThread(newSelectedThreadData);
    const pathname = window.location.pathname;
    window.history.pushState({}, document.title, pathname);
  };

  const changeThread = async (
    selectedThreadData: IThread,
    _canvasProjectDetails = initialCanvasState,
  ) => {
    setIsChatMode(true);
    setChatWindowOpen(true);
    // const canvasDetailsObj = _canvasProjectDetails.title ? _canvasProjectDetails : canvasDetails;
    const readUserEmails: any = selectedThread.readUserEmails || [];
    // const oldThreads: IThread[] = canvasDetailsObj.threads || [];
    const newSelectedThreadData: any = selectedThreadData;
    if (!readUserEmails.includes(email)) {
      readUserEmails.push(email);
      newSelectedThreadData.readUserEmails = readUserEmails;
    }
    setSelectedThread(newSelectedThreadData);
    setThreadChatId(selectedThreadData.id);
    let pathname = window.location.pathname;
    pathname = `${pathname}?threadId=${selectedThreadData.id}`;
    if (selectedThreadData && selectedThreadData.page) {
      pathname = `${pathname}&page=${selectedThreadData.page}`;
    }
    window.history.pushState({}, document.title, pathname);

    // const matchedThreadIndex = oldThreads.findIndex((thread: IThread) => thread.id === props.location.search);
    // const firestore = firebase.firestore();

    // firestore
    //   .collection('chat')
    //   .where(firebase.firestore.FieldPath.documentId(), '==', selectedThreadData.id)
    //   .onSnapshot((chatDetails: any) => {
    //     if (chatDetails && chatDetails.docs && chatDetails.docs.length) {
    //       const constchatDetailData = chatDetails.docs[0].data();
    //       setThreadChatId(chatDetails.docs[0].id);
    //       // setThreadChatMessages(constchatDetailData.messages);
    //       setThreadChatCount(constchatDetailData.count);
    //       setIsChatMode(true);
    //       oldThreads[matchedThreadIndex] = newSelectedThreadData;
    //       firestore
    //         .collection('projects')
    //         .doc(props.match.params.id)
    //         .update({
    //           threads: oldThreads
    //         });
    //     }
    //   })
  };

  const addMessageInThread = (chatMessage: any, newDocumentId: any) => {
    // if (true) {
    //   return
    // }
    const { message, chatMessageCount } = chatMessage;
    const firestore = firebase.firestore();
    // if (threadChatCount !== 0) {
    const oldThreads: IThread[] = canvasDetails.threads || [];
    const matchedThreadIndex = oldThreads.findIndex(
      (thread: IThread) => thread.id === threadChatId,
    );
    const pageUrlParams = new URLSearchParams(window.location.search);
    const page: any = pageUrlParams.get('page');
    if (matchedThreadIndex > -1) {
      oldThreads[matchedThreadIndex].replyCount = chatMessageCount;
      oldThreads[matchedThreadIndex].id = newDocumentId;
      if (
        !oldThreads[matchedThreadIndex].message ||
        oldThreads[matchedThreadIndex].message.includes('Untitled thread')
      ) {
        if (oldThreads[matchedThreadIndex].message === 'Untitled thread') {
          oldThreads[matchedThreadIndex].message = message;
        } else {
          oldThreads[matchedThreadIndex].message = oldThreads[matchedThreadIndex].message.replace(
            'Untitled thread',
            message,
          );
        }
      }
      oldThreads[matchedThreadIndex].page = page;
      setThreadChatId(newDocumentId);
      firestore
        .collection('projects')
        .doc(props.match.params.id)
        .update({
          threads: oldThreads,
        });
      setSelectedThread({
        ...selectedThread,
        replyCount: chatMessageCount,
        page,
      });
    }
    // } else {
    //   let oldThreads: IThread[] = canvasDetails.threads || [];
    //   let sequenceNo = oldThreads.length + 1;
    //   const { fullName = '', photoURL = '', email = '' } = profile;
    //   const messageData = {
    //     member: {
    //       image: photoURL,
    //       name: fullName,
    //       email,
    //     },
    //     message,
    //     mediaTime: selectedThread.mediaTime,
    //     createdAt,
    //     replyCount: 1,
    //     sequenceNo,
    //     id: newDocumentId,
    //     isResolve: false,
    //     readUserEmails: [email]
    //   };
    //
    //   if (oldThreads[0] && oldThreads[0].dummy === true) {
    //     sequenceNo = 1;
    //     oldThreads = [messageData]
    //   } else {
    //     oldThreads.unshift(messageData)
    //   }
    //

    //   setThreadChatId(newDocumentId);
    //   setSelectedThread({
    //     member: {
    //       image: photoURL,
    //       name: fullName,
    //       email
    //     },
    //     message,
    //     mediaTime: mediaPlayedTime,
    //     createdAt,
    //     replyCount: 1,
    //     sequenceNo,
    //     id: newDocumentId,
    //     isResolve: false,
    //     readUserEmails: [email]
    //   })
    //   setThreadChatCount(1);
    //

    //   firestore
    //     .collection('projects')
    //     .doc(props.match.params.id)
    //     .update({
    //       threads: oldThreads
    //     });
    // }
  };

  const updateThreadMessage = (updatedThreadMessage: string) => {
    const firestore = firebase.firestore();
    const oldThreads: IThread[] = canvasDetails.threads || [];
    const matchedThreadIndex = oldThreads.findIndex(
      (thread: IThread) => thread.id === threadChatId,
    );
    if (matchedThreadIndex > -1) {
      oldThreads[matchedThreadIndex].message = updatedThreadMessage;
      firestore
        .collection('projects')
        .doc(props.match.params.id)
        .update({
          threads: oldThreads,
        });
      setSelectedThread({
        ...selectedThread,
        message: updatedThreadMessage,
      });
    }
  };

  const pauseVideo = (currentMediaTime: number) => {
    setMediaPlayedTime(currentMediaTime);
  };

  const threadActionMenu = () => {
    const menu = (
      <Menu>
        {['Share Link', 'Delete'].map((action: string, index: number) => (
          <Menu.Item
            className='thread-action-menu-item project-dropdown-item'
            key={index}
            onClick={() => {
              if (action === 'Delete') {
                deleteThread();
              } else if (action === 'Share Link') {
                copyURL(window.location.href);
              }
            }}
          >
            {action}
          </Menu.Item>
        ))}
      </Menu>
    );
    return menu;
  };

  const deleteThread = () => {
    if (!uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          props.history.push('/signup');
        },
      });
    } else {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Do you want to delete this thread?',
        onOk: async () => {
          const firestore = firebase.firestore();
          const oldThreads: IThread[] = canvasDetails.threads || [];
          const matchedThreadIndex = oldThreads.findIndex(
            (thread: IThread) => thread.id === threadChatId,
          );
          if (matchedThreadIndex > -1) {
            oldThreads.splice(matchedThreadIndex, 1);
            firestore
              .collection('projects')
              .doc(canvasId)
              .update({
                threads: oldThreads,
              });
            const pageDocument = await firestore
              .collection('pages')
              .where('project_id', '==', canvasId)
              .get();
            if (pageDocument && pageDocument.docs && pageDocument.docs.length) {
              const pageData: any = pageDocument.docs[0].data();
              let feedbacks = pageData.feedback || [];
              feedbacks = feedbacks.filter((feedback: any) => {
                return feedback.circle_pointer.id !== threadChatId;
              });
              pageData.feedback = feedbacks;
              firestore
                .collection('pages')
                .doc(pageDocument.docs[0].id)
                .update({
                  feedback: feedbacks,
                });

              const urlParams = new URLSearchParams(window.location.search);
              const pageValue =
                props.match.path === '/c/:id' ? urlParams.get('threadId') : urlParams.get('page');

              const store = { pages: pageData, auth: uid, pagePath: pageValue };
              syncWithIframe(store, 'SYNC_INIT');
            }
            setIsChatMode(false);
          }
        },
      });
    }
  };

  const chatWindowRender = () => {
    return (
      <div
        className='thread-chat-container'
        style={{
          width: isMobile ? '100%' : undefined,
          height: isMobile ? '100vh' : undefined,
        }}
      >
        {isMobile && (
          <span
            onClick={() => {
              let pathname = window.location.pathname;
              const pageUrlParams = new URLSearchParams(window.location.search);
              const pageParam: any = pageUrlParams.get('page');
              if (pageParam) {
                pathname = `${pathname}?page=${pageParam}`;
              }
              window.history.pushState({}, document.title, pathname);
              setChatWindowOpen(false);
            }}
            style={{
              marginLeft: '10px',
              marginTop: '5px',
              background: 'rgb(255, 255, 255)',
            }}
          >
            <Icon type='close' />
          </span>
        )}
        {!isChatMode && (
          <Thread
            initial={initial}
            filterCheckbox={filterCheckbox}
            setFilterCheckbox={setFilterCheckbox}
            loginUserEmail={email}
            threads={threads}
            changeThread={changeThread}
            addComment={addComment}
            setActiveTab={setActiveTab}
            showCommenting={
              props.match.path === '/pdf/:id' ||
              props.match.path === '/whiteboard/:id' ||
              props.match.path === '/website/:id' ||
              props.match.path === '/image/:id'
            }
            mediaPlayedTime={mediaPlayedTime}
            profile={profile}
            videoUrl={project_url}
            match={props.match}
            uid={uid}
            selectedOrganizationData={selectedOrganizationData}
          />
        )}
        {!threads.length && !isChatMode && !initial ? (
          <div className='no-thread-found'>
            <Chat />
            <div>No comments yet</div>
          </div>
        ) : null}

        {isChatMode && (
          <div className='thread-list'>
            <div
              style={{
                width: isMobile ? '100%' : undefined,
                paddingTop: isMobile ? '0px' : undefined,
              }}
              className='chat-container'
            >
              <div className='chat-container-thread'>
                <div className='thread-header'>
                  <div className='thread-left-section'>
                    <div
                      className='back-icon cursor-pointer'
                      onClick={() => {
                        setIsChatMode(false);
                        const newSelectedThreadData = JSON.parse(JSON.stringify(selectedThread));
                        newSelectedThreadData.mediaTime = 0;
                        setSelectedThread(newSelectedThreadData);
                        let pathname = window.location.pathname;
                        const pageUrlParams = new URLSearchParams(window.location.search);
                        const pageParam: any = pageUrlParams.get('page');
                        if (pageParam) {
                          pathname = `${pathname}?page=${pageParam}`;
                        }
                        window.history.pushState({}, document.title, pathname);
                      }}
                    >
                      <BackCircleIcon />
                    </div>
                    {props.match.path === '/c/:id' && (
                      <div className='thread-canvas-time'>
                        {getVideoDurationFormat(selectedThread.mediaTime)}
                      </div>
                    )}
                  </div>

                  <div className='thread-right-section'>
                    {isMobile ? (
                      <DropDownCustom
                        buttonComponent={() => (
                          <div className='more-icon cursor-pointer'>
                            <TripleDot />
                          </div>
                        )}
                        position={'bottom'}
                        items={threadActionMenu()}
                      />
                    ) : (
                      <Dropdown overlay={threadActionMenu()}>
                        <div className='more-icon cursor-pointer'>
                          <TripleDot />
                        </div>
                      </Dropdown>
                    )}
                    <Tooltip
                      title={selectedThread.isResolve ? 'Un Resolve' : 'Resolve'}
                      placement='bottomLeft'
                    >
                      <div className='right-click cursor-pointer' onClick={resolveThread}>
                        <CircleRightClick />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {
                  <div className='thread-item-section'>
                    <ThreadItem
                      loginUserEmail={email}
                      isEditableThread={true}
                      thread={selectedThread}
                      updateThreadMessage={updateThreadMessage}
                      isVideoType={props.match.path === '/c/:id'}
                    />
                  </div>
                }
              </div>
              <ChatWindow
                projectID={props.match.params.id}
                chatId={threadChatId}
                isWriteAccess={true}
                addMessageInThread={addMessageInThread}
                clearOldChat={clearOldChat}
                setClearOldChat={setClearOldChat}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  const resolveThread = () => {
    if (!uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          props.history.push('/signup');
        },
      });
    } else {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: `Do you want to ${selectedThread.isResolve ? 'un resolve' : 'resolve'} this thread?`,
        onOk: () => {
          const firestore = firebase.firestore();
          const oldThreads: IThread[] = canvasDetails.threads || [];
          const matchedThreadIndex = oldThreads.findIndex(
            (thread: IThread) => thread.id === threadChatId,
          );
          if (matchedThreadIndex > -1) {
            oldThreads[matchedThreadIndex].isResolve = selectedThread.isResolve ? false : true;
            firestore
              .collection('projects')
              .doc(canvasId)
              .update({
                threads: oldThreads,
              });
            setIsChatMode(false);
          }
        },
      });
    }
  };

  const {
    type = '',
    title = '',
    project_url,
    createdAtUTC = '',
    tags = [],
    members = [],
    workflowId = '',
    transcript = [],
    archived = 'no',
    blobURL = '',
    project_created_by = '',
    userNotes = '',
    jiraIssueUrl,
    trelloIssueUrl,
    canvasPassword,
  } = canvasDetails;

  const checkPassword = () => {
    if (canvasPassword === inputPassword) {
      setShowPasswordField(false);
      setPasswordError('');
    } else {
      setPasswordError('Password is not Correct!');
    }
  };
  if (loading) {
    return (
      <div className='full-page-loader'>
        <img width='80' src='/images/small-loader.gif' alt='Zooc' />
      </div>
    );
  }
  if (error) {
    return error;
  }
  if (showPasswordField) {
    return (
      <div>
        <Logo />
        <div style={{ padding: '30px 0 10px 0' }}>This canvas is password protected!</div>
        <div className='d-flex'>
          <div style={{ width: '350px' }}>
            <Input
              value={inputPassword}
              onChange={(e: any) => setInputPassword(e.target.value)}
              className='ant-input'
              type='password'
              placeholder='Password'
            />
            {/* <input value={inputPassword} onChange={(e: any) => setInputPassword(e.target.value)} placeholder='Type canvas password' /> */}
          </div>
        </div>
        <div style={{ color: 'red', marginBottom: 10 }}>{passwordError}</div>
        <Button className='add tag-btn' type='primary' onClick={checkPassword}>
          Verify
        </Button>
      </div>
    );
  }

  return (
    <div className='canvas-detail-container'>
      <div
        className={
          props.match.path === '/pdf/:id'
            ? 'canvas-content-container no-scroll'
            : 'canvas-content-container'
        }
      >
        <div className={isMobile ? '' : 'header-area'}>
          <CanvasHeader
            isInterviewCanvas={!(email === 'aemal@zooc.io' || email === 'diana@reactgeeks.com' || email === 'manrajparmar793@gmail.com' || !isInterviewCanvas)}
            masterProjectId={masterProjectId}
            tags={tags}
            tagList={tagList}
            memberList={memberList}
            members={members}
            workflow={workflow}
            title={title}
            projectId={props.match.params.id}
            canvasWorkflowId={workflowId}
            history={props.history}
            archived={archived}
            iframe={props.iframe}
            setDrawing={setDrawing}
            isWriteAccess={true}
            keyboardEvent={keyboardEvent}
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            showControls={
              props.match.path === '/whiteboard/:id' ||
              props.match.path === '/website/:id' ||
              props.match.path === '/image/:id' ||
              props.match.path === '/pdf/:id' ||
              (props.match.path === '/c/:id' && isChatMode)
            }
            jiraIssueUrl={jiraIssueUrl}
            trelloIssueUrl={trelloIssueUrl}
            match={props.match}
            canvasDetails={canvasDetails}
            isCanvasOwner={canvasDetails.project_created_by === uid}
            isMobile={isMobile}
            uid={uid}
            selectedOrganizationData={selectedOrganizationData}
          />
        </div>
        <div className='video-player-area'>
          {props.match.path === '/text/:id' ? (
            <TextDetail projectId={props.match.params.id} userNotes={userNotes} />
          ) : // :
          // props.match.path === "/audio/:id" ?
          // use Custom Audio player here
          // <AudioPlayer id={'audioPlayer'} url={project_url} />
          // <Iframe
          //   isWriteAccess={true}
          //   drawerVisible={false}
          //   url={project_url}
          //   iframe={props.iframe}
          //   projectId={props.match.params.id}
          //   toggleDrawing={toggleDrawing}
          //   setKeyboardEvent={setKeyboardEvent}
          //   project={canvasDetails}
          //   addComment={addComment}
          //   strokeColor={backgroundColor}
          // />

          props.match.path === '/pdf/:id' ? (
            <>
              <PDFJS
                url={project_url}
                iframe={props.iframe}
                projectId={props.match.params.id}
                toggleDrawing={toggleDrawing}
                setKeyboardEvent={setKeyboardEvent}
                project={canvasDetails}
                addComment={addComment}
                strokeColor={backgroundColor}
                history={props.history}
                setIsChatMode={setIsChatMode}
                isChatMode={isChatMode}
              />
            </>
          ) : props.match.path === '/whiteboard/:id' ||
            props.match.path === '/website/:id' ||
            props.match.path === '/image/:id' ? (
            project_url ? (
              <Iframe
                isWriteAccess={true}
                drawerVisible={false}
                setDrawerVisible={() => null}
                url={project_url}
                iframe={props.iframe}
                projectId={props.match.params.id}
                toggleDrawing={toggleDrawing}
                setKeyboardEvent={setKeyboardEvent}
                project={canvasDetails}
                addComment={addComment}
                strokeColor={backgroundColor}
              />
            ) : null
          ) : (
            <VideoCanvas
              changeVideoTime={changeVideoTime}
              videoUrl={project_url}
              createdAt={createdAtUTC}
              createdAtUTC={createdAtUTC}
              isMediaCreatedByLoginUser={project_created_by === uid}
              pauseVideo={pauseVideo}
              changeThread={changeThread}
              selctedThreadMediaTime={selectedThread.mediaTime}
              transcript={transcript}
              blobURL={blobURL}
              // props for the Iframe
              iframe={props.iframe}
              projectId={props.match.params.id}
              toggleDrawing={toggleDrawing}
              setKeyboardEvent={setKeyboardEvent}
              project={canvasDetails}
              addComment={addComment}
              strokeColor={backgroundColor}
              isChatMode={isChatMode}
            />
          )}
        </div>
      </div>
      {(email === 'aemal@zooc.io' || email === 'diana@reactgeeks.com' || email === 'manrajparmar793@gmail.com'  || !isInterviewCanvas) && (
        <>
          {isMobile ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setChatWindowOpen(true);
              }}
              className='chat-floating-icon'
            >
              <ChatFloatingIcon />
            </div>
          ) : (
            chatWindowRender()
          )}
        </>
      )}
      {isMobile && (
        <Drawer
          placement='right'
          closable={false}
          width='90vw'
          onClose={() => {
            let pathname = window.location.pathname;
            const pageUrlParams = new URLSearchParams(window.location.search);
            const pageParam: any = pageUrlParams.get('page');
            if (pageParam) {
              pathname = `${pathname}?page=${pageParam}`;
            }
            window.history.pushState({}, document.title, pathname);
            setChatWindowOpen(false);
          }}
          visible={chatWindowOpen}
        >
          {chatWindowRender()}
        </Drawer>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    iframe: state.project.iframe,

    threads: state.canvas.filterThreads,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    canvasFilterThreadsAction: (threads: IThread[]) => dispatch(canvasFilterThreads(threads)),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Canvas);

// export default Canvas;

import firebase from 'firebase';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import uuid from 'uuid';
import { signUp as signUpAction } from '../../store/actions/authActions';
import { addPageComment } from '../../store/actions/pageActions';
import {
  addFeedback,
  createProject as createProjectAction,
  deleteLoginUserAllNotifications as deleteLoginUserAllNotificationsAction,
} from '../../store/actions/projectActions';
import { IUser } from '../../types/authTypes';
import { mobileCheck } from '../../utils';
import FeedbackModel from '../projects/FeedbackModel';
import '../shared/spinner.css';
const isMobile = mobileCheck();

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
  imageType: string;
  jiraIssueUrl: string;
  trelloIssueUrl: string;
  project_base64_image: any;
  appendVideo: string;
  thumnail_url: any;
}

const PublicChat = ({
  signUp,
  createProject,
  addFeedbackButton,
  deleteLoginUserAllNotifications,
  auth,
  addPageFeedback,
}: {
  signUp: (signUpData: IUser) => any;
  createProject: any;
  addFeedbackButton: any;
  auth: { uid: string };
  addPageFeedback: any;
  deleteLoginUserAllNotifications(isHideDeletedNotification?: boolean): void;
}) => {
  const [pageID, setPageID] = useState('');
  const [projectID, setProjectID] = useState('');

  const eventHandler = (e: any) => {
    const eventData = e.data;
    switch (eventData.data) {
      case 'DELETE_USER_ALL_NOTIFICATION':
        deleteLoginUserAllNotifications(true);
        break;
      default:
    }
  };

  // useEffect(() => {
  //   const crispElement: any = document.querySelector('.crisp-client');
  //   if (crispElement && crispElement.style) {
  //     crispElement.style.display = 'none';
  //   }
  // });

  React.useEffect(() => {
    const { uid = '' } = auth;
    if (uid) {
      firebase
        .firestore()
        .collection('notifications')
        .doc(uid)
        .onSnapshot((doc: any) => {
          const notificationsData = doc.data();
          if (notificationsData) {
            const { unReadCount = 0 } = notificationsData;
            window.parent.postMessage({ event: 'UNREAD_MESSAGE_COUNT', value: unReadCount }, '*');
          }
        });
    }
  }, [auth]);

  React.useEffect(() => {
    window.addEventListener('message', eventHandler, false);
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  const createPublicUser = async () => {
    const publicUserID = uuid();

    signUp({
      email: `fh.public.user+${publicUserID}@gmail.com`,
      password: publicUserID,
      fullName: `publicClient-${publicUserID}`,
      adminIds: [],
      isPublicUser: true,
    }).then(async (signupData: any) => {
      window.parent.postMessage({ event: 'GET_LIVE_CHAT_USER_UID', value: signupData.uid }, '*');
      const projectData: any = {
        title: signupData.uid,
        project_url: 'https://zooc.io/whiteboard/',
        member_emails: ['fh.public.user+admin@gmail.com'],
        project_tags: [],
        type: 'whiteBoard',
        imageType: '',
        jiraIssueUrl: '',
        trelloIssueUrl: '',
        project_base64_image: '',
        appendVideo: '',
      };

      const fiebaseInstance: any = firebase;
      const firebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);

      createProject(projectData, firebaseAuthToken).then((projectRecID: string) => {
        const feedbackButtonDetails = {
          id: 'UgKP9Ynk43zznKMW',
          x: 170,
          y: 132,
          path: 'BODY#body-html-fhasa > DIV:nth-child(2)',
          url: 'https://zooc.io/whiteboard/',
          oldWindowHeight: 650,
          oldWindowWidth: 1346,
          pagePath: '',
        };

        addFeedbackButton(feedbackButtonDetails, projectRecID).then((pageData: any) => {
          setPageID(pageData.id);
          setProjectID(projectRecID);

          const messageData = {
            thumbnail: '',
            originalImage: '',
            feedback:
              'Hey there! You can see how it works by interacting with this chat window. 😎',
            email: 'fh.public.user+admin@gmail.com',
            sender: 'Aemal Sayer',
            time: moment().utc(),
            uid: 'a3S9WWVXwfe7BQVtvOtFZ72RLW03',
            attachment: '',
            attachedFileName: '',
            uuid: 'message-uuid',
            audioUrl: '',
            userAgent: window.navigator.userAgent,
            videoUrl: '',
          };

          addPageFeedback(
            messageData,
            {
              page_id: pageData.id,
              circle_id: 'UgKP9Ynk43zznKMW',
              project_id: projectRecID,
            },
            pageData.id,
          );

          localStorage.setItem('projectID', projectRecID);
          localStorage.setItem('pageID', pageData.id);
        });
      });
    });
  };

  useEffect(() => {
    if (!localStorage.getItem('userId')) {
      createPublicUser();
    } else {
      setProjectID(localStorage.getItem('projectID') || '');
      setPageID(localStorage.getItem('pageID') || '');
    }
  }, []);

  return (
    <div className='public-chat' style={{ height: '100vh', width: '100vw' }}>
      {!projectID || !pageID ? (
        <div className='spinner full-height'>
          <div className='gif-loader full-height' />
        </div>
      ) : (
        <div className={'feedbackbox ' + `${isMobile ? 'chat-mobile' : ''}`} style={{ zIndex: 10 }}>
          <FeedbackModel
            isWriteAccess={true}
            projectID={projectID}
            pageID={pageID}
            circlePointerID={'UgKP9Ynk43zznKMW'}
            isPublicChatWindow={true}
            clearShowingMessageid={() => null}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, any>) => {
  return {
    signUp: (creds: IUser) => dispatch(signUpAction(creds)),
    createProject: (project: Project, firebaseAuthToken: string) =>
      dispatch(createProjectAction(project, firebaseAuthToken)),
    addFeedbackButton: (feedback: any, projectId: string) =>
      dispatch(addFeedback(feedback, projectId)),
    deleteLoginUserAllNotifications: (isHideDeletedNotification?: boolean) =>
      dispatch(deleteLoginUserAllNotificationsAction(isHideDeletedNotification)),
    addPageFeedback: (pageComment: any, data: any, pageId: string) =>
      dispatch(addPageComment(pageComment, data, pageId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicChat);

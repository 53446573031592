import { Modal, notification } from 'antd';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ShowNewVideoCanvas as ShowNewVideoCanvasAction } from 'store/actions/newCanvasActions';
import { v4 as uuid } from 'uuid';
import {
  createProject as createProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { addThumnailURLInProject, trackAnalytics } from '../../utils';
import ByURLDialogContent from './ByURLDialogContent';
import './style.css';
import UploadDialogContent from './UploadDialogContent';
import VideoDialogContent from './VideoDialogContent';
// import { mobileCheck } from '../../utils';
// const isMobile = mobileCheck();

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
}

const Dialog = (props: {
  ShowNewVideoCanvas: any;
  canvas: any;
  selectedWorkflowId?: string;
  selectedMasterProjectId?: string;
  history?: any;
  createProject: any;
  resetLoader: any;
  firebaseAuthToken: string;
  profile: any;
  selectedMasterProject: any;
  auth: any;
  selectedOrganizationData: any;
}) => {
  const {
    selectedWorkflowId,
    selectedMasterProjectId,
    history,
    createProject,
    firebaseAuthToken,
    profile,
    ShowNewVideoCanvas,
    selectedMasterProject,
    auth,
    selectedOrganizationData,
  } = props;
  const [selectedTab, setSelectedTab] = useState('Record');
  const [loading, setLoading] = useState(false);

  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization && selectedOrganizationData) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    if (profile) {
      userQuota = profile.userQuota;
    }
  }

  const createVideoTask = async (url: string, videoType: string, blobURL: string = '') => {
    setLoading(true);
    trackAnalytics('Canvas', 'Created a video canvas', videoType);
    const { email } = profile;
    const projectData = {
      title: 'Untitled task',
      project_url: url,
      member_emails: [email],
      project_tags: [],
      type: videoType, // videoRecord/videoUpload/videoByURL
      workflowId: selectedWorkflowId,
      masterProjectId: selectedMasterProjectId,
      archived: 'no',
      blobURL,
    };
    let userFirebaseAuthToken = firebaseAuthToken;
    const fiebaseInstance: any = firebase;
    if (
      !firebaseAuthToken &&
      fiebaseInstance &&
      fiebaseInstance.auth() &&
      fiebaseInstance.auth().currentUser
    ) {
      userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    createProject(projectData, userFirebaseAuthToken, selectedMasterProject).then(
      async (insertedId: any) => {
        if (insertedId) {
          if (videoType === 'videoRecord' || videoType === 'videoUpload') {
            addThumnailURLInProject(blobURL ? blobURL : url, insertedId);
            if (blobURL) {
              const blob: any = await fetch(blobURL).then((r) => r.blob());
              if (blob.size) {
                if (auth && auth.uid && userQuota) {
                  userQuota.storageLimit = userQuota.storageLimit - blob.size / 1024;
                  if (userQuota.storageLimit <= 0) {
                    notification.error({
                      message: 'Your current plan doesn’t include using this feature.',
                    });
                    return true;
                  }
                  const firestore = firebase.firestore();
                  if (profile.isManagedUserQuotaInOrganization) {
                    firestore
                      .collection('organization')
                      .doc(selectedOrganizationData.id)
                      .update({
                        userQuota,
                      });
                  } else {
                    firestore
                      .collection('users')
                      .doc(auth.uid)
                      .update({
                        userQuota,
                      });
                  }
                }
              }
            }
          }
          const slackMessage = `${profile.fullName} created video type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/c/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );
          ShowNewVideoCanvas(false);
          setLoading(false);
          history.push(`/c/${insertedId}`);
        }
      },
    );
  };
  return (
    <div>
      <Modal
        visible={props.canvas.addVideoCanvasVisiblity}
        footer={null}
        closable={false}
        maskClosable={true}
        onOk={() => props.ShowNewVideoCanvas(false)}
        onCancel={() => props.ShowNewVideoCanvas(false)}
        bodyStyle={{ height: '300px', width: '500px' }}
      >
        <div className='dialogContainer'>
          <div className='dialogTabsContainer'>
            <div
              className={`dialogTab dialogTab1 ${selectedTab === 'Record' && 'dialogTabSelected'}`}
              onClick={() => {
                trackAnalytics('Canvas', 'Video Canvas Record Clicked');
                setSelectedTab('Record');
              }}
            >
              Record
            </div>
            <div
              className={`dialogTab dialogTab2 ${selectedTab === 'Upload' && 'dialogTabSelected'}`}
              //   className={`${isMobile ? 'pdfDialog' : ''} dialogTab dialogTab2 ${selectedTab ===
              //     'Upload' && 'dialogTabSelected'}`}

              onClick={() => {
                if (
                  auth &&
                  auth.uid &&
                  userQuota &&
                  userQuota.storageLimit > 0 &&
                  userQuota.totalVideoComments &&
                  userQuota.totalVideoComments > 0 &&
                  userQuota.totalScreenRecordings > 0
                ) {
                  trackAnalytics('Canvas', 'Video Canvas Upload Tab Clicked');
                  setSelectedTab('Upload');
                } else {
                  notification.error({
                    message: 'Your current plan doesn’t include using this feature.',
                  });
                }
              }}
            >
              Upload
            </div>
            <div
              className={`dialogTab dialogTab3 ${selectedTab === 'ByURL' && 'dialogTabSelected'}`}
              // className={`${isMobile ? 'pdfDialog' : ''} dialogTab dialogTab3 ${selectedTab ===
              //   'ByURL' && 'dialogTabSelected'}`}
              onClick={() => {
                trackAnalytics('Canvas', 'Video Canvas By URL Clicked');
                setSelectedTab('ByURL');
              }}
            >
              By URL
            </div>
          </div>
          {selectedTab === 'Record' && props.canvas.addVideoCanvasVisiblity ? (
            <VideoDialogContent
              createVideoTask={createVideoTask}
              ShowNewVideoCanvas={ShowNewVideoCanvas}
              selectedOrganizationData={selectedOrganizationData}
            />
          ) : selectedTab === 'Upload' && props.canvas.addVideoCanvasVisiblity ? (
            <UploadDialogContent
              isLoading={loading}
              firebaseAuthToken={firebaseAuthToken}
              createVideoTask={createVideoTask}
              ShowNewVideoCanvas={ShowNewVideoCanvas}
              projectType='video'
              profile={profile}
              auth={auth}
              selectedOrganizationData={selectedOrganizationData}
            />
          ) : (
            props.canvas.addVideoCanvasVisiblity && (
              <ByURLDialogContent
                type={'video'}
                loading={loading}
                createVideoTask={createVideoTask}
                ShowNewVideoCanvas={ShowNewVideoCanvas}
              />
            )
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    canvas: state.canvas,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ShowNewVideoCanvas: (modalVisiblity: boolean) =>
      dispatch(ShowNewVideoCanvasAction(modalVisiblity)),
    resetLoader: () => dispatch(resetAction()),
    createProject: (project: Project, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dialog) as any;

import { Drawer, Modal } from 'antd';
import firebase from 'firebase';
import React, { useState } from 'react';
import ColorContext from '../../../context/ColorContext';
import { Zbutton } from '../../../primitives';
import { IMasterProject, ITag } from '../../../types/projectTypes';
import { mobileCheck } from '../../../utils';
import { Close } from '../../svg';
import './tags.css';
import TagsDialog from './TagsDialog';

const isMobile = mobileCheck();

interface IProps {
  tagsDrawerVisible: boolean;
  setTagDrawerVisible: () => void;
  project: IMasterProject;
}

const TagsDrawer: React.FC<IProps> = ({ tagsDrawerVisible, setTagDrawerVisible, project }) => {
  const { tags } = project;
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTag, setSelectedTag] = useState<ITag>({
    title: '',
    color: '',
    id: '',
  });
  const [tagModalVisible, setTagModalVileble] = useState(false);

  const addShwoModal = () => {
    setIsEdit(false);
    setTagModalVileble(true);
  };

  const editShwoModal = (tag: ITag) => {
    setSelectedTag(tag);
    setIsEdit(true);
    setTagModalVileble(true);
  };

  const deleteShwoModal = (tag: ITag) => {
    const deletedTag = tags && tags.filter((el) => el.id !== tag.id);
    if (tag && tag.id) {
      Modal.confirm({
        content: '',
        title: `Are you sure, you want to delete "${tag.title}" tag?`,
        onOk() {
          const firestore = firebase.firestore();
          const updatedTimeStamp = new Date().getTime();
          firestore
            .collection('masterProjects')
            .doc(project.id)
            .update({
              tags: deletedTag,
              updatedTimeStamp,
            });
        },
      });
    }
  };

  const handleCancel = () => {
    setTagModalVileble(false);
  };

  return (
    <Drawer
      title='Modify Tags'
      placement='right'
      closable={false}
      onClose={() => setTagDrawerVisible()}
      visible={tagsDrawerVisible}
      style={{ position: 'absolute' }}
      width={isMobile ? '90vw' : '75vw'}
    >
      <ColorContext.Consumer>
        {({ theme }) => (
          <div className='tags-container' style={{ color: theme.text.color2 }}>
            <p>Tags can be used to easily categorize and filter canvases.</p>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <Zbutton primary btnColors={theme} onClick={addShwoModal}>
                Add New
              </Zbutton>
            </div>
            <ul className='tag-list'>
              {tags &&
                tags.map((tag: ITag) => {
                  return (
                    <li key={tag.id}>
                      <span className='tag-content'>
                        <div className='tag-color' style={{ background: tag.color }} />
                        <div>{tag.title}</div>
                      </span>
                      <div className='tag-btns-container'>
                        <div className='hide-edit' onClick={() => editShwoModal(tag)}>
                          Edit
                        </div>
                        <div className='hide-delete' onClick={() => deleteShwoModal(tag)}>
                          Delete
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </ColorContext.Consumer>
      <TagsDialog
        isEdit={isEdit}
        tagModalVisible={tagModalVisible}
        handleCancel={handleCancel}
        project={project}
        selectedTag={selectedTag}
      />
      <div className='drawer-close-btn' onClick={() => setTagDrawerVisible()}>
        <Close />
      </div>
    </Drawer>
  );
};

export default TagsDrawer;

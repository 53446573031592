import React from 'react';

export default () => (
  <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.5 0C6.49189 0 0 6.49189 0 14.5C0 22.5081 6.49189 29 14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49189 22.5081 0 14.5 0ZM14.5 3.15063C20.7681 3.15063 25.8476 8.23349 25.8476 14.5C25.8476 20.7665 20.7681 25.8476 14.5 25.8476C8.23189 25.8476 3.1524 20.7665 3.1524 14.5C3.15242 8.23349 8.23189 3.15063 14.5 3.15063ZM20.0561 7.61463L11.652 16.0204L8.9262 13.2946L6.2517 15.9674L8.97753 18.6932L11.6697 21.3854L14.3425 18.7109L22.7483 10.3068L20.0561 7.61463Z'
      fill='#27AE60'
    />
  </svg>
);

import React from 'react';

export default () => (
  <svg width='26' height='34' viewBox='0 0 26 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.893 27.8931C15.017 27.3874 14.7707 25.9932 14.2024 25.1118C13.6404 24.2088 12.8638 23.5875 12.0808 22.9879C11.5221 22.5851 11.0233 22.0827 10.6032 21.4998C10.4264 21.2614 10.0664 20.8207 10.4327 20.734C10.8052 20.6473 11.4493 21.0663 11.7777 21.2253C12.3523 21.4998 12.9206 21.8176 13.451 22.1933L14.0888 20.9652C13.1037 20.2211 11.8408 19.5637 10.6663 19.3687C9.99697 19.2531 9.28975 19.412 9.06242 20.2428C8.86036 20.958 9.1824 21.6804 9.54864 22.2439C10.4137 23.5659 11.7587 24.2016 12.7627 25.343C12.9774 25.5814 13.2363 25.8631 13.3626 26.1954C13.4952 26.5133 13.4636 26.535 13.1669 26.535C12.3839 26.535 11.4051 25.8342 10.7673 25.3719L10.1296 26.6C11.0957 27.2791 12.7122 28.341 13.893 27.8931ZM20.8958 16.6235C21.0347 16.4646 21.0347 16.2045 20.8958 16.0528L20.0749 15.1137C20.0088 15.0407 19.9205 15 19.8287 15C19.7368 15 19.6485 15.0407 19.5824 15.1137L18.9383 15.8505L20.2517 17.3531L20.8958 16.6235ZM14.6823 20.7196V22.2222H15.9958L19.8792 17.7794L18.5658 16.2767L14.6823 20.7196Z'
      fill='#9B9B9B'
    />
    <path
      d='M24.849 7.20982C25.1649 7.56399 25.4358 8.04464 25.6615 8.65179C25.8872 9.25893 26 9.81548 26 10.3214V32.1786C26 32.6845 25.842 33.1146 25.526 33.4688C25.2101 33.8229 24.8264 34 24.375 34H1.625C1.17361 34 0.789931 33.8229 0.473958 33.4688C0.157986 33.1146 0 32.6845 0 32.1786V1.82143C0 1.31548 0.157986 0.885417 0.473958 0.53125C0.789931 0.177083 1.17361 0 1.625 0H16.7917C17.2431 0 17.7396 0.126488 18.2812 0.379464C18.8229 0.63244 19.2517 0.936012 19.5677 1.29018L24.849 7.20982ZM17.3333 2.58036V9.71429H23.6979C23.5851 9.34747 23.4609 9.08817 23.3255 8.93638L18.0273 2.99777C17.8919 2.84598 17.6606 2.70685 17.3333 2.58036ZM23.8333 31.5714V12.1429H16.7917C16.3403 12.1429 15.9566 11.9658 15.6406 11.6116C15.3247 11.2574 15.1667 10.8274 15.1667 10.3214V2.42857H2.16667V31.5714H23.8333Z'
      fill='#9B9B9B'
    />
  </svg>
);

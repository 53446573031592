import React from 'react';

export default () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 256 213'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
  >
    <path
      d='M128.3 0C59.66 0 5.884 87.33.796 161.09c21.54 37.383 73.483 51.435 127.506 51.435 54.019 0 105.965-14.052 127.494-51.436C250.707 87.33 196.938 0 128.301 0'
      fill='#B6DEFF'
    />
    <path
      d='M237.86 143.52s-15.16-32.776-30.569-51.898c-15.42-19.13-34.251-39.96-45.385-39.96-11.128 0-42.236 55.078-56.507 55.078-14.274 0-21.692-24.968-39.241-24.968-17.556 0-45.448 60.209-45.448 60.209l-1.89 10.207s9.69 42.961 111.064 42.961c101.378 0 109.48-42.256 109.48-42.256l-1.503-9.374'
      fill='#6C6'
    />
    <path
      d='M106.987 130.245c-15.744 0-23.815-15.534-28.191-25.123-3.935-8.638-4.616-22.276-15.781-22.658 1.097-.435 2.153-.692 3.143-.692 17.549 0 24.967 24.968 39.241 24.968 14.271 0 45.379-55.079 56.507-55.079 2.097 0 4.459.74 7.022 2.056-1.896-.25-4.327-.076-6.2 1.803-3.597 3.598-36.25 74.725-55.741 74.725'
      fill='#17AD49'
    />
  </svg>
);

import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { IOrgProject } from '../../../types/projectTypes';
import ImageDragDrop from '../../shared/ImageDragDrop';

interface IProps {
  orgId: string;
  selectedProject: IOrgProject;
}

const OrgLogo: React.FC<IProps> = ({ selectedProject, orgId }) => {
  // @ts-ignore
  const [logoThumbnailUrl, setLogoThumbnailUrl] = useState('');
  const [logoImageUrl, setLogoImageUrl] = useState('');

  useEffect(() => {
    setLogoThumbnailUrl(selectedProject.logoThumbnailUrl ? selectedProject.logoThumbnailUrl : '');
    setLogoImageUrl(selectedProject.logoUrl ? selectedProject.logoUrl : '');
  }, [selectedProject]);

  const handleAddLogo = (image: any, thumbnailImage: any) => {
    const orgProjectId: string | null = window.localStorage.getItem('selectedProjectId');
    const firestore = firebase.firestore();
    const orgRef = firestore.collection('organization').doc(orgId);
    orgRef.get().then((doc: any) => {
      if (doc.exists) {
        const projects = doc.data().projects;
        const addLogo = projects.map((project: any) => {
          if (project.id === orgProjectId) {
            return {
              ...project,
              logoUrl: image,
              logoThumbnailUrl: thumbnailImage,
            };
          } else {
            return project;
          }
        });
        orgRef.set(
          {
            projects: addLogo,
          },
          { merge: true },
        );
      }
    });
  };

  return (
    <ImageDragDrop
      edit={false}
      hideDragAndDrop={true}
      url={logoImageUrl}
      sendFeedback={(image: any, thumbnailImage: any) => {
        handleAddLogo(image, thumbnailImage);
        image && setLogoImageUrl(image);
        thumbnailImage && setLogoThumbnailUrl(thumbnailImage);
      }}
      getThumbnailBlob={() => null}
    />
  );
};

export default OrgLogo;

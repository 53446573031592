import { Drawer } from 'antd';
import React from 'react';
import { mobileCheck } from '../../utils';
import OrganizationQuota from '../organization-quota';
import { Close } from '../svg';
import UserQuota from '../userquota';
import './auth.css';

const isMobile = mobileCheck();

interface IProps {
  onCloseDrawer: () => void;
  visible: boolean;
  organizationDetail: any;
  profile: any;
}
const UsageDrawer: React.FC<IProps> = ({ onCloseDrawer, visible, organizationDetail, profile }) => {
  return (
    <div>
      <Drawer
        title='Usage'
        placement='right'
        closable={false}
        onClose={() => onCloseDrawer()}
        visible={visible}
        style={{ position: 'absolute', overflowY: 'auto' }}
        width={isMobile ? '90vw' : '75vw'}
        className='profile-drawer-container'
      >
        <div className='drawer-close-btn' onClick={() => onCloseDrawer()}>
          <Close />
        </div>
        <div style={{ width: isMobile ? '100%' : undefined }} className='usage-drawer-user-quota'>
          {profile.isManagedUserQuotaInOrganization ? (
            <OrganizationQuota organizations={organizationDetail} />
          ) : (
            <UserQuota profile={profile} />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default UsageDrawer;

import { Button, Dropdown, Icon, Menu, Modal, Tooltip } from 'antd';
import firebase from 'firebase/app';
// import RecordAudioFile from '../recording/RecordAudioFile';

import 'firebase/storage';

import moment from 'moment';
import React, { MutableRefObject, useEffect, useState } from 'react';
// import { ReactMic } from 'react-mic';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { v4 as uuid } from 'uuid';
import { statsAction } from '../../store/actions/authActions';
import { updateFirebaseAuthToken as updateFirebaseAuthTokenAction } from '../../store/actions/authActions';
import {
  addPageComment,
  deleteFromVideoStorage,
  resolvePage,
} from '../../store/actions/pageActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { IFeedback } from '../../types/pageTypes';
import { getQueryStringValue, sendMessageToIframe, trackAnalytics } from '../../utils';
import InfoModal from '../shared/InfoModal';

import UploadFileLogic from '../shared/UploadFileLogic';

import DragAndDropZone from '../shared/DragAndDropZone';

import { mobileCheck } from '../../utils';
import AudioPlayer from '../player/AudioPlayer';
import RecordAudio from '../recording/RecordAudio';
import { CommentInfoModal } from './CommentInfoModal';
import { ImagePreview } from './ImagePreview';
import RecordVideoModal from './RecordVideoModal';
import RenderVideos from './RenderVideos';
import UploadModal from './UploadModal';
const isMobile = mobileCheck();
const itemRefs: any = {};

export interface IFeedbackModelProps {
  pages: any;
  auth: any;
  profile: any;
  iframe: any;
  addPageFeedback: any;
  resolvePageFeedback: any;
  onClose: any;
  projectID: string;
  pageID: string;
  circlePointerID: string;
  direction: string;
  resolved: string;
  currentShowingMessageid: string;
  clearShowingMessageid: any;
  isChatWindow?: boolean;
  isPublicChatWindow?: boolean;
  firebaseAuthToken: string;
  isWriteAccess: boolean;
  organization: any;
  updateFirebaseAuthToken(token: string): void;
}

const FeedbackModel: (props: IFeedbackModelProps) => JSX.Element = ({
  pages,
  auth,
  profile,
  iframe,
  addPageFeedback,
  resolvePageFeedback,
  onClose,
  projectID,
  pageID,
  circlePointerID,
  direction,
  resolved,
  currentShowingMessageid,
  clearShowingMessageid,
  isChatWindow,
  isPublicChatWindow,
  firebaseAuthToken,
  isWriteAccess = false,
  updateFirebaseAuthToken,
  organization,
}) => {
  const trackAnalyticsPrefix = isPublicChatWindow ? 'Public User - ' : '';
  let urlRefs: any = '';
  const imgRef = React.useRef() as MutableRefObject<HTMLImageElement>;
  let chatContainerRef: any = React.createRef();
  let feedbacks: IFeedback[] = [];
  let inProgress: IFeedback[] = [];
  const { firstName, lastName, initials = '' } = profile;
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = organization.selectOrganizationDetails.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  const [value, setValue] = React.useState('');
  const [selectedImage, setImage] = React.useState('');
  const [showPreview, setPreview] = React.useState(false);
  const [detailModal, showDetailModal] = React.useState(false);
  const [scrollIntoView, setScrollIntoView] = React.useState(true);
  const [userID, setUserID] = React.useState();
  const [sendingImage, setSendingImage] = React.useState(false);
  const [allowScrollToBootom, setAllowScrollToBootom] = React.useState(false);
  const [isRecordingModal, setIsRecordingModal] = React.useState(false);
  const [showCommentInfoModal, setShowCommentInfoModal] = React.useState(false);
  const [dataForCommentInfo, setDataForCommentInfo] = React.useState(null);
  const [activeKey, setActiveKey] = React.useState(null);
  const [isDropDown, setIsDropDown] = React.useState(false);
  const [isUploadModal, setIsUploadModal] = React.useState(false);
  const [feedbackList, setFeedbackList] = React.useState(feedbacks);
  const [isRecordModal, setIsRecordModal] = React.useState('');

  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const inputFileRef = React.useRef() as MutableRefObject<HTMLInputElement>;
  const [copied, setCopied] = useState(false);

  /*eslint-disable */
  const isDataUrlString = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  /*eslint-enable */
  const recordVoiceClipTime = userQuota && userQuota.recordVoiceClipTime;

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  React.useEffect(() => {
    if (pages) {
      const allFeedbacks = (pages[pageID] && pages[pageID].feedback) || [];
      const filteredCircleComments = allFeedbacks.filter(
        (data: any) => data.circle_pointer.id === circlePointerID,
      );
      if (filteredCircleComments[0] && filteredCircleComments[0].comment) {
        feedbacks = filteredCircleComments[0].comment;
        setFeedbackList(feedbacks);
      } else {
        feedbacks = [];
        setFeedbackList(feedbacks);
      }
      // setting userID on inital stages
      const userDetails = localStorage.getItem('userDetails');
      if (userDetails && auth.isEmpty) {
        const userData = JSON.parse(userDetails);
        if (userID === undefined) {
          setUserID(userData.uid);
        }
      } else if (auth.uid) {
        if (userID === undefined) {
          setUserID(auth.uid);
        }
      }
      scrollToClickedComment();
    }
  }, [pages, circlePointerID]);

  const eventHandler = (e: any) => {
    const eventData = e.data;

    switch (eventData.data) {
      case 'SCROLL_TO_CHAT':
        if (scrollerRef.current !== null) {
          scrollerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
        }

        break;
      default:
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', eventHandler, false);

    scrollToClickedComment();

    // window.parent.postMessage('LIVE_CHAT_NEW_MESSAGE', '*');
  }, [feedbackList]);

  React.useEffect(() => {
    if (scrollerRef.current !== null && allowScrollToBootom) {
      // @ts-ignore
      return scrollerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
    return setScrollIntoView(false);
  }, [scrollIntoView, feedbacks.length]);

  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (auth.isEmpty) {
      // open modal to get public user details
      showDetailModal(true);
    } else {
      if (e.keyCode === 13 && value !== '') {
        sendFeedbackFunc();
      }

      return setScrollIntoView(false);
    }
  };

  const sendFeedbackFunc = async () => {
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send message clicked');
    setAllowScrollToBootom(true);
    // check authentication
    if (auth.isEmpty) {
      // open modal to get public user details
      showDetailModal(true);
    } else {
      await sendFeedback(value, '', false, pageID, false, '', '');
    }
    return setScrollIntoView(true);
  };

  const sendMessage = async (firebaseLoginUserToken: string) => {
    clearShowingMessageid();
    setAllowScrollToBootom(true);
    await sendFeedback(value, '', false, pageID, false, '', '', '', firebaseLoginUserToken);
  };

  const isDataURL = (s: string) => {
    return !!s.match(isDataUrlString);
  };
  const handleResolve = () => {
    if (isWriteAccess) {
      if (auth.isEmpty) {
        // open modal to get public user details
        showDetailModal(true);
      } else {
        trackAnalytics(
          `${trackAnalyticsPrefix}Chat window`,
          resolved ? 'Resolve feedback' : 'Unresolve feedback',
        );
        const pageClone = pages ? { ...pages[pageID], id: pageID } : null;
        resolvePageFeedback(circlePointerID, pageClone, iframe);
        onClose();
      }
    }
  };
  const sendFeedback = async (
    message: any,
    originalImg: any,
    checkImage: boolean,
    pageIDValue: string,
    checkFile = false,
    fileName = '',
    audioUrl: string,
    videoFirebaseUrl?: string,
    firebasePublicUserAuthToken?: string,
    blobURL?: string,
  ) => {
    let firebaseAuthUserToken = firebaseAuthToken ? firebaseAuthToken : firebasePublicUserAuthToken;
    const fiebaseInstance: any = firebase;
    firebaseAuthUserToken = firebaseAuthUserToken
      ? firebaseAuthUserToken
      : await fiebaseInstance.auth().currentUser.getIdToken(true);
    !firebaseAuthUserToken && updateFirebaseAuthToken(firebaseAuthUserToken || '');
    clearShowingMessageid();
    setAllowScrollToBootom(true);
    const userDetails: any = localStorage.getItem('userDetails');
    let attachment = '';
    let attachedFileName = '';
    let text = message;
    let thumbnail = '';
    let audioUrlData = '';
    let videoUrl = '';
    if (checkImage) {
      thumbnail = message;
      text = '';
    }
    if (videoFirebaseUrl) {
      videoUrl = videoFirebaseUrl;
    }
    if (audioUrl) {
      audioUrlData = audioUrl;
      text = '';
    }
    if (checkFile) {
      attachment = message;
      attachedFileName = fileName;
    }
    const data = {
      thumbnail,
      originalImage: originalImg,
      feedback: text,
      email: '',
      sender: '',
      time: moment().utc(),
      uid: '',
      attachment,
      attachedFileName,
      uuid: '',
      audioUrl: audioUrlData,
      userAgent: window.navigator.userAgent,
      videoUrl,
      blobURL: blobURL ? blobURL : '',
    };

    let uniqueUuid: any;
    uniqueUuid = uuid();
    if (inProgress.length > 0) {
      const firstElement: any = inProgress.shift();
      uniqueUuid = firstElement.uuid;
    }
    if (auth.isEmpty && userDetails) {
      // user not Login but userdata is saved
      const userData = JSON.parse(userDetails);
      data.sender = userData.name;
      data.uid = userData.uid;
      data.email = userData.email;
    } else if (auth.uid) {
      setUserID(auth.uid);
      data.sender = firstName + ' ' + lastName;
      data.uid = auth.uid;
    }
    data.uuid = uniqueUuid;

    const firestore = firebase.firestore();
    firestore
      .collection('projects')
      .where(firebase.firestore.FieldPath.documentId(), '==', projectID)
      .get()
      .then((projectDetails: any) => {
        if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
          const projectDetailData = projectDetails.docs[0].data();
          const userPushNotificationTokens =
            projectDetailData && projectDetailData.userPushNotificationTokens
              ? projectDetailData.userPushNotificationTokens
              : [];
          let receiversToken = userPushNotificationTokens.map((userToken: any) => userToken.token);

          const firebaseToken = window.localStorage.getItem('firebaseToken');
          if (firebaseToken) {
            receiversToken = receiversToken.filter((item: string) => item !== firebaseToken);
          }

          const firebaseNotificationAuthKey = process.env.REACT_APP_FIREBASE_NOTIFICATION_AUTH_KEY;
          const firebaseNotificationURL: any = process.env.REACT_APP_FIREBASE_NOTIFICATION_URL;
          const urlParams = new URLSearchParams(window.location.search);
          const recalc = urlParams.get('recalc');
          let url = window.location.href;
          const notificationId = uuid();
          if (url.includes('public-chat')) {
            url = url.replace('public-chat', `project/${projectID}`);
          }
          const projectURL = `${url}${
            url.includes('?') ? '&' : '?'
          }circleId=${circlePointerID}&commentId=${pageIDValue}&notificationId=${notificationId}`;

          let feedbackMessage: string = '';
          if (videoFirebaseUrl) {
            feedbackMessage =
              isRecordModal === 'video'
                ? `${data.sender} sent video comment!`
                : `${data.sender} sent screen recording file!`;
          } else if (audioUrl) {
            feedbackMessage = `${data.sender} sent a voice clip!`;
          } else if (checkFile || originalImg) {
            feedbackMessage = `${data.sender} sent a file!`;
          } else {
            feedbackMessage = `${data.sender} sent a ${message} message`;
          }

          feedbackMessage &&
            firebaseAuthUserToken &&
            sendMessageToSlack(
              firebaseAuthUserToken,
              `${feedbackMessage}`,
              projectID,
              projectURL,
              initials,
              notificationId,
            );

          if (receiversToken && receiversToken.length) {
            fetch(firebaseNotificationURL, {
              method: 'POST',
              headers: new Headers({
                'content-Type': 'application/json',
                Authorization: `key=${firebaseNotificationAuthKey}`,
              }),

              body: JSON.stringify({
                data: {
                  message: feedbackMessage,
                  title: 'Feedback Hero',
                  body: 'Click here to see all the feedback points.',
                  data: {
                    url: `${window.location.origin}/project/${projectID}${
                      recalc ? '?recalc=true' : ''
                    }`,
                  },
                  click_action: `${window.location.origin}/project/${projectID}${
                    recalc ? '?recalc=true' : ''
                  }`,
                  page_id: pageIDValue,
                  circle_id: circlePointerID,
                  icon: `${window.location.origin}/icon.png`,
                  page_path: getQueryStringValue('page'),
                  url: `${window.location.origin}/project/${projectID}${
                    recalc ? '?recalc=true' : ''
                  }`,
                },
                registration_ids: receiversToken,
              }),
            });
          }
        }
      });
    await addPageFeedback(
      data,
      {
        page_id: pageIDValue,
        circle_id: circlePointerID,
        project_id: projectID,
      },
      pageIDValue,
    );
    if (checkImage) {
      statsAction('IMAGE_COMMENT_SENT', firestore);
    } else {
      statsAction('TEXT_COMMENT_SENT', firestore);
    }
    setValue('');
  };

  const setImagePreview = (image: string) => {
    setImage(image);
    setPreview(true);
  };

  const getImageBlob = (data: any) => {
    const items = data && (data.clipboardData || data.originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
      }
    }
    if (blob !== null) {
      setSendingImage(true);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        if (isDataURL(e.target.result)) {
          updateFeedbackFileSelect(1);
          const originalImg: any = await sendImageToStore(e.target.result);
          createThumbNail(e.target.result, originalImg).then((thumbnail) => {
            sendFeedback(thumbnail, originalImg, true, pageID, false, '', '');
          });
        } else {
          setValue(e.target.result);
        }
      };
      reader.readAsDataURL(blob);
    }
  };

  const setRefForImage = () => {
    if (!!scrollerRef.current && !currentShowingMessageid) {
      scrollerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };
  const sendImageToStore = (dataUrl: string) => {
    const fileName = 'file' + new Date().getTime();
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    return storageRef.putString(dataUrl, 'data_url').then((imageData: any) => {
      return imageData.ref.getDownloadURL().then((url: string) => {
        return url;
      });
    });
  };

  const sendVideoToStore = (blob: any) => {
    setSendingImage(true);

    const fileName = 'file' + new Date().getTime();
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    return storageRef.put(blob).then((videoData: any) => {
      return videoData.ref.getDownloadURL().then((url: string) => {
        return url;
      });
    });
  };

  const createThumbNail = (data: string, originalImageUrl: string) =>
    new Promise((resolve) => {
      const img = new Image();
      img.src = data;
      img.onload = async () => {
        if (img.height > 200 || img.width > 200) {
          const elem = document.createElement('canvas');
          let maxWidth = 200;
          let maxHeight = 200;
          if (img.height > img.width) {
            maxHeight = 200;
            maxWidth = (img.width / img.height) * maxHeight;

            elem.height = maxHeight;
            elem.width = maxWidth;
          } else {
            maxWidth = 200;
            maxHeight = (img.height / img.width) * maxWidth;

            elem.height = maxHeight;
            elem.width = maxWidth;
          }
          const ctx: any = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, maxWidth, maxHeight);
          const dataUrl = elem.toDataURL();
          const thumbnail: any = await sendImageToStore(dataUrl);
          resolve(thumbnail);
        } else {
          resolve(originalImageUrl);
        }
      };
    });

  const getClassName = () => {
    if (direction === 'left') {
      if (!auth.isEmpty) {
        return 'feedback-closer';
      } else {
        return 'feedback-close-left';
      }
    } else {
      return 'feedback-closer-right';
    }
  };
  const hideshowInfoModal = () => {
    showDetailModal(false);
  };

  const callback = (response: any) => {
    if (response.cancel) {
      setSendingImage(false);
    } else {
      const fileName = response ? response.fileName : null;
      if (!fileName) {
        const thumbnailImg = response.thumbnail;
        const originalImage = response.originalImg;
        sendFeedback(thumbnailImg, originalImage, true, pageID, false, '', '');
      } else {
        const originalFile = response.originalFile;
        sendFeedback(originalFile, '', false, pageID, true, fileName, '');
      }
    }
  };
  const scrollToClickedComment = () => {
    if (currentShowingMessageid && chatContainerRef) {
      const element = itemRefs[currentShowingMessageid];
      const topOffset = 200;
      const elementPosition =
        element && element.getBoundingClientRect() && element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - topOffset;
      chatContainerRef.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    } else {
      if (scrollerRef.current !== null && chatContainerRef) {
        return chatContainerRef.scrollTo({
          top: chatContainerRef.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  };

  // const onStop = (recordedBlob: any) => {
  //   setAudioRecorded(recordedBlob);
  // };

  const preload = (status: boolean) => {
    setSendingImage(status);
  };

  const fileHandler = async (fileObj: any) => {
    await UploadFileLogic(fileObj, callback, preload, profile, auth, organization);
  };

  const fileChangeHandler = (e: any) => {
    const fileObj = e.target.files;
    updateFeedbackFileSelect(fileObj.length);
    fileHandler(fileObj);
  };

  const openCommentModal = (userAgent: any) => {
    setShowCommentInfoModal(true);
    setDataForCommentInfo(userAgent);
  };

  const triggerInputFile = () => inputFileRef.current.click();

  const handleMouseEnter = (key: any) => {
    setActiveKey(key);
  };

  const handleMouseLeave = (key: any) => {
    setActiveKey(key);
  };

  const generateFeed = (type = '') => {
    const userDetails = localStorage.getItem('userDetails');
    const data = {
      attachedFileName: '',
      attachment: '',
      feedback: 'Sending File...',
      originalImage: 'loading',
      sender: 'loading',
      thumbnail: '/images/small-loader.gif',
      time: '2020-02-06T12:25:28.795Z',
      uid: userID,
      uuid: uuid(),
      type,
    };
    if (userDetails && auth.isEmpty) {
      const userData = JSON.parse(userDetails);
      data.sender = userData.name;
    }
    return data;
  };

  const fileUploadProgress = async (count: number, allFeedbackList: IFeedback[], type?: string) => {
    const uploading: any[] = [];
    Array.from(
      {
        length: count,
      },
      () => {
        uploading.push(generateFeed(type));
      },
    );
    inProgress = uploading;
    const mergedFeedbacks = [...allFeedbackList, ...uploading];
    setFeedbackList(mergedFeedbacks);
  };

  const updateFeedback = async (count: number) => {
    await fileUploadProgress(count, feedbacks);
    scrollToClickedComment();
  };

  const updateFeedbackFileSelect = async (count: number, type?: string) => {
    await fileUploadProgress(count, feedbackList, type);
    scrollToClickedComment();
  };

  const copyUrl = () => {
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, `copied URL`);
    const textArea = document.createElement('textarea');
    textArea.value = urlRefs.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
    setCopied(true);
  };

  const deleteFromFirebaseStorage = (firebaseStorageURL: string) => {
    firebase
      .storage()
      .refFromURL(firebaseStorageURL)
      .delete();
  };

  const deleteMedia = (selectedMessage: {
    videoUrl?: string;
    audioUrl?: string;
    thumbnail?: string;
    originalImage?: string;
  }) => {
    const { videoUrl = '', audioUrl = '', thumbnail = '', originalImage = '' } = selectedMessage;
    if (videoUrl) {
      videoUrl.includes('https://firebasestorage.googleapis.com') &&
        deleteFromFirebaseStorage(videoUrl);
      videoUrl.includes('embed.api.video') && deleteFromVideoStorage(firebaseAuthToken, videoUrl);
    } else if (audioUrl) {
      deleteFromFirebaseStorage(audioUrl);
    } else if (thumbnail) {
      deleteFromFirebaseStorage(thumbnail);
      deleteFromFirebaseStorage(originalImage);
    }
  };

  const deleteChatMessage = (key: number) => {
    Modal.confirm({
      content: '',
      title: 'Do you want to delete this message?',
      onOk: async () => {
        trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Delete message clicked');
        const firestore = firebase.firestore();
        let authorId = localStorage.getItem('userDetails');
        authorId = authorId && JSON.parse(authorId).uid;
        const allFeedbacks = JSON.parse(JSON.stringify(pages[pageID].feedback));
        let selectIndex = -1;
        allFeedbacks.map((data: any, index: number) => {
          if (data.circle_pointer.id === circlePointerID) {
            selectIndex = index;
          }
        });
        let selectedMessage = allFeedbacks[selectIndex].comment[key];
        deleteMedia(selectedMessage);
        selectedMessage = {
          ...selectedMessage,
          messageDeleted: true,
        };
        allFeedbacks[selectIndex].comment[key] = selectedMessage;

        await firestore
          .collection('pages')
          .doc(pageID)
          .set(
            {
              feedback: allFeedbacks,
              annotations: pages[pageID].annotations,
            },
            { merge: true },
          );
      },
    });
  };

  const onAudioSubmit = async (firebaseUrl: any) => {
    setSendingImage(true);
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send recording clicked', {
      projectId: projectID,
    });
    setIsRecordingModal(false);
    // updateFeedbackFileSelect(1);
    await sendFeedback('', '', false, pageID, false, '', firebaseUrl);
    setSendingImage(false);
  };

  const deleteFeedback = async () => {
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Deleted a message', {
      projectId: projectID,
    });
    const allFeedbacks = (pages[pageID] && pages[pageID].feedback) || [];
    let circleIndexToBeDeleted = -1;
    allFeedbacks.map((data: any, index: number) => {
      if (data.circle_pointer.id === circlePointerID) {
        circleIndexToBeDeleted = index;
      }
    });
    const allFeedbackTemp = JSON.parse(JSON.stringify(allFeedbacks));
    allFeedbackTemp.splice(circleIndexToBeDeleted, 1);
    const firestore = firebase.firestore();
    await firestore
      .collection('pages')
      .doc(pageID)
      .set(
        {
          feedback: allFeedbackTemp,
          annotations: pages[pageID].annotations,
        },
        { merge: true },
      );
    sendMessageToIframe(iframe, 'DELETE_FEEDBACK', { key: circlePointerID });
    trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Deleted a feedback', {
      projectId: projectID,
    });
    onClose();
  };

  const locationUrl = window.location.href;
  const feedbackURL = `${locationUrl}${
    locationUrl.includes('?') ? '&' : '?'
  }circleId=${circlePointerID}`;

  const urlify = (text: string, isyou: boolean) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    );
    const textArray = text.split(' ').map((word: string) => {
      if (!!pattern.test(word)) {
        return `<a
                  style="color: ${isyou ? '' : '#FFEB3B'}; text-decoration: underline"
                  href="${word.includes('http') ? word : '//' + word}" target="_blank">${word}</a>`;
      }
      return `<span style="padding: 0px">${word}</span>`;
    });
    return textArray.join(' ');
  };

  return (
    <React.Fragment>
      <div className={isMobile ? 'chat-header-mobile' : ''}>
        {!isPublicChatWindow && (
          <Tooltip placement='top' title={'Close chat window'}>
            <Button
              size='small'
              type='primary'
              icon='cross'
              // @ts-ignore
              // onClick={feedbackStateToggler.bind(null, 1, false)}
              onClick={() => {
                onClose();
                trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Close button clicked', {
                  projectId: projectID,
                });
              }}
              shape='circle'
              className={isMobile ? 'chat-header-mobile-content' : getClassName()}
            />
          </Tooltip>
        )}

        {!isPublicChatWindow && !auth.isEmpty && !isChatWindow && (
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item disabled={!isWriteAccess} onClick={handleResolve}>
                  {!resolved ? 'Resolve Feedback' : 'Unresolve Feedback'}
                </Menu.Item>
                <Menu.Item
                  disabled={!isWriteAccess}
                  onClick={() => {
                    if (isWriteAccess) {
                      if (auth.isEmpty) {
                        // open modal to get public user details
                        showDetailModal(true);
                      } else {
                        Modal.confirm({
                          content: '',
                          okText: 'Yes',
                          cancelText: 'No',
                          title: 'Do you want to delete this feedback?',
                          onOk: deleteFeedback,
                        });
                      }
                    }
                  }}
                >
                  Delete feedback
                </Menu.Item>
                <Menu.Item
                  disabled={!isWriteAccess}
                  onClick={() => {
                    isWriteAccess && copyUrl();
                  }}
                >
                  <span
                    className='comment-url'
                    ref={(el) => {
                      urlRefs = el;
                    }}
                  >
                    {feedbackURL}
                  </span>
                  {`${copied ? 'Copied' : 'Copy'}`} Feedback URL
                </Menu.Item>
              </Menu>
            )}
            placement={direction === 'left' ? 'bottomRight' : 'bottomLeft'}
          >
            <Button
              size='small'
              type='primary'
              icon='down'
              style={{ backgroundColor: '#444F60', borderColor: '#444F60' }}
              onClick={() => setIsDropDown(true)}
              shape='circle'
              className={
                isMobile
                  ? 'chat-header-mobile-content-dropdown'
                  : direction === 'left'
                  ? 'feedback-resolve'
                  : 'feedback-resolve-right'
              }
            />
          </Dropdown>
        )}
      </div>

      {isDropDown && <div></div>}
      <div className='feedbacks'>
        <DragAndDropZone
          pageId={pageID}
          preload={preload}
          callback={callback}
          circlePointerID={circlePointerID}
          mockUpload={updateFeedback}
          profile={profile}
          auth={auth}
          organization={organization}
        >
          <ul
            ref={(el) => {
              chatContainerRef = el;
            }}
          >
            <li style={{ height: '30px' }} />
            {pages &&
              feedbackList.map((feedback: any, key) => {
                const date = moment(feedback.time).fromNow() || '';
                let dateFormat = 'MMM Do [at] h:mm:ss A z';
                if (moment(feedback.time).years() !== new Date().getFullYear()) {
                  dateFormat = 'MMM Do, YYYY [at] h:mm:ss A z';
                }
                const dateToolTip = moment(feedback.time).format(dateFormat) || '';

                let isShowBlobProcessedMessage = false;
                if (feedback.uid === userID && feedback.blobURL) {
                  const currentUTCTime = moment().utc();
                  const minutes = moment(currentUTCTime).diff(moment(feedback.time), 'minutes');
                  if (minutes < 1) {
                    isShowBlobProcessedMessage = true;
                  }
                }

                return (
                  <>
                    <li
                      key={key}
                      ref={(el) => {
                        itemRefs[feedback.uuid] = el;
                      }}
                      onMouseEnter={() => handleMouseEnter(key)}
                      onMouseLeave={() => handleMouseLeave(null)}
                    >
                      {feedback.originalImage === 'loading' && feedback.type !== 'video' ? (
                        <div className='feedback you'>
                          <div className='feedback-text'>
                            <span>Sending File</span>
                            <Icon type='loading' spin />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`feedback ${(feedback.videoUrl || feedback.type === 'video') &&
                            !feedback.messageDeleted &&
                            'processing-video-container'} ${
                            feedback.uid === userID
                              ? feedback.thumbnail || feedback.videoUrl || feedback.audioUrl
                                ? feedback.messageDeleted || feedback.type === 'video'
                                  ? 'you'
                                  : 'thumbnail-you'
                                : 'you'
                              : feedback.thumbnail || feedback.videoUrl || feedback.audioUrl
                              ? feedback.messageDeleted
                                ? 'him'
                                : 'thumbnail-him'
                              : 'him'
                          }
                      ${
                        feedback.audioUrl || feedback.videoUrl || feedback.audioUrl
                          ? feedback.messageDeleted
                            ? ''
                            : 'full-width'
                          : ''
                      }
                      ${feedback.uuid === currentShowingMessageid ? 'matched-comment' : ''}`}
                        >
                          <span className='partner'>
                            {feedback.uid !== userID && (
                              <span style={{ marginRight: '5px' }}>{feedback.sender}</span>
                            )}
                            {activeKey === key &&
                              feedback.uid === userID &&
                              !feedback.messageDeleted && (
                                <React.Fragment>
                                  <Tooltip placement='top' title={'Delete'}>
                                    <span
                                      className={
                                        !isWriteAccess
                                          ? 'info-circle-icon disabled'
                                          : 'info-circle-icon'
                                      }
                                      onClick={() => {
                                        isWriteAccess && deleteChatMessage(key);
                                      }}
                                    >
                                      <Icon type='delete' />
                                    </span>
                                  </Tooltip>
                                </React.Fragment>
                              )}
                            {activeKey === key && (
                              <React.Fragment>
                                <Tooltip placement='top' title={'Navigator Information'}>
                                  <span
                                    className='info-circle-icon'
                                    onClick={() => openCommentModal(feedback.userAgent)}
                                  >
                                    <Icon type='info-circle' />
                                  </span>
                                </Tooltip>
                              </React.Fragment>
                            )}
                            {feedback.uid === userID && (
                              <span style={{ marginLeft: '5px' }}>
                                {!isPublicChatWindow && feedback.sender}
                              </span>
                            )}
                          </span>
                          {feedback.messageDeleted ? (
                            <div style={{ fontStyle: 'italic', color: '#B0BEC5' }}>
                              <Icon type='stop' />
                              {feedback.uid === userID
                                ? ' You deleted this message'
                                : ' This message was deleted'}
                            </div>
                          ) : feedback.thumbnail && feedback.type !== 'video' ? (
                            <img
                              alt='preview'
                              className='feedback-image'
                              src={feedback.thumbnail}
                              onClick={() => setImagePreview(feedback.originalImage)}
                              onLoad={setRefForImage}
                              ref={imgRef}
                            />
                          ) : feedback.audioUrl ? (
                            <div
                              className={`${
                                feedback.uid === userID ? 'video-player-right' : 'video-player-left'
                              } video-player`}
                            >
                              <AudioPlayer id={key.toString()} url={feedback.audioUrl} />
                            </div>
                          ) : feedback.attachment ? (
                            <div style={{ maxWidth: '100%' }}>
                              {' '}
                              <Tooltip placement='top' title='Click to download'>
                                <a href={feedback.attachment} target='_blank'>
                                  {feedback.uid === userID ? (
                                    <Button className='file-uploaded-text' type='primary'>
                                      {feedback.attachedFileName}
                                    </Button>
                                  ) : (
                                    <Button className='file-uploaded-text'>
                                      {feedback.attachedFileName}
                                    </Button>
                                  )}
                                </a>
                              </Tooltip>
                            </div>
                          ) : feedback.videoUrl ? (
                            <div
                              className={`${
                                feedback.uid === userID ? 'video-player-right' : 'video-player-left'
                              } video-player`}
                            >
                              <RenderVideos
                                isMediaCreatedByLoginUser={feedback.uid === userID}
                                createdTime={feedback.time}
                                blobURL={feedback.blobURL}
                                videos={[feedback.videoUrl]}
                                iframeClass={`${
                                  feedback.uid === userID
                                    ? 'stream-video-player-right'
                                    : 'stream-video-player-left'
                                }`}
                                parentComponent='feedbackModal'
                              />
                            </div>
                          ) : !feedback.videoUrl && feedback.type === 'video' ? (
                            <div
                              className={`${
                                feedback.uid === userID ? 'video-player-right' : 'video-player-left'
                              } video-player`}
                            >
                              <div className='text-center'>
                                {feedback.videoStatus === 'DELETED'
                                  ? 'Something went wrong with this video, please try again.'
                                  : 'This video is being processed, please wait a few seconds.'}
                              </div>
                            </div>
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: urlify(feedback.feedback, feedback.uid === userID),
                              }}
                              className='feedback-text'
                            />
                          )}
                          {isShowBlobProcessedMessage && (
                            <div className='blob-video-processing'>
                              This video is being processed
                            </div>
                          )}
                          <Tooltip
                            placement={feedback.uid === userID ? 'bottomRight' : 'bottomLeft'}
                            title={dateToolTip}
                          >
                            <span className='time'>{date}</span>
                          </Tooltip>
                        </div>
                      )}
                    </li>
                  </>
                );
              })}
            <div ref={scrollerRef} />
          </ul>
        </DragAndDropZone>
      </div>
      <div className='sendBox'>
        <div className='icons'>
          {!auth.isEmpty && !isRecordingModal && (
            <Tooltip placement='topLeft' title='Upload File'>
              <Icon
                type='upload'
                className={!isWriteAccess ? 'disabled' : ''}
                onClick={() => {
                  if (isWriteAccess) {
                    if (auth.isEmpty) {
                      // open modal to get public user details
                      showDetailModal(true);
                    } else {
                      triggerInputFile();
                      trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Upload file clicked', {
                        projectId: projectID,
                      });
                    }
                  }
                }}
              />
              <input
                type='file'
                ref={inputFileRef}
                className='hideElement'
                onChange={(e) => {
                  isWriteAccess && fileChangeHandler(e);
                }}
                multiple={true}
                disabled={!isWriteAccess}
              />
            </Tooltip>
          )}
          {!auth.isEmpty && !isRecordingModal && (
            <Tooltip placement='top' title={'Send Audio'}>
              <Icon
                onClick={() => {
                  if (isWriteAccess) {
                    if (auth.isEmpty) {
                      // open modal to get public user details
                      showDetailModal(true);
                    } else {
                      setIsRecordingModal(true);
                      trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send audio clicked', {
                        projectId: projectID,
                      });
                    }
                  }
                }}
                className={!isWriteAccess ? 'disabled' : ''}
                type='audio'
              />
            </Tooltip>
          )}
          {!auth.isEmpty && !isRecordingModal && (
            <Tooltip placement='top' title={'Send Video'}>
              <Icon
                onClick={() => {
                  if (isWriteAccess) {
                    if (auth.isEmpty) {
                      // open modal to get public user details
                      showDetailModal(true);
                    } else {
                      trackAnalytics(`${trackAnalyticsPrefix}Chat window`, 'Send video clicked', {
                        projectId: projectID,
                      });
                      setIsRecordModal('video');
                    }
                  }
                }}
                className={!isWriteAccess ? 'disabled' : ''}
                type='video-camera'
              />
            </Tooltip>
          )}
          {!auth.isEmpty && !isRecordingModal && !isMobile && (
            <Tooltip placement='top' title={'Send Screen Recording'}>
              <Icon
                onClick={() => {
                  if (isWriteAccess) {
                    if (auth.isEmpty) {
                      // open modal to get public user details
                      showDetailModal(true);
                    } else {
                      setIsRecordModal('screen');
                      trackAnalytics(
                        `${trackAnalyticsPrefix}Chat window`,
                        'Send screen recording clicked',
                        {
                          projectId: projectID,
                        },
                      );
                    }
                  }
                }}
                className={!isWriteAccess ? 'disabled' : ''}
                type='desktop'
              />
            </Tooltip>
          )}

          {!isPublicChatWindow && !auth.isEmpty && !isRecordingModal && !isMobile && (
            <Tooltip placement='top' title={'Send Files from Extension'}>
              <Icon
                onClick={() => {
                  if (isWriteAccess) {
                    if (auth.isEmpty) {
                      // open modal to get public user details
                      showDetailModal(true);
                    } else {
                      setIsUploadModal(true);
                      trackAnalytics(
                        `${trackAnalyticsPrefix}Chat window`,
                        'Send Files from Extension clicked',
                        {
                          projectId: projectID,
                        },
                      );
                    }
                  }
                }}
                className={!isWriteAccess ? 'disabled' : ''}
                type='paper-clip'
              />
            </Tooltip>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <input
            placeholder={sendingImage ? 'Type your feedback' : 'Type your feedback'}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onPaste={(e) => getImageBlob(e)}
            onKeyDown={keyDownHandler}
            autoFocus={isPublicChatWindow ? false : true}
          />
          <div
            className='send-feedback-button'
            onClick={value !== '' ? sendFeedbackFunc : () => null}
          >
            <Icon
              style={{
                fontSize: 25,
                color: value !== '' ? '#039BE5' : '#B0BEC5',
                cursor: value !== '' ? 'pointer' : 'not-allowed',
              }}
              type='right-circle'
              theme='filled'
            />
          </div>
        </div>
      </div>
      <div style={{ overflow: 'hidden', height: '100%' }}>
        <div
          className={`fhasaAudio-modal ${isRecordingModal ? 'fhasaShow-modal' : 'fhasaHide-modal'}`}
        >
          <RecordAudio
            maxRecordTime={recordVoiceClipTime}
            onClose={setIsRecordingModal}
            projectID={projectID}
            // setLoading={setSendingImage}
            onSubmit={onAudioSubmit}
            firebaseAuthToken={firebaseAuthToken}
            setLoading={() => updateFeedbackFileSelect(1)}
            organization={organization}
          />
        </div>
      </div>

      {/* <Modal
        title='Record a audio comment'
        visible={isRecordingModal}
        footer={false}
        width={800}
        bodyStyle={{ height: 400, display: 'flex', justifyContent: 'center' }}
        onCancel={() => setIsRecordingModal(false)}
      >
        <div style={{ width: 450 }}>
          <RecordAudioFile
            large
            maxRecordTime={recordVoiceClipTime}
            projectID={projectID}
            setLoading={setSendingImage}
            onClose={setIsRecordingModal}
            onSubmit={onAudioSubmit}
          />
        </div>
      </Modal> */}

      {showPreview && (
        <ImagePreview
          selectedImage={selectedImage}
          showPreview={showPreview}
          setPreview={() => setPreview(false)}
        />
      )}
      <InfoModal
        sendMessage={sendMessage}
        showInfoModal={detailModal}
        projectId={projectID}
        hideshowInfoModal={hideshowInfoModal}
      />
      <UploadModal
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        sendFeedback={sendFeedback}
        pageID={pageID}
        userID={userID}
      />
      <CommentInfoModal
        setPreview={() => setShowCommentInfoModal(false)}
        showPreview={showCommentInfoModal}
        data={dataForCommentInfo}
      />
      {isRecordModal && (
        <RecordVideoModal
          updateFeedback={updateFeedbackFileSelect}
          isRecordModal={isRecordModal}
          setIsRecordModal={setIsRecordModal}
          sendFeedback={sendFeedback}
          pageID={pageID}
          sendVideoToStore={sendVideoToStore}
          setSendingImage={setSendingImage}
          userQuota={userQuota}
          isPublicChatWindow={isPublicChatWindow}
          firebaseAuthToken={firebaseAuthToken}
          organization={organization}
        />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    pages: state.firestore.data.pages,
    iframe: state.project.iframe,
    firebaseAuthToken: state.auth.firebaseAuthToken,
    organization: state.organization,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFirebaseAuthToken: (firebaseAuthToken: string) =>
      dispatch(updateFirebaseAuthTokenAction(firebaseAuthToken)),
    addPageFeedback: (pageComment: any, data: any, pageId: string) =>
      dispatch(addPageComment(pageComment, data, pageId)),
    resolvePageFeedback: (circleId: string, pages: any, iframe: any) =>
      dispatch(resolvePage(circleId, pages, iframe)),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  firestoreConnect((props: any) => {
    return [
      {
        collection: 'pages',
        where: ['project_id', '==', props.projectID],
      },
    ];
  }),
)(FeedbackModel);

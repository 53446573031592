/* tslint:disable */
import {
  Button,
  // Icon,
  notification,
  Progress,
  // Menu
} from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import React, { useRef, useState, useEffect } from 'react';
import { secondsToHms } from '../../utils/index';
// import usePreciseTimer from '../projects/Timer';
// import { RecordingTimer } from '../shared/RecordingTimer';
import './audioRecord.css';
import PlayIcon from './images/play-icon.png';
import MediaPermissionModal from './MediaPermissionModal';

let drawVisual: any = null;
let stop: any = null;
const RecordAudioFile = (props: any) => {
  const [isOpen, setIsOpen ] = useState(false);
  const isAllowPermissionRef = useRef(false);
  const [blobObject, setBlobObject]: any = useState({});
  const [timer, setTimer] = useState(0);
  const [isRecording, setRecording] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [micModal, setMicModal] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  // const [micOptions, setMicOptions]: any = useState([]);

  const {
    maxRecordTime,
    showPercentage,
    onSubmitWithPercentage,
    onClose,
    onSubmit,
    background,
  } = props;
  const audioPlayBackTimer: any = useRef(null);
  React.useEffect(() => {
    const audio: any = document.getElementById('audio-player');
    if (audio) {
      playing ? audio.play() : audio.pause();
    }
  }, [playing]);
  React.useEffect(() => {
    if (timer >= maxRecordTime * 60) {
      setRecording(false);
      setTimer(0);
      stop && stop();
    }
  }, [timer]);
  React.useEffect(() => {
    (async () => {
      const leftchannel: any = [];
      const rightchannel: any = [];
      let recorder: any = null;
      let recording: any = false;
      let recordingLength: any = 0;
      // let volume: any = null;
      let audioInput: any = null;
      let sampleRate: any = null;
      // @ts-ignore
      const AudioContext: any = window.AudioContext || window.webkitAudioContext;
      let context: any = null;
      let analyser: any = null;
      const canvas: any = document.getElementById('canvas-audio-recorder');
      const canvasCtx: any = canvas.getContext('2d');
      const visualSelect: any = document.querySelector('#visSelect');
      const micSelect: any = document.querySelector('#micSelect');
      let stream: any = null;
      let tested: any = false;

      try {
        // @ts-ignore
        window.stream = stream = await getStream();
        isAllowPermissionRef.current = true;
        // ('Got stream');
      } catch (err) {
        console.log('Issue getting mic' + err);
        isAllowPermissionRef.current = false;
        return false;
      }

      const deviceInfos = await navigator.mediaDevices.enumerateDevices();
      const mics = [];
      // let micOptionsArray = [];
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === 'audioinput') {
          mics.push(deviceInfo);
          const label = deviceInfo.label || 'Microphone ' + mics.length;
          const option = document.createElement('option');
          option.value = deviceInfo.deviceId;
          option.text = label;
          micSelect.appendChild(option);
          // micOptionsArray.push({ value: deviceInfo.deviceId, text: label });
        }
      }
      // setMicOptions(micOptionsArray);

      function getStream(constraints: any) {
        if (!constraints) {
          constraints = { audio: true, video: false };
        }
        return navigator.mediaDevices.getUserMedia(constraints);
      }

      setUpRecording();

      function setUpRecording() {
        context = new AudioContext();
        sampleRate = context.sampleRate;

        // creates a gain node
        // volume = context.createGain();
        // ('volume', volume)
        // creates an audio node from teh microphone incoming stream
        audioInput = context.createMediaStreamSource(stream);

        // Create analyser
        analyser = context.createAnalyser();

        // connect audio input to the analyser
        audioInput.connect(analyser);

        // connect analyser to the volume control
        // analyser.connect(volume);

        const bufferSize = 2048;
        recorder = context.createScriptProcessor(bufferSize, 2, 2);

        // we connect the volume control to the processor
        // volume.connect(recorder);

        analyser.connect(recorder);

        // finally connect the processor to the output
        recorder.connect(context.destination);

        recorder.onaudioprocess = function(e: any) {
          // Check
          if (!recording) {
            return;
          }
          // Do something with the data, i.e Convert this to WAV
          // ('recording');
          const left = e.inputBuffer.getChannelData(0);
          const right = e.inputBuffer.getChannelData(1);
          if (!tested) {
            tested = true;
            // if this reduces to 0 we are not getting any sound
            if (!left.reduce((a: any, b: any) => a + b)) {
              alert('There seems to be an issue with your Mic');
              // clean up;
              stop();
              stream.getTracks().forEach(function(track: any) {
                track.stop();
              });
              context.close();
            }
          }
          // we clone the samples
          leftchannel.push(new Float32Array(left));
          rightchannel.push(new Float32Array(right));
          recordingLength += bufferSize;
        };
        visualize();
      }

      function mergeBuffers(channelBuffer: any, recordingLength: any) {
        const result = new Float32Array(recordingLength);
        let offset = 0;
        const lng = channelBuffer.length;
        for (let i = 0; i < lng; i++) {
          const buffer = channelBuffer[i];
          result.set(buffer, offset);
          offset += buffer.length;
        }
        return result;
      }

      function interleave(leftChannel: any, rightChannel: any) {
        const length = leftChannel.length + rightChannel.length;
        const result = new Float32Array(length);

        let inputIndex = 0;

        for (let index = 0; index < length; ) {
          result[index++] = leftChannel[inputIndex];
          result[index++] = rightChannel[inputIndex];
          inputIndex++;
        }
        return result;
      }

      function writeUTFBytes(view: any, offset: any, string: any) {
        const lng = string.length;
        for (let i = 0; i < lng; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      }

      function start() {
        recording = true;
        // @ts-ignore
        // document.querySelector('#msg').style.visibility = 'visible'
        // reset the buffers for the new recording
        leftchannel.length = rightchannel.length = 0;
        recordingLength = 0;
        // ('context: ', !!context);
        if (!context) {
          setUpRecording();
        }
      }

      const stop = () => {
        // ('Stop')
        recording = false;
        // @ts-ignore
        // document.querySelector('#msg').style.visibility = 'hidden'

        // we flat the left and right channels down
        const leftBuffer = mergeBuffers(leftchannel, recordingLength);
        const rightBuffer = mergeBuffers(rightchannel, recordingLength);
        // we interleave both channels together
        const interleaved = interleave(leftBuffer, rightBuffer);

        ///////////// WAV Encode /////////////////
        // from http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
        //

        // we create our wav file
        const buffer = new ArrayBuffer(44 + interleaved.length * 2);
        const view = new DataView(buffer);

        // RIFF chunk descriptor
        writeUTFBytes(view, 0, 'RIFF');
        view.setUint32(4, 44 + interleaved.length * 2, true);
        writeUTFBytes(view, 8, 'WAVE');
        // FMT sub-chunk
        writeUTFBytes(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        // stereo (2 channels)
        view.setUint16(22, 2, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, sampleRate * 4, true);
        view.setUint16(32, 4, true);
        view.setUint16(34, 16, true);
        // data sub-chunk
        writeUTFBytes(view, 36, 'data');
        view.setUint32(40, interleaved.length * 2, true);

        // write the PCM samples
        const lng = interleaved.length;
        let index = 44;
        const volume = 1;
        for (let i = 0; i < lng; i++) {
          view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
          index += 2;
        }

        // our final binary blob
        const blob = new Blob([view], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(blob);
        setBlobObject({ blob, audioUrl });
        // @ts-ignore
        document.querySelector('#audio-player').setAttribute('src', audioUrl);
        // const link: any = document.querySelector('#download');
        // link.setAttribute('href', audioUrl);
        // link.download = 'output.wav';

        const stopButton: any = document.getElementById('stop');
        stopButton.style.display = 'none';
        const canvas: any = document.getElementById('canvas-audio-recorder');
        canvas.style.display = 'none';
        const audioTag: any = document.getElementById('audio-player-container');
        audioTag.style.display = 'inline-block';
        setRecording(false);
         // @ts-ignore
         window && window.stream && window.stream.getTracks()[0].stop();
      };

      // Visualizer function from
      // https://webaudiodemos.appspot.com/AudioRecorder/index.html
      //
      function visualize() {
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        const visualSetting = visualSelect.value;
        if (!analyser) {
          return;
        }

        if (visualSetting === 'sinewave') {
          analyser.fftSize = 2048;
          const bufferLength = analyser.fftSize;
          const dataArray = new Uint8Array(bufferLength);

          canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

          const draw = function() {
            drawVisual = requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            canvasCtx.fillStyle = background ? background : '#C4C4C4';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = '#001DAD';

            canvasCtx.beginPath();

            const sliceWidth = (WIDTH * 1.0) / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0;
              const y = (v * HEIGHT) / 2;

              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }

              x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
          };

          draw();
        }
      }

      visualSelect.onchange = function() {
        window.cancelAnimationFrame(drawVisual);
        visualize();
      };

      micSelect.onchange = async () => {
        // ('now use device ', micSelect.value, e);
        stream.getTracks().forEach(function(track: any) {
          track.stop();
        });
        context.close();
        try{
        stream = await getStream({
          audio: {
            deviceId: { exact: micSelect.value },
          },
          video: false,
        });
         // @ts-ignore
         window.stream = stream;
         isAllowPermissionRef.current = true;
        } catch (error) {
          isAllowPermissionRef.current = false;
          console.error("error in audio permission:", error);
          return false;
        }
        setUpRecording();
      };

      function pause() {
        recording = false;
        // context.suspend()
      }

      function resume() {
        recording = true;
        // context.resume();
      }

      // @ts-ignore
      document.querySelector('#pauseAudioRecord').onclick = () => {
        pause();
        setIsPaused(true);
      };

      // @ts-ignore
      document.querySelector('#resumeAudioRecord').onclick = () => {
        resume();
        setIsPaused(false);
      };
      // ('remove this after used', pause, resume)
      // @ts-ignore
      document.querySelector('#record').onclick = (e) => {
        // ('Start recording')
        const playButton: any = document.getElementById('record');
        playButton.style.display = 'none';
        const stopButton: any = document.getElementById('stop');
        stopButton.style.display = 'inline-block';
        const settingsButton: any = document.getElementById('mySidenav');
        settingsButton.style.display = 'none';

        start();
        setRecording(true);
      };
      // @ts-ignore
      document.querySelector('#stop').onclick = (e) => {
        stop();
        isPaused && setIsPaused(false);
      };
    })();
    const audio: any = document.getElementById('audio-player');

    audio.onended = () => {
      setPlaying(false);
    };

    audio.addEventListener(
      'timeupdate',
      () => {
        if (audioPlayBackTimer.current) {
          audioPlayBackTimer.current.innerHTML = secondsToHms(audio.currentTime);
        }
      },
      false,
    );
    return () => {
      // @ts-ignore
      window && window.stream && window.stream.getTracks().forEach(function(track: any) {
        track.stop();
      });
    }
  }, []);

  const sendAudioToStore = async () => {
    setLoading(true);
    props.setLoading && props.setLoading(true);
    const { blob } = blobObject;
    const fileName = 'file' + new Date().getTime();
    const storageRef = firebase
      .storage()
      .ref()
      .child(fileName);
    if (showPercentage) {
      const firebaseUploadObject: any = storageRef.put(blob);
      firebaseUploadObject.on(
        'state_changed',
        (snapshot: any) => {
          const progress: any = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPercentage(progress.toFixed(2));
        },
        () => {
          notification.error({
            description: '',
            message: 'upload cancelled!',
          });
          setLoading(false);
          props.setLoading && props.setLoading(false);
          clearAudio();
        },
        () => {
          return firebaseUploadObject.snapshot.ref
            .getDownloadURL()
            .then(async (downloadURL: any) => {
              setLoading(false);
              props.setLoading && props.setLoading(false);
              // clearAudio();
              const audioURL = downloadURL;
              onSubmitWithPercentage(audioURL);
            });
        },
      );
    } else {
      return storageRef.put(blob).then((audioData: any) => {
        return audioData.ref.getDownloadURL().then((url: string) => {
          setLoading(false);
          props.setLoading && props.setLoading(false);
          clearAudio();
          return url;
        });
      });
    }
  };

  const clearAudio = () => {
    setBlobObject({});
    setPlaying(false);
    const audio: any = document.getElementById('audio-player');
    if (audio) {
      audio.src = null;
    }
    const playButton: any = document.getElementById('record');
    if (playButton) {
      playButton.style.display = 'inline-block';
    }
    const canvas: any = document.getElementById('canvas-audio-recorder');
    if (canvas) {
      canvas.style.display = 'block';
    }
    const audioTag: any = document.getElementById('audio-player-container');
    if (audioTag) {
      audioTag.style.display = 'none';
    }
    const settingsButton: any = document.getElementById('mySidenav');
    if (settingsButton) {
      settingsButton.style.display = 'block';
    }
    setTimer(0);
  };

  const TimerComp = () => {
    useEffect(() => {
      const timerId = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
      return () => {
        clearInterval(timerId);
      };
    }, []);
    return secondsToHms(maxRecordTime * 60 - timer);
  };
  return (
    <div className='app-audio-recorder'>
      <MediaPermissionModal isOpen={isOpen} setIsOpen={setIsOpen}/>
      <select className={'select-audio-recorder'} id='visSelect'>
        <option value='sinewave'>Wave</option>
      </select>
      <div
        style={{ background: background ? background : '#C4C4C4' }}
        className={`${props.large ? 'audio-player-container-large' : ''} audio-player-container`}
      >
        <div
          id={'audio-player-container'}
          className={`audio-tag-recorder ${props.large ? '' : 'audio-tag-recorder-small'}`}
        >
          <audio id='audio-player' style={{ display: 'none' }} controls></audio>
          {loading || props.loading ? (
            <>
              <Progress
                showInfo={false}
                percent={percentage}
                status={percentage === 100 ? 'success' : 'active'}
              />
              <div>Uploading...</div>
            </>
          ) : (
            <>
              <Button
                onClick={() => clearAudio()}
                shape={'circle'}
                type='primary'
                size={'large'}
                icon={'delete'}
                style={{ borderColor: 'red', backgroundColor: 'red', margin: 10 }}
              />
              <Button
                onClick={() => setPlaying((playValue) => !playValue)}
                className={`play-audio-button-large ${
                  props.large ? ` ${playing ? '' : 'play-icon'}` : ''
                }`}
                shape={'circle'}
                type='primary'
                size={'large'}
                icon={playing ? 'pause' : ''}
              >
                {!playing && (
                  <img
                    className={props.large ? 'play-Icon-audio-record' : 'play-Icon-audio-record'}
                    src={PlayIcon}
                    alt='play'
                  />
                )}
              </Button>
              <Button
                onClick={async () => {
                  onClose && onClose(false);
                  const audio: any = document.getElementById('audio-player');
                  audio.src = null;
                  const audioUrl = await sendAudioToStore();
                  setPlaying(false);
                  onSubmit && onSubmit(audioUrl);
                }}
                shape={'circle'}
                type='primary'
                size={'large'}
                icon={'like'}
                style={{ borderColor: 'green', backgroundColor: 'green', margin: 10 }}
              />
              <div style={{ fontSize: '20px', fontWeight: 400 }} ref={audioPlayBackTimer} />
            </>
          )}
        </div>
        <div style={{ height: props.large ? '70px' : blobObject.blob ? '0px' : '40px' }}>
          <span id='mySidenav'>
            {!micModal && (
              <Button
                onClick={() => setMicModal((value) => !value)}
                shape={'circle'}
                type='primary'
                size={'large'}
                icon={'setting'}
                className={props.large ? '' : 'settings-player-button-small'}
                id='settings-player-button'
              />
            )}
            <span
              className={props.large ? '' : 'settings-player-button-small'}
              id='settings-player-button'
              style={{
                width: '230px',
                backgroundColor: 'white',
                borderRadius: '5px',
                display: micModal ? 'flex' : 'none',
                padding: '5px',
              }}
            >
              <select style={{ display: micModal ? 'block' : 'none' }} name='' id='micSelect'>
                {/* {micOptions.map((micOption: { value: string; text: string }) => {
                  return <option value={micOptions.label}>{micOption.text}</option>;
                })} */}
              </select>
              <Button
                onClick={() => setMicModal((value) => !value)}
                shape={'circle'}
                type='primary'
                size={'large'}
                icon={'close'}
                style={{ display: micModal ? 'block' : 'none' }}
              />
            </span>
          </span>
          <Button
            id='record'
            className={`button-record-audio-player ${props.large ? 'record-icon' : ''}`}
            shape={'circle'}
            type='primary'
            size={'large'}
            icon={'audio'}
            onClick={() => !isAllowPermissionRef.current && setIsOpen(true)}
          />
          <Button
            id='pauseAudioRecord'
            className={`button-record-audio-player ${props.large ? 'record-icon' : ''}`}
            style={{
              marginRight: '60px',
              display: isPaused ? 'none' : isRecording ? 'block' : 'none',
            }}
            shape={'circle'}
            type='primary'
            size={'large'}
            icon={'pause'}
          />

          <Button
            id='resumeAudioRecord'
            className={`button-record-audio-player ${props.large ? 'record-icon' : ''}`}
            style={{
              marginRight: '60px',
              display: isPaused ? (isRecording ? 'block' : 'none') : 'none',
            }}
            shape={'circle'}
            type='primary'
            size={'large'}
            icon='audio'
          />

          <Button
            id='stop'
            className={`button-record-audio-player ${props.large ? 'record-icon' : ''}`}
            style={{ display: 'none', backgroundColor: 'red', borderColor: 'red' }}
            shape={'circle'}
            type='primary'
            size={'large'}
          >
            <div className={`stop-icon ${props.large ? '' : 'stop-icon-small'}`}></div>
          </Button>
        </div>
        <div
          className={`timer-container timer-audio-recorder timer-container-audio-recording
                    ${props.large ? '' : ' timer-container-small'}`}
        >
          {isRecording && !isPaused && <TimerComp />}
          {isRecording && isPaused && secondsToHms(maxRecordTime * 60 - timer)}
        </div>
        {!blobObject.blob && !isRecording && (
          <div
            className={`timer-audio-recorder timer-container
                    timer-container-audio-recording ${props.large ? '' : ' timer-container-small'}`}
          >
            {secondsToHms(maxRecordTime * 60)}
          </div>
        )}
        <canvas
          className={props.large ? 'canvas-audio-recorder-large' : ''}
          id={'canvas-audio-recorder'}
        ></canvas>
      </div>
    </div>
  );
};

export default RecordAudioFile;

import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { secondsToHms } from '../../utils/index';

export const RecordingTimer = ({
  startTimer,
  maxRecordTime = 30,
  timerStyle = '',
  hideText = false,
}: any) => {
  const [timer, setTimer]: any = useState(maxRecordTime * 60);
  useEffect(() => {
    const a = setInterval(() => {
      setTimer((timerValueCurrent: any) => {
        if (timerValueCurrent <= 0) {
          startTimer(false);
          clearInterval(a);
          notification.error({
            description: 'Limit for the recording time has been reached.',
            message: 'Timeout!',
          });
        }
        return timerValueCurrent - 1;
      });
    }, 1000);
    return () => {
      a && clearInterval(a);
    };
  }, []);
  return (
    <div style={{ fontWeight: 'bolder', textAlign: 'center' }}>
      {!hideText && <span style={{ color: 'red' }}>RECORDING </span>}
      <span className={timerStyle}>{secondsToHms(timer)}</span>
    </div>
  );
};

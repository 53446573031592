import { Drawer, Form, Input, Modal, notification, Select } from 'antd';
import ColorContext from 'context/ColorContext';
import firebase from 'firebase/app';
import { Zbutton, Zinput } from 'primitives';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { editProject as editProjectAction } from 'store/actions/projectActions';
import { getMasterData, postTrelloData } from 'store/actions/trelloIntegrationAction';
import IntegrationDrawer from '../dashboard/IntegrationDrawer';
import './index.css';

const CreateIssueForm = (props: any) => {
  const { visible, setVisible, auth, selectedOrganizationData } = props;
  const [boards, setBoards] = useState([]);
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState('');
  const [prevTrelloConnect, setPrevTrelloConnect] = useState(false);
  const [integrationDrawerVisible, setIntegrationDrawerVisible] = useState(false);

  const onCloseIntegrationDrawer = () => {
    setIntegrationDrawerVisible(false);
  };

  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { TextArea } = Input;
  const trelloConnect = (props.profile && props.profile.trelloConnect) || false;

  useEffect(() => {
    async function getBoards() {
      const fiebaseInstance: any = firebase;
      if (fiebaseInstance && fiebaseInstance.auth() && fiebaseInstance.auth().currentUser) {
        const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
        if (trelloConnect !== prevTrelloConnect) {
          getMasterData(firebaseToken, '/members/me/boards').then((data: any) => {
            setBoards(data);
            setPrevTrelloConnect(trelloConnect);
          });
        }
      }
    }
    // Execute the created function directly
    getBoards();
  }, [props.profile, prevTrelloConnect, auth]);

  useEffect(() => {
    async function getBoardLists() {
      const fiebaseInstance: any = firebase;
      if (fiebaseInstance && fiebaseInstance.auth() && fiebaseInstance.auth().currentUser) {
        const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
        if (trelloConnect) {
          getMasterData(firebaseToken, `/boards/${selectedBoardId}/lists`).then((data: any) => {
            setLists(data);
          });
        }
      }
    }
    getBoardLists();
  }, [selectedBoardId]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      const { summary, description, boardListId } = values;
      const loginUserId = props.auth.uid;
      const { projectId, editProject } = props;
      const projectURL = window.location.href;
      if (!err && loginUserId) {
        const postBodyData: any = {
          name: summary,
          desc: `${description} Website URL: ${projectURL}`,
          pos: 'top',
          dueComplete: false,
          idList: boardListId,
          keepFromSource: 'all',
        };

        setIsLoading(true);
        const fiebaseInstance: any = firebase;
        const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
        postTrelloData(firebaseToken, '/cards', JSON.stringify(postBodyData)).then(
          (resData: any) => {
            if (resData.error) {
              notification.error({
                message: resData.message,
              });
              setIsLoading(false);
              setVisible();
            } else {
              const trelloIssueURL =
                resData && resData.data && resData.data.url ? resData.data.url : '';
              const firestore = firebase.firestore();
              firestore
                .collection('projects')
                .doc(projectId)
                .get()
                .then((doc: any) => {
                  if (doc.exists) {
                    const project = doc.data();
                    const { member_emails, type, imageType, jiraIssueUrl, tags } = project;
                    editProject(
                      projectId,
                      project.title,
                      member_emails,
                      tags,
                      type,
                      imageType,
                      jiraIssueUrl,
                      trelloIssueURL,
                    ).then(() => {
                      props.form.resetFields();
                      notification.success({
                        message: resData.message,
                      });
                      setIsLoading(false);
                      setVisible();
                    });
                  }
                });
            }
          },
        );
      }
    });
  };

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div>
          {trelloConnect ? (
            <Drawer
              title='Create Trello Issue'
              placement='right'
              closable={true}
              onClose={() => setVisible()}
              visible={visible}
              style={{ position: 'absolute' }}
              width='75vw'
            >
              <div className='trello-issue-create-form' style={{ color: theme.text.color2 }}>
                <Form className='white'>
                  <Form.Item label='Summary'>
                    {getFieldDecorator('summary', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input summary!',
                        },
                      ],
                    })(<Zinput />)}
                  </Form.Item>
                  <Form.Item label='Borad'>
                    {getFieldDecorator('boardKey', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: 'Please select board!',
                        },
                      ],
                    })(
                      <Select
                        onChange={(value: string) => {
                          setSelectedBoardId(value);
                        }}
                        showSearch
                        placeholder='Select a board'
                        optionFilterProp='children'
                        filterOption={(input: string, option: any) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {boards.map((board: any) => (
                          <Option key={board.id} value={board.id} className='select-option'>
                            {board.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>

                  <Form.Item label='Board List'>
                    {getFieldDecorator('boardListId', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: 'Please select board list!',
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        placeholder='Select a board list'
                        optionFilterProp='children'
                        filterOption={(input: string, option: any) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {lists &&
                          lists.map((list: any) => (
                            <Option key={list.id} value={list.id} className='select-option'>
                              {list.name}
                            </Option>
                          ))}
                      </Select>,
                    )}
                  </Form.Item>
                  <Form.Item label='Description'>
                    {getFieldDecorator('description', {
                      initialValue: '',
                    })(<TextArea rows={2} />)}
                  </Form.Item>
                  <div className='container-btns'>
                    <Zbutton
                      key='back'
                      secondary
                      btnColors={theme}
                      style={{ marginRight: 10 }}
                      onClick={() => setVisible()}
                    >
                      Cancel
                    </Zbutton>
                    <Zbutton
                      primary
                      btnColors={theme}
                      key='submit'
                      loading={isLoading}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Zbutton>
                  </div>
                </Form>
              </div>
            </Drawer>
          ) : (
            <Modal
              title='Create Trello Issue'
              visible={visible}
              onOk={() => setVisible()}
              onCancel={() => setVisible()}
              footer={null}
            >
              <div style={{ color: theme.text.color2 }}>
                <p>Please connect your trello account to create an issue.</p>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <Zbutton
                    primary
                    btnColors={theme}
                    onClick={() => setIntegrationDrawerVisible(true)}
                  >
                    Connect to Trello
                  </Zbutton>
                </div>
              </div>
            </Modal>
          )}
          <IntegrationDrawer
            onCloseDrawer={onCloseIntegrationDrawer}
            visible={integrationDrawerVisible}
            selectedOrganizationData={selectedOrganizationData}
          />
        </div>
      )}
    </ColorContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    editProject: (
      id: string,
      title: string,
      memberEmails: string[],
      tags: string[],
      type: string,
      imageType: string,
      jiraIssueUrl: string,
      trelloIssueUrl: string,
    ) =>
      dispatch(
        editProjectAction(
          id,
          title,
          memberEmails,
          tags,
          type,
          imageType,
          jiraIssueUrl,
          trelloIssueUrl,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create()(CreateIssueForm));

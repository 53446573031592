import { Modal } from 'antd';
import firebase from 'firebase/app';
import 'firebase/storage';
import React from 'react';
import { trackAnalytics } from '../../utils';
import VideoRecord from '../recording/VideoRecord';
const fiebaseInstance: any = firebase;
const { REACT_APP_IS_AWS_REAL_TIME_SAVING = 'false' } = process.env;
const isAWSRealTimeSaving = REACT_APP_IS_AWS_REAL_TIME_SAVING.trim() === 'true' ? true : false;

const RecordVideo = (props: any) => {
  const [loading, setLoading] = React.useState(false);
  const [isRocording, setIsRecording] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');

  const {
    isRecordModal,
    setIsRecordModal,
    sendFeedback,
    pageID,
    sendVideoToStore,
    setSendingImage,
    updateFeedback,
    userQuota,
    isPublicChatWindow,
    firebaseAuthToken,
  } = props;
  let firebaseToken = '';

  React.useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const sendToFirebase = async (mediaBlobUrl: any, projectS3URL: string) => {
    trackAnalytics(
      'Feedback modal',
      `Send video ${isRecordModal === 'screen' ? 'recording' : ''} clicked'`,
    );
    setLoading(true);
    setIsRecordModal('');
    updateFeedback(1, 'video');
    let mediaURL = '';
    firebaseToken = firebaseAuthToken;
    if (!firebaseToken) {
      firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    if (isAWSRealTimeSaving) {
      mediaURL = projectS3URL;
    } else {
      const blob = await fetch(mediaBlobUrl).then((r) => r.blob());
      const videoFirebaseUrl = await sendVideoToStore(blob);
      mediaURL = videoFirebaseUrl;
    }
    await sendFeedback('', '', false, pageID, false, '', '', mediaURL, '', mediaBlobUrl);
    setSendingImage(false);
    setLoading(false);
  };
  const recordingType =
    isRecordModal === 'screen' ? 'screenRecordingCommentTime' : 'videoCommentTime';

  if (videoUrl || isRocording) {
    window.onbeforeunload = (event: any) => {
      const message = 'Sure you want to leave?';
      if (typeof event === 'undefined') {
        event = window.event;
      }
      if (event) {
        event.returnValue = message;
      }
      return message;
    };
  } else {
    window.onbeforeunload = null;
  }

  return (
    <Modal
      title='Record a video comment'
      visible={isRecordModal}
      footer={false}
      width={800}
      bodyStyle={{ height: 'auto' }}
      onCancel={() => {
        if (videoUrl || isRocording) {
          Modal.confirm({
            content: '',
            title: 'Are you sure, your recording will be lost?',
            async onOk() {
              firebaseToken = firebaseAuthToken;
              if (!firebaseToken) {
                firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
              }
              setIsRecordModal('');
              trackAnalytics('Chat window', 'Record modal close');
            },
          });
        } else {
          setIsRecordModal('');
          trackAnalytics('Chat window', 'Record modal close');
        }
      }}
    >
      <VideoRecord
        loading={loading}
        isRecordModal={isRecordModal}
        sendToFirebase={sendToFirebase}
        setVideoUrl={setVideoUrl}
        showSendButton
        maxRecordTime={userQuota && userQuota[recordingType]}
        setIsRecording={setIsRecording}
        isPublicChatWindow={isPublicChatWindow}
        firebaseAuthToken={firebaseAuthToken}
      />
    </Modal>
  );
};

export default RecordVideo;

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9C0.75 4.44365 4.44365 0.75 9 0.75ZM2.29924 8.18055C2.51789 8.46041 3.12586 8.81848 4.05619 9.11063C4.42251 9.22567 4.8237 9.32679 5.25416 9.41266C5.2514 9.27591 5.25 9.13832 5.25 9C5.25 6.50427 5.70417 4.25048 6.49091 2.73173C4.24809 3.63031 2.59971 5.69775 2.29924 8.18055ZM5.34685 10.9581C4.1425 10.7488 3.10983 10.432 2.32722 10.0243C2.69128 12.4156 4.31008 14.3945 6.49091 15.2683C5.9165 14.1594 5.51937 12.6587 5.34685 10.9581ZM6.88418 11.158C7.55701 11.2186 8.26566 11.25 9 11.25C9.72249 11.25 10.4343 11.209 11.119 11.1331C10.7807 13.8313 9.84525 15.75 9 15.75C8.15735 15.75 7.2251 13.8431 6.88418 11.158ZM11.2397 9.60338C10.5276 9.69562 9.77104 9.75 9 9.75C8.21683 9.75 7.46506 9.71121 6.76149 9.63766C6.75391 9.42792 6.75 9.21523 6.75 9C6.75 5.238 7.9452 2.25 9 2.25C10.0548 2.25 11.25 5.238 11.25 9C11.25 9.20351 11.2465 9.40475 11.2397 9.60338ZM12.6594 10.8952C12.4904 12.6217 12.0906 14.1457 11.5091 15.2683C13.7284 14.3791 15.3657 12.3454 15.6909 9.89732C14.8958 10.3109 13.8485 10.6567 12.6594 10.8952ZM15.6964 8.1451C15.3435 8.54124 14.2303 9.01181 12.7472 9.34059C12.7491 9.2276 12.75 9.11405 12.75 9C12.75 6.50427 12.2958 4.25048 11.5091 2.73173C13.7412 3.62603 15.3846 5.67807 15.6964 8.1451Z'
      fill={color || '#4F4F4F'}
    />
  </svg>
);

export default Icon;

import 'ant-design-pro/dist/ant-design-pro.css';
import AvatarList from 'ant-design-pro/lib/AvatarList';
import { Avatar, Tooltip } from 'antd';
import React from 'react';
import { IMemberUser } from '../../types/projectTypes';
import './members.css';

// const members = [
//   {
//     image: 'https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png',
//     name: 'Test 1',
//   },
//   {
//     image: 'https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png',
//     name: 'Test 2',
//   },
//   {
//     image: '',
//     name: 'Test 3',
//   },
//   {
//     image: 'https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png',
//     name: 'Test 1',
//   },
//   {
//     image: 'https://gw.alipayobjects.com/zos/rmsportal/sfjbOqnsXXJgNCjCzDBL.png',
//     name: 'Test 2',
//   },
//   {
//     image: '',
//     name: 'Test 3',
//   },
// ];

const MembersAvtar = ({
  members,
  setCanvasMemberModalVisible,
}: {
  members: IMemberUser[];
  setCanvasMemberModalVisible?: any;
}) => {
  return (
    <div className='members-container'>
      <AvatarList size={33}>
        {members.map((member: any, index: number) => {
          const { name = '', image, id } = member;
          if (image) {
            return <AvatarList.Item key={index} tips={name} src={image} />;
          } else {
            return (
              <Tooltip title={name} key={index}>
                <li
                  onClick={() =>
                    setCanvasMemberModalVisible ? setCanvasMemberModalVisible(true) : null
                  }
                  className='antd-pro-avatar-list-avatarItem'
                  key={id}
                >
                  <Avatar key={index}>
                    {`${name && name[0] && name[0].toUpperCase()}${
                      name && name[1] ? name[1].toUpperCase() : ''
                    }`}
                  </Avatar>
                </li>
              </Tooltip>
            );
          }
        })}
      </AvatarList>
    </div>
  );
};

export default MembersAvtar;

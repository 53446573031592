import autosize from 'autosize';
import React, { useEffect, useRef } from 'react';
import { SendMessageIcon } from './ChatSvgs';

const ChatMessageInput = (props: any) => {
  const [value, setValue] = React.useState('');
  const inputField: any = useRef(null);
  const {
    getImageBlob,
    isPublicChatWindow,
    sendFeedbackFunc,
    setData,
    updateInput,
    auth,
    showDetailModal,
    setUpdateInput,
    setScrollIntoView,
    isInterviewCanvas = false,
  } = props;

  useEffect(() => {
    autosize(inputField.current);
  }, []);

  useEffect(() => {
    setValue(setData);
  }, [setData, updateInput]);

  const keyDownHandler = (e: any, message: any) => {
    if (!isInterviewCanvas && auth.isEmpty) {
      // open modal to get public user details
      showDetailModal(true);
    } else if (e.shiftKey) {
      return;
    } else {
      if (e.keyCode === 13 && message !== '') {
        setValue('');
        setUpdateInput((inputUpdate: any) => !inputUpdate);
        sendFeedbackFunc(message);
      }

      return setScrollIntoView(false);
    }
  };

  return (
    <div className='chat-message-input-box'>
      <textarea
        className='chat-message-input'
        placeholder={'Message...'}
        onKeyUp={(e: any) => keyDownHandler(e, value)}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        style={{ height: value.length === 0 ? '40px' : '' }}
        ref={inputField}
        value={value}
        rows={1}
        id={'resizable-input'}
        onPaste={(e) => getImageBlob(e)}
        autoFocus={isPublicChatWindow ? false : true}
      />

      <div className='send-feedback-button chat-window-feedback-button'>
        <div
          style={{ cursor: value !== '' ? 'pointer' : 'not-allowed' }}
          onClick={value !== '' ? () => sendFeedbackFunc(value) : () => null}
        >
          <SendMessageIcon color={value !== '' ? '#306AE1' : '#B0BEC5'} />
        </div>
      </div>
    </div>
  );
};

export default ChatMessageInput;

import 'firebase/storage';

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './DragAndDropZone.css';
import UploadFileLogic from './UploadFileLogic';

export interface IUploadFileModelProps {
  callback: any;
  children: any;
  preload: any;
  circlePointerID: string;
  pageId: string;
  mockUpload?: any;
  profile: any;
  auth: any;
  organization: any;
}

const DragAndDropZone: (props: IUploadFileModelProps) => JSX.Element = ({
  callback,
  children,
  preload,
  circlePointerID,
  pageId,
  mockUpload,
  profile,
  auth,
  organization,
}) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      mockUpload(acceptedFiles.length);
      if (preload) {
        await UploadFileLogic(acceptedFiles, callback, preload, profile, auth, organization);
      } else {
        await UploadFileLogic(acceptedFiles, callback, null, profile, auth, organization);
      }
    },
    [circlePointerID, pageId],
  );

  const onDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    onDragOver,
  });

  return (
    <React.Fragment>
      <div className={`dropZone ${isDragActive && 'active'}`} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive && <p>Drop the file here ...</p>}
        {children}
      </div>
    </React.Fragment>
  );
};

export default compose<any>(
  connect(
    null,
    null,
  ),
)(DragAndDropZone);

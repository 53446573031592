import * as Redux from 'redux';

// Constant Types
export const Type = {
  ClearAuthState: 'CLEAR_AUTH_STATE',
  SignInError: 'SIGN_IN_ERROR',
  SignInRequest: 'SIGN_IN_REQUEST',
  SignInSuccess: 'SIGN_IN_SUCCESS',
  SignOutSuccess: 'SIGN_OUT_SUCCESS',
  SignUpError: 'SIGN_UP_ERROR',
  SignUpRequest: 'SIGN_UP_REQUEST',
  SignUpSuccess: 'SIGN_UP_SUCCESS',
  StatusIdle: 'IDLE',
  StatusRunning: 'RUNNING',
  forgotPasswordError: 'FORGOT_PASSWORD_ERROR',
  forgotPasswordRequest: 'FORGOT_PASSWORD_REQUEST',
  forgotPasswordSuccess: 'FORGOT_PASSWORD_SUCCESS',
  resetPasswordError: 'RESET_PASSWORD_ERROR',
  resetPasswordRequest: 'RESET_PASSWORD_REQUEST',
  resetPasswordSuccess: 'RESET_PASSWORD_SUCCESS',
  verifyTokenError: 'VERIFY_TOKEN_ERROR',
  verifyTokenRequest: 'VERIFY_TOKEN_REQUEST',
  verifyTokenSuccess: 'VERIFY_TOKEN_SUCCESS',
  saveIframe: 'SAVE_IFRAME',
  firebaseAuthToken: 'UPDATE_FIREBASE_AUTH_TOKEN',
  userProductPlan: 'UPDATE_USER_PRODUCT_PLAN',
  GoogleLogedIn: 'GOOGLE_LOGEDIN',
  GoogleLogInError: 'GOOGLE_LOGEDIN_ERROR',
  githubLogedIn: 'GITGUB_LOGEDIN',
  githubLogInError: 'GITGUB_LOGEDIN_ERROR',
  updateUserProfileRequest: 'UPDATE_USER_PROFILE_REQUEST',
  updateUserProfileSuccess: 'UPDATE_USER_PROFILE_SUCCESS',
  updateUserProfileErrore: 'UPDATE_USER_PROFILE_ERROR',
  updateEmailRequest: 'UPDATE_EMAIL_REQUEST',
  updateEmailSuccess: 'UPDATE_EMAIL_SUCCESS',
  updateEmailErrore: 'UPDATE_EMAIL_ERROR',
};

// IAuthAction
export interface IAuthAction extends Redux.Action {
  type: string;
  err?: { message?: string };
  payload?: any;
}

// IAuthState
export interface IAuthState {
  authError: null | string;
  status: string;
  firebaseAuthToken: string;
  userProductPlan: string;
}

export interface ICredentials {
  email: string;
  password: string;
  isPublicUser?: boolean;
}

export interface IUser {
  email: string;
  password: string;
  fullName: string;
  adminIds: string[];
  isPublicUser?: boolean;
  organizationId?: string;
  appSumoId?: string;
}

import React from 'react';

export default () => (
  <svg width='184' height='57' viewBox='0 0 184 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <line x1='0.5' y1='26.2519' x2='0.5' y2='30.2906' stroke='#4F4F4F' />
    <line x1='99.6183' y1='27.2615' x2='99.6183' y2='29.2809' stroke='#4F4F4F' />
    <line x1='50.0591' y1='23.2228' x2='50.0591' y2='33.3196' stroke='#4F4F4F' />
    <line x1='149.178' y1='27.2615' x2='149.178' y2='29.2809' stroke='#4F4F4F' />
    <line x1='25.2793' y1='23.2228' x2='25.2793' y2='33.3196' stroke='#4F4F4F' />
    <line x1='21.8724' y1='22' x2='21.8724' y2='35' stroke='#4F4F4F' />
    <line x1='124.398' y1='23.2228' x2='124.398' y2='33.3196' stroke='#4F4F4F' />
    <line x1='74.8389' y1='19.184' x2='74.8389' y2='37.3584' stroke='#4F4F4F' />
    <line x1='173.957' y1='23.2228' x2='173.957' y2='33.3196' stroke='#4F4F4F' />
    <line x1='12.8897' y1='19.184' x2='12.8897' y2='37.3584' stroke='#4F4F4F' />
    <line x1='112.008' y1='27.2615' x2='112.008' y2='29.2809' stroke='#4F4F4F' />
    <line x1='62.4489' y1='12.1162' x2='62.4489' y2='44.4262' stroke='#4F4F4F' />
    <line x1='161.568' y1='20.1937' x2='161.568' y2='36.3487' stroke='#4F4F4F' />
    <line x1='37.6693' y1='27.2615' x2='37.6693' y2='29.2809' stroke='#4F4F4F' />
    <line x1='136.788' y1='16.155' x2='136.788' y2='40.3875' stroke='#4F4F4F' />
    <line x1='87.2288' y1='25.2422' x2='87.2288' y2='31.3003' stroke='#4F4F4F' />
    <line x1='3.59741' y1='24.2325' x2='3.59741' y2='32.31' stroke='#4F4F4F' />
    <line x1='102.716' y1='27.2615' x2='102.716' y2='29.2809' stroke='#4F4F4F' />
    <line x1='53.1566' y1='11.1066' x2='53.1566' y2='45.4359' stroke='#4F4F4F' />
    <line x1='152.275' y1='25.2422' x2='152.275' y2='31.3003' stroke='#4F4F4F' />
    <line x1='28.3772' y1='24.2325' x2='28.3772' y2='32.31' stroke='#4F4F4F' />
    <line x1='127.496' y1='22.2131' x2='127.496' y2='34.3293' stroke='#4F4F4F' />
    <line x1='77.9364' y1='16.155' x2='77.9364' y2='40.3874' stroke='#4F4F4F' />
    <line x1='177.055' y1='25.2422' x2='177.055' y2='31.3003' stroke='#4F4F4F' />
    <line x1='15.9872' y1='20.1937' x2='15.9872' y2='36.3487' stroke='#4F4F4F' />
    <line x1='115.106' y1='27.2615' x2='115.106' y2='29.2809' stroke='#4F4F4F' />
    <line x1='65.5464' y1='16.155' x2='65.5464' y2='40.3874' stroke='#4F4F4F' />
    <line x1='164.665' y1='23.2228' x2='164.665' y2='33.3196' stroke='#4F4F4F' />
    <line x1='40.7668' y1='27.2615' x2='40.7668' y2='29.2809' stroke='#4F4F4F' />
    <line x1='139.885' y1='11.1066' x2='139.885' y2='45.4359' stroke='#4F4F4F' />
    <line x1='90.3262' y1='26.2519' x2='90.3262' y2='30.2906' stroke='#4F4F4F' />
    <line x1='6.69482' y1='25.2422' x2='6.69482' y2='31.3003' stroke='#4F4F4F' />
    <line x1='105.813' y1='27.2615' x2='105.813' y2='29.2809' stroke='#4F4F4F' />
    <line x1='56.254' y1='3.81663e-09' x2='56.254' y2='56.5424' stroke='#4F4F4F' />
    <line x1='155.372' y1='22.2131' x2='155.372' y2='34.3293' stroke='#4F4F4F' />
    <line x1='31.4747' y1='25.2422' x2='31.4747' y2='31.3003' stroke='#4F4F4F' />
    <line x1='130.593' y1='21.2034' x2='130.593' y2='35.339' stroke='#4F4F4F' />
    <line x1='81.0338' y1='20.1937' x2='81.0338' y2='36.3487' stroke='#4F4F4F' />
    <line x1='180.152' y1='23.2228' x2='180.152' y2='33.3196' stroke='#4F4F4F' />
    <line x1='19.0847' y1='21.2034' x2='19.0847' y2='35.339' stroke='#4F4F4F' />
    <line x1='118.203' y1='26.2519' x2='118.203' y2='30.2906' stroke='#4F4F4F' />
    <line x1='68.6438' y1='20.1937' x2='68.6438' y2='36.3487' stroke='#4F4F4F' />
    <line x1='167.762' y1='25.2422' x2='167.762' y2='31.3003' stroke='#4F4F4F' />
    <line x1='43.8642' y1='27.2615' x2='43.8642' y2='29.2809' stroke='#4F4F4F' />
    <line x1='142.983' y1='20.1937' x2='142.983' y2='36.3487' stroke='#4F4F4F' />
    <line x1='93.4236' y1='27.2615' x2='93.4236' y2='29.2809' stroke='#4F4F4F' />
    <line x1='9.7923' y1='22.2131' x2='9.7923' y2='34.3293' stroke='#4F4F4F' />
    <line x1='108.911' y1='27.2615' x2='108.911' y2='29.2809' stroke='#4F4F4F' />
    <line x1='59.3514' y1='7.06781' x2='59.3514' y2='49.4746' stroke='#4F4F4F' />
    <line x1='158.47' y1='21.2034' x2='158.47' y2='35.339' stroke='#4F4F4F' />
    <line x1='34.5721' y1='26.2519' x2='34.5721' y2='30.2906' stroke='#4F4F4F' />
    <line x1='133.69' y1='19.184' x2='133.69' y2='37.3584' stroke='#4F4F4F' />
    <line x1='84.1313' y1='23.2228' x2='84.1313' y2='33.3196' stroke='#4F4F4F' />
    <line x1='183.25' y1='26.2519' x2='183.25' y2='30.2906' stroke='#4F4F4F' />
    <line x1='121.301' y1='25.2422' x2='121.301' y2='31.3003' stroke='#4F4F4F' />
    <line x1='71.7415' y1='22.2131' x2='71.7415' y2='34.3293' stroke='#4F4F4F' />
    <line x1='170.86' y1='24.2325' x2='170.86' y2='32.31' stroke='#4F4F4F' />
    <line x1='46.9617' y1='26.2519' x2='46.9617' y2='30.2906' stroke='#4F4F4F' />
    <line x1='146.08' y1='26.2519' x2='146.08' y2='30.2906' stroke='#4F4F4F' />
    <line x1='96.5208' y1='27.2615' x2='96.5208' y2='29.2809' stroke='#4F4F4F' />
  </svg>
);

import {
  CANVAS_FILTER_THREADS,
  HIDE_ADD_VIDEO_CANVAS,
  NewCanvasTypes,
  SHOW_ADD_VIDEO_CANVAS,
} from '../../types/newCanvasTypes';

const initState = {
  addVideoCanvasVisiblity: false,
  filterThreads: [],
};

const newCanvasReducer = (state = initState, action: NewCanvasTypes) => {
  switch (action.type) {
    case SHOW_ADD_VIDEO_CANVAS:
      return { ...state, addVideoCanvasVisiblity: action.payload };
    case HIDE_ADD_VIDEO_CANVAS:
      return { ...state, addVideoCanvasVisiblity: false };
    case CANVAS_FILTER_THREADS:
      return { ...state, filterThreads: action.payload };
    default:
      return state;
  }
};

export default newCanvasReducer;

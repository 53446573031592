import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { canvasFilterThreads } from '../../store/actions/newCanvasActions';
import { IThread } from '../../types/projectTypes';
import Iframe from '../iframe';
import TextDetail from '../textDetail';
import './style.css';
import VideoCanvas from './VideoCanvas';

const Canvas = (props: any) => {
  const { auth = [] } = props;
  const { uid = '' } = auth;

  const initialCanvasState = {
    title: '',
    project_url: '',
    createdAtUTC: '',
    tags: [],
    members: [],
    threads: [],
    workflowId: '',
    transcript: [],
    archived: 'no',
    blobURL: '',
    project_created_by: '',
    userNotes: '',
    jiraIssueUrl: '',
    trelloIssueUrl: '',
  };

  const [canvasDetails, setCanvasDetails] = useState(initialCanvasState);

  useEffect(() => {
    const firestore = firebase.firestore();
    firestore
      .collection('projects')
      .where(firebase.firestore.FieldPath.documentId(), '==', props.match.params.id)
      .onSnapshot((projectDetails: any) => {
        if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
          const projectDetailData = projectDetails.docs[0].data();
          setCanvasDetails(projectDetailData);
        } else {
          setCanvasDetails(initialCanvasState);
        }
      });
  }, [props.match.params.id]);

  const {
    project_url,
    createdAtUTC = '',
    transcript = [],
    blobURL = '',
    project_created_by = '',
    userNotes = '',
  } = canvasDetails;

  return (
    <div className='canvas-detail-container'>
      <div className='canvas-content-container'>
        <div className='video-player-area'>
          {props.match.path === '/text/:id' ? (
            <TextDetail projectId={props.match.params.id} userNotes={userNotes} />
          ) : props.match.path === '/whiteboard/:id' ||
            props.match.path === '/website/:id' ||
            props.match.path === '/image/:id' ? (
            project_url ? (
              <Iframe
                isWriteAccess={true}
                drawerVisible={false}
                setDrawerVisible={() => null}
                url={project_url}
                iframe={props.iframe}
                projectId={props.match.params.id}
                toggleDrawing={false}
                setKeyboardEvent={() => null}
                project={canvasDetails}
                addComment={() => null}
                strokeColor={'#001DAD'}
              />
            ) : null
          ) : (
            <VideoCanvas
              videoUrl={project_url}
              createdAt={createdAtUTC}
              createdAtUTC={createdAtUTC}
              isMediaCreatedByLoginUser={project_created_by === uid}
              pauseVideo={() => null}
              // selctedThreadMediaTime={0}
              transcript={transcript}
              blobURL={blobURL}
              // props for the Iframe
              iframe={props.iframe}
              projectId={props.match.params.id}
              toggleDrawing={false}
              setKeyboardEvent={() => null}
              project={canvasDetails}
              addComment={() => null}
              strokeColor={'#001DAD'}
              isChatMode={false}
              smallPlayer
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    iframe: state.project.iframe,

    threads: state.canvas.filterThreads,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    canvasFilterThreadsAction: (threads: IThread[]) => dispatch(canvasFilterThreads(threads)),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Canvas);

import { Avatar, Input } from 'antd';
import React, { useRef, useState } from 'react';
import { trackAnalytics } from 'utils';
import { IThread } from '../../../types/projectTypes';
import { getVideoDurationFormat } from '../../../utils';

import './thread.css';

const ThreadItem = ({
  thread,
  changeThread,
  updateThreadMessage,
  isEditableThread,
  loginUserEmail = '',
  isVideoType,
}: {
  isEditableThread?: boolean;
  thread: IThread;
  updateThreadMessage?: any;
  changeThread?: (thread: IThread) => void;
  loginUserEmail: string;
  isVideoType?: boolean;
}) => {
  const {
    member,
    message,
    mediaTime,
    replyCount,
    sequenceNo,
    id,
    readUserEmails = [],
    isResolve,
  } = thread;
  const titleRef: any = useRef(null);
  const { name, image } = member;
  const [isEdit, setIsEdit] = useState(false);
  const [currentThreadMessage, setCurrentThreadMessage] = useState(message);
  const isRead =
    (readUserEmails && readUserEmails.length && readUserEmails.includes(loginUserEmail)) || false;
  return (
    <div
      className={`thread-item cursor-pointer ${isResolve ? 'resolve-thread' : ''} ${
        isEditableThread ? 'selected-thread-item' : ''
      }`}
      key={id}
      onClick={() => {
        changeThread && trackAnalytics('Canvas Detail', 'Thread Item Clicked');
        changeThread && changeThread(thread);
      }}
    >
      <div className='thread-user-container'>
        <div className='thread-user-right-section'>
          <span
            style={{ visibility: !isRead ? 'visible' : 'hidden' }}
            className='thread-online-member'
          ></span>
          {image ? (
            <Avatar className='thread-avtar' src={image} />
          ) : (
            <Avatar>{name && name[0] && name[0].toUpperCase()}</Avatar>
          )}
          <div className='user-detail'>
            <div className='thread-member-name'>{name}</div>
            {isVideoType && (
              <div className='thread-media-time'>{getVideoDurationFormat(mediaTime)}</div>
            )}
          </div>
        </div>
        <div className='thread-replies'>
          {replyCount} {replyCount > 1 ? 'replies' : 'reply'}
        </div>
      </div>

      <div className='thread-message-container'>
        {!isEdit && (
          <div className='thread-message'>
            <div
              className='thread-message-title'
              onClick={() => {
                setIsEdit(true);
                setCurrentThreadMessage(message);
              }}
            >
              {message}
            </div>
            {id && isEditableThread && (
              <span
                className='edit-thread'
                onClick={() => {
                  titleRef && titleRef.current && titleRef.current && titleRef.current.focus();
                  setIsEdit(true);
                  setCurrentThreadMessage(message);
                }}
              >
                Edit
              </span>
            )}
          </div>
        )}
        {isEdit && isEditableThread && (
          <Input
            ref={titleRef}
            autoFocus
            className='thread-edit-input'
            placeholder='Enter thread title'
            value={currentThreadMessage}
            onChange={(e: any) => {
              setCurrentThreadMessage(e.target.value);
            }}
            onBlur={() => {
              updateThreadMessage(currentThreadMessage);
              setIsEdit(false);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                updateThreadMessage(currentThreadMessage);
                setIsEdit(false);
              }
            }}
          />
        )}
        <div className='thread-count'>{sequenceNo ? sequenceNo : ''}</div>
      </div>
    </div>
  );
};

export default ThreadItem;

import { Tooltip } from 'antd';
import { CameraIcon, DisableCameraIcon, DraggableIcon } from 'components/svg';
// @ts-ignore
import getUserMedia from 'getusermedia';
import React, { useState } from 'react';
import Draggable from 'react-draggable'; // The default
import { secondsToHms } from '../../utils/index';
import { RecordingTimer } from '../shared/RecordingTimer';
import './style.css';

const maxRecordTime = 5;
const shouldStartRecording = true;

const ChromeExtensionVideoRecord = () => {
  const [videoStreamSize, setVideoStreamSize] = useState('small');
  const [isTimer, shouldStartTimer] = React.useState(false);
  const [cameraState, setCameraState] = useState(true);
  // let videoTag = null;

  const getVideoStreamContainerClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return '';
      case 'medium':
        return 'videoStreamContainerMedium';
      case 'large':
        return 'videoStreamContainerLarge';
    }
  };
  const getVideoStreamTagClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return 'videoStreamTag';
      case 'medium':
        return 'videStreamTagMedium';
      case 'large':
        return 'videStreamTagLarge';
    }
  };
  const getVideoStreamControlsClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return '';
      case 'medium':
        return 'videoStreamControlsMedium';
      case 'large':
        return 'videoStreamControlsLarge';
    }
  };
  const getStopRecordingButtonClass = () => {
    switch (videoStreamSize) {
      case 'small':
        return '';
      case 'medium':
        return 'stopRecordingButtonMedium';
      case 'large':
        return 'stopRecordingButtonLarge';
    }
  };

  return cameraState ? (
    <Draggable>
      <div className={`videoStreamControls ${getVideoStreamControlsClass()}`}>
        <div className='disableCameraFeed' onClick={() => setCameraState(!cameraState)}>
          <DisableCameraIcon />
        </div>
        <div className={`videoStreamContainer ${getVideoStreamContainerClass()}`}>
          <video
            className={getVideoStreamTagClass()}
            // ref={(input) => {
            //   videoTag = input;
            // }}
            autoPlay
          />
        </div>

        <div className='videoStreamSizes'>
          <div
            className={`videoStreamSmall ${(videoStreamSize === 'small' &&
              'videoStreamSelectedOption') ||
              ''}`}
            onClick={() => setVideoStreamSize('small')}
          ></div>
          <div
            className={`videoStreamMedium  ${(videoStreamSize === 'medium' &&
              'videoStreamSelectedOption') ||
              ''}`}
            onClick={() => setVideoStreamSize('medium')}
          ></div>
          <div
            className={`videoStreamLarge  ${(videoStreamSize === 'large' &&
              'videoStreamSelectedOption') ||
              ''}`}
            onClick={() => setVideoStreamSize('large')}
          ></div>
        </div>
        {shouldStartRecording ? (
          <Tooltip placement='right' title='Stop Recording'>
            <div className={`stopRecordingButton ${getStopRecordingButtonClass()}`}></div>
          </Tooltip>
        ) : null}
        <div className='videoDuration'>
          {isTimer ? (
            <RecordingTimer hideText startTimer={shouldStartTimer} maxRecordTime={maxRecordTime} />
          ) : (
            <span>{secondsToHms(maxRecordTime * 60)}</span>
          )}
        </div>
      </div>
    </Draggable>
  ) : (
    <Draggable>
      <div className='screenRecordingControls'>
        <Tooltip placement='top' title='Add Camera'>
          <div className='addVideoStream' onClick={() => setCameraState(!cameraState)}>
            <CameraIcon color='#4F4F4F' />
          </div>
        </Tooltip>
        <div className='videoDuration chrome-extension-screen-recording'>
          {isTimer ? (
            <RecordingTimer hideText startTimer={shouldStartTimer} maxRecordTime={maxRecordTime} />
          ) : (
            <span>{secondsToHms(maxRecordTime * 60)}</span>
          )}
        </div>
        <Tooltip placement='top' title='Stop Recording'>
          <div className='stopScreenRecordingButton'></div>
        </Tooltip>
        <div className='draggableIcon'>
          <DraggableIcon color='#4F4F4F' />
        </div>
      </div>
    </Draggable>
  );
};

export default ChromeExtensionVideoRecord;

import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import Spinner from '../shared/spinner';
import { CircleRightClick, ErrorIcon } from '../svg';
import './auth.css';

const ConfirmEmail = () => {
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const uuid = urlParams.get('uuid');

    const updateUserEmail = firebase.functions().httpsCallable('updateUserEmail');
    updateUserEmail({ email, uuid })
      .then(() => {
        return setSuccess(true);
      })
      .catch((error: any) => {
        setErrorMsg(error.message);
      });
  });

  return (
    <div className='change-email-container'>
      {success ? (
        <div className='success-message'>
          <CircleRightClick />
          <div style={{ marginLeft: 10 }}>Your email has been successfully changed.</div>
        </div>
      ) : errorMsg ? (
        <div className='success-message'>
          <ErrorIcon />
          <div style={{ marginLeft: 10 }}>{errorMsg}</div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ConfirmEmail;

import { Table } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import './style.css';

class StatsList extends Component<any, any> {
  getColumns = () => {
    return [
      {
        dataIndex: 'date',
        key: 'date',
        title: 'Date',
        width: 'auto',
      },
      {
        dataIndex: 'total_users_signed_up',
        key: 'total_users_signed_up',
        title: 'Total Normal Registration',
        width: 'auto',
      },
      {
        dataIndex: 'total_users_signed_up_by_appsumo_code',
        key: 'total_users_signed_up_by_appsumo_code',
        title: 'Total Registration By AppSumo Code',
        width: 'auto',
      },
      {
        dataIndex: 'total_users_signed_up_by_appsumo_invitee',
        key: 'total_users_signed_up_by_appsumo_invitee',
        title: 'Total Registration By AppSumo Invitees',
        width: 'auto',
      },
      {
        dataIndex: 'total_appsumo_code_used',
        key: 'total_appsumo_code_used',
        title: 'Total AppSumo Code Used',
        width: 'auto',
      },
      {
        dataIndex: 'total_invite_users',
        key: 'total_invite_users',
        title: 'Total Invite Users',
        width: 'auto',
      },
      {
        dataIndex: 'total_users_logged_in',
        key: 'total_users_logged_in',
        title: 'Total SignIn Users',
        width: 'auto',
      },
      {
        dataIndex: 'total_public_users_logged_in',
        key: 'total_public_users_logged_in',
        title: 'Total Public Users SignIn',
        width: 'auto',
      },
      {
        dataIndex: 'total_projects_created',
        key: 'total_projects_created',
        title: 'Total Canvases Created',
        width: 'auto',
      },
      {
        dataIndex: 'total_pages_created',
        key: 'total_pages_created',
        title: 'Total Pages Created',
        width: 'auto',
      },
      {
        dataIndex: 'total_feedback_buttons_create',
        key: 'total_feedback_buttons_create',
        title: 'Total Feedback Buttons Created',
        width: 'auto',
      },
      {
        dataIndex: 'total_comments_sent',
        key: 'total_comments_sent',
        title: 'Total Comments Sent',
        width: 'auto',
      },
      {
        dataIndex: 'total_screenshots_sent',
        key: 'total_screenshots_sent',
        title: 'Total Screenshots Sent',
        width: 'auto',
      },
    ];
  };
  render() {
    return (
      <div className='stats-list section'>
        <Table
          rowKey='id'
          columns={this.getColumns()}
          dataSource={this.props.stats}
          pagination={false}
        />
      </div>
    );
  }
}

export default withRouter(StatsList);

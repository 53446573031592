import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M1.69606 1.62463H9.32356C10.0257 1.62463 10.5948 2.19379 10.5948 2.89588V3.77431L14.4086 1.86742V10.2806L10.5948 8.37372V9.25214C10.5948 9.95423 10.0257 10.5234 9.32356 10.5234H1.69606C0.993963 10.5234 0.424805 9.95423 0.424805 9.25214V2.89588C0.424805 2.19379 0.993963 1.62463 1.69606 1.62463ZM10.5948 6.95242L13.1373 8.22368V3.92435L10.5948 5.19561V6.95242ZM1.69603 2.89589V9.25215H9.32354V2.89589H1.69603Z'
      fill='#EB5757'
    />
    <line
      x1='12.2051'
      y1='1.06069'
      x2='1.26574'
      y2='12'
      stroke='#EB5757'
      stroke-width='1.5'
      stroke-linecap={color || 'round'}
    />
  </svg>
);

export default Icon;

import ColorContext from 'context/ColorContext';
import React from 'react';

export default () => (
  <svg width='21' height='23' viewBox='0 0 21 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <ColorContext.Consumer>
      {({ theme }) => (
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M17.7917 9.41663C17.7917 5.18831 15.46 2.2536 12.4431 1.37257C12.3339 1.08993 12.1613 0.827169 11.9266 0.606688C11.1248 -0.146687 9.87532 -0.146687 9.07349 0.606688C8.83934 0.826685 8.66694 1.08878 8.55766 1.37071C5.53687 2.2479 3.20837 5.1762 3.20837 9.41663C3.20837 12.1603 2.65523 13.6976 1.63125 14.8118C1.46005 14.998 0.996219 15.4247 0.682011 15.7138L0.682003 15.7138L0.681965 15.7138C0.533313 15.8506 0.418158 15.9565 0.383337 15.9916L0.083374 16.2958V19.848H6.42501C6.63944 20.9543 7.23971 21.6944 8.32525 22.3569C9.66061 23.1718 11.3395 23.1718 12.6748 22.3569C13.6328 21.7723 14.2752 20.8738 14.5358 19.848H20.9167V16.2958L20.6167 15.9916C20.5852 15.9595 20.4747 15.8576 20.3303 15.7244L20.3302 15.7244C20.017 15.4354 19.544 14.9991 19.3702 14.8093C18.3456 13.69 17.7917 12.1506 17.7917 9.41663ZM12.3091 19.848H8.60275C8.74992 20.1218 9.00077 20.3285 9.41047 20.5785C10.0795 20.9868 10.9206 20.9868 11.5896 20.5785C11.8994 20.3895 12.1415 20.1393 12.3091 19.848ZM17.8336 16.216C18.0528 16.4555 18.5226 16.8931 18.8334 17.1791V17.7646H2.16671V17.1795C2.47512 16.8969 2.94633 16.4596 3.16513 16.2215C4.55652 14.7077 5.29171 12.6643 5.29171 9.41663C5.29171 5.47902 7.72167 3.17953 10.5 3.17953C13.2706 3.17953 15.7084 5.49089 15.7084 9.41663C15.7084 12.6539 16.4438 14.6979 17.8336 16.216Z'
          fill={theme.text.color2}
        />
      )}
    </ColorContext.Consumer>
  </svg>
);

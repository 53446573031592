import firebase from 'firebase';
import React from 'react';
import { connect } from 'react-redux';
import { Zbutton } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { VectorIcon } from '../svg';
import './auth.css';

interface IProps {
  userId: string;
}

const DeletedUser: React.FC<IProps> = ({ userId }) => {
  const handleUndo = () => {
    const firestore = firebase.firestore();
    return firestore
      .collection('users')
      .doc(userId)
      .set(
        {
          isUserDeleted: false,
        },
        { merge: true },
      )
      .then(() => {
        statsAction('MASTER_PROJECT_UPDATED', firestore);
      })
      .catch(() => {
        return false;
      });
  };

  return (
    <div className='delete-user-containger'>
      <div style={{ marginLeft: 30, marginRight: 40 }}>
        <VectorIcon />
      </div>
      <div className='delete-info'>
        <p>
          Your account has been marked as deleted and your data will be purged in 24 hours. You can
          undo the delete by clicking on the following button.
        </p>
      </div>
      <div style={{ marginLeft: 40, marginRight: 30 }}>
        <Zbutton secondary onClick={handleUndo}>
          Undo
        </Zbutton>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any };
}) => {
  return {
    userId: state.firebase.auth.uid,
  };
};

export default connect(
  mapStateToProps,
  // @ts-ignore
)(DeletedUser);

import React from 'react';

export default () => (
  <svg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.28516 16.207H6.09961C6.61133 16.1992 7.01367 16.0645 7.30664 15.8027C7.59961 15.541 7.74609 15.1875 7.74609 14.7422C7.74609 13.7422 7.24805 13.2422 6.25195 13.2422C5.7832 13.2422 5.4082 13.377 5.12695 13.6465C4.84961 13.9121 4.71094 14.2656 4.71094 14.707H3.62695C3.62695 14.0312 3.87305 13.4707 4.36523 13.0254C4.86133 12.5762 5.49023 12.3516 6.25195 12.3516C7.05664 12.3516 7.6875 12.5645 8.14453 12.9902C8.60156 13.416 8.83008 14.0078 8.83008 14.7656C8.83008 15.1367 8.70898 15.4961 8.4668 15.8438C8.22852 16.1914 7.90234 16.4512 7.48828 16.623C7.95703 16.7715 8.31836 17.0176 8.57227 17.3613C8.83008 17.7051 8.95898 18.125 8.95898 18.6211C8.95898 19.3867 8.70898 19.9941 8.20898 20.4434C7.70898 20.8926 7.05859 21.1172 6.25781 21.1172C5.45703 21.1172 4.80469 20.9004 4.30078 20.4668C3.80078 20.0332 3.55078 19.4609 3.55078 18.75H4.64062C4.64062 19.1992 4.78711 19.5586 5.08008 19.8281C5.37305 20.0977 5.76562 20.2324 6.25781 20.2324C6.78125 20.2324 7.18164 20.0957 7.45898 19.8223C7.73633 19.5488 7.875 19.1562 7.875 18.6445C7.875 18.1484 7.72266 17.7676 7.41797 17.502C7.11328 17.2363 6.67383 17.0996 6.09961 17.0918H5.28516V16.207ZM15.7969 17.3613C15.7969 18.6309 15.5801 19.5742 15.1465 20.1914C14.7129 20.8086 14.0352 21.1172 13.1133 21.1172C12.2031 21.1172 11.5293 20.8164 11.0918 20.2148C10.6543 19.6094 10.4277 18.707 10.4121 17.5078V16.0605C10.4121 14.8066 10.6289 13.875 11.0625 13.2656C11.4961 12.6562 12.1758 12.3516 13.1016 12.3516C14.0195 12.3516 14.6953 12.6465 15.1289 13.2363C15.5625 13.8223 15.7852 14.7285 15.7969 15.9551V17.3613ZM14.7129 15.8789C14.7129 14.9609 14.584 14.293 14.3262 13.875C14.0684 13.4531 13.6602 13.2422 13.1016 13.2422C12.5469 13.2422 12.1426 13.4512 11.8887 13.8691C11.6348 14.2871 11.5039 14.9297 11.4961 15.7969V17.5312C11.4961 18.4531 11.6289 19.1348 11.8945 19.5762C12.1641 20.0137 12.5703 20.2324 13.1133 20.2324C13.6484 20.2324 14.0449 20.0254 14.3027 19.6113C14.5645 19.1973 14.7012 18.5449 14.7129 17.6543V15.8789Z'
      fill='#333333'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M18 5.36577V0H20V9H11V7H16.7316C16.1795 6.28244 15.3736 5.22397 14.9508 4.75991C13.3323 2.98346 11.5912 2 9.5 2C4.39481 2 2 5.39719 2 10H0C0 4.41222 3.11035 0 9.5 0C12.2385 0 14.466 1.25818 16.4292 3.41293C16.8493 3.87402 17.5008 4.71193 18 5.36577Z'
      fill='#333333'
    />
  </svg>
);

import React from 'react';
import { IThread } from '../../../types/projectTypes';
import './thread.css';
import ThreadItem from './ThreadItem';

const ThreadList = ({
  threads,
  changeThread,
  loginUserEmail,
}: {
  threads: IThread[];
  changeThread: (thread: IThread) => void;
  loginUserEmail: string;
}) => {
  return (
    <div className='thread-list'>
      {threads.map((thread: IThread) => {
        return (
          <ThreadItem
            loginUserEmail={loginUserEmail}
            changeThread={changeThread}
            thread={thread}
            key={thread.id}
          />
        );
      })}
    </div>
  );
};

export default ThreadList;

import { Avatar, Popover } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IThread } from '../../../types/projectTypes';
import { getVideoDurationFormat } from '../../../utils';

import './style.css';

const SeekBar = (props: any) => {
  // const threads = useSelector((state: any) => state.canvas.filterThreads);
  const [isShowThreadPopoverIndex, setIsShowThreadPopoverIndex] = useState(-1);
  const {
    threads,
    bgcolor,
    playedPercentage,
    seekChange,
    bufferPercentage,
    id,
    duration,
    changeThread,
    isTheaterMode,
    changeVideoTime,
  } = props;
  const seekbarRef: any = [];
  seekbarRef[id] = React.useRef<HTMLDivElement>(null);
  const slickBarClick = (e: any) => {
    changeVideoTime && changeVideoTime();
    const offsetLeft =
      seekbarRef[id].current.getBoundingClientRect() &&
      seekbarRef[id].current.getBoundingClientRect().left;
    const left = e.pageX - offsetLeft;
    const totalWidth = seekbarRef[id].current.clientWidth;
    const percentage = (left / totalWidth) * 100;
    seekChange(percentage ? percentage.toFixed(2) : 0);
  };

  // const duration: number = 616;
  let threadsWithLeftPercentage: IThread[] = [];
  threadsWithLeftPercentage = threads.map((threadMarker: IThread) => {
    threadMarker.fromLeftPercentage = (threadMarker.mediaTime / duration) * 100;
    return threadMarker;
  });
  threadsWithLeftPercentage = threadsWithLeftPercentage.sort(
    (firstObj: IThread, secoundObj: IThread) => {
      const firstObjFromLeft = firstObj.fromLeftPercentage || 0;
      const secoundObjFromLeft = secoundObj.fromLeftPercentage || 0;
      return firstObjFromLeft - secoundObjFromLeft;
    },
  );
  const seekbarWidth = (window.innerWidth * 75) / 100 - 70;
  const threadMemberWidth = 20;
  const minPercentageGap = (threadMemberWidth / seekbarWidth) * 100;
  let seekbarThreads: IThread[] = [];
  const usedThreadIds: string[] = [];
  threadsWithLeftPercentage.forEach((parentTread, index) => {
    if (!usedThreadIds.includes(parentTread.id)) {
      const sameGroupArray: IThread[] = [];
      let isMultipleMembers = false;
      for (
        let childThredIndex = index;
        childThredIndex < threadsWithLeftPercentage.length;
        childThredIndex++
      ) {
        const childThread: IThread = threadsWithLeftPercentage[childThredIndex] || {};
        if (!usedThreadIds.includes(childThread.id)) {
          let diff = (childThread.fromLeftPercentage || 0) - (parentTread.fromLeftPercentage || 0);
          if (diff < 0) {
            diff = diff * -1;
          }
          if (diff <= minPercentageGap) {
            sameGroupArray.push(childThread);
            usedThreadIds.push(childThread.id);
            isMultipleMembers = true;
          }
        }
      }

      if (isMultipleMembers) {
        const popoverArray: any = [sameGroupArray];
        seekbarThreads = seekbarThreads.concat(popoverArray);
      } else {
        usedThreadIds.push(parentTread.id);
        const memberArray: any = [parentTread];
        seekbarThreads = seekbarThreads.concat([memberArray]);
      }
    }
  });

  const getPopoverMembers = (threadMarkerArray: IThread[]) => {
    return threadMarkerArray.map((threadMarker: IThread) => {
      const { member, mediaTime } = threadMarker;
      const { name, image } = member;
      return (
        <div
          className='thread-popover-member cursor-pointer'
          onClick={() => {
            changeThread && changeThread(threadMarker);
          }}
        >
          {image ? (
            <Avatar className='thread-avtar' src={image} />
          ) : (
            <Avatar>{name && name[0] && name[0].toUpperCase()}</Avatar>
          )}
          <span className='thread-popover-media-time'>{getVideoDurationFormat(mediaTime)}</span>
        </div>
      );
    });
  };
  return (
    <div
      ref={seekbarRef[id]}
      className={`seekbar-container ${duration ? 'pointer' : ''}`}
      onClick={slickBarClick}
    >
      <div
        className='seekbar-progress'
        style={{
          width: `${bufferPercentage}%`,
          backgroundColor: '#e0e0de',
        }}
      ></div>
      <div
        className='seekbar-progress seekbar-played-progress'
        style={{
          width: `${playedPercentage}%`,
          backgroundColor: bgcolor,
        }}
      >
        {/* <span className="seekbar-label">{`${completed}%`}</span> */}
      </div>

      <div style={{ display: 'flex' }}>
        {!isTheaterMode &&
          seekbarThreads.map((threadMarkerArray: any, index: number) => {
            if (threadMarkerArray.length > 1) {
              const threadMarker: IThread = threadMarkerArray[0];
              const fromLeftPercentage = (threadMarker.mediaTime / duration) * 100;
              return (
                <Popover
                  content={
                    <div className='thread-popover-container'>
                      {getPopoverMembers(threadMarkerArray)}
                    </div>
                  }
                  title=''
                  trigger='hover'
                  onVisibleChange={(visible: boolean) => {
                    !visible && setIsShowThreadPopoverIndex(-1);
                  }}
                >
                  <Popover
                    content={
                      <div className='thread-popover-container'>
                        {getPopoverMembers(threadMarkerArray)}
                      </div>
                    }
                    title=''
                    trigger='click'
                    visible={isShowThreadPopoverIndex === index}
                    onVisibleChange={() => {
                      setIsShowThreadPopoverIndex(isShowThreadPopoverIndex === index ? -1 : index);
                    }}
                  >
                    <span
                      className='seek-thread-avtar seek-thread-multiple'
                      style={{ left: `${fromLeftPercentage}%` }}
                    >
                      {<Avatar>+{threadMarkerArray.length}</Avatar>}
                    </span>
                  </Popover>
                </Popover>
              );
            } else {
              const threadMarker: IThread = threadMarkerArray[0];
              const { member } = threadMarker;
              const { name, image } = member;
              const fromLeftPercentage = (threadMarker.mediaTime / duration) * 100;
              return (
                <span
                  key={index}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeThread && changeThread(threadMarker);
                  }}
                  className='seek-thread-avtar'
                  style={{ left: `${fromLeftPercentage}%` }}
                >
                  {image ? (
                    <Avatar className='thread-avtar' src={image} />
                  ) : (
                    <Avatar>{name && name[0] && name[0].toUpperCase()}</Avatar>
                  )}
                </span>
              );
            }
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    threads: state.canvas.filterThreads,
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    null,
  ),
)(SeekBar);

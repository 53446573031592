import { Drawer, Modal, notification, Switch, Tooltip } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { eventConstant } from '../../constant';
import { addDrawing, changeReadBy } from '../../store/actions/pageActions';
import {
  addFeedback,
  disableUndoRedo,
  openCommentWindow as openCommentWindowAction,
  saveIframe,
} from '../../store/actions/projectActions';
import { getQueryStringValue, mobileCheck, sendMessageToIframe, trackAnalytics } from '../../utils';
import FeedbackModel from '../projects/FeedbackModel';
import { AlertModal } from '../shared/AlertModal';
import './index.css';
import RenderComments from './RenderComments';

const isMobile = mobileCheck();

interface IProps {
  height: any;
  width: any;
  match: any;
  isWriteAccess: boolean;
  history: any;
  iframe: any;
  url: string;
  projectId: string;
  auth: { uid: never };
  toggleDrawing: boolean;
  project?: any;
  pages?: Array<{
    feedback: Array<{
      circle_pointer: {
        id: string;
        oldWindowHeight: number;
        oldWindowWidth: number;
        pagePath: string;
      };
      readBy: [];
      resolved: boolean;
    }>;
    project_id: string;
    id: string;
  }>;
  changeReadByUsers: (data: any, pages: any) => void;
  saveIframe?: (iframe: any) => void;
  addFeedbackProps?: (feedback: any, projectId: string) => Promise<any>;
  drawerVisible: boolean;
  setDrawerVisible?: (drawerVisible: boolean) => void;
  addDrawingAction: (drawingJson: any, data: any, pageId: string) => Promise<any>;
  setKeyboardEvent: (keyboardEvent: string) => void;
  disableUndoRedo: (data: any) => void;
  persistCommentWindow: boolean;
  openCommentWindow: any;
  addComment: any;
  location: any;
  strokeColor: any;
  pageScale?: any;
  type?: any;
  page?: any;
}

interface IState {
  loaded: boolean;
  inputValue: string;
  chatWindow: {
    top: number;
    left: number;
    visible: boolean;
  };
  circlePointerID: string;
  direction: string;
  resolved: boolean;
  pageId: string;
  showInfoModal: boolean;
  showCommentingModeAlertModal: boolean;
  pagePath: string;
  selectedCommentFilter: string;
  searchText: string;
  currentShowingMessageid: string;
  allPagePath: string[];
  selectedPagePath: string;
  pages?: Array<{
    feedback: Array<{ circle_pointer: { id: string }; readBy: []; resolved: boolean }>;
    project_id: string;
    id: string;
  }>;
  persistCommentWindow: boolean;
  strokeColor: string;
  searchParam: string;
  iframeWidth?: number;
  iframeHeight?: number;
  pageScale?: number;
}
class Iframe extends Component<IProps, IState> {
  wrapperRef: any;
  iframe: any;
  searchInput: any;
  isAddingPoint: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      loaded: false,
      inputValue: 'https://www.actioned.com/',
      chatWindow: {
        top: 0,
        left: 0,
        visible: false,
      },
      circlePointerID: '',
      direction: 'left',
      resolved: false,
      pageId: '',
      showInfoModal: false,
      pages: [],
      showCommentingModeAlertModal: false,
      selectedCommentFilter: 'active',
      pagePath: getQueryStringValue('page'),
      searchText: '',
      currentShowingMessageid: '',
      allPagePath: [],
      selectedPagePath: 'All',
      persistCommentWindow: false,
      strokeColor: '#001DAD',
      searchParam: '',
      iframeWidth: 0,
      iframeHeight: 0,
      pageScale: 1,
    };
    this.iframe = React.createRef();
    this.searchInput = React.createRef();
    window.addEventListener('message', this.eventHandler, false);
    this.isAddingPoint = false;
    // sendMessageToIframe(this.props.iframe, eventConstant.HIDE_CHAT_WINDOW, {});
  }

  UNSAFE_componentWillReceiveProps(nextprops: any) {
    // const { location = {} } = nextprops;
    const { pages, auth, page, height, width, pageScale } = nextprops;
    const pageUrlParams = new URLSearchParams(window.location.search);
    const threadId: any = pageUrlParams.get('threadId');
    if (
      nextprops &&
      nextprops.type === 'pdf' &&
      (nextprops.width !== this.state.iframeWidth ||
        nextprops.height !== this.state.iframeHeight ||
        nextprops.pageScale !== this.state.pageScale)
    ) {
      let authId: any = auth.uid;
      if (!authId) {
        authId = localStorage.getItem('userDetails');
        authId = authId && JSON.parse(authId).uid;
      }
      const store = {
        pages: nextprops.pages,
        auth: authId,
        pagePath: page,
        width,
        height,
        pageScale,
      };
      (this.state.iframeWidth || width) && this.syncWithIframe(store, eventConstant.RE_INIT);
      this.setState({
        iframeWidth: width,
        iframeHeight: height,
      });
    }

    if (threadId && this.state.searchParam !== threadId) {
      sendMessageToIframe(nextprops.iframe, eventConstant.SHOW_ACTIVE_CHAT_POINT, {
        threadId,
      });
      this.setState({
        searchParam: threadId,
      });
    }
    if (!threadId) {
      sendMessageToIframe(nextprops.iframe, eventConstant.HIDE_ACTIVE_CHAT_POINT, {});
    }
    const { pagePath, persistCommentWindow, strokeColor } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const pageValue =
      nextprops.match.path === '/c/:id' ? urlParams.get('threadId') : urlParams.get('page');
    if (
      nextprops.match.path === '/pdf/:id' &&
      nextprops &&
      nextprops.page &&
      nextprops.page !== pagePath
    ) {
      let authId: any = auth.uid;
      if (!authId) {
        authId = localStorage.getItem('userDetails');
        authId = authId && JSON.parse(authId).uid;
      }
      const store = { pages, auth: authId, pagePath: nextprops.page, width, height, pageScale };
      this.syncWithIframe(store, eventConstant.RE_INIT);
      this.setState({
        pagePath: nextprops.page,
      });
    }

    if (nextprops.match.path === '/c/:id' && pageValue !== pagePath) {
      let authId: any = auth.uid;
      if (!authId) {
        authId = localStorage.getItem('userDetails');
        authId = authId && JSON.parse(authId).uid;
      }
      const store = { pages, auth: authId, pagePath: pageValue };
      this.syncWithIframe(store, eventConstant.RE_INIT);
      this.setState({
        pagePath: pageValue ? pageValue : '',
      });
    }

    if (persistCommentWindow !== nextprops.persistCommentWindow) {
      this.windowResize();
      this.setState({
        persistCommentWindow: nextprops.persistCommentWindow,
      });
    }
    if (nextprops.strokeColor !== strokeColor) {
      const authId: any = auth.uid;
      const store = { pages, auth: authId, pagePath, strokeColor: nextprops.strokeColor };
      this.syncWithIframe(store, eventConstant.SYNC_STROKE_COLOR);
      this.setState({
        strokeColor: nextprops.strokeColor,
      });
    }
    if (JSON.stringify(nextprops.pages) !== JSON.stringify(this.state.pages)) {
      // const { pages, auth } = nextprops;
      let authId: any = nextprops.auth.uid;
      if (!authId) {
        authId = localStorage.getItem('userDetails');
        authId = authId && JSON.parse(authId).uid;
      }
      const store = { pages: nextprops.pages, auth: authId, pagePath };
      this.syncWithIframe(store, eventConstant.SYNC_INIT);

      const feedbackData: any = nextprops.pages || [{ feedback: [] }];
      const feedbacks = (feedbackData && feedbackData[0] && feedbackData[0].feedback) || [];
      const allPagePath: string[] = ['All'];
      feedbacks.forEach((feedback: any) => {
        if (feedback.circle_pointer) {
          const circlePagePath: string = feedback.circle_pointer.pagePath
            ? feedback.circle_pointer.pagePath
            : '/';
          if (allPagePath.indexOf(circlePagePath) === -1) {
            allPagePath.push(circlePagePath);
          }
        }
      });
      this.setState({
        pages: nextprops.pages,
        allPagePath,
      });
    }
  }

  openChat = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const notificationCircleId = urlParams.get('circleId');
    const pagePath = urlParams.get('page');
    // const { pages } = this.props;
    // let pageId = '';

    if (notificationCircleId) {
      // let data: any = {};
      // if (pages && pages[0]) {
      //   pages.forEach((page: any) => {
      //     const { feedback } = page;
      //     feedback.forEach((feeds: any) => {
      //       if (feeds.circle_pointer.id === notificationCircleId) {
      // data = feeds.circle_pointer;
      // pageId = page.id;
      //       }
      //     });
      //   });
      // }
      // if (pageId) {
      //   const event = eventConstant.SCROLL_WINDOW_TO_FEEDBACK_POINT;
      //   sendMessageToIframe(this.props.iframe, event, {
      //     id: data.id,
      //     pageId,
      //     path: data.path,
      //     x: data.x,
      //     y: data.y,
      //     threadId: data.threadId,
      //     windowSize: window.innerWidth,
      //   });
      // }
      setTimeout(() => {
        const search = window.location.search;
        let pathname = window.location.pathname;
        if (search.includes('recalc')) {
          pathname = pathname + '?recalc=true';
        }
        pathname = `${pathname}${pathname.includes('?') ? '&' : '?'}page=${pagePath}`;
        window.history.pushState({}, document.title, pathname);
      }, 0);
    }
    //  document.addEventListener('mousedown', this.handleClickOutside);
    // if the pages are already loaded from the API load our html4
    if (this.props.pages) {
      //  this.loadHtml();
    }
  };

  componentDidUpdate(prevProps: IProps) {
    // load html after pages are loaded from
    if (this.props.pages && !prevProps.pages) {
      this.loadHtml();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    // load html after initial pages loaded
    if (this.props.pages) {
      this.loadHtml();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.windowResize);
    window.removeEventListener('message', this.eventHandler);
  }

  windowResize = () => {
    const { pages, auth } = this.props;
    const { pagePath } = this.state;
    let authId: any = auth.uid;
    if (!authId) {
      authId = localStorage.getItem('userDetails');
      authId = authId && JSON.parse(authId).uid;
    }
    const store = { pages, auth: authId, pagePath };
    this.syncWithIframe(store, eventConstant.RE_INIT);
  };

  // Set the wrapper ref
  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  // Call if clicked on outside of element
  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeChatWindow();
    }
  };

  closeChatWindow = () => {
    this.iframe.current.style.pointerEvents = 'auto';
    this.setState({
      chatWindow: {
        top: 0,
        left: 0,
        visible: false,
      },
      currentShowingMessageid: '',
    });
    sendMessageToIframe(this.props.iframe, eventConstant.HIDE_CHAT_WINDOW, {});
  };

  setPointerEvent = (value: any) => {
    this.iframe.current.style.pointerEvents = value;
  };

  handleCommentingModeAlertModalOk = () => {
    this.setState({
      showCommentingModeAlertModal: false,
    });
  };

  onDrawerClose = () => {
    const { setDrawerVisible } = this.props;
    setDrawerVisible && setDrawerVisible(false);
  };

  openFeedback = (pagePath: string, circleId: string, commentId: string) => {
    const currentPagePath =
      this.state.pagePath && this.state.pagePath !== 'null' ? this.state.pagePath : '';
    const { setDrawerVisible } = this.props;
    setDrawerVisible && setDrawerVisible(false);
    this.setState(
      {
        pagePath,
        currentShowingMessageid: commentId,
        chatWindow: {
          top: 0,
          left: 0,
          visible: false,
        },
      },
      () => {
        let pathname = window.location.pathname;
        const search = window.location.search;
        if (search.includes('recalc')) {
          pathname = pathname + '?recalc=true';
        }
        const projectURL = `${pathname}${
          pathname.includes('?') ? '&' : '?'
        }circleId=${circleId}&commentId=${commentId}${pagePath ? `&page=${pagePath}` : ''}`;
        this.props.history.push(projectURL);

        if (currentPagePath !== pagePath) {
          this.loadHtml();
        } else {
          sendMessageToIframe(this.props.iframe, eventConstant.SHOW_CHAT_WINDOW, {});
          this.openChat();
        }
      },
    );
  };

  closeChatOnFilter = () => {
    this.setState({
      chatWindow: {
        top: 0,
        left: 0,
        visible: false,
      },
      currentShowingMessageid: '',
    });
  };

  clearSearch = () => {
    this.setState({
      currentShowingMessageid: '',
    });
  };

  clearShowingMessageid = () => {
    this.setState({
      currentShowingMessageid: '',
    });
  };
  render() {
    const { pages, drawerVisible, toggleDrawing, isWriteAccess, persistCommentWindow } = this.props;
    const {
      circlePointerID,
      pageId,
      showCommentingModeAlertModal,
      currentShowingMessageid,
    } = this.state;
    const projectID = pages && pages.length !== 0 ? pages[0].project_id : '';
    const pageID = pageId;
    const chatWindowWidth = 352;
    const isSpaceFromLeft = this.state.chatWindow.left < window.innerWidth - chatWindowWidth;
    const chatWindowStyle: any = {
      position: 'absolute',
      top: this.state.chatWindow.top,
      overflow: 'auto',
      zIndex: 1000,
    };
    if (isSpaceFromLeft) {
      chatWindowStyle.left = this.state.chatWindow.left;
    } else {
      chatWindowStyle.right = window.innerWidth - this.state.chatWindow.left;
    }
    if (mobileCheck()) {
      chatWindowStyle.right = 0;
      chatWindowStyle.top = 0;
      chatWindowStyle.left = 0;
    }

    const sampleChat = this.state.chatWindow.visible && (
      <div
        ref={this.setWrapperRef}
        style={chatWindowStyle}
        onMouseEnter={() => this.setPointerEvent('none')}
        onMouseLeave={() => this.setPointerEvent('auto')}
      >
        <div id='viewport'>
          <div
            className={'feedbackbox ' + `${isMobile ? 'chat-mobile' : ''}`}
            style={{ zIndex: 10, marginRight: '40px' }}
          >
            {' '}
            <FeedbackModel
              isWriteAccess={isWriteAccess}
              projectID={projectID}
              pageID={pageID}
              circlePointerID={circlePointerID}
              onClose={this.closeChatWindow}
              direction={this.state.direction}
              resolved={this.state.resolved}
              currentShowingMessageid={currentShowingMessageid}
              clearShowingMessageid={this.clearShowingMessageid}
            />
          </div>
        </div>
      </div>
    );
    const feedbackData: any = this.props.pages || [{ feedback: [] }];
    const whiteBoardMode = this.props.url === 'https://zooc.io/whiteboard/';
    const { auth, project, iframe } = this.props;

    const { chatWindow, allPagePath } = this.state;

    return (
      <div
        className={`card-content ${
          toggleDrawing || whiteBoardMode ? 'frame-wrapper' : 'full-width'
        }`}
      >
        <div className='App'>
          <Drawer
            title={
              <div>
                Comments
                <Tooltip title={persistCommentWindow ? 'Unpin' : 'Pin'}>
                  <Switch
                    size='small'
                    style={{ position: 'absolute', top: '20px', right: '20px' }}
                    checked={persistCommentWindow}
                    onChange={() => {
                      this.props.openCommentWindow(!persistCommentWindow);
                      this.onDrawerClose();
                    }}
                  />
                </Tooltip>
              </div>
            }
            placement='right'
            closable={false}
            mask={!persistCommentWindow}
            onClose={this.onDrawerClose}
            visible={drawerVisible || persistCommentWindow}
            className={'sidebar-comment-drawer'}
          >
            <RenderComments
              feedbackData={feedbackData}
              project={project}
              chatWindow={chatWindow}
              allPagePath={allPagePath}
              iframe={iframe}
              currentShowingMessageid={currentShowingMessageid}
              closeChatOnFilter={this.closeChatOnFilter}
              openFeedback={this.openFeedback}
              clearSearch={this.clearSearch}
              onDrawerClose={this.onDrawerClose}
              auth={auth}
            />
          </Drawer>
          <AlertModal
            title={'You are in commenting mode!'}
            message={
              "Currently, you are in commenting mode, you can't go on subpages, If you want to go in subpages then please change the mode from commenting to browse mode."
            }
            visible={showCommentingModeAlertModal}
            handleOk={this.handleCommentingModeAlertModalOk}
          />
          {sampleChat}
          {this.state.loaded ? null : <div className='gif-loader' />}
          <div
            className={
              this.state.loaded &&
              (toggleDrawing || whiteBoardMode) &&
              this.props.match.path !== '/pdf/:id'
                ? 'frame-container'
                : ''
            }
            id={
              this.props.height
                ? 'pdfIframeContainer'
                : toggleDrawing || whiteBoardMode
                ? 'iframeContainerDraw'
                : 'iframeContainer'
            }
            ref='iframeContainer'
          >
            {this.props.height ? (
              <iframe
                title='FeedbackHero'
                id='iframe'
                ref={this.iframe}
                width={`${this.props.width}px`}
                height={`${this.props.height}px`}
                style={{ border: 0 }}
              />
            ) : (
              <iframe title='FeedbackHero' id='iframe' ref={this.iframe} style={{ border: 0 }} />
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   * @param data local storage data
   */
  syncWithIframe(data: any, event: string) {
    if (this.iframe.current) {
      // let isImageTypeProject = checkImageURL(this.props.url);
      const el = this.iframe.current.contentWindow;
      data.isImageTypeProject = getQueryStringValue('recalc') || this.props.type === 'pdf';
      const selectedCommentFilter = this.state.selectedCommentFilter;
      data.selectedCommentFilter = selectedCommentFilter;
      sendMessageToIframe(this.props.iframe, event, {});
      el.postMessage({ event, data }, '*');
    }
  }

  eventHandler = (e: any) => {
    const eventData = e.data;
    const event = eventData.event;
    const { projectId, addDrawingAction }: any = this.props;
    switch (event) {
      case eventConstant.CHANGE_COMMENT_FILTER_TO_ALL:
        this.setState({
          selectedCommentFilter: 'all',
        });
        break;
      case eventConstant.CHANGE_COMMENT_FILTER_TO_ACTIVE:
        this.setState({
          selectedCommentFilter: 'active',
        });
        break;
      case eventConstant.LINK_DISABLED_IN_COMMENTRY_MODE:
        this.setState({
          showCommentingModeAlertModal: true,
        });
        break;
      case eventConstant.LOADED_IFRAME:
        if (this.props.match.path === '/c/:id') {
          const eventTemp = eventConstant.TOGGLE_DRAWING;
          sendMessageToIframe(this.props.iframe, eventTemp, {
            drawingType: 'freeDraw',
          });
        }

        const pageUrlParams = new URLSearchParams(window.location.search);
        const threadId: any = pageUrlParams.get('threadId');
        if (threadId) {
          sendMessageToIframe(this.props.iframe, eventConstant.SHOW_ACTIVE_CHAT_POINT, {
            threadId,
          });
          this.setState({
            searchParam: threadId,
          });
        }

        const selectedCommentFilter = this.state.selectedCommentFilter;
        let currentSelectedFilterEvent = '';
        switch (selectedCommentFilter) {
          case 'resolved':
            currentSelectedFilterEvent = eventConstant.SHOW_RESOLVED_FEEDBACK;
            break;
          case 'active':
            currentSelectedFilterEvent = eventConstant.SHOW_ACTIVE_FEEDBACK;
            break;
          case 'all':
            currentSelectedFilterEvent = eventConstant.SHOW_ALL_FEEDBACK;
            break;
        }
        sendMessageToIframe(this.props.iframe, currentSelectedFilterEvent, {});
        this.openChat();
        break;
      case eventConstant.ADD_POINT:
        this.addPoint(eventData.data);
        break;
      case eventConstant.ADD_KONVA_OBJECT:
        if (eventData.data.pageId) {
          const pageUrlParamsValue = new URLSearchParams(window.location.search);
          // const pageUrlPathPath = pageUrlParams.get('page');
          const pageUrlPathPath =
            this.props.match.path === '/c/:id'
              ? pageUrlParamsValue.get('threadId')
              : pageUrlParamsValue.get('page');
          addDrawingAction(
            eventData.data.json,
            { project_id: projectId, pagePath: pageUrlPathPath ? pageUrlPathPath : '' },
            eventData.data.pageId,
          );
        } else {
          this.addPoint(eventData.data);
        }
        break;

      case eventConstant.MOUSE_EVENT_TRIGGER:
        const { setKeyboardEvent } = this.props;
        setKeyboardEvent(eventData.data.type);
        break;

      case eventConstant.POINT_CLICK:
        this.pointClicked(eventData.data);
        break;
      case eventConstant.SET_IFRAME_HEIGHT: {
        this.loaded();
        break;
      }
      case eventConstant.IFRAME_SCROLLED:
        this.setState({
          chatWindow: {
            top: 0,
            left: 0,
            visible: false,
          },
        });
        sendMessageToIframe(this.props.iframe, eventConstant.HIDE_CHAT_WINDOW, {});
        break;
      case eventConstant.LOADED_PAGE:
        const store = {
          pages: this.props.pages,
          auth: this.props.auth.uid,
          pagePath: this.props.page,
          width: this.props.width,
          height: this.props.height,
          pageScale: this.props.pageScale,
        };
        this.syncWithIframe(store, eventConstant.RE_INIT);

        const loadedPage = eventData.data;
        this.setState({
          pagePath: loadedPage ? loadedPage : '',
          loaded: true,
        });
        let pathname = window.location.pathname;
        const search = window.location.search;
        if (search.includes('recalc')) {
          pathname = pathname + '?recalc=true';
        }
        pathname = `${pathname}${pathname.includes('?') ? '&' : '?'}page=${loadedPage}`;
        window.history.pushState({}, document.title, pathname);

        break;
      case eventConstant.ADD_FEEDBACK:
        this.closeChatWindow();
        break;
      case eventConstant.SHOULD_DISABLE_UNDO_REDO:
        this.props.disableUndoRedo(eventData.data);
        break;
      default:
    }
  };
  loaded = () => {
    this.setState({ loaded: true });
  };
  addPoint = async (data: any) => {
    const { addFeedbackProps, projectId, auth, addComment } = this.props;

    if (!auth.uid) {
      Modal.confirm({
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        title: 'Please Sign Up to continue!',
        onOk: () => {
          this.props.history.push('/signup');
        },
      });
    } else {
      const { pagePath } = this.state;
      data.pagePath = pagePath;
      if (!auth.uid && !localStorage.getItem('userDetails')) {
        this.setState({
          showInfoModal: true,
        });
      }
      // to prevent multiple points
      if (this.isAddingPoint) {
        return;
      }
      const inputField = document.getElementById('resizable-input');
      inputField && inputField.focus();
      this.isAddingPoint = true;
      const urlParams = new URLSearchParams(window.location.search);
      const videoThreadId = urlParams.get('threadId');
      const pageParam = urlParams.get('page');
      const threadId = this.props.match.path === '/c/:id' ? videoThreadId : await addComment();

      data.threadId = threadId;
      data.id = threadId;
      if (addFeedbackProps) {
        trackAnalytics('Canvas Detail', 'Added feedback Point');
        const value = await addFeedbackProps(data, projectId);
        if (
          value &&
          value.id &&
          (this.props.match.path === '/c/:id' || this.props.match.path === '/pdf/:id')
        ) {
          this.props.addDrawingAction(
            data.json,
            {
              annotationPageWidth: this.props.width,
              annotationPageHeight: this.props.height,
              project_id: projectId,
              pagePath: threadId,
            },
            value.id,
          );
        }
        this.isAddingPoint = false;
        // if (threadId && !value) {
        //   this.syncWithIframe({ threadId }, eventConstant.SYNC_THREAD_ID);
        // }
        if (value) {
          const store = {
            pages: this.props.pages,
            auth: auth.uid,
            pageID: value.id,
            pagePath,
            threadId,
          };
          this.syncWithIframe(store, eventConstant.SYNC_PAGE_ID);
          //  this.syncWithIframe(store, eventConstant.RE_INIT);
        }
        let urlPath = this.props.location.pathname + '?threadId=' + threadId;
        if (pageParam) {
          urlPath = `${urlPath}&page=${pageParam}`;
        }
        this.props.history.push(urlPath);
      }
    }
  };

  // addFirstKonvaPoint = async (data: any) => {
  //   const { addFeedbackProps, projectId, auth } = this.props;
  //   const { pagePath } = this.state;
  //   data.pagePath = pagePath;
  //   if (!auth.uid && !localStorage.getItem('userDetails')) {
  //     this.setState({
  //       showInfoModal: true,
  //     });
  //   }
  //   if (addFeedbackProps) {
  //     const value = await addFeedbackProps(data, projectId);
  //     if (value) {
  //       const store = { pageID: value.id, pagePath };
  //       return this.syncWithIframe(store, eventConstant.SYNC_PAGE_ID);
  //     }
  //   }
  // };

  pointClicked = (data: any) => {
    if (!data.threadId) {
      return;
    }
    let chatTop = data && data.global && data.global.top && parseInt(data.global.top, 10);
    let chatLeft = data && data.global && data.global.left && parseInt(data.global.left, 10);
    const chatScrollTop = data && data.scrollTop && parseInt(data.scrollTop, 10);
    const { pages, auth } = this.props;
    const { currentWindowHeight, currentWindowWidth } = data;
    let oldWindowHeight: number = 0;
    let oldWindowWidth: number = 0;
    if (pages && pages[0]) {
      const { feedback } = pages[0];
      feedback.forEach((feeds) => {
        if (feeds.circle_pointer.id === data.id) {
          oldWindowHeight = (feeds.circle_pointer && feeds.circle_pointer.oldWindowHeight) || 0;
          oldWindowWidth = (feeds.circle_pointer && feeds.circle_pointer.oldWindowWidth) || 0;
          if (!feeds.readBy || !feeds.readBy.includes(auth.uid)) {
            this.props.changeReadByUsers(feeds, pages);
          }
        }
      });
    }
    const isImageTypeProject = getQueryStringValue('recalc') || this.props.type === 'pdf';
    if (isImageTypeProject && oldWindowWidth && oldWindowHeight) {
      const circleRadius = 30;
      chatTop = chatTop - circleRadius / 2;
      chatLeft = chatLeft - circleRadius / 2;
      chatTop = (chatTop * currentWindowHeight) / oldWindowHeight;
      chatLeft = (chatLeft * currentWindowWidth) / oldWindowWidth;
    }
    chatTop = chatTop - chatScrollTop + 66;
    // if Add New Feedback opens near the Bottom.
    // 410 is the popsize we can define that in config
    const screeHeight = window.screen.availHeight;
    if (chatTop >= screeHeight / 2) {
      chatTop = chatTop - 430;
    }
    const { persistCommentWindow, history } = this.props;
    const windowWidht = persistCommentWindow ? window.innerWidth - 256 : window.innerWidth;
    const iframeWidth = this.iframe.current.clientWidth;
    const windowEmptySpace = (windowWidht - iframeWidth) / 2;
    this.setState({
      chatWindow: {
        top: chatTop,
        left: chatLeft + windowEmptySpace,
        // visible: true,
        visible: false,
      },
      circlePointerID: data.id,
      direction: data.direction,
      resolved: data.resolved,
      pageId: data.pageId,
    });
    const inputField = document.getElementById('resizable-input');
    inputField && inputField.focus();
    let urlPath = this.props.location.pathname + '?threadId=' + data.threadId;
    const urlParams = new URLSearchParams(window.location.search);
    const pageParam = urlParams.get('page');
    if (pageParam) {
      urlPath = `${urlPath}&page=${pageParam}`;
    }
    history.push(urlPath);
    sendMessageToIframe(this.props.iframe, eventConstant.SHOW_CHAT_WINDOW, {});
    trackAnalytics('Canvas Detail', 'Clicked Point');
    // scroll to point when clicked on point.
    const event = eventConstant.SCROLL_WINDOW_TO_FEEDBACK_POINT;
    sendMessageToIframe(this.props.iframe, event, {
      id: data.id,
      path: data.path,
      x: data.x,
      y: data.y,
      threadId: data.threadId,
      windowSize: window.innerWidth,
    });
  };

  loadWithProxy1 = (url: string) => {
    const SERVER = process.env.REACT_APP_PROXY_SERVER;
    const data = {
      url,
    };
    const { pagePath } = this.state;
    fetch(`${SERVER} / generate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((result) => result.json())
      .then((result: any) => {
        this.iframe.current.src = SERVER + result.key;
        this.iframe.current.onload = () => {
          const { pages, auth, project } = this.props;
          let authId: any = auth.uid;
          if (!authId) {
            authId = localStorage.getItem('userDetails');
            authId = authId && JSON.parse(authId).uid;
          }
          const store = {
            pages,
            auth: authId,
            pagePath,
            projectType: project && project.type,
          };
          this.syncWithIframe(store, eventConstant.INIT);
        };
        this.props.saveIframe && this.props.saveIframe(this.iframe.current);
      });
  };

  public loadWithProxy2 = (url: string) => {
    const SERVER = 'https://localhost:4500';
    // const SERVER = 'https://feedbackhero-proxy-interface.herokuapp.com';
    this.iframe.current.src = SERVER + '/proxy2?url=' + encodeURIComponent(url);
    this.iframe.current.onload = () => {
      const { pages, auth, project } = this.props;
      const { pagePath } = this.state;
      let authId: any = auth.uid;
      if (!authId) {
        authId = localStorage.getItem('userDetails');
        authId = authId && JSON.parse(authId).uid;
      }
      const store = {
        pages,
        auth: authId,
        pagePath,
        projectType: project && project.type,
      };
      this.syncWithIframe(store, eventConstant.INIT);
    };
    this.props.saveIframe && this.props.saveIframe(this.iframe.current);
  };

  public loadWithProxy4 = (url: string) => {
    // please remove localhost here
    const SERVER = process.env.REACT_APP_PROXY_SERVER;
    let { pagePath } = this.state;
    fetch(`${SERVER}/open?link=${url}`)
      .then((result) => result.json())
      .then((result: any) => {
        if (result && result.status) {
          const host = result.host;
          if (
            typeof pagePath === 'string' &&
            pagePath &&
            pagePath.charAt(0) === '/' &&
            host.charAt(host.length - 1) === '/'
          ) {
            pagePath = pagePath.substr(1);
          } else {
            pagePath = pagePath || '';
          }
          if (this.iframe && this.iframe.current) {
            if(this.props.match.path === '/pdf/:id'){
              this.iframe.current.src = `${host}`;
            }else{
              this.iframe.current.src = `${host}${pagePath}`;
            }
            this.iframe.current.onload = () => {
              const { pages, auth, project } = this.props;
              let authId: any = auth.uid;
              if (!authId) {
                authId = localStorage.getItem('userDetails');
                authId = authId && JSON.parse(authId).uid;
              }
              const currentUrlParams = new URLSearchParams(window.location.search);
              const currentPagePath =
                this.props.match.path === '/c/:id'
                  ? currentUrlParams.get('threadId')
                  : currentUrlParams.get('page');
              const store: any = {
                pages,
                auth: authId,
                pagePath: currentPagePath,
                projectType: project && project.type,
              };
              this.setState({
                pagePath: currentPagePath ? currentPagePath : '',
              });
              setTimeout(() => {
                this.syncWithIframe(store, eventConstant.INIT);
              }, 1000);
            };
          }
          this.props.saveIframe && this.props.saveIframe(this.iframe.current);
        } else {
          notification.error({
            message: result.message ? result.message : 'Something went wrong.',
          });
          this.setState({
            loaded: true,
          });
          return false;
        }
      });
  };

  loadHtml = () => {
    // use conditions to decode which proxy to use
    // this.loadWithProxy1(this.props.url);
    // this.loadWithProxy2(this.props.url);
    this.loadWithProxy4(this.props.url);
  };
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    pages: state.firestore.ordered.pages,
    persistCommentWindow: state.project.persistCommentWindow,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    changeReadByUsers: (data: any, pages: any) => dispatch(changeReadBy(data, pages)),
    saveIframe: (iframe: any) => dispatch(saveIframe(iframe)),
    addFeedbackProps: (feedback: any, projectId: string) =>
      dispatch(addFeedback(feedback, projectId)),
    addDrawingAction: (drawingJson: any, data: any, pageId: string) =>
      dispatch(addDrawing(drawingJson, data, pageId)),
    disableUndoRedo: (data: any) => dispatch(disableUndoRedo(data)),
    openCommentWindow: (isOpenChatWindow: string) =>
      dispatch(openCommentWindowAction(isOpenChatWindow)),
  };
};

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  firestoreConnect((props: any) => {
    return [
      {
        collection: 'pages',
        where: [['project_id', '==', props.projectId]],
      },
    ];
  }),
)(Iframe);

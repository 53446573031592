import React from 'react';

export default () => (
  <svg width='33' height='30' viewBox='0 0 33 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21.41 22.25C21.1914 22.2529 20.9779 22.1841 20.8021 22.0541C20.6263 21.9241 20.498 21.7401 20.4367 21.5303C20.3755 21.3204 20.3847 21.0962 20.4629 20.8921C20.5412 20.688 20.6842 20.5151 20.87 20.4C21.78 19.832 22.5273 19.038 23.0392 18.0954C23.551 17.1528 23.81 16.0936 23.7909 15.0212C23.7718 13.9487 23.4752 12.8995 22.93 11.9757C22.3848 11.0519 21.6097 10.2851 20.68 9.74998C20.5605 9.68746 20.4549 9.60142 20.3695 9.49702C20.2841 9.39263 20.2207 9.27204 20.1832 9.14251C20.1456 9.01298 20.1346 8.87719 20.1509 8.74331C20.1671 8.60942 20.2103 8.48021 20.2778 8.36345C20.3453 8.24668 20.4357 8.14478 20.5436 8.06386C20.6515 7.98294 20.7746 7.92469 20.9056 7.8926C21.0366 7.86052 21.1728 7.85526 21.3059 7.87715C21.4389 7.89904 21.5662 7.94762 21.68 8.01998C22.9039 8.72967 23.9237 9.7436 24.6404 10.9634C25.3571 12.1833 25.7463 13.5676 25.7705 14.9822C25.7946 16.3968 25.4528 17.7936 24.7782 19.0372C24.1036 20.2808 23.119 21.3289 21.92 22.08C21.7687 22.1821 21.5924 22.2409 21.41 22.25Z'
      fill='black'
    />
    <path
      d='M23.62 28.18C23.3961 28.1787 23.1791 28.1022 23.0038 27.9629C22.8284 27.8236 22.705 27.6295 22.6531 27.4117C22.6012 27.1938 22.624 26.9649 22.7177 26.7615C22.8114 26.5581 22.9707 26.3921 23.17 26.29C25.2678 25.2564 27.0317 23.6523 28.2594 21.6619C29.4872 19.6715 30.129 17.3753 30.1112 15.0367C30.0935 12.6981 29.4169 10.4119 28.1591 8.44037C26.9013 6.4688 25.1132 4.89171 23 3.89002C22.8781 3.83523 22.7684 3.75655 22.6774 3.65863C22.5864 3.56072 22.5159 3.44555 22.4702 3.31995C22.4245 3.19435 22.4044 3.06085 22.4112 2.92736C22.4179 2.79387 22.4514 2.66308 22.5096 2.54274C22.5677 2.42239 22.6494 2.31493 22.7498 2.2267C22.8503 2.13847 22.9673 2.07127 23.0942 2.02906C23.221 1.98686 23.355 1.97051 23.4882 1.98098C23.6215 1.99145 23.7513 2.02853 23.87 2.09002C26.3246 3.24883 28.4029 5.07606 29.8665 7.3621C31.3301 9.64814 32.1197 12.3004 32.1448 15.0147C32.1698 17.7291 31.4293 20.3955 30.0082 22.7081C28.587 25.0208 26.5428 26.8861 24.11 28.09C23.9569 28.1609 23.7883 28.1918 23.62 28.18Z'
      fill='black'
    />
    <path
      d='M16 29.06C15.7361 29.0602 15.4747 29.0082 15.231 28.9069C14.9873 28.8056 14.7661 28.6571 14.58 28.47L7.14 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V11C0 10.4695 0.210714 9.96083 0.585786 9.58576C0.960859 9.21069 1.46957 8.99997 2 8.99997H7.22L14.55 1.60997C14.8262 1.31924 15.1834 1.11824 15.5752 1.03306C15.9671 0.947889 16.3755 0.982476 16.7475 1.13233C17.1194 1.28219 17.4377 1.5404 17.6611 1.87344C17.8844 2.20648 18.0025 2.59897 18 2.99997V27C18 27.3965 17.8821 27.7841 17.6613 28.1135C17.4405 28.4429 17.1268 28.6993 16.76 28.85C16.5231 28.9697 16.2648 29.0411 16 29.06ZM2 11V19H7.56C7.69231 19.0005 7.82319 19.0273 7.94507 19.0788C8.06694 19.1303 8.17739 19.2055 8.27 19.3L16 27.06V2.99997L8.35 10.7C8.25739 10.7945 8.14694 10.8696 8.02507 10.9211C7.90319 10.9726 7.77231 10.9994 7.64 11H2Z'
      fill='black'
    />
  </svg>
);

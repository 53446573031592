import { notification } from 'antd';
import firebase from 'firebase/app';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createProject as createProjectAction } from '../../store/actions/projectActions';
import { sendRecordingToSlack } from '../../store/actions/recordingAction';
import './index.css';
const urlParams = new URLSearchParams(window.location.search);
const channelId = urlParams.get('channelId') || '';
const slackUserId = urlParams.get('slackUserId') || '';

const MobileRecordAudio = (props: any) => {
  useEffect(() => {
    const iframeObj = iframeRef.current.contentWindow;
    iframeObj.postMessage(
      { event: 'VOICE_CLIP_MAXIMUM_TIME', data: props.recordVoiceClipTime },
      '*',
    );
  }, [props.recordVoiceClipTime]);

  const iframeRef: any = useRef(null);
  const eventHandler = (e: any) => {
    const eventData = e.data;
    const iframe = iframeRef.current.contentWindow;
    switch (eventData.event) {
      case 'AUDIO_BLOB_URL':
        const { audioBlobs, audioBlobIndex } = eventData.data;
        const fileName = 'file' + new Date().getTime();
        const storageRef = firebase
          .storage()
          .ref()
          .child(fileName);
        const firebaseUploadObject: any = storageRef.put(audioBlobs[audioBlobIndex]);
        firebaseUploadObject.on(
          'state_changed',
          (snapshot: any) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progress < 80 && iframe.postMessage({ event: 'PROGRESS_BAR', data: progress }, '*');
          },
          () => {
            notification.error({
              description: '',
              message: 'upload cancelled!',
            });
          },
          () => {
            return firebaseUploadObject.snapshot.ref
              .getDownloadURL()
              .then(async (downloadURL: any) => {
                const selectedMasterProjectId = window.localStorage.getItem(
                  'selectedMasterProjectId',
                )
                  ? window.localStorage.getItem('selectedMasterProjectId')
                  : '';
                let selectedMasterProject = {};
                if (selectedMasterProjectId) {
                  const firestore = firebase.firestore();
                  const selectedMasterProjectData: any = await firestore
                    .collection('masterProjects')
                    .where(firebase.firestore.FieldPath.documentId(), '==', selectedMasterProjectId)
                    .get();

                  if (
                    selectedMasterProjectData &&
                    selectedMasterProjectData.docs &&
                    selectedMasterProjectData.docs.length
                  ) {
                    selectedMasterProject = selectedMasterProjectData.docs[0].data();
                  }
                }

                const projectData: any = {
                  title: `Audio Recording`,
                  project_url: downloadURL,
                  member_emails: [],
                  project_tags: [],
                  type: 'audio',
                  imageType: 'base64',
                  jiraIssueUrl: '',
                  trelloIssueUrl: '',
                  project_base64_image: '',
                  appendVideo: '',
                  thumbnail_url: '',

                  workflowId: window.localStorage.getItem('selectedWorkflowId')
                    ? window.localStorage.getItem('selectedWorkflowId')
                    : 'chromeExtenstion',
                  masterProjectId: window.localStorage.getItem('selectedMasterProjectId')
                    ? window.localStorage.getItem('selectedMasterProjectId')
                    : 'chromeExtenstion',
                  archived: 'no',
                };

                props
                  .createProject(projectData, props.auth.firebaseAuthToken, selectedMasterProject)
                  .then((insertedId: any) => {
                    const fiebaseInstance: any = firebase;
                    fiebaseInstance
                      .auth()
                      .currentUser.getIdToken(true)
                      .then((firebaseToken: string) => {
                        iframe.postMessage({ event: 'PROGRESS_BAR', data: 90 }, '*');
                        sendRecordingToSlack(
                          {
                            fileUrl: downloadURL,
                            channelId,
                            recordingType: 'Audio',
                            slackUserId,
                            projectUrl: `${window.location.origin}/audio/${insertedId}`,
                          },
                          firebaseToken,
                          false,
                        )
                          .then(() => {
                            iframe.postMessage({ event: 'PROGRESS_BAR', data: 100 }, '*');
                          })
                          .catch(() => {
                            notification.error({
                              description: '',
                              message: 'Something went wrong while sending on slack.',
                            });
                          });
                      })
                      .catch(() => {
                        notification.error({
                          description: '',
                          message: 'Something went wrong while uploading file.',
                        });
                      });
                  });
              });
          },
        );
        break;
      default:
    }
  };

  useEffect(() => {
    window.addEventListener('message', eventHandler, false);
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);
  return (
    <iframe
      allow='camera;microphone'
      ref={iframeRef}
      title='FeedbackHero'
      style={{ width: '100%', height: '100vh', border: 'none' }}
      id='iframe'
      src={`${process.env.REACT_APP_WEBSITE_BASE_URL}/record-audio`}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createProject: (project: any, firebaseAuthToken: string) =>
      dispatch(createProjectAction(project, firebaseAuthToken)),
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MobileRecordAudio);

import React from 'react';

export default () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 7H16V9H9V16H7V9H0V7H7V0H9V7Z'
      fill='#001DAD'
    />
  </svg>
);

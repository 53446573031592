import React from 'react';

export default () => (
  <svg width='37' height='26' viewBox='0 0 37 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M35.983 4.02363C35.7738 3.24449 35.3636 2.53396 34.7935 1.96315C34.2235 1.39235 33.5134 0.981279 32.7346 0.771094C29.8676 1.56462e-07 18.375 0 18.375 0C18.375 0 6.88242 -1.56462e-07 4.01543 0.766992C3.23622 0.976496 2.52584 1.38733 1.95565 1.95825C1.38545 2.52916 0.975513 3.24005 0.766992 4.01953C-1.56462e-07 6.89062 0 12.8789 0 12.8789C0 12.8789 -1.56462e-07 18.8672 0.766992 21.7342C1.18945 23.3174 2.43633 24.5643 4.01543 24.9867C6.88242 25.7578 18.375 25.7578 18.375 25.7578C18.375 25.7578 29.8676 25.7578 32.7346 24.9867C34.3178 24.5643 35.5605 23.3174 35.983 21.7342C36.75 18.8672 36.75 12.8789 36.75 12.8789C36.75 12.8789 36.75 6.89063 35.983 4.02363ZM14.7246 18.375V7.38281L24.2402 12.8379L14.7246 18.375Z'
      fill='#9B9B9B'
    />
  </svg>
);

import React from 'react';

// eslint-disable-next-line no-console
const debug = console.error; // tslint:disable-line

export default (getVideoElement, getPlayerElement) => {
  const [fullscreen, setFullscreen] = React.useState(false);

  const requestFullscreen = React.useCallback(() => {
    const el = getPlayerElement();
    if (!el) {
      debug('useVideoFullscreen: 全屏异常，unmounted');
      return;
    }
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      // compatible IE11
      el.msRequestFullscreen();
    } else if (el.webkitRequestFullscreen) {
      // Compatible with WeChat PC version built-in browser
      el.webkitRequestFullscreen();
    } else {
      const videoEl = getVideoElement();
      if (videoEl && videoEl.webkitEnterFullScreen) {
        // iOS Safari
        videoEl.webkitEnterFullScreen();
      } else {
        debug(
          'useVideoFullscreen: Full screen exception, the browser does not support requestFullscreen',
        );
      }
    }
  }, [getVideoElement, getPlayerElement]);

  const exitFullscreen = React.useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else {
      debug(
        'useVideoFullscreen: Full screen exception, the browser does not support exitFullscreen',
      );
    }
  }, []);

  const onChange = React.useCallback(() => {
    const el = getPlayerElement();
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;
    setFullscreen(!!el && fullscreenElement === el);
  }, [getPlayerElement]);

  React.useEffect(() => {
    document.addEventListener('fullscreenchange', onChange);
    document.addEventListener('webkitfullscreenchange', onChange);
    document.onmsfullscreenchange = onChange;
    return () => {
      document.removeEventListener('fullscreenchange', onChange);
      document.removeEventListener('webkitfullscreenchange', onChange);
      document.onmsfullscreenchange = null;
    };
  }, [onChange]);

  return { fullscreen, requestFullscreen, exitFullscreen };
};

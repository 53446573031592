import React from 'react';
import { ChatIcon, MenuIcon } from './assets/Svgs';
import NewSelectDropdown from './NewSelectDropdown';

import './mobile.css';

const MobileDashboard = ({
  // chatWindowMobile,
  toggleChatWindow,
  // projectControls,
  toggleProjectControls,
  selectedMasterProject,
  selectedWorkflowId,
}: any) => {
  return (
    <div id='bottomControls' className='m-container'>
      <span onClick={toggleProjectControls}>
        <MenuIcon />
      </span>
      <NewSelectDropdown
        selectedMasterProject={selectedMasterProject}
        selectedWorkflowId={selectedWorkflowId}
        selectedMasterProjectId={(selectedMasterProject && selectedMasterProject.id) || ''}
      />
      <span onClick={toggleChatWindow}>
        <ChatIcon />
      </span>
    </div>
  );
};

export default MobileDashboard;

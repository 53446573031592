import { notification } from 'antd';
const { REACT_APP_FIREBASE_CLOUD_FUNCTION_URL } = process.env;

export const sendRecordingToSlack = (
  postData: {
    fileUrl: string;
    channelId: string;
    recordingType: string;
    slackUserId: string;
    projectUrl: string;
  },
  firebaseToken: string,
  isAutoCloseWindow: boolean,
) => {
  isAutoCloseWindow &&
    notification.success({
      message: 'Sending file to slack...',
    });
  return fetch(`${REACT_APP_FIREBASE_CLOUD_FUNCTION_URL}/sendFileToSlack`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify(postData),
  })
    .then((res) => res.json())
    .then((data) => {
      const { status, message } = data;
      if (status) {
        isAutoCloseWindow &&
          notification.success({
            message,
          });
        window.close();
      } else {
        notification.error({
          message,
        });
      }
    });
};

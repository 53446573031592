import { Button, Form, Input, Modal, notification, Switch, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { cancelSubscription, updateSubscription } from '../../store/actions/paddleIntegrationAction';
import { checkout, MONTHLY_PLANS, YEARLY_PLANS, mobileCheck } from '../../utils';
import './index.css';
import OrganizationQuota from '../organization-quota';
import UserQuota from '../userquota';

const isMobile = mobileCheck();

const SubscriptionPlan = (props: any = {}) => {
  const { profile, auth, form, firebaseAuthToken, organizationDetail } = props;
  const selectedOrganizationId = window.localStorage.getItem('selectedOrganizationId');
  const selectedOrganizationMatchedIndex = organizationDetail.findIndex(
    (og: any) => og.id === selectedOrganizationId,
  );
  const organization = organizationDetail[selectedOrganizationMatchedIndex];
  
  const {
    // userQuota = {},
    plan = '',
    isFreePlan = true,
    isSubscription = false,
    isPaymentFailed = false,
    isSubscriptionCancelled = false,
    successPayments = [],
    planExpireDate = '',
    planLabel = ''
  } = profile.isManagedUserQuotaInOrganization ? organization : profile;
  console.log("data==",profile.isManagedUserQuotaInOrganization, profile.isManagedUserQuotaInOrganization ? organization : profile)
  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planMode, setPlanMode] = useState('monthly');
  const [plans, setPlans] = useState(MONTHLY_PLANS);

  const { uid, email } = auth;
  const FormItem = Form.Item;
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    form.validateFields((err: any, values: any) => {
      if (!err) {
        setIsLoading(true);
        cancelSubscription(firebaseAuthToken, values).then((resMessage: any) => {
          setSubscriptionCancelModal(false);
          if (resMessage.error) {
            notification.error({
              message: resMessage.message,
            });
            setIsLoading(false);
          } else {
            notification.success({
              message: resMessage.message,
            });
            setIsLoading(false);
          }
        });
      }
    });
  };

  const onPlanChange = (checked: boolean) => {
    if (checked) {
      setPlans(YEARLY_PLANS);
      setPlanMode('yearly');
    } else {
      setPlans(MONTHLY_PLANS);
      setPlanMode('monthly');
    }
  };

  const getPlanLabel = (planKey: string) => {
    let planLabel = '';
    switch (planKey) {
      case 'freelancer':
        planLabel = 'Freelancers';
        break;
      case 'productTeam':
        planLabel = 'Product Teams';
        break;
      case 'digitalAgency':
        planLabel = 'Digital Agencies';
        break;
    }
    return planLabel;
  };

  const getPlans = () => {
    return (
      <div>
        <a style={{color: 'rgb(0, 29, 173)'}} className='pt-15' target='_blank' href={'https://zooc.io/pricing'}>
          <span>See pricing page</span>
        </a>
        <div className='pt-30'>
          Monthly
          <Switch className='plan-switch' onChange={onPlanChange} />
          Annualy
        </div>
        <div className='plan-wrapper' style={{display: 'flex'}}>
        {plans.map((planDetail: any, index: number) => {
          const { name, id, price } = planDetail;
          return (
            <div key={index} className='plan-container' style={{marginLeft: index ? '50px' : '0px'}}>
              <h3>{name}</h3>
              <p>${price}/Month</p>
              <Button
                style={{backgroundColor: '#001DAD'}}
                type='primary'
                onClick={() => {
                  checkout(id, 'name', planMode, email, uid);
                }}
              >
                {'Upgrade Now'}
              </Button>
            </div>
          );
        })}
        </div>
      </div>
    );
  };
  return (
    <div className='subscription-plan-container'>
      <Modal
        visible={subscriptionCancelModal}
        title='Please enter details to cancel subscription'
        onOk={handleSubmit}
        onCancel={() => {
          setSubscriptionCancelModal(false);
        }}
        footer={null}
      >
        <div className='container'>
          <Form className='white' onSubmit={handleSubmit}>
            <FormItem>
              {form.getFieldDecorator('cancelSubscriptionReason', {
                rules: [
                  {
                    message: 'Cancel Subscription Reason Please!',
                    required: true,
                  },
                ],
              })(<Input placeholder='Cancel Subscription Reason' type='text' />)}
            </FormItem>
            <div className='public-user-btn'>
              <Button style={{backgroundColor: '#001DAD'}} type='primary' loading={isLoading} htmlType='submit' className='form-button'>
                Submit
              </Button>
              <Button
                style={{backgroundColor: '#001DAD'}}
                className='public-user-cancel-btn'
                key='back'
                onClick={() => {
                  setSubscriptionCancelModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Typography.Text strong={true} className='settings-form-label'>
        <div style={{marginTop: '10px'}}>Your Plan: {planLabel ? planLabel : getPlanLabel(plan)}</div>
        { isSubscription && planExpireDate && <div  style={{marginTop: '10px', marginBottom: '10px'}}>
          Next Bill Date: {planExpireDate}
          </div> }
      </Typography.Text>
      {isFreePlan && <div>{getPlans()}</div>}
      {!isFreePlan && (
        <div>
          <a style={{color: 'rgb(0, 29, 173)'}} className='pt-15' target='_blank' href={'https://zooc.io/pricing'}>
            <span>See pricing page</span>
          </a>
          {isPaymentFailed && (
            <div>
              <p> Seems, Your payment has been failed, Please contact to support team.</p>
              {getPlans()}
            </div>
          )}
          {isSubscriptionCancelled && (
            <div>
              <p> Seems, You have cancelled subscription, Please subscribe to continue access.</p>
              {getPlans()}
            </div>
          )}

          <div>
            {isSubscription && plan === 'productTeam' && (
              <div className="digital-agency" style={{marginTop: '30px !important'}}>
                <div style={{marginTop: '30px !important'}}>
                  <h3>{planMode === 'monthly' ? MONTHLY_PLANS[1].name : YEARLY_PLANS[1].name}</h3>
                  <div>
                    Monthly
                    <Switch className='plan-switch' onChange={onPlanChange} />
                    Annualy
                  </div>
                  <p className='pt-15' style={{marginTop: '10px !important'}}>
                    ${planMode === 'monthly' ? MONTHLY_PLANS[1].price : YEARLY_PLANS[1].price}/
                    Month
                  </p>
                  <Button
                    style={{backgroundColor: '#001DAD'}}
                    loading={isLoading}
                    onClick={() => {
                      setIsLoading(true);
                      updateSubscription(firebaseAuthToken, {
                        planId: planMode === 'monthly' ? MONTHLY_PLANS[1].id : YEARLY_PLANS[1].id,
                        planKey: 'digitalAgency',
                        planLabel: planMode === 'monthly' ? 'Digital Agencies (Monthly)' : 'Digital Agencies (Annualy)'
                      }).then((resMessage: any) => {
                        if (resMessage.error) {
                          notification.error({
                            message: resMessage.message,
                          });
                          setIsLoading(false);
                        } else {
                          notification.success({
                            message: resMessage.message,
                          });
                          setIsLoading(false);
                        }
                      });
                      // checkout(
                      //   planMode === 'monthly' ? MONTHLY_PLANS[1].id : YEARLY_PLANS[1].id,
                      //   'digitalAgency',
                      //   'monthly',
                      //   email,
                      //   uid,
                      // );
                    }}
                    type='primary'
                  >
                    Upgrade Plan
                  </Button>
                </div>
              </div>
            )}
          </div>
          {isSubscription && (
            <>
              <div style={{marginTop: '10px'}}>
                <a
                  href='#'
                  onClick={() => {
                    setSubscriptionCancelModal(true);
                  }}
                >
                  <span>Cancel my Subscription</span>
                </a>
              </div>
            </>
          )}
        </div>
      )}

      <div style={{ width: isMobile ? '100%' : undefined }} className='subscription-usage-drawer-user-quota'>
        <h3>Usage Quota:</h3>
        {profile.isManagedUserQuotaInOrganization ? (
          <OrganizationQuota organizations={organizationDetail} />
        ) : (
          <UserQuota profile={profile} />
        )}
      </div>

      {successPayments && successPayments.length ? (
        <div className='pt-5'>
          <Typography.Text strong={true} className='settings-form-label'>
            Invoices:
          </Typography.Text>
          <ul>
            {successPayments.map((successPayment: any, index: number) => {
              const { receipt_url, event_time } = successPayment;
              return (
                <li key={index}>
                  <a target='_blank' href={receipt_url}>
                    {moment(event_time).format('YYYY-MM-DD')}-invoice
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    firebaseAuthToken: state.auth.firebaseAuthToken,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create<IProps>()(SubscriptionPlan)) as any;

import React from 'react';

export default () => (
  <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M5.28399 8.71392C5.39564 8.82557 5.45836 8.97699 5.45836 9.13488C5.45836 9.29277 5.39564 9.44419 5.28399 9.55583L2.33731 12.5025L5.28399 15.4492C5.39564 15.5609 5.45836 15.7123 5.45836 15.8702C5.45836 16.0281 5.39564 16.1795 5.28399 16.2911C5.17235 16.4028 5.02093 16.4655 4.86304 16.4655C4.70515 16.4655 4.55373 16.4028 4.44208 16.2911L1.07444 12.9235C0.962795 12.8118 0.900074 12.6604 0.900074 12.5025C0.900074 12.3446 0.962795 12.1932 1.07444 12.0816L4.44208 8.71392C4.55373 8.60228 4.70515 8.53956 4.86304 8.53956C5.02093 8.53956 5.17235 8.60228 5.28399 8.71392Z'
      fill='#333333'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.69891 12.4716C9.69901 12.5499 9.68366 12.6275 9.65374 12.6999C9.62382 12.7722 9.57992 12.838 9.52455 12.8933C9.46918 12.9487 9.40343 12.9926 9.33107 13.0225C9.2587 13.0524 9.18114 13.0678 9.10284 13.0677L1.52564 13.0677C1.36756 13.0677 1.21594 13.0049 1.10416 12.8931C0.992373 12.7813 0.929572 12.6297 0.929572 12.4716C0.929572 12.3135 0.992373 12.1619 1.10416 12.0501C1.21594 11.9384 1.36756 11.8756 1.52565 11.8756L9.10284 11.8756C9.18115 11.8755 9.2587 11.8908 9.33106 11.9207C9.40343 11.9506 9.46918 11.9945 9.52455 12.0499C9.57992 12.1053 9.62382 12.171 9.65374 12.2434C9.68366 12.3158 9.69901 12.3933 9.69891 12.4716ZM24.0114 12.4716C24.0115 12.5499 23.9961 12.6275 23.9662 12.6998C23.9363 12.7722 23.8924 12.838 23.837 12.8933C23.7817 12.9487 23.7159 12.9926 23.6435 13.0225C23.5712 13.0524 23.4936 13.0678 23.4153 13.0677L15.8381 13.0677C15.68 13.0677 15.5284 13.0049 15.4166 12.8931C15.3049 12.7813 15.2421 12.6297 15.2421 12.4716C15.2421 12.3135 15.3049 12.1619 15.4166 12.0501C15.5284 11.9384 15.68 11.8756 15.8381 11.8756L23.4153 11.8756C23.4936 11.8755 23.5712 11.8908 23.6435 11.9207C23.7159 11.9506 23.7817 11.9945 23.837 12.0499C23.8924 12.1053 23.9363 12.171 23.9662 12.2434C23.9961 12.3158 24.0115 12.3933 24.0114 12.4716Z'
      fill='#333333'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M19.6569 8.71392C19.7686 8.60228 19.92 8.53956 20.0779 8.53956C20.2358 8.53956 20.3872 8.60228 20.4988 8.71392L23.8665 12.0816C23.9781 12.1932 24.0408 12.3446 24.0408 12.5025C24.0408 12.6604 23.9781 12.8118 23.8665 12.9235L20.4988 16.2911C20.3872 16.4028 20.2358 16.4655 20.0779 16.4655C19.92 16.4655 19.7686 16.4028 19.6569 16.2911C19.5453 16.1795 19.4826 16.0281 19.4826 15.8702C19.4826 15.7123 19.5453 15.5609 19.6569 15.4492L22.6036 12.5025L19.6569 9.55583C19.5453 9.44419 19.4826 9.29277 19.4826 9.13488C19.4826 8.97699 19.5453 8.82557 19.6569 8.71392Z'
      fill='#333333'
    />
  </svg>
);

import React from 'react';

export default () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M0.666748 15.6667C0.666748 16.5872 1.41294 17.3334 2.33341 17.3334H13.5119L17.3334 13.5119V2.33335C17.3334 1.41288 16.5872 0.666687 15.6667 0.666687H2.33341C1.41294 0.666687 0.666748 1.41288 0.666748 2.33335V15.6667ZM2.33341 2.33335H15.6667V11.5H13.1667C12.2463 11.5 11.5001 12.2462 11.5001 13.1667V15.6667H2.33341V2.33335ZM13.1667 13.1667H15.3216L13.1667 15.3215V13.1667ZM4.83341 11.5V13.1667H9.83342V11.5H4.83341ZM4.83341 9.83335V8.16669H13.1667V9.83335H4.83341ZM4.83341 4.83335V6.50002H13.1667V4.83335H4.83341Z'
      fill='#333333'
    />
  </svg>
);

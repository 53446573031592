import { Form, Modal, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Zbutton, Zinput } from '../../primitives';
import {
  clearAuthState as clearAuthStateAction,
  signUp as signUpAction,
  updateFirebaseAuthToken as updateFirebaseAuthTokenAction,
} from '../../store/actions/authActions';
import { IAuthAction, IUser, Type } from '../../types/authTypes';
import { ButtonEvent } from '../../types/syntheticEventsTypes';
import { trackAnalytics } from '../../utils';
import './infomodal.css';
interface ICreds {
  email: string;
  firstName: string;
}

interface IProps extends FormComponentProps {
  showInfoModal: boolean;
  projectId: string;
  hideshowInfoModal: any;
  sendMessage?: any;
  children?: JSX.Element | JSX.Element[];
  signUp: (o: IUser) => void;
  clearAuthState: () => void;
  authError: string;
  status: string;
  auth: {
    isLoaded: boolean;
    uid: string;
  };
  profile: any;
  updateFirebaseAuthToken: any;
}

const FormItem = Form.Item;
const InfoModal = (props: IProps) => {
  const {
    showInfoModal,
    projectId,
    hideshowInfoModal,
    authError,
    clearAuthState,
    signUp,
    auth,
    form,
    profile,
    status,
    updateFirebaseAuthToken,
  } = props;

  const [detailModal, showDetailModal] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const signup = async () => {
      const firestore = firebase.firestore();
      const token = localStorage.getItem('firebaseToken');
      if (auth.isLoaded && auth.uid && token && detailModal) {
        const { uid } = auth;
        if (uid) {
          firestore
            .collection('projects')
            .where(firebase.firestore.FieldPath.documentId(), '==', projectId)
            .get()
            .then((projectDetails: any) => {
              if (projectDetails && projectDetails.docs && projectDetails.docs.length) {
                const projectDetailData = projectDetails.docs[0].data();
                const userPushNotificationTokens =
                  projectDetailData && projectDetailData.userPushNotificationTokens
                    ? projectDetailData.userPushNotificationTokens
                    : [];
                const isExistToken = userPushNotificationTokens.some(
                  (ut: any) => ut.uid === uid && ut.token,
                );
                if (!isExistToken && token) {
                  userPushNotificationTokens.push({ uid, token });
                  firestore
                    .collection('projects')
                    .doc(projectId)
                    .update({ userPushNotificationTokens });
                }
              }
            });
        }
      }
      if (auth.isLoaded && auth.uid && profile && profile.firstName && detailModal) {
        const fiebaseInstance: any = firebase;
        if (fiebaseInstance) {
          const firebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
          updateFirebaseAuthToken(firebaseAuthToken);
          setLoading(false);
          showDetailModal(false);
          trackAnalytics('User', 'Signed up');
        }
      }
    };
    signup();
    // eslint-disable-next-line
  }, [auth, profile]);

  useEffect(() => {
    status === Type.StatusRunning ? setLoading(true) : setLoading(false);
    if (status === Type.SignUpError) {
      notification.error({
        description: authError,
        message: 'Login Failed',
      });
      return () => clearAuthState && clearAuthState();
    }
    // eslint-disable-next-line no-restricted-globals
  }, [authError, status, clearAuthState]);
  const handleSubmit = (e: ButtonEvent) => {
    e.preventDefault();
    form.validateFields((err: any, values: ICreds) => {
      if (!err) {
        setLoading(true);
        const formData: any = values;
        formData.password = 'dsfas34k23k4jksjfiu3nmn234!@#$';
        formData.lastName = '';
        formData.adminIds = [];
        formData.isPublicUser = true;
        signUp(formData);
      }
    });
  };

  React.useEffect(() => {
    showDetailModal(showInfoModal);
  }, [showInfoModal, showDetailModal]);

  const handleCancel = () => {
    hideshowInfoModal(false);
    showDetailModal(!detailModal);
  };

  return (
    <Modal
      visible={detailModal}
      title='Please enter details to add feedback'
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={null}
    >
      <div className='container'>
        <Form className='white' onSubmit={handleSubmit}>
          <FormItem>
            {form.getFieldDecorator('firstName', {
              rules: [
                {
                  message: 'Name Please!',
                  required: true,
                },
              ],
            })(<Zinput placeholder='Name' type='text' />)}
          </FormItem>
          <FormItem>
            {form.getFieldDecorator('email', {
              rules: [
                {
                  message: 'Email Please!',
                  required: true,
                },
              ],
            })(<Zinput placeholder='Email' type='text' />)}
          </FormItem>
          <div className='public-user-btn'>
            <Zbutton primary loading={loading} htmlType='submit'>
              Submit
            </Zbutton>
            <Zbutton secondary style={{ marginRight: 10 }} key='back' onClick={handleCancel}>
              Cancel
            </Zbutton>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: {
  auth: {
    authError: string;
    status: string;
  };
  // @remove-any
  firebase: { auth: any; profile: any };
}) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    status: state.auth.status,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, void, IAuthAction>) => {
  return {
    clearAuthState: () => dispatch(clearAuthStateAction()),
    signUp: (creds: IUser) => dispatch(signUpAction(creds)),
    updateFirebaseAuthToken: (firebaseAuthToken: string) =>
      dispatch(updateFirebaseAuthTokenAction(firebaseAuthToken)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create<IProps>()(withRouter(InfoModal)));

import { Button, Form, Input, Modal } from 'antd';
import firebaseApp from 'firebase/app';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { trackAnalytics } from 'utils';
import { v4 as uuid } from 'uuid';
import { createProject as createProjectAction } from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import './style.css';

const ProjectForm = (props: any) => {
  const {
    visible,
    setVisible,
    title = '',
    createProject,
    firebaseAuthToken,
    selectedWorkflowId,
    selectedMasterProjectId,
    email,
    selectedMasterProject,
    profile,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      const { projectName } = values;
      if (!err) {
        setIsLoading(true);
        let userFirebaseAuthToken = firebaseAuthToken;
        const fiebaseInstance: any = firebaseApp;
        if (
          !firebaseAuthToken &&
          fiebaseInstance &&
          fiebaseInstance.auth() &&
          fiebaseInstance.auth().currentUser
        ) {
          userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
        }
        createProject(
          {
            project_tags: [],
            title: `Website - ${projectName}`,
            type: 'website',
            project_url: projectName,
            member_emails: [email],
            workflowId: selectedWorkflowId,
            masterProjectId: selectedMasterProjectId,
            archived: 'no',
          },
          userFirebaseAuthToken,
          selectedMasterProject,
        ).then((insertedId: any) => {
          setIsLoading(false);
          trackAnalytics('Canvas', 'Created a canvas', 'Website');
          const slackMessage = `${profile.fullName} created website type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/website/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );

          props.history.push(`/website/${insertedId}`);
        });
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <div className='project-form'>
      <Modal visible={visible} title={title} onOk={setVisible} onCancel={setVisible} footer={null}>
        <div className='add-project-container'>
          <Form className='white'>
            <Form.Item className='add-project-form-item hide-padding-right'>
              {getFieldDecorator('projectName', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Please Input Website Url!',
                    whitespace: true,
                  },
                ],
              })(<Input placeholder='Enter website url' />)}
            </Form.Item>
            <div className='add-project-submit-div'>
              <Button
                className='add-project-submit-button'
                key='submit'
                type='primary'
                htmlType='submit'
                loading={isLoading}
                disabled={isLoading}
                onClick={handleSubmit}
              >
                Create Canvas
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    createProject: (project: any, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create()(withRouter(ProjectForm)));

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 9C1.89543 9 1 8.10457 1 7L1 3C1 1.89543 1.89543 1 3 1L8 1C9.10457 1 10 1.89543 10 3L10 7C10 8.10457 9.10457 9 8 9L3 9Z'
      stroke={color || `#4F4F4F`}
      stroke-width='2'
    />
    <path
      d='M16 9C14.8954 9 14 8.10457 14 7L14 3C14 1.89543 14.8954 1 16 1L21 1C22.1046 1 23 1.89543 23 3L23 7C23 8.10457 22.1046 9 21 9L16 9Z'
      stroke={color || `#4F4F4F`}
      stroke-width='2'
    />
    <path
      d='M16 21C14.8954 21 14 20.1046 14 19L14 15C14 13.8954 14.8954 13 16 13L21 13C22.1046 13 23 13.8954 23 15L23 19C23 20.1046 22.1046 21 21 21L16 21Z'
      stroke={color || `#4F4F4F`}
      stroke-width='2'
    />
    <path
      d='M3 21C1.89543 21 1 20.1046 1 19L1 15C1 13.8954 1.89543 13 3 13L8 13C9.10457 13 10 13.8954 10 15L10 19C10 20.1046 9.10457 21 8 21L3 21Z'
      stroke={color || `#4F4F4F`}
      stroke-width='2'
    />
  </svg>
);

export default Icon;

import { Table } from 'antd';
import firebase from 'firebase/app';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { groupBy } from '../../utils';

interface IOwnProps {
  stats: any;
  auth?: any;
  profile?: any;
}

class AppSumoUsersStats extends Component<IOwnProps, any> {
  state = {
    appSumoUsers: [],
  };
  componentDidMount() {
    const firestore = firebase.firestore();
    firestore
      .collection('redemption')
      .where('isUsed', '==', true)
      .get()
      .then((redemptionSnap: any) => {
        if (redemptionSnap && redemptionSnap.docs && redemptionSnap.docs.length) {
          const redemptions = redemptionSnap.docs.map((doc: any) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          });
          const redemptionGroupByUserId = groupBy(redemptions, 'userId');
          const appSumoUsers: any = [];
          Object.keys(redemptionGroupByUserId).map((appSumoUser: any) => {
            appSumoUsers.push({
              userId: appSumoUser,
              appsumoCode: redemptionGroupByUserId[appSumoUser],
            });
          });
          this.setState({
            appSumoUsers,
          });
        }
      });
  }

  getColumns = () => {
    return [
      {
        dataIndex: 'userId',
        key: 'userId',
        title: 'User Id',
        width: 'auto',
      },
      {
        dataIndex: 'appsumoCode',
        key: 'appsumoCode',
        title: 'AppSumo Used Code',
        width: 'auto',
      },
    ];
  };

  render() {
    const { profile } = this.props;
    const { appSumoUsers } = this.state;
    if (!profile.email || profile.role !== 'ADMIN') {
      return false && <Redirect to='/signin' />;
    }
    return (
      <div className='dashboard container'>
        <div className='row'>
          <Table
            rowKey='id'
            columns={this.getColumns()}
            dataSource={appSumoUsers}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

export default compose<any>(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: 'stats',
    },
  ]),
)(AppSumoUsersStats);

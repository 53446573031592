import { Avatar, Checkbox, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { trackAnalytics } from 'utils';
import { ApplyFilter, DefaultFilter } from '../../svg';

import './style.css';

const Filter = (props: any) => {
  const [visibleNotificationPopover, setVisibleNotificationPopover] = useState(false);
  const [tags, setTags]: any = useState([]);
  const [members, setMembers]: any = useState([]);
  const [selectedAllTag, setSelectAllTag]: any = useState(false);
  const [selectedAllMember, setSelectAllMember]: any = useState(false);

  useEffect(() => {
    const defaultTags = props.tags.map((tag: any) => {
      tag.isChecked = false;
      return tag;
    });
    setTags(defaultTags);
  }, [props.tags]);

  useEffect(() => {
    let defaultMembers = props.members.map((member: any) => {
      member.isChecked = false;
      return member;
    });
    defaultMembers = defaultMembers.filter((member: any) => {
      return member.status ? member.status === 'ACTIVE' : true;
    });
    setMembers(defaultMembers);
  }, [props.members]);

  const changefilterMember = (e: any, index: number) => {
    trackAnalytics('Canvas', 'Changed filter for Member');
    members[index].isChecked = e.target.checked;
    setMembers(JSON.parse(JSON.stringify(members)));

    const checkedMemberFilter = members.filter((member: any) => member.isChecked);
    if (checkedMemberFilter.length === members.length) {
      setSelectAllMember(true);
    } else {
      selectedAllMember && setSelectAllMember(false);
    }
    props.filterCanvases && props.filterCanvases(tags, members);
  };

  const changefilterTag = (e: any, index: number) => {
    trackAnalytics('Canvas', 'Changed filter for Tag');
    tags[index].isChecked = e.target.checked;
    setTags(JSON.parse(JSON.stringify(tags)));

    const checkedTagFilter = tags.filter((tag: any) => tag.isChecked);
    if (checkedTagFilter.length === tags.length) {
      setSelectAllTag(true);
    } else {
      selectedAllTag && setSelectAllTag(false);
    }
    props.filterCanvases && props.filterCanvases(tags, members);
  };

  const changeAllFilterMember = (e: any) => {
    trackAnalytics('Canvas', 'Clear all filters Clicked');
    setSelectAllMember(e.target.checked);
    const filterMembers = members.map((member: any) => {
      member.isChecked = e.target.checked;
      return member;
    });
    props.filterCanvases && props.filterCanvases(tags, filterMembers);
    setMembers(filterMembers);
  };

  const changeAllFilterTag = (e: any) => {
    trackAnalytics('Canvas', 'Changed all filters Tag');
    setSelectAllTag(e.target.checked);
    const filterTags = tags.map((tag: any) => {
      tag.isChecked = e.target.checked;
      return tag;
    });
    props.filterCanvases && props.filterCanvases(filterTags, members);
    setTags(filterTags);
  };

  const isSelected = (allTags: any, allMembers: any) => {
    const filterTags = allTags.filter((tag: any) => {
      return tag.isChecked;
    });

    const filterMembers = allMembers.filter((member: any) => {
      return member.isChecked;
    });

    return filterTags.length || filterMembers.length;
  };

  const content = (
    <div className='filter-container'>
      {members && members.length ? (
        <div
          className={`filter-member-container ${tags &&
            tags.length &&
            'filter-member-container-bottom-border'}`}
        >
          <div className='all-member'>
            <label className='cursor-pointer' htmlFor={`member-all`}>
              <div>
                <span>Assigned To</span>
                <span className='member-filter-checkbox'>
                  <Checkbox
                    id='member-all'
                    checked={selectedAllMember}
                    onChange={(e: any) => {
                      changeAllFilterMember(e);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </label>
          </div>
          <div className='member-list'>
            <ul>
              {members.map((member: any, index: number) => {
                return (
                  <li key={index}>
                    <label htmlFor={`member-${index}`} className='cursor-pointer'>
                      <div>
                        {member.image ? (
                          <Avatar src={member.image} />
                        ) : (
                          <Avatar>{member.name && member.name[0]}</Avatar>
                        )}
                        <span className='filter-checkbox-span break-span-word'>{member.name}</span>
                        <span className='member-filter-checkbox'>
                          <Checkbox
                            id={`member-${index}`}
                            checked={member.isChecked}
                            onChange={(e: any) => {
                              changefilterMember(e, index);
                            }}
                          />
                        </span>
                      </div>
                    </label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}

      {tags && tags.length ? (
        <div className='filter-tag-container'>
          <div className='all-tag'>
            <label htmlFor={`tag-all`} className='cursor-pointer'>
              <div>
                <span>Show All Tags</span>
                <span className='tag-filter-checkbox'>
                  <Checkbox
                    id={`tag-all`}
                    checked={selectedAllTag}
                    onChange={(e: any) => {
                      changeAllFilterTag(e);
                    }}
                  ></Checkbox>
                </span>
              </div>
            </label>
          </div>
          <div className='tag-list'>
            <ul>
              {tags.map((tag: any, index: number) => (
                <li key={index}>
                  <label htmlFor={`tag-${index}`} className='cursor-pointer'>
                    <div>
                      <span
                        className='filter-tag-color'
                        style={{ background: tag.color, borderRadius: '3px' }}
                      ></span>
                      <span className='filter-checkbox-span break-tag-span-word break-span-word'>
                        {tag.title}
                      </span>
                      <span className='tag-filter-checkbox'>
                        <Checkbox
                          id={`tag-${index}`}
                          defaultChecked={false}
                          checked={tag.isChecked}
                          onChange={(e: any) => {
                            changefilterTag(e, index);
                          }}
                        ></Checkbox>
                      </span>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      <Popover
        content={content}
        placement='bottomRight'
        style={{ marginTop: 0 }}
        visible={visibleNotificationPopover}
        onVisibleChange={(currentVisible) => {
          setVisibleNotificationPopover(currentVisible);
        }}
      >
        <div style={{ position: 'relative', cursor: 'pointer', marginRight: '15px' }}>
          {isSelected(tags, members) ? <ApplyFilter /> : <DefaultFilter />}
        </div>
      </Popover>
    </>
  );
};

export default Filter;

import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M2.33341 0.666687H15.6667C16.5872 0.666687 17.3334 1.41288 17.3334 2.33335V15.6667C17.3334 16.5872 16.5872 17.3334 15.6667 17.3334H2.33341C1.41294 17.3334 0.666748 16.5872 0.666748 15.6667V2.33335C0.666748 1.41288 1.41294 0.666687 2.33341 0.666687ZM2.33344 2.33337V11.9882L5.66675 8.65485L8.58343 11.5715L14.0001 6.15485L15.6668 7.82151V2.33337H2.33344ZM2.33344 15.6667V14.3452L5.66675 11.0119L10.3216 15.6667H2.33344ZM15.6668 15.6667H12.6786L9.76194 12.75L14.0001 8.51187L15.6668 10.1785V15.6667ZM10.6667 5.66669C10.6667 4.28598 9.54746 3.16669 8.16675 3.16669C6.78604 3.16669 5.66675 4.28598 5.66675 5.66669C5.66675 7.0474 6.78604 8.16669 8.16675 8.16669C9.54746 8.16669 10.6667 7.0474 10.6667 5.66669ZM7.33344 5.66671C7.33344 5.20647 7.70653 4.83337 8.16677 4.83337C8.62701 4.83337 9.0001 5.20647 9.0001 5.66671C9.0001 6.12695 8.62701 6.50004 8.16677 6.50004C7.70653 6.50004 7.33344 6.12695 7.33344 5.66671Z'
      fill={color || '#4F4F4F'}
    />
  </svg>
);

export default Icon;

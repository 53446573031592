import React from 'react';

export default () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.34517 8.83333L8.58925 14.0774L7.41074 15.2559L0.154816 8L7.41074 0.74408L8.58925 1.92259L3.34517 7.16667H15.5V8.83333H3.34517Z'
      fill='#333333'
    />
  </svg>
);

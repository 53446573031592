import { Drawer, Form, Input, Modal, notification, Select } from 'antd';
import ColorContext from 'context/ColorContext';
import firebase from 'firebase/app';
import { Zbutton, Zinput } from 'primitives';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getMasterData, postJiraData } from 'store/actions/jiraIntegrationAction';
import { editProject as editProjectAction } from 'store/actions/projectActions';
import IntegrationDrawer from '../dashboard/IntegrationDrawer';
import { Close } from '../svg';
import './index.css';

const CreateIssueForm = (props: any) => {
  const [integrationDrawerVisible, setIntegrationDrawerVisible] = useState(false);
  const { visible, setVisible, selectedOrganizationData } = props;
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [issueTypesLoading, setIssueTypesLoading] = useState(true);
  const [prioritiesLoading, setPrioritiesLoading] = useState(true);
  const [assigneesLoading, setAssigneesLoading] = useState(true);
  const [labelsLoading, setLabelsLoading] = useState(true);

  const [selectedProjectKey, setSelectedProjectKey] = useState('');
  const [issueTypes, setIssueTypes] = useState([]);
  const [labelChildren, setLabelChildren] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevJiraConnect, setPrevJiraConnect] = useState(false);
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { TextArea } = Input;
  const jiraConnect = (props.profile && props.profile.jiraConnect) || false;

  const onCloseIntegrationDrawer = () => {
    setIntegrationDrawerVisible(false);
  };

  useEffect(() => {
    async function getMasterDataFun() {
      const fiebaseInstance: any = firebase;

      if (!fiebaseInstance || !visible) {
        return;
      }

      const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
      if (jiraConnect && prevJiraConnect !== jiraConnect) {
        getMasterData(firebaseToken, '/project').then((data: any) => {
          setProjectsLoading(false);
          setProjects(data);
        });
        getMasterData(firebaseToken, '/issuetype').then((data: any) => {
          setIssueTypesLoading(false);
          const filterIssues = data.filter(
            (item: any) => item.name !== 'Sub-task' && item.name !== 'Epic',
          );
          setIssueTypes(filterIssues);
        });
        getMasterData(firebaseToken, '/label').then((data: any) => {
          setLabelsLoading(false);
          const values = typeof data.values === 'object' ? data.values : [];
          const children: any = [];
          values.forEach((element: string, index: number) => {
            children.push(
              <Option key={index} value={element}>
                {element}
              </Option>,
            );
          });
          setLabelChildren(children);
        });
        getMasterData(firebaseToken, '/priority').then((data: any) => {
          setPrioritiesLoading(false);
          setPriorities(data);
        });
        getMasterData(firebaseToken, '/users/search').then((data: any) => {
          setAssigneesLoading(false);
          const activeData = data.filter(
            (item: any) => item.active && item.accountType === 'atlassian',
          );
          setAssignees(activeData);
        });
      }
      setPrevJiraConnect(jiraConnect);
    }
    getMasterDataFun();
  }, [props.profile, prevJiraConnect, visible]);

  const handleProjectChange = (value: string) => {
    setSelectedProjectKey(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      const { projectKey, summary, issueType, priority, assignee, labels, description } = values;
      const loginUserId = props.auth.uid;
      const { projectId, editProject } = props;
      const projectURL = window.location.href;
      if (!err && loginUserId) {
        const postBodyData: any = {
          fields: {
            project: {
              key: projectKey,
            },
            summary,
            issuetype: {
              id: issueType,
            },
            description: {
              type: 'doc',
              version: 1,
              content: [
                {
                  type: 'blockquote',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          type: 'text',
                          text: 'Website URL: ',
                        },
                        {
                          type: 'text',
                          text: projectURL,
                          marks: [
                            {
                              type: 'link',
                              attrs: {
                                href: projectURL,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'blockquote',
                  content: [
                    {
                      type: 'paragraph',
                      content: [
                        {
                          type: 'text',
                          text: `User Agent: ${window.navigator.userAgent}`,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        };

        if (priority !== '') {
          postBodyData.fields.priority = {
            id: priority,
          };
        }

        if (assignee !== '') {
          postBodyData.fields.assignee = {
            id: assignee,
          };
        }

        if (labels.length) {
          postBodyData.fields.labels = labels;
        }

        if (description !== '') {
          postBodyData.fields.description.content.unshift({
            type: 'paragraph',
            content: [
              {
                text: description,
                type: 'text',
              },
            ],
          });
        }

        setIsLoading(true);
        const fiebaseInstance: any = firebase;
        const firebaseToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
        postJiraData(firebaseToken, '/issue', JSON.stringify(postBodyData)).then((resData: any) => {
          if (resData.error) {
            notification.error({
              message: resData.message,
            });
            setIsLoading(false);
            setVisible();
          } else {
            const { jiraHost } = props.profile;
            const jiraIssueKey =
              resData && resData.data && resData.data.key ? resData.data.key : '';
            const jiraIssueUrl = `${jiraHost}/projects/${projectKey}/issues/${jiraIssueKey}`;
            const firestore = firebase.firestore();
            firestore
              .collection('projects')
              .doc(projectId)
              .get()
              .then((doc: any) => {
                if (doc.exists) {
                  const project = doc.data();
                  const { member_emails, type, imageType, trelloIssueUrl, tags, title } = project;
                  editProject(
                    projectId,
                    title,
                    member_emails,
                    tags,
                    type,
                    imageType,
                    jiraIssueUrl,
                    trelloIssueUrl,
                  ).then(() => {
                    props.form.resetFields();
                    notification.success({
                      message: resData.message,
                    });
                    setIsLoading(false);
                    setVisible();
                  });
                }
              });
          }
        });
      }
    });
  };
  const selectedProject: any = projects.find((project: any) => project.key === selectedProjectKey);
  let projectIssueTypes: any = [];
  if (selectedProject && selectedProject.id) {
    projectIssueTypes = issueTypes.filter((issueType: any) => {
      const issueTypeProjectId =
        issueType.scope && issueType.scope.project && issueType.scope.project.id;
      // @ts-ignore
      return issueTypeProjectId === selectedProject.id; // typeof issueType.scope === "undefined" ||
    });
  }

  if (!projectIssueTypes.length) {
    projectIssueTypes = issueTypes.filter((issueType: any) => {
      return typeof issueType.scope === 'undefined';
    });
  }

  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div>
          {jiraConnect ? (
            <Drawer
              title='Create Jira Issue'
              placement='right'
              closable={true}
              onClose={() => setVisible()}
              visible={visible}
              style={{ position: 'absolute' }}
              width='75vw'
            >
              <div className='jira-issue-create-form' style={{ color: theme.text.color2 }}>
                <Form className='white'>
                  <Form.Item label='Summary' style={{ marginBottom: 5 }}>
                    {getFieldDecorator('summary', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input summary!',
                        },
                      ],
                    })(<Zinput />)}
                  </Form.Item>
                  <Form.Item label='Description' style={{ marginBottom: 5 }}>
                    {getFieldDecorator('description', {
                      initialValue: '',
                    })(<TextArea rows={2} />)}
                  </Form.Item>
                  <Form.Item label='Project' style={{ marginBottom: 5 }}>
                    {getFieldDecorator('projectKey', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: 'Please select project!',
                        },
                      ],
                    })(
                      <Select
                        loading={projectsLoading}
                        onChange={handleProjectChange}
                        showSearch
                        placeholder='Select a project'
                        optionFilterProp='children'
                        filterOption={(input: string, option: any) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {projects.map((project: any) => (
                          <Option key={project.key} value={project.key} className='select-option'>
                            {project.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                  <Form.Item label='Issue Type' style={{ marginBottom: 5 }}>
                    {getFieldDecorator('issueType', {
                      initialValue: '',
                      rules: [
                        {
                          required: true,
                          message: 'Please select issue type!',
                        },
                      ],
                    })(
                      <Select
                        loading={issueTypesLoading}
                        showSearch
                        placeholder='Select a issue type'
                        optionFilterProp='children'
                        filterOption={(input: string, option: any) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {projectIssueTypes.map((issueType: any, index: number) => (
                          <Option key={index} value={issueType.id} className='select-option'>
                            {issueType.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                  <Form.Item label='Priority' style={{ marginBottom: 5 }}>
                    {getFieldDecorator('priority', {
                      initialValue: '',
                    })(
                      <Select
                        showSearch
                        placeholder='Select a priority'
                        optionFilterProp='children'
                        loading={prioritiesLoading}
                        filterOption={(input: string, option: any) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {priorities.map((priority: any) => (
                          <Option key={priority.id} value={priority.id} className='select-option'>
                            {priority.name}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                  <Form.Item label='Assignee' style={{ marginBottom: 5 }}>
                    {getFieldDecorator('assignee', {
                      initialValue: '',
                    })(
                      <Select
                        showSearch
                        placeholder='Select a assignee'
                        optionFilterProp='children'
                        loading={assigneesLoading}
                        filterOption={(input: string, option: any) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {assignees.map((assignee: any) => (
                          <Option
                            key={assignee.accountId}
                            value={assignee.accountId}
                            className='select-option'
                          >
                            {assignee.displayName}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Form.Item>
                  <Form.Item label='Labels' style={{ marginBottom: 5 }}>
                    {getFieldDecorator('labels', {
                      initialValue: [],
                    })(
                      <Select
                        mode='multiple'
                        size={'default'}
                        placeholder='Please select'
                        style={{ width: '100%' }}
                        loading={labelsLoading}
                      >
                        {labelChildren}
                      </Select>,
                    )}
                  </Form.Item>
                  <div className='container-btns'>
                    <Zbutton
                      key='back'
                      secondary
                      btnColors={theme}
                      style={{ marginRight: 10 }}
                      onClick={() => setVisible()}
                    >
                      Cancel
                    </Zbutton>
                    <Zbutton
                      primary
                      btnColors={theme}
                      key='submit'
                      loading={isLoading}
                      onClick={handleSubmit}
                    >
                      Submit
                    </Zbutton>
                  </div>
                </Form>
              </div>
              <div className='drawer-close-btn' onClick={() => setVisible()}>
                <Close />
              </div>
            </Drawer>
          ) : (
            <Modal
              title='Create Jira Issue'
              visible={visible}
              onOk={() => setVisible()}
              onCancel={() => setVisible()}
              footer={null}
            >
              <div style={{ color: theme.text.color2 }}>
                <p>Please connect your jira account to create an issue.</p>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <Zbutton
                    primary
                    btnColors={theme}
                    onClick={() => setIntegrationDrawerVisible(true)}
                  >
                    Connect to Jira
                  </Zbutton>
                </div>
              </div>
            </Modal>
          )}
          <IntegrationDrawer
            onCloseDrawer={onCloseIntegrationDrawer}
            visible={integrationDrawerVisible}
            selectedOrganizationData={selectedOrganizationData}
          />
        </div>
      )}
    </ColorContext.Consumer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    editProject: (
      id: string,
      title: string,
      memberEmails: string[],
      tags: string[],
      type: string,
      imageType: string,
      jiraIssueUrl: string,
      trelloIssueUrl: string,
    ) =>
      dispatch(
        editProjectAction(
          id,
          title,
          memberEmails,
          tags,
          type,
          imageType,
          jiraIssueUrl,
          trelloIssueUrl,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(Form.create()(CreateIssueForm));

import { colors } from '@atlaskit/theme';
import styled from '@emotion/styled';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { hexToRGBA } from 'utils';
import { IMasterProject, IProject } from '../../types/projectTypes';
import { grid } from '../constants';
import InnerList from './InnerList';

const getBackgroundColor = (isDraggingOver: boolean, isDraggingFrom: boolean) => {
  if (isDraggingOver) {
    return colors.R50;
  }
  if (isDraggingFrom) {
    return colors.T50;
  }
  return colors.N30;
};

const Wrapper = styled.div`
  background-color: ${(props: any) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px;
  height: 100%;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

interface ICanvasListProps {
  ignoreContainerClipping: boolean;
  internalScroll: boolean;
  isDropDisabled: boolean;
  isCombineEnabled: boolean;
  listType: string;
  canvases: IProject[];
  color: string;
  workflowIndex: number;
  selectedMasterProject: IMasterProject;
  history: any;
}

export default class CanvasList extends React.Component<ICanvasListProps, any> {
  render() {
    const {
      ignoreContainerClipping,
      internalScroll,
      isDropDisabled,
      isCombineEnabled,
      listType,
      canvases,
      color,
      workflowIndex,
      selectedMasterProject,
      history,
    } = this.props;
    return (
      <Droppable
        droppableId={`${workflowIndex}`}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
      >
        {(dropProvided: any, dropSnapshot: any) => (
          <Wrapper
            style={{
              height: 'auto',
              background: dropSnapshot.isDraggingOver ? hexToRGBA(color, 60) : hexToRGBA(color, 30),
            }}
            isDraggingOver={dropSnapshot.isDraggingOver}
            isDropDisabled={isDropDisabled}
            isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <ScrollContainer>
                <InnerList
                  selectedMasterProject={selectedMasterProject}
                  canvases={canvases}
                  dropProvided={dropProvided}
                  history={history}
                />
              </ScrollContainer>
            ) : (
              <InnerList
                selectedMasterProject={selectedMasterProject}
                canvases={canvases}
                dropProvided={dropProvided}
                history={history}
              />
            )}
          </Wrapper>
        )}
      </Droppable>
    );
  }
}

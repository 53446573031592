import { Button } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  children: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  htmlType?: any;
  primary?: boolean;
  secondary?: boolean;
  dashed?: boolean;
  danger?: boolean;
  style?: any;
  btnColors?: any;
  id?: string;
}

const StyleButton = styled(Button)`
  height: 35px;
  width: 120px;
  padding: 10px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	border-radius: 5px;
  border: none;
  ${({ primary }: IProps) =>
    primary &&
    css`
      color: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) => primaryBtn.color3};
      background: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) =>
        primaryBtn.color1};
      &:hover {
        color: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) => primaryBtn.color3};
        background: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) =>
          primaryBtn.color2};
      }
      &:focus {
        color: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) => primaryBtn.color3};
        background: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) =>
          primaryBtn.color2};
      }
      &:active {
        color: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) => primaryBtn.color3};
        background: ${({ btnColors: { primaryBtn } = { primaryBtn: '' } }: IProps) =>
          primaryBtn.color2};
      }
    `}
  ${({ secondary }: IProps) =>
    secondary &&
    css`
      color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
        secondaryBtn.color3};
      background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
        secondaryBtn.color1};
      &:hover {
        color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color3};
        background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color2};
      }
      &:focus {
        color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color3};
        background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color2};
      }
      &:active {
        color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color3};
        background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color2};
      }
    `}
  ${({ dashed }: IProps) =>
    dashed &&
    css`
      color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
        secondaryBtn.color3};
      background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
        secondaryBtn.color1};
      border: 1px dashed #000000;
      &:hover {
        color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color3};
        background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color2};
        border: 1px dashed #000000;
      }
      &:focus {
        color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color3};
        background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color2};
        border: 1px dashed #000000;
      }
      &:active {
        color: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color3};
        background: ${({ btnColors: { secondaryBtn } = { secondaryBtn: '' } }: IProps) =>
          secondaryBtn.color2};
        border: 1px dashed #000000;
      }
    `}
  ${({ danger }: IProps) =>
    danger &&
    css`
      color: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color3};
      background: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color1};
      &:hover {
        color: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color3};
        background: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color2};
      }
      &:focus {
        color: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color3};
        background: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color2};
      }
      &:active {
        color: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color3};
        background: ${({ btnColors: { dangerBtn } }: IProps) => dangerBtn.color2};
      }
    `}
`;

const Zbutton: React.FC<IProps> = ({
  children,
  onClick,
  loading,
  htmlType,
  disabled,
  primary,
  secondary,
  dashed,
  danger,
  style,
  id,
  btnColors,
}) => {
  return (
    <StyleButton
      onClick={onClick}
      loading={loading}
      htmlType={htmlType}
      disabled={disabled}
      primary={primary}
      secondary={secondary}
      dashed={dashed}
      danger={danger}
      btnColors={btnColors}
      style={style}
      id={id}
    >
      {children}
    </StyleButton>
  );
};

export default Zbutton;

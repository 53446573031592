import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import StatsList from './StatsList';

interface IOwnProps {
  stats: any;
  auth?: any;
  profile?: any;
}

class Stats extends Component<IOwnProps, any> {
  render() {
    const { profile, stats } = this.props;
    if (!profile.email || profile.role !== 'ADMIN') {
      return false && <Redirect to='/signin' />;
    }
    return (
      <div className='dashboard container'>
        <div className='row'>
          <div className='col s12 m6'>
            <StatsList stats={stats} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    stats: state.firestore.ordered.stats,
  };
};

export default compose<any>(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: 'stats',
    },
  ]),
)(Stats);

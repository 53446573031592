import React, { Fragment } from 'react';
import { SketchPicker } from 'react-color';

interface IProps {
  bgColor: string;
  colorName: string;
  selectedColor: string;
  background: string;
  displayColorPicker: boolean;
  handleClose: () => void;
  handleChangeColor: (color: any, pickedColor: any) => void;
  handleClick: (color: string, colorName: string) => void;
}

const OrgColorList: React.FC<IProps> = ({
  bgColor,
  colorName,
  selectedColor,
  background,
  handleClick,
  handleClose,
  handleChangeColor,
  displayColorPicker,
}) => {
  return (
    <Fragment>
      {bgColor && (
        <div>
          <div>
            <div
              className={selectedColor === colorName ? 'selected-branding-color' : 'branding-color'}
              style={{ background: bgColor }}
              onClick={() => handleClick(bgColor, colorName)}
            />
            <p className='color-code'>{bgColor}</p>
          </div>
          {selectedColor === colorName && displayColorPicker ? (
            <div style={{ position: 'absolute', zIndex: 2 }}>
              <div
                style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}
                onClick={handleClose}
              />
              <SketchPicker
                color={background}
                onChange={(e: any) => handleChangeColor(e, selectedColor)}
              />
            </div>
          ) : null}
        </div>
      )}
    </Fragment>
  );
};

export default OrgColorList;

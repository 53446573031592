import { Drawer } from 'antd';
import React from 'react';
import { Close } from '../../svg';
interface Props {
  visible: boolean;
  ChildComponent: any;
  onClose: () => void;
  title?: string;
  screenCoverage: number;
  direction: 'right' | 'left';
  isNotFlex?: boolean;
}

const SideDrawer = (props: Props) => {
  const {
    visible,
    ChildComponent,
    onClose,
    title = '',
    screenCoverage = 80,
    direction = 'right',
    isNotFlex = false,
  } = props;

  return (
    <Drawer
      title={title}
      placement={direction}
      closable={false}
      onClose={onClose}
      visible={visible}
      style={{ position: 'absolute' }}
      width={`${screenCoverage}vw`}
    >
      <div className={`${isNotFlex ? '' : 'd-flex'}`}>
        <ChildComponent />
      </div>
      <div className='drawer-close-btn' onClick={onClose}>
        <Close />
      </div>
    </Drawer>
  );
};

export default React.memo(SideDrawer);

import React from 'react';

export default ({ color }: any) => (
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 8.74227e-08H7V2H0V8.74227e-08Z' fill={color ? color : '#333333'} />
    <path d='M0 7V8.74227e-08L2 0L2 7H0Z' fill={color ? color : '#333333'} />
    <path d='M19 0L19 7L17 7L17 -8.74228e-08L19 0Z' fill={color ? color : '#333333'} />
    <path d='M12 -3.0598e-07L19 0L19 2L12 2L12 -3.0598e-07Z' fill={color ? color : '#333333'} />
    <path d='M19 19L12 19L12 17L19 17L19 19Z' fill={color ? color : '#333333'} />
    <path d='M19 12L19 19L17 19L17 12L19 12Z' fill={color ? color : '#333333'} />
    <path
      d='M8.74227e-08 19L-2.18557e-07 12L2 12L2 19L8.74227e-08 19Z'
      fill={color ? color : '#333333'}
    />
    <path d='M7 19L8.74227e-08 19L-8.74228e-08 17L7 17L7 19Z' fill={color ? color : '#333333'} />
  </svg>
);

import { Col, Drawer, Row } from 'antd';
import React from 'react';
import { trackAnalytics } from 'utils';
import ColorContext from '../../context/ColorContext';
import { Zbutton } from '../../primitives';
import { mobileCheck } from '../../utils';
import JiraConnect from '../jira-integration';
import SlackConnect from '../slack-integration';
// AssanaIcon,
import { Basecamp, Chrome, Close, Jira, MondayIcon, SlackIcon, TrelloIcon } from '../svg';
import TrelloConnect from '../trello-integration';
import './profilemenu.css';

const isMobile = mobileCheck();

interface IProps {
  onCloseDrawer: () => void;
  visible: boolean;
  selectedOrganizationData: any;
}

const IntegrationDrawer: React.FC<IProps> = ({
  onCloseDrawer,
  visible,
  selectedOrganizationData,
}) => {
  return (
    <div>
      <Drawer
        title='Integrations'
        placement='right'
        closable={false}
        onClose={() => onCloseDrawer()}
        visible={visible}
        style={{ position: 'absolute' }}
        width='75vw'
      >
        <ColorContext.Consumer>
          {({ theme }) => (
            <div className='integration-container' style={{ color: theme.text.color2 }}>
              <Row gutter={isMobile ? undefined : 24} justify='space-around'>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        <Chrome />
                      </div>
                      <h3 className='integration-header'>Chrome ext.</h3>
                      <span>Install Zooc Chrome Extension</span>
                      <Zbutton primary btnColors={theme}>
                        <a
                          onClick={() => trackAnalytics('Integration', 'Chrome ext. Clicked')}
                          href='https://zooc.tawk.help/article/chrome-extension-beta'
                          target='blank'
                        >
                          Install
                        </a>
                      </Zbutton>
                    </div>
                  </div>
                </Col>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        <SlackIcon />
                      </div>
                      <h3 className='integration-header'>Zooc Slack app</h3>
                      <span>Install Zooc in your Slack workspace</span>
                      <Zbutton primary btnColors={theme}>
                        <a
                          onClick={() => trackAnalytics('Integration', 'Zooc Slack app Clicked')}
                          href='https://slack.com/oauth/v2/authorize?client_id=383656572982.1044683234035&scope=chat:write,commands,files:write&user_scope=chat:write,files:write'
                          target='blank'
                        >
                          Install
                        </a>
                      </Zbutton>
                    </div>
                  </div>
                </Col>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        <SlackIcon />
                      </div>
                      <h4 className='integration-header'>Slack Webhook</h4>
                      <span>Get notified on Slack instantly</span>
                      <SlackConnect selectedOrganizationData={selectedOrganizationData} />
                    </div>
                  </div>
                </Col>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        <Jira />
                      </div>
                      <h3 className='integration-header'>Jira</h3>
                      <span>Connect zooc with your Jira workflow</span>
                      <JiraConnect selectedOrganizationData={selectedOrganizationData} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={24} justify='space-around'>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        <TrelloIcon />
                      </div>
                      <h3 className='integration-header'>Trello</h3>
                      <span>Connect zooc with your Trello boards</span>
                      <TrelloConnect selectedOrganizationData={selectedOrganizationData} />
                    </div>
                  </div>
                </Col>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        {/* <AssanaIcon /> */}
                        <img src='/images/asana.png' alt='Asana' />
                      </div>
                      <h3 className='integration-header'>Asana</h3>
                      <span>Connect zooc with your Asana workflows</span>
                      <Zbutton
                        onClick={() => trackAnalytics('Integration', 'Asana workflows Clicked')}
                        primary
                        btnColors={theme}
                      >
                        Coming soon
                      </Zbutton>
                    </div>
                  </div>
                </Col>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        <MondayIcon />
                      </div>
                      <h3 className='integration-header'>Monday.com</h3>
                      <span>Connect zooc with Monday.com</span>
                      <Zbutton
                        onClick={() => trackAnalytics('Integration', 'Monday.com Clicked')}
                        primary
                        btnColors={theme}
                      >
                        Coming soon
                      </Zbutton>
                    </div>
                  </div>
                </Col>
                <Col className='gutter-row' span={isMobile ? 24 : 6}>
                  <div
                    className='integration-column'
                    style={{ background: theme.backgrounds.color1 }}
                  >
                    <div className='integration-content'>
                      <div>
                        <Basecamp />
                      </div>
                      <h3 className='integration-header'>Basecamp</h3>
                      <span>Connect zooc with Basecamp</span>
                      <Zbutton
                        onClick={() => trackAnalytics('Integration', 'Basecamp Clicked')}
                        primary
                        btnColors={theme}
                      >
                        Coming soon
                      </Zbutton>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </ColorContext.Consumer>
        <div className='drawer-close-btn' onClick={() => onCloseDrawer()}>
          <Close />
        </div>
      </Drawer>
    </div>
  );
};

export default IntegrationDrawer;

import { Form, Modal } from 'antd';
import firebase from 'firebase';
import React, { FC, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { Iworkflow } from '../../types/projectTypes';
import ColorPicker from '../shared/ColorPicker';

interface IProps {
  project: any;
  selectedWorkflow: Iworkflow;
  visible: boolean;
  handleCancel: () => void;
  isEdit: boolean;
  form: any;
}

const WorkflowDialog: FC<IProps> = ({
  form: { resetFields, validateFieldsAndScroll, getFieldDecorator },
  project,
  selectedWorkflow,
  handleCancel,
  visible,
  isEdit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateStep, setUpdateStep] = useState('');
  const [updateColor, setUpdateColor] = useState('');
  const [colorState, setColorState] = useState({
    selectedIndex: isEdit ? 14 : 3,
    selectedColor: isEdit ? updateColor : '#219653',
  });
  const [background, setBackground] = useState<string>('#FFFFFF');

  useEffect(() => {
    setUpdateStep(isEdit && selectedWorkflow.step ? selectedWorkflow.step : '');
    setUpdateColor(isEdit && selectedWorkflow.color ? selectedWorkflow.color : '');
  }, [isEdit, selectedWorkflow]);

  useEffect(() => {
    isEdit && setBackground(updateColor);
  }, [updateColor]);

  const reset = () => {
    setColorState({
      ...colorState,
      selectedIndex: isEdit ? 14 : 3,
      selectedColor: isEdit ? updateColor : '#219653',
    });
    setIsLoading(false);
    resetFields();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err: any, values: any) => {
      const { stepName } = values;
      setIsLoading(true);
      const updatedWorkflow = project.workflow.map((step: Iworkflow) => {
        if (selectedWorkflow.id === step.id) {
          return {
            ...step,
            step: stepName,
            color: updateColor,
          };
        } else {
          return step;
        }
      });
      if (!err || err === null) {
        setIsLoading(true);
        const firestore = firebase.firestore();
        if (!isEdit) {
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .doc(project.id)
            .set(
              {
                updatedTimeStamp,
                workflow: [
                  ...project.workflow,
                  {
                    id: uuid(),
                    step: stepName,
                    color: colorState.selectedColor,
                    total: 0,
                  },
                ],
              },
              { merge: true },
            )
            .then(() => {
              statsAction('CREATE_WORKFLOW_STEP', firestore);
              reset();
              handleCancel();
            })
            .catch(() => {
              setIsLoading(false);
              return false;
            });
        } else {
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .doc(project.id)
            .set({
              ...project,
              workflow: updatedWorkflow,
              updatedTimeStamp,
            })
            .then(() => {
              statsAction('UPDATE_WORKFLOW_STEP', firestore);
              reset();
              handleCancel();
            })
            .catch(() => {
              setIsLoading(false);
              return false;
            });
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <div>
      <ColorContext.Consumer>
        {({ theme }) => (
          <Modal
            visible={visible}
            onOk={handleCancel}
            onCancel={() => handleCancel()}
            footer={null}
          >
            <div className='add-step-container' style={{ color: theme.text.color2 }}>
              <h2 style={{ color: theme.text.color2 }}>Add workflow step</h2>
              {/* <p>Some contents...</p> */}
              <Form className='white'>
                <Form.Item>
                  <div
                    className='selected-color'
                    style={{ background: isEdit ? updateColor : colorState.selectedColor }}
                  />
                  {getFieldDecorator('stepName', {
                    initialValue: isEdit && updateStep ? updateStep : '',
                    rules: [
                      {
                        required: true,
                        message: 'Please input step name!',
                        whitespace: true,
                      },
                    ],
                  })(<Zinput placeholder='Enter Step Name' secondary />)}
                </Form.Item>
                <Form.Item>
                  <ColorPicker
                    background={background}
                    setBackground={setBackground}
                    colorState={colorState}
                    setColorState={setColorState}
                    setUpdateColor={setUpdateColor}
                  />
                </Form.Item>
                <div className='add-step-btn-container'>
                  <Zbutton
                    secondary
                    btnColors={theme}
                    style={{ marginRight: 10 }}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Zbutton>
                  <Zbutton
                    key='submit'
                    primary
                    btnColors={theme}
                    loading={isLoading}
                    onClick={handleSubmit}
                  >
                    {isEdit ? 'Save Changes' : 'Create Step'}
                  </Zbutton>
                </div>
              </Form>
            </div>
          </Modal>
        )}
      </ColorContext.Consumer>
    </div>
  );
};

export default Form.create<IProps>()(WorkflowDialog);

import { Progress, Typography } from 'antd';
import React from 'react';
import ColorContext from '../../context/ColorContext';
// import { Close } from '../svg';
import './style.css';

interface IProps {
  profile: any;
}

const UserQuota: React.FC<IProps> = ({ profile }) => {
  const { userQuota = {}, plan } = profile;
  const {
    maxChromeExtensionScreenshots = 0,
    chromeExtensionScreenshots = 0,
    storageLimit = 0,
    maxStorageLimit = 0,
    totalVoiceClips = 0,
    maxTotalVoiceClips = 0,
    totalVideoComments = 0,
    maxTotalVideoComments = 0,
    totalScreenRecordings = 0,
    maxTotalScreenRecordings = 0,
    canvasesCount = '',
    maxCanvasesCount = '',

    // jiraIntegration = false,
    // trelloIntegration = false,
    // slackIntegration = true,
    // abilityToAddCommentOnVideo = false,
    // commentOnVideoFromThirdParty = true,
    // whitelabel = false,

    // recordVoiceClipTime = 0,
    // videoCommentTime = 0,
    // screenRecordingCommentTime = 0,
    // manageMultipleProjects = false,
    // manageMultipleWorkspace = false
  } = userQuota;
  const getPlanLabel = (planKey: string) => {
    let planLabel = '';
    switch (planKey) {
      case 'freelancer':
        planLabel = 'Freelancers Plan Feature';
        break;
      case 'productTeam':
        planLabel = 'Product Teams Plan Feature';
        break;
      case 'digitalAgency':
        planLabel = 'Digital Agencies Plan Features';
        break;
    }
    return planLabel;
  };
  return (
    <div>
      {/* <Drawer
                title='Usage Quota'
                placement='right'
                closable={false}
                onClose={() => onCloseDrawer()}
                visible={visible}
                style={{ position: 'absolute', overflowY: 'auto' }}
                width='75vw'
            >
                <div className='drawer-close-btn' onClick={() => onCloseDrawer()}>
                    <Close />
                </div> */}
      <ColorContext.Consumer>
        {({ theme }) => (
          <div
            className={`edit-profile-userquota-container ${profile.isManagedUserQuotaInOrganization &&
              'quota-in-usage-drawer'}`}
            style={{ color: theme.text.color2 }}
          >
            <div>
              <Typography.Text strong={true} className='settings-form-label'>
                {getPlanLabel(plan)}
              </Typography.Text>
              {maxChromeExtensionScreenshots && (
                <div className='pt-5'>
                  <div className='user-quota-details pt-5 user-quota-progress-container'>
                    <span className='display-block'>Chrome Extension Screenshot Count: </span>
                    <span color='#039BE5'>
                      {' '}
                      {maxChromeExtensionScreenshots === -1
                        ? 'Unlimited'
                        : `${maxChromeExtensionScreenshots -
                            chromeExtensionScreenshots} / ${maxChromeExtensionScreenshots}`}
                    </span>
                  </div>
                  {maxChromeExtensionScreenshots !== -1 ? (
                    <Progress
                      percent={
                        ((maxChromeExtensionScreenshots - chromeExtensionScreenshots) /
                          maxChromeExtensionScreenshots) *
                        100
                      }
                      showInfo={false}
                    />
                  ) : null}
                </div>
              )}

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Storage Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxStorageLimit
                      ? storageLimit === -1
                        ? 'Unlimited'
                        : `${((maxStorageLimit - storageLimit) / (1024 * 1024)).toFixed(2)} / ${(
                            maxStorageLimit /
                            (1024 * 1024)
                          ).toFixed(0)} GB`
                      : '0 GB'}
                  </span>
                </div>
                {maxStorageLimit !== -1 ? (
                  <Progress
                    percent={((maxStorageLimit - storageLimit) / maxStorageLimit) * 100}
                    showInfo={false}
                  />
                ) : null}
              </div>

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Voice Clips Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxTotalVoiceClips
                      ? maxTotalVoiceClips === -1
                        ? 'Unlimited'
                        : `${maxTotalVoiceClips - totalVoiceClips} / ${maxTotalVoiceClips}`
                      : 0}
                  </span>
                </div>
                {maxTotalVoiceClips !== -1 ? (
                  <Progress
                    percent={((maxTotalVoiceClips - totalVoiceClips) / maxTotalVoiceClips) * 100}
                    showInfo={false}
                  />
                ) : null}
              </div>

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Video Comments Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxTotalVideoComments
                      ? maxTotalVideoComments === -1
                        ? 'Unlimited'
                        : `${maxTotalVideoComments - totalVideoComments} / ${maxTotalVideoComments}`
                      : 0}
                  </span>
                </div>
                {maxTotalVideoComments !== -1 ? (
                  <Progress
                    percent={
                      ((maxTotalVideoComments - totalVideoComments) / maxTotalVideoComments) * 100
                    }
                    showInfo={false}
                  />
                ) : null}
              </div>

              <div className='pt-5'>
                <div className='user-quota-details pt-5 user-quota-progress-container'>
                  <span className='display-block'>Screen Recordings Limit:</span>
                  <span color='#039BE5'>
                    {' '}
                    {maxTotalScreenRecordings
                      ? maxTotalScreenRecordings === -1
                        ? 'Unlimited'
                        : `${maxTotalScreenRecordings -
                            totalScreenRecordings} / ${maxTotalScreenRecordings}`
                      : 0}
                  </span>
                </div>
                {maxTotalScreenRecordings !== -1 ? (
                  <Progress
                    percent={
                      ((maxTotalScreenRecordings - totalScreenRecordings) /
                        maxTotalScreenRecordings) *
                      100
                    }
                    showInfo={false}
                  />
                ) : null}
              </div>

              {maxCanvasesCount && (
                <div className='pt-5'>
                  <div className='user-quota-details pt-5 user-quota-progress-container'>
                    <span className='display-block'>Canvas Count:</span>
                    <span color='#039BE5'>
                      {' '}
                      {canvasesCount === -1
                        ? 'Unlimited'
                        : `${maxCanvasesCount - canvasesCount} / ${maxCanvasesCount}`}
                    </span>
                  </div>
                  {maxCanvasesCount !== -1 ? (
                    <Progress
                      percent={((maxCanvasesCount - canvasesCount) / maxCanvasesCount) * 100}
                      showInfo={false}
                    />
                  ) : null}
                </div>
              )}

              {/* <div className='user-quota-details pt-5'>
                                <span className='display-block'>Jira Integration:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {jiraIntegration
                                        ? 'Yes'
                                        : 'No'
                                    }
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Trello Integration:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {trelloIntegration
                                        ? 'Yes'
                                        : 'No'
                                    }
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Slack Integration:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {slackIntegration
                                        ? 'Yes'
                                        : 'No'
                                    }
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Ability To Add Comment On Video:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {abilityToAddCommentOnVideo
                                        ? 'Yes'
                                        : 'No'
                                    }
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Comment On Video From Third Party:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {commentOnVideoFromThirdParty
                                        ? 'Yes'
                                        : 'No'
                                    }
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Whitelabel:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {whitelabel
                                        ? 'Yes'
                                        : 'No'
                                    }
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Voice Clip Recording Time:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {recordVoiceClipTime ? recordVoiceClipTime === -1 ? 'Unlimited' : `${recordVoiceClipTime} Minutes` : '0 Minute'}
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Video Recording Time:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {videoCommentTime ? videoCommentTime === -1 ? 'Unlimited' : `${videoCommentTime} Minutes` : '0 Minute'}
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Manage Multiple Projects:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {manageMultipleProjects
                                        ? 'Yes'
                                        : 'No'}
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Manage Multiple Workspace:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {manageMultipleWorkspace
                                        ? 'Yes'
                                        : 'No'}
                                </Tag>
                            </div>

                            <div className='user-quota-details pt-5'>
                                <span className='display-block'>Screen Recording Time:</span>
                                <Tag color='#039BE5'>
                                    {' '}
                                    {screenRecordingCommentTime ? screenRecordingCommentTime === -1
                                        ? 'Unlimited'
                                        : `${screenRecordingCommentTime} Minutes` : '0 Minute'}
                                </Tag>
                            </div> */}
            </div>
          </div>
        )}
      </ColorContext.Consumer>
      {/* </Drawer> */}
    </div>
  );
};

export default UserQuota;

import { Modal } from 'antd';
import React from 'react';

interface CommentInfoMapper {
  data: any;
  showPreview: boolean;
  setPreview: () => void;
}

export const CommentInfoModal = ({ data, showPreview, setPreview }: CommentInfoMapper) => {
  return (
    <Modal
      title='Navigator Information'
      visible={showPreview}
      style={{ textAlign: 'center' }}
      onCancel={setPreview}
      footer={null}
    >
      {data}
    </Modal>
  );
};

import { Form, message, Modal, Radio, Select } from 'antd';
import firebase from 'firebase';
import React, { Dispatch, FC, Fragment, SetStateAction, useState } from 'react';
import { connect } from 'react-redux';
import ColorContext from '../../context/ColorContext';
import { Zbutton, Zinput } from '../../primitives';
import { statsAction } from '../../store/actions/authActions';
import { Iworkflow } from '../../types/projectTypes';
import { ArrowBack } from '../svg';

const { Option } = Select;

interface IProps {
  visible: boolean;
  handleCancel: () => void;
  isOptionSelected: boolean;
  setIsOptionSelected: Dispatch<SetStateAction<boolean>>;
  selectedWorkflow: Iworkflow;
  project: any;
  form: any;
  auth: any;
}

const DeleteWorkflowStep: FC<IProps> = ({
  visible,
  handleCancel,
  isOptionSelected,
  setIsOptionSelected,
  selectedWorkflow,
  project,
  form,
  auth,
}) => {
  const [deleteOption, setDeleteOption] = useState('delete');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState('');

  const onChange = (e: any) => {
    setDeleteOption(e.target.value);
  };

  const handleChange = (value: string) => {
    setSelectedWorkflowId(value);
  };

  const reset = () => {
    setIsLoading(false);
    handleCancel();
    form.resetFields();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      const { stepName } = values;
      const deletedWorkflow = project.workflow.filter((step: Iworkflow) => {
        return selectedWorkflow.id !== step.id;
      });
      if (!err) {
        setIsLoading(true);
        const firestore = firebase.firestore();
        if (stepName === selectedWorkflow.step) {
          const updatedTimeStamp = new Date().getTime();
          return firestore
            .collection('masterProjects')
            .doc(project.id)
            .set({
              ...project,
              workflow: deletedWorkflow,
              updatedTimeStamp,
            })
            .then(() => {
              statsAction('DELETE_WORKFLOW_STEP', firestore);
              reset();
            })
            .catch(() => {
              setIsLoading(false);
              handleCancel();
              return false;
            });
        } else if (deleteOption === 'move' && selectedWorkflow.total !== 0) {
          const workflowLimit = Math.ceil(selectedWorkflow.total / 500);
          const batch = firestore.batch();
          for (const i: number = 1; i <= workflowLimit; ) {
            return firestore
              .collection('projects')
              .where('project_created_by', '==', auth.uid)
              .where('workflowId', '==', selectedWorkflow.id)
              .limit(500)
              .get()
              .then((snap: any) => {
                if (!snap.empty) {
                  snap.forEach((doc: any) => {
                    const workflowRef = firestore.collection('projects').doc(doc.id);
                    batch.update(workflowRef, { workflowId: selectedWorkflowId });
                  });
                  batch
                    .commit()
                    .then(() => {
                      const updatedTimeStamp = new Date().getTime();
                      return firestore
                        .collection('masterProjects')
                        .doc(project.id)
                        .set({
                          ...project,
                          workflow: deletedWorkflow,
                          updatedTimeStamp,
                        });
                    })
                    .then(() => {
                      statsAction('WORKFLOW_CANVASES_MOVED', firestore);
                      setIsLoading(false);
                      message.success('All the canvases are moved successfully');
                      handleCancel();
                    })
                    .catch(() => {
                      setIsLoading(false);
                      handleCancel();
                      message.error('The move has been not occurred, Please try it again');
                      return false;
                    });
                }
              });
          }
        }
      }
    });
  };

  const intialValue = () => {
    const firstIndex =
      project &&
      project.workflow
        .map((wf: any) => {
          if (wf.id !== selectedWorkflow.id) {
            return wf.step;
          }
        })
        .filter((step: any) => step);
    return firstIndex[0];
  };

  const deleteWorkflowOptions = () => {
    if (deleteOption === 'delete') {
      return (
        <ColorContext.Consumer>
          {({ theme }) => (
            <Fragment>
              <p style={{ color: theme.text.color2 }}>
                This will delete 34 canvases from In Progress workflow step.
              </p>
              <p style={{ color: theme.text.color2 }}>
                To delete <strong>{selectedWorkflow.step}</strong> workflow step, please type it in
                the following input:
              </p>
              <Form className='white'>
                <Form.Item>
                  {form.getFieldDecorator('stepName', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input step name!',
                        whitespace: true,
                      },
                    ],
                  })(<Zinput placeholder='Enter Step Name' />)}
                </Form.Item>
              </Form>
            </Fragment>
          )}
        </ColorContext.Consumer>
      );
    } else if (deleteOption === 'move') {
      return (
        <ColorContext.Consumer>
          {({ theme }) => (
            <div style={{ paddingTop: 30 }}>
              <p style={{ color: theme.text.color2 }}>
                To delete <strong>{selectedWorkflow.step}</strong> select the workflow step where
                you would like to move its canvasses
              </p>
              <Select defaultValue={intialValue()} style={{ width: 150 }} onChange={handleChange}>
                {project.workflow.map((step: Iworkflow) => {
                  if (step.id !== selectedWorkflow.id) {
                    return (
                      <Option className='select-option' key={step.id} value={step.id}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <div className='step-color' style={{ background: step.color }} />
                          <div>{step.step}</div>
                        </div>
                      </Option>
                    );
                  }
                })}
              </Select>
            </div>
          )}
        </ColorContext.Consumer>
      );
    }
  };

  return (
    <Modal
      visible={visible}
      onOk={handleCancel}
      onCancel={() => handleCancel()}
      footer={null}
      style={{ position: 'relative' }}
    >
      {isOptionSelected && (
        <div className='arrow-back-btn' onClick={() => setIsOptionSelected(false)}>
          <ArrowBack />
        </div>
      )}
      <ColorContext.Consumer>
        {({ theme }) => (
          <div className='add-step-container' style={{ color: theme.text.color2 }}>
            {!isOptionSelected ? (
              <Fragment>
                <h3 style={{ color: '#EB5757' }}>WARNING!</h3>
                <p>This workflow step has canvasses, deleting this will have consequencess.</p>
                <div className='radio-btn-container'>
                  <p style={{ color: theme.text.color2 }}>What do you want to do? </p>
                  <Radio.Group onChange={onChange} value={deleteOption}>
                    <Radio value={'delete'}>Delete it anyways</Radio>
                    <Radio value={'move'}>
                      Delete it and move its canvasses to another workflow step
                    </Radio>
                  </Radio.Group>
                </div>
              </Fragment>
            ) : (
              deleteWorkflowOptions()
            )}
            <div className='add-step-btn-container'>
              <Zbutton secondary btnColors={theme} onClick={() => handleCancel()}>
                Cancel
              </Zbutton>
              <Zbutton
                style={{ marginLeft: 10, width: 160 }}
                key='submit'
                primary
                btnColors={theme}
                loading={isLoading}
                onClick={(e) => {
                  isOptionSelected ? handleSubmit(e) : setIsOptionSelected(true);
                }}
              >
                {isOptionSelected ? 'Delete Workflow Step' : 'Next'}
              </Zbutton>
            </div>
          </div>
        )}
      </ColorContext.Consumer>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create<IProps>()(DeleteWorkflowStep));

import { Input } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: any;
  secondary?: boolean;
  bgcolor?: any;
  defaultValue?: string;
}

const StyleInput = styled(Input)`
  width: 100%;
  height: 45px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  background: ${({ bgcolor }: IProps) => bgcolor};
  color: #333333 !important;
  &:hover {
    border: 1px solid #001dad;
    background: #ffffff;
  }
  &:focus {
    border: 1px solid #001dad;
    background: #ffffff;
    outline: none;
  }
  &:active {
    border: 1px solid #001dad;
    background: #ffffff;
  }
  ${({ secondary }: IProps) =>
    secondary &&
    css`
      padding-left: 28px !important;
    `}
`;

const Zinput: React.FC<IProps> = ({
  placeholder,
  type,
  value,
  onChange,
  secondary,
  bgcolor,
  defaultValue,
}) => {
  return (
    <StyleInput
      bgcolor={bgcolor}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={type}
      secondary={secondary}
      defaultValue={defaultValue}
    />
  );
};

export default Zinput;

import React from 'react';

export default () => (
  <svg width='35' height='33' viewBox='0 0 35 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M34.6004 28.2107L20.7104 2.65073C20.4339 2.14133 20.0251 1.716 19.527 1.41962C19.0289 1.12324 18.46 0.966797 17.8804 0.966797C17.3008 0.966797 16.732 1.12324 16.2339 1.41962C15.7358 1.716 15.3269 2.14133 15.0504 2.65073L1.17043 28.2107C0.895956 28.7022 0.755116 29.257 0.761957 29.8199C0.768797 30.3828 0.92308 30.934 1.20942 31.4187C1.49576 31.9034 1.90415 32.3045 2.39387 32.5821C2.88359 32.8596 3.43752 33.004 4.00043 33.0007H31.7704C32.3276 33.0002 32.8751 32.855 33.3594 32.5795C33.8437 32.304 34.2482 31.9075 34.5334 31.4288C34.8186 30.9502 34.9747 30.4057 34.9864 29.8486C34.9982 29.2916 34.8652 28.741 34.6004 28.2507V28.2107ZM16.6004 9.00073C16.6004 8.62943 16.7479 8.27333 17.0105 8.01078C17.273 7.74823 17.6291 7.60073 18.0004 7.60073C18.3717 7.60073 18.7278 7.74823 18.9904 8.01078C19.2529 8.27333 19.4004 8.62943 19.4004 9.00073V21.0007C19.4004 21.372 19.2529 21.7281 18.9904 21.9907C18.7278 22.2532 18.3717 22.4007 18.0004 22.4007C17.6291 22.4007 17.273 22.2532 17.0105 21.9907C16.7479 21.7281 16.6004 21.372 16.6004 21.0007V9.00073ZM18.0004 28.8507C17.6444 28.8507 17.2964 28.7452 17.0004 28.5474C16.7044 28.3496 16.4737 28.0685 16.3375 27.7396C16.2012 27.4107 16.1656 27.0487 16.235 26.6996C16.3045 26.3504 16.4759 26.0297 16.7276 25.7779C16.9794 25.5262 17.3001 25.3548 17.6493 25.2853C17.9984 25.2159 18.3604 25.2515 18.6893 25.3877C19.0182 25.524 19.2993 25.7547 19.4971 26.0507C19.6949 26.3467 19.8004 26.6947 19.8004 27.0507C19.8004 27.2871 19.7539 27.5212 19.6634 27.7396C19.573 27.9579 19.4404 28.1564 19.2732 28.3235C19.1061 28.4907 18.9076 28.6233 18.6893 28.7137C18.4709 28.8042 18.2368 28.8507 18.0004 28.8507Z'
      fill='white'
    />
  </svg>
);

import firebase from 'firebase/app';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { hexToRGBA } from 'utils';
import KanbanBoard from '../../kanban-board/index';
import { IMasterProject, IProject, IworkflowCanvas } from '../../types/projectTypes';
import ProjectList from '../projects/ProjectList';

interface IProps {
  projects: IProject[];
  auth?: any;
  isWriteAccess: boolean;
  signUpDays: number;
  selectedMasterProject: IMasterProject;
  onDelete: any;
  loader: boolean;
  profile: any;
  workflowCanvas: IworkflowCanvas[];
  loadMoreCanvas: any;
  paginationLimit: number;
  internalScroll: boolean;
  updateStateOnDragEnd: (asdf: any) => void;
  selectedOrganizationData: any;
  activeColumnId: string;
  setActiveColumnId: any;
  innerLoader: boolean;
  history: any;
}

export const KanbanList: React.FC<IProps> = ({
  projects,
  auth,
  isWriteAccess,
  signUpDays,
  selectedMasterProject,
  onDelete,
  loader,
  profile,
  workflowCanvas,
  loadMoreCanvas,
  paginationLimit,
  internalScroll,
  updateStateOnDragEnd,
  selectedOrganizationData,
  activeColumnId,
  setActiveColumnId,
  innerLoader,
  history,
}) => {
  const reorder = (column: any, startIndex: number, endIndex: number) => {
    const result = Array.from(column);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    const updatedTimeStamp = new Date().getTime();
    window.localStorage.setItem('updatedTimeStamp', updatedTimeStamp.toString());
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const firestore = firebase.firestore();

    if (source.droppableId === destination.droppableId && source.idex === destination.index) {
      return;
    }

    if (type === 'COLUMN') {
      const ordered = reorder(workflowCanvas, source.index, destination.index);
      let updatedOrdered = JSON.parse(JSON.stringify(ordered));
      updatedOrdered = updatedOrdered.filter((order: any) => {
        delete order.canvases;
        delete order.isMoreCanvas;
        delete order.loadingMoreCanvas;
        return order;
      });
      updateStateOnDragEnd(ordered);
      firestore
        .collection('masterProjects')
        .doc(selectedMasterProject.id)
        .update({
          workflow: updatedOrdered,
          updatedTimeStamp,
        });
      return;
    }

    /*
    Logic for calculating the canvas sequence number:

    1. when there is no canvas on a column,  just start with 1 as sequence number
    2. when there are more 3+ canvases and the canvas is dropped between two other canvases then use this fomula:

      formula: (prevCanvasSequenceNumber + nextCanvasSequenceNumber)/2

    3. when the canvas is dropped to the end of the column, then use this formula:

      formula: prevCanvasSequenceNumber + 0.000001

    4. when the canvas is dropped to the start of the column, then use this formula:

        formula: prevCanvasSequenceNumber - 0.000001
    */
    if (source.droppableId !== destination.droppableId && type === 'canvas') {
      const sourceColumn = workflowCanvas[source.droppableId];
      const destColumn = workflowCanvas[destination.droppableId];
      const sourceCanvases = [...sourceColumn.canvases];
      const destCanvases = [...destColumn.canvases];

      const canvasId = sourceCanvases[source.index].id;

      const [removed] = sourceCanvases.splice(source.index, 1);
      destCanvases.splice(destination.index, 0, removed);

      const diffValue = 0.000001;
      let updatedSequenceNumber = 1;
      if (destColumn.canvases.length) {
        // If first canvas then update with default sequence no 1
        if (destination.index) {
          if (destColumn.canvases.length >= 2 && destination.index < destColumn.canvases.length) {
            // if updating on center position then update with avg value of above and below canvas
            updatedSequenceNumber =
              (destColumn.canvases[destination.index - 1].sequenceNumber +
                destColumn.canvases[destination.index].sequenceNumber) /
              2;
          } else {
            // if updating on last position then update with last plus 0.000001
            updatedSequenceNumber =
              diffValue + destColumn.canvases[destination.index - 1].sequenceNumber;
          }
        } else {
          // if updating on first position then update with first minus 0.000001
          updatedSequenceNumber = destColumn.canvases[destination.index].sequenceNumber - diffValue;
        }
      }

      workflowCanvas[source.droppableId] = {
        ...sourceColumn,
        canvases: sourceCanvases,
      };
      destCanvases[destination.index].sequenceNumber = updatedSequenceNumber;
      workflowCanvas[destination.droppableId] = {
        ...destColumn,
        canvases: destCanvases,
      };

      updateStateOnDragEnd(workflowCanvas);
      firestore
        .collection('projects')
        .doc(canvasId)
        .update({
          sequenceNumber: updatedSequenceNumber,
          workflowId: destColumn.id,
        });
      // destCanvases.map((destCanvas: any, index: number) => {
      //   return firestore
      //     .collection('projects')
      //     .doc(destCanvas.id)
      //     .update({
      //       workflowId: destColumn.id,
      //       sequenceNumber: index
      //     });
      // });

      const workflow = workflowCanvas.map((workflowItem: any, index: number) => {
        if (index === parseInt(source.droppableId, 10)) {
          workflowItem.total = sourceCanvases.length;
        }
        if (index === parseInt(destination.droppableId, 10)) {
          workflowItem.total = destCanvases.length;
        }
        return workflowItem;
      });
      firestore
        .collection('masterProjects')
        .doc(selectedMasterProject.id)
        .update({
          workflow,
          updatedTimeStamp,
        });
    } else {
      const column = workflowCanvas[source.droppableId];
      const copiedCanvases = [...column.canvases];
      const canvasId = copiedCanvases[source.index].id;
      const diffValue = 0.000001;
      let updatedSequenceNumber = 0;
      if (destination.index) {
        if (copiedCanvases.length >= 2 && destination.index < copiedCanvases.length - 1) {
          // if updating on center position then update with avg value of above and below canvas
          updatedSequenceNumber =
            (copiedCanvases[destination.index + 1].sequenceNumber +
              copiedCanvases[destination.index].sequenceNumber) /
            2;
        } else {
          // if updating on last position then update with last plus 0.000001
          updatedSequenceNumber = diffValue + column.canvases[destination.index].sequenceNumber;
        }
      } else {
        // if updating on first position then update with first minus 0.000001
        updatedSequenceNumber = column.canvases[destination.index].sequenceNumber - diffValue;
      }

      const [removed] = copiedCanvases.splice(source.index, 1);
      copiedCanvases.splice(destination.index, 0, removed);
      copiedCanvases[destination.index].sequenceNumber = updatedSequenceNumber;
      workflowCanvas[source.droppableId] = {
        ...column,
        canvases: copiedCanvases,
      };
      updateStateOnDragEnd(workflowCanvas);
      firestore
        .collection('projects')
        .doc(canvasId)
        .update({
          sequenceNumber: updatedSequenceNumber,
        });
      firestore
        .collection('masterProjects')
        .doc(selectedMasterProject.id)
        .update({
          updatedAt: new Date(),
          updatedTimeStamp,
        });
      // copiedCanvases.map((canvasItem: any, index: number) => {
      //   return firestore
      //     .collection('projects')
      //     .doc(canvasItem.id)
      //     .update({
      //       sequenceNumber: index,
      //     });
      // });
    }
  };

  return (
    <KanbanBoard
      history={history}
      selectedMasterProject={selectedMasterProject}
      workflowCanvas={workflowCanvas}
      onDragEnd={onDragEnd}
      setActiveColumnId={setActiveColumnId}
      activeColumnId={activeColumnId}
      loader={loader}
    />
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='kanbanView'>
        <Droppable droppableId='board' type='COLUMN' direction='horizontal'>
          {(provided) => (
            <div className='kanbanContainer' ref={provided.innerRef} {...provided.droppableProps}>
              {workflowCanvas.map((workflowStep: IworkflowCanvas, workflowIndex: number) => {
                const {
                  id,
                  color = '',
                  step,
                  canvases = [],
                  loadingMoreCanvas = false,
                  isMoreCanvas = false,
                } = workflowStep;
                return (
                  <Draggable draggableId={id} key={id} index={workflowIndex}>
                    {(dragProvided, dragSnapshot) => (
                      <div
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        className={`kanbanColumn ${activeColumnId === id && 'selected-column'}`}
                        onClick={setActiveColumnId(id)}
                        key={id}
                      >
                        <div
                          className='kanbanColumnHeader'
                          style={{
                            background: dragSnapshot.isDragging
                              ? hexToRGBA(color, 40)
                              : hexToRGBA(color, 50),
                          }}
                        >
                          <div className='kanbanColumnText' {...dragProvided.dragHandleProps}>
                            {step} {canvases.length ? `(${canvases.length})` : ''}
                          </div>
                        </div>
                        <Droppable droppableId={`${workflowIndex}`} type='canvas'>
                          {(dropProvided: any, dropSnapshot) => {
                            return (
                              <div
                                {...dropProvided.droppableProps}
                                key={workflowIndex}
                                className='canvasColunm'
                                style={{
                                  background: dropSnapshot.isDraggingOver
                                    ? hexToRGBA(color, 60)
                                    : hexToRGBA(color, 30),
                                }}
                              >
                                {internalScroll ? (
                                  <div className='innerScroll'>
                                    <div className='drop-zone' ref={dropProvided.innerRef}>
                                      <ProjectList
                                        selectedMasterProject={selectedMasterProject}
                                        isWriteAccess={isWriteAccess}
                                        signUpDays={signUpDays}
                                        profile={profile}
                                        authId={auth && auth.uid}
                                        loader={
                                          activeColumnId && activeColumnId === id
                                            ? innerLoader
                                            : loader
                                        }
                                        loginUserId={auth && auth.uid}
                                        loginUserEmail={auth && auth.email}
                                        projects={projects}
                                        onDelete={onDelete}
                                        canvases={canvases || []}
                                        loadingMoreCanvas={loadingMoreCanvas}
                                        isMoreCanvas={isMoreCanvas}
                                        loadMoreCanvas={loadMoreCanvas}
                                        selectedWorkflowId={id}
                                        paginationLimit={paginationLimit}
                                        selectedMasterProjectId={
                                          (selectedMasterProject && selectedMasterProject.id) || ''
                                        }
                                        isKanbanView={true}
                                        internalScroll
                                        selectedOrganizationData={selectedOrganizationData}
                                      />
                                      {dropProvided.placeholder}
                                    </div>
                                  </div>
                                ) : (
                                  <div className='drop-zone' ref={dropProvided.innerRef}>
                                    <ProjectList
                                      selectedMasterProject={selectedMasterProject}
                                      isWriteAccess={isWriteAccess}
                                      signUpDays={signUpDays}
                                      profile={profile}
                                      authId={auth && auth.uid}
                                      loader={loader}
                                      loginUserId={auth && auth.uid}
                                      loginUserEmail={auth && auth.email}
                                      projects={projects}
                                      onDelete={onDelete}
                                      canvases={canvases || []}
                                      loadingMoreCanvas={loadingMoreCanvas}
                                      isMoreCanvas={isMoreCanvas}
                                      loadMoreCanvas={loadMoreCanvas}
                                      selectedWorkflowId={id}
                                      paginationLimit={paginationLimit}
                                      selectedMasterProjectId={
                                        (selectedMasterProject && selectedMasterProject.id) || ''
                                      }
                                      isKanbanView={true}
                                      internalScroll
                                      selectedOrganizationData={selectedOrganizationData}
                                    />
                                    {dropProvided.placeholder}
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default KanbanList;

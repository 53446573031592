import { Avatar, Tooltip } from 'antd';
import React, { Fragment, useState } from 'react';
import { trackAnalytics } from 'utils';
import ColorContext from '../../context/ColorContext';
import { ZselectedWorkspace, ZselectedWorkspaceWraper } from '../../primitives';
import { IMasterProject } from '../../types/projectTypes';
import { AddIcon } from '../svg';

const MasterProjects = (props: {
  selfMasterProjects: IMasterProject[];
  selectedMasterProject: IMasterProject;
  changeMasterProject: any;
  addMasterProject: any;
  history: any;
  match: any;
  profile: any;
  selectedOrganizationData: any;
}) => {
  const {
    selfMasterProjects,
    selectedMasterProject,
    changeMasterProject,
    addMasterProject,
    history,
    match,
    profile,
    selectedOrganizationData,
  } = props;
  const [hover, setHover] = useState(false);

  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <Fragment>
      <ColorContext.Consumer>
        {({ theme }) => (
          <div className='project' style={{ borderColor: theme.secondaryBtn.color2 }}>
            {selfMasterProjects.map((project: IMasterProject) => {
              const { id, name, imageThumbnailUrl, color } = project;
              return (
                <Tooltip title={name} placement='right' key={id}>
                  <ZselectedWorkspaceWraper
                    bgColor={theme.secondaryBtn.color1}
                    onClick={() => {
                      window.localStorage.setItem('selectedMasterProjectId', project.id);
                      changeMasterProject(project);
                      history.push(`/${match.path.split('/')[1]}/${project.id}`);
                    }}
                  >
                    {id === selectedMasterProject.id ? (
                      <ZselectedWorkspace bgColor={theme.primaryBtn.color2} />
                    ) : null
                    // false && index === 2 ? (
                    //   <ZunreadWorkspace bgColor={theme.primaryBtn.color2} />
                    // ) : (
                    //   '')
                    }
                    {imageThumbnailUrl ? (
                      <div
                        className='background-image'
                        style={{ backgroundImage: `url(${imageThumbnailUrl})` }}
                      ></div>
                    ) : (
                      <Avatar
                        className='project-thumb-image'
                        key={id}
                        shape='square'
                        size={50}
                        style={{ background: color }}
                      >
                        {`${name[0].toUpperCase()}${name[1] ? name[1].toUpperCase() : ''}`}
                      </Avatar>
                    )}
                  </ZselectedWorkspaceWraper>
                </Tooltip>
              );
            })}
            <Tooltip
              title={
                userQuota && userQuota.manageMultipleWorkspace
                  ? 'Add Workspace'
                  : "You don't have access to create multiple workspaces."
              }
              placement='right'
            >
              <span
                className={`${
                  userQuota && userQuota.manageMultipleWorkspace ? '' : 'disabled'
                } project-thumb-image-container add-project`}
                onClick={() => {
                  userQuota && userQuota.manageMultipleWorkspace && addMasterProject();
                  userQuota &&
                    userQuota.manageMultipleWorkspace &&
                    trackAnalytics('Workspace', 'Add Workspace Clicked');
                }}
                style={{
                  background: theme.backgrounds.color1,
                  color: theme.primaryBtn.color3,
                  ...(hover && {
                    color: theme.primaryBtn.color3,
                    background: theme.primaryBtn.color1,
                  }),
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <AddIcon />
              </span>
            </Tooltip>
          </div>
        )}
      </ColorContext.Consumer>
    </Fragment>
  );
};

export default MasterProjects;

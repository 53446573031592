import { Button } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  children: any;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  htmlType?: any;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
}

const SignUpStyleButton = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  border-radius: 5px;
  border: none;
  ${({ primary }: IProps) =>
    primary &&
    css`
      color: #ffffff;
      background: #001dad;
      justify-content: space-between;
      &:hover {
        color: #ffffff;
        background: #306ae1;
      }
      &:focus {
        color: #ffffff;
        background: #306ae1;
      }
      &:ative {
        color: #ffffff;
        background: #306ae1;
      }
    `}
  ${({ secondary }: IProps) =>
    secondary &&
    css`
      color: #333333;
      border: 1px solid #bdbdbd;
      justify-content: flex-start;
      &:hover {
        color: #333333;
        background: #ecf5ff;
        border: 1px solid #bdbdbd !important;
      }
      &:focus {
        color: #333333;
        background: #ecf5ff;
        border: 1px solid #bdbdbd;
      }
      &:ative {
        color: #333333;
        background: #ecf5ff;
        border: 1px solid #bdbdbd;
      }
    `}
`;

const SignUpButtons: React.FC<IProps> = ({
  children,
  onClick,
  loading,
  htmlType,
  disabled,
  primary,
  secondary,
}) => {
  return (
    <SignUpStyleButton
      onClick={onClick}
      loading={loading}
      htmlType={htmlType}
      disabled={disabled}
      primary={primary}
      secondary={secondary}
    >
      {children}
    </SignUpStyleButton>
  );
};

export default SignUpButtons;

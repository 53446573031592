import { Button, Checkbox, Menu, Modal } from 'antd';
import ColorContext from 'context/ColorContext';
import { Zbutton } from 'primitives';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { IThread } from 'types/projectTypes';
import { trackAnalytics } from 'utils';
import { mobileCheck } from '../../../utils';
import DropDownCustom from '../../dropDown';
// import { validateYouTubeUrl } from '../../../utils';
import { DropdownArrow } from '../../svg'; // SearchIcon
import './thread.css';
import ThreadList from './ThreadList';
const isMobile = mobileCheck();

const Thread = ({
  threads,
  changeThread,
  addComment,
  loginUserEmail,
  filterCheckbox,
  setFilterCheckbox,
  initial,
  showCommenting,
  setActiveTab,
  profile,
  videoUrl,
  match,
  history,
  uid,
  selectedOrganizationData,
}: {
  threads: IThread[];
  addComment: () => void;
  changeThread: (thread: IThread) => void;
  loginUserEmail: string;
  filterCheckbox: any;
  setFilterCheckbox: any;
  initial: boolean;
  showCommenting: boolean;
  setActiveTab: any;
  mediaPlayedTime: any;
  profile: any;
  videoUrl: any;
  match: any;
  history: any;
  uid: any;
  selectedOrganizationData: any;
}) => {
  const changefilterThread = (e: any, key: string) => {
    trackAnalytics('Canvas Detail', 'Changed Filter in Thread');
    if (key === 'isShowAll') {
      if (e.target.checked) {
        setFilterCheckbox({
          isShowUnread: true,
          isShowResolved: true,
          isShowAll: true,
        });
      } else {
        setFilterCheckbox({
          isShowUnread: true,
          isShowResolved: true,
          isShowAll: false,
        });
      }
    } else {
      if (!e.target.checked) {
        setFilterCheckbox({
          ...filterCheckbox,
          [key]: e.target.checked,
          isShowAll: false,
        });
        // } else if ((key === 'isShowUnread' && e.target.checked && filterCheckbox.isShowResolved) || (key === 'isShowResolved' && e.target.checked && filterCheckbox.isShowUnread)) {
        //   setFilterCheckbox({
        //     isShowUnread: true,
        //     isShowResolved: true,
        //     isShowAll: true
        //   })
      } else {
        setFilterCheckbox({
          ...filterCheckbox,
          [key]: e.target.checked,
        });
      }
    }
  };

  const threadMenu = () => {
    const menu = (
      <Menu className='thread-filter-menu'>
        {[
          { key: 'isShowUnread', label: 'Show Unread' },
          { key: 'isShowResolved', label: 'Show Resolved' },
        ].map((action: { key: string; label: string }, index: number) => (
          <Menu.Item className='project-dropdown-item' key={action.label + index}>
            <div className='thread-filter-checkbox'>
              <Checkbox
                defaultChecked={false}
                checked={filterCheckbox[action.key]}
                onChange={(e: any) => {
                  changefilterThread(e, action.key);
                }}
              >
                <span className='filter-checkbox-span'>{action.label}</span>
              </Checkbox>
            </div>
          </Menu.Item>
        ))}

        <Menu.Item className='project-dropdown-item' key='1'>
          <div className='thread-filter-checkbox'>
            <Checkbox
              defaultChecked={false}
              checked={filterCheckbox.isShowAll}
              onChange={(e: any) => {
                changefilterThread(e, 'isShowAll');
              }}
            >
              <span className='filter-checkbox-span'>Show All</span>
            </Checkbox>
          </div>
        </Menu.Item>

        {/* {['John Doe', 'User 2', 'User 3'].map((user: string, index: number) => (
          <Menu.Item className='project-dropdown-item' key={index}>
            <Avatar src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png' />
            <span className="filter-checkbox-span">{user}</span> <div className="thread-filter-checkbox"><Checkbox></Checkbox></div>
          </Menu.Item>
        ))} */}
      </Menu>
    );
    return menu;
  };
  let userQuota: any = {};
  if (profile.isManagedUserQuotaInOrganization) {
    userQuota = selectedOrganizationData.userQuota;
  } else {
    userQuota = profile.userQuota;
  }
  return (
    <ColorContext.Consumer>
      {({ theme }) => (
        <div>
          <div
            style={{ marginTop: isMobile ? '0px' : undefined }}
            className='thread-filter-container'
          >
            <DropDownCustom
              buttonComponent={() => (
                <Button className='thread-filter-btn'>
                  Filter <DropdownArrow />
                </Button>
              )}
              position={'bottom-right'}
              items={threadMenu()}
            />
            {/* <Dropdown overlay={threadMenu()}>
              <Button className='thread-filter-btn'>
                Filter <DropdownArrow />
              </Button>
            </Dropdown> */}
            <Zbutton
              disabled={
                match.path !== '/c/:id'
                  ? false
                  : videoUrl && !videoUrl.includes('zooc-media-files')
                  ? userQuota && !userQuota.commentOnVideoFromThirdParty
                  : userQuota && !userQuota.abilityToAddCommentOnVideo
              }
              primary
              btnColors={theme}
              style={{ width: 132, height: 32, marginRight: 13, fontSize: 16 }}
              onClick={async () => {
                if (!uid) {
                  Modal.confirm({
                    content: '',
                    okText: 'Yes',
                    cancelText: 'No',
                    title: 'Please Sign Up to continue!',
                    onOk: () => {
                      history.push('/signup');
                    },
                  });
                } else {
                  if (showCommenting) {
                    setActiveTab('commenting');
                  } else {
                    const threadId = await addComment();
                    let pathname = window.location.pathname;
                    pathname = `${pathname}?threadId=${threadId}`;
                    const pageUrlParams = new URLSearchParams(window.location.search);
                    const pageParam: any = pageUrlParams.get('page');
                    if (pageParam) {
                      pathname = `${pathname}&page=${pageParam}`;
                    }
                    trackAnalytics('Canvas Detail', 'Add Comment Clicked');
                    window.history.pushState({}, document.title, pathname);
                  }
                }
              }}
            >
              Add Comment
            </Zbutton>
          </div>
          {!initial && threads.length ? (
            <div className='total-threads-count noselect'>Total threads: {threads.length}</div>
          ) : null}
          <ThreadList
            loginUserEmail={loginUserEmail}
            threads={threads}
            changeThread={changeThread}
          />
        </div>
      )}
    </ColorContext.Consumer>
  );
};

export default withRouter(Thread as any) as any;

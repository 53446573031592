import React from 'react';

interface IProps {
  color?: string;
}

const Icon: React.SFC<IProps> = ({ color }) => (
  <svg width='13' height='17' viewBox='0 0 13 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M6.1604 0C7.86156 0 9.24061 1.38385 9.24061 3.09091V8.5C9.24061 10.2071 7.86156 11.5909 6.1604 11.5909C4.45925 11.5909 3.08019 10.2071 3.08019 8.5V3.09091C3.08019 1.38385 4.45925 0 6.1604 0ZM6.93048 14.634V15.4546H9.24064V17H3.08021V15.4546H5.39037V14.634C2.3515 14.2537 0 11.6524 0 8.50001V6.95456H1.54011V8.50001C1.54011 11.0606 3.60869 13.1364 6.16042 13.1364C8.71215 13.1364 10.7807 11.0606 10.7807 8.50001V6.95456H12.3208V8.50001C12.3208 11.6524 9.96935 14.2537 6.93048 14.634ZM4.70588 3.25133C4.70588 2.49527 5.33795 1.88235 6.11764 1.88235C6.89734 1.88235 7.52941 2.49527 7.52941 3.25133V8.04278C7.52941 8.79884 6.89734 9.41176 6.11764 9.41176C5.33795 9.41176 4.70588 8.79884 4.70588 8.04278V3.25133Z'
      fill={color || '#001DAD'}
    />
  </svg>
);

export default Icon;

import { Button, Form, Input, Modal } from 'antd';
import firebase from 'firebase';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const ProjectForm = (props: any) => {
  const { visible, setVisible, title = '', projectId } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      const { password } = values;
      if (!err) {
        setIsLoading(true);
        const firestore = firebase.firestore();
        firestore
          .collection('projects')
          .doc(projectId)
          .set(
            {
              canvasPassword: password,
            },
            { merge: true },
          )
          .then(() => {
            setIsLoading(false);
            setVisible();
          });
      }
    });
  };

  const { getFieldDecorator } = props.form;
  return (
    <div onClick={(e) => e.stopPropagation()} className='project-form'>
      <Modal visible={visible} title={title} onOk={setVisible} onCancel={setVisible} footer={null}>
        <div className='add-project-container'>
          <Form className='white'>
            <Form.Item className='add-project-form-item hide-padding-right'>
              {getFieldDecorator('password', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Please Input Password!',
                    whitespace: true,
                  },
                ],
              })(<Input placeholder='Enter Password' />)}
            </Form.Item>
            <div className='add-project-submit-div'>
              <Button
                className='add-project-submit-button'
                key='submit'
                type='primary'
                htmlType='submit'
                loading={isLoading}
                disabled={isLoading}
                onClick={handleSubmit}
              >
                Set Password
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    firebaseAuthToken: state.auth.firebaseAuthToken,
  };
};

export default connect(
  mapStateToProps,
  null,
  // @ts-ignore
)(Form.create()(withRouter(ProjectForm)));

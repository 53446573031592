import { Form, Modal } from 'antd';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import ColorContext from '../../../context/ColorContext';
import { Zbutton, Zinput } from '../../../primitives';
import { statsAction } from '../../../store/actions/authActions';
import './editOrganizationDialog.css';

interface IProps {
  organizationModalVisible: boolean;
  handleCancel: () => void;
  organizations: any;
  form: any;
  selectedOrganizationIndex: number;
}

const OrganizationsDialog: React.FC<IProps> = ({
  organizationModalVisible,
  handleCancel,
  organizations,
  selectedOrganizationIndex,
  form: { getFieldDecorator, validateFields, resetFields },
}) => {
  const [organizationTitle, setOrganizationTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTitle, setIsTitle] = useState(false);
  const selectedOrganization = organizations[selectedOrganizationIndex];
  useEffect(() => {
    setOrganizationTitle(selectedOrganization.name);
  }, [organizations, selectedOrganizationIndex, organizationModalVisible]);

  const reset = () => {
    setIsLoading(false);
    resetFields();
    handleCancel();
    setIsTitle(false);
  };

  const handleAddOrganization = () => {
    validateFields((err: any, values: any) => {
      const { organizationName } = values;
      const firestore = firebase.firestore();
      const organizationExists = organizations
        ? organizations.filter((Org: any) => {
            if (organizationName === Org.name && Org.id !== selectedOrganization.id) {
              setIsTitle(true);
            }
            return organizationName === Org.name && Org.id !== selectedOrganization.id;
          })
        : [];

      if (!err) {
        if (organizationExists.length === 0 && selectedOrganization.id) {
          setIsLoading(true);
          return firestore
            .collection('organization')
            .doc(selectedOrganization.id)
            .update({
              name: organizationName,
            })
            .then(() => {
              statsAction('UPDATE_ORGANIZATION', firestore);
              reset();
            })
            .catch(() => {
              setIsLoading(false);
              return false;
            });
        }
      }
    });
  };

  return (
    <div>
      <Modal
        visible={organizationModalVisible}
        onOk={handleCancel}
        onCancel={() => {
          handleCancel();
          isTitle && reset();
        }}
        footer={null}
      >
        <ColorContext.Consumer>
          {({ theme }) => (
            <div className='organization-dialog-container' style={{ color: theme.text.color2 }}>
              <h2>{'Edit Organization'}</h2>
              {/* <p>Some contents...</p> */}
              <Form className='white'>
                <Form.Item>
                  <div className='selected-color' />
                  {getFieldDecorator('organizationName', {
                    initialValue: organizationTitle,
                    rules: [
                      {
                        required: true,
                        message: 'Please input organization name!',
                        whitespace: true,
                      },
                    ],
                  })(<Zinput placeholder='Enter Organization Name' secondary />)}
                </Form.Item>
                {isTitle && (
                  <div>
                    <p style={{ color: 'red', marginTop: 0 }}>
                      The given organization is allready exist. Please try a new one
                    </p>
                  </div>
                )}
                <div className='add-organization-btn-container'>
                  <Zbutton
                    secondary
                    btnColors={theme}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      handleCancel();
                      isTitle && reset();
                    }}
                  >
                    Cancel
                  </Zbutton>
                  <Zbutton
                    key='submit'
                    primary
                    btnColors={theme}
                    loading={isLoading}
                    onClick={handleAddOrganization}
                  >
                    {'Save Changes'}
                  </Zbutton>
                </div>
              </Form>
            </div>
          )}
        </ColorContext.Consumer>
      </Modal>
    </div>
  );
};

export default Form.create<IProps>()(OrganizationsDialog);

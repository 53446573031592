import { Modal, notification } from 'antd';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  createProject as createProjectAction,
  reset as resetAction,
} from '../../store/actions/projectActions';
import { sendMessageToSlack } from '../../store/actions/slackIntegrationAction';
import { trackAnalytics } from '../../utils';
import { mobileCheck } from '../../utils';
// import UploadDialogContent from './UploadDialogContent';
import ImageDragDrop from '../shared/ImageDragDrop';
import ByClipboard from './ByClipboard';
import ByURLDialogContent from './ByURLDialogContent';
import './style.css';

const isMobile = mobileCheck();

interface Project {
  title: string;
  project_url: string;
  member_emails: string[];
  project_tags: string[];
  type: string;
}

const Dialog = (props: {
  canvas: any;
  selectedWorkflowId?: string;
  selectedMasterProjectId?: string;
  history?: any;
  createProject: any;
  resetLoader: any;
  firebaseAuthToken: string;
  profile: any;
  auth: any;
  isImageModal: boolean;
  setIsImageModal: any;
  propsLoading: boolean;
  selectedMasterProject: any;
  selectedOrganizationData: any;
}) => {
  const {
    selectedWorkflowId,
    selectedMasterProjectId,
    history,
    createProject,
    firebaseAuthToken,
    profile,
    auth,
    isImageModal,
    setIsImageModal,
    propsLoading,
    selectedMasterProject,
    selectedOrganizationData,
  } = props;
  const [selectedTab, setSelectedTab] = useState(isMobile ? 'ByURL' : 'Record');
  const [imageUrl, setImageUrl] = useState('');

  const createImageTask = async (
    url: string,
    thumbnailURL: string = '',
    blobURL: string = '',
    blobSize: any = '',
  ) => {
    trackAnalytics('Canvas', 'Created a canvas', 'image');
    const { email } = profile;
    const projectData = {
      title: 'Untitled task',
      project_url: url,
      member_emails: [email],
      project_tags: [],
      type: 'image',
      workflowId: selectedWorkflowId,
      masterProjectId: selectedMasterProjectId,
      archived: 'no',
      project_base64_image: blobURL,
      thumbnail_url: thumbnailURL,
    };
    let userFirebaseAuthToken = firebaseAuthToken;
    const fiebaseInstance: any = firebase;
    if (
      !firebaseAuthToken &&
      fiebaseInstance &&
      fiebaseInstance.auth() &&
      fiebaseInstance.auth().currentUser
    ) {
      userFirebaseAuthToken = await fiebaseInstance.auth().currentUser.getIdToken(true);
    }
    createProject(projectData, userFirebaseAuthToken, selectedMasterProject).then(
      async (insertedId: any) => {
        if (insertedId) {
          let userQuota: any = {};
          if (profile.isManagedUserQuotaInOrganization) {
            userQuota = selectedOrganizationData.userQuota;
          } else {
            userQuota = profile.userQuota;
          }
          if (blobSize && profile && userQuota) {
            if (auth && auth.uid && userQuota) {
              userQuota.storageLimit = userQuota.storageLimit - blobSize / 1024;
              if (userQuota.storageLimit <= 0) {
                notification.error({
                  message: 'Your current plan doesn’t include using this feature.',
                });
                return true;
              }
              const firestore = firebase.firestore();
              if (profile.isManagedUserQuotaInOrganization) {
                firestore
                  .collection('organization')
                  .doc(selectedOrganizationData.id)
                  .update({
                    userQuota,
                  });
              } else {
                firestore
                  .collection('users')
                  .doc(auth.uid)
                  .update({
                    userQuota,
                  });
              }
            }
          }
          const slackMessage = `${profile.fullName} created image type task`;
          const notificationId = uuid();
          const projectURL = `${window.location.origin}/image/${insertedId}?notificationId=${notificationId}`;
          userFirebaseAuthToken &&
            sendMessageToSlack(
              userFirebaseAuthToken,
              `${slackMessage}`,
              insertedId,
              projectURL,
              profile.initials ? profile.initials : '',
              notificationId,
            );
          history.push(`/image/${insertedId}`);
          setIsImageModal(false);
        }
      },
    );
  };
  return (
    <div>
      <Modal
        visible={isImageModal}
        footer={null}
        closable={false}
        maskClosable={true}
        onOk={() => setIsImageModal(false)}
        onCancel={() => setIsImageModal(false)}
        bodyStyle={{ height: '300px', width: '500px' }}
      >
        <div className='dialogContainer'>
          <div className='dialogTabsContainer'>
            {!isMobile && (
              <div
                className={`dialogTab dialogTab1 ${selectedTab === 'Record' &&
                  'dialogTabSelected'}`}
                onClick={() => setSelectedTab('Record')}
              >
                From Clipboard
              </div>
            )}
            <div
              className={`${isMobile ? 'pdfDialog' : ''} dialogTab dialogTab2 ${selectedTab ===
                'Upload' && 'dialogTabSelected'}`}
              onClick={() => setSelectedTab('Upload')}
            >
              Upload
            </div>
            <div
              className={`${isMobile ? 'pdfDialog' : ''} dialogTab dialogTab3 ${selectedTab ===
                'ByURL' && 'dialogTabSelected'}`}
              onClick={() => setSelectedTab('ByURL')}
            >
              By URL
            </div>
          </div>
          {selectedTab === 'Record' && isImageModal ? (
            <ByClipboard
              profile={profile}
              auth={auth}
              ShowNewVideoCanvas={setIsImageModal}
              createImageTask={createImageTask}
              selectedOrganizationData={selectedOrganizationData}
            />
          ) : // <VideoDialogContent createVideoTask={createVideoTask} ShowNewVideoCanvas={setIsImageModal} />
          selectedTab === 'Upload' && isImageModal ? (
            // <UploadDialogContent ShowNewVideoCanvas={setIsImageModal} />

            // <UploadDialogContent
            //     ShowNewVideoCanvas={setIsImageModal}
            //     projectType="image"
            //     createImageTask={createImageTask}
            // />

            <div className={'image-drag-drop upload-image-and-drag-drop'}>
              <ImageDragDrop
                isUploadAndSave
                loader={propsLoading}
                edit={false}
                url={imageUrl}
                profile={profile}
                auth={auth}
                sendFeedback={(image: any, thumbnailImage: any) => {
                  image && createImageTask(image, thumbnailImage, '', '');
                  image && setImageUrl(image);
                }}
              />
            </div>
          ) : (
            isImageModal && (
              <ByURLDialogContent
                type={'image'}
                createImageTask={createImageTask}
                ShowNewVideoCanvas={setIsImageModal}
              />
            )
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    canvas: state.canvas,
    propsLoading: state.project.loading,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    firebaseAuthToken: state.auth.firebaseAuthToken,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    resetLoader: () => dispatch(resetAction()),
    createProject: (project: Project, firebaseAuthToken: string, selectedMasterProject: any) =>
      dispatch(createProjectAction(project, firebaseAuthToken, selectedMasterProject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dialog as any) as any;
